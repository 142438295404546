import Select from 'react-select';
import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useSearchParams } from 'react-router-dom';


const options = [
    { value: "Alchemist", label: "Alchemist" },
    { value: "Axe", label: "Axe" },
    { value: "Bristleback", label: "Bristleback" },
    { value: "Centaur Warrunner", label: "Centaur Warrunner" },
    { value: "Chaos Knight", label: "Chaos Knight" },
    { value: "Dawnbreaker", label: "Dawnbreaker" },
    { value: "Doom", label: "Doom" },
    { value: "Dragon Knight", label: "Dragon Knight" },
    { value: "Earth Spirit", label: "Earth Spirit" },
    { value: "Earthshaker", label: "Earthshaker" },
    { value: "Elder Titan", label: "Elder Titan" },
    { value: "Huskar", label: "Huskar" },
    { value: "Kunkka", label: "Kunkka" },
    { value: "Legion Commander", label: "Legion Commander" },
    { value: "Lifestealer", label: "Lifestealer" },
    { value: "Mars", label: "Mars" },
    { value: "Night Stalker", label: "Night Stalker" },
    { value: "Ogre Magi", label: "Ogre Magi" },
    { value: "Omniknight", label: "Omniknight" },
    { value: "Primal Beast", label: "Primal Beast" },
    { value: "Pudge", label: "Pudge" },
    { value: "Slardar", label: "Slardar" },
    { value: "Spirit Breaker", label: "Spirit Breaker" },
    { value: "Sven", label: "Sven" },
    { value: "Tidehunter", label: "Tidehunter" },
    { value: "Tiny", label: "Tiny" },
    { value: "Treant Protector", label: "Treant Protector" },
    { value: "Tusk", label: "Tusk" },
    { value: "Underlord", label: "Underlord" },
    { value: "Undying", label: "Undying" },
    { value: "Wraith King", label: "Wraith King" },
    { value: "Anti-Mage", label: "Anti-Mage" },
    { value: "Arc Warden", label: "Arc Warden" },
    { value: "Bloodseeker", label: "Bloodseeker" },
    { value: "Bounty Hunter", label: "Bounty Hunter" },
    { value: "Clinkz", label: "Clinkz" },
    { value: "Drow Ranger", label: "Drow Ranger" },
    { value: "Ember Spirit", label: "Ember Spirit" },
    { value: "Faceless Void", label: "Faceless Void" },
    { value: "Gyrocopter", label: "Gyrocopter" },
    { value: "Hoodwink", label: "Hoodwink" },
    { value: "Juggernaut", label: "Juggernaut" },
    { value: "Kez", label: "Kez" },
    { value: "Luna", label: "Luna" },
    { value: "Medusa", label: "Medusa" },
    { value: "Meepo", label: "Meepo" },
    { value: "Monkey King", label: "Monkey King" },
    { value: "Morphling", label: "Morphling" },
    { value: "Naga Siren", label: "Naga Siren" },
    { value: "Phantom Assassin", label: "Phantom Assassin" },
    { value: "Phantom Lancer", label: "Phantom Lancer" },
    { value: "Razor", label: "Razor" },
    { value: "Riki", label: "Riki" },
    { value: "Shadow Fiend", label: "Shadow Fiend" },
    { value: "Slark", label: "Slark" },
    { value: "Sniper", label: "Sniper" },
    { value: "Spectre", label: "Spectre" },
    { value: "Templar Assassin", label: "Templar Assassin" },
    { value: "Terrorblade", label: "Terrorblade" },
    { value: "Troll Warlord", label: "Troll Warlord" },
    { value: "Ursa", label: "Ursa" },
    { value: "Viper", label: "Viper" },
    { value: "Weaver", label: "Weaver" },
    { value: "Ancient Apparition", label: "Ancient Apparition" },
    { value: "Crystal Maiden", label: "Crystal Maiden" },
    { value: "Death Prophet", label: "Death Prophet" },
    { value: "Disruptor", label: "Disruptor" },
    { value: "Enchantress", label: "Enchantress" },
    { value: "Grimstroke", label: "Grimstroke" },
    { value: "Jakiro", label: "Jakiro" },
    { value: "Keeper of the Light", label: "Keeper of the Light" },
    { value: "Leshrac", label: "Leshrac" },
    { value: "Lich", label: "Lich" },
    { value: "Lina", label: "Lina" },
    { value: "Lion", label: "Lion" },
    { value: "Muerta", label: "Muerta" },
    { value: "Nature's Prophet", label: "Nature's Prophet" },
    { value: "Necrophos", label: "Necrophos" },
    { value: "Oracle", label: "Oracle" },
    { value: "Outworld Destroyer", label: "Outworld Destroyer" },
    { value: "Puck", label: "Puck" },
    { value: "Pugna", label: "Pugna" },
    { value: "Queen of Pain", label: "Queen of Pain" },
    { value: "Ringmaster", label: "Ringmaster"},
    { value: "Rubick", label: "Rubick" },
    { value: "Shadow Demon", label: "Shadow Demon" },
    { value: "Shadow Shaman", label: "Shadow Shaman" },
    { value: "Silencer", label: "Silencer" },
    { value: "Skywrath Mage", label: "Skywrath Mage" },
    { value: "Storm Spirit", label: "Storm Spirit" },
    { value: "Tinker", label: "Tinker" },
    { value: "Warlock", label: "Warlock" },
    { value: "Witch Doctor", label: "Witch Doctor" },
    { value: "Zeus", label: "Zeus" },
    { value: "Abaddon", label: "Abaddon" },
    { value: "Bane", label: "Bane" },
    { value: "Batrider", label: "Batrider" },
    { value: "Beastmaster", label: "Beastmaster" },
    { value: "Brewmaster", label: "Brewmaster" },
    { value: "Broodmother", label: "Broodmother" },
    { value: "Chen", label: "Chen" },
    { value: "Clockwerk", label: "Clockwerk" },
    { value: "Dark Seer", label: "Dark Seer" },
    { value: "Dark Willow", label: "Dark Willow" },
    { value: "Dazzle", label: "Dazzle" },
    { value: "Enigma", label: "Enigma" },
    { value: "Invoker", label: "Invoker" },
    { value: "Io", label: "Io" },
    { value: "Lone Druid", label: "Lone Druid" },
    { value: "Lycan", label: "Lycan" },
    { value: "Magnus", label: "Magnus" },
    { value: "Marci", label: "Marci" },
    { value: "Mirana", label: "Mirana" },
    { value: "Nyx Assassin", label: "Nyx Assassin" },
    { value: "Pangolier", label: "Pangolier" },
    { value: "Phoenix", label: "Phoenix" },
    { value: "Sand King", label: "Sand King" },
    { value: "Snapfire", label: "Snapfire" },
    { value: "Techies", label: "Techies" },
    { value: "Timbersaw", label: "Timbersaw" },
    { value: "Vengeful Spirit", label: "Vengeful Spirit" },
    { value: "Venomancer", label: "Venomancer" },
    { value: "Visage", label: "Visage" },
    { value: "Void Spirit", label: "Void Spirit" },
    { value: "Windranger", label: "Windranger" },
    { value: "Winter Wyvern", label: "Winter Wyvern" },
]

const allColumns = {
    "Alchemist": {
        name: "Alchemist",
        selector: row => row["Alchemist"],
        sortable: true,
    },
    "Axe": {
        name: "Axe",
        selector: row => row["Axe"],
        sortable: true,
    },
    "Bristleback": {
        name: "Bristleback",
        selector: row => row["Bristleback"],
        sortable: true,
    },
    "Centaur Warrunner": {
        name: "Centaur Warrunner",
        selector: row => row["Centaur Warrunner"],
        sortable: true,
    },
    "Chaos Knight": {
        name: "Chaos Knight",
        selector: row => row["Chaos Knight"],
        sortable: true,
    },
    "Dawnbreaker": {
        name: "Dawnbreaker",
        selector: row => row["Dawnbreaker"],
        sortable: true,
    },
    "Doom": {
        name: "Doom",
        selector: row => row["Doom"],
        sortable: true,
    },
    "Dragon Knight": {
        name: "Dragon Knight",
        selector: row => row["Dragon Knight"],
        sortable: true,
    },
    "Earth Spirit": {
        name: "Earth Spirit",
        selector: row => row["Earth Spirit"],
        sortable: true,
    },
    "Earthshaker": {
        name: "Earthshaker",
        selector: row => row["Earthshaker"],
        sortable: true,
    },
    "Elder Titan": {
        name: "Elder Titan",
        selector: row => row["Elder Titan"],
        sortable: true,
    },
    "Huskar": {
        name: "Huskar",
        selector: row => row["Huskar"],
        sortable: true,
    },
    "Kunkka": {
        name: "Kunkka",
        selector: row => row["Kunkka"],
        sortable: true,
    },
    "Legion Commander": {
        name: "Legion Commander",
        selector: row => row["Legion Commander"],
        sortable: true,
    },
    "Lifestealer": {
        name: "Lifestealer",
        selector: row => row["Lifestealer"],
        sortable: true,
    },
    "Mars": {
        name: "Mars",
        selector: row => row["Mars"],
        sortable: true,
    },
    "Night Stalker": {
        name: "Night Stalker",
        selector: row => row["Night Stalker"],
        sortable: true,
    },
    "Ogre Magi": {
        name: "Ogre Magi",
        selector: row => row["Ogre Magi"],
        sortable: true,
    },
    "Omniknight": {
        name: "Omniknight",
        selector: row => row["Omniknight"],
        sortable: true,
    },
    "Primal Beast": {
        name: "Primal Beast",
        selector: row => row["Primal Beast"],
        sortable: true,
    },
    "Pudge": {
        name: "Pudge",
        selector: row => row["Pudge"],
        sortable: true,
    },
    "Slardar": {
        name: "Slardar",
        selector: row => row["Slardar"],
        sortable: true,
    },
    "Spirit Breaker": {
        name: "Spirit Breaker",
        selector: row => row["Spirit Breaker"],
        sortable: true,
    },
    "Sven": {
        name: "Sven",
        selector: row => row["Sven"],
        sortable: true,
    },
    "Tidehunter": {
        name: "Tidehunter",
        selector: row => row["Tidehunter"],
        sortable: true,
    },
    "Tiny": {
        name: "Tiny",
        selector: row => row["Tiny"],
        sortable: true,
    },
    "Treant Protector": {
        name: "Treant Protector",
        selector: row => row["Treant Protector"],
        sortable: true,
    },
    "Tusk": {
        name: "Tusk",
        selector: row => row["Tusk"],
        sortable: true,
    },
    "Underlord": {
        name: "Underlord",
        selector: row => row["Underlord"],
        sortable: true,
    },
    "Undying": {
        name: "Undying",
        selector: row => row["Undying"],
        sortable: true,
    },
    "Wraith King": {
        name: "Wraith King",
        selector: row => row["Wraith King"],
        sortable: true,
    },
    "Anti-Mage": {
        name: "Anti-Mage",
        selector: row => row["Anti-Mage"],
        sortable: true,
    },
    "Arc Warden": {
        name: "Arc Warden",
        selector: row => row["Arc Warden"],
        sortable: true,
    },
    "Bloodseeker": {
        name: "Bloodseeker",
        selector: row => row["Bloodseeker"],
        sortable: true,
    },
    "Bounty Hunter": {
        name: "Bounty Hunter",
        selector: row => row["Bounty Hunter"],
        sortable: true,
    },
    "Clinkz": {
        name: "Clinkz",
        selector: row => row["Clinkz"],
        sortable: true,
    },
    "Drow Ranger": {
        name: "Drow Ranger",
        selector: row => row["Drow Ranger"],
        sortable: true,
    },
    "Ember Spirit": {
        name: "Ember Spirit",
        selector: row => row["Ember Spirit"],
        sortable: true,
    },
    "Faceless Void": {
        name: "Faceless Void",
        selector: row => row["Faceless Void"],
        sortable: true,
    },
    "Gyrocopter": {
        name: "Gyrocopter",
        selector: row => row["Gyrocopter"],
        sortable: true,
    },
    "Hoodwink": {
        name: "Hoodwink",
        selector: row => row["Hoodwink"],
        sortable: true,
    },
    "Juggernaut": {
        name: "Juggernaut",
        selector: row => row["Juggernaut"],
        sortable: true,
    },
    "Kez": {
        name: "Kez",
        selector: row => row["Kez"],
        sortable: true,
    },
    "Luna": {
        name: "Luna",
        selector: row => row["Luna"],
        sortable: true,
    },
    "Medusa": {
        name: "Medusa",
        selector: row => row["Medusa"],
        sortable: true,
    },
    "Meepo": {
        name: "Meepo",
        selector: row => row["Meepo"],
        sortable: true,
    },
    "Monkey King": {
        name: "Monkey King",
        selector: row => row["Monkey King"],
        sortable: true,
    },
    "Morphling": {
        name: "Morphling",
        selector: row => row["Morphling"],
        sortable: true,
    },
    "Naga Siren": {
        name: "Naga Siren",
        selector: row => row["Naga Siren"],
        sortable: true,
    },
    "Phantom Assassin": {
        name: "Phantom Assassin",
        selector: row => row["Phantom Assassin"],
        sortable: true,
    },
    "Phantom Lancer": {
        name: "Phantom Lancer",
        selector: row => row["Phantom Lancer"],
        sortable: true,
    },
    "Razor": {
        name: "Razor",
        selector: row => row["Razor"],
        sortable: true,
    },
    "Riki": {
        name: "Riki",
        selector: row => row["Riki"],
        sortable: true,
    },
    "Shadow Fiend": {
        name: "Shadow Fiend",
        selector: row => row["Shadow Fiend"],
        sortable: true,
    },
    "Slark": {
        name: "Slark",
        selector: row => row["Slark"],
        sortable: true,
    },
    "Sniper": {
        name: "Sniper",
        selector: row => row["Sniper"],
        sortable: true,
    },
    "Spectre": {
        name: "Spectre",
        selector: row => row["Spectre"],
        sortable: true,
    },
    "Templar Assassin": {
        name: "Templar Assassin",
        selector: row => row["Templar Assassin"],
        sortable: true,
    },
    "Terrorblade": {
        name: "Terrorblade",
        selector: row => row["Terrorblade"],
        sortable: true,
    },
    "Troll Warlord": {
        name: "Troll Warlord",
        selector: row => row["Troll Warlord"],
        sortable: true,
    },
    "Ursa": {
        name: "Ursa",
        selector: row => row["Ursa"],
        sortable: true,
    },
    "Viper": {
        name: "Viper",
        selector: row => row["Viper"],
        sortable: true,
    },
    "Weaver": {
        name: "Weaver",
        selector: row => row["Weaver"],
        sortable: true,
    },
    "Ancient Apparition": {
        name: "Ancient Apparition",
        selector: row => row["Ancient Apparition"],
        sortable: true,
    },
    "Crystal Maiden": {
        name: "Crystal Maiden",
        selector: row => row["Crystal Maiden"],
        sortable: true,
    },
    "Death Prophet": {
        name: "Death Prophet",
        selector: row => row["Death Prophet"],
        sortable: true,
    },
    "Disruptor": {
        name: "Disruptor",
        selector: row => row["Disruptor"],
        sortable: true,
    },
    "Enchantress": {
        name: "Enchantress",
        selector: row => row["Enchantress"],
        sortable: true,
    },
    "Grimstroke": {
        name: "Grimstroke",
        selector: row => row["Grimstroke"],
        sortable: true,
    },
    "Jakiro": {
        name: "Jakiro",
        selector: row => row["Jakiro"],
        sortable: true,
    },
    "Keeper of the Light": {
        name: "Keeper of the Light",
        selector: row => row["Keeper of the Light"],
        sortable: true,
    },
    "Leshrac": {
        name: "Leshrac",
        selector: row => row["Leshrac"],
        sortable: true,
    },
    "Lich": {
        name: "Lich",
        selector: row => row["Lich"],
        sortable: true,
    },
    "Lina": {
        name: "Lina",
        selector: row => row["Lina"],
        sortable: true,
    },
    "Lion": {
        name: "Lion",
        selector: row => row["Lion"],
        sortable: true,
    },
    "Muerta": {
        name: "Muerta",
        selector: row => row["Muerta"],
        sortable: true,
    },
    "Nature's Prophet": {
        name: "Nature's Prophet",
        selector: row => row["Nature's Prophet"],
        sortable: true,
    },
    "Necrophos": {
        name: "Necrophos",
        selector: row => row["Necrophos"],
        sortable: true,
    },
    "Oracle": {
        name: "Oracle",
        selector: row => row["Oracle"],
        sortable: true,
    },
    "Outworld Destroyer": {
        name: "Outworld Destroyer",
        selector: row => row["Outworld Destroyer"],
        sortable: true,
    },
    "Puck": {
        name: "Puck",
        selector: row => row["Puck"],
        sortable: true,
    },
    "Pugna": {
        name: "Pugna",
        selector: row => row["Pugna"],
        sortable: true,
    },
    "Queen of Pain": {
        name: "Queen of Pain",
        selector: row => row["Queen of Pain"],
        sortable: true,
    },
    "Ringmaster": {
        name: "Ringmaster",
        selector: row => row["Ringmaster"],
        sortable: true,
    },
    "Rubick": {
        name: "Rubick",
        selector: row => row["Rubick"],
        sortable: true,
    },
    "Shadow Demon": {
        name: "Shadow Demon",
        selector: row => row["Shadow Demon"],
        sortable: true,
    },
    "Shadow Shaman": {
        name: "Shadow Shaman",
        selector: row => row["Shadow Shaman"],
        sortable: true,
    },
    "Silencer": {
        name: "Silencer",
        selector: row => row["Silencer"],
        sortable: true,
    },
    "Skywrath Mage": {
        name: "Skywrath Mage",
        selector: row => row["Skywrath Mage"],
        sortable: true,
    },
    "Storm Spirit": {
        name: "Storm Spirit",
        selector: row => row["Storm Spirit"],
        sortable: true,
    },
    "Tinker": {
        name: "Tinker",
        selector: row => row["Tinker"],
        sortable: true,
    },
    "Warlock": {
        name: "Warlock",
        selector: row => row["Warlock"],
        sortable: true,
    },
    "Witch Doctor": {
        name: "Witch Doctor",
        selector: row => row["Witch Doctor"],
        sortable: true,
    },
    "Zeus": {
        name: "Zeus",
        selector: row => row["Zeus"],
        sortable: true,
    },
    "Abaddon": {
        name: "Abaddon",
        selector: row => row["Abaddon"],
        sortable: true,
    },
    "Bane": {
        name: "Bane",
        selector: row => row["Bane"],
        sortable: true,
    },
    "Batrider": {
        name: "Batrider",
        selector: row => row["Batrider"],
        sortable: true,
    },
    "Beastmaster": {
        name: "Beastmaster",
        selector: row => row["Beastmaster"],
        sortable: true,
    },
    "Brewmaster": {
        name: "Brewmaster",
        selector: row => row["Brewmaster"],
        sortable: true,
    },
    "Broodmother": {
        name: "Broodmother",
        selector: row => row["Broodmother"],
        sortable: true,
    },
    "Chen": {
        name: "Chen",
        selector: row => row["Chen"],
        sortable: true,
    },
    "Clockwerk": {
        name: "Clockwerk",
        selector: row => row["Clockwerk"],
        sortable: true,
    },
    "Dark Seer": {
        name: "Dark Seer",
        selector: row => row["Dark Seer"],
        sortable: true,
    },
    "Dark Willow": {
        name: "Dark Willow",
        selector: row => row["Dark Willow"],
        sortable: true,
    },
    "Dazzle": {
        name: "Dazzle",
        selector: row => row["Dazzle"],
        sortable: true,
    },
    "Enigma": {
        name: "Enigma",
        selector: row => row["Enigma"],
        sortable: true,
    },
    "Invoker": {
        name: "Invoker",
        selector: row => row["Invoker"],
        sortable: true,
    },
    "Io": {
        name: "Io",
        selector: row => row["Io"],
        sortable: true,
    },
    "Lone Druid": {
        name: "Lone Druid",
        selector: row => row["Lone Druid"],
        sortable: true,
    },
    "Lycan": {
        name: "Lycan",
        selector: row => row["Lycan"],
        sortable: true,
    },
    "Magnus": {
        name: "Magnus",
        selector: row => row["Magnus"],
        sortable: true,
    },
    "Marci": {
        name: "Marci",
        selector: row => row["Marci"],
        sortable: true,
    },
    "Mirana": {
        name: "Mirana",
        selector: row => row["Mirana"],
        sortable: true,
    },
    "Nyx Assassin": {
        name: "Nyx Assassin",
        selector: row => row["Nyx Assassin"],
        sortable: true,
    },
    "Pangolier": {
        name: "Pangolier",
        selector: row => row["Pangolier"],
        sortable: true,
    },
    "Phoenix": {
        name: "Phoenix",
        selector: row => row["Phoenix"],
        sortable: true,
    },
    "Sand King": {
        name: "Sand King",
        selector: row => row["Sand King"],
        sortable: true,
    },
    "Snapfire": {
        name: "Snapfire",
        selector: row => row["Snapfire"],
        sortable: true,
    },
    "Techies": {
        name: "Techies",
        selector: row => row["Techies"],
        sortable: true,
    },
    "Timbersaw": {
        name: "Timbersaw",
        selector: row => row["Timbersaw"],
        sortable: true,
    },
    "Vengeful Spirit": {
        name: "Vengeful Spirit",
        selector: row => row["Vengeful Spirit"],
        sortable: true,
    },
    "Venomancer": {
        name: "Venomancer",
        selector: row => row["Venomancer"],
        sortable: true,
    },
    "Visage": {
        name: "Visage",
        selector: row => row["Visage"],
        sortable: true,
    },
    "Void Spirit": {
        name: "Void Spirit",
        selector: row => row["Void Spirit"],
        sortable: true,
    },
    "Windranger": {
        name: "Windranger",
        selector: row => row["Windranger"],
        sortable: true,
    },
    "Winter Wyvern": {
        name: "Winter Wyvern",
        selector: row => row["Winter Wyvern"],
        sortable: true,
    },
}


const allData = {
    "Alchemist": [
        {
            "name": "Abaddon",
            "Alchemist": -0.726
        },
        {
            "name": "Ancient Apparition",
            "Alchemist": 0.625
        },
        {
            "name": "Anti-Mage",
            "Alchemist": 1.599
        },
        {
            "name": "Arc Warden",
            "Alchemist": 0.967
        },
        {
            "name": "Axe",
            "Alchemist": -0.178
        },
        {
            "name": "Bane",
            "Alchemist": 0.937
        },
        {
            "name": "Batrider",
            "Alchemist": 1.155
        },
        {
            "name": "Beastmaster",
            "Alchemist": -0.518
        },
        {
            "name": "Bloodseeker",
            "Alchemist": -0.008
        },
        {
            "name": "Bounty Hunter",
            "Alchemist": 1.784
        },
        {
            "name": "Brewmaster",
            "Alchemist": 0.452
        },
        {
            "name": "Bristleback",
            "Alchemist": 1.094
        },
        {
            "name": "Broodmother",
            "Alchemist": 0.112
        },
        {
            "name": "Centaur Warrunner",
            "Alchemist": -1.373
        },
        {
            "name": "Chaos Knight",
            "Alchemist": -0.422
        },
        {
            "name": "Chen",
            "Alchemist": 2.607
        },
        {
            "name": "Clinkz",
            "Alchemist": 0.916
        },
        {
            "name": "Clockwerk",
            "Alchemist": 0.191
        },
        {
            "name": "Crystal Maiden",
            "Alchemist": 0.304
        },
        {
            "name": "Dark Seer",
            "Alchemist": 0.182
        },
        {
            "name": "Dark Willow",
            "Alchemist": 0.05
        },
        {
            "name": "Dawnbreaker",
            "Alchemist": 0.02
        },
        {
            "name": "Dazzle",
            "Alchemist": 0.218
        },
        {
            "name": "Death Prophet",
            "Alchemist": 0.179
        },
        {
            "name": "Disruptor",
            "Alchemist": -0.29
        },
        {
            "name": "Doom",
            "Alchemist": 0.77
        },
        {
            "name": "Dragon Knight",
            "Alchemist": -0.445
        },
        {
            "name": "Drow Ranger",
            "Alchemist": 0.438
        },
        {
            "name": "Earth Spirit",
            "Alchemist": -0.134
        },
        {
            "name": "Earthshaker",
            "Alchemist": -0.898
        },
        {
            "name": "Elder Titan",
            "Alchemist": -0.609
        },
        {
            "name": "Ember Spirit",
            "Alchemist": -0.839
        },
        {
            "name": "Enchantress",
            "Alchemist": 0.932
        },
        {
            "name": "Enigma",
            "Alchemist": -1.87
        },
        {
            "name": "Faceless Void",
            "Alchemist": -0.329
        },
        {
            "name": "Grimstroke",
            "Alchemist": 1.112
        },
        {
            "name": "Gyrocopter",
            "Alchemist": 0.56
        },
        {
            "name": "Hoodwink",
            "Alchemist": 0.345
        },
        {
            "name": "Huskar",
            "Alchemist": 1.4
        },
        {
            "name": "Invoker",
            "Alchemist": -0.41
        },
        {
            "name": "Io",
            "Alchemist": 0.554
        },
        {
            "name": "Jakiro",
            "Alchemist": -0.406
        },
        {
            "name": "Juggernaut",
            "Alchemist": 1.154
        },
        {
            "name": "Keeper of the Light",
            "Alchemist": 1.563
        },
        {
            "name": "Kez",
            "Alchemist": 1.646
        },
        {
            "name": "Kunkka",
            "Alchemist": -1.154
        },
        {
            "name": "Legion Commander",
            "Alchemist": 0.71
        },
        {
            "name": "Leshrac",
            "Alchemist": 0.509
        },
        {
            "name": "Lich",
            "Alchemist": 0.198
        },
        {
            "name": "Lifestealer",
            "Alchemist": 0.056
        },
        {
            "name": "Lina",
            "Alchemist": 0.964
        },
        {
            "name": "Lion",
            "Alchemist": 0.387
        },
        {
            "name": "Lone Druid",
            "Alchemist": -0.237
        },
        {
            "name": "Luna",
            "Alchemist": 0.077
        },
        {
            "name": "Lycan",
            "Alchemist": -1.868
        },
        {
            "name": "Magnus",
            "Alchemist": -0.641
        },
        {
            "name": "Marci",
            "Alchemist": 1.131
        },
        {
            "name": "Mars",
            "Alchemist": -0.83
        },
        {
            "name": "Medusa",
            "Alchemist": -4.513
        },
        {
            "name": "Meepo",
            "Alchemist": -1.252
        },
        {
            "name": "Mirana",
            "Alchemist": 0.036
        },
        {
            "name": "Monkey King",
            "Alchemist": 1.199
        },
        {
            "name": "Morphling",
            "Alchemist": 0.001
        },
        {
            "name": "Muerta",
            "Alchemist": -0.436
        },
        {
            "name": "Naga Siren",
            "Alchemist": -0.944
        },
        {
            "name": "Nature's Prophet",
            "Alchemist": 0.577
        },
        {
            "name": "Necrophos",
            "Alchemist": 0.162
        },
        {
            "name": "Night Stalker",
            "Alchemist": 0.454
        },
        {
            "name": "Nyx Assassin",
            "Alchemist": 1.348
        },
        {
            "name": "Ogre Magi",
            "Alchemist": -0.04
        },
        {
            "name": "Omniknight",
            "Alchemist": 1.171
        },
        {
            "name": "Oracle",
            "Alchemist": -0.209
        },
        {
            "name": "Outworld Destroyer",
            "Alchemist": 0.444
        },
        {
            "name": "Pangolier",
            "Alchemist": 0.655
        },
        {
            "name": "Phantom Assassin",
            "Alchemist": 0.383
        },
        {
            "name": "Phantom Lancer",
            "Alchemist": -1.51
        },
        {
            "name": "Phoenix",
            "Alchemist": -0.841
        },
        {
            "name": "Primal Beast",
            "Alchemist": 0.143
        },
        {
            "name": "Puck",
            "Alchemist": -1.084
        },
        {
            "name": "Pudge",
            "Alchemist": -0.409
        },
        {
            "name": "Pugna",
            "Alchemist": -0.111
        },
        {
            "name": "Queen of Pain",
            "Alchemist": 0.433
        },
        {
            "name": "Razor",
            "Alchemist": 0.639
        },
        {
            "name": "Riki",
            "Alchemist": -0.017
        },
        {
            "name": "Ringmaster",
            "Alchemist": -0.257
        },
        {
            "name": "Rubick",
            "Alchemist": -0.427
        },
        {
            "name": "Sand King",
            "Alchemist": -0.195
        },
        {
            "name": "Shadow Demon",
            "Alchemist": 1.089
        },
        {
            "name": "Shadow Fiend",
            "Alchemist": 0.434
        },
        {
            "name": "Shadow Shaman",
            "Alchemist": -0.769
        },
        {
            "name": "Silencer",
            "Alchemist": -0.753
        },
        {
            "name": "Skywrath Mage",
            "Alchemist": 0.527
        },
        {
            "name": "Slardar",
            "Alchemist": 0.935
        },
        {
            "name": "Slark",
            "Alchemist": 1.422
        },
        {
            "name": "Snapfire",
            "Alchemist": 0.834
        },
        {
            "name": "Sniper",
            "Alchemist": 0.424
        },
        {
            "name": "Spectre",
            "Alchemist": -0.689
        },
        {
            "name": "Spirit Breaker",
            "Alchemist": 0.653
        },
        {
            "name": "Storm Spirit",
            "Alchemist": 0.297
        },
        {
            "name": "Sven",
            "Alchemist": -0.721
        },
        {
            "name": "Techies",
            "Alchemist": -0.339
        },
        {
            "name": "Templar Assassin",
            "Alchemist": -0.371
        },
        {
            "name": "Terrorblade",
            "Alchemist": 0.118
        },
        {
            "name": "Tidehunter",
            "Alchemist": -0.742
        },
        {
            "name": "Timbersaw",
            "Alchemist": 1.112
        },
        {
            "name": "Tinker",
            "Alchemist": -0.545
        },
        {
            "name": "Tiny",
            "Alchemist": -1.281
        },
        {
            "name": "Treant Protector",
            "Alchemist": 0.24
        },
        {
            "name": "Troll Warlord",
            "Alchemist": 0.024
        },
        {
            "name": "Tusk",
            "Alchemist": 1.279
        },
        {
            "name": "Underlord",
            "Alchemist": -0.584
        },
        {
            "name": "Undying",
            "Alchemist": -0.97
        },
        {
            "name": "Ursa",
            "Alchemist": -0.018
        },
        {
            "name": "Vengeful Spirit",
            "Alchemist": -0.355
        },
        {
            "name": "Venomancer",
            "Alchemist": 0.597
        },
        {
            "name": "Viper",
            "Alchemist": 0.905
        },
        {
            "name": "Visage",
            "Alchemist": -0.051
        },
        {
            "name": "Void Spirit",
            "Alchemist": -0.866
        },
        {
            "name": "Warlock",
            "Alchemist": -0.935
        },
        {
            "name": "Weaver",
            "Alchemist": -0.042
        },
        {
            "name": "Windranger",
            "Alchemist": 0.332
        },
        {
            "name": "Winter Wyvern",
            "Alchemist": -0.115
        },
        {
            "name": "Witch Doctor",
            "Alchemist": -0.104
        },
        {
            "name": "Wraith King",
            "Alchemist": -2.632
        },
        {
            "name": "Zeus",
            "Alchemist": -0.096
        }
    ],
    "Axe": [
        {
            "name": "Abaddon",
            "Axe": 2.095
        },
        {
            "name": "Alchemist",
            "Axe": 0.196
        },
        {
            "name": "Ancient Apparition",
            "Axe": 0.447
        },
        {
            "name": "Anti-Mage",
            "Axe": -4.543
        },
        {
            "name": "Arc Warden",
            "Axe": -0.841
        },
        {
            "name": "Bane",
            "Axe": 0.065
        },
        {
            "name": "Batrider",
            "Axe": 2.388
        },
        {
            "name": "Beastmaster",
            "Axe": 0.508
        },
        {
            "name": "Bloodseeker",
            "Axe": 0.172
        },
        {
            "name": "Bounty Hunter",
            "Axe": 0.368
        },
        {
            "name": "Brewmaster",
            "Axe": 0.837
        },
        {
            "name": "Bristleback",
            "Axe": -1.654
        },
        {
            "name": "Broodmother",
            "Axe": -3.338
        },
        {
            "name": "Centaur Warrunner",
            "Axe": 1.119
        },
        {
            "name": "Chaos Knight",
            "Axe": -1.617
        },
        {
            "name": "Chen",
            "Axe": 0.478
        },
        {
            "name": "Clinkz",
            "Axe": -1.815
        },
        {
            "name": "Clockwerk",
            "Axe": 0.467
        },
        {
            "name": "Crystal Maiden",
            "Axe": -0.352
        },
        {
            "name": "Dark Seer",
            "Axe": -0.663
        },
        {
            "name": "Dark Willow",
            "Axe": -1.488
        },
        {
            "name": "Dawnbreaker",
            "Axe": 0.433
        },
        {
            "name": "Dazzle",
            "Axe": -1.134
        },
        {
            "name": "Death Prophet",
            "Axe": 1.339
        },
        {
            "name": "Disruptor",
            "Axe": 0.172
        },
        {
            "name": "Doom",
            "Axe": 1.968
        },
        {
            "name": "Dragon Knight",
            "Axe": 1.516
        },
        {
            "name": "Drow Ranger",
            "Axe": -0.775
        },
        {
            "name": "Earth Spirit",
            "Axe": 1.551
        },
        {
            "name": "Earthshaker",
            "Axe": -0.511
        },
        {
            "name": "Elder Titan",
            "Axe": -0.79
        },
        {
            "name": "Ember Spirit",
            "Axe": 1.414
        },
        {
            "name": "Enchantress",
            "Axe": -0.009
        },
        {
            "name": "Enigma",
            "Axe": -1.204
        },
        {
            "name": "Faceless Void",
            "Axe": -0.856
        },
        {
            "name": "Grimstroke",
            "Axe": 0.445
        },
        {
            "name": "Gyrocopter",
            "Axe": 1.084
        },
        {
            "name": "Hoodwink",
            "Axe": -0.908
        },
        {
            "name": "Huskar",
            "Axe": -1.702
        },
        {
            "name": "Invoker",
            "Axe": 0.107
        },
        {
            "name": "Io",
            "Axe": 0.079
        },
        {
            "name": "Jakiro",
            "Axe": 1.095
        },
        {
            "name": "Juggernaut",
            "Axe": -1.14
        },
        {
            "name": "Keeper of the Light",
            "Axe": 0.87
        },
        {
            "name": "Kez",
            "Axe": -0.848
        },
        {
            "name": "Kunkka",
            "Axe": 0.194
        },
        {
            "name": "Legion Commander",
            "Axe": -1.072
        },
        {
            "name": "Leshrac",
            "Axe": 2.424
        },
        {
            "name": "Lich",
            "Axe": -0.219
        },
        {
            "name": "Lifestealer",
            "Axe": 1.811
        },
        {
            "name": "Lina",
            "Axe": -1.12
        },
        {
            "name": "Lion",
            "Axe": -0.619
        },
        {
            "name": "Lone Druid",
            "Axe": -0.049
        },
        {
            "name": "Luna",
            "Axe": 0.841
        },
        {
            "name": "Lycan",
            "Axe": -0.469
        },
        {
            "name": "Magnus",
            "Axe": 0.213
        },
        {
            "name": "Marci",
            "Axe": -0.971
        },
        {
            "name": "Mars",
            "Axe": 0.55
        },
        {
            "name": "Medusa",
            "Axe": -1.368
        },
        {
            "name": "Meepo",
            "Axe": -1.569
        },
        {
            "name": "Mirana",
            "Axe": -1.049
        },
        {
            "name": "Monkey King",
            "Axe": 0.249
        },
        {
            "name": "Morphling",
            "Axe": -1.928
        },
        {
            "name": "Muerta",
            "Axe": -0.76
        },
        {
            "name": "Naga Siren",
            "Axe": -4.282
        },
        {
            "name": "Nature's Prophet",
            "Axe": -0.085
        },
        {
            "name": "Necrophos",
            "Axe": 2.309
        },
        {
            "name": "Night Stalker",
            "Axe": 0.496
        },
        {
            "name": "Nyx Assassin",
            "Axe": -0.584
        },
        {
            "name": "Ogre Magi",
            "Axe": 0.648
        },
        {
            "name": "Omniknight",
            "Axe": 0.14
        },
        {
            "name": "Oracle",
            "Axe": -0.589
        },
        {
            "name": "Outworld Destroyer",
            "Axe": 2.247
        },
        {
            "name": "Pangolier",
            "Axe": -0.201
        },
        {
            "name": "Phantom Assassin",
            "Axe": -3.042
        },
        {
            "name": "Phantom Lancer",
            "Axe": -2.598
        },
        {
            "name": "Phoenix",
            "Axe": 1.311
        },
        {
            "name": "Primal Beast",
            "Axe": 3.001
        },
        {
            "name": "Puck",
            "Axe": -0.794
        },
        {
            "name": "Pudge",
            "Axe": 2.157
        },
        {
            "name": "Pugna",
            "Axe": 0.915
        },
        {
            "name": "Queen of Pain",
            "Axe": 1.32
        },
        {
            "name": "Razor",
            "Axe": 1.016
        },
        {
            "name": "Riki",
            "Axe": -0.784
        },
        {
            "name": "Ringmaster",
            "Axe": 1.3
        },
        {
            "name": "Rubick",
            "Axe": -1.158
        },
        {
            "name": "Sand King",
            "Axe": 1.106
        },
        {
            "name": "Shadow Demon",
            "Axe": -0.28
        },
        {
            "name": "Shadow Fiend",
            "Axe": -0.086
        },
        {
            "name": "Shadow Shaman",
            "Axe": -0.789
        },
        {
            "name": "Silencer",
            "Axe": 1.908
        },
        {
            "name": "Skywrath Mage",
            "Axe": 1.162
        },
        {
            "name": "Slardar",
            "Axe": -0.164
        },
        {
            "name": "Slark",
            "Axe": -0.692
        },
        {
            "name": "Snapfire",
            "Axe": 0.339
        },
        {
            "name": "Sniper",
            "Axe": -1.873
        },
        {
            "name": "Spectre",
            "Axe": 2.168
        },
        {
            "name": "Spirit Breaker",
            "Axe": 0.825
        },
        {
            "name": "Storm Spirit",
            "Axe": -1.331
        },
        {
            "name": "Sven",
            "Axe": 0.803
        },
        {
            "name": "Techies",
            "Axe": 1.035
        },
        {
            "name": "Templar Assassin",
            "Axe": -0.213
        },
        {
            "name": "Terrorblade",
            "Axe": -3.533
        },
        {
            "name": "Tidehunter",
            "Axe": 0.398
        },
        {
            "name": "Timbersaw",
            "Axe": 3.014
        },
        {
            "name": "Tinker",
            "Axe": 1.408
        },
        {
            "name": "Tiny",
            "Axe": 2.199
        },
        {
            "name": "Treant Protector",
            "Axe": 1.171
        },
        {
            "name": "Troll Warlord",
            "Axe": -4.527
        },
        {
            "name": "Tusk",
            "Axe": -0.141
        },
        {
            "name": "Underlord",
            "Axe": -0.012
        },
        {
            "name": "Undying",
            "Axe": 0.431
        },
        {
            "name": "Ursa",
            "Axe": 0.425
        },
        {
            "name": "Vengeful Spirit",
            "Axe": -0.407
        },
        {
            "name": "Venomancer",
            "Axe": 2.299
        },
        {
            "name": "Viper",
            "Axe": 0.838
        },
        {
            "name": "Visage",
            "Axe": -2.845
        },
        {
            "name": "Void Spirit",
            "Axe": -1.045
        },
        {
            "name": "Warlock",
            "Axe": 2.304
        },
        {
            "name": "Weaver",
            "Axe": -1.694
        },
        {
            "name": "Windranger",
            "Axe": -1.683
        },
        {
            "name": "Winter Wyvern",
            "Axe": -0.985
        },
        {
            "name": "Witch Doctor",
            "Axe": 1.96
        },
        {
            "name": "Wraith King",
            "Axe": 1.173
        },
        {
            "name": "Zeus",
            "Axe": 1.348
        }
    ],
    "Bristleback": [
        {
            "name": "Abaddon",
            "Bristleback": 0.023
        },
        {
            "name": "Alchemist",
            "Bristleback": -1.128
        },
        {
            "name": "Ancient Apparition",
            "Bristleback": 1.477
        },
        {
            "name": "Anti-Mage",
            "Bristleback": 0.841
        },
        {
            "name": "Arc Warden",
            "Bristleback": -0.722
        },
        {
            "name": "Axe",
            "Bristleback": 2.04
        },
        {
            "name": "Bane",
            "Bristleback": 0.222
        },
        {
            "name": "Batrider",
            "Bristleback": 0.856
        },
        {
            "name": "Beastmaster",
            "Bristleback": -2.438
        },
        {
            "name": "Bloodseeker",
            "Bristleback": -1.761
        },
        {
            "name": "Bounty Hunter",
            "Bristleback": 1.732
        },
        {
            "name": "Brewmaster",
            "Bristleback": -2.377
        },
        {
            "name": "Broodmother",
            "Bristleback": -1.179
        },
        {
            "name": "Centaur Warrunner",
            "Bristleback": -2.164
        },
        {
            "name": "Chaos Knight",
            "Bristleback": 0.281
        },
        {
            "name": "Chen",
            "Bristleback": -0.153
        },
        {
            "name": "Clinkz",
            "Bristleback": -3.129
        },
        {
            "name": "Clockwerk",
            "Bristleback": 0.192
        },
        {
            "name": "Crystal Maiden",
            "Bristleback": -0.393
        },
        {
            "name": "Dark Seer",
            "Bristleback": -0.051
        },
        {
            "name": "Dark Willow",
            "Bristleback": -0.35
        },
        {
            "name": "Dawnbreaker",
            "Bristleback": -2.4
        },
        {
            "name": "Dazzle",
            "Bristleback": 0.207
        },
        {
            "name": "Death Prophet",
            "Bristleback": -2.503
        },
        {
            "name": "Disruptor",
            "Bristleback": 0.076
        },
        {
            "name": "Doom",
            "Bristleback": -1.999
        },
        {
            "name": "Dragon Knight",
            "Bristleback": 1.13
        },
        {
            "name": "Drow Ranger",
            "Bristleback": 1.621
        },
        {
            "name": "Earth Spirit",
            "Bristleback": 0.892
        },
        {
            "name": "Earthshaker",
            "Bristleback": -1.256
        },
        {
            "name": "Elder Titan",
            "Bristleback": -1.567
        },
        {
            "name": "Ember Spirit",
            "Bristleback": 2.107
        },
        {
            "name": "Enchantress",
            "Bristleback": -1.492
        },
        {
            "name": "Enigma",
            "Bristleback": -2.622
        },
        {
            "name": "Faceless Void",
            "Bristleback": -0.552
        },
        {
            "name": "Grimstroke",
            "Bristleback": -1.188
        },
        {
            "name": "Gyrocopter",
            "Bristleback": -1.785
        },
        {
            "name": "Hoodwink",
            "Bristleback": 2.223
        },
        {
            "name": "Huskar",
            "Bristleback": -1.661
        },
        {
            "name": "Invoker",
            "Bristleback": -1.287
        },
        {
            "name": "Io",
            "Bristleback": -1.179
        },
        {
            "name": "Jakiro",
            "Bristleback": 0.711
        },
        {
            "name": "Juggernaut",
            "Bristleback": 2.036
        },
        {
            "name": "Keeper of the Light",
            "Bristleback": 0.37
        },
        {
            "name": "Kez",
            "Bristleback": 0.348
        },
        {
            "name": "Kunkka",
            "Bristleback": -1.209
        },
        {
            "name": "Legion Commander",
            "Bristleback": 3.468
        },
        {
            "name": "Leshrac",
            "Bristleback": -0.996
        },
        {
            "name": "Lich",
            "Bristleback": -1.427
        },
        {
            "name": "Lifestealer",
            "Bristleback": -2.736
        },
        {
            "name": "Lina",
            "Bristleback": -1.866
        },
        {
            "name": "Lion",
            "Bristleback": -0.13
        },
        {
            "name": "Lone Druid",
            "Bristleback": -2.834
        },
        {
            "name": "Luna",
            "Bristleback": -2.396
        },
        {
            "name": "Lycan",
            "Bristleback": -2.046
        },
        {
            "name": "Magnus",
            "Bristleback": 0.621
        },
        {
            "name": "Marci",
            "Bristleback": 0.96
        },
        {
            "name": "Mars",
            "Bristleback": 0.465
        },
        {
            "name": "Medusa",
            "Bristleback": -1.13
        },
        {
            "name": "Meepo",
            "Bristleback": -3.059
        },
        {
            "name": "Mirana",
            "Bristleback": 0.027
        },
        {
            "name": "Monkey King",
            "Bristleback": 0.34
        },
        {
            "name": "Morphling",
            "Bristleback": 1.645
        },
        {
            "name": "Muerta",
            "Bristleback": -0.276
        },
        {
            "name": "Naga Siren",
            "Bristleback": 0.855
        },
        {
            "name": "Nature's Prophet",
            "Bristleback": 0.917
        },
        {
            "name": "Necrophos",
            "Bristleback": 0.825
        },
        {
            "name": "Night Stalker",
            "Bristleback": -1.133
        },
        {
            "name": "Nyx Assassin",
            "Bristleback": -0.329
        },
        {
            "name": "Ogre Magi",
            "Bristleback": 0.983
        },
        {
            "name": "Omniknight",
            "Bristleback": -1.2
        },
        {
            "name": "Oracle",
            "Bristleback": -1.06
        },
        {
            "name": "Outworld Destroyer",
            "Bristleback": -1.309
        },
        {
            "name": "Pangolier",
            "Bristleback": -0.325
        },
        {
            "name": "Phantom Assassin",
            "Bristleback": 0.004
        },
        {
            "name": "Phantom Lancer",
            "Bristleback": 2.386
        },
        {
            "name": "Phoenix",
            "Bristleback": -0.866
        },
        {
            "name": "Primal Beast",
            "Bristleback": 3.263
        },
        {
            "name": "Puck",
            "Bristleback": 0.263
        },
        {
            "name": "Pudge",
            "Bristleback": -1.001
        },
        {
            "name": "Pugna",
            "Bristleback": -0.671
        },
        {
            "name": "Queen of Pain",
            "Bristleback": 0.29
        },
        {
            "name": "Razor",
            "Bristleback": -1.815
        },
        {
            "name": "Riki",
            "Bristleback": -2.996
        },
        {
            "name": "Ringmaster",
            "Bristleback": -0.176
        },
        {
            "name": "Rubick",
            "Bristleback": -1.052
        },
        {
            "name": "Sand King",
            "Bristleback": -1.192
        },
        {
            "name": "Shadow Demon",
            "Bristleback": 0.443
        },
        {
            "name": "Shadow Fiend",
            "Bristleback": -0.998
        },
        {
            "name": "Shadow Shaman",
            "Bristleback": 1.649
        },
        {
            "name": "Silencer",
            "Bristleback": 1.875
        },
        {
            "name": "Skywrath Mage",
            "Bristleback": -1.378
        },
        {
            "name": "Slardar",
            "Bristleback": 1.224
        },
        {
            "name": "Slark",
            "Bristleback": 4.901
        },
        {
            "name": "Snapfire",
            "Bristleback": -0.816
        },
        {
            "name": "Sniper",
            "Bristleback": 0.517
        },
        {
            "name": "Spectre",
            "Bristleback": -1.78
        },
        {
            "name": "Spirit Breaker",
            "Bristleback": 0.337
        },
        {
            "name": "Storm Spirit",
            "Bristleback": 1.845
        },
        {
            "name": "Sven",
            "Bristleback": -2.154
        },
        {
            "name": "Techies",
            "Bristleback": -1.415
        },
        {
            "name": "Templar Assassin",
            "Bristleback": -0.686
        },
        {
            "name": "Terrorblade",
            "Bristleback": -0.203
        },
        {
            "name": "Tidehunter",
            "Bristleback": -0.805
        },
        {
            "name": "Timbersaw",
            "Bristleback": -0.569
        },
        {
            "name": "Tinker",
            "Bristleback": 1.663
        },
        {
            "name": "Tiny",
            "Bristleback": 0.152
        },
        {
            "name": "Treant Protector",
            "Bristleback": -0.964
        },
        {
            "name": "Troll Warlord",
            "Bristleback": -0.436
        },
        {
            "name": "Tusk",
            "Bristleback": 0.69
        },
        {
            "name": "Underlord",
            "Bristleback": -1.566
        },
        {
            "name": "Undying",
            "Bristleback": -0.676
        },
        {
            "name": "Ursa",
            "Bristleback": -2.757
        },
        {
            "name": "Vengeful Spirit",
            "Bristleback": -0.35
        },
        {
            "name": "Venomancer",
            "Bristleback": 1.44
        },
        {
            "name": "Viper",
            "Bristleback": 3.677
        },
        {
            "name": "Visage",
            "Bristleback": -3.596
        },
        {
            "name": "Void Spirit",
            "Bristleback": 1.011
        },
        {
            "name": "Warlock",
            "Bristleback": -1.916
        },
        {
            "name": "Weaver",
            "Bristleback": -0.199
        },
        {
            "name": "Windranger",
            "Bristleback": -0.499
        },
        {
            "name": "Winter Wyvern",
            "Bristleback": 1.128
        },
        {
            "name": "Witch Doctor",
            "Bristleback": -2.212
        },
        {
            "name": "Wraith King",
            "Bristleback": -2.092
        },
        {
            "name": "Zeus",
            "Bristleback": -0.967
        }
    ],
    "Centaur Warrunner": [
        {
            "name": "Abaddon",
            "Centaur Warrunner": 0.754
        },
        {
            "name": "Alchemist",
            "Centaur Warrunner": 1.428
        },
        {
            "name": "Ancient Apparition",
            "Centaur Warrunner": -0.718
        },
        {
            "name": "Anti-Mage",
            "Centaur Warrunner": -1.289
        },
        {
            "name": "Arc Warden",
            "Centaur Warrunner": -0.596
        },
        {
            "name": "Axe",
            "Centaur Warrunner": -1.099
        },
        {
            "name": "Bane",
            "Centaur Warrunner": -0.857
        },
        {
            "name": "Batrider",
            "Centaur Warrunner": 0.795
        },
        {
            "name": "Beastmaster",
            "Centaur Warrunner": 0.064
        },
        {
            "name": "Bloodseeker",
            "Centaur Warrunner": 1.617
        },
        {
            "name": "Bounty Hunter",
            "Centaur Warrunner": 0.855
        },
        {
            "name": "Brewmaster",
            "Centaur Warrunner": -0.325
        },
        {
            "name": "Bristleback",
            "Centaur Warrunner": 2.145
        },
        {
            "name": "Broodmother",
            "Centaur Warrunner": 0.44
        },
        {
            "name": "Chaos Knight",
            "Centaur Warrunner": -1.186
        },
        {
            "name": "Chen",
            "Centaur Warrunner": 0.172
        },
        {
            "name": "Clinkz",
            "Centaur Warrunner": -0.857
        },
        {
            "name": "Clockwerk",
            "Centaur Warrunner": 0.382
        },
        {
            "name": "Crystal Maiden",
            "Centaur Warrunner": 0.882
        },
        {
            "name": "Dark Seer",
            "Centaur Warrunner": 0.565
        },
        {
            "name": "Dark Willow",
            "Centaur Warrunner": 0.09
        },
        {
            "name": "Dawnbreaker",
            "Centaur Warrunner": -0.343
        },
        {
            "name": "Dazzle",
            "Centaur Warrunner": 2.531
        },
        {
            "name": "Death Prophet",
            "Centaur Warrunner": 0.537
        },
        {
            "name": "Disruptor",
            "Centaur Warrunner": 0.557
        },
        {
            "name": "Doom",
            "Centaur Warrunner": 0.661
        },
        {
            "name": "Dragon Knight",
            "Centaur Warrunner": 0.796
        },
        {
            "name": "Drow Ranger",
            "Centaur Warrunner": -2.741
        },
        {
            "name": "Earth Spirit",
            "Centaur Warrunner": 0.846
        },
        {
            "name": "Earthshaker",
            "Centaur Warrunner": 0.156
        },
        {
            "name": "Elder Titan",
            "Centaur Warrunner": 0.487
        },
        {
            "name": "Ember Spirit",
            "Centaur Warrunner": 2.755
        },
        {
            "name": "Enchantress",
            "Centaur Warrunner": -0.043
        },
        {
            "name": "Enigma",
            "Centaur Warrunner": 0.4
        },
        {
            "name": "Faceless Void",
            "Centaur Warrunner": -0.433
        },
        {
            "name": "Grimstroke",
            "Centaur Warrunner": -0.115
        },
        {
            "name": "Gyrocopter",
            "Centaur Warrunner": -0.01
        },
        {
            "name": "Hoodwink",
            "Centaur Warrunner": -1.333
        },
        {
            "name": "Huskar",
            "Centaur Warrunner": 1.477
        },
        {
            "name": "Invoker",
            "Centaur Warrunner": 0.498
        },
        {
            "name": "Io",
            "Centaur Warrunner": 1.355
        },
        {
            "name": "Jakiro",
            "Centaur Warrunner": 0.274
        },
        {
            "name": "Juggernaut",
            "Centaur Warrunner": 1.318
        },
        {
            "name": "Keeper of the Light",
            "Centaur Warrunner": 0.189
        },
        {
            "name": "Kez",
            "Centaur Warrunner": 1.953
        },
        {
            "name": "Kunkka",
            "Centaur Warrunner": -0.166
        },
        {
            "name": "Legion Commander",
            "Centaur Warrunner": -0.409
        },
        {
            "name": "Leshrac",
            "Centaur Warrunner": 0.068
        },
        {
            "name": "Lich",
            "Centaur Warrunner": -0.905
        },
        {
            "name": "Lifestealer",
            "Centaur Warrunner": 4.957
        },
        {
            "name": "Lina",
            "Centaur Warrunner": -0.711
        },
        {
            "name": "Lion",
            "Centaur Warrunner": -0.701
        },
        {
            "name": "Lone Druid",
            "Centaur Warrunner": 1.252
        },
        {
            "name": "Luna",
            "Centaur Warrunner": -1.573
        },
        {
            "name": "Lycan",
            "Centaur Warrunner": 0.338
        },
        {
            "name": "Magnus",
            "Centaur Warrunner": 0.326
        },
        {
            "name": "Marci",
            "Centaur Warrunner": -0.908
        },
        {
            "name": "Mars",
            "Centaur Warrunner": 0.844
        },
        {
            "name": "Medusa",
            "Centaur Warrunner": -4.385
        },
        {
            "name": "Meepo",
            "Centaur Warrunner": 0.303
        },
        {
            "name": "Mirana",
            "Centaur Warrunner": -0.6
        },
        {
            "name": "Monkey King",
            "Centaur Warrunner": 1.032
        },
        {
            "name": "Morphling",
            "Centaur Warrunner": -1.109
        },
        {
            "name": "Muerta",
            "Centaur Warrunner": -1.482
        },
        {
            "name": "Naga Siren",
            "Centaur Warrunner": -1.76
        },
        {
            "name": "Nature's Prophet",
            "Centaur Warrunner": 0.53
        },
        {
            "name": "Necrophos",
            "Centaur Warrunner": 1.457
        },
        {
            "name": "Night Stalker",
            "Centaur Warrunner": 0.253
        },
        {
            "name": "Nyx Assassin",
            "Centaur Warrunner": -0.587
        },
        {
            "name": "Ogre Magi",
            "Centaur Warrunner": 0.154
        },
        {
            "name": "Omniknight",
            "Centaur Warrunner": 0.669
        },
        {
            "name": "Oracle",
            "Centaur Warrunner": 2.135
        },
        {
            "name": "Outworld Destroyer",
            "Centaur Warrunner": 0.344
        },
        {
            "name": "Pangolier",
            "Centaur Warrunner": 1.117
        },
        {
            "name": "Phantom Assassin",
            "Centaur Warrunner": -1.842
        },
        {
            "name": "Phantom Lancer",
            "Centaur Warrunner": -2.12
        },
        {
            "name": "Phoenix",
            "Centaur Warrunner": -0.276
        },
        {
            "name": "Primal Beast",
            "Centaur Warrunner": -1.287
        },
        {
            "name": "Puck",
            "Centaur Warrunner": -0.549
        },
        {
            "name": "Pudge",
            "Centaur Warrunner": -0.321
        },
        {
            "name": "Pugna",
            "Centaur Warrunner": -0.878
        },
        {
            "name": "Queen of Pain",
            "Centaur Warrunner": 0.802
        },
        {
            "name": "Razor",
            "Centaur Warrunner": -1.137
        },
        {
            "name": "Riki",
            "Centaur Warrunner": -0.565
        },
        {
            "name": "Ringmaster",
            "Centaur Warrunner": 0.217
        },
        {
            "name": "Rubick",
            "Centaur Warrunner": -0.623
        },
        {
            "name": "Sand King",
            "Centaur Warrunner": -2.009
        },
        {
            "name": "Shadow Demon",
            "Centaur Warrunner": -0.071
        },
        {
            "name": "Shadow Fiend",
            "Centaur Warrunner": -0.609
        },
        {
            "name": "Shadow Shaman",
            "Centaur Warrunner": -0.15
        },
        {
            "name": "Silencer",
            "Centaur Warrunner": 0.29
        },
        {
            "name": "Skywrath Mage",
            "Centaur Warrunner": -1.842
        },
        {
            "name": "Slardar",
            "Centaur Warrunner": 1.602
        },
        {
            "name": "Slark",
            "Centaur Warrunner": -0.295
        },
        {
            "name": "Snapfire",
            "Centaur Warrunner": -1.016
        },
        {
            "name": "Sniper",
            "Centaur Warrunner": -3.733
        },
        {
            "name": "Spectre",
            "Centaur Warrunner": -0.854
        },
        {
            "name": "Spirit Breaker",
            "Centaur Warrunner": -1.103
        },
        {
            "name": "Storm Spirit",
            "Centaur Warrunner": -0.644
        },
        {
            "name": "Sven",
            "Centaur Warrunner": 1.132
        },
        {
            "name": "Techies",
            "Centaur Warrunner": -1.306
        },
        {
            "name": "Templar Assassin",
            "Centaur Warrunner": 0.214
        },
        {
            "name": "Terrorblade",
            "Centaur Warrunner": -0.626
        },
        {
            "name": "Tidehunter",
            "Centaur Warrunner": 1.313
        },
        {
            "name": "Timbersaw",
            "Centaur Warrunner": 0.834
        },
        {
            "name": "Tinker",
            "Centaur Warrunner": 1.287
        },
        {
            "name": "Tiny",
            "Centaur Warrunner": 0.858
        },
        {
            "name": "Treant Protector",
            "Centaur Warrunner": 0.376
        },
        {
            "name": "Troll Warlord",
            "Centaur Warrunner": -0.432
        },
        {
            "name": "Tusk",
            "Centaur Warrunner": -0.133
        },
        {
            "name": "Underlord",
            "Centaur Warrunner": 1.944
        },
        {
            "name": "Undying",
            "Centaur Warrunner": 1.613
        },
        {
            "name": "Ursa",
            "Centaur Warrunner": 0.339
        },
        {
            "name": "Vengeful Spirit",
            "Centaur Warrunner": -0.61
        },
        {
            "name": "Venomancer",
            "Centaur Warrunner": 0.365
        },
        {
            "name": "Viper",
            "Centaur Warrunner": -1.497
        },
        {
            "name": "Visage",
            "Centaur Warrunner": -0.441
        },
        {
            "name": "Void Spirit",
            "Centaur Warrunner": 0.269
        },
        {
            "name": "Warlock",
            "Centaur Warrunner": 2.144
        },
        {
            "name": "Weaver",
            "Centaur Warrunner": 0.607
        },
        {
            "name": "Windranger",
            "Centaur Warrunner": -2.688
        },
        {
            "name": "Winter Wyvern",
            "Centaur Warrunner": -0.649
        },
        {
            "name": "Witch Doctor",
            "Centaur Warrunner": 1.947
        },
        {
            "name": "Wraith King",
            "Centaur Warrunner": 0.523
        },
        {
            "name": "Zeus",
            "Centaur Warrunner": -0.88
        }
    ],
    "Chaos Knight": [
        {
            "name": "Abaddon",
            "Chaos Knight": 0.121
        },
        {
            "name": "Alchemist",
            "Chaos Knight": 0.453
        },
        {
            "name": "Ancient Apparition",
            "Chaos Knight": -1.184
        },
        {
            "name": "Anti-Mage",
            "Chaos Knight": 2.354
        },
        {
            "name": "Arc Warden",
            "Chaos Knight": -2.034
        },
        {
            "name": "Axe",
            "Chaos Knight": 2.035
        },
        {
            "name": "Bane",
            "Chaos Knight": -2.276
        },
        {
            "name": "Batrider",
            "Chaos Knight": 0.1
        },
        {
            "name": "Beastmaster",
            "Chaos Knight": 1.698
        },
        {
            "name": "Bloodseeker",
            "Chaos Knight": -0.9
        },
        {
            "name": "Bounty Hunter",
            "Chaos Knight": -1.423
        },
        {
            "name": "Brewmaster",
            "Chaos Knight": 1.165
        },
        {
            "name": "Bristleback",
            "Chaos Knight": -0.388
        },
        {
            "name": "Broodmother",
            "Chaos Knight": -0.019
        },
        {
            "name": "Centaur Warrunner",
            "Chaos Knight": 1.167
        },
        {
            "name": "Chen",
            "Chaos Knight": -1.285
        },
        {
            "name": "Clinkz",
            "Chaos Knight": -3.411
        },
        {
            "name": "Clockwerk",
            "Chaos Knight": 0.415
        },
        {
            "name": "Crystal Maiden",
            "Chaos Knight": 1.712
        },
        {
            "name": "Dark Seer",
            "Chaos Knight": 3.84
        },
        {
            "name": "Dark Willow",
            "Chaos Knight": -0.474
        },
        {
            "name": "Dawnbreaker",
            "Chaos Knight": 1.552
        },
        {
            "name": "Dazzle",
            "Chaos Knight": 0.364
        },
        {
            "name": "Death Prophet",
            "Chaos Knight": -1.565
        },
        {
            "name": "Disruptor",
            "Chaos Knight": -0.655
        },
        {
            "name": "Doom",
            "Chaos Knight": -1.125
        },
        {
            "name": "Dragon Knight",
            "Chaos Knight": 1.337
        },
        {
            "name": "Drow Ranger",
            "Chaos Knight": -1.796
        },
        {
            "name": "Earth Spirit",
            "Chaos Knight": 0.648
        },
        {
            "name": "Earthshaker",
            "Chaos Knight": 1.425
        },
        {
            "name": "Elder Titan",
            "Chaos Knight": -0.661
        },
        {
            "name": "Ember Spirit",
            "Chaos Knight": 0.921
        },
        {
            "name": "Enchantress",
            "Chaos Knight": -2.047
        },
        {
            "name": "Enigma",
            "Chaos Knight": 0.711
        },
        {
            "name": "Faceless Void",
            "Chaos Knight": -2.294
        },
        {
            "name": "Grimstroke",
            "Chaos Knight": -0.773
        },
        {
            "name": "Gyrocopter",
            "Chaos Knight": 1.504
        },
        {
            "name": "Hoodwink",
            "Chaos Knight": 0.238
        },
        {
            "name": "Huskar",
            "Chaos Knight": -4.708
        },
        {
            "name": "Invoker",
            "Chaos Knight": 0.527
        },
        {
            "name": "Io",
            "Chaos Knight": -0.834
        },
        {
            "name": "Jakiro",
            "Chaos Knight": 2.549
        },
        {
            "name": "Juggernaut",
            "Chaos Knight": -0.126
        },
        {
            "name": "Keeper of the Light",
            "Chaos Knight": -0.547
        },
        {
            "name": "Kez",
            "Chaos Knight": -0.272
        },
        {
            "name": "Kunkka",
            "Chaos Knight": 1.39
        },
        {
            "name": "Legion Commander",
            "Chaos Knight": -2.747
        },
        {
            "name": "Leshrac",
            "Chaos Knight": 3.004
        },
        {
            "name": "Lich",
            "Chaos Knight": 2.232
        },
        {
            "name": "Lifestealer",
            "Chaos Knight": 0.207
        },
        {
            "name": "Lina",
            "Chaos Knight": 0.913
        },
        {
            "name": "Lion",
            "Chaos Knight": -0.148
        },
        {
            "name": "Lone Druid",
            "Chaos Knight": -1.294
        },
        {
            "name": "Luna",
            "Chaos Knight": 0.573
        },
        {
            "name": "Lycan",
            "Chaos Knight": -0.099
        },
        {
            "name": "Magnus",
            "Chaos Knight": 1.206
        },
        {
            "name": "Marci",
            "Chaos Knight": -0.007
        },
        {
            "name": "Mars",
            "Chaos Knight": -0.553
        },
        {
            "name": "Medusa",
            "Chaos Knight": 3.251
        },
        {
            "name": "Meepo",
            "Chaos Knight": 1.158
        },
        {
            "name": "Mirana",
            "Chaos Knight": -0.689
        },
        {
            "name": "Monkey King",
            "Chaos Knight": -0.017
        },
        {
            "name": "Morphling",
            "Chaos Knight": -1.294
        },
        {
            "name": "Muerta",
            "Chaos Knight": 0.425
        },
        {
            "name": "Naga Siren",
            "Chaos Knight": 3.73
        },
        {
            "name": "Nature's Prophet",
            "Chaos Knight": -2.165
        },
        {
            "name": "Necrophos",
            "Chaos Knight": 2.124
        },
        {
            "name": "Night Stalker",
            "Chaos Knight": -1.902
        },
        {
            "name": "Nyx Assassin",
            "Chaos Knight": -1.32
        },
        {
            "name": "Ogre Magi",
            "Chaos Knight": -3.63
        },
        {
            "name": "Omniknight",
            "Chaos Knight": -1.134
        },
        {
            "name": "Oracle",
            "Chaos Knight": -2.388
        },
        {
            "name": "Outworld Destroyer",
            "Chaos Knight": -2.851
        },
        {
            "name": "Pangolier",
            "Chaos Knight": 2.533
        },
        {
            "name": "Phantom Assassin",
            "Chaos Knight": 0.152
        },
        {
            "name": "Phantom Lancer",
            "Chaos Knight": 4.472
        },
        {
            "name": "Phoenix",
            "Chaos Knight": 2.891
        },
        {
            "name": "Primal Beast",
            "Chaos Knight": 0.377
        },
        {
            "name": "Puck",
            "Chaos Knight": 1.057
        },
        {
            "name": "Pudge",
            "Chaos Knight": -1.128
        },
        {
            "name": "Pugna",
            "Chaos Knight": -3.209
        },
        {
            "name": "Queen of Pain",
            "Chaos Knight": -0.732
        },
        {
            "name": "Razor",
            "Chaos Knight": -1.347
        },
        {
            "name": "Riki",
            "Chaos Knight": -0.148
        },
        {
            "name": "Ringmaster",
            "Chaos Knight": 1.618
        },
        {
            "name": "Rubick",
            "Chaos Knight": 0.096
        },
        {
            "name": "Sand King",
            "Chaos Knight": 4.873
        },
        {
            "name": "Shadow Demon",
            "Chaos Knight": -2.138
        },
        {
            "name": "Shadow Fiend",
            "Chaos Knight": -0.178
        },
        {
            "name": "Shadow Shaman",
            "Chaos Knight": -1.301
        },
        {
            "name": "Silencer",
            "Chaos Knight": -0.488
        },
        {
            "name": "Skywrath Mage",
            "Chaos Knight": -1.498
        },
        {
            "name": "Slardar",
            "Chaos Knight": -2.244
        },
        {
            "name": "Slark",
            "Chaos Knight": -0.471
        },
        {
            "name": "Snapfire",
            "Chaos Knight": 0.878
        },
        {
            "name": "Sniper",
            "Chaos Knight": -1.082
        },
        {
            "name": "Spectre",
            "Chaos Knight": -0.329
        },
        {
            "name": "Spirit Breaker",
            "Chaos Knight": -1.1
        },
        {
            "name": "Storm Spirit",
            "Chaos Knight": 0.117
        },
        {
            "name": "Sven",
            "Chaos Knight": 1.369
        },
        {
            "name": "Techies",
            "Chaos Knight": 0.291
        },
        {
            "name": "Templar Assassin",
            "Chaos Knight": -0.927
        },
        {
            "name": "Terrorblade",
            "Chaos Knight": 1.893
        },
        {
            "name": "Tidehunter",
            "Chaos Knight": 3.987
        },
        {
            "name": "Timbersaw",
            "Chaos Knight": 2.795
        },
        {
            "name": "Tinker",
            "Chaos Knight": -0.968
        },
        {
            "name": "Tiny",
            "Chaos Knight": 0.911
        },
        {
            "name": "Treant Protector",
            "Chaos Knight": 0.31
        },
        {
            "name": "Troll Warlord",
            "Chaos Knight": 0.792
        },
        {
            "name": "Tusk",
            "Chaos Knight": -0.047
        },
        {
            "name": "Underlord",
            "Chaos Knight": 2.537
        },
        {
            "name": "Undying",
            "Chaos Knight": -0.24
        },
        {
            "name": "Ursa",
            "Chaos Knight": -2.609
        },
        {
            "name": "Vengeful Spirit",
            "Chaos Knight": -2.382
        },
        {
            "name": "Venomancer",
            "Chaos Knight": 1.388
        },
        {
            "name": "Viper",
            "Chaos Knight": -3.61
        },
        {
            "name": "Visage",
            "Chaos Knight": -1.52
        },
        {
            "name": "Void Spirit",
            "Chaos Knight": -0.392
        },
        {
            "name": "Warlock",
            "Chaos Knight": 4.342
        },
        {
            "name": "Weaver",
            "Chaos Knight": -0.936
        },
        {
            "name": "Windranger",
            "Chaos Knight": -0.362
        },
        {
            "name": "Winter Wyvern",
            "Chaos Knight": 3.049
        },
        {
            "name": "Witch Doctor",
            "Chaos Knight": 1.188
        },
        {
            "name": "Wraith King",
            "Chaos Knight": -0.627
        },
        {
            "name": "Zeus",
            "Chaos Knight": -1.646
        }
    ],
    "Dawnbreaker": [
        {
            "name": "Abaddon",
            "Dawnbreaker": -0.319
        },
        {
            "name": "Alchemist",
            "Dawnbreaker": -0.007
        },
        {
            "name": "Ancient Apparition",
            "Dawnbreaker": 1.632
        },
        {
            "name": "Anti-Mage",
            "Dawnbreaker": -0.195
        },
        {
            "name": "Arc Warden",
            "Dawnbreaker": -1.685
        },
        {
            "name": "Axe",
            "Dawnbreaker": -0.524
        },
        {
            "name": "Bane",
            "Dawnbreaker": 0.225
        },
        {
            "name": "Batrider",
            "Dawnbreaker": 1.158
        },
        {
            "name": "Beastmaster",
            "Dawnbreaker": -2.144
        },
        {
            "name": "Bloodseeker",
            "Dawnbreaker": 1.451
        },
        {
            "name": "Bounty Hunter",
            "Dawnbreaker": 1.463
        },
        {
            "name": "Brewmaster",
            "Dawnbreaker": 0.117
        },
        {
            "name": "Bristleback",
            "Dawnbreaker": 2.366
        },
        {
            "name": "Broodmother",
            "Dawnbreaker": 0.883
        },
        {
            "name": "Centaur Warrunner",
            "Dawnbreaker": 0.376
        },
        {
            "name": "Chaos Knight",
            "Dawnbreaker": -1.484
        },
        {
            "name": "Chen",
            "Dawnbreaker": -0.027
        },
        {
            "name": "Clinkz",
            "Dawnbreaker": 0.575
        },
        {
            "name": "Clockwerk",
            "Dawnbreaker": 0.145
        },
        {
            "name": "Crystal Maiden",
            "Dawnbreaker": -1.712
        },
        {
            "name": "Dark Seer",
            "Dawnbreaker": -0.503
        },
        {
            "name": "Dark Willow",
            "Dawnbreaker": 2.249
        },
        {
            "name": "Dazzle",
            "Dawnbreaker": 1.164
        },
        {
            "name": "Death Prophet",
            "Dawnbreaker": 1.981
        },
        {
            "name": "Disruptor",
            "Dawnbreaker": 0.496
        },
        {
            "name": "Doom",
            "Dawnbreaker": 1.43
        },
        {
            "name": "Dragon Knight",
            "Dawnbreaker": 1.091
        },
        {
            "name": "Drow Ranger",
            "Dawnbreaker": 1.044
        },
        {
            "name": "Earth Spirit",
            "Dawnbreaker": -0.31
        },
        {
            "name": "Earthshaker",
            "Dawnbreaker": -0.419
        },
        {
            "name": "Elder Titan",
            "Dawnbreaker": 1.272
        },
        {
            "name": "Ember Spirit",
            "Dawnbreaker": 0.649
        },
        {
            "name": "Enchantress",
            "Dawnbreaker": -0.82
        },
        {
            "name": "Enigma",
            "Dawnbreaker": -1.675
        },
        {
            "name": "Faceless Void",
            "Dawnbreaker": 1.589
        },
        {
            "name": "Grimstroke",
            "Dawnbreaker": -0.058
        },
        {
            "name": "Gyrocopter",
            "Dawnbreaker": 0.161
        },
        {
            "name": "Hoodwink",
            "Dawnbreaker": -0.117
        },
        {
            "name": "Huskar",
            "Dawnbreaker": 1.173
        },
        {
            "name": "Invoker",
            "Dawnbreaker": 0.331
        },
        {
            "name": "Io",
            "Dawnbreaker": 0.805
        },
        {
            "name": "Jakiro",
            "Dawnbreaker": 1.194
        },
        {
            "name": "Juggernaut",
            "Dawnbreaker": 1.256
        },
        {
            "name": "Keeper of the Light",
            "Dawnbreaker": 1.844
        },
        {
            "name": "Kez",
            "Dawnbreaker": 0.837
        },
        {
            "name": "Kunkka",
            "Dawnbreaker": 1.096
        },
        {
            "name": "Legion Commander",
            "Dawnbreaker": -3.247
        },
        {
            "name": "Leshrac",
            "Dawnbreaker": -0.135
        },
        {
            "name": "Lich",
            "Dawnbreaker": 0.879
        },
        {
            "name": "Lifestealer",
            "Dawnbreaker": 0.945
        },
        {
            "name": "Lina",
            "Dawnbreaker": 1.507
        },
        {
            "name": "Lion",
            "Dawnbreaker": -0.623
        },
        {
            "name": "Lone Druid",
            "Dawnbreaker": 0.13
        },
        {
            "name": "Luna",
            "Dawnbreaker": -0.064
        },
        {
            "name": "Lycan",
            "Dawnbreaker": -0.203
        },
        {
            "name": "Magnus",
            "Dawnbreaker": -0.209
        },
        {
            "name": "Marci",
            "Dawnbreaker": -1.049
        },
        {
            "name": "Mars",
            "Dawnbreaker": -0.539
        },
        {
            "name": "Medusa",
            "Dawnbreaker": -0.299
        },
        {
            "name": "Meepo",
            "Dawnbreaker": -1.458
        },
        {
            "name": "Mirana",
            "Dawnbreaker": 0.191
        },
        {
            "name": "Monkey King",
            "Dawnbreaker": 1.446
        },
        {
            "name": "Morphling",
            "Dawnbreaker": 1.629
        },
        {
            "name": "Muerta",
            "Dawnbreaker": 0.891
        },
        {
            "name": "Naga Siren",
            "Dawnbreaker": -3.001
        },
        {
            "name": "Nature's Prophet",
            "Dawnbreaker": -1.896
        },
        {
            "name": "Necrophos",
            "Dawnbreaker": -0.439
        },
        {
            "name": "Night Stalker",
            "Dawnbreaker": 0.323
        },
        {
            "name": "Nyx Assassin",
            "Dawnbreaker": 1.901
        },
        {
            "name": "Ogre Magi",
            "Dawnbreaker": 0.43
        },
        {
            "name": "Omniknight",
            "Dawnbreaker": 0.396
        },
        {
            "name": "Oracle",
            "Dawnbreaker": -0.674
        },
        {
            "name": "Outworld Destroyer",
            "Dawnbreaker": 0.748
        },
        {
            "name": "Pangolier",
            "Dawnbreaker": 0.612
        },
        {
            "name": "Phantom Assassin",
            "Dawnbreaker": -2.222
        },
        {
            "name": "Phantom Lancer",
            "Dawnbreaker": -1.144
        },
        {
            "name": "Phoenix",
            "Dawnbreaker": 1.253
        },
        {
            "name": "Primal Beast",
            "Dawnbreaker": -0.02
        },
        {
            "name": "Puck",
            "Dawnbreaker": 0.245
        },
        {
            "name": "Pudge",
            "Dawnbreaker": -1.646
        },
        {
            "name": "Pugna",
            "Dawnbreaker": 0.868
        },
        {
            "name": "Queen of Pain",
            "Dawnbreaker": 0.448
        },
        {
            "name": "Razor",
            "Dawnbreaker": 0.258
        },
        {
            "name": "Riki",
            "Dawnbreaker": 0.88
        },
        {
            "name": "Ringmaster",
            "Dawnbreaker": 0.659
        },
        {
            "name": "Rubick",
            "Dawnbreaker": 0.403
        },
        {
            "name": "Sand King",
            "Dawnbreaker": -0.516
        },
        {
            "name": "Shadow Demon",
            "Dawnbreaker": -1.361
        },
        {
            "name": "Shadow Fiend",
            "Dawnbreaker": 0.435
        },
        {
            "name": "Shadow Shaman",
            "Dawnbreaker": -1.15
        },
        {
            "name": "Silencer",
            "Dawnbreaker": 2.869
        },
        {
            "name": "Skywrath Mage",
            "Dawnbreaker": -0.35
        },
        {
            "name": "Slardar",
            "Dawnbreaker": 1.848
        },
        {
            "name": "Slark",
            "Dawnbreaker": -2.001
        },
        {
            "name": "Snapfire",
            "Dawnbreaker": 0.607
        },
        {
            "name": "Sniper",
            "Dawnbreaker": 0.112
        },
        {
            "name": "Spectre",
            "Dawnbreaker": -2.402
        },
        {
            "name": "Spirit Breaker",
            "Dawnbreaker": -0.931
        },
        {
            "name": "Storm Spirit",
            "Dawnbreaker": -0.462
        },
        {
            "name": "Sven",
            "Dawnbreaker": 0.462
        },
        {
            "name": "Techies",
            "Dawnbreaker": -0.467
        },
        {
            "name": "Templar Assassin",
            "Dawnbreaker": 0.487
        },
        {
            "name": "Terrorblade",
            "Dawnbreaker": -0.289
        },
        {
            "name": "Tidehunter",
            "Dawnbreaker": -0.914
        },
        {
            "name": "Timbersaw",
            "Dawnbreaker": 2.041
        },
        {
            "name": "Tinker",
            "Dawnbreaker": 0.017
        },
        {
            "name": "Tiny",
            "Dawnbreaker": -0.329
        },
        {
            "name": "Treant Protector",
            "Dawnbreaker": -1.327
        },
        {
            "name": "Troll Warlord",
            "Dawnbreaker": 0.578
        },
        {
            "name": "Tusk",
            "Dawnbreaker": -1.01
        },
        {
            "name": "Underlord",
            "Dawnbreaker": 1.052
        },
        {
            "name": "Undying",
            "Dawnbreaker": -0.594
        },
        {
            "name": "Ursa",
            "Dawnbreaker": 1.54
        },
        {
            "name": "Vengeful Spirit",
            "Dawnbreaker": -0.502
        },
        {
            "name": "Venomancer",
            "Dawnbreaker": 0.557
        },
        {
            "name": "Viper",
            "Dawnbreaker": 0.748
        },
        {
            "name": "Visage",
            "Dawnbreaker": 0.362
        },
        {
            "name": "Void Spirit",
            "Dawnbreaker": -0.174
        },
        {
            "name": "Warlock",
            "Dawnbreaker": -0.095
        },
        {
            "name": "Weaver",
            "Dawnbreaker": 1.248
        },
        {
            "name": "Windranger",
            "Dawnbreaker": 0.007
        },
        {
            "name": "Winter Wyvern",
            "Dawnbreaker": 1.564
        },
        {
            "name": "Witch Doctor",
            "Dawnbreaker": 0.613
        },
        {
            "name": "Wraith King",
            "Dawnbreaker": -1.404
        },
        {
            "name": "Zeus",
            "Dawnbreaker": -0.209
        }
    ],
    "Doom": [
        {
            "name": "Abaddon",
            "Doom": -2.341
        },
        {
            "name": "Alchemist",
            "Doom": -0.677
        },
        {
            "name": "Ancient Apparition",
            "Doom": -0.382
        },
        {
            "name": "Anti-Mage",
            "Doom": 1.943
        },
        {
            "name": "Arc Warden",
            "Doom": 1.955
        },
        {
            "name": "Axe",
            "Doom": -1.57
        },
        {
            "name": "Bane",
            "Doom": -0.626
        },
        {
            "name": "Batrider",
            "Doom": 1.785
        },
        {
            "name": "Beastmaster",
            "Doom": -0.245
        },
        {
            "name": "Bloodseeker",
            "Doom": -1.267
        },
        {
            "name": "Bounty Hunter",
            "Doom": -0.075
        },
        {
            "name": "Brewmaster",
            "Doom": -1.171
        },
        {
            "name": "Bristleback",
            "Doom": 2.162
        },
        {
            "name": "Broodmother",
            "Doom": 0.976
        },
        {
            "name": "Centaur Warrunner",
            "Doom": -0.657
        },
        {
            "name": "Chaos Knight",
            "Doom": 1.152
        },
        {
            "name": "Chen",
            "Doom": -2.361
        },
        {
            "name": "Clinkz",
            "Doom": 0.12
        },
        {
            "name": "Clockwerk",
            "Doom": -0.355
        },
        {
            "name": "Crystal Maiden",
            "Doom": 1.204
        },
        {
            "name": "Dark Seer",
            "Doom": -1.884
        },
        {
            "name": "Dark Willow",
            "Doom": 0.851
        },
        {
            "name": "Dawnbreaker",
            "Doom": -1.286
        },
        {
            "name": "Dazzle",
            "Doom": -0.74
        },
        {
            "name": "Death Prophet",
            "Doom": -0.282
        },
        {
            "name": "Disruptor",
            "Doom": -0.147
        },
        {
            "name": "Dragon Knight",
            "Doom": -0.675
        },
        {
            "name": "Drow Ranger",
            "Doom": 1.531
        },
        {
            "name": "Earth Spirit",
            "Doom": -0.116
        },
        {
            "name": "Earthshaker",
            "Doom": 0.029
        },
        {
            "name": "Elder Titan",
            "Doom": -0.341
        },
        {
            "name": "Ember Spirit",
            "Doom": -0.988
        },
        {
            "name": "Enchantress",
            "Doom": -0.44
        },
        {
            "name": "Enigma",
            "Doom": 2.446
        },
        {
            "name": "Faceless Void",
            "Doom": -0.327
        },
        {
            "name": "Grimstroke",
            "Doom": 0.667
        },
        {
            "name": "Gyrocopter",
            "Doom": -0.151
        },
        {
            "name": "Hoodwink",
            "Doom": 0.295
        },
        {
            "name": "Huskar",
            "Doom": -2.214
        },
        {
            "name": "Invoker",
            "Doom": 1.042
        },
        {
            "name": "Io",
            "Doom": -2.421
        },
        {
            "name": "Jakiro",
            "Doom": 0.772
        },
        {
            "name": "Juggernaut",
            "Doom": -1.555
        },
        {
            "name": "Keeper of the Light",
            "Doom": 0.815
        },
        {
            "name": "Kez",
            "Doom": 0.581
        },
        {
            "name": "Kunkka",
            "Doom": -0.344
        },
        {
            "name": "Legion Commander",
            "Doom": -0.035
        },
        {
            "name": "Leshrac",
            "Doom": 1.03
        },
        {
            "name": "Lich",
            "Doom": 0.288
        },
        {
            "name": "Lifestealer",
            "Doom": -2.262
        },
        {
            "name": "Lina",
            "Doom": 1.262
        },
        {
            "name": "Lion",
            "Doom": -0.072
        },
        {
            "name": "Lone Druid",
            "Doom": 2.028
        },
        {
            "name": "Luna",
            "Doom": 1.266
        },
        {
            "name": "Lycan",
            "Doom": 0.652
        },
        {
            "name": "Magnus",
            "Doom": 0.186
        },
        {
            "name": "Marci",
            "Doom": 1.384
        },
        {
            "name": "Mars",
            "Doom": -0.584
        },
        {
            "name": "Medusa",
            "Doom": 2.013
        },
        {
            "name": "Meepo",
            "Doom": 2.439
        },
        {
            "name": "Mirana",
            "Doom": -0.076
        },
        {
            "name": "Monkey King",
            "Doom": -0.321
        },
        {
            "name": "Morphling",
            "Doom": -2.964
        },
        {
            "name": "Muerta",
            "Doom": -1.044
        },
        {
            "name": "Naga Siren",
            "Doom": 0.192
        },
        {
            "name": "Nature's Prophet",
            "Doom": 1.08
        },
        {
            "name": "Necrophos",
            "Doom": -3.626
        },
        {
            "name": "Night Stalker",
            "Doom": 0.99
        },
        {
            "name": "Nyx Assassin",
            "Doom": -1.369
        },
        {
            "name": "Ogre Magi",
            "Doom": 0.001
        },
        {
            "name": "Omniknight",
            "Doom": -4.041
        },
        {
            "name": "Oracle",
            "Doom": -1.06
        },
        {
            "name": "Outworld Destroyer",
            "Doom": -0.054
        },
        {
            "name": "Pangolier",
            "Doom": -0.814
        },
        {
            "name": "Phantom Assassin",
            "Doom": -0.61
        },
        {
            "name": "Phantom Lancer",
            "Doom": -0.774
        },
        {
            "name": "Phoenix",
            "Doom": -1.367
        },
        {
            "name": "Primal Beast",
            "Doom": -0.662
        },
        {
            "name": "Puck",
            "Doom": 0.686
        },
        {
            "name": "Pudge",
            "Doom": -0.745
        },
        {
            "name": "Pugna",
            "Doom": -1.52
        },
        {
            "name": "Queen of Pain",
            "Doom": -0.033
        },
        {
            "name": "Razor",
            "Doom": 0.605
        },
        {
            "name": "Riki",
            "Doom": -0.051
        },
        {
            "name": "Ringmaster",
            "Doom": -1.325
        },
        {
            "name": "Rubick",
            "Doom": 1.377
        },
        {
            "name": "Sand King",
            "Doom": -0.355
        },
        {
            "name": "Shadow Demon",
            "Doom": -0.67
        },
        {
            "name": "Shadow Fiend",
            "Doom": 0.829
        },
        {
            "name": "Shadow Shaman",
            "Doom": -0.198
        },
        {
            "name": "Silencer",
            "Doom": 0.33
        },
        {
            "name": "Skywrath Mage",
            "Doom": 0.69
        },
        {
            "name": "Slardar",
            "Doom": -1.513
        },
        {
            "name": "Slark",
            "Doom": -1.108
        },
        {
            "name": "Snapfire",
            "Doom": -0.831
        },
        {
            "name": "Sniper",
            "Doom": 0.255
        },
        {
            "name": "Spectre",
            "Doom": 1.484
        },
        {
            "name": "Spirit Breaker",
            "Doom": 0.064
        },
        {
            "name": "Storm Spirit",
            "Doom": 0.241
        },
        {
            "name": "Sven",
            "Doom": -1.13
        },
        {
            "name": "Techies",
            "Doom": 0.239
        },
        {
            "name": "Templar Assassin",
            "Doom": 0.18
        },
        {
            "name": "Terrorblade",
            "Doom": 0.378
        },
        {
            "name": "Tidehunter",
            "Doom": -0.931
        },
        {
            "name": "Timbersaw",
            "Doom": -2.126
        },
        {
            "name": "Tinker",
            "Doom": 0.115
        },
        {
            "name": "Tiny",
            "Doom": -1.225
        },
        {
            "name": "Treant Protector",
            "Doom": -1.016
        },
        {
            "name": "Troll Warlord",
            "Doom": -1.119
        },
        {
            "name": "Tusk",
            "Doom": 0.393
        },
        {
            "name": "Underlord",
            "Doom": -1.512
        },
        {
            "name": "Undying",
            "Doom": -0.23
        },
        {
            "name": "Ursa",
            "Doom": -0.219
        },
        {
            "name": "Vengeful Spirit",
            "Doom": 0.43
        },
        {
            "name": "Venomancer",
            "Doom": 0.992
        },
        {
            "name": "Viper",
            "Doom": 0.908
        },
        {
            "name": "Visage",
            "Doom": 0.995
        },
        {
            "name": "Void Spirit",
            "Doom": -1.102
        },
        {
            "name": "Warlock",
            "Doom": -0.161
        },
        {
            "name": "Weaver",
            "Doom": 0.512
        },
        {
            "name": "Windranger",
            "Doom": 0.005
        },
        {
            "name": "Winter Wyvern",
            "Doom": -0.63
        },
        {
            "name": "Witch Doctor",
            "Doom": 0.463
        },
        {
            "name": "Wraith King",
            "Doom": 1.411
        },
        {
            "name": "Zeus",
            "Doom": -0.189
        }
    ],
    "Dragon Knight": [
        {
            "name": "Abaddon",
            "Dragon Knight": -0.056
        },
        {
            "name": "Alchemist",
            "Dragon Knight": 0.621
        },
        {
            "name": "Ancient Apparition",
            "Dragon Knight": 1.683
        },
        {
            "name": "Anti-Mage",
            "Dragon Knight": 0.084
        },
        {
            "name": "Arc Warden",
            "Dragon Knight": 0.723
        },
        {
            "name": "Axe",
            "Dragon Knight": -1.517
        },
        {
            "name": "Bane",
            "Dragon Knight": 1.03
        },
        {
            "name": "Batrider",
            "Dragon Knight": 1.84
        },
        {
            "name": "Beastmaster",
            "Dragon Knight": 1.187
        },
        {
            "name": "Bloodseeker",
            "Dragon Knight": -0.701
        },
        {
            "name": "Bounty Hunter",
            "Dragon Knight": 0.526
        },
        {
            "name": "Brewmaster",
            "Dragon Knight": 1.282
        },
        {
            "name": "Bristleback",
            "Dragon Knight": -0.702
        },
        {
            "name": "Broodmother",
            "Dragon Knight": -0.544
        },
        {
            "name": "Centaur Warrunner",
            "Dragon Knight": -0.42
        },
        {
            "name": "Chaos Knight",
            "Dragon Knight": -0.794
        },
        {
            "name": "Chen",
            "Dragon Knight": 0.819
        },
        {
            "name": "Clinkz",
            "Dragon Knight": 0.187
        },
        {
            "name": "Clockwerk",
            "Dragon Knight": 0.114
        },
        {
            "name": "Crystal Maiden",
            "Dragon Knight": 0.781
        },
        {
            "name": "Dark Seer",
            "Dragon Knight": 0.614
        },
        {
            "name": "Dark Willow",
            "Dragon Knight": 0.389
        },
        {
            "name": "Dawnbreaker",
            "Dragon Knight": -0.898
        },
        {
            "name": "Dazzle",
            "Dragon Knight": 0.758
        },
        {
            "name": "Death Prophet",
            "Dragon Knight": -0.733
        },
        {
            "name": "Disruptor",
            "Dragon Knight": -0.822
        },
        {
            "name": "Doom",
            "Dragon Knight": 1.41
        },
        {
            "name": "Drow Ranger",
            "Dragon Knight": -0.95
        },
        {
            "name": "Earth Spirit",
            "Dragon Knight": 0.894
        },
        {
            "name": "Earthshaker",
            "Dragon Knight": -1.173
        },
        {
            "name": "Elder Titan",
            "Dragon Knight": -1.022
        },
        {
            "name": "Ember Spirit",
            "Dragon Knight": -1.288
        },
        {
            "name": "Enchantress",
            "Dragon Knight": 0.989
        },
        {
            "name": "Enigma",
            "Dragon Knight": 0.634
        },
        {
            "name": "Faceless Void",
            "Dragon Knight": 0.897
        },
        {
            "name": "Grimstroke",
            "Dragon Knight": -0.354
        },
        {
            "name": "Gyrocopter",
            "Dragon Knight": 0.345
        },
        {
            "name": "Hoodwink",
            "Dragon Knight": 0.385
        },
        {
            "name": "Huskar",
            "Dragon Knight": 1.718
        },
        {
            "name": "Invoker",
            "Dragon Knight": 0.876
        },
        {
            "name": "Io",
            "Dragon Knight": -1.196
        },
        {
            "name": "Jakiro",
            "Dragon Knight": 0.955
        },
        {
            "name": "Juggernaut",
            "Dragon Knight": -0.062
        },
        {
            "name": "Keeper of the Light",
            "Dragon Knight": 1.282
        },
        {
            "name": "Kez",
            "Dragon Knight": -0.073
        },
        {
            "name": "Kunkka",
            "Dragon Knight": 0.643
        },
        {
            "name": "Legion Commander",
            "Dragon Knight": -1.772
        },
        {
            "name": "Leshrac",
            "Dragon Knight": 0.004
        },
        {
            "name": "Lich",
            "Dragon Knight": 0.605
        },
        {
            "name": "Lifestealer",
            "Dragon Knight": 1.306
        },
        {
            "name": "Lina",
            "Dragon Knight": -0.026
        },
        {
            "name": "Lion",
            "Dragon Knight": -0.356
        },
        {
            "name": "Lone Druid",
            "Dragon Knight": 0.163
        },
        {
            "name": "Luna",
            "Dragon Knight": -0.098
        },
        {
            "name": "Lycan",
            "Dragon Knight": -0.536
        },
        {
            "name": "Magnus",
            "Dragon Knight": 0.072
        },
        {
            "name": "Marci",
            "Dragon Knight": -0.426
        },
        {
            "name": "Mars",
            "Dragon Knight": 0.643
        },
        {
            "name": "Medusa",
            "Dragon Knight": -0.915
        },
        {
            "name": "Meepo",
            "Dragon Knight": -0.887
        },
        {
            "name": "Mirana",
            "Dragon Knight": 0.337
        },
        {
            "name": "Monkey King",
            "Dragon Knight": 0.656
        },
        {
            "name": "Morphling",
            "Dragon Knight": -0.726
        },
        {
            "name": "Muerta",
            "Dragon Knight": 0.15
        },
        {
            "name": "Naga Siren",
            "Dragon Knight": -1.439
        },
        {
            "name": "Nature's Prophet",
            "Dragon Knight": 0.481
        },
        {
            "name": "Necrophos",
            "Dragon Knight": 1.787
        },
        {
            "name": "Night Stalker",
            "Dragon Knight": -1.444
        },
        {
            "name": "Nyx Assassin",
            "Dragon Knight": -1.19
        },
        {
            "name": "Ogre Magi",
            "Dragon Knight": 1.146
        },
        {
            "name": "Omniknight",
            "Dragon Knight": -0.504
        },
        {
            "name": "Oracle",
            "Dragon Knight": -0.713
        },
        {
            "name": "Outworld Destroyer",
            "Dragon Knight": 0.899
        },
        {
            "name": "Pangolier",
            "Dragon Knight": -0.229
        },
        {
            "name": "Phantom Assassin",
            "Dragon Knight": 0.073
        },
        {
            "name": "Phantom Lancer",
            "Dragon Knight": -1.576
        },
        {
            "name": "Phoenix",
            "Dragon Knight": 2.021
        },
        {
            "name": "Primal Beast",
            "Dragon Knight": 0.376
        },
        {
            "name": "Puck",
            "Dragon Knight": -1.856
        },
        {
            "name": "Pudge",
            "Dragon Knight": -0.162
        },
        {
            "name": "Pugna",
            "Dragon Knight": 0.54
        },
        {
            "name": "Queen of Pain",
            "Dragon Knight": -0.134
        },
        {
            "name": "Razor",
            "Dragon Knight": 0.919
        },
        {
            "name": "Riki",
            "Dragon Knight": -1.005
        },
        {
            "name": "Ringmaster",
            "Dragon Knight": 0.84
        },
        {
            "name": "Rubick",
            "Dragon Knight": 0.674
        },
        {
            "name": "Sand King",
            "Dragon Knight": 1.129
        },
        {
            "name": "Shadow Demon",
            "Dragon Knight": 0.53
        },
        {
            "name": "Shadow Fiend",
            "Dragon Knight": -0.268
        },
        {
            "name": "Shadow Shaman",
            "Dragon Knight": -0.365
        },
        {
            "name": "Silencer",
            "Dragon Knight": -0.373
        },
        {
            "name": "Skywrath Mage",
            "Dragon Knight": -0.858
        },
        {
            "name": "Slardar",
            "Dragon Knight": -0.202
        },
        {
            "name": "Slark",
            "Dragon Knight": 2.006
        },
        {
            "name": "Snapfire",
            "Dragon Knight": 0.622
        },
        {
            "name": "Sniper",
            "Dragon Knight": 0.898
        },
        {
            "name": "Spectre",
            "Dragon Knight": -0.218
        },
        {
            "name": "Spirit Breaker",
            "Dragon Knight": -0.048
        },
        {
            "name": "Storm Spirit",
            "Dragon Knight": 0.081
        },
        {
            "name": "Sven",
            "Dragon Knight": -0.624
        },
        {
            "name": "Techies",
            "Dragon Knight": 0.376
        },
        {
            "name": "Templar Assassin",
            "Dragon Knight": -0.761
        },
        {
            "name": "Terrorblade",
            "Dragon Knight": 1.229
        },
        {
            "name": "Tidehunter",
            "Dragon Knight": 0.082
        },
        {
            "name": "Timbersaw",
            "Dragon Knight": 1.14
        },
        {
            "name": "Tinker",
            "Dragon Knight": 1.702
        },
        {
            "name": "Tiny",
            "Dragon Knight": 0.066
        },
        {
            "name": "Treant Protector",
            "Dragon Knight": 1.365
        },
        {
            "name": "Troll Warlord",
            "Dragon Knight": 0.475
        },
        {
            "name": "Tusk",
            "Dragon Knight": 0.046
        },
        {
            "name": "Underlord",
            "Dragon Knight": 1.479
        },
        {
            "name": "Undying",
            "Dragon Knight": 0.673
        },
        {
            "name": "Ursa",
            "Dragon Knight": 0.683
        },
        {
            "name": "Vengeful Spirit",
            "Dragon Knight": 0.296
        },
        {
            "name": "Venomancer",
            "Dragon Knight": 2.086
        },
        {
            "name": "Viper",
            "Dragon Knight": 4.206
        },
        {
            "name": "Visage",
            "Dragon Knight": 0.25
        },
        {
            "name": "Void Spirit",
            "Dragon Knight": -1.835
        },
        {
            "name": "Warlock",
            "Dragon Knight": -0.166
        },
        {
            "name": "Weaver",
            "Dragon Knight": -0.153
        },
        {
            "name": "Windranger",
            "Dragon Knight": 0.04
        },
        {
            "name": "Winter Wyvern",
            "Dragon Knight": 0.519
        },
        {
            "name": "Witch Doctor",
            "Dragon Knight": 1.665
        },
        {
            "name": "Wraith King",
            "Dragon Knight": -0.739
        },
        {
            "name": "Zeus",
            "Dragon Knight": -0.205
        }
    ],
    "Earth Spirit": [
        {
            "name": "Abaddon",
            "Earth Spirit": 1.37
        },
        {
            "name": "Alchemist",
            "Earth Spirit": 0.263
        },
        {
            "name": "Ancient Apparition",
            "Earth Spirit": 0.015
        },
        {
            "name": "Anti-Mage",
            "Earth Spirit": 0.554
        },
        {
            "name": "Arc Warden",
            "Earth Spirit": -0.47
        },
        {
            "name": "Axe",
            "Earth Spirit": -1.234
        },
        {
            "name": "Bane",
            "Earth Spirit": -0.08
        },
        {
            "name": "Batrider",
            "Earth Spirit": -2.117
        },
        {
            "name": "Beastmaster",
            "Earth Spirit": -0.337
        },
        {
            "name": "Bloodseeker",
            "Earth Spirit": 0.775
        },
        {
            "name": "Bounty Hunter",
            "Earth Spirit": 0.407
        },
        {
            "name": "Brewmaster",
            "Earth Spirit": -2.306
        },
        {
            "name": "Bristleback",
            "Earth Spirit": -0.885
        },
        {
            "name": "Broodmother",
            "Earth Spirit": 0.697
        },
        {
            "name": "Centaur Warrunner",
            "Earth Spirit": -0.802
        },
        {
            "name": "Chaos Knight",
            "Earth Spirit": -0.644
        },
        {
            "name": "Chen",
            "Earth Spirit": 0.314
        },
        {
            "name": "Clinkz",
            "Earth Spirit": -0.227
        },
        {
            "name": "Clockwerk",
            "Earth Spirit": -0.939
        },
        {
            "name": "Crystal Maiden",
            "Earth Spirit": -0.072
        },
        {
            "name": "Dark Seer",
            "Earth Spirit": 1.313
        },
        {
            "name": "Dark Willow",
            "Earth Spirit": -0.295
        },
        {
            "name": "Dawnbreaker",
            "Earth Spirit": 0.525
        },
        {
            "name": "Dazzle",
            "Earth Spirit": -0.637
        },
        {
            "name": "Death Prophet",
            "Earth Spirit": 1.132
        },
        {
            "name": "Disruptor",
            "Earth Spirit": -0.827
        },
        {
            "name": "Doom",
            "Earth Spirit": 0.134
        },
        {
            "name": "Dragon Knight",
            "Earth Spirit": -0.22
        },
        {
            "name": "Drow Ranger",
            "Earth Spirit": -3.188
        },
        {
            "name": "Earthshaker",
            "Earth Spirit": 0.507
        },
        {
            "name": "Elder Titan",
            "Earth Spirit": 3.621
        },
        {
            "name": "Ember Spirit",
            "Earth Spirit": 0.96
        },
        {
            "name": "Enchantress",
            "Earth Spirit": -0.776
        },
        {
            "name": "Enigma",
            "Earth Spirit": 1.18
        },
        {
            "name": "Faceless Void",
            "Earth Spirit": -0.338
        },
        {
            "name": "Grimstroke",
            "Earth Spirit": 0.326
        },
        {
            "name": "Gyrocopter",
            "Earth Spirit": 0.041
        },
        {
            "name": "Hoodwink",
            "Earth Spirit": -0.607
        },
        {
            "name": "Huskar",
            "Earth Spirit": 2.817
        },
        {
            "name": "Invoker",
            "Earth Spirit": -1.31
        },
        {
            "name": "Io",
            "Earth Spirit": 1.3
        },
        {
            "name": "Jakiro",
            "Earth Spirit": 0.039
        },
        {
            "name": "Juggernaut",
            "Earth Spirit": 2.179
        },
        {
            "name": "Keeper of the Light",
            "Earth Spirit": 1.529
        },
        {
            "name": "Kez",
            "Earth Spirit": 2.74
        },
        {
            "name": "Kunkka",
            "Earth Spirit": 0.143
        },
        {
            "name": "Legion Commander",
            "Earth Spirit": 1.428
        },
        {
            "name": "Leshrac",
            "Earth Spirit": 1.298
        },
        {
            "name": "Lich",
            "Earth Spirit": -0.899
        },
        {
            "name": "Lifestealer",
            "Earth Spirit": 2.813
        },
        {
            "name": "Lina",
            "Earth Spirit": 0.823
        },
        {
            "name": "Lion",
            "Earth Spirit": 0.498
        },
        {
            "name": "Lone Druid",
            "Earth Spirit": 3.376
        },
        {
            "name": "Luna",
            "Earth Spirit": 1.077
        },
        {
            "name": "Lycan",
            "Earth Spirit": 0.053
        },
        {
            "name": "Magnus",
            "Earth Spirit": 0.352
        },
        {
            "name": "Marci",
            "Earth Spirit": 1.931
        },
        {
            "name": "Mars",
            "Earth Spirit": -1.584
        },
        {
            "name": "Medusa",
            "Earth Spirit": -0.235
        },
        {
            "name": "Meepo",
            "Earth Spirit": 3.569
        },
        {
            "name": "Mirana",
            "Earth Spirit": -1.191
        },
        {
            "name": "Monkey King",
            "Earth Spirit": 0.961
        },
        {
            "name": "Morphling",
            "Earth Spirit": 0.147
        },
        {
            "name": "Muerta",
            "Earth Spirit": 0.317
        },
        {
            "name": "Naga Siren",
            "Earth Spirit": 1.562
        },
        {
            "name": "Nature's Prophet",
            "Earth Spirit": -1.327
        },
        {
            "name": "Necrophos",
            "Earth Spirit": 0.406
        },
        {
            "name": "Night Stalker",
            "Earth Spirit": -0.346
        },
        {
            "name": "Nyx Assassin",
            "Earth Spirit": -0.785
        },
        {
            "name": "Ogre Magi",
            "Earth Spirit": -0.416
        },
        {
            "name": "Omniknight",
            "Earth Spirit": 0.204
        },
        {
            "name": "Oracle",
            "Earth Spirit": 0.411
        },
        {
            "name": "Outworld Destroyer",
            "Earth Spirit": 0.747
        },
        {
            "name": "Pangolier",
            "Earth Spirit": 0.284
        },
        {
            "name": "Phantom Assassin",
            "Earth Spirit": -2.224
        },
        {
            "name": "Phantom Lancer",
            "Earth Spirit": -1.807
        },
        {
            "name": "Phoenix",
            "Earth Spirit": -0.856
        },
        {
            "name": "Primal Beast",
            "Earth Spirit": 1.059
        },
        {
            "name": "Puck",
            "Earth Spirit": -0.385
        },
        {
            "name": "Pudge",
            "Earth Spirit": -0.654
        },
        {
            "name": "Pugna",
            "Earth Spirit": 0.243
        },
        {
            "name": "Queen of Pain",
            "Earth Spirit": -0.943
        },
        {
            "name": "Razor",
            "Earth Spirit": -2.047
        },
        {
            "name": "Riki",
            "Earth Spirit": -0.321
        },
        {
            "name": "Ringmaster",
            "Earth Spirit": -0.955
        },
        {
            "name": "Rubick",
            "Earth Spirit": -2.086
        },
        {
            "name": "Sand King",
            "Earth Spirit": -1.73
        },
        {
            "name": "Shadow Demon",
            "Earth Spirit": -0.742
        },
        {
            "name": "Shadow Fiend",
            "Earth Spirit": -0.133
        },
        {
            "name": "Shadow Shaman",
            "Earth Spirit": 1.07
        },
        {
            "name": "Silencer",
            "Earth Spirit": 1.039
        },
        {
            "name": "Skywrath Mage",
            "Earth Spirit": 0.886
        },
        {
            "name": "Slardar",
            "Earth Spirit": 0.187
        },
        {
            "name": "Slark",
            "Earth Spirit": 0.928
        },
        {
            "name": "Snapfire",
            "Earth Spirit": -1.451
        },
        {
            "name": "Sniper",
            "Earth Spirit": -2.624
        },
        {
            "name": "Spectre",
            "Earth Spirit": -0.493
        },
        {
            "name": "Spirit Breaker",
            "Earth Spirit": 0.417
        },
        {
            "name": "Storm Spirit",
            "Earth Spirit": -0.021
        },
        {
            "name": "Sven",
            "Earth Spirit": 0.446
        },
        {
            "name": "Techies",
            "Earth Spirit": 0.328
        },
        {
            "name": "Templar Assassin",
            "Earth Spirit": -0.602
        },
        {
            "name": "Terrorblade",
            "Earth Spirit": -0.793
        },
        {
            "name": "Tidehunter",
            "Earth Spirit": 2.075
        },
        {
            "name": "Timbersaw",
            "Earth Spirit": -0.666
        },
        {
            "name": "Tinker",
            "Earth Spirit": 0.404
        },
        {
            "name": "Tiny",
            "Earth Spirit": -0.222
        },
        {
            "name": "Treant Protector",
            "Earth Spirit": -0.592
        },
        {
            "name": "Troll Warlord",
            "Earth Spirit": 2.3
        },
        {
            "name": "Tusk",
            "Earth Spirit": 0.406
        },
        {
            "name": "Underlord",
            "Earth Spirit": -1.444
        },
        {
            "name": "Undying",
            "Earth Spirit": -0.123
        },
        {
            "name": "Ursa",
            "Earth Spirit": 1.637
        },
        {
            "name": "Vengeful Spirit",
            "Earth Spirit": -0.243
        },
        {
            "name": "Venomancer",
            "Earth Spirit": -0.934
        },
        {
            "name": "Viper",
            "Earth Spirit": -0.155
        },
        {
            "name": "Visage",
            "Earth Spirit": -1.092
        },
        {
            "name": "Void Spirit",
            "Earth Spirit": 0.176
        },
        {
            "name": "Warlock",
            "Earth Spirit": 0.545
        },
        {
            "name": "Weaver",
            "Earth Spirit": 1.073
        },
        {
            "name": "Windranger",
            "Earth Spirit": -1.36
        },
        {
            "name": "Winter Wyvern",
            "Earth Spirit": -1.32
        },
        {
            "name": "Witch Doctor",
            "Earth Spirit": 1.535
        },
        {
            "name": "Wraith King",
            "Earth Spirit": 1.096
        },
        {
            "name": "Zeus",
            "Earth Spirit": 0.327
        }
    ],
    "Earthshaker": [
        {
            "name": "Abaddon",
            "Earthshaker": -2.508
        },
        {
            "name": "Alchemist",
            "Earthshaker": 0.712
        },
        {
            "name": "Ancient Apparition",
            "Earthshaker": -0.333
        },
        {
            "name": "Anti-Mage",
            "Earthshaker": -2.497
        },
        {
            "name": "Arc Warden",
            "Earthshaker": -0.625
        },
        {
            "name": "Axe",
            "Earthshaker": 0.503
        },
        {
            "name": "Bane",
            "Earthshaker": -0.337
        },
        {
            "name": "Batrider",
            "Earthshaker": 1.353
        },
        {
            "name": "Beastmaster",
            "Earthshaker": -1.775
        },
        {
            "name": "Bloodseeker",
            "Earthshaker": 0.224
        },
        {
            "name": "Bounty Hunter",
            "Earthshaker": -0.371
        },
        {
            "name": "Brewmaster",
            "Earthshaker": -1.244
        },
        {
            "name": "Bristleback",
            "Earthshaker": 1.26
        },
        {
            "name": "Broodmother",
            "Earthshaker": -6.811
        },
        {
            "name": "Centaur Warrunner",
            "Earthshaker": -0.245
        },
        {
            "name": "Chaos Knight",
            "Earthshaker": -1.291
        },
        {
            "name": "Chen",
            "Earthshaker": -0.915
        },
        {
            "name": "Clinkz",
            "Earthshaker": 1.618
        },
        {
            "name": "Clockwerk",
            "Earthshaker": 1.244
        },
        {
            "name": "Crystal Maiden",
            "Earthshaker": -0.601
        },
        {
            "name": "Dark Seer",
            "Earthshaker": -2.593
        },
        {
            "name": "Dark Willow",
            "Earthshaker": 0.324
        },
        {
            "name": "Dawnbreaker",
            "Earthshaker": 0.193
        },
        {
            "name": "Dazzle",
            "Earthshaker": 0.01
        },
        {
            "name": "Death Prophet",
            "Earthshaker": 1.094
        },
        {
            "name": "Disruptor",
            "Earthshaker": 0.708
        },
        {
            "name": "Doom",
            "Earthshaker": 0.355
        },
        {
            "name": "Dragon Knight",
            "Earthshaker": 1.201
        },
        {
            "name": "Drow Ranger",
            "Earthshaker": 0.764
        },
        {
            "name": "Earth Spirit",
            "Earthshaker": -0.195
        },
        {
            "name": "Elder Titan",
            "Earthshaker": -0.364
        },
        {
            "name": "Ember Spirit",
            "Earthshaker": 0.244
        },
        {
            "name": "Enchantress",
            "Earthshaker": 1.272
        },
        {
            "name": "Enigma",
            "Earthshaker": -0.558
        },
        {
            "name": "Faceless Void",
            "Earthshaker": -0.149
        },
        {
            "name": "Grimstroke",
            "Earthshaker": 0.739
        },
        {
            "name": "Gyrocopter",
            "Earthshaker": 0.851
        },
        {
            "name": "Hoodwink",
            "Earthshaker": 1.341
        },
        {
            "name": "Huskar",
            "Earthshaker": 1.289
        },
        {
            "name": "Invoker",
            "Earthshaker": -0.343
        },
        {
            "name": "Io",
            "Earthshaker": -1.487
        },
        {
            "name": "Jakiro",
            "Earthshaker": 0.799
        },
        {
            "name": "Juggernaut",
            "Earthshaker": 0.051
        },
        {
            "name": "Keeper of the Light",
            "Earthshaker": 0.09
        },
        {
            "name": "Kez",
            "Earthshaker": 0.82
        },
        {
            "name": "Kunkka",
            "Earthshaker": -0.368
        },
        {
            "name": "Legion Commander",
            "Earthshaker": -0.486
        },
        {
            "name": "Leshrac",
            "Earthshaker": 0.093
        },
        {
            "name": "Lich",
            "Earthshaker": 0.35
        },
        {
            "name": "Lifestealer",
            "Earthshaker": 0.647
        },
        {
            "name": "Lina",
            "Earthshaker": 1.063
        },
        {
            "name": "Lion",
            "Earthshaker": 0.554
        },
        {
            "name": "Lone Druid",
            "Earthshaker": -2.542
        },
        {
            "name": "Luna",
            "Earthshaker": 0.787
        },
        {
            "name": "Lycan",
            "Earthshaker": -1.617
        },
        {
            "name": "Magnus",
            "Earthshaker": -1.195
        },
        {
            "name": "Marci",
            "Earthshaker": -0.852
        },
        {
            "name": "Mars",
            "Earthshaker": 0.208
        },
        {
            "name": "Medusa",
            "Earthshaker": -0.743
        },
        {
            "name": "Meepo",
            "Earthshaker": -7.601
        },
        {
            "name": "Mirana",
            "Earthshaker": -0.323
        },
        {
            "name": "Monkey King",
            "Earthshaker": -0.041
        },
        {
            "name": "Morphling",
            "Earthshaker": -1.149
        },
        {
            "name": "Muerta",
            "Earthshaker": 0.024
        },
        {
            "name": "Naga Siren",
            "Earthshaker": -2.902
        },
        {
            "name": "Nature's Prophet",
            "Earthshaker": 0.237
        },
        {
            "name": "Necrophos",
            "Earthshaker": 0.496
        },
        {
            "name": "Night Stalker",
            "Earthshaker": 1.136
        },
        {
            "name": "Nyx Assassin",
            "Earthshaker": -0.517
        },
        {
            "name": "Ogre Magi",
            "Earthshaker": 0.217
        },
        {
            "name": "Omniknight",
            "Earthshaker": -1.172
        },
        {
            "name": "Oracle",
            "Earthshaker": -1.265
        },
        {
            "name": "Outworld Destroyer",
            "Earthshaker": -0.481
        },
        {
            "name": "Pangolier",
            "Earthshaker": -0.03
        },
        {
            "name": "Phantom Assassin",
            "Earthshaker": 0.781
        },
        {
            "name": "Phantom Lancer",
            "Earthshaker": -2.152
        },
        {
            "name": "Phoenix",
            "Earthshaker": 0.36
        },
        {
            "name": "Primal Beast",
            "Earthshaker": 0.484
        },
        {
            "name": "Puck",
            "Earthshaker": -0.333
        },
        {
            "name": "Pudge",
            "Earthshaker": -0.402
        },
        {
            "name": "Pugna",
            "Earthshaker": -0.839
        },
        {
            "name": "Queen of Pain",
            "Earthshaker": -0.01
        },
        {
            "name": "Razor",
            "Earthshaker": 0.383
        },
        {
            "name": "Riki",
            "Earthshaker": -0.374
        },
        {
            "name": "Ringmaster",
            "Earthshaker": -0.192
        },
        {
            "name": "Rubick",
            "Earthshaker": -0.575
        },
        {
            "name": "Sand King",
            "Earthshaker": 0.691
        },
        {
            "name": "Shadow Demon",
            "Earthshaker": -1.129
        },
        {
            "name": "Shadow Fiend",
            "Earthshaker": 0.148
        },
        {
            "name": "Shadow Shaman",
            "Earthshaker": -0.161
        },
        {
            "name": "Silencer",
            "Earthshaker": 1.173
        },
        {
            "name": "Skywrath Mage",
            "Earthshaker": 0.656
        },
        {
            "name": "Slardar",
            "Earthshaker": 0.491
        },
        {
            "name": "Slark",
            "Earthshaker": -2.451
        },
        {
            "name": "Snapfire",
            "Earthshaker": 0.286
        },
        {
            "name": "Sniper",
            "Earthshaker": 1.457
        },
        {
            "name": "Spectre",
            "Earthshaker": 0.517
        },
        {
            "name": "Spirit Breaker",
            "Earthshaker": 0.202
        },
        {
            "name": "Storm Spirit",
            "Earthshaker": -0.452
        },
        {
            "name": "Sven",
            "Earthshaker": 0.962
        },
        {
            "name": "Techies",
            "Earthshaker": 0.742
        },
        {
            "name": "Templar Assassin",
            "Earthshaker": 2.625
        },
        {
            "name": "Terrorblade",
            "Earthshaker": -1.625
        },
        {
            "name": "Tidehunter",
            "Earthshaker": -0.381
        },
        {
            "name": "Timbersaw",
            "Earthshaker": 0.272
        },
        {
            "name": "Tinker",
            "Earthshaker": -1.399
        },
        {
            "name": "Tiny",
            "Earthshaker": 1.04
        },
        {
            "name": "Treant Protector",
            "Earthshaker": 0.522
        },
        {
            "name": "Troll Warlord",
            "Earthshaker": 0.411
        },
        {
            "name": "Tusk",
            "Earthshaker": -0.695
        },
        {
            "name": "Underlord",
            "Earthshaker": -0.479
        },
        {
            "name": "Undying",
            "Earthshaker": -0.839
        },
        {
            "name": "Ursa",
            "Earthshaker": -0.001
        },
        {
            "name": "Vengeful Spirit",
            "Earthshaker": -0.203
        },
        {
            "name": "Venomancer",
            "Earthshaker": 0.872
        },
        {
            "name": "Viper",
            "Earthshaker": 1.647
        },
        {
            "name": "Visage",
            "Earthshaker": -2.978
        },
        {
            "name": "Void Spirit",
            "Earthshaker": -0.63
        },
        {
            "name": "Warlock",
            "Earthshaker": -1.201
        },
        {
            "name": "Weaver",
            "Earthshaker": -0.758
        },
        {
            "name": "Windranger",
            "Earthshaker": 0.447
        },
        {
            "name": "Winter Wyvern",
            "Earthshaker": -0.012
        },
        {
            "name": "Witch Doctor",
            "Earthshaker": -0.586
        },
        {
            "name": "Wraith King",
            "Earthshaker": 0.264
        },
        {
            "name": "Zeus",
            "Earthshaker": 0.564
        }
    ],
    "Elder Titan": [
        {
            "name": "Abaddon",
            "Elder Titan": -3.554
        },
        {
            "name": "Alchemist",
            "Elder Titan": 0.497
        },
        {
            "name": "Ancient Apparition",
            "Elder Titan": -1.144
        },
        {
            "name": "Anti-Mage",
            "Elder Titan": -1.451
        },
        {
            "name": "Arc Warden",
            "Elder Titan": -3.01
        },
        {
            "name": "Axe",
            "Elder Titan": 0.81
        },
        {
            "name": "Bane",
            "Elder Titan": -0.342
        },
        {
            "name": "Batrider",
            "Elder Titan": 1.737
        },
        {
            "name": "Beastmaster",
            "Elder Titan": -0.784
        },
        {
            "name": "Bloodseeker",
            "Elder Titan": 0.071
        },
        {
            "name": "Bounty Hunter",
            "Elder Titan": 0.162
        },
        {
            "name": "Brewmaster",
            "Elder Titan": -1.147
        },
        {
            "name": "Bristleback",
            "Elder Titan": 1.329
        },
        {
            "name": "Broodmother",
            "Elder Titan": 1.901
        },
        {
            "name": "Centaur Warrunner",
            "Elder Titan": -0.552
        },
        {
            "name": "Chaos Knight",
            "Elder Titan": 0.622
        },
        {
            "name": "Chen",
            "Elder Titan": 4.064
        },
        {
            "name": "Clinkz",
            "Elder Titan": 3.54
        },
        {
            "name": "Clockwerk",
            "Elder Titan": -0.448
        },
        {
            "name": "Crystal Maiden",
            "Elder Titan": -0.037
        },
        {
            "name": "Dark Seer",
            "Elder Titan": 2.494
        },
        {
            "name": "Dark Willow",
            "Elder Titan": 1.022
        },
        {
            "name": "Dawnbreaker",
            "Elder Titan": -1.397
        },
        {
            "name": "Dazzle",
            "Elder Titan": 0.624
        },
        {
            "name": "Death Prophet",
            "Elder Titan": -0.338
        },
        {
            "name": "Disruptor",
            "Elder Titan": -0.131
        },
        {
            "name": "Doom",
            "Elder Titan": 0.076
        },
        {
            "name": "Dragon Knight",
            "Elder Titan": 1.235
        },
        {
            "name": "Drow Ranger",
            "Elder Titan": -0.745
        },
        {
            "name": "Earth Spirit",
            "Elder Titan": -3.713
        },
        {
            "name": "Earthshaker",
            "Elder Titan": 0.06
        },
        {
            "name": "Ember Spirit",
            "Elder Titan": -0.693
        },
        {
            "name": "Enchantress",
            "Elder Titan": 0.625
        },
        {
            "name": "Enigma",
            "Elder Titan": -0.169
        },
        {
            "name": "Faceless Void",
            "Elder Titan": -0.262
        },
        {
            "name": "Grimstroke",
            "Elder Titan": -0.077
        },
        {
            "name": "Gyrocopter",
            "Elder Titan": -0.771
        },
        {
            "name": "Hoodwink",
            "Elder Titan": 2.36
        },
        {
            "name": "Huskar",
            "Elder Titan": -0.421
        },
        {
            "name": "Invoker",
            "Elder Titan": 0.674
        },
        {
            "name": "Io",
            "Elder Titan": -1.807
        },
        {
            "name": "Jakiro",
            "Elder Titan": -0.33
        },
        {
            "name": "Juggernaut",
            "Elder Titan": 1.301
        },
        {
            "name": "Keeper of the Light",
            "Elder Titan": 0.009
        },
        {
            "name": "Kez",
            "Elder Titan": 0.306
        },
        {
            "name": "Kunkka",
            "Elder Titan": 0.944
        },
        {
            "name": "Legion Commander",
            "Elder Titan": 0.686
        },
        {
            "name": "Leshrac",
            "Elder Titan": -0.446
        },
        {
            "name": "Lich",
            "Elder Titan": 0.61
        },
        {
            "name": "Lifestealer",
            "Elder Titan": 1.0
        },
        {
            "name": "Lina",
            "Elder Titan": 1.608
        },
        {
            "name": "Lion",
            "Elder Titan": 0.332
        },
        {
            "name": "Lone Druid",
            "Elder Titan": -1.248
        },
        {
            "name": "Luna",
            "Elder Titan": 1.087
        },
        {
            "name": "Lycan",
            "Elder Titan": 1.786
        },
        {
            "name": "Magnus",
            "Elder Titan": -1.68
        },
        {
            "name": "Marci",
            "Elder Titan": -0.671
        },
        {
            "name": "Mars",
            "Elder Titan": -0.796
        },
        {
            "name": "Medusa",
            "Elder Titan": 1.696
        },
        {
            "name": "Meepo",
            "Elder Titan": -8.085
        },
        {
            "name": "Mirana",
            "Elder Titan": 1.088
        },
        {
            "name": "Monkey King",
            "Elder Titan": -0.032
        },
        {
            "name": "Morphling",
            "Elder Titan": -1.618
        },
        {
            "name": "Muerta",
            "Elder Titan": 0.307
        },
        {
            "name": "Naga Siren",
            "Elder Titan": 1.275
        },
        {
            "name": "Nature's Prophet",
            "Elder Titan": 1.853
        },
        {
            "name": "Necrophos",
            "Elder Titan": -0.113
        },
        {
            "name": "Night Stalker",
            "Elder Titan": 0.579
        },
        {
            "name": "Nyx Assassin",
            "Elder Titan": -0.559
        },
        {
            "name": "Ogre Magi",
            "Elder Titan": 1.025
        },
        {
            "name": "Omniknight",
            "Elder Titan": -1.087
        },
        {
            "name": "Oracle",
            "Elder Titan": -1.06
        },
        {
            "name": "Outworld Destroyer",
            "Elder Titan": -0.532
        },
        {
            "name": "Pangolier",
            "Elder Titan": -3.509
        },
        {
            "name": "Phantom Assassin",
            "Elder Titan": 2.565
        },
        {
            "name": "Phantom Lancer",
            "Elder Titan": -1.802
        },
        {
            "name": "Phoenix",
            "Elder Titan": 0.229
        },
        {
            "name": "Primal Beast",
            "Elder Titan": 0.987
        },
        {
            "name": "Puck",
            "Elder Titan": 1.755
        },
        {
            "name": "Pudge",
            "Elder Titan": -1.429
        },
        {
            "name": "Pugna",
            "Elder Titan": -1.061
        },
        {
            "name": "Queen of Pain",
            "Elder Titan": -0.406
        },
        {
            "name": "Razor",
            "Elder Titan": -1.558
        },
        {
            "name": "Riki",
            "Elder Titan": -1.127
        },
        {
            "name": "Ringmaster",
            "Elder Titan": -2.624
        },
        {
            "name": "Rubick",
            "Elder Titan": -2.133
        },
        {
            "name": "Sand King",
            "Elder Titan": -0.145
        },
        {
            "name": "Shadow Demon",
            "Elder Titan": 0.464
        },
        {
            "name": "Shadow Fiend",
            "Elder Titan": 1.102
        },
        {
            "name": "Shadow Shaman",
            "Elder Titan": -0.518
        },
        {
            "name": "Silencer",
            "Elder Titan": 0.235
        },
        {
            "name": "Skywrath Mage",
            "Elder Titan": 2.019
        },
        {
            "name": "Slardar",
            "Elder Titan": 0.916
        },
        {
            "name": "Slark",
            "Elder Titan": -4.596
        },
        {
            "name": "Snapfire",
            "Elder Titan": 0.619
        },
        {
            "name": "Sniper",
            "Elder Titan": 0.709
        },
        {
            "name": "Spectre",
            "Elder Titan": 0.196
        },
        {
            "name": "Spirit Breaker",
            "Elder Titan": 1.358
        },
        {
            "name": "Storm Spirit",
            "Elder Titan": 1.548
        },
        {
            "name": "Sven",
            "Elder Titan": 1.763
        },
        {
            "name": "Techies",
            "Elder Titan": 1.972
        },
        {
            "name": "Templar Assassin",
            "Elder Titan": 1.501
        },
        {
            "name": "Terrorblade",
            "Elder Titan": -0.117
        },
        {
            "name": "Tidehunter",
            "Elder Titan": -3.01
        },
        {
            "name": "Timbersaw",
            "Elder Titan": -0.22
        },
        {
            "name": "Tinker",
            "Elder Titan": -0.935
        },
        {
            "name": "Tiny",
            "Elder Titan": 0.681
        },
        {
            "name": "Treant Protector",
            "Elder Titan": 0.27
        },
        {
            "name": "Troll Warlord",
            "Elder Titan": 3.29
        },
        {
            "name": "Tusk",
            "Elder Titan": -2.207
        },
        {
            "name": "Underlord",
            "Elder Titan": -0.664
        },
        {
            "name": "Undying",
            "Elder Titan": -2.04
        },
        {
            "name": "Ursa",
            "Elder Titan": 2.097
        },
        {
            "name": "Vengeful Spirit",
            "Elder Titan": 0.119
        },
        {
            "name": "Venomancer",
            "Elder Titan": -0.403
        },
        {
            "name": "Viper",
            "Elder Titan": 0.415
        },
        {
            "name": "Visage",
            "Elder Titan": 2.22
        },
        {
            "name": "Void Spirit",
            "Elder Titan": -0.431
        },
        {
            "name": "Warlock",
            "Elder Titan": -0.211
        },
        {
            "name": "Weaver",
            "Elder Titan": -0.711
        },
        {
            "name": "Windranger",
            "Elder Titan": 1.076
        },
        {
            "name": "Winter Wyvern",
            "Elder Titan": 0.9
        },
        {
            "name": "Witch Doctor",
            "Elder Titan": -0.131
        },
        {
            "name": "Wraith King",
            "Elder Titan": 2.824
        },
        {
            "name": "Zeus",
            "Elder Titan": 0.973
        }
    ],
    "Huskar": [
        {
            "name": "Abaddon",
            "Huskar": 1.329
        },
        {
            "name": "Alchemist",
            "Huskar": -1.396
        },
        {
            "name": "Ancient Apparition",
            "Huskar": 7.432
        },
        {
            "name": "Anti-Mage",
            "Huskar": -6.903
        },
        {
            "name": "Arc Warden",
            "Huskar": 1.525
        },
        {
            "name": "Axe",
            "Huskar": 1.962
        },
        {
            "name": "Bane",
            "Huskar": 0.37
        },
        {
            "name": "Batrider",
            "Huskar": -0.555
        },
        {
            "name": "Beastmaster",
            "Huskar": 0.973
        },
        {
            "name": "Bloodseeker",
            "Huskar": 2.129
        },
        {
            "name": "Bounty Hunter",
            "Huskar": -1.627
        },
        {
            "name": "Brewmaster",
            "Huskar": -0.601
        },
        {
            "name": "Bristleback",
            "Huskar": 1.658
        },
        {
            "name": "Broodmother",
            "Huskar": 3.184
        },
        {
            "name": "Centaur Warrunner",
            "Huskar": -1.438
        },
        {
            "name": "Chaos Knight",
            "Huskar": 4.555
        },
        {
            "name": "Chen",
            "Huskar": 2.986
        },
        {
            "name": "Clinkz",
            "Huskar": 2.012
        },
        {
            "name": "Clockwerk",
            "Huskar": -4.341
        },
        {
            "name": "Crystal Maiden",
            "Huskar": -0.071
        },
        {
            "name": "Dark Seer",
            "Huskar": -0.213
        },
        {
            "name": "Dark Willow",
            "Huskar": 0.087
        },
        {
            "name": "Dawnbreaker",
            "Huskar": -1.184
        },
        {
            "name": "Dazzle",
            "Huskar": -0.486
        },
        {
            "name": "Death Prophet",
            "Huskar": 2.135
        },
        {
            "name": "Disruptor",
            "Huskar": -1.292
        },
        {
            "name": "Doom",
            "Huskar": 2.262
        },
        {
            "name": "Dragon Knight",
            "Huskar": -1.598
        },
        {
            "name": "Drow Ranger",
            "Huskar": 1.515
        },
        {
            "name": "Earth Spirit",
            "Huskar": -2.587
        },
        {
            "name": "Earthshaker",
            "Huskar": -1.447
        },
        {
            "name": "Elder Titan",
            "Huskar": 0.275
        },
        {
            "name": "Ember Spirit",
            "Huskar": -6.077
        },
        {
            "name": "Enchantress",
            "Huskar": 1.011
        },
        {
            "name": "Enigma",
            "Huskar": 1.333
        },
        {
            "name": "Faceless Void",
            "Huskar": -2.044
        },
        {
            "name": "Grimstroke",
            "Huskar": -0.566
        },
        {
            "name": "Gyrocopter",
            "Huskar": -0.498
        },
        {
            "name": "Hoodwink",
            "Huskar": 1.42
        },
        {
            "name": "Invoker",
            "Huskar": -0.59
        },
        {
            "name": "Io",
            "Huskar": 0.229
        },
        {
            "name": "Jakiro",
            "Huskar": -0.651
        },
        {
            "name": "Juggernaut",
            "Huskar": -1.396
        },
        {
            "name": "Keeper of the Light",
            "Huskar": -2.242
        },
        {
            "name": "Kez",
            "Huskar": 2.451
        },
        {
            "name": "Kunkka",
            "Huskar": -1.345
        },
        {
            "name": "Legion Commander",
            "Huskar": 1.734
        },
        {
            "name": "Leshrac",
            "Huskar": 0.87
        },
        {
            "name": "Lich",
            "Huskar": 0.378
        },
        {
            "name": "Lifestealer",
            "Huskar": -1.283
        },
        {
            "name": "Lina",
            "Huskar": 1.452
        },
        {
            "name": "Lion",
            "Huskar": 0.668
        },
        {
            "name": "Lone Druid",
            "Huskar": -1.45
        },
        {
            "name": "Luna",
            "Huskar": 1.835
        },
        {
            "name": "Lycan",
            "Huskar": -1.542
        },
        {
            "name": "Magnus",
            "Huskar": -1.575
        },
        {
            "name": "Marci",
            "Huskar": 1.244
        },
        {
            "name": "Mars",
            "Huskar": -1.676
        },
        {
            "name": "Medusa",
            "Huskar": 1.647
        },
        {
            "name": "Meepo",
            "Huskar": -2.864
        },
        {
            "name": "Mirana",
            "Huskar": -0.224
        },
        {
            "name": "Monkey King",
            "Huskar": 0.871
        },
        {
            "name": "Morphling",
            "Huskar": 1.191
        },
        {
            "name": "Muerta",
            "Huskar": 0.095
        },
        {
            "name": "Naga Siren",
            "Huskar": 3.38
        },
        {
            "name": "Nature's Prophet",
            "Huskar": 0.646
        },
        {
            "name": "Necrophos",
            "Huskar": 4.171
        },
        {
            "name": "Night Stalker",
            "Huskar": -3.476
        },
        {
            "name": "Nyx Assassin",
            "Huskar": -1.74
        },
        {
            "name": "Ogre Magi",
            "Huskar": -0.93
        },
        {
            "name": "Omniknight",
            "Huskar": 0.282
        },
        {
            "name": "Oracle",
            "Huskar": -1.196
        },
        {
            "name": "Outworld Destroyer",
            "Huskar": -1.545
        },
        {
            "name": "Pangolier",
            "Huskar": -4.855
        },
        {
            "name": "Phantom Assassin",
            "Huskar": 3.403
        },
        {
            "name": "Phantom Lancer",
            "Huskar": 3.127
        },
        {
            "name": "Phoenix",
            "Huskar": -3.63
        },
        {
            "name": "Primal Beast",
            "Huskar": -0.823
        },
        {
            "name": "Puck",
            "Huskar": -2.531
        },
        {
            "name": "Pudge",
            "Huskar": 0.691
        },
        {
            "name": "Pugna",
            "Huskar": -3.14
        },
        {
            "name": "Queen of Pain",
            "Huskar": -3.526
        },
        {
            "name": "Razor",
            "Huskar": -0.161
        },
        {
            "name": "Riki",
            "Huskar": 0.673
        },
        {
            "name": "Ringmaster",
            "Huskar": 0.312
        },
        {
            "name": "Rubick",
            "Huskar": -0.994
        },
        {
            "name": "Sand King",
            "Huskar": -0.74
        },
        {
            "name": "Shadow Demon",
            "Huskar": 1.729
        },
        {
            "name": "Shadow Fiend",
            "Huskar": 1.015
        },
        {
            "name": "Shadow Shaman",
            "Huskar": 1.503
        },
        {
            "name": "Silencer",
            "Huskar": -1.567
        },
        {
            "name": "Skywrath Mage",
            "Huskar": -0.224
        },
        {
            "name": "Slardar",
            "Huskar": -1.486
        },
        {
            "name": "Slark",
            "Huskar": -0.814
        },
        {
            "name": "Snapfire",
            "Huskar": -0.459
        },
        {
            "name": "Sniper",
            "Huskar": 2.926
        },
        {
            "name": "Spectre",
            "Huskar": -0.725
        },
        {
            "name": "Spirit Breaker",
            "Huskar": 0.58
        },
        {
            "name": "Storm Spirit",
            "Huskar": -1.516
        },
        {
            "name": "Sven",
            "Huskar": -0.035
        },
        {
            "name": "Techies",
            "Huskar": 0.178
        },
        {
            "name": "Templar Assassin",
            "Huskar": 1.557
        },
        {
            "name": "Terrorblade",
            "Huskar": 1.967
        },
        {
            "name": "Tidehunter",
            "Huskar": -3.071
        },
        {
            "name": "Timbersaw",
            "Huskar": 2.162
        },
        {
            "name": "Tinker",
            "Huskar": -1.518
        },
        {
            "name": "Tiny",
            "Huskar": -3.004
        },
        {
            "name": "Treant Protector",
            "Huskar": -0.741
        },
        {
            "name": "Troll Warlord",
            "Huskar": 2.157
        },
        {
            "name": "Tusk",
            "Huskar": 0.983
        },
        {
            "name": "Underlord",
            "Huskar": -1.666
        },
        {
            "name": "Undying",
            "Huskar": 1.136
        },
        {
            "name": "Ursa",
            "Huskar": 1.232
        },
        {
            "name": "Vengeful Spirit",
            "Huskar": 0.695
        },
        {
            "name": "Venomancer",
            "Huskar": -0.392
        },
        {
            "name": "Viper",
            "Huskar": 7.33
        },
        {
            "name": "Visage",
            "Huskar": 0.803
        },
        {
            "name": "Void Spirit",
            "Huskar": -3.531
        },
        {
            "name": "Warlock",
            "Huskar": -0.7
        },
        {
            "name": "Weaver",
            "Huskar": -1.375
        },
        {
            "name": "Windranger",
            "Huskar": 4.095
        },
        {
            "name": "Winter Wyvern",
            "Huskar": -1.679
        },
        {
            "name": "Witch Doctor",
            "Huskar": 1.521
        },
        {
            "name": "Wraith King",
            "Huskar": 1.808
        },
        {
            "name": "Zeus",
            "Huskar": -1.917
        }
    ],
    "Kunkka": [
        {
            "name": "Abaddon",
            "Kunkka": -1.046
        },
        {
            "name": "Alchemist",
            "Kunkka": 1.306
        },
        {
            "name": "Ancient Apparition",
            "Kunkka": 0.973
        },
        {
            "name": "Anti-Mage",
            "Kunkka": -0.389
        },
        {
            "name": "Arc Warden",
            "Kunkka": -2.488
        },
        {
            "name": "Axe",
            "Kunkka": 0.326
        },
        {
            "name": "Bane",
            "Kunkka": -0.133
        },
        {
            "name": "Batrider",
            "Kunkka": -0.005
        },
        {
            "name": "Beastmaster",
            "Kunkka": -0.122
        },
        {
            "name": "Bloodseeker",
            "Kunkka": 0.685
        },
        {
            "name": "Bounty Hunter",
            "Kunkka": 0.752
        },
        {
            "name": "Brewmaster",
            "Kunkka": -0.734
        },
        {
            "name": "Bristleback",
            "Kunkka": 1.301
        },
        {
            "name": "Broodmother",
            "Kunkka": -0.944
        },
        {
            "name": "Centaur Warrunner",
            "Kunkka": 0.264
        },
        {
            "name": "Chaos Knight",
            "Kunkka": -1.457
        },
        {
            "name": "Chen",
            "Kunkka": -2.599
        },
        {
            "name": "Clinkz",
            "Kunkka": -0.374
        },
        {
            "name": "Clockwerk",
            "Kunkka": -0.784
        },
        {
            "name": "Crystal Maiden",
            "Kunkka": 0.064
        },
        {
            "name": "Dark Seer",
            "Kunkka": 0.368
        },
        {
            "name": "Dark Willow",
            "Kunkka": -0.813
        },
        {
            "name": "Dawnbreaker",
            "Kunkka": -0.958
        },
        {
            "name": "Dazzle",
            "Kunkka": 0.316
        },
        {
            "name": "Death Prophet",
            "Kunkka": -0.255
        },
        {
            "name": "Disruptor",
            "Kunkka": -0.419
        },
        {
            "name": "Doom",
            "Kunkka": 0.318
        },
        {
            "name": "Dragon Knight",
            "Kunkka": -0.016
        },
        {
            "name": "Drow Ranger",
            "Kunkka": -0.913
        },
        {
            "name": "Earth Spirit",
            "Kunkka": -0.144
        },
        {
            "name": "Earthshaker",
            "Kunkka": 0.679
        },
        {
            "name": "Elder Titan",
            "Kunkka": -0.998
        },
        {
            "name": "Ember Spirit",
            "Kunkka": 2.037
        },
        {
            "name": "Enchantress",
            "Kunkka": 0.566
        },
        {
            "name": "Enigma",
            "Kunkka": -2.64
        },
        {
            "name": "Faceless Void",
            "Kunkka": 1.426
        },
        {
            "name": "Grimstroke",
            "Kunkka": -0.134
        },
        {
            "name": "Gyrocopter",
            "Kunkka": -0.106
        },
        {
            "name": "Hoodwink",
            "Kunkka": 0.849
        },
        {
            "name": "Huskar",
            "Kunkka": 1.445
        },
        {
            "name": "Invoker",
            "Kunkka": 0.322
        },
        {
            "name": "Io",
            "Kunkka": -2.331
        },
        {
            "name": "Jakiro",
            "Kunkka": 0.93
        },
        {
            "name": "Juggernaut",
            "Kunkka": 0.981
        },
        {
            "name": "Keeper of the Light",
            "Kunkka": 0.511
        },
        {
            "name": "Kez",
            "Kunkka": 0.027
        },
        {
            "name": "Legion Commander",
            "Kunkka": -1.885
        },
        {
            "name": "Leshrac",
            "Kunkka": -1.194
        },
        {
            "name": "Lich",
            "Kunkka": 0.709
        },
        {
            "name": "Lifestealer",
            "Kunkka": 3.37
        },
        {
            "name": "Lina",
            "Kunkka": -0.778
        },
        {
            "name": "Lion",
            "Kunkka": -0.673
        },
        {
            "name": "Lone Druid",
            "Kunkka": -3.745
        },
        {
            "name": "Luna",
            "Kunkka": -0.977
        },
        {
            "name": "Lycan",
            "Kunkka": -2.115
        },
        {
            "name": "Magnus",
            "Kunkka": -0.858
        },
        {
            "name": "Marci",
            "Kunkka": -2.04
        },
        {
            "name": "Mars",
            "Kunkka": -0.59
        },
        {
            "name": "Medusa",
            "Kunkka": -2.618
        },
        {
            "name": "Meepo",
            "Kunkka": -3.892
        },
        {
            "name": "Mirana",
            "Kunkka": -0.665
        },
        {
            "name": "Monkey King",
            "Kunkka": 0.501
        },
        {
            "name": "Morphling",
            "Kunkka": -0.017
        },
        {
            "name": "Muerta",
            "Kunkka": -0.996
        },
        {
            "name": "Naga Siren",
            "Kunkka": -0.933
        },
        {
            "name": "Nature's Prophet",
            "Kunkka": -0.616
        },
        {
            "name": "Necrophos",
            "Kunkka": 0.685
        },
        {
            "name": "Night Stalker",
            "Kunkka": -0.417
        },
        {
            "name": "Nyx Assassin",
            "Kunkka": -0.571
        },
        {
            "name": "Ogre Magi",
            "Kunkka": 0.738
        },
        {
            "name": "Omniknight",
            "Kunkka": -0.249
        },
        {
            "name": "Oracle",
            "Kunkka": -1.224
        },
        {
            "name": "Outworld Destroyer",
            "Kunkka": -0.685
        },
        {
            "name": "Pangolier",
            "Kunkka": 0.315
        },
        {
            "name": "Phantom Assassin",
            "Kunkka": -0.118
        },
        {
            "name": "Phantom Lancer",
            "Kunkka": -1.145
        },
        {
            "name": "Phoenix",
            "Kunkka": 1.034
        },
        {
            "name": "Primal Beast",
            "Kunkka": 0.081
        },
        {
            "name": "Puck",
            "Kunkka": 0.561
        },
        {
            "name": "Pudge",
            "Kunkka": -0.576
        },
        {
            "name": "Pugna",
            "Kunkka": -0.383
        },
        {
            "name": "Queen of Pain",
            "Kunkka": 0.207
        },
        {
            "name": "Razor",
            "Kunkka": 0.761
        },
        {
            "name": "Riki",
            "Kunkka": -0.899
        },
        {
            "name": "Ringmaster",
            "Kunkka": -0.404
        },
        {
            "name": "Rubick",
            "Kunkka": 1.478
        },
        {
            "name": "Sand King",
            "Kunkka": 0.37
        },
        {
            "name": "Shadow Demon",
            "Kunkka": 0.174
        },
        {
            "name": "Shadow Fiend",
            "Kunkka": -0.576
        },
        {
            "name": "Shadow Shaman",
            "Kunkka": -0.524
        },
        {
            "name": "Silencer",
            "Kunkka": 0.385
        },
        {
            "name": "Skywrath Mage",
            "Kunkka": -0.608
        },
        {
            "name": "Slardar",
            "Kunkka": 0.686
        },
        {
            "name": "Slark",
            "Kunkka": -0.568
        },
        {
            "name": "Snapfire",
            "Kunkka": -0.453
        },
        {
            "name": "Sniper",
            "Kunkka": -0.607
        },
        {
            "name": "Spectre",
            "Kunkka": -0.014
        },
        {
            "name": "Spirit Breaker",
            "Kunkka": -0.17
        },
        {
            "name": "Storm Spirit",
            "Kunkka": 1.009
        },
        {
            "name": "Sven",
            "Kunkka": -0.426
        },
        {
            "name": "Techies",
            "Kunkka": 0.583
        },
        {
            "name": "Templar Assassin",
            "Kunkka": -0.192
        },
        {
            "name": "Terrorblade",
            "Kunkka": -1.451
        },
        {
            "name": "Tidehunter",
            "Kunkka": -1.135
        },
        {
            "name": "Timbersaw",
            "Kunkka": 1.677
        },
        {
            "name": "Tinker",
            "Kunkka": 0.092
        },
        {
            "name": "Tiny",
            "Kunkka": -0.797
        },
        {
            "name": "Treant Protector",
            "Kunkka": 1.357
        },
        {
            "name": "Troll Warlord",
            "Kunkka": -0.339
        },
        {
            "name": "Tusk",
            "Kunkka": 0.524
        },
        {
            "name": "Underlord",
            "Kunkka": 0.408
        },
        {
            "name": "Undying",
            "Kunkka": 1.923
        },
        {
            "name": "Ursa",
            "Kunkka": -0.489
        },
        {
            "name": "Vengeful Spirit",
            "Kunkka": -0.428
        },
        {
            "name": "Venomancer",
            "Kunkka": 1.599
        },
        {
            "name": "Viper",
            "Kunkka": -0.054
        },
        {
            "name": "Visage",
            "Kunkka": -0.087
        },
        {
            "name": "Void Spirit",
            "Kunkka": 1.491
        },
        {
            "name": "Warlock",
            "Kunkka": 0.921
        },
        {
            "name": "Weaver",
            "Kunkka": -0.012
        },
        {
            "name": "Windranger",
            "Kunkka": -0.41
        },
        {
            "name": "Winter Wyvern",
            "Kunkka": 0.872
        },
        {
            "name": "Witch Doctor",
            "Kunkka": 0.283
        },
        {
            "name": "Wraith King",
            "Kunkka": 0.542
        },
        {
            "name": "Zeus",
            "Kunkka": -0.603
        }
    ],
    "Legion Commander": [
        {
            "name": "Abaddon",
            "Legion Commander": 1.285
        },
        {
            "name": "Alchemist",
            "Legion Commander": -0.484
        },
        {
            "name": "Ancient Apparition",
            "Legion Commander": -0.026
        },
        {
            "name": "Anti-Mage",
            "Legion Commander": -2.989
        },
        {
            "name": "Arc Warden",
            "Legion Commander": -0.63
        },
        {
            "name": "Axe",
            "Legion Commander": 1.054
        },
        {
            "name": "Bane",
            "Legion Commander": 0.921
        },
        {
            "name": "Batrider",
            "Legion Commander": 0.084
        },
        {
            "name": "Beastmaster",
            "Legion Commander": 0.459
        },
        {
            "name": "Bloodseeker",
            "Legion Commander": -2.156
        },
        {
            "name": "Bounty Hunter",
            "Legion Commander": -1.366
        },
        {
            "name": "Brewmaster",
            "Legion Commander": -0.397
        },
        {
            "name": "Bristleback",
            "Legion Commander": -2.789
        },
        {
            "name": "Broodmother",
            "Legion Commander": -0.444
        },
        {
            "name": "Centaur Warrunner",
            "Legion Commander": 0.595
        },
        {
            "name": "Chaos Knight",
            "Legion Commander": 2.73
        },
        {
            "name": "Chen",
            "Legion Commander": 1.395
        },
        {
            "name": "Clinkz",
            "Legion Commander": -1.222
        },
        {
            "name": "Clockwerk",
            "Legion Commander": 0.351
        },
        {
            "name": "Crystal Maiden",
            "Legion Commander": -0.197
        },
        {
            "name": "Dark Seer",
            "Legion Commander": -1.182
        },
        {
            "name": "Dark Willow",
            "Legion Commander": -1.056
        },
        {
            "name": "Dawnbreaker",
            "Legion Commander": 2.989
        },
        {
            "name": "Dazzle",
            "Legion Commander": 0.934
        },
        {
            "name": "Death Prophet",
            "Legion Commander": 0.009
        },
        {
            "name": "Disruptor",
            "Legion Commander": -0.625
        },
        {
            "name": "Doom",
            "Legion Commander": 0.725
        },
        {
            "name": "Dragon Knight",
            "Legion Commander": 1.77
        },
        {
            "name": "Drow Ranger",
            "Legion Commander": 0.584
        },
        {
            "name": "Earth Spirit",
            "Legion Commander": -0.663
        },
        {
            "name": "Earthshaker",
            "Legion Commander": 0.499
        },
        {
            "name": "Elder Titan",
            "Legion Commander": -0.608
        },
        {
            "name": "Ember Spirit",
            "Legion Commander": -1.331
        },
        {
            "name": "Enchantress",
            "Legion Commander": -0.168
        },
        {
            "name": "Enigma",
            "Legion Commander": -0.537
        },
        {
            "name": "Faceless Void",
            "Legion Commander": -1.107
        },
        {
            "name": "Grimstroke",
            "Legion Commander": -0.147
        },
        {
            "name": "Gyrocopter",
            "Legion Commander": -0.24
        },
        {
            "name": "Hoodwink",
            "Legion Commander": 0.324
        },
        {
            "name": "Huskar",
            "Legion Commander": -1.412
        },
        {
            "name": "Invoker",
            "Legion Commander": -0.157
        },
        {
            "name": "Io",
            "Legion Commander": 0.493
        },
        {
            "name": "Jakiro",
            "Legion Commander": -0.877
        },
        {
            "name": "Juggernaut",
            "Legion Commander": -1.121
        },
        {
            "name": "Keeper of the Light",
            "Legion Commander": -0.249
        },
        {
            "name": "Kez",
            "Legion Commander": -0.434
        },
        {
            "name": "Kunkka",
            "Legion Commander": 2.302
        },
        {
            "name": "Leshrac",
            "Legion Commander": -0.059
        },
        {
            "name": "Lich",
            "Legion Commander": 0.657
        },
        {
            "name": "Lifestealer",
            "Legion Commander": -0.141
        },
        {
            "name": "Lina",
            "Legion Commander": 0.463
        },
        {
            "name": "Lion",
            "Legion Commander": 1.212
        },
        {
            "name": "Lone Druid",
            "Legion Commander": 1.36
        },
        {
            "name": "Luna",
            "Legion Commander": 1.202
        },
        {
            "name": "Lycan",
            "Legion Commander": 0.467
        },
        {
            "name": "Magnus",
            "Legion Commander": 0.654
        },
        {
            "name": "Marci",
            "Legion Commander": 1.503
        },
        {
            "name": "Mars",
            "Legion Commander": 1.661
        },
        {
            "name": "Medusa",
            "Legion Commander": 1.862
        },
        {
            "name": "Meepo",
            "Legion Commander": -1.715
        },
        {
            "name": "Mirana",
            "Legion Commander": -0.52
        },
        {
            "name": "Monkey King",
            "Legion Commander": 2.531
        },
        {
            "name": "Morphling",
            "Legion Commander": 0.481
        },
        {
            "name": "Muerta",
            "Legion Commander": -0.22
        },
        {
            "name": "Naga Siren",
            "Legion Commander": -0.168
        },
        {
            "name": "Nature's Prophet",
            "Legion Commander": -0.465
        },
        {
            "name": "Necrophos",
            "Legion Commander": -1.014
        },
        {
            "name": "Night Stalker",
            "Legion Commander": -0.097
        },
        {
            "name": "Nyx Assassin",
            "Legion Commander": -0.293
        },
        {
            "name": "Ogre Magi",
            "Legion Commander": -0.723
        },
        {
            "name": "Omniknight",
            "Legion Commander": 2.726
        },
        {
            "name": "Oracle",
            "Legion Commander": 1.86
        },
        {
            "name": "Outworld Destroyer",
            "Legion Commander": 1.124
        },
        {
            "name": "Pangolier",
            "Legion Commander": 1.422
        },
        {
            "name": "Phantom Assassin",
            "Legion Commander": 0.428
        },
        {
            "name": "Phantom Lancer",
            "Legion Commander": -0.473
        },
        {
            "name": "Phoenix",
            "Legion Commander": -1.393
        },
        {
            "name": "Primal Beast",
            "Legion Commander": 1.579
        },
        {
            "name": "Puck",
            "Legion Commander": -0.92
        },
        {
            "name": "Pudge",
            "Legion Commander": 1.695
        },
        {
            "name": "Pugna",
            "Legion Commander": 1.278
        },
        {
            "name": "Queen of Pain",
            "Legion Commander": -1.374
        },
        {
            "name": "Razor",
            "Legion Commander": -0.159
        },
        {
            "name": "Riki",
            "Legion Commander": 0.898
        },
        {
            "name": "Ringmaster",
            "Legion Commander": 2.581
        },
        {
            "name": "Rubick",
            "Legion Commander": -1.152
        },
        {
            "name": "Sand King",
            "Legion Commander": 0.667
        },
        {
            "name": "Shadow Demon",
            "Legion Commander": 2.919
        },
        {
            "name": "Shadow Fiend",
            "Legion Commander": 0.891
        },
        {
            "name": "Shadow Shaman",
            "Legion Commander": 0.467
        },
        {
            "name": "Silencer",
            "Legion Commander": -1.018
        },
        {
            "name": "Skywrath Mage",
            "Legion Commander": 0.612
        },
        {
            "name": "Slardar",
            "Legion Commander": 0.423
        },
        {
            "name": "Slark",
            "Legion Commander": -1.45
        },
        {
            "name": "Snapfire",
            "Legion Commander": 0.416
        },
        {
            "name": "Sniper",
            "Legion Commander": 0.065
        },
        {
            "name": "Spectre",
            "Legion Commander": -0.501
        },
        {
            "name": "Spirit Breaker",
            "Legion Commander": 1.943
        },
        {
            "name": "Storm Spirit",
            "Legion Commander": -0.332
        },
        {
            "name": "Sven",
            "Legion Commander": 0.943
        },
        {
            "name": "Techies",
            "Legion Commander": 2.049
        },
        {
            "name": "Templar Assassin",
            "Legion Commander": 2.029
        },
        {
            "name": "Terrorblade",
            "Legion Commander": -1.208
        },
        {
            "name": "Tidehunter",
            "Legion Commander": -0.404
        },
        {
            "name": "Timbersaw",
            "Legion Commander": 1.205
        },
        {
            "name": "Tinker",
            "Legion Commander": -0.194
        },
        {
            "name": "Tiny",
            "Legion Commander": 1.477
        },
        {
            "name": "Treant Protector",
            "Legion Commander": 1.379
        },
        {
            "name": "Troll Warlord",
            "Legion Commander": 0.598
        },
        {
            "name": "Tusk",
            "Legion Commander": 2.431
        },
        {
            "name": "Underlord",
            "Legion Commander": -0.567
        },
        {
            "name": "Undying",
            "Legion Commander": -0.869
        },
        {
            "name": "Ursa",
            "Legion Commander": 1.076
        },
        {
            "name": "Vengeful Spirit",
            "Legion Commander": 1.777
        },
        {
            "name": "Venomancer",
            "Legion Commander": -1.257
        },
        {
            "name": "Viper",
            "Legion Commander": -1.059
        },
        {
            "name": "Visage",
            "Legion Commander": 0.065
        },
        {
            "name": "Void Spirit",
            "Legion Commander": -0.693
        },
        {
            "name": "Warlock",
            "Legion Commander": -0.094
        },
        {
            "name": "Weaver",
            "Legion Commander": -1.343
        },
        {
            "name": "Windranger",
            "Legion Commander": 0.326
        },
        {
            "name": "Winter Wyvern",
            "Legion Commander": 3.293
        },
        {
            "name": "Witch Doctor",
            "Legion Commander": -0.011
        },
        {
            "name": "Wraith King",
            "Legion Commander": 1.78
        },
        {
            "name": "Zeus",
            "Legion Commander": 0.663
        }
    ],
    "Lifestealer": [
        {
            "name": "Abaddon",
            "Lifestealer": 0.807
        },
        {
            "name": "Alchemist",
            "Lifestealer": 0.432
        },
        {
            "name": "Ancient Apparition",
            "Lifestealer": 0.851
        },
        {
            "name": "Anti-Mage",
            "Lifestealer": 2.099
        },
        {
            "name": "Arc Warden",
            "Lifestealer": 1.371
        },
        {
            "name": "Axe",
            "Lifestealer": -1.791
        },
        {
            "name": "Bane",
            "Lifestealer": 1.937
        },
        {
            "name": "Batrider",
            "Lifestealer": 2.249
        },
        {
            "name": "Beastmaster",
            "Lifestealer": 2.144
        },
        {
            "name": "Bloodseeker",
            "Lifestealer": -1.473
        },
        {
            "name": "Bounty Hunter",
            "Lifestealer": 1.041
        },
        {
            "name": "Brewmaster",
            "Lifestealer": -0.974
        },
        {
            "name": "Bristleback",
            "Lifestealer": 3.033
        },
        {
            "name": "Broodmother",
            "Lifestealer": 3.142
        },
        {
            "name": "Centaur Warrunner",
            "Lifestealer": -3.795
        },
        {
            "name": "Chaos Knight",
            "Lifestealer": 0.437
        },
        {
            "name": "Chen",
            "Lifestealer": 2.234
        },
        {
            "name": "Clinkz",
            "Lifestealer": 3.103
        },
        {
            "name": "Clockwerk",
            "Lifestealer": -1.355
        },
        {
            "name": "Crystal Maiden",
            "Lifestealer": 0.273
        },
        {
            "name": "Dark Seer",
            "Lifestealer": 0.914
        },
        {
            "name": "Dark Willow",
            "Lifestealer": -0.005
        },
        {
            "name": "Dawnbreaker",
            "Lifestealer": -0.527
        },
        {
            "name": "Dazzle",
            "Lifestealer": 1.549
        },
        {
            "name": "Death Prophet",
            "Lifestealer": 2.536
        },
        {
            "name": "Disruptor",
            "Lifestealer": -0.38
        },
        {
            "name": "Doom",
            "Lifestealer": 3.092
        },
        {
            "name": "Dragon Knight",
            "Lifestealer": -1.312
        },
        {
            "name": "Drow Ranger",
            "Lifestealer": 1.887
        },
        {
            "name": "Earth Spirit",
            "Lifestealer": -1.43
        },
        {
            "name": "Earthshaker",
            "Lifestealer": -0.596
        },
        {
            "name": "Elder Titan",
            "Lifestealer": -0.43
        },
        {
            "name": "Ember Spirit",
            "Lifestealer": 0.027
        },
        {
            "name": "Enchantress",
            "Lifestealer": 1.06
        },
        {
            "name": "Enigma",
            "Lifestealer": 3.202
        },
        {
            "name": "Faceless Void",
            "Lifestealer": 1.963
        },
        {
            "name": "Grimstroke",
            "Lifestealer": 1.327
        },
        {
            "name": "Gyrocopter",
            "Lifestealer": -0.93
        },
        {
            "name": "Hoodwink",
            "Lifestealer": 0.725
        },
        {
            "name": "Huskar",
            "Lifestealer": 1.67
        },
        {
            "name": "Invoker",
            "Lifestealer": -1.61
        },
        {
            "name": "Io",
            "Lifestealer": 1.881
        },
        {
            "name": "Jakiro",
            "Lifestealer": -0.539
        },
        {
            "name": "Juggernaut",
            "Lifestealer": 1.952
        },
        {
            "name": "Keeper of the Light",
            "Lifestealer": 0.905
        },
        {
            "name": "Kez",
            "Lifestealer": 2.043
        },
        {
            "name": "Kunkka",
            "Lifestealer": -2.075
        },
        {
            "name": "Legion Commander",
            "Lifestealer": 0.126
        },
        {
            "name": "Leshrac",
            "Lifestealer": -1.117
        },
        {
            "name": "Lich",
            "Lifestealer": 0.612
        },
        {
            "name": "Lina",
            "Lifestealer": 0.742
        },
        {
            "name": "Lion",
            "Lifestealer": 0.159
        },
        {
            "name": "Lone Druid",
            "Lifestealer": 2.135
        },
        {
            "name": "Luna",
            "Lifestealer": 0.824
        },
        {
            "name": "Lycan",
            "Lifestealer": 1.132
        },
        {
            "name": "Magnus",
            "Lifestealer": 0.604
        },
        {
            "name": "Marci",
            "Lifestealer": 2.017
        },
        {
            "name": "Mars",
            "Lifestealer": -2.317
        },
        {
            "name": "Medusa",
            "Lifestealer": 1.393
        },
        {
            "name": "Meepo",
            "Lifestealer": 1.442
        },
        {
            "name": "Mirana",
            "Lifestealer": 0.396
        },
        {
            "name": "Monkey King",
            "Lifestealer": 1.932
        },
        {
            "name": "Morphling",
            "Lifestealer": 3.533
        },
        {
            "name": "Muerta",
            "Lifestealer": 0.192
        },
        {
            "name": "Naga Siren",
            "Lifestealer": 1.569
        },
        {
            "name": "Nature's Prophet",
            "Lifestealer": 3.45
        },
        {
            "name": "Necrophos",
            "Lifestealer": 0.82
        },
        {
            "name": "Night Stalker",
            "Lifestealer": 0.897
        },
        {
            "name": "Nyx Assassin",
            "Lifestealer": -1.21
        },
        {
            "name": "Ogre Magi",
            "Lifestealer": 0.142
        },
        {
            "name": "Omniknight",
            "Lifestealer": 1.932
        },
        {
            "name": "Oracle",
            "Lifestealer": 0.66
        },
        {
            "name": "Outworld Destroyer",
            "Lifestealer": -0.297
        },
        {
            "name": "Pangolier",
            "Lifestealer": 2.661
        },
        {
            "name": "Phantom Assassin",
            "Lifestealer": 1.674
        },
        {
            "name": "Phantom Lancer",
            "Lifestealer": 0.848
        },
        {
            "name": "Phoenix",
            "Lifestealer": -1.469
        },
        {
            "name": "Primal Beast",
            "Lifestealer": -1.058
        },
        {
            "name": "Puck",
            "Lifestealer": 0.926
        },
        {
            "name": "Pudge",
            "Lifestealer": -0.62
        },
        {
            "name": "Pugna",
            "Lifestealer": 1.399
        },
        {
            "name": "Queen of Pain",
            "Lifestealer": 1.413
        },
        {
            "name": "Razor",
            "Lifestealer": 3.305
        },
        {
            "name": "Riki",
            "Lifestealer": 1.536
        },
        {
            "name": "Ringmaster",
            "Lifestealer": 0.391
        },
        {
            "name": "Rubick",
            "Lifestealer": -0.405
        },
        {
            "name": "Sand King",
            "Lifestealer": -2.244
        },
        {
            "name": "Shadow Demon",
            "Lifestealer": 0.205
        },
        {
            "name": "Shadow Fiend",
            "Lifestealer": 0.19
        },
        {
            "name": "Shadow Shaman",
            "Lifestealer": 0.641
        },
        {
            "name": "Silencer",
            "Lifestealer": 0.292
        },
        {
            "name": "Skywrath Mage",
            "Lifestealer": 1.568
        },
        {
            "name": "Slardar",
            "Lifestealer": 3.029
        },
        {
            "name": "Slark",
            "Lifestealer": 1.727
        },
        {
            "name": "Snapfire",
            "Lifestealer": -0.132
        },
        {
            "name": "Sniper",
            "Lifestealer": 0.253
        },
        {
            "name": "Spectre",
            "Lifestealer": -0.281
        },
        {
            "name": "Spirit Breaker",
            "Lifestealer": 1.488
        },
        {
            "name": "Storm Spirit",
            "Lifestealer": 1.699
        },
        {
            "name": "Sven",
            "Lifestealer": -1.049
        },
        {
            "name": "Techies",
            "Lifestealer": -1.857
        },
        {
            "name": "Templar Assassin",
            "Lifestealer": 1.69
        },
        {
            "name": "Terrorblade",
            "Lifestealer": 0.463
        },
        {
            "name": "Tidehunter",
            "Lifestealer": -0.533
        },
        {
            "name": "Timbersaw",
            "Lifestealer": -1.108
        },
        {
            "name": "Tinker",
            "Lifestealer": 0.032
        },
        {
            "name": "Tiny",
            "Lifestealer": -2.524
        },
        {
            "name": "Treant Protector",
            "Lifestealer": 0.394
        },
        {
            "name": "Troll Warlord",
            "Lifestealer": 1.753
        },
        {
            "name": "Tusk",
            "Lifestealer": 3.021
        },
        {
            "name": "Underlord",
            "Lifestealer": -2.402
        },
        {
            "name": "Undying",
            "Lifestealer": -0.304
        },
        {
            "name": "Ursa",
            "Lifestealer": 1.43
        },
        {
            "name": "Vengeful Spirit",
            "Lifestealer": 0.313
        },
        {
            "name": "Venomancer",
            "Lifestealer": 0.851
        },
        {
            "name": "Viper",
            "Lifestealer": 1.604
        },
        {
            "name": "Visage",
            "Lifestealer": 1.839
        },
        {
            "name": "Void Spirit",
            "Lifestealer": 1.557
        },
        {
            "name": "Warlock",
            "Lifestealer": -0.334
        },
        {
            "name": "Weaver",
            "Lifestealer": 1.724
        },
        {
            "name": "Windranger",
            "Lifestealer": 1.787
        },
        {
            "name": "Winter Wyvern",
            "Lifestealer": 0.445
        },
        {
            "name": "Witch Doctor",
            "Lifestealer": 0.881
        },
        {
            "name": "Wraith King",
            "Lifestealer": -1.698
        },
        {
            "name": "Zeus",
            "Lifestealer": -1.019
        }
    ],
    "Mars": [
        {
            "name": "Abaddon",
            "Mars": -0.7
        },
        {
            "name": "Alchemist",
            "Mars": 0.856
        },
        {
            "name": "Ancient Apparition",
            "Mars": 0.338
        },
        {
            "name": "Anti-Mage",
            "Mars": 2.118
        },
        {
            "name": "Arc Warden",
            "Mars": -0.79
        },
        {
            "name": "Axe",
            "Mars": -0.256
        },
        {
            "name": "Bane",
            "Mars": 0.412
        },
        {
            "name": "Batrider",
            "Mars": 0.496
        },
        {
            "name": "Beastmaster",
            "Mars": 1.001
        },
        {
            "name": "Bloodseeker",
            "Mars": 1.194
        },
        {
            "name": "Bounty Hunter",
            "Mars": 1.014
        },
        {
            "name": "Brewmaster",
            "Mars": -0.84
        },
        {
            "name": "Bristleback",
            "Mars": -0.455
        },
        {
            "name": "Broodmother",
            "Mars": -0.549
        },
        {
            "name": "Centaur Warrunner",
            "Mars": -0.911
        },
        {
            "name": "Chaos Knight",
            "Mars": 0.488
        },
        {
            "name": "Chen",
            "Mars": 4.512
        },
        {
            "name": "Clinkz",
            "Mars": -1.807
        },
        {
            "name": "Clockwerk",
            "Mars": 0.064
        },
        {
            "name": "Crystal Maiden",
            "Mars": -0.615
        },
        {
            "name": "Dark Seer",
            "Mars": 0.043
        },
        {
            "name": "Dark Willow",
            "Mars": -0.854
        },
        {
            "name": "Dawnbreaker",
            "Mars": 0.623
        },
        {
            "name": "Dazzle",
            "Mars": 0.958
        },
        {
            "name": "Death Prophet",
            "Mars": 1.291
        },
        {
            "name": "Disruptor",
            "Mars": 0.185
        },
        {
            "name": "Doom",
            "Mars": 0.571
        },
        {
            "name": "Dragon Knight",
            "Mars": -0.161
        },
        {
            "name": "Drow Ranger",
            "Mars": -3.096
        },
        {
            "name": "Earth Spirit",
            "Mars": 1.525
        },
        {
            "name": "Earthshaker",
            "Mars": -0.028
        },
        {
            "name": "Elder Titan",
            "Mars": 0.581
        },
        {
            "name": "Ember Spirit",
            "Mars": 2.085
        },
        {
            "name": "Enchantress",
            "Mars": -2.657
        },
        {
            "name": "Enigma",
            "Mars": 0.931
        },
        {
            "name": "Faceless Void",
            "Mars": 1.989
        },
        {
            "name": "Grimstroke",
            "Mars": -0.923
        },
        {
            "name": "Gyrocopter",
            "Mars": -1.074
        },
        {
            "name": "Hoodwink",
            "Mars": -1.259
        },
        {
            "name": "Huskar",
            "Mars": 1.75
        },
        {
            "name": "Invoker",
            "Mars": -0.156
        },
        {
            "name": "Io",
            "Mars": -1.362
        },
        {
            "name": "Jakiro",
            "Mars": 0.319
        },
        {
            "name": "Juggernaut",
            "Mars": 0.392
        },
        {
            "name": "Keeper of the Light",
            "Mars": 0.521
        },
        {
            "name": "Kez",
            "Mars": 2.336
        },
        {
            "name": "Kunkka",
            "Mars": 0.568
        },
        {
            "name": "Legion Commander",
            "Mars": -1.298
        },
        {
            "name": "Leshrac",
            "Mars": -0.12
        },
        {
            "name": "Lich",
            "Mars": 0.615
        },
        {
            "name": "Lifestealer",
            "Mars": 3.44
        },
        {
            "name": "Lina",
            "Mars": -1.027
        },
        {
            "name": "Lion",
            "Mars": -0.836
        },
        {
            "name": "Lone Druid",
            "Mars": 2.207
        },
        {
            "name": "Luna",
            "Mars": -1.181
        },
        {
            "name": "Lycan",
            "Mars": 1.245
        },
        {
            "name": "Magnus",
            "Mars": -0.585
        },
        {
            "name": "Marci",
            "Mars": -0.909
        },
        {
            "name": "Medusa",
            "Mars": -1.324
        },
        {
            "name": "Meepo",
            "Mars": 2.053
        },
        {
            "name": "Mirana",
            "Mars": -1.354
        },
        {
            "name": "Monkey King",
            "Mars": 0.44
        },
        {
            "name": "Morphling",
            "Mars": 1.171
        },
        {
            "name": "Muerta",
            "Mars": 0.72
        },
        {
            "name": "Naga Siren",
            "Mars": -0.477
        },
        {
            "name": "Nature's Prophet",
            "Mars": -1.213
        },
        {
            "name": "Necrophos",
            "Mars": 0.177
        },
        {
            "name": "Night Stalker",
            "Mars": 0.055
        },
        {
            "name": "Nyx Assassin",
            "Mars": 0.058
        },
        {
            "name": "Ogre Magi",
            "Mars": -0.385
        },
        {
            "name": "Omniknight",
            "Mars": 0.808
        },
        {
            "name": "Oracle",
            "Mars": -0.641
        },
        {
            "name": "Outworld Destroyer",
            "Mars": -0.735
        },
        {
            "name": "Pangolier",
            "Mars": 0.92
        },
        {
            "name": "Phantom Assassin",
            "Mars": 0.443
        },
        {
            "name": "Phantom Lancer",
            "Mars": 1.31
        },
        {
            "name": "Phoenix",
            "Mars": 0.845
        },
        {
            "name": "Primal Beast",
            "Mars": -0.032
        },
        {
            "name": "Puck",
            "Mars": 2.121
        },
        {
            "name": "Pudge",
            "Mars": -0.789
        },
        {
            "name": "Pugna",
            "Mars": 1.171
        },
        {
            "name": "Queen of Pain",
            "Mars": 2.315
        },
        {
            "name": "Razor",
            "Mars": -0.436
        },
        {
            "name": "Riki",
            "Mars": 0.859
        },
        {
            "name": "Ringmaster",
            "Mars": 0.408
        },
        {
            "name": "Rubick",
            "Mars": -0.835
        },
        {
            "name": "Sand King",
            "Mars": 0.03
        },
        {
            "name": "Shadow Demon",
            "Mars": -0.968
        },
        {
            "name": "Shadow Fiend",
            "Mars": -0.134
        },
        {
            "name": "Shadow Shaman",
            "Mars": -0.367
        },
        {
            "name": "Silencer",
            "Mars": 1.038
        },
        {
            "name": "Skywrath Mage",
            "Mars": -0.847
        },
        {
            "name": "Slardar",
            "Mars": 0.565
        },
        {
            "name": "Slark",
            "Mars": -3.806
        },
        {
            "name": "Snapfire",
            "Mars": -1.39
        },
        {
            "name": "Sniper",
            "Mars": -2.572
        },
        {
            "name": "Spectre",
            "Mars": 0.886
        },
        {
            "name": "Spirit Breaker",
            "Mars": -1.908
        },
        {
            "name": "Storm Spirit",
            "Mars": 1.535
        },
        {
            "name": "Sven",
            "Mars": 0.566
        },
        {
            "name": "Techies",
            "Mars": -1.186
        },
        {
            "name": "Templar Assassin",
            "Mars": -1.393
        },
        {
            "name": "Terrorblade",
            "Mars": -0.777
        },
        {
            "name": "Tidehunter",
            "Mars": -0.086
        },
        {
            "name": "Timbersaw",
            "Mars": -0.903
        },
        {
            "name": "Tinker",
            "Mars": 0.278
        },
        {
            "name": "Tiny",
            "Mars": -0.675
        },
        {
            "name": "Treant Protector",
            "Mars": -0.742
        },
        {
            "name": "Troll Warlord",
            "Mars": -0.222
        },
        {
            "name": "Tusk",
            "Mars": -0.15
        },
        {
            "name": "Underlord",
            "Mars": 0.406
        },
        {
            "name": "Undying",
            "Mars": 1.275
        },
        {
            "name": "Ursa",
            "Mars": -0.68
        },
        {
            "name": "Vengeful Spirit",
            "Mars": -0.18
        },
        {
            "name": "Venomancer",
            "Mars": 1.068
        },
        {
            "name": "Viper",
            "Mars": -0.266
        },
        {
            "name": "Visage",
            "Mars": -0.984
        },
        {
            "name": "Void Spirit",
            "Mars": 1.657
        },
        {
            "name": "Warlock",
            "Mars": 0.826
        },
        {
            "name": "Weaver",
            "Mars": -0.096
        },
        {
            "name": "Windranger",
            "Mars": -1.644
        },
        {
            "name": "Winter Wyvern",
            "Mars": -0.037
        },
        {
            "name": "Witch Doctor",
            "Mars": -0.193
        },
        {
            "name": "Wraith King",
            "Mars": 0.365
        },
        {
            "name": "Zeus",
            "Mars": -0.168
        }
    ],
    "Night Stalker": [
        {
            "name": "Abaddon",
            "Night Stalker": 0.214
        },
        {
            "name": "Alchemist",
            "Night Stalker": -0.443
        },
        {
            "name": "Ancient Apparition",
            "Night Stalker": -2.417
        },
        {
            "name": "Anti-Mage",
            "Night Stalker": -1.767
        },
        {
            "name": "Arc Warden",
            "Night Stalker": 2.871
        },
        {
            "name": "Axe",
            "Night Stalker": -0.686
        },
        {
            "name": "Bane",
            "Night Stalker": 1.294
        },
        {
            "name": "Batrider",
            "Night Stalker": 1.179
        },
        {
            "name": "Beastmaster",
            "Night Stalker": 3.735
        },
        {
            "name": "Bloodseeker",
            "Night Stalker": 2.248
        },
        {
            "name": "Bounty Hunter",
            "Night Stalker": 2.509
        },
        {
            "name": "Brewmaster",
            "Night Stalker": 0.627
        },
        {
            "name": "Bristleback",
            "Night Stalker": 1.097
        },
        {
            "name": "Broodmother",
            "Night Stalker": 4.133
        },
        {
            "name": "Centaur Warrunner",
            "Night Stalker": -0.167
        },
        {
            "name": "Chaos Knight",
            "Night Stalker": 1.87
        },
        {
            "name": "Chen",
            "Night Stalker": 1.852
        },
        {
            "name": "Clinkz",
            "Night Stalker": 0.947
        },
        {
            "name": "Clockwerk",
            "Night Stalker": 0.237
        },
        {
            "name": "Crystal Maiden",
            "Night Stalker": 0.749
        },
        {
            "name": "Dark Seer",
            "Night Stalker": 0.509
        },
        {
            "name": "Dark Willow",
            "Night Stalker": 0.982
        },
        {
            "name": "Dawnbreaker",
            "Night Stalker": -0.317
        },
        {
            "name": "Dazzle",
            "Night Stalker": 0.505
        },
        {
            "name": "Death Prophet",
            "Night Stalker": -0.533
        },
        {
            "name": "Disruptor",
            "Night Stalker": 1.035
        },
        {
            "name": "Doom",
            "Night Stalker": -0.69
        },
        {
            "name": "Dragon Knight",
            "Night Stalker": 1.547
        },
        {
            "name": "Drow Ranger",
            "Night Stalker": -1.426
        },
        {
            "name": "Earth Spirit",
            "Night Stalker": 0.599
        },
        {
            "name": "Earthshaker",
            "Night Stalker": -1.474
        },
        {
            "name": "Elder Titan",
            "Night Stalker": -0.552
        },
        {
            "name": "Ember Spirit",
            "Night Stalker": -0.749
        },
        {
            "name": "Enchantress",
            "Night Stalker": 1.727
        },
        {
            "name": "Enigma",
            "Night Stalker": 1.494
        },
        {
            "name": "Faceless Void",
            "Night Stalker": -0.814
        },
        {
            "name": "Grimstroke",
            "Night Stalker": -0.842
        },
        {
            "name": "Gyrocopter",
            "Night Stalker": 1.328
        },
        {
            "name": "Hoodwink",
            "Night Stalker": 0.095
        },
        {
            "name": "Huskar",
            "Night Stalker": 3.454
        },
        {
            "name": "Invoker",
            "Night Stalker": -0.186
        },
        {
            "name": "Io",
            "Night Stalker": 2.362
        },
        {
            "name": "Jakiro",
            "Night Stalker": -0.353
        },
        {
            "name": "Juggernaut",
            "Night Stalker": -2.613
        },
        {
            "name": "Keeper of the Light",
            "Night Stalker": 1.052
        },
        {
            "name": "Kez",
            "Night Stalker": 1.389
        },
        {
            "name": "Kunkka",
            "Night Stalker": 0.637
        },
        {
            "name": "Legion Commander",
            "Night Stalker": 0.025
        },
        {
            "name": "Leshrac",
            "Night Stalker": -0.266
        },
        {
            "name": "Lich",
            "Night Stalker": -0.14
        },
        {
            "name": "Lifestealer",
            "Night Stalker": -0.748
        },
        {
            "name": "Lina",
            "Night Stalker": -0.612
        },
        {
            "name": "Lion",
            "Night Stalker": -0.583
        },
        {
            "name": "Lone Druid",
            "Night Stalker": 4.373
        },
        {
            "name": "Luna",
            "Night Stalker": 2.924
        },
        {
            "name": "Lycan",
            "Night Stalker": 2.571
        },
        {
            "name": "Magnus",
            "Night Stalker": -0.177
        },
        {
            "name": "Marci",
            "Night Stalker": 2.799
        },
        {
            "name": "Mars",
            "Night Stalker": 0.071
        },
        {
            "name": "Medusa",
            "Night Stalker": -0.235
        },
        {
            "name": "Meepo",
            "Night Stalker": 4.194
        },
        {
            "name": "Mirana",
            "Night Stalker": 0.15
        },
        {
            "name": "Monkey King",
            "Night Stalker": 0.576
        },
        {
            "name": "Morphling",
            "Night Stalker": 0.796
        },
        {
            "name": "Muerta",
            "Night Stalker": -0.912
        },
        {
            "name": "Naga Siren",
            "Night Stalker": 5.405
        },
        {
            "name": "Nature's Prophet",
            "Night Stalker": -0.287
        },
        {
            "name": "Necrophos",
            "Night Stalker": -0.443
        },
        {
            "name": "Nyx Assassin",
            "Night Stalker": -1.088
        },
        {
            "name": "Ogre Magi",
            "Night Stalker": 2.316
        },
        {
            "name": "Omniknight",
            "Night Stalker": 1.052
        },
        {
            "name": "Oracle",
            "Night Stalker": -0.72
        },
        {
            "name": "Outworld Destroyer",
            "Night Stalker": -0.908
        },
        {
            "name": "Pangolier",
            "Night Stalker": -1.016
        },
        {
            "name": "Phantom Assassin",
            "Night Stalker": 1.246
        },
        {
            "name": "Phantom Lancer",
            "Night Stalker": 1.451
        },
        {
            "name": "Phoenix",
            "Night Stalker": 2.242
        },
        {
            "name": "Primal Beast",
            "Night Stalker": 0.634
        },
        {
            "name": "Puck",
            "Night Stalker": -4.112
        },
        {
            "name": "Pudge",
            "Night Stalker": -1.224
        },
        {
            "name": "Pugna",
            "Night Stalker": -1.283
        },
        {
            "name": "Queen of Pain",
            "Night Stalker": -1.091
        },
        {
            "name": "Razor",
            "Night Stalker": 0.824
        },
        {
            "name": "Riki",
            "Night Stalker": -1.145
        },
        {
            "name": "Ringmaster",
            "Night Stalker": -0.555
        },
        {
            "name": "Rubick",
            "Night Stalker": -0.757
        },
        {
            "name": "Sand King",
            "Night Stalker": -0.923
        },
        {
            "name": "Shadow Demon",
            "Night Stalker": 0.001
        },
        {
            "name": "Shadow Fiend",
            "Night Stalker": 0.754
        },
        {
            "name": "Shadow Shaman",
            "Night Stalker": 0.669
        },
        {
            "name": "Silencer",
            "Night Stalker": -1.791
        },
        {
            "name": "Skywrath Mage",
            "Night Stalker": -1.127
        },
        {
            "name": "Slardar",
            "Night Stalker": 0.964
        },
        {
            "name": "Slark",
            "Night Stalker": -3.327
        },
        {
            "name": "Snapfire",
            "Night Stalker": 0.02
        },
        {
            "name": "Sniper",
            "Night Stalker": 1.046
        },
        {
            "name": "Spectre",
            "Night Stalker": 0.03
        },
        {
            "name": "Spirit Breaker",
            "Night Stalker": 1.785
        },
        {
            "name": "Storm Spirit",
            "Night Stalker": -1.673
        },
        {
            "name": "Sven",
            "Night Stalker": 1.509
        },
        {
            "name": "Techies",
            "Night Stalker": 0.943
        },
        {
            "name": "Templar Assassin",
            "Night Stalker": 2.361
        },
        {
            "name": "Terrorblade",
            "Night Stalker": 3.548
        },
        {
            "name": "Tidehunter",
            "Night Stalker": -1.014
        },
        {
            "name": "Timbersaw",
            "Night Stalker": -1.41
        },
        {
            "name": "Tinker",
            "Night Stalker": -0.735
        },
        {
            "name": "Tiny",
            "Night Stalker": 1.101
        },
        {
            "name": "Treant Protector",
            "Night Stalker": 1.984
        },
        {
            "name": "Troll Warlord",
            "Night Stalker": 2.147
        },
        {
            "name": "Tusk",
            "Night Stalker": 0.663
        },
        {
            "name": "Underlord",
            "Night Stalker": 0.078
        },
        {
            "name": "Undying",
            "Night Stalker": 0.359
        },
        {
            "name": "Ursa",
            "Night Stalker": -1.289
        },
        {
            "name": "Vengeful Spirit",
            "Night Stalker": 1.052
        },
        {
            "name": "Venomancer",
            "Night Stalker": 1.979
        },
        {
            "name": "Viper",
            "Night Stalker": 1.729
        },
        {
            "name": "Visage",
            "Night Stalker": 2.065
        },
        {
            "name": "Void Spirit",
            "Night Stalker": -2.39
        },
        {
            "name": "Warlock",
            "Night Stalker": -0.784
        },
        {
            "name": "Weaver",
            "Night Stalker": -0.642
        },
        {
            "name": "Windranger",
            "Night Stalker": 0.614
        },
        {
            "name": "Winter Wyvern",
            "Night Stalker": 1.072
        },
        {
            "name": "Witch Doctor",
            "Night Stalker": -1.736
        },
        {
            "name": "Wraith King",
            "Night Stalker": 1.267
        },
        {
            "name": "Zeus",
            "Night Stalker": -0.259
        }
    ],
    "Ogre Magi": [
        {
            "name": "Abaddon",
            "Ogre Magi": 0.704
        },
        {
            "name": "Alchemist",
            "Ogre Magi": 0.061
        },
        {
            "name": "Ancient Apparition",
            "Ogre Magi": -0.074
        },
        {
            "name": "Anti-Mage",
            "Ogre Magi": 2.809
        },
        {
            "name": "Arc Warden",
            "Ogre Magi": 1.28
        },
        {
            "name": "Axe",
            "Ogre Magi": -0.66
        },
        {
            "name": "Bane",
            "Ogre Magi": -0.262
        },
        {
            "name": "Batrider",
            "Ogre Magi": 0.529
        },
        {
            "name": "Beastmaster",
            "Ogre Magi": 1.909
        },
        {
            "name": "Bloodseeker",
            "Ogre Magi": -0.744
        },
        {
            "name": "Bounty Hunter",
            "Ogre Magi": -0.31
        },
        {
            "name": "Brewmaster",
            "Ogre Magi": 0.375
        },
        {
            "name": "Bristleback",
            "Ogre Magi": -0.695
        },
        {
            "name": "Broodmother",
            "Ogre Magi": 1.134
        },
        {
            "name": "Centaur Warrunner",
            "Ogre Magi": -0.062
        },
        {
            "name": "Chaos Knight",
            "Ogre Magi": 3.354
        },
        {
            "name": "Chen",
            "Ogre Magi": 1.539
        },
        {
            "name": "Clinkz",
            "Ogre Magi": -0.716
        },
        {
            "name": "Clockwerk",
            "Ogre Magi": -0.553
        },
        {
            "name": "Crystal Maiden",
            "Ogre Magi": 0.207
        },
        {
            "name": "Dark Seer",
            "Ogre Magi": 0.575
        },
        {
            "name": "Dark Willow",
            "Ogre Magi": 0.646
        },
        {
            "name": "Dawnbreaker",
            "Ogre Magi": -0.465
        },
        {
            "name": "Dazzle",
            "Ogre Magi": -0.2
        },
        {
            "name": "Death Prophet",
            "Ogre Magi": -0.707
        },
        {
            "name": "Disruptor",
            "Ogre Magi": -0.668
        },
        {
            "name": "Doom",
            "Ogre Magi": 0.568
        },
        {
            "name": "Dragon Knight",
            "Ogre Magi": -1.163
        },
        {
            "name": "Drow Ranger",
            "Ogre Magi": -1.435
        },
        {
            "name": "Earth Spirit",
            "Ogre Magi": 0.784
        },
        {
            "name": "Earthshaker",
            "Ogre Magi": -0.213
        },
        {
            "name": "Elder Titan",
            "Ogre Magi": -1.102
        },
        {
            "name": "Ember Spirit",
            "Ogre Magi": 0.009
        },
        {
            "name": "Enchantress",
            "Ogre Magi": -1.086
        },
        {
            "name": "Enigma",
            "Ogre Magi": 2.008
        },
        {
            "name": "Faceless Void",
            "Ogre Magi": -0.213
        },
        {
            "name": "Grimstroke",
            "Ogre Magi": 0.071
        },
        {
            "name": "Gyrocopter",
            "Ogre Magi": -0.451
        },
        {
            "name": "Hoodwink",
            "Ogre Magi": -0.587
        },
        {
            "name": "Huskar",
            "Ogre Magi": 0.925
        },
        {
            "name": "Invoker",
            "Ogre Magi": 0.821
        },
        {
            "name": "Io",
            "Ogre Magi": -0.615
        },
        {
            "name": "Jakiro",
            "Ogre Magi": 0.106
        },
        {
            "name": "Juggernaut",
            "Ogre Magi": 0.973
        },
        {
            "name": "Keeper of the Light",
            "Ogre Magi": -0.314
        },
        {
            "name": "Kez",
            "Ogre Magi": 0.103
        },
        {
            "name": "Kunkka",
            "Ogre Magi": -0.189
        },
        {
            "name": "Legion Commander",
            "Ogre Magi": 0.701
        },
        {
            "name": "Leshrac",
            "Ogre Magi": -0.54
        },
        {
            "name": "Lich",
            "Ogre Magi": 0.091
        },
        {
            "name": "Lifestealer",
            "Ogre Magi": -0.159
        },
        {
            "name": "Lina",
            "Ogre Magi": 0.092
        },
        {
            "name": "Lion",
            "Ogre Magi": -0.075
        },
        {
            "name": "Lone Druid",
            "Ogre Magi": 0.479
        },
        {
            "name": "Luna",
            "Ogre Magi": 0.434
        },
        {
            "name": "Lycan",
            "Ogre Magi": 0.614
        },
        {
            "name": "Magnus",
            "Ogre Magi": 1.064
        },
        {
            "name": "Marci",
            "Ogre Magi": 0.982
        },
        {
            "name": "Mars",
            "Ogre Magi": 0.644
        },
        {
            "name": "Medusa",
            "Ogre Magi": -0.748
        },
        {
            "name": "Meepo",
            "Ogre Magi": 1.634
        },
        {
            "name": "Mirana",
            "Ogre Magi": -0.168
        },
        {
            "name": "Monkey King",
            "Ogre Magi": -1.011
        },
        {
            "name": "Morphling",
            "Ogre Magi": -0.133
        },
        {
            "name": "Muerta",
            "Ogre Magi": -0.579
        },
        {
            "name": "Naga Siren",
            "Ogre Magi": 3.81
        },
        {
            "name": "Nature's Prophet",
            "Ogre Magi": 0.423
        },
        {
            "name": "Necrophos",
            "Ogre Magi": 0.374
        },
        {
            "name": "Night Stalker",
            "Ogre Magi": -2.282
        },
        {
            "name": "Nyx Assassin",
            "Ogre Magi": -0.847
        },
        {
            "name": "Omniknight",
            "Ogre Magi": -0.402
        },
        {
            "name": "Oracle",
            "Ogre Magi": 1.051
        },
        {
            "name": "Outworld Destroyer",
            "Ogre Magi": -1.051
        },
        {
            "name": "Pangolier",
            "Ogre Magi": -0.272
        },
        {
            "name": "Phantom Assassin",
            "Ogre Magi": -1.081
        },
        {
            "name": "Phantom Lancer",
            "Ogre Magi": 4.639
        },
        {
            "name": "Phoenix",
            "Ogre Magi": -0.264
        },
        {
            "name": "Primal Beast",
            "Ogre Magi": 0.076
        },
        {
            "name": "Puck",
            "Ogre Magi": 0.842
        },
        {
            "name": "Pudge",
            "Ogre Magi": 0.925
        },
        {
            "name": "Pugna",
            "Ogre Magi": -0.326
        },
        {
            "name": "Queen of Pain",
            "Ogre Magi": -0.066
        },
        {
            "name": "Razor",
            "Ogre Magi": -0.44
        },
        {
            "name": "Riki",
            "Ogre Magi": -0.746
        },
        {
            "name": "Ringmaster",
            "Ogre Magi": 0.35
        },
        {
            "name": "Rubick",
            "Ogre Magi": -0.125
        },
        {
            "name": "Sand King",
            "Ogre Magi": 0.666
        },
        {
            "name": "Shadow Demon",
            "Ogre Magi": 0.922
        },
        {
            "name": "Shadow Fiend",
            "Ogre Magi": -0.846
        },
        {
            "name": "Shadow Shaman",
            "Ogre Magi": -0.126
        },
        {
            "name": "Silencer",
            "Ogre Magi": -1.045
        },
        {
            "name": "Skywrath Mage",
            "Ogre Magi": 0.024
        },
        {
            "name": "Slardar",
            "Ogre Magi": -0.656
        },
        {
            "name": "Slark",
            "Ogre Magi": 1.801
        },
        {
            "name": "Snapfire",
            "Ogre Magi": 0.196
        },
        {
            "name": "Sniper",
            "Ogre Magi": -0.253
        },
        {
            "name": "Spectre",
            "Ogre Magi": -0.106
        },
        {
            "name": "Spirit Breaker",
            "Ogre Magi": -0.402
        },
        {
            "name": "Storm Spirit",
            "Ogre Magi": 1.111
        },
        {
            "name": "Sven",
            "Ogre Magi": -0.746
        },
        {
            "name": "Techies",
            "Ogre Magi": 0.685
        },
        {
            "name": "Templar Assassin",
            "Ogre Magi": -0.416
        },
        {
            "name": "Terrorblade",
            "Ogre Magi": 0.97
        },
        {
            "name": "Tidehunter",
            "Ogre Magi": 0.534
        },
        {
            "name": "Timbersaw",
            "Ogre Magi": -0.235
        },
        {
            "name": "Tinker",
            "Ogre Magi": -0.365
        },
        {
            "name": "Tiny",
            "Ogre Magi": -0.923
        },
        {
            "name": "Treant Protector",
            "Ogre Magi": 0.965
        },
        {
            "name": "Troll Warlord",
            "Ogre Magi": -1.319
        },
        {
            "name": "Tusk",
            "Ogre Magi": 0.748
        },
        {
            "name": "Underlord",
            "Ogre Magi": 0.893
        },
        {
            "name": "Undying",
            "Ogre Magi": -0.796
        },
        {
            "name": "Ursa",
            "Ogre Magi": -2.032
        },
        {
            "name": "Vengeful Spirit",
            "Ogre Magi": -0.075
        },
        {
            "name": "Venomancer",
            "Ogre Magi": 0.791
        },
        {
            "name": "Viper",
            "Ogre Magi": 0.568
        },
        {
            "name": "Visage",
            "Ogre Magi": -0.174
        },
        {
            "name": "Void Spirit",
            "Ogre Magi": -0.344
        },
        {
            "name": "Warlock",
            "Ogre Magi": 1.616
        },
        {
            "name": "Weaver",
            "Ogre Magi": -0.372
        },
        {
            "name": "Windranger",
            "Ogre Magi": -0.576
        },
        {
            "name": "Winter Wyvern",
            "Ogre Magi": 0.489
        },
        {
            "name": "Witch Doctor",
            "Ogre Magi": 0.323
        },
        {
            "name": "Wraith King",
            "Ogre Magi": -0.587
        },
        {
            "name": "Zeus",
            "Ogre Magi": -0.333
        }
    ],
    "Omniknight": [
        {
            "name": "Abaddon",
            "Omniknight": -0.85
        },
        {
            "name": "Alchemist",
            "Omniknight": -1.104
        },
        {
            "name": "Ancient Apparition",
            "Omniknight": 3.526
        },
        {
            "name": "Anti-Mage",
            "Omniknight": -0.868
        },
        {
            "name": "Arc Warden",
            "Omniknight": 0.439
        },
        {
            "name": "Axe",
            "Omniknight": 0.167
        },
        {
            "name": "Bane",
            "Omniknight": 1.551
        },
        {
            "name": "Batrider",
            "Omniknight": 1.224
        },
        {
            "name": "Beastmaster",
            "Omniknight": -0.321
        },
        {
            "name": "Bloodseeker",
            "Omniknight": 0.965
        },
        {
            "name": "Bounty Hunter",
            "Omniknight": 0.911
        },
        {
            "name": "Brewmaster",
            "Omniknight": 1.861
        },
        {
            "name": "Bristleback",
            "Omniknight": 1.282
        },
        {
            "name": "Broodmother",
            "Omniknight": -3.25
        },
        {
            "name": "Centaur Warrunner",
            "Omniknight": -0.774
        },
        {
            "name": "Chaos Knight",
            "Omniknight": 1.278
        },
        {
            "name": "Chen",
            "Omniknight": 0.21
        },
        {
            "name": "Clinkz",
            "Omniknight": -1.138
        },
        {
            "name": "Clockwerk",
            "Omniknight": -0.963
        },
        {
            "name": "Crystal Maiden",
            "Omniknight": 0.61
        },
        {
            "name": "Dark Seer",
            "Omniknight": -0.494
        },
        {
            "name": "Dark Willow",
            "Omniknight": -1.616
        },
        {
            "name": "Dawnbreaker",
            "Omniknight": -0.157
        },
        {
            "name": "Dazzle",
            "Omniknight": -0.965
        },
        {
            "name": "Death Prophet",
            "Omniknight": -0.199
        },
        {
            "name": "Disruptor",
            "Omniknight": -1.553
        },
        {
            "name": "Doom",
            "Omniknight": 4.059
        },
        {
            "name": "Dragon Knight",
            "Omniknight": 1.18
        },
        {
            "name": "Drow Ranger",
            "Omniknight": 1.096
        },
        {
            "name": "Earth Spirit",
            "Omniknight": -0.227
        },
        {
            "name": "Earthshaker",
            "Omniknight": 1.468
        },
        {
            "name": "Elder Titan",
            "Omniknight": 1.118
        },
        {
            "name": "Ember Spirit",
            "Omniknight": -0.577
        },
        {
            "name": "Enchantress",
            "Omniknight": 0.116
        },
        {
            "name": "Enigma",
            "Omniknight": 1.666
        },
        {
            "name": "Faceless Void",
            "Omniknight": 2.263
        },
        {
            "name": "Grimstroke",
            "Omniknight": 0.896
        },
        {
            "name": "Gyrocopter",
            "Omniknight": -0.639
        },
        {
            "name": "Hoodwink",
            "Omniknight": -0.267
        },
        {
            "name": "Huskar",
            "Omniknight": -0.279
        },
        {
            "name": "Invoker",
            "Omniknight": -0.864
        },
        {
            "name": "Io",
            "Omniknight": -0.86
        },
        {
            "name": "Jakiro",
            "Omniknight": 0.788
        },
        {
            "name": "Juggernaut",
            "Omniknight": -0.444
        },
        {
            "name": "Keeper of the Light",
            "Omniknight": -0.138
        },
        {
            "name": "Kez",
            "Omniknight": -1.209
        },
        {
            "name": "Kunkka",
            "Omniknight": 0.155
        },
        {
            "name": "Legion Commander",
            "Omniknight": -2.608
        },
        {
            "name": "Leshrac",
            "Omniknight": 2.504
        },
        {
            "name": "Lich",
            "Omniknight": 0.008
        },
        {
            "name": "Lifestealer",
            "Omniknight": -1.251
        },
        {
            "name": "Lina",
            "Omniknight": 0.482
        },
        {
            "name": "Lion",
            "Omniknight": -1.551
        },
        {
            "name": "Lone Druid",
            "Omniknight": -1.381
        },
        {
            "name": "Luna",
            "Omniknight": 0.671
        },
        {
            "name": "Lycan",
            "Omniknight": 0.128
        },
        {
            "name": "Magnus",
            "Omniknight": 0.232
        },
        {
            "name": "Marci",
            "Omniknight": -1.136
        },
        {
            "name": "Mars",
            "Omniknight": -0.903
        },
        {
            "name": "Medusa",
            "Omniknight": 0.925
        },
        {
            "name": "Meepo",
            "Omniknight": -3.548
        },
        {
            "name": "Mirana",
            "Omniknight": -1.101
        },
        {
            "name": "Monkey King",
            "Omniknight": 0.519
        },
        {
            "name": "Morphling",
            "Omniknight": 0.7
        },
        {
            "name": "Muerta",
            "Omniknight": 1.41
        },
        {
            "name": "Naga Siren",
            "Omniknight": 0.925
        },
        {
            "name": "Nature's Prophet",
            "Omniknight": 1.48
        },
        {
            "name": "Necrophos",
            "Omniknight": 0.789
        },
        {
            "name": "Night Stalker",
            "Omniknight": -0.908
        },
        {
            "name": "Nyx Assassin",
            "Omniknight": -0.027
        },
        {
            "name": "Ogre Magi",
            "Omniknight": 0.789
        },
        {
            "name": "Oracle",
            "Omniknight": -1.439
        },
        {
            "name": "Outworld Destroyer",
            "Omniknight": 2.542
        },
        {
            "name": "Pangolier",
            "Omniknight": -1.476
        },
        {
            "name": "Phantom Assassin",
            "Omniknight": -1.313
        },
        {
            "name": "Phantom Lancer",
            "Omniknight": 3.672
        },
        {
            "name": "Phoenix",
            "Omniknight": 0.299
        },
        {
            "name": "Primal Beast",
            "Omniknight": -1.104
        },
        {
            "name": "Puck",
            "Omniknight": -1.49
        },
        {
            "name": "Pudge",
            "Omniknight": 0.342
        },
        {
            "name": "Pugna",
            "Omniknight": 1.932
        },
        {
            "name": "Queen of Pain",
            "Omniknight": 1.261
        },
        {
            "name": "Razor",
            "Omniknight": 1.747
        },
        {
            "name": "Riki",
            "Omniknight": -0.483
        },
        {
            "name": "Ringmaster",
            "Omniknight": 0.214
        },
        {
            "name": "Rubick",
            "Omniknight": -0.194
        },
        {
            "name": "Sand King",
            "Omniknight": 0.752
        },
        {
            "name": "Shadow Demon",
            "Omniknight": 3.498
        },
        {
            "name": "Shadow Fiend",
            "Omniknight": 0.654
        },
        {
            "name": "Shadow Shaman",
            "Omniknight": -1.38
        },
        {
            "name": "Silencer",
            "Omniknight": 0.624
        },
        {
            "name": "Skywrath Mage",
            "Omniknight": -1.067
        },
        {
            "name": "Slardar",
            "Omniknight": 1.541
        },
        {
            "name": "Slark",
            "Omniknight": -1.744
        },
        {
            "name": "Snapfire",
            "Omniknight": 0.598
        },
        {
            "name": "Sniper",
            "Omniknight": 0.608
        },
        {
            "name": "Spectre",
            "Omniknight": -1.505
        },
        {
            "name": "Spirit Breaker",
            "Omniknight": -0.317
        },
        {
            "name": "Storm Spirit",
            "Omniknight": -1.776
        },
        {
            "name": "Sven",
            "Omniknight": 0.351
        },
        {
            "name": "Techies",
            "Omniknight": 0.475
        },
        {
            "name": "Templar Assassin",
            "Omniknight": 0.251
        },
        {
            "name": "Terrorblade",
            "Omniknight": -0.154
        },
        {
            "name": "Tidehunter",
            "Omniknight": -2.51
        },
        {
            "name": "Timbersaw",
            "Omniknight": -0.188
        },
        {
            "name": "Tinker",
            "Omniknight": -0.321
        },
        {
            "name": "Tiny",
            "Omniknight": 0.32
        },
        {
            "name": "Treant Protector",
            "Omniknight": 0.046
        },
        {
            "name": "Troll Warlord",
            "Omniknight": -1.193
        },
        {
            "name": "Tusk",
            "Omniknight": 0.041
        },
        {
            "name": "Underlord",
            "Omniknight": -0.02
        },
        {
            "name": "Undying",
            "Omniknight": 2.061
        },
        {
            "name": "Ursa",
            "Omniknight": -1.36
        },
        {
            "name": "Vengeful Spirit",
            "Omniknight": -0.678
        },
        {
            "name": "Venomancer",
            "Omniknight": 0.184
        },
        {
            "name": "Viper",
            "Omniknight": -0.352
        },
        {
            "name": "Visage",
            "Omniknight": -2.41
        },
        {
            "name": "Void Spirit",
            "Omniknight": -1.203
        },
        {
            "name": "Warlock",
            "Omniknight": 1.661
        },
        {
            "name": "Weaver",
            "Omniknight": 0.796
        },
        {
            "name": "Windranger",
            "Omniknight": 0.489
        },
        {
            "name": "Winter Wyvern",
            "Omniknight": 0.668
        },
        {
            "name": "Witch Doctor",
            "Omniknight": 1.677
        },
        {
            "name": "Wraith King",
            "Omniknight": -0.362
        },
        {
            "name": "Zeus",
            "Omniknight": -0.568
        }
    ],
    "Primal Beast": [
        {
            "name": "Abaddon",
            "Primal Beast": -0.38
        },
        {
            "name": "Alchemist",
            "Primal Beast": 0.081
        },
        {
            "name": "Ancient Apparition",
            "Primal Beast": 0.463
        },
        {
            "name": "Anti-Mage",
            "Primal Beast": 1.282
        },
        {
            "name": "Arc Warden",
            "Primal Beast": -1.775
        },
        {
            "name": "Axe",
            "Primal Beast": -2.684
        },
        {
            "name": "Bane",
            "Primal Beast": 0.474
        },
        {
            "name": "Batrider",
            "Primal Beast": 0.009
        },
        {
            "name": "Beastmaster",
            "Primal Beast": -0.082
        },
        {
            "name": "Bloodseeker",
            "Primal Beast": 0.701
        },
        {
            "name": "Bounty Hunter",
            "Primal Beast": -0.595
        },
        {
            "name": "Brewmaster",
            "Primal Beast": 0.952
        },
        {
            "name": "Bristleback",
            "Primal Beast": -3.383
        },
        {
            "name": "Broodmother",
            "Primal Beast": -1.587
        },
        {
            "name": "Centaur Warrunner",
            "Primal Beast": 1.45
        },
        {
            "name": "Chaos Knight",
            "Primal Beast": -0.291
        },
        {
            "name": "Chen",
            "Primal Beast": -2.575
        },
        {
            "name": "Clinkz",
            "Primal Beast": 0.557
        },
        {
            "name": "Clockwerk",
            "Primal Beast": 2.478
        },
        {
            "name": "Crystal Maiden",
            "Primal Beast": 0.006
        },
        {
            "name": "Dark Seer",
            "Primal Beast": 0.767
        },
        {
            "name": "Dark Willow",
            "Primal Beast": -0.031
        },
        {
            "name": "Dawnbreaker",
            "Primal Beast": 0.373
        },
        {
            "name": "Dazzle",
            "Primal Beast": -0.487
        },
        {
            "name": "Death Prophet",
            "Primal Beast": 0.041
        },
        {
            "name": "Disruptor",
            "Primal Beast": 1.581
        },
        {
            "name": "Doom",
            "Primal Beast": 0.638
        },
        {
            "name": "Dragon Knight",
            "Primal Beast": 0.692
        },
        {
            "name": "Drow Ranger",
            "Primal Beast": -1.53
        },
        {
            "name": "Earth Spirit",
            "Primal Beast": -1.012
        },
        {
            "name": "Earthshaker",
            "Primal Beast": -0.111
        },
        {
            "name": "Elder Titan",
            "Primal Beast": -1.15
        },
        {
            "name": "Ember Spirit",
            "Primal Beast": 1.651
        },
        {
            "name": "Enchantress",
            "Primal Beast": -1.852
        },
        {
            "name": "Enigma",
            "Primal Beast": 1.709
        },
        {
            "name": "Faceless Void",
            "Primal Beast": 2.373
        },
        {
            "name": "Grimstroke",
            "Primal Beast": 2.805
        },
        {
            "name": "Gyrocopter",
            "Primal Beast": 1.197
        },
        {
            "name": "Hoodwink",
            "Primal Beast": -1.609
        },
        {
            "name": "Huskar",
            "Primal Beast": 1.067
        },
        {
            "name": "Invoker",
            "Primal Beast": 0.496
        },
        {
            "name": "Io",
            "Primal Beast": -0.926
        },
        {
            "name": "Jakiro",
            "Primal Beast": 1.526
        },
        {
            "name": "Juggernaut",
            "Primal Beast": 1.671
        },
        {
            "name": "Keeper of the Light",
            "Primal Beast": -0.363
        },
        {
            "name": "Kez",
            "Primal Beast": -0.519
        },
        {
            "name": "Kunkka",
            "Primal Beast": -0.012
        },
        {
            "name": "Legion Commander",
            "Primal Beast": -0.876
        },
        {
            "name": "Leshrac",
            "Primal Beast": 1.769
        },
        {
            "name": "Lich",
            "Primal Beast": 0.947
        },
        {
            "name": "Lifestealer",
            "Primal Beast": 2.639
        },
        {
            "name": "Lina",
            "Primal Beast": 0.227
        },
        {
            "name": "Lion",
            "Primal Beast": 1.253
        },
        {
            "name": "Lone Druid",
            "Primal Beast": 0.213
        },
        {
            "name": "Luna",
            "Primal Beast": -0.068
        },
        {
            "name": "Lycan",
            "Primal Beast": -0.477
        },
        {
            "name": "Magnus",
            "Primal Beast": -0.148
        },
        {
            "name": "Marci",
            "Primal Beast": -1.634
        },
        {
            "name": "Mars",
            "Primal Beast": 0.109
        },
        {
            "name": "Medusa",
            "Primal Beast": 0.516
        },
        {
            "name": "Meepo",
            "Primal Beast": -0.169
        },
        {
            "name": "Mirana",
            "Primal Beast": 0.282
        },
        {
            "name": "Monkey King",
            "Primal Beast": -0.804
        },
        {
            "name": "Morphling",
            "Primal Beast": 0.693
        },
        {
            "name": "Muerta",
            "Primal Beast": -2.425
        },
        {
            "name": "Naga Siren",
            "Primal Beast": -4.07
        },
        {
            "name": "Nature's Prophet",
            "Primal Beast": -1.405
        },
        {
            "name": "Necrophos",
            "Primal Beast": 0.025
        },
        {
            "name": "Night Stalker",
            "Primal Beast": -0.218
        },
        {
            "name": "Nyx Assassin",
            "Primal Beast": 0.723
        },
        {
            "name": "Ogre Magi",
            "Primal Beast": 0.602
        },
        {
            "name": "Omniknight",
            "Primal Beast": 1.082
        },
        {
            "name": "Oracle",
            "Primal Beast": 1.022
        },
        {
            "name": "Outworld Destroyer",
            "Primal Beast": 2.212
        },
        {
            "name": "Pangolier",
            "Primal Beast": -3.565
        },
        {
            "name": "Phantom Assassin",
            "Primal Beast": -3.005
        },
        {
            "name": "Phantom Lancer",
            "Primal Beast": -1.544
        },
        {
            "name": "Phoenix",
            "Primal Beast": -0.007
        },
        {
            "name": "Puck",
            "Primal Beast": 0.828
        },
        {
            "name": "Pudge",
            "Primal Beast": -0.241
        },
        {
            "name": "Pugna",
            "Primal Beast": -1.365
        },
        {
            "name": "Queen of Pain",
            "Primal Beast": 0.798
        },
        {
            "name": "Razor",
            "Primal Beast": -1.739
        },
        {
            "name": "Riki",
            "Primal Beast": -1.63
        },
        {
            "name": "Ringmaster",
            "Primal Beast": -0.212
        },
        {
            "name": "Rubick",
            "Primal Beast": -0.549
        },
        {
            "name": "Sand King",
            "Primal Beast": 0.505
        },
        {
            "name": "Shadow Demon",
            "Primal Beast": 0.414
        },
        {
            "name": "Shadow Fiend",
            "Primal Beast": -2.356
        },
        {
            "name": "Shadow Shaman",
            "Primal Beast": 0.79
        },
        {
            "name": "Silencer",
            "Primal Beast": 1.25
        },
        {
            "name": "Skywrath Mage",
            "Primal Beast": 1.355
        },
        {
            "name": "Slardar",
            "Primal Beast": 0.919
        },
        {
            "name": "Slark",
            "Primal Beast": -0.826
        },
        {
            "name": "Snapfire",
            "Primal Beast": -0.346
        },
        {
            "name": "Sniper",
            "Primal Beast": -2.514
        },
        {
            "name": "Spectre",
            "Primal Beast": -0.5
        },
        {
            "name": "Spirit Breaker",
            "Primal Beast": -1.09
        },
        {
            "name": "Storm Spirit",
            "Primal Beast": 1.287
        },
        {
            "name": "Sven",
            "Primal Beast": 0.676
        },
        {
            "name": "Techies",
            "Primal Beast": -0.432
        },
        {
            "name": "Templar Assassin",
            "Primal Beast": -0.593
        },
        {
            "name": "Terrorblade",
            "Primal Beast": -0.552
        },
        {
            "name": "Tidehunter",
            "Primal Beast": -0.722
        },
        {
            "name": "Timbersaw",
            "Primal Beast": -0.791
        },
        {
            "name": "Tinker",
            "Primal Beast": -1.051
        },
        {
            "name": "Tiny",
            "Primal Beast": 1.087
        },
        {
            "name": "Treant Protector",
            "Primal Beast": -0.879
        },
        {
            "name": "Troll Warlord",
            "Primal Beast": -0.204
        },
        {
            "name": "Tusk",
            "Primal Beast": -0.569
        },
        {
            "name": "Underlord",
            "Primal Beast": 3.736
        },
        {
            "name": "Undying",
            "Primal Beast": 0.653
        },
        {
            "name": "Ursa",
            "Primal Beast": -0.653
        },
        {
            "name": "Vengeful Spirit",
            "Primal Beast": -0.427
        },
        {
            "name": "Venomancer",
            "Primal Beast": 0.238
        },
        {
            "name": "Viper",
            "Primal Beast": -0.167
        },
        {
            "name": "Visage",
            "Primal Beast": -0.499
        },
        {
            "name": "Void Spirit",
            "Primal Beast": -1.117
        },
        {
            "name": "Warlock",
            "Primal Beast": -0.635
        },
        {
            "name": "Weaver",
            "Primal Beast": 0.616
        },
        {
            "name": "Windranger",
            "Primal Beast": -0.835
        },
        {
            "name": "Winter Wyvern",
            "Primal Beast": -0.411
        },
        {
            "name": "Witch Doctor",
            "Primal Beast": 1.362
        },
        {
            "name": "Wraith King",
            "Primal Beast": 0.25
        },
        {
            "name": "Zeus",
            "Primal Beast": 1.133
        }
    ],
    "Pudge": [
        {
            "name": "Abaddon",
            "Pudge": -1.378
        },
        {
            "name": "Alchemist",
            "Pudge": 0.114
        },
        {
            "name": "Ancient Apparition",
            "Pudge": -0.814
        },
        {
            "name": "Anti-Mage",
            "Pudge": 0.701
        },
        {
            "name": "Arc Warden",
            "Pudge": 0.305
        },
        {
            "name": "Axe",
            "Pudge": -2.153
        },
        {
            "name": "Bane",
            "Pudge": -0.814
        },
        {
            "name": "Batrider",
            "Pudge": 1.675
        },
        {
            "name": "Beastmaster",
            "Pudge": 1.306
        },
        {
            "name": "Bloodseeker",
            "Pudge": -1.734
        },
        {
            "name": "Bounty Hunter",
            "Pudge": -0.092
        },
        {
            "name": "Brewmaster",
            "Pudge": 0.205
        },
        {
            "name": "Bristleback",
            "Pudge": 1.08
        },
        {
            "name": "Broodmother",
            "Pudge": 0.261
        },
        {
            "name": "Centaur Warrunner",
            "Pudge": 0.103
        },
        {
            "name": "Chaos Knight",
            "Pudge": 0.827
        },
        {
            "name": "Chen",
            "Pudge": 2.508
        },
        {
            "name": "Clinkz",
            "Pudge": -1.704
        },
        {
            "name": "Clockwerk",
            "Pudge": 0.496
        },
        {
            "name": "Crystal Maiden",
            "Pudge": -1.328
        },
        {
            "name": "Dark Seer",
            "Pudge": -0.78
        },
        {
            "name": "Dark Willow",
            "Pudge": 0.267
        },
        {
            "name": "Dawnbreaker",
            "Pudge": 0.853
        },
        {
            "name": "Dazzle",
            "Pudge": 0.367
        },
        {
            "name": "Death Prophet",
            "Pudge": 0.84
        },
        {
            "name": "Disruptor",
            "Pudge": 0.132
        },
        {
            "name": "Doom",
            "Pudge": 1.33
        },
        {
            "name": "Dragon Knight",
            "Pudge": -0.297
        },
        {
            "name": "Drow Ranger",
            "Pudge": -1.872
        },
        {
            "name": "Earth Spirit",
            "Pudge": 0.93
        },
        {
            "name": "Earthshaker",
            "Pudge": 0.385
        },
        {
            "name": "Elder Titan",
            "Pudge": 0.362
        },
        {
            "name": "Ember Spirit",
            "Pudge": 0.392
        },
        {
            "name": "Enchantress",
            "Pudge": -0.565
        },
        {
            "name": "Enigma",
            "Pudge": 0.421
        },
        {
            "name": "Faceless Void",
            "Pudge": 0.015
        },
        {
            "name": "Grimstroke",
            "Pudge": 0.497
        },
        {
            "name": "Gyrocopter",
            "Pudge": 0.056
        },
        {
            "name": "Hoodwink",
            "Pudge": 1.394
        },
        {
            "name": "Huskar",
            "Pudge": -0.641
        },
        {
            "name": "Invoker",
            "Pudge": 0.621
        },
        {
            "name": "Io",
            "Pudge": -0.056
        },
        {
            "name": "Jakiro",
            "Pudge": -0.605
        },
        {
            "name": "Juggernaut",
            "Pudge": -0.813
        },
        {
            "name": "Keeper of the Light",
            "Pudge": 0.263
        },
        {
            "name": "Kez",
            "Pudge": 1.214
        },
        {
            "name": "Kunkka",
            "Pudge": 0.919
        },
        {
            "name": "Legion Commander",
            "Pudge": -1.831
        },
        {
            "name": "Leshrac",
            "Pudge": -0.047
        },
        {
            "name": "Lich",
            "Pudge": -1.029
        },
        {
            "name": "Lifestealer",
            "Pudge": -0.221
        },
        {
            "name": "Lina",
            "Pudge": -0.118
        },
        {
            "name": "Lion",
            "Pudge": -0.336
        },
        {
            "name": "Lone Druid",
            "Pudge": 0.631
        },
        {
            "name": "Luna",
            "Pudge": -0.56
        },
        {
            "name": "Lycan",
            "Pudge": 1.92
        },
        {
            "name": "Magnus",
            "Pudge": 1.581
        },
        {
            "name": "Marci",
            "Pudge": 2.386
        },
        {
            "name": "Mars",
            "Pudge": 0.931
        },
        {
            "name": "Medusa",
            "Pudge": -0.608
        },
        {
            "name": "Meepo",
            "Pudge": -0.386
        },
        {
            "name": "Mirana",
            "Pudge": -0.135
        },
        {
            "name": "Monkey King",
            "Pudge": 1.809
        },
        {
            "name": "Morphling",
            "Pudge": 0.803
        },
        {
            "name": "Muerta",
            "Pudge": 0.212
        },
        {
            "name": "Naga Siren",
            "Pudge": -0.461
        },
        {
            "name": "Nature's Prophet",
            "Pudge": -0.722
        },
        {
            "name": "Necrophos",
            "Pudge": -2.283
        },
        {
            "name": "Night Stalker",
            "Pudge": 0.451
        },
        {
            "name": "Nyx Assassin",
            "Pudge": -0.728
        },
        {
            "name": "Ogre Magi",
            "Pudge": -1.013
        },
        {
            "name": "Omniknight",
            "Pudge": -0.075
        },
        {
            "name": "Oracle",
            "Pudge": -1.276
        },
        {
            "name": "Outworld Destroyer",
            "Pudge": -0.405
        },
        {
            "name": "Pangolier",
            "Pudge": 2.483
        },
        {
            "name": "Phantom Assassin",
            "Pudge": -1.657
        },
        {
            "name": "Phantom Lancer",
            "Pudge": 0.413
        },
        {
            "name": "Phoenix",
            "Pudge": 0.563
        },
        {
            "name": "Primal Beast",
            "Pudge": 0.972
        },
        {
            "name": "Puck",
            "Pudge": 0.79
        },
        {
            "name": "Pugna",
            "Pudge": -2.234
        },
        {
            "name": "Queen of Pain",
            "Pudge": 0.627
        },
        {
            "name": "Razor",
            "Pudge": -0.842
        },
        {
            "name": "Riki",
            "Pudge": -0.304
        },
        {
            "name": "Ringmaster",
            "Pudge": 1.6
        },
        {
            "name": "Rubick",
            "Pudge": -0.84
        },
        {
            "name": "Sand King",
            "Pudge": -1.077
        },
        {
            "name": "Shadow Demon",
            "Pudge": -0.094
        },
        {
            "name": "Shadow Fiend",
            "Pudge": 0.063
        },
        {
            "name": "Shadow Shaman",
            "Pudge": -1.267
        },
        {
            "name": "Silencer",
            "Pudge": 0.309
        },
        {
            "name": "Skywrath Mage",
            "Pudge": 0.637
        },
        {
            "name": "Slardar",
            "Pudge": 1.349
        },
        {
            "name": "Slark",
            "Pudge": -0.433
        },
        {
            "name": "Snapfire",
            "Pudge": 0.388
        },
        {
            "name": "Sniper",
            "Pudge": -2.557
        },
        {
            "name": "Spectre",
            "Pudge": -1.329
        },
        {
            "name": "Spirit Breaker",
            "Pudge": -0.726
        },
        {
            "name": "Storm Spirit",
            "Pudge": 0.889
        },
        {
            "name": "Sven",
            "Pudge": 1.435
        },
        {
            "name": "Techies",
            "Pudge": -0.192
        },
        {
            "name": "Templar Assassin",
            "Pudge": 0.773
        },
        {
            "name": "Terrorblade",
            "Pudge": 1.52
        },
        {
            "name": "Tidehunter",
            "Pudge": -0.297
        },
        {
            "name": "Timbersaw",
            "Pudge": 1.87
        },
        {
            "name": "Tinker",
            "Pudge": -1.743
        },
        {
            "name": "Tiny",
            "Pudge": 0.561
        },
        {
            "name": "Treant Protector",
            "Pudge": -0.331
        },
        {
            "name": "Troll Warlord",
            "Pudge": -0.226
        },
        {
            "name": "Tusk",
            "Pudge": 1.303
        },
        {
            "name": "Underlord",
            "Pudge": -1.542
        },
        {
            "name": "Undying",
            "Pudge": 0.43
        },
        {
            "name": "Ursa",
            "Pudge": 2.018
        },
        {
            "name": "Vengeful Spirit",
            "Pudge": -0.269
        },
        {
            "name": "Venomancer",
            "Pudge": -0.708
        },
        {
            "name": "Viper",
            "Pudge": -1.923
        },
        {
            "name": "Visage",
            "Pudge": -0.47
        },
        {
            "name": "Void Spirit",
            "Pudge": 0.798
        },
        {
            "name": "Warlock",
            "Pudge": -0.449
        },
        {
            "name": "Weaver",
            "Pudge": 1.392
        },
        {
            "name": "Windranger",
            "Pudge": 1.108
        },
        {
            "name": "Winter Wyvern",
            "Pudge": -0.782
        },
        {
            "name": "Witch Doctor",
            "Pudge": -0.193
        },
        {
            "name": "Wraith King",
            "Pudge": -0.183
        },
        {
            "name": "Zeus",
            "Pudge": -0.553
        }
    ],
    "Slardar": [
        {
            "name": "Abaddon",
            "Slardar": 0.502
        },
        {
            "name": "Alchemist",
            "Slardar": -0.953
        },
        {
            "name": "Ancient Apparition",
            "Slardar": -0.863
        },
        {
            "name": "Anti-Mage",
            "Slardar": -3.714
        },
        {
            "name": "Arc Warden",
            "Slardar": 1.276
        },
        {
            "name": "Axe",
            "Slardar": 0.232
        },
        {
            "name": "Bane",
            "Slardar": -1.097
        },
        {
            "name": "Batrider",
            "Slardar": 0.369
        },
        {
            "name": "Beastmaster",
            "Slardar": 1.721
        },
        {
            "name": "Bloodseeker",
            "Slardar": -0.02
        },
        {
            "name": "Bounty Hunter",
            "Slardar": -0.454
        },
        {
            "name": "Brewmaster",
            "Slardar": 0.121
        },
        {
            "name": "Bristleback",
            "Slardar": -1.321
        },
        {
            "name": "Broodmother",
            "Slardar": -1.573
        },
        {
            "name": "Centaur Warrunner",
            "Slardar": -1.561
        },
        {
            "name": "Chaos Knight",
            "Slardar": 2.16
        },
        {
            "name": "Chen",
            "Slardar": -0.9
        },
        {
            "name": "Clinkz",
            "Slardar": -1.683
        },
        {
            "name": "Clockwerk",
            "Slardar": 0.146
        },
        {
            "name": "Crystal Maiden",
            "Slardar": 0.124
        },
        {
            "name": "Dark Seer",
            "Slardar": -0.347
        },
        {
            "name": "Dark Willow",
            "Slardar": 0.997
        },
        {
            "name": "Dawnbreaker",
            "Slardar": -1.858
        },
        {
            "name": "Dazzle",
            "Slardar": 0.034
        },
        {
            "name": "Death Prophet",
            "Slardar": -0.423
        },
        {
            "name": "Disruptor",
            "Slardar": 0.732
        },
        {
            "name": "Doom",
            "Slardar": 1.57
        },
        {
            "name": "Dragon Knight",
            "Slardar": 0.482
        },
        {
            "name": "Drow Ranger",
            "Slardar": 3.167
        },
        {
            "name": "Earth Spirit",
            "Slardar": -0.07
        },
        {
            "name": "Earthshaker",
            "Slardar": -0.661
        },
        {
            "name": "Elder Titan",
            "Slardar": -0.979
        },
        {
            "name": "Ember Spirit",
            "Slardar": -0.363
        },
        {
            "name": "Enchantress",
            "Slardar": 0.664
        },
        {
            "name": "Enigma",
            "Slardar": 2.526
        },
        {
            "name": "Faceless Void",
            "Slardar": -1.007
        },
        {
            "name": "Grimstroke",
            "Slardar": -0.386
        },
        {
            "name": "Gyrocopter",
            "Slardar": 0.989
        },
        {
            "name": "Hoodwink",
            "Slardar": 0.593
        },
        {
            "name": "Huskar",
            "Slardar": 1.484
        },
        {
            "name": "Invoker",
            "Slardar": -1.514
        },
        {
            "name": "Io",
            "Slardar": -2.079
        },
        {
            "name": "Jakiro",
            "Slardar": 1.121
        },
        {
            "name": "Juggernaut",
            "Slardar": 2.218
        },
        {
            "name": "Keeper of the Light",
            "Slardar": -1.666
        },
        {
            "name": "Kez",
            "Slardar": 0.466
        },
        {
            "name": "Kunkka",
            "Slardar": -0.566
        },
        {
            "name": "Legion Commander",
            "Slardar": -0.311
        },
        {
            "name": "Leshrac",
            "Slardar": -0.161
        },
        {
            "name": "Lich",
            "Slardar": -0.066
        },
        {
            "name": "Lifestealer",
            "Slardar": -2.853
        },
        {
            "name": "Lina",
            "Slardar": -0.944
        },
        {
            "name": "Lion",
            "Slardar": 0.393
        },
        {
            "name": "Lone Druid",
            "Slardar": -0.4
        },
        {
            "name": "Luna",
            "Slardar": 2.864
        },
        {
            "name": "Lycan",
            "Slardar": -1.944
        },
        {
            "name": "Magnus",
            "Slardar": -0.006
        },
        {
            "name": "Marci",
            "Slardar": -0.662
        },
        {
            "name": "Mars",
            "Slardar": -0.507
        },
        {
            "name": "Medusa",
            "Slardar": 1.849
        },
        {
            "name": "Meepo",
            "Slardar": 0.613
        },
        {
            "name": "Mirana",
            "Slardar": 0.108
        },
        {
            "name": "Monkey King",
            "Slardar": 0.515
        },
        {
            "name": "Morphling",
            "Slardar": 0.773
        },
        {
            "name": "Muerta",
            "Slardar": 2.053
        },
        {
            "name": "Naga Siren",
            "Slardar": 3.855
        },
        {
            "name": "Nature's Prophet",
            "Slardar": -0.224
        },
        {
            "name": "Necrophos",
            "Slardar": 0.336
        },
        {
            "name": "Night Stalker",
            "Slardar": -0.956
        },
        {
            "name": "Nyx Assassin",
            "Slardar": -1.747
        },
        {
            "name": "Ogre Magi",
            "Slardar": 0.732
        },
        {
            "name": "Omniknight",
            "Slardar": -1.468
        },
        {
            "name": "Oracle",
            "Slardar": -1.624
        },
        {
            "name": "Outworld Destroyer",
            "Slardar": 0.271
        },
        {
            "name": "Pangolier",
            "Slardar": -0.793
        },
        {
            "name": "Phantom Assassin",
            "Slardar": 0.041
        },
        {
            "name": "Phantom Lancer",
            "Slardar": 4.168
        },
        {
            "name": "Phoenix",
            "Slardar": 1.679
        },
        {
            "name": "Primal Beast",
            "Slardar": -0.762
        },
        {
            "name": "Puck",
            "Slardar": -0.003
        },
        {
            "name": "Pudge",
            "Slardar": -1.975
        },
        {
            "name": "Pugna",
            "Slardar": -1.569
        },
        {
            "name": "Queen of Pain",
            "Slardar": 0.106
        },
        {
            "name": "Razor",
            "Slardar": 1.111
        },
        {
            "name": "Riki",
            "Slardar": -0.532
        },
        {
            "name": "Ringmaster",
            "Slardar": -0.188
        },
        {
            "name": "Rubick",
            "Slardar": -0.99
        },
        {
            "name": "Sand King",
            "Slardar": -0.899
        },
        {
            "name": "Shadow Demon",
            "Slardar": 1.206
        },
        {
            "name": "Shadow Fiend",
            "Slardar": -0.259
        },
        {
            "name": "Shadow Shaman",
            "Slardar": 1.114
        },
        {
            "name": "Silencer",
            "Slardar": 0.566
        },
        {
            "name": "Skywrath Mage",
            "Slardar": -0.421
        },
        {
            "name": "Slark",
            "Slardar": 1.045
        },
        {
            "name": "Snapfire",
            "Slardar": -0.006
        },
        {
            "name": "Sniper",
            "Slardar": 0.785
        },
        {
            "name": "Spectre",
            "Slardar": -1.766
        },
        {
            "name": "Spirit Breaker",
            "Slardar": -0.277
        },
        {
            "name": "Storm Spirit",
            "Slardar": -0.084
        },
        {
            "name": "Sven",
            "Slardar": -1.528
        },
        {
            "name": "Techies",
            "Slardar": 1.27
        },
        {
            "name": "Templar Assassin",
            "Slardar": -0.494
        },
        {
            "name": "Terrorblade",
            "Slardar": 3.74
        },
        {
            "name": "Tidehunter",
            "Slardar": 2.18
        },
        {
            "name": "Timbersaw",
            "Slardar": -0.92
        },
        {
            "name": "Tinker",
            "Slardar": -0.516
        },
        {
            "name": "Tiny",
            "Slardar": -0.173
        },
        {
            "name": "Treant Protector",
            "Slardar": 0.021
        },
        {
            "name": "Troll Warlord",
            "Slardar": 3.083
        },
        {
            "name": "Tusk",
            "Slardar": -0.107
        },
        {
            "name": "Underlord",
            "Slardar": 0.095
        },
        {
            "name": "Undying",
            "Slardar": -0.79
        },
        {
            "name": "Ursa",
            "Slardar": -1.261
        },
        {
            "name": "Vengeful Spirit",
            "Slardar": 1.16
        },
        {
            "name": "Venomancer",
            "Slardar": 1.307
        },
        {
            "name": "Viper",
            "Slardar": 0.526
        },
        {
            "name": "Visage",
            "Slardar": -0.753
        },
        {
            "name": "Void Spirit",
            "Slardar": -0.589
        },
        {
            "name": "Warlock",
            "Slardar": -0.735
        },
        {
            "name": "Weaver",
            "Slardar": 0.157
        },
        {
            "name": "Windranger",
            "Slardar": 1.084
        },
        {
            "name": "Winter Wyvern",
            "Slardar": 0.165
        },
        {
            "name": "Witch Doctor",
            "Slardar": -0.073
        },
        {
            "name": "Wraith King",
            "Slardar": -0.361
        },
        {
            "name": "Zeus",
            "Slardar": 0.207
        }
    ],
    "Spirit Breaker": [
        {
            "name": "Abaddon",
            "Spirit Breaker": 0.078
        },
        {
            "name": "Alchemist",
            "Spirit Breaker": -0.663
        },
        {
            "name": "Ancient Apparition",
            "Spirit Breaker": -0.904
        },
        {
            "name": "Anti-Mage",
            "Spirit Breaker": -0.969
        },
        {
            "name": "Arc Warden",
            "Spirit Breaker": -0.722
        },
        {
            "name": "Axe",
            "Spirit Breaker": -0.829
        },
        {
            "name": "Bane",
            "Spirit Breaker": -1.658
        },
        {
            "name": "Batrider",
            "Spirit Breaker": 1.103
        },
        {
            "name": "Beastmaster",
            "Spirit Breaker": 0.9
        },
        {
            "name": "Bloodseeker",
            "Spirit Breaker": -0.978
        },
        {
            "name": "Bounty Hunter",
            "Spirit Breaker": -0.62
        },
        {
            "name": "Brewmaster",
            "Spirit Breaker": -0.439
        },
        {
            "name": "Bristleback",
            "Spirit Breaker": -0.295
        },
        {
            "name": "Broodmother",
            "Spirit Breaker": -2.657
        },
        {
            "name": "Centaur Warrunner",
            "Spirit Breaker": 1.026
        },
        {
            "name": "Chaos Knight",
            "Spirit Breaker": 1.022
        },
        {
            "name": "Chen",
            "Spirit Breaker": -1.495
        },
        {
            "name": "Clinkz",
            "Spirit Breaker": -1.016
        },
        {
            "name": "Clockwerk",
            "Spirit Breaker": 2.401
        },
        {
            "name": "Crystal Maiden",
            "Spirit Breaker": 0.836
        },
        {
            "name": "Dark Seer",
            "Spirit Breaker": 0.668
        },
        {
            "name": "Dark Willow",
            "Spirit Breaker": 0.986
        },
        {
            "name": "Dawnbreaker",
            "Spirit Breaker": 0.806
        },
        {
            "name": "Dazzle",
            "Spirit Breaker": -0.162
        },
        {
            "name": "Death Prophet",
            "Spirit Breaker": -0.747
        },
        {
            "name": "Disruptor",
            "Spirit Breaker": 0.457
        },
        {
            "name": "Doom",
            "Spirit Breaker": 0.199
        },
        {
            "name": "Dragon Knight",
            "Spirit Breaker": 0.181
        },
        {
            "name": "Drow Ranger",
            "Spirit Breaker": -0.881
        },
        {
            "name": "Earth Spirit",
            "Spirit Breaker": -0.142
        },
        {
            "name": "Earthshaker",
            "Spirit Breaker": -0.237
        },
        {
            "name": "Elder Titan",
            "Spirit Breaker": -1.435
        },
        {
            "name": "Ember Spirit",
            "Spirit Breaker": 1.043
        },
        {
            "name": "Enchantress",
            "Spirit Breaker": 1.034
        },
        {
            "name": "Enigma",
            "Spirit Breaker": 1.534
        },
        {
            "name": "Faceless Void",
            "Spirit Breaker": -0.023
        },
        {
            "name": "Grimstroke",
            "Spirit Breaker": -0.002
        },
        {
            "name": "Gyrocopter",
            "Spirit Breaker": 0.143
        },
        {
            "name": "Hoodwink",
            "Spirit Breaker": -1.343
        },
        {
            "name": "Huskar",
            "Spirit Breaker": -0.548
        },
        {
            "name": "Invoker",
            "Spirit Breaker": 0.533
        },
        {
            "name": "Io",
            "Spirit Breaker": 0.505
        },
        {
            "name": "Jakiro",
            "Spirit Breaker": 1.814
        },
        {
            "name": "Juggernaut",
            "Spirit Breaker": -1.811
        },
        {
            "name": "Keeper of the Light",
            "Spirit Breaker": -0.812
        },
        {
            "name": "Kez",
            "Spirit Breaker": -0.72
        },
        {
            "name": "Kunkka",
            "Spirit Breaker": 0.359
        },
        {
            "name": "Legion Commander",
            "Spirit Breaker": -1.939
        },
        {
            "name": "Leshrac",
            "Spirit Breaker": 0.328
        },
        {
            "name": "Lich",
            "Spirit Breaker": 1.12
        },
        {
            "name": "Lifestealer",
            "Spirit Breaker": -1.296
        },
        {
            "name": "Lina",
            "Spirit Breaker": -0.99
        },
        {
            "name": "Lion",
            "Spirit Breaker": -0.124
        },
        {
            "name": "Lone Druid",
            "Spirit Breaker": -0.131
        },
        {
            "name": "Luna",
            "Spirit Breaker": -0.062
        },
        {
            "name": "Lycan",
            "Spirit Breaker": -0.888
        },
        {
            "name": "Magnus",
            "Spirit Breaker": 0.097
        },
        {
            "name": "Marci",
            "Spirit Breaker": -0.256
        },
        {
            "name": "Mars",
            "Spirit Breaker": 1.954
        },
        {
            "name": "Medusa",
            "Spirit Breaker": -0.066
        },
        {
            "name": "Meepo",
            "Spirit Breaker": 1.435
        },
        {
            "name": "Mirana",
            "Spirit Breaker": -0.389
        },
        {
            "name": "Monkey King",
            "Spirit Breaker": -0.388
        },
        {
            "name": "Morphling",
            "Spirit Breaker": 0.997
        },
        {
            "name": "Muerta",
            "Spirit Breaker": 0.073
        },
        {
            "name": "Naga Siren",
            "Spirit Breaker": 1.411
        },
        {
            "name": "Nature's Prophet",
            "Spirit Breaker": -0.936
        },
        {
            "name": "Necrophos",
            "Spirit Breaker": 2.308
        },
        {
            "name": "Night Stalker",
            "Spirit Breaker": -1.833
        },
        {
            "name": "Nyx Assassin",
            "Spirit Breaker": -0.282
        },
        {
            "name": "Ogre Magi",
            "Spirit Breaker": 0.463
        },
        {
            "name": "Omniknight",
            "Spirit Breaker": 0.471
        },
        {
            "name": "Oracle",
            "Spirit Breaker": 1.105
        },
        {
            "name": "Outworld Destroyer",
            "Spirit Breaker": -0.251
        },
        {
            "name": "Pangolier",
            "Spirit Breaker": -0.842
        },
        {
            "name": "Phantom Assassin",
            "Spirit Breaker": -0.918
        },
        {
            "name": "Phantom Lancer",
            "Spirit Breaker": 1.541
        },
        {
            "name": "Phoenix",
            "Spirit Breaker": 0.283
        },
        {
            "name": "Primal Beast",
            "Spirit Breaker": 1.316
        },
        {
            "name": "Puck",
            "Spirit Breaker": 0.274
        },
        {
            "name": "Pudge",
            "Spirit Breaker": 0.75
        },
        {
            "name": "Pugna",
            "Spirit Breaker": -1.113
        },
        {
            "name": "Queen of Pain",
            "Spirit Breaker": 0.681
        },
        {
            "name": "Razor",
            "Spirit Breaker": 0.184
        },
        {
            "name": "Riki",
            "Spirit Breaker": -0.778
        },
        {
            "name": "Ringmaster",
            "Spirit Breaker": 0.297
        },
        {
            "name": "Rubick",
            "Spirit Breaker": -0.456
        },
        {
            "name": "Sand King",
            "Spirit Breaker": 1.122
        },
        {
            "name": "Shadow Demon",
            "Spirit Breaker": -0.525
        },
        {
            "name": "Shadow Fiend",
            "Spirit Breaker": -1.599
        },
        {
            "name": "Shadow Shaman",
            "Spirit Breaker": -0.277
        },
        {
            "name": "Silencer",
            "Spirit Breaker": -1.213
        },
        {
            "name": "Skywrath Mage",
            "Spirit Breaker": -0.912
        },
        {
            "name": "Slardar",
            "Spirit Breaker": 0.201
        },
        {
            "name": "Slark",
            "Spirit Breaker": -0.386
        },
        {
            "name": "Snapfire",
            "Spirit Breaker": -0.512
        },
        {
            "name": "Sniper",
            "Spirit Breaker": -1.652
        },
        {
            "name": "Spectre",
            "Spirit Breaker": 1.117
        },
        {
            "name": "Storm Spirit",
            "Spirit Breaker": -0.213
        },
        {
            "name": "Sven",
            "Spirit Breaker": 0.488
        },
        {
            "name": "Techies",
            "Spirit Breaker": 0.792
        },
        {
            "name": "Templar Assassin",
            "Spirit Breaker": 0.341
        },
        {
            "name": "Terrorblade",
            "Spirit Breaker": 0.398
        },
        {
            "name": "Tidehunter",
            "Spirit Breaker": 1.007
        },
        {
            "name": "Timbersaw",
            "Spirit Breaker": 1.061
        },
        {
            "name": "Tinker",
            "Spirit Breaker": -0.055
        },
        {
            "name": "Tiny",
            "Spirit Breaker": 2.3
        },
        {
            "name": "Treant Protector",
            "Spirit Breaker": 0.787
        },
        {
            "name": "Troll Warlord",
            "Spirit Breaker": -0.129
        },
        {
            "name": "Tusk",
            "Spirit Breaker": 1.687
        },
        {
            "name": "Underlord",
            "Spirit Breaker": 2.908
        },
        {
            "name": "Undying",
            "Spirit Breaker": 0.021
        },
        {
            "name": "Ursa",
            "Spirit Breaker": -0.107
        },
        {
            "name": "Vengeful Spirit",
            "Spirit Breaker": 0.047
        },
        {
            "name": "Venomancer",
            "Spirit Breaker": 1.219
        },
        {
            "name": "Viper",
            "Spirit Breaker": -0.105
        },
        {
            "name": "Visage",
            "Spirit Breaker": 0.333
        },
        {
            "name": "Void Spirit",
            "Spirit Breaker": -0.056
        },
        {
            "name": "Warlock",
            "Spirit Breaker": 0.185
        },
        {
            "name": "Weaver",
            "Spirit Breaker": -0.627
        },
        {
            "name": "Windranger",
            "Spirit Breaker": -0.493
        },
        {
            "name": "Winter Wyvern",
            "Spirit Breaker": 1.086
        },
        {
            "name": "Witch Doctor",
            "Spirit Breaker": 0.64
        },
        {
            "name": "Wraith King",
            "Spirit Breaker": 1.324
        },
        {
            "name": "Zeus",
            "Spirit Breaker": -0.371
        }
    ],
    "Sven": [
        {
            "name": "Abaddon",
            "Sven": -1.486
        },
        {
            "name": "Alchemist",
            "Sven": 0.773
        },
        {
            "name": "Ancient Apparition",
            "Sven": 0.726
        },
        {
            "name": "Anti-Mage",
            "Sven": -2.452
        },
        {
            "name": "Arc Warden",
            "Sven": -1.229
        },
        {
            "name": "Axe",
            "Sven": -0.62
        },
        {
            "name": "Bane",
            "Sven": 1.417
        },
        {
            "name": "Batrider",
            "Sven": 2.023
        },
        {
            "name": "Beastmaster",
            "Sven": -0.257
        },
        {
            "name": "Bloodseeker",
            "Sven": -0.999
        },
        {
            "name": "Bounty Hunter",
            "Sven": 0.871
        },
        {
            "name": "Brewmaster",
            "Sven": -1.279
        },
        {
            "name": "Bristleback",
            "Sven": 2.205
        },
        {
            "name": "Broodmother",
            "Sven": -0.458
        },
        {
            "name": "Centaur Warrunner",
            "Sven": -1.189
        },
        {
            "name": "Chaos Knight",
            "Sven": -1.386
        },
        {
            "name": "Chen",
            "Sven": -0.166
        },
        {
            "name": "Clinkz",
            "Sven": 1.866
        },
        {
            "name": "Clockwerk",
            "Sven": 0.492
        },
        {
            "name": "Crystal Maiden",
            "Sven": 0.271
        },
        {
            "name": "Dark Seer",
            "Sven": -2.865
        },
        {
            "name": "Dark Willow",
            "Sven": 0.607
        },
        {
            "name": "Dawnbreaker",
            "Sven": -0.398
        },
        {
            "name": "Dazzle",
            "Sven": 0.537
        },
        {
            "name": "Death Prophet",
            "Sven": -0.754
        },
        {
            "name": "Disruptor",
            "Sven": -0.238
        },
        {
            "name": "Doom",
            "Sven": 1.124
        },
        {
            "name": "Dragon Knight",
            "Sven": 1.222
        },
        {
            "name": "Drow Ranger",
            "Sven": -0.673
        },
        {
            "name": "Earth Spirit",
            "Sven": -0.448
        },
        {
            "name": "Earthshaker",
            "Sven": -0.998
        },
        {
            "name": "Elder Titan",
            "Sven": -1.875
        },
        {
            "name": "Ember Spirit",
            "Sven": -1.143
        },
        {
            "name": "Enchantress",
            "Sven": 1.551
        },
        {
            "name": "Enigma",
            "Sven": -0.241
        },
        {
            "name": "Faceless Void",
            "Sven": 0.772
        },
        {
            "name": "Grimstroke",
            "Sven": -0.81
        },
        {
            "name": "Gyrocopter",
            "Sven": 1.138
        },
        {
            "name": "Hoodwink",
            "Sven": -0.382
        },
        {
            "name": "Huskar",
            "Sven": 0.026
        },
        {
            "name": "Invoker",
            "Sven": 0.098
        },
        {
            "name": "Io",
            "Sven": -1.153
        },
        {
            "name": "Jakiro",
            "Sven": 0.166
        },
        {
            "name": "Juggernaut",
            "Sven": -0.074
        },
        {
            "name": "Keeper of the Light",
            "Sven": 0.128
        },
        {
            "name": "Kez",
            "Sven": -1.886
        },
        {
            "name": "Kunkka",
            "Sven": 0.408
        },
        {
            "name": "Legion Commander",
            "Sven": -0.606
        },
        {
            "name": "Leshrac",
            "Sven": -1.212
        },
        {
            "name": "Lich",
            "Sven": -0.421
        },
        {
            "name": "Lifestealer",
            "Sven": 2.025
        },
        {
            "name": "Lina",
            "Sven": -1.132
        },
        {
            "name": "Lion",
            "Sven": -0.156
        },
        {
            "name": "Lone Druid",
            "Sven": 0.215
        },
        {
            "name": "Luna",
            "Sven": 0.936
        },
        {
            "name": "Lycan",
            "Sven": -2.931
        },
        {
            "name": "Magnus",
            "Sven": -0.606
        },
        {
            "name": "Marci",
            "Sven": -1.014
        },
        {
            "name": "Mars",
            "Sven": -0.588
        },
        {
            "name": "Medusa",
            "Sven": 1.005
        },
        {
            "name": "Meepo",
            "Sven": -4.827
        },
        {
            "name": "Mirana",
            "Sven": 0.514
        },
        {
            "name": "Monkey King",
            "Sven": 1.55
        },
        {
            "name": "Morphling",
            "Sven": 0.086
        },
        {
            "name": "Muerta",
            "Sven": 0.44
        },
        {
            "name": "Naga Siren",
            "Sven": -4.182
        },
        {
            "name": "Nature's Prophet",
            "Sven": -0.129
        },
        {
            "name": "Necrophos",
            "Sven": 0.783
        },
        {
            "name": "Night Stalker",
            "Sven": -1.472
        },
        {
            "name": "Nyx Assassin",
            "Sven": -1.378
        },
        {
            "name": "Ogre Magi",
            "Sven": 1.077
        },
        {
            "name": "Omniknight",
            "Sven": -0.495
        },
        {
            "name": "Oracle",
            "Sven": -0.96
        },
        {
            "name": "Outworld Destroyer",
            "Sven": -0.008
        },
        {
            "name": "Pangolier",
            "Sven": 0.004
        },
        {
            "name": "Phantom Assassin",
            "Sven": 1.54
        },
        {
            "name": "Phantom Lancer",
            "Sven": -3.95
        },
        {
            "name": "Phoenix",
            "Sven": 2.359
        },
        {
            "name": "Primal Beast",
            "Sven": -0.669
        },
        {
            "name": "Puck",
            "Sven": -0.671
        },
        {
            "name": "Pudge",
            "Sven": -1.596
        },
        {
            "name": "Pugna",
            "Sven": -1.417
        },
        {
            "name": "Queen of Pain",
            "Sven": -0.59
        },
        {
            "name": "Razor",
            "Sven": 1.691
        },
        {
            "name": "Riki",
            "Sven": -1.005
        },
        {
            "name": "Ringmaster",
            "Sven": -0.472
        },
        {
            "name": "Rubick",
            "Sven": 0.523
        },
        {
            "name": "Sand King",
            "Sven": -0.165
        },
        {
            "name": "Shadow Demon",
            "Sven": -0.632
        },
        {
            "name": "Shadow Fiend",
            "Sven": 0.439
        },
        {
            "name": "Shadow Shaman",
            "Sven": 1.143
        },
        {
            "name": "Silencer",
            "Sven": 0.431
        },
        {
            "name": "Skywrath Mage",
            "Sven": -0.787
        },
        {
            "name": "Slardar",
            "Sven": 1.614
        },
        {
            "name": "Slark",
            "Sven": 0.528
        },
        {
            "name": "Snapfire",
            "Sven": 0.014
        },
        {
            "name": "Sniper",
            "Sven": 0.506
        },
        {
            "name": "Spectre",
            "Sven": -1.535
        },
        {
            "name": "Spirit Breaker",
            "Sven": -0.441
        },
        {
            "name": "Storm Spirit",
            "Sven": -1.06
        },
        {
            "name": "Techies",
            "Sven": 0.754
        },
        {
            "name": "Templar Assassin",
            "Sven": 2.978
        },
        {
            "name": "Terrorblade",
            "Sven": 2.019
        },
        {
            "name": "Tidehunter",
            "Sven": -0.665
        },
        {
            "name": "Timbersaw",
            "Sven": -0.838
        },
        {
            "name": "Tinker",
            "Sven": -0.334
        },
        {
            "name": "Tiny",
            "Sven": -1.0
        },
        {
            "name": "Treant Protector",
            "Sven": 1.32
        },
        {
            "name": "Troll Warlord",
            "Sven": 3.047
        },
        {
            "name": "Tusk",
            "Sven": -0.593
        },
        {
            "name": "Underlord",
            "Sven": -0.5
        },
        {
            "name": "Undying",
            "Sven": 1.08
        },
        {
            "name": "Ursa",
            "Sven": 1.381
        },
        {
            "name": "Vengeful Spirit",
            "Sven": 0.341
        },
        {
            "name": "Venomancer",
            "Sven": 0.731
        },
        {
            "name": "Viper",
            "Sven": 0.901
        },
        {
            "name": "Visage",
            "Sven": -1.419
        },
        {
            "name": "Void Spirit",
            "Sven": -2.154
        },
        {
            "name": "Warlock",
            "Sven": -1.79
        },
        {
            "name": "Weaver",
            "Sven": 0.571
        },
        {
            "name": "Windranger",
            "Sven": 0.361
        },
        {
            "name": "Winter Wyvern",
            "Sven": 1.693
        },
        {
            "name": "Witch Doctor",
            "Sven": 0.963
        },
        {
            "name": "Wraith King",
            "Sven": 1.878
        },
        {
            "name": "Zeus",
            "Sven": -0.336
        }
    ],
    "Tidehunter": [
        {
            "name": "Abaddon",
            "Tidehunter": -0.284
        },
        {
            "name": "Alchemist",
            "Tidehunter": 0.831
        },
        {
            "name": "Ancient Apparition",
            "Tidehunter": 0.264
        },
        {
            "name": "Anti-Mage",
            "Tidehunter": 1.577
        },
        {
            "name": "Arc Warden",
            "Tidehunter": -1.477
        },
        {
            "name": "Axe",
            "Tidehunter": -0.537
        },
        {
            "name": "Bane",
            "Tidehunter": -0.559
        },
        {
            "name": "Batrider",
            "Tidehunter": -2.185
        },
        {
            "name": "Beastmaster",
            "Tidehunter": -1.194
        },
        {
            "name": "Bloodseeker",
            "Tidehunter": 2.126
        },
        {
            "name": "Bounty Hunter",
            "Tidehunter": -1.126
        },
        {
            "name": "Brewmaster",
            "Tidehunter": -0.651
        },
        {
            "name": "Bristleback",
            "Tidehunter": 0.908
        },
        {
            "name": "Broodmother",
            "Tidehunter": -2.279
        },
        {
            "name": "Centaur Warrunner",
            "Tidehunter": -0.979
        },
        {
            "name": "Chaos Knight",
            "Tidehunter": -3.499
        },
        {
            "name": "Chen",
            "Tidehunter": -0.004
        },
        {
            "name": "Clinkz",
            "Tidehunter": 1.171
        },
        {
            "name": "Clockwerk",
            "Tidehunter": 1.091
        },
        {
            "name": "Crystal Maiden",
            "Tidehunter": -0.293
        },
        {
            "name": "Dark Seer",
            "Tidehunter": 0.305
        },
        {
            "name": "Dark Willow",
            "Tidehunter": 1.303
        },
        {
            "name": "Dawnbreaker",
            "Tidehunter": 0.946
        },
        {
            "name": "Dazzle",
            "Tidehunter": -1.364
        },
        {
            "name": "Death Prophet",
            "Tidehunter": 2.847
        },
        {
            "name": "Disruptor",
            "Tidehunter": 0.497
        },
        {
            "name": "Doom",
            "Tidehunter": 1.454
        },
        {
            "name": "Dragon Knight",
            "Tidehunter": -0.169
        },
        {
            "name": "Drow Ranger",
            "Tidehunter": 0.021
        },
        {
            "name": "Earth Spirit",
            "Tidehunter": -1.41
        },
        {
            "name": "Earthshaker",
            "Tidehunter": 0.206
        },
        {
            "name": "Elder Titan",
            "Tidehunter": 2.875
        },
        {
            "name": "Ember Spirit",
            "Tidehunter": -2.214
        },
        {
            "name": "Enchantress",
            "Tidehunter": 1.325
        },
        {
            "name": "Enigma",
            "Tidehunter": -0.037
        },
        {
            "name": "Faceless Void",
            "Tidehunter": -1.192
        },
        {
            "name": "Grimstroke",
            "Tidehunter": 2.023
        },
        {
            "name": "Gyrocopter",
            "Tidehunter": 0.813
        },
        {
            "name": "Hoodwink",
            "Tidehunter": 0.893
        },
        {
            "name": "Huskar",
            "Tidehunter": 3.125
        },
        {
            "name": "Invoker",
            "Tidehunter": 0.812
        },
        {
            "name": "Io",
            "Tidehunter": 1.217
        },
        {
            "name": "Jakiro",
            "Tidehunter": -1.102
        },
        {
            "name": "Juggernaut",
            "Tidehunter": 3.527
        },
        {
            "name": "Keeper of the Light",
            "Tidehunter": 0.346
        },
        {
            "name": "Kez",
            "Tidehunter": -0.906
        },
        {
            "name": "Kunkka",
            "Tidehunter": 1.572
        },
        {
            "name": "Legion Commander",
            "Tidehunter": 0.346
        },
        {
            "name": "Leshrac",
            "Tidehunter": 2.126
        },
        {
            "name": "Lich",
            "Tidehunter": 0.873
        },
        {
            "name": "Lifestealer",
            "Tidehunter": 0.647
        },
        {
            "name": "Lina",
            "Tidehunter": -0.527
        },
        {
            "name": "Lion",
            "Tidehunter": -0.691
        },
        {
            "name": "Lone Druid",
            "Tidehunter": -1.266
        },
        {
            "name": "Luna",
            "Tidehunter": -0.099
        },
        {
            "name": "Lycan",
            "Tidehunter": -2.525
        },
        {
            "name": "Magnus",
            "Tidehunter": -0.799
        },
        {
            "name": "Marci",
            "Tidehunter": -0.409
        },
        {
            "name": "Mars",
            "Tidehunter": 0.453
        },
        {
            "name": "Medusa",
            "Tidehunter": -2.877
        },
        {
            "name": "Meepo",
            "Tidehunter": -1.262
        },
        {
            "name": "Mirana",
            "Tidehunter": -0.573
        },
        {
            "name": "Monkey King",
            "Tidehunter": 2.167
        },
        {
            "name": "Morphling",
            "Tidehunter": 0.107
        },
        {
            "name": "Muerta",
            "Tidehunter": 2.175
        },
        {
            "name": "Naga Siren",
            "Tidehunter": -4.145
        },
        {
            "name": "Nature's Prophet",
            "Tidehunter": 0.698
        },
        {
            "name": "Necrophos",
            "Tidehunter": 2.82
        },
        {
            "name": "Night Stalker",
            "Tidehunter": 1.019
        },
        {
            "name": "Nyx Assassin",
            "Tidehunter": -0.218
        },
        {
            "name": "Ogre Magi",
            "Tidehunter": -0.719
        },
        {
            "name": "Omniknight",
            "Tidehunter": 2.635
        },
        {
            "name": "Oracle",
            "Tidehunter": -0.664
        },
        {
            "name": "Outworld Destroyer",
            "Tidehunter": 2.617
        },
        {
            "name": "Pangolier",
            "Tidehunter": 2.857
        },
        {
            "name": "Phantom Assassin",
            "Tidehunter": -1.604
        },
        {
            "name": "Phantom Lancer",
            "Tidehunter": -3.785
        },
        {
            "name": "Phoenix",
            "Tidehunter": -0.825
        },
        {
            "name": "Primal Beast",
            "Tidehunter": 1.397
        },
        {
            "name": "Puck",
            "Tidehunter": -0.415
        },
        {
            "name": "Pudge",
            "Tidehunter": -0.492
        },
        {
            "name": "Pugna",
            "Tidehunter": 3.074
        },
        {
            "name": "Queen of Pain",
            "Tidehunter": 0.065
        },
        {
            "name": "Razor",
            "Tidehunter": 3.268
        },
        {
            "name": "Riki",
            "Tidehunter": -0.323
        },
        {
            "name": "Ringmaster",
            "Tidehunter": 1.351
        },
        {
            "name": "Rubick",
            "Tidehunter": 0.433
        },
        {
            "name": "Sand King",
            "Tidehunter": 0.472
        },
        {
            "name": "Shadow Demon",
            "Tidehunter": -0.32
        },
        {
            "name": "Shadow Fiend",
            "Tidehunter": 1.353
        },
        {
            "name": "Shadow Shaman",
            "Tidehunter": -0.539
        },
        {
            "name": "Silencer",
            "Tidehunter": -2.372
        },
        {
            "name": "Skywrath Mage",
            "Tidehunter": -1.013
        },
        {
            "name": "Slardar",
            "Tidehunter": -1.95
        },
        {
            "name": "Slark",
            "Tidehunter": 2.578
        },
        {
            "name": "Snapfire",
            "Tidehunter": 2.511
        },
        {
            "name": "Sniper",
            "Tidehunter": 1.604
        },
        {
            "name": "Spectre",
            "Tidehunter": -2.898
        },
        {
            "name": "Spirit Breaker",
            "Tidehunter": -1.061
        },
        {
            "name": "Storm Spirit",
            "Tidehunter": -2.141
        },
        {
            "name": "Sven",
            "Tidehunter": 0.961
        },
        {
            "name": "Techies",
            "Tidehunter": -0.794
        },
        {
            "name": "Templar Assassin",
            "Tidehunter": 0.01
        },
        {
            "name": "Terrorblade",
            "Tidehunter": -0.264
        },
        {
            "name": "Timbersaw",
            "Tidehunter": 1.954
        },
        {
            "name": "Tinker",
            "Tidehunter": 2.448
        },
        {
            "name": "Tiny",
            "Tidehunter": 1.158
        },
        {
            "name": "Treant Protector",
            "Tidehunter": 0.293
        },
        {
            "name": "Troll Warlord",
            "Tidehunter": -2.538
        },
        {
            "name": "Tusk",
            "Tidehunter": 1.168
        },
        {
            "name": "Underlord",
            "Tidehunter": 1.853
        },
        {
            "name": "Undying",
            "Tidehunter": 1.775
        },
        {
            "name": "Ursa",
            "Tidehunter": 2.794
        },
        {
            "name": "Vengeful Spirit",
            "Tidehunter": -1.14
        },
        {
            "name": "Venomancer",
            "Tidehunter": 1.842
        },
        {
            "name": "Viper",
            "Tidehunter": 1.861
        },
        {
            "name": "Visage",
            "Tidehunter": -1.535
        },
        {
            "name": "Void Spirit",
            "Tidehunter": -1.964
        },
        {
            "name": "Warlock",
            "Tidehunter": -2.313
        },
        {
            "name": "Weaver",
            "Tidehunter": 1.671
        },
        {
            "name": "Windranger",
            "Tidehunter": -0.266
        },
        {
            "name": "Winter Wyvern",
            "Tidehunter": -0.66
        },
        {
            "name": "Witch Doctor",
            "Tidehunter": 2.585
        },
        {
            "name": "Wraith King",
            "Tidehunter": -3.722
        },
        {
            "name": "Zeus",
            "Tidehunter": 0.877
        }
    ],
    "Tiny": [
        {
            "name": "Abaddon",
            "Tiny": -1.335
        },
        {
            "name": "Alchemist",
            "Tiny": 1.202
        },
        {
            "name": "Ancient Apparition",
            "Tiny": 0.008
        },
        {
            "name": "Anti-Mage",
            "Tiny": -1.801
        },
        {
            "name": "Arc Warden",
            "Tiny": -0.143
        },
        {
            "name": "Axe",
            "Tiny": -2.23
        },
        {
            "name": "Bane",
            "Tiny": 0.166
        },
        {
            "name": "Batrider",
            "Tiny": 0.489
        },
        {
            "name": "Beastmaster",
            "Tiny": 0.624
        },
        {
            "name": "Bloodseeker",
            "Tiny": 1.434
        },
        {
            "name": "Bounty Hunter",
            "Tiny": 0.343
        },
        {
            "name": "Brewmaster",
            "Tiny": 0.14
        },
        {
            "name": "Bristleback",
            "Tiny": -0.123
        },
        {
            "name": "Broodmother",
            "Tiny": -1.444
        },
        {
            "name": "Centaur Warrunner",
            "Tiny": -0.835
        },
        {
            "name": "Chaos Knight",
            "Tiny": -0.849
        },
        {
            "name": "Chen",
            "Tiny": 0.548
        },
        {
            "name": "Clinkz",
            "Tiny": 0.305
        },
        {
            "name": "Clockwerk",
            "Tiny": 0.459
        },
        {
            "name": "Crystal Maiden",
            "Tiny": -0.067
        },
        {
            "name": "Dark Seer",
            "Tiny": -1.895
        },
        {
            "name": "Dark Willow",
            "Tiny": 0.302
        },
        {
            "name": "Dawnbreaker",
            "Tiny": 0.218
        },
        {
            "name": "Dazzle",
            "Tiny": 1.897
        },
        {
            "name": "Death Prophet",
            "Tiny": 1.11
        },
        {
            "name": "Disruptor",
            "Tiny": -0.036
        },
        {
            "name": "Doom",
            "Tiny": 1.382
        },
        {
            "name": "Dragon Knight",
            "Tiny": 0.112
        },
        {
            "name": "Drow Ranger",
            "Tiny": -2.092
        },
        {
            "name": "Earth Spirit",
            "Tiny": 0.406
        },
        {
            "name": "Earthshaker",
            "Tiny": -1.101
        },
        {
            "name": "Elder Titan",
            "Tiny": -0.842
        },
        {
            "name": "Ember Spirit",
            "Tiny": 0.828
        },
        {
            "name": "Enchantress",
            "Tiny": 0.153
        },
        {
            "name": "Enigma",
            "Tiny": -0.252
        },
        {
            "name": "Faceless Void",
            "Tiny": 0.354
        },
        {
            "name": "Grimstroke",
            "Tiny": 0.275
        },
        {
            "name": "Gyrocopter",
            "Tiny": 1.462
        },
        {
            "name": "Hoodwink",
            "Tiny": -0.552
        },
        {
            "name": "Huskar",
            "Tiny": 2.885
        },
        {
            "name": "Invoker",
            "Tiny": -0.255
        },
        {
            "name": "Io",
            "Tiny": -0.044
        },
        {
            "name": "Jakiro",
            "Tiny": -0.529
        },
        {
            "name": "Juggernaut",
            "Tiny": 0.723
        },
        {
            "name": "Keeper of the Light",
            "Tiny": 1.492
        },
        {
            "name": "Kez",
            "Tiny": 0.946
        },
        {
            "name": "Kunkka",
            "Tiny": 0.959
        },
        {
            "name": "Legion Commander",
            "Tiny": -1.435
        },
        {
            "name": "Leshrac",
            "Tiny": 0.406
        },
        {
            "name": "Lich",
            "Tiny": 0.315
        },
        {
            "name": "Lifestealer",
            "Tiny": 3.019
        },
        {
            "name": "Lina",
            "Tiny": 0.18
        },
        {
            "name": "Lion",
            "Tiny": -0.848
        },
        {
            "name": "Lone Druid",
            "Tiny": -0.113
        },
        {
            "name": "Luna",
            "Tiny": 1.138
        },
        {
            "name": "Lycan",
            "Tiny": -0.059
        },
        {
            "name": "Magnus",
            "Tiny": -0.481
        },
        {
            "name": "Marci",
            "Tiny": -0.672
        },
        {
            "name": "Mars",
            "Tiny": 0.771
        },
        {
            "name": "Medusa",
            "Tiny": -2.286
        },
        {
            "name": "Meepo",
            "Tiny": -0.516
        },
        {
            "name": "Mirana",
            "Tiny": -0.683
        },
        {
            "name": "Monkey King",
            "Tiny": 0.739
        },
        {
            "name": "Morphling",
            "Tiny": 0.309
        },
        {
            "name": "Muerta",
            "Tiny": -0.154
        },
        {
            "name": "Naga Siren",
            "Tiny": -2.987
        },
        {
            "name": "Nature's Prophet",
            "Tiny": 0.462
        },
        {
            "name": "Necrophos",
            "Tiny": 3.462
        },
        {
            "name": "Night Stalker",
            "Tiny": -1.17
        },
        {
            "name": "Nyx Assassin",
            "Tiny": -1.16
        },
        {
            "name": "Ogre Magi",
            "Tiny": 1.039
        },
        {
            "name": "Omniknight",
            "Tiny": -0.206
        },
        {
            "name": "Oracle",
            "Tiny": -0.642
        },
        {
            "name": "Outworld Destroyer",
            "Tiny": 0.235
        },
        {
            "name": "Pangolier",
            "Tiny": 0.115
        },
        {
            "name": "Phantom Assassin",
            "Tiny": -1.797
        },
        {
            "name": "Phantom Lancer",
            "Tiny": -1.786
        },
        {
            "name": "Phoenix",
            "Tiny": 1.457
        },
        {
            "name": "Primal Beast",
            "Tiny": -0.645
        },
        {
            "name": "Puck",
            "Tiny": -2.144
        },
        {
            "name": "Pudge",
            "Tiny": -0.703
        },
        {
            "name": "Pugna",
            "Tiny": 0.34
        },
        {
            "name": "Queen of Pain",
            "Tiny": 0.461
        },
        {
            "name": "Razor",
            "Tiny": 0.341
        },
        {
            "name": "Riki",
            "Tiny": -2.532
        },
        {
            "name": "Ringmaster",
            "Tiny": 0.372
        },
        {
            "name": "Rubick",
            "Tiny": 0.685
        },
        {
            "name": "Sand King",
            "Tiny": 0.125
        },
        {
            "name": "Shadow Demon",
            "Tiny": -0.745
        },
        {
            "name": "Shadow Fiend",
            "Tiny": -0.435
        },
        {
            "name": "Shadow Shaman",
            "Tiny": -0.402
        },
        {
            "name": "Silencer",
            "Tiny": -0.467
        },
        {
            "name": "Skywrath Mage",
            "Tiny": -0.456
        },
        {
            "name": "Slardar",
            "Tiny": 0.127
        },
        {
            "name": "Slark",
            "Tiny": -1.836
        },
        {
            "name": "Snapfire",
            "Tiny": 0.652
        },
        {
            "name": "Sniper",
            "Tiny": -0.16
        },
        {
            "name": "Spectre",
            "Tiny": -0.836
        },
        {
            "name": "Spirit Breaker",
            "Tiny": -2.33
        },
        {
            "name": "Storm Spirit",
            "Tiny": -0.972
        },
        {
            "name": "Sven",
            "Tiny": 0.997
        },
        {
            "name": "Techies",
            "Tiny": -0.374
        },
        {
            "name": "Templar Assassin",
            "Tiny": 0.628
        },
        {
            "name": "Terrorblade",
            "Tiny": 1.775
        },
        {
            "name": "Tidehunter",
            "Tiny": -1.151
        },
        {
            "name": "Timbersaw",
            "Tiny": 0.833
        },
        {
            "name": "Tinker",
            "Tiny": 0.24
        },
        {
            "name": "Treant Protector",
            "Tiny": 0.863
        },
        {
            "name": "Troll Warlord",
            "Tiny": -0.869
        },
        {
            "name": "Tusk",
            "Tiny": 0.502
        },
        {
            "name": "Underlord",
            "Tiny": 2.738
        },
        {
            "name": "Undying",
            "Tiny": 1.854
        },
        {
            "name": "Ursa",
            "Tiny": -0.17
        },
        {
            "name": "Vengeful Spirit",
            "Tiny": 0.301
        },
        {
            "name": "Venomancer",
            "Tiny": 2.093
        },
        {
            "name": "Viper",
            "Tiny": 2.959
        },
        {
            "name": "Visage",
            "Tiny": -1.103
        },
        {
            "name": "Void Spirit",
            "Tiny": -1.3
        },
        {
            "name": "Warlock",
            "Tiny": -0.138
        },
        {
            "name": "Weaver",
            "Tiny": 0.863
        },
        {
            "name": "Windranger",
            "Tiny": -1.018
        },
        {
            "name": "Winter Wyvern",
            "Tiny": 0.041
        },
        {
            "name": "Witch Doctor",
            "Tiny": 0.795
        },
        {
            "name": "Wraith King",
            "Tiny": 0.489
        },
        {
            "name": "Zeus",
            "Tiny": 0.465
        }
    ],
    "Treant Protector": [
        {
            "name": "Abaddon",
            "Treant Protector": 0.827
        },
        {
            "name": "Alchemist",
            "Treant Protector": -0.155
        },
        {
            "name": "Ancient Apparition",
            "Treant Protector": 1.686
        },
        {
            "name": "Anti-Mage",
            "Treant Protector": 0.38
        },
        {
            "name": "Arc Warden",
            "Treant Protector": 0.744
        },
        {
            "name": "Axe",
            "Treant Protector": -0.884
        },
        {
            "name": "Bane",
            "Treant Protector": -0.451
        },
        {
            "name": "Batrider",
            "Treant Protector": 0.732
        },
        {
            "name": "Beastmaster",
            "Treant Protector": 0.542
        },
        {
            "name": "Bloodseeker",
            "Treant Protector": -0.185
        },
        {
            "name": "Bounty Hunter",
            "Treant Protector": -0.52
        },
        {
            "name": "Brewmaster",
            "Treant Protector": -0.513
        },
        {
            "name": "Bristleback",
            "Treant Protector": 1.018
        },
        {
            "name": "Broodmother",
            "Treant Protector": -1.556
        },
        {
            "name": "Centaur Warrunner",
            "Treant Protector": -0.423
        },
        {
            "name": "Chaos Knight",
            "Treant Protector": -0.291
        },
        {
            "name": "Chen",
            "Treant Protector": -0.364
        },
        {
            "name": "Clinkz",
            "Treant Protector": -1.495
        },
        {
            "name": "Clockwerk",
            "Treant Protector": 0.706
        },
        {
            "name": "Crystal Maiden",
            "Treant Protector": 0.502
        },
        {
            "name": "Dark Seer",
            "Treant Protector": 0.112
        },
        {
            "name": "Dark Willow",
            "Treant Protector": 0.069
        },
        {
            "name": "Dawnbreaker",
            "Treant Protector": 1.507
        },
        {
            "name": "Dazzle",
            "Treant Protector": -0.264
        },
        {
            "name": "Death Prophet",
            "Treant Protector": 1.731
        },
        {
            "name": "Disruptor",
            "Treant Protector": -0.232
        },
        {
            "name": "Doom",
            "Treant Protector": 1.001
        },
        {
            "name": "Dragon Knight",
            "Treant Protector": -0.928
        },
        {
            "name": "Drow Ranger",
            "Treant Protector": -0.79
        },
        {
            "name": "Earth Spirit",
            "Treant Protector": 0.584
        },
        {
            "name": "Earthshaker",
            "Treant Protector": -0.398
        },
        {
            "name": "Elder Titan",
            "Treant Protector": -0.285
        },
        {
            "name": "Ember Spirit",
            "Treant Protector": -0.974
        },
        {
            "name": "Enchantress",
            "Treant Protector": 0.02
        },
        {
            "name": "Enigma",
            "Treant Protector": -0.442
        },
        {
            "name": "Faceless Void",
            "Treant Protector": -0.207
        },
        {
            "name": "Grimstroke",
            "Treant Protector": 0.538
        },
        {
            "name": "Gyrocopter",
            "Treant Protector": 0.191
        },
        {
            "name": "Hoodwink",
            "Treant Protector": -0.499
        },
        {
            "name": "Huskar",
            "Treant Protector": 0.775
        },
        {
            "name": "Invoker",
            "Treant Protector": 0.518
        },
        {
            "name": "Io",
            "Treant Protector": -0.397
        },
        {
            "name": "Jakiro",
            "Treant Protector": 0.604
        },
        {
            "name": "Juggernaut",
            "Treant Protector": 2.224
        },
        {
            "name": "Keeper of the Light",
            "Treant Protector": -0.432
        },
        {
            "name": "Kez",
            "Treant Protector": -0.039
        },
        {
            "name": "Kunkka",
            "Treant Protector": -1.378
        },
        {
            "name": "Legion Commander",
            "Treant Protector": -1.022
        },
        {
            "name": "Leshrac",
            "Treant Protector": 1.279
        },
        {
            "name": "Lich",
            "Treant Protector": 0.585
        },
        {
            "name": "Lifestealer",
            "Treant Protector": 0.524
        },
        {
            "name": "Lina",
            "Treant Protector": 0.368
        },
        {
            "name": "Lion",
            "Treant Protector": -0.378
        },
        {
            "name": "Lone Druid",
            "Treant Protector": -3.691
        },
        {
            "name": "Luna",
            "Treant Protector": -1.212
        },
        {
            "name": "Lycan",
            "Treant Protector": -2.256
        },
        {
            "name": "Magnus",
            "Treant Protector": 0.522
        },
        {
            "name": "Marci",
            "Treant Protector": -1.068
        },
        {
            "name": "Mars",
            "Treant Protector": 0.73
        },
        {
            "name": "Medusa",
            "Treant Protector": -1.553
        },
        {
            "name": "Meepo",
            "Treant Protector": -2.282
        },
        {
            "name": "Mirana",
            "Treant Protector": -1.209
        },
        {
            "name": "Monkey King",
            "Treant Protector": -0.628
        },
        {
            "name": "Morphling",
            "Treant Protector": 1.464
        },
        {
            "name": "Muerta",
            "Treant Protector": -0.283
        },
        {
            "name": "Naga Siren",
            "Treant Protector": -1.05
        },
        {
            "name": "Nature's Prophet",
            "Treant Protector": -2.22
        },
        {
            "name": "Necrophos",
            "Treant Protector": 1.264
        },
        {
            "name": "Night Stalker",
            "Treant Protector": -1.955
        },
        {
            "name": "Nyx Assassin",
            "Treant Protector": 1.665
        },
        {
            "name": "Ogre Magi",
            "Treant Protector": -0.732
        },
        {
            "name": "Omniknight",
            "Treant Protector": -0.101
        },
        {
            "name": "Oracle",
            "Treant Protector": 1.976
        },
        {
            "name": "Outworld Destroyer",
            "Treant Protector": -0.062
        },
        {
            "name": "Pangolier",
            "Treant Protector": -2.109
        },
        {
            "name": "Phantom Assassin",
            "Treant Protector": -0.941
        },
        {
            "name": "Phantom Lancer",
            "Treant Protector": -1.994
        },
        {
            "name": "Phoenix",
            "Treant Protector": 3.296
        },
        {
            "name": "Primal Beast",
            "Treant Protector": 0.845
        },
        {
            "name": "Puck",
            "Treant Protector": -0.584
        },
        {
            "name": "Pudge",
            "Treant Protector": 0.738
        },
        {
            "name": "Pugna",
            "Treant Protector": -0.032
        },
        {
            "name": "Queen of Pain",
            "Treant Protector": 0.277
        },
        {
            "name": "Razor",
            "Treant Protector": 0.191
        },
        {
            "name": "Riki",
            "Treant Protector": -0.266
        },
        {
            "name": "Ringmaster",
            "Treant Protector": 0.385
        },
        {
            "name": "Rubick",
            "Treant Protector": -0.147
        },
        {
            "name": "Sand King",
            "Treant Protector": 1.144
        },
        {
            "name": "Shadow Demon",
            "Treant Protector": 0.037
        },
        {
            "name": "Shadow Fiend",
            "Treant Protector": -1.053
        },
        {
            "name": "Shadow Shaman",
            "Treant Protector": -0.49
        },
        {
            "name": "Silencer",
            "Treant Protector": -0.253
        },
        {
            "name": "Skywrath Mage",
            "Treant Protector": 1.053
        },
        {
            "name": "Slardar",
            "Treant Protector": 0.103
        },
        {
            "name": "Slark",
            "Treant Protector": 2.026
        },
        {
            "name": "Snapfire",
            "Treant Protector": 0.438
        },
        {
            "name": "Sniper",
            "Treant Protector": 0.075
        },
        {
            "name": "Spectre",
            "Treant Protector": -0.942
        },
        {
            "name": "Spirit Breaker",
            "Treant Protector": -0.675
        },
        {
            "name": "Storm Spirit",
            "Treant Protector": -0.777
        },
        {
            "name": "Sven",
            "Treant Protector": -1.242
        },
        {
            "name": "Techies",
            "Treant Protector": -0.01
        },
        {
            "name": "Templar Assassin",
            "Treant Protector": -1.931
        },
        {
            "name": "Terrorblade",
            "Treant Protector": 0.237
        },
        {
            "name": "Tidehunter",
            "Treant Protector": 0.057
        },
        {
            "name": "Timbersaw",
            "Treant Protector": 3.293
        },
        {
            "name": "Tinker",
            "Treant Protector": 0.966
        },
        {
            "name": "Tiny",
            "Treant Protector": -0.816
        },
        {
            "name": "Troll Warlord",
            "Treant Protector": -0.091
        },
        {
            "name": "Tusk",
            "Treant Protector": -0.789
        },
        {
            "name": "Underlord",
            "Treant Protector": 1.283
        },
        {
            "name": "Undying",
            "Treant Protector": 0.935
        },
        {
            "name": "Ursa",
            "Treant Protector": -0.302
        },
        {
            "name": "Vengeful Spirit",
            "Treant Protector": -0.487
        },
        {
            "name": "Venomancer",
            "Treant Protector": 0.511
        },
        {
            "name": "Viper",
            "Treant Protector": 0.516
        },
        {
            "name": "Visage",
            "Treant Protector": -1.553
        },
        {
            "name": "Void Spirit",
            "Treant Protector": 1.352
        },
        {
            "name": "Warlock",
            "Treant Protector": -0.015
        },
        {
            "name": "Weaver",
            "Treant Protector": -0.14
        },
        {
            "name": "Windranger",
            "Treant Protector": -0.712
        },
        {
            "name": "Winter Wyvern",
            "Treant Protector": -0.386
        },
        {
            "name": "Witch Doctor",
            "Treant Protector": 0.228
        },
        {
            "name": "Wraith King",
            "Treant Protector": -1.245
        },
        {
            "name": "Zeus",
            "Treant Protector": 0.531
        }
    ],
    "Tusk": [
        {
            "name": "Abaddon",
            "Tusk": -1.797
        },
        {
            "name": "Alchemist",
            "Tusk": -1.157
        },
        {
            "name": "Ancient Apparition",
            "Tusk": -0.364
        },
        {
            "name": "Anti-Mage",
            "Tusk": -0.674
        },
        {
            "name": "Arc Warden",
            "Tusk": 0.865
        },
        {
            "name": "Axe",
            "Tusk": 0.79
        },
        {
            "name": "Bane",
            "Tusk": 0.364
        },
        {
            "name": "Batrider",
            "Tusk": 0.579
        },
        {
            "name": "Beastmaster",
            "Tusk": -1.196
        },
        {
            "name": "Bloodseeker",
            "Tusk": 0.925
        },
        {
            "name": "Bounty Hunter",
            "Tusk": 0.08
        },
        {
            "name": "Brewmaster",
            "Tusk": 1.028
        },
        {
            "name": "Bristleback",
            "Tusk": -0.636
        },
        {
            "name": "Broodmother",
            "Tusk": -0.489
        },
        {
            "name": "Centaur Warrunner",
            "Tusk": 0.169
        },
        {
            "name": "Chaos Knight",
            "Tusk": 0.036
        },
        {
            "name": "Chen",
            "Tusk": -2.257
        },
        {
            "name": "Clinkz",
            "Tusk": -0.706
        },
        {
            "name": "Clockwerk",
            "Tusk": 0.676
        },
        {
            "name": "Crystal Maiden",
            "Tusk": 0.497
        },
        {
            "name": "Dark Seer",
            "Tusk": 1.477
        },
        {
            "name": "Dark Willow",
            "Tusk": 1.435
        },
        {
            "name": "Dawnbreaker",
            "Tusk": 1.244
        },
        {
            "name": "Dazzle",
            "Tusk": -0.375
        },
        {
            "name": "Death Prophet",
            "Tusk": 1.151
        },
        {
            "name": "Disruptor",
            "Tusk": -0.084
        },
        {
            "name": "Doom",
            "Tusk": -0.408
        },
        {
            "name": "Dragon Knight",
            "Tusk": 0.709
        },
        {
            "name": "Drow Ranger",
            "Tusk": 0.423
        },
        {
            "name": "Earth Spirit",
            "Tusk": -0.442
        },
        {
            "name": "Earthshaker",
            "Tusk": 1.246
        },
        {
            "name": "Elder Titan",
            "Tusk": 2.025
        },
        {
            "name": "Ember Spirit",
            "Tusk": 1.62
        },
        {
            "name": "Enchantress",
            "Tusk": 0.43
        },
        {
            "name": "Enigma",
            "Tusk": 0.687
        },
        {
            "name": "Faceless Void",
            "Tusk": 1.089
        },
        {
            "name": "Grimstroke",
            "Tusk": 0.413
        },
        {
            "name": "Gyrocopter",
            "Tusk": 0.436
        },
        {
            "name": "Hoodwink",
            "Tusk": -0.072
        },
        {
            "name": "Huskar",
            "Tusk": -0.952
        },
        {
            "name": "Invoker",
            "Tusk": -1.362
        },
        {
            "name": "Io",
            "Tusk": -0.039
        },
        {
            "name": "Jakiro",
            "Tusk": 0.745
        },
        {
            "name": "Juggernaut",
            "Tusk": -0.14
        },
        {
            "name": "Keeper of the Light",
            "Tusk": -1.828
        },
        {
            "name": "Kez",
            "Tusk": -0.706
        },
        {
            "name": "Kunkka",
            "Tusk": -0.504
        },
        {
            "name": "Legion Commander",
            "Tusk": -1.976
        },
        {
            "name": "Leshrac",
            "Tusk": 0.741
        },
        {
            "name": "Lich",
            "Tusk": 1.163
        },
        {
            "name": "Lifestealer",
            "Tusk": -2.274
        },
        {
            "name": "Lina",
            "Tusk": 0.349
        },
        {
            "name": "Lion",
            "Tusk": 0.246
        },
        {
            "name": "Lone Druid",
            "Tusk": -3.702
        },
        {
            "name": "Luna",
            "Tusk": 1.551
        },
        {
            "name": "Lycan",
            "Tusk": -2.05
        },
        {
            "name": "Magnus",
            "Tusk": 0.575
        },
        {
            "name": "Marci",
            "Tusk": 0.079
        },
        {
            "name": "Mars",
            "Tusk": 0.17
        },
        {
            "name": "Medusa",
            "Tusk": -0.125
        },
        {
            "name": "Meepo",
            "Tusk": 0.262
        },
        {
            "name": "Mirana",
            "Tusk": -0.857
        },
        {
            "name": "Monkey King",
            "Tusk": 0.705
        },
        {
            "name": "Morphling",
            "Tusk": 0.891
        },
        {
            "name": "Muerta",
            "Tusk": 1.215
        },
        {
            "name": "Naga Siren",
            "Tusk": 2.361
        },
        {
            "name": "Nature's Prophet",
            "Tusk": -0.152
        },
        {
            "name": "Necrophos",
            "Tusk": 1.069
        },
        {
            "name": "Night Stalker",
            "Tusk": -0.461
        },
        {
            "name": "Nyx Assassin",
            "Tusk": -0.227
        },
        {
            "name": "Ogre Magi",
            "Tusk": -0.197
        },
        {
            "name": "Omniknight",
            "Tusk": -0.082
        },
        {
            "name": "Oracle",
            "Tusk": -1.833
        },
        {
            "name": "Outworld Destroyer",
            "Tusk": -0.2
        },
        {
            "name": "Pangolier",
            "Tusk": 0.901
        },
        {
            "name": "Phantom Assassin",
            "Tusk": -1.42
        },
        {
            "name": "Phantom Lancer",
            "Tusk": 1.188
        },
        {
            "name": "Phoenix",
            "Tusk": 1.787
        },
        {
            "name": "Primal Beast",
            "Tusk": 0.544
        },
        {
            "name": "Puck",
            "Tusk": 0.947
        },
        {
            "name": "Pudge",
            "Tusk": -0.852
        },
        {
            "name": "Pugna",
            "Tusk": 0.48
        },
        {
            "name": "Queen of Pain",
            "Tusk": -0.197
        },
        {
            "name": "Razor",
            "Tusk": -0.224
        },
        {
            "name": "Riki",
            "Tusk": -0.215
        },
        {
            "name": "Ringmaster",
            "Tusk": 1.096
        },
        {
            "name": "Rubick",
            "Tusk": -1.399
        },
        {
            "name": "Sand King",
            "Tusk": 1.736
        },
        {
            "name": "Shadow Demon",
            "Tusk": -1.621
        },
        {
            "name": "Shadow Fiend",
            "Tusk": 0.335
        },
        {
            "name": "Shadow Shaman",
            "Tusk": -0.118
        },
        {
            "name": "Silencer",
            "Tusk": -0.369
        },
        {
            "name": "Skywrath Mage",
            "Tusk": -1.291
        },
        {
            "name": "Slardar",
            "Tusk": 0.238
        },
        {
            "name": "Slark",
            "Tusk": -0.873
        },
        {
            "name": "Snapfire",
            "Tusk": -0.01
        },
        {
            "name": "Sniper",
            "Tusk": -0.119
        },
        {
            "name": "Spectre",
            "Tusk": 0.465
        },
        {
            "name": "Spirit Breaker",
            "Tusk": -1.518
        },
        {
            "name": "Storm Spirit",
            "Tusk": 0.071
        },
        {
            "name": "Sven",
            "Tusk": 0.556
        },
        {
            "name": "Techies",
            "Tusk": 0.497
        },
        {
            "name": "Templar Assassin",
            "Tusk": 1.59
        },
        {
            "name": "Terrorblade",
            "Tusk": 1.376
        },
        {
            "name": "Tidehunter",
            "Tusk": -0.723
        },
        {
            "name": "Timbersaw",
            "Tusk": 2.383
        },
        {
            "name": "Tinker",
            "Tusk": -0.001
        },
        {
            "name": "Tiny",
            "Tusk": -0.256
        },
        {
            "name": "Treant Protector",
            "Tusk": 0.785
        },
        {
            "name": "Troll Warlord",
            "Tusk": 0.359
        },
        {
            "name": "Underlord",
            "Tusk": 1.711
        },
        {
            "name": "Undying",
            "Tusk": 1.912
        },
        {
            "name": "Ursa",
            "Tusk": 0.089
        },
        {
            "name": "Vengeful Spirit",
            "Tusk": -0.177
        },
        {
            "name": "Venomancer",
            "Tusk": 0.463
        },
        {
            "name": "Viper",
            "Tusk": -0.293
        },
        {
            "name": "Visage",
            "Tusk": -0.063
        },
        {
            "name": "Void Spirit",
            "Tusk": -0.205
        },
        {
            "name": "Warlock",
            "Tusk": -0.857
        },
        {
            "name": "Weaver",
            "Tusk": 0.837
        },
        {
            "name": "Windranger",
            "Tusk": -0.753
        },
        {
            "name": "Winter Wyvern",
            "Tusk": 1.972
        },
        {
            "name": "Witch Doctor",
            "Tusk": 0.023
        },
        {
            "name": "Wraith King",
            "Tusk": -0.117
        },
        {
            "name": "Zeus",
            "Tusk": -0.422
        }
    ],
    "Underlord": [
        {
            "name": "Abaddon",
            "Underlord": -0.961
        },
        {
            "name": "Alchemist",
            "Underlord": 0.627
        },
        {
            "name": "Ancient Apparition",
            "Underlord": 1.791
        },
        {
            "name": "Anti-Mage",
            "Underlord": 1.206
        },
        {
            "name": "Arc Warden",
            "Underlord": 0.431
        },
        {
            "name": "Axe",
            "Underlord": 0.102
        },
        {
            "name": "Bane",
            "Underlord": -0.209
        },
        {
            "name": "Batrider",
            "Underlord": 1.062
        },
        {
            "name": "Beastmaster",
            "Underlord": 0.314
        },
        {
            "name": "Bloodseeker",
            "Underlord": -0.076
        },
        {
            "name": "Bounty Hunter",
            "Underlord": 1.043
        },
        {
            "name": "Brewmaster",
            "Underlord": 0.56
        },
        {
            "name": "Bristleback",
            "Underlord": 1.503
        },
        {
            "name": "Broodmother",
            "Underlord": -0.745
        },
        {
            "name": "Centaur Warrunner",
            "Underlord": -2.046
        },
        {
            "name": "Chaos Knight",
            "Underlord": -2.709
        },
        {
            "name": "Chen",
            "Underlord": 2.623
        },
        {
            "name": "Clinkz",
            "Underlord": 1.419
        },
        {
            "name": "Clockwerk",
            "Underlord": -1.761
        },
        {
            "name": "Crystal Maiden",
            "Underlord": -0.191
        },
        {
            "name": "Dark Seer",
            "Underlord": -0.004
        },
        {
            "name": "Dark Willow",
            "Underlord": 0.269
        },
        {
            "name": "Dawnbreaker",
            "Underlord": -1.008
        },
        {
            "name": "Dazzle",
            "Underlord": 1.277
        },
        {
            "name": "Death Prophet",
            "Underlord": -0.655
        },
        {
            "name": "Disruptor",
            "Underlord": -0.387
        },
        {
            "name": "Doom",
            "Underlord": 1.441
        },
        {
            "name": "Dragon Knight",
            "Underlord": -1.375
        },
        {
            "name": "Drow Ranger",
            "Underlord": -0.428
        },
        {
            "name": "Earth Spirit",
            "Underlord": 1.443
        },
        {
            "name": "Earthshaker",
            "Underlord": 0.377
        },
        {
            "name": "Elder Titan",
            "Underlord": 0.703
        },
        {
            "name": "Ember Spirit",
            "Underlord": -1.23
        },
        {
            "name": "Enchantress",
            "Underlord": 0.397
        },
        {
            "name": "Enigma",
            "Underlord": -0.135
        },
        {
            "name": "Faceless Void",
            "Underlord": -0.324
        },
        {
            "name": "Grimstroke",
            "Underlord": -0.59
        },
        {
            "name": "Gyrocopter",
            "Underlord": -2.175
        },
        {
            "name": "Hoodwink",
            "Underlord": 0.328
        },
        {
            "name": "Huskar",
            "Underlord": 1.735
        },
        {
            "name": "Invoker",
            "Underlord": 0.819
        },
        {
            "name": "Io",
            "Underlord": -2.278
        },
        {
            "name": "Jakiro",
            "Underlord": -0.076
        },
        {
            "name": "Juggernaut",
            "Underlord": 2.762
        },
        {
            "name": "Keeper of the Light",
            "Underlord": 0.34
        },
        {
            "name": "Kez",
            "Underlord": -0.608
        },
        {
            "name": "Kunkka",
            "Underlord": -0.487
        },
        {
            "name": "Legion Commander",
            "Underlord": 0.905
        },
        {
            "name": "Leshrac",
            "Underlord": 0.159
        },
        {
            "name": "Lich",
            "Underlord": -0.073
        },
        {
            "name": "Lifestealer",
            "Underlord": 3.382
        },
        {
            "name": "Lina",
            "Underlord": -0.589
        },
        {
            "name": "Lion",
            "Underlord": -0.016
        },
        {
            "name": "Lone Druid",
            "Underlord": -5.533
        },
        {
            "name": "Luna",
            "Underlord": -1.536
        },
        {
            "name": "Lycan",
            "Underlord": -1.329
        },
        {
            "name": "Magnus",
            "Underlord": 0.442
        },
        {
            "name": "Marci",
            "Underlord": -0.685
        },
        {
            "name": "Mars",
            "Underlord": -0.52
        },
        {
            "name": "Medusa",
            "Underlord": -3.14
        },
        {
            "name": "Meepo",
            "Underlord": -5.903
        },
        {
            "name": "Mirana",
            "Underlord": -1.194
        },
        {
            "name": "Monkey King",
            "Underlord": 1.338
        },
        {
            "name": "Morphling",
            "Underlord": 1.793
        },
        {
            "name": "Muerta",
            "Underlord": -0.118
        },
        {
            "name": "Naga Siren",
            "Underlord": -3.715
        },
        {
            "name": "Nature's Prophet",
            "Underlord": 0.507
        },
        {
            "name": "Necrophos",
            "Underlord": -1.341
        },
        {
            "name": "Night Stalker",
            "Underlord": -0.008
        },
        {
            "name": "Nyx Assassin",
            "Underlord": -0.129
        },
        {
            "name": "Ogre Magi",
            "Underlord": -0.973
        },
        {
            "name": "Omniknight",
            "Underlord": 0.018
        },
        {
            "name": "Oracle",
            "Underlord": 1.628
        },
        {
            "name": "Outworld Destroyer",
            "Underlord": 1.744
        },
        {
            "name": "Pangolier",
            "Underlord": 1.209
        },
        {
            "name": "Phantom Assassin",
            "Underlord": 0.005
        },
        {
            "name": "Phantom Lancer",
            "Underlord": -3.92
        },
        {
            "name": "Phoenix",
            "Underlord": 0.023
        },
        {
            "name": "Primal Beast",
            "Underlord": -3.717
        },
        {
            "name": "Puck",
            "Underlord": -0.567
        },
        {
            "name": "Pudge",
            "Underlord": 1.63
        },
        {
            "name": "Pugna",
            "Underlord": 0.943
        },
        {
            "name": "Queen of Pain",
            "Underlord": 0.194
        },
        {
            "name": "Razor",
            "Underlord": -1.069
        },
        {
            "name": "Riki",
            "Underlord": -3.294
        },
        {
            "name": "Ringmaster",
            "Underlord": 0.388
        },
        {
            "name": "Rubick",
            "Underlord": 6.259
        },
        {
            "name": "Sand King",
            "Underlord": -1.234
        },
        {
            "name": "Shadow Demon",
            "Underlord": -1.293
        },
        {
            "name": "Shadow Fiend",
            "Underlord": 0.422
        },
        {
            "name": "Shadow Shaman",
            "Underlord": -0.174
        },
        {
            "name": "Silencer",
            "Underlord": 0.409
        },
        {
            "name": "Skywrath Mage",
            "Underlord": 0.59
        },
        {
            "name": "Slardar",
            "Underlord": -0.074
        },
        {
            "name": "Slark",
            "Underlord": 0.831
        },
        {
            "name": "Snapfire",
            "Underlord": 0.458
        },
        {
            "name": "Sniper",
            "Underlord": 1.691
        },
        {
            "name": "Spectre",
            "Underlord": -1.593
        },
        {
            "name": "Spirit Breaker",
            "Underlord": -3.24
        },
        {
            "name": "Storm Spirit",
            "Underlord": -0.515
        },
        {
            "name": "Sven",
            "Underlord": 0.409
        },
        {
            "name": "Techies",
            "Underlord": -0.862
        },
        {
            "name": "Templar Assassin",
            "Underlord": -1.339
        },
        {
            "name": "Terrorblade",
            "Underlord": -1.508
        },
        {
            "name": "Tidehunter",
            "Underlord": -1.73
        },
        {
            "name": "Timbersaw",
            "Underlord": 0.74
        },
        {
            "name": "Tinker",
            "Underlord": 1.456
        },
        {
            "name": "Tiny",
            "Underlord": -3.06
        },
        {
            "name": "Treant Protector",
            "Underlord": -1.306
        },
        {
            "name": "Troll Warlord",
            "Underlord": -0.934
        },
        {
            "name": "Tusk",
            "Underlord": -1.78
        },
        {
            "name": "Undying",
            "Underlord": 0.183
        },
        {
            "name": "Ursa",
            "Underlord": 2.302
        },
        {
            "name": "Vengeful Spirit",
            "Underlord": -1.162
        },
        {
            "name": "Venomancer",
            "Underlord": 0.155
        },
        {
            "name": "Viper",
            "Underlord": 0.168
        },
        {
            "name": "Visage",
            "Underlord": -2.681
        },
        {
            "name": "Void Spirit",
            "Underlord": -1.747
        },
        {
            "name": "Warlock",
            "Underlord": -0.663
        },
        {
            "name": "Weaver",
            "Underlord": -0.681
        },
        {
            "name": "Windranger",
            "Underlord": 0.095
        },
        {
            "name": "Winter Wyvern",
            "Underlord": -0.677
        },
        {
            "name": "Witch Doctor",
            "Underlord": 1.574
        },
        {
            "name": "Wraith King",
            "Underlord": -4.426
        },
        {
            "name": "Zeus",
            "Underlord": -1.407
        }
    ],
    "Undying": [
        {
            "name": "Abaddon",
            "Undying": -2.942
        },
        {
            "name": "Alchemist",
            "Undying": 0.996
        },
        {
            "name": "Ancient Apparition",
            "Undying": 2.22
        },
        {
            "name": "Anti-Mage",
            "Undying": 1.131
        },
        {
            "name": "Arc Warden",
            "Undying": -0.435
        },
        {
            "name": "Axe",
            "Undying": -0.545
        },
        {
            "name": "Bane",
            "Undying": -0.804
        },
        {
            "name": "Batrider",
            "Undying": 0.47
        },
        {
            "name": "Beastmaster",
            "Undying": -1.535
        },
        {
            "name": "Bloodseeker",
            "Undying": 1.207
        },
        {
            "name": "Bounty Hunter",
            "Undying": 1.446
        },
        {
            "name": "Brewmaster",
            "Undying": 0.083
        },
        {
            "name": "Bristleback",
            "Undying": 0.607
        },
        {
            "name": "Broodmother",
            "Undying": 1.221
        },
        {
            "name": "Centaur Warrunner",
            "Undying": -1.535
        },
        {
            "name": "Chaos Knight",
            "Undying": 0.291
        },
        {
            "name": "Chen",
            "Undying": 0.487
        },
        {
            "name": "Clinkz",
            "Undying": 2.021
        },
        {
            "name": "Clockwerk",
            "Undying": -1.791
        },
        {
            "name": "Crystal Maiden",
            "Undying": -0.779
        },
        {
            "name": "Dark Seer",
            "Undying": 0.456
        },
        {
            "name": "Dark Willow",
            "Undying": 0.216
        },
        {
            "name": "Dawnbreaker",
            "Undying": 0.588
        },
        {
            "name": "Dazzle",
            "Undying": -0.223
        },
        {
            "name": "Death Prophet",
            "Undying": -2.26
        },
        {
            "name": "Disruptor",
            "Undying": -0.834
        },
        {
            "name": "Doom",
            "Undying": 0.348
        },
        {
            "name": "Dragon Knight",
            "Undying": -0.71
        },
        {
            "name": "Drow Ranger",
            "Undying": -0.195
        },
        {
            "name": "Earth Spirit",
            "Undying": 0.339
        },
        {
            "name": "Earthshaker",
            "Undying": 0.657
        },
        {
            "name": "Elder Titan",
            "Undying": 1.843
        },
        {
            "name": "Ember Spirit",
            "Undying": 1.043
        },
        {
            "name": "Enchantress",
            "Undying": 0.417
        },
        {
            "name": "Enigma",
            "Undying": -0.649
        },
        {
            "name": "Faceless Void",
            "Undying": 1.711
        },
        {
            "name": "Grimstroke",
            "Undying": 0.344
        },
        {
            "name": "Gyrocopter",
            "Undying": 1.502
        },
        {
            "name": "Hoodwink",
            "Undying": 0.629
        },
        {
            "name": "Huskar",
            "Undying": -1.097
        },
        {
            "name": "Invoker",
            "Undying": -0.329
        },
        {
            "name": "Io",
            "Undying": -0.465
        },
        {
            "name": "Jakiro",
            "Undying": 0.401
        },
        {
            "name": "Juggernaut",
            "Undying": 0.547
        },
        {
            "name": "Keeper of the Light",
            "Undying": 0.792
        },
        {
            "name": "Kez",
            "Undying": 0.992
        },
        {
            "name": "Kunkka",
            "Undying": -1.602
        },
        {
            "name": "Legion Commander",
            "Undying": 0.943
        },
        {
            "name": "Leshrac",
            "Undying": -3.698
        },
        {
            "name": "Lich",
            "Undying": -1.456
        },
        {
            "name": "Lifestealer",
            "Undying": 0.617
        },
        {
            "name": "Lina",
            "Undying": 0.808
        },
        {
            "name": "Lion",
            "Undying": 0.203
        },
        {
            "name": "Lone Druid",
            "Undying": -0.338
        },
        {
            "name": "Luna",
            "Undying": 2.869
        },
        {
            "name": "Lycan",
            "Undying": 1.183
        },
        {
            "name": "Magnus",
            "Undying": 0.742
        },
        {
            "name": "Marci",
            "Undying": 1.758
        },
        {
            "name": "Mars",
            "Undying": -1.201
        },
        {
            "name": "Medusa",
            "Undying": 5.005
        },
        {
            "name": "Meepo",
            "Undying": 1.885
        },
        {
            "name": "Mirana",
            "Undying": -0.08
        },
        {
            "name": "Monkey King",
            "Undying": 0.83
        },
        {
            "name": "Morphling",
            "Undying": 1.241
        },
        {
            "name": "Muerta",
            "Undying": 0.834
        },
        {
            "name": "Naga Siren",
            "Undying": 1.812
        },
        {
            "name": "Nature's Prophet",
            "Undying": 0.396
        },
        {
            "name": "Necrophos",
            "Undying": 0.501
        },
        {
            "name": "Night Stalker",
            "Undying": -0.353
        },
        {
            "name": "Nyx Assassin",
            "Undying": -0.099
        },
        {
            "name": "Ogre Magi",
            "Undying": 0.728
        },
        {
            "name": "Omniknight",
            "Undying": -1.704
        },
        {
            "name": "Oracle",
            "Undying": -2.761
        },
        {
            "name": "Outworld Destroyer",
            "Undying": -1.644
        },
        {
            "name": "Pangolier",
            "Undying": 1.165
        },
        {
            "name": "Phantom Assassin",
            "Undying": 0.16
        },
        {
            "name": "Phantom Lancer",
            "Undying": 2.082
        },
        {
            "name": "Phoenix",
            "Undying": 0.894
        },
        {
            "name": "Primal Beast",
            "Undying": -0.36
        },
        {
            "name": "Puck",
            "Undying": -0.822
        },
        {
            "name": "Pudge",
            "Undying": -1.292
        },
        {
            "name": "Pugna",
            "Undying": 0.863
        },
        {
            "name": "Queen of Pain",
            "Undying": 0.119
        },
        {
            "name": "Razor",
            "Undying": 0.006
        },
        {
            "name": "Riki",
            "Undying": 1.533
        },
        {
            "name": "Ringmaster",
            "Undying": -0.907
        },
        {
            "name": "Rubick",
            "Undying": 1.174
        },
        {
            "name": "Sand King",
            "Undying": 0.634
        },
        {
            "name": "Shadow Demon",
            "Undying": -0.246
        },
        {
            "name": "Shadow Fiend",
            "Undying": 0.396
        },
        {
            "name": "Shadow Shaman",
            "Undying": -0.269
        },
        {
            "name": "Silencer",
            "Undying": 0.543
        },
        {
            "name": "Skywrath Mage",
            "Undying": 0.175
        },
        {
            "name": "Slardar",
            "Undying": 0.763
        },
        {
            "name": "Slark",
            "Undying": 0.523
        },
        {
            "name": "Snapfire",
            "Undying": 0.381
        },
        {
            "name": "Sniper",
            "Undying": 0.92
        },
        {
            "name": "Spectre",
            "Undying": -2.976
        },
        {
            "name": "Spirit Breaker",
            "Undying": -0.143
        },
        {
            "name": "Storm Spirit",
            "Undying": 0.336
        },
        {
            "name": "Sven",
            "Undying": -0.945
        },
        {
            "name": "Techies",
            "Undying": -0.176
        },
        {
            "name": "Templar Assassin",
            "Undying": 0.861
        },
        {
            "name": "Terrorblade",
            "Undying": 0.888
        },
        {
            "name": "Tidehunter",
            "Undying": -1.784
        },
        {
            "name": "Timbersaw",
            "Undying": 1.231
        },
        {
            "name": "Tinker",
            "Undying": 0.58
        },
        {
            "name": "Tiny",
            "Undying": -1.997
        },
        {
            "name": "Treant Protector",
            "Undying": -0.704
        },
        {
            "name": "Troll Warlord",
            "Undying": 1.279
        },
        {
            "name": "Tusk",
            "Undying": -1.657
        },
        {
            "name": "Underlord",
            "Undying": -0.149
        },
        {
            "name": "Ursa",
            "Undying": -1.638
        },
        {
            "name": "Vengeful Spirit",
            "Undying": -0.45
        },
        {
            "name": "Venomancer",
            "Undying": 1.733
        },
        {
            "name": "Viper",
            "Undying": -0.647
        },
        {
            "name": "Visage",
            "Undying": -1.792
        },
        {
            "name": "Void Spirit",
            "Undying": 0.626
        },
        {
            "name": "Warlock",
            "Undying": 0.578
        },
        {
            "name": "Weaver",
            "Undying": 1.605
        },
        {
            "name": "Windranger",
            "Undying": 0.52
        },
        {
            "name": "Winter Wyvern",
            "Undying": -2.064
        },
        {
            "name": "Witch Doctor",
            "Undying": 1.873
        },
        {
            "name": "Wraith King",
            "Undying": -2.894
        },
        {
            "name": "Zeus",
            "Undying": -0.995
        }
    ],
    "Wraith King": [
        {
            "name": "Abaddon",
            "Wraith King": 1.846
        },
        {
            "name": "Alchemist",
            "Wraith King": 2.694
        },
        {
            "name": "Ancient Apparition",
            "Wraith King": -0.371
        },
        {
            "name": "Anti-Mage",
            "Wraith King": 2.762
        },
        {
            "name": "Arc Warden",
            "Wraith King": 0.717
        },
        {
            "name": "Axe",
            "Wraith King": -1.533
        },
        {
            "name": "Bane",
            "Wraith King": 0.665
        },
        {
            "name": "Batrider",
            "Wraith King": 0.703
        },
        {
            "name": "Beastmaster",
            "Wraith King": 2.133
        },
        {
            "name": "Bloodseeker",
            "Wraith King": -0.187
        },
        {
            "name": "Bounty Hunter",
            "Wraith King": 1.393
        },
        {
            "name": "Brewmaster",
            "Wraith King": 2.114
        },
        {
            "name": "Bristleback",
            "Wraith King": 2.144
        },
        {
            "name": "Broodmother",
            "Wraith King": 2.254
        },
        {
            "name": "Centaur Warrunner",
            "Wraith King": -0.274
        },
        {
            "name": "Chaos Knight",
            "Wraith King": 0.877
        },
        {
            "name": "Chen",
            "Wraith King": 3.897
        },
        {
            "name": "Clinkz",
            "Wraith King": -1.937
        },
        {
            "name": "Clockwerk",
            "Wraith King": 0.315
        },
        {
            "name": "Crystal Maiden",
            "Wraith King": 1.716
        },
        {
            "name": "Dark Seer",
            "Wraith King": 3.351
        },
        {
            "name": "Dark Willow",
            "Wraith King": -0.008
        },
        {
            "name": "Dawnbreaker",
            "Wraith King": 1.446
        },
        {
            "name": "Dazzle",
            "Wraith King": 1.838
        },
        {
            "name": "Death Prophet",
            "Wraith King": 0.482
        },
        {
            "name": "Disruptor",
            "Wraith King": -0.246
        },
        {
            "name": "Doom",
            "Wraith King": -0.782
        },
        {
            "name": "Dragon Knight",
            "Wraith King": 0.628
        },
        {
            "name": "Drow Ranger",
            "Wraith King": -1.922
        },
        {
            "name": "Earth Spirit",
            "Wraith King": -0.508
        },
        {
            "name": "Earthshaker",
            "Wraith King": -0.628
        },
        {
            "name": "Elder Titan",
            "Wraith King": -2.357
        },
        {
            "name": "Ember Spirit",
            "Wraith King": 0.908
        },
        {
            "name": "Enchantress",
            "Wraith King": 1.063
        },
        {
            "name": "Enigma",
            "Wraith King": -2.09
        },
        {
            "name": "Faceless Void",
            "Wraith King": -0.263
        },
        {
            "name": "Grimstroke",
            "Wraith King": -0.586
        },
        {
            "name": "Gyrocopter",
            "Wraith King": -0.344
        },
        {
            "name": "Hoodwink",
            "Wraith King": -0.379
        },
        {
            "name": "Huskar",
            "Wraith King": -1.651
        },
        {
            "name": "Invoker",
            "Wraith King": 0.515
        },
        {
            "name": "Io",
            "Wraith King": 1.734
        },
        {
            "name": "Jakiro",
            "Wraith King": 2.202
        },
        {
            "name": "Juggernaut",
            "Wraith King": -0.698
        },
        {
            "name": "Keeper of the Light",
            "Wraith King": 2.549
        },
        {
            "name": "Kez",
            "Wraith King": 0.062
        },
        {
            "name": "Kunkka",
            "Wraith King": 0.011
        },
        {
            "name": "Legion Commander",
            "Wraith King": -2.116
        },
        {
            "name": "Leshrac",
            "Wraith King": 0.633
        },
        {
            "name": "Lich",
            "Wraith King": 0.719
        },
        {
            "name": "Lifestealer",
            "Wraith King": 1.841
        },
        {
            "name": "Lina",
            "Wraith King": 0.042
        },
        {
            "name": "Lion",
            "Wraith King": -0.92
        },
        {
            "name": "Lone Druid",
            "Wraith King": 3.823
        },
        {
            "name": "Luna",
            "Wraith King": -0.015
        },
        {
            "name": "Lycan",
            "Wraith King": 1.832
        },
        {
            "name": "Magnus",
            "Wraith King": -0.761
        },
        {
            "name": "Marci",
            "Wraith King": 0.156
        },
        {
            "name": "Mars",
            "Wraith King": -0.13
        },
        {
            "name": "Medusa",
            "Wraith King": 0.782
        },
        {
            "name": "Meepo",
            "Wraith King": 3.011
        },
        {
            "name": "Mirana",
            "Wraith King": -0.505
        },
        {
            "name": "Monkey King",
            "Wraith King": 1.667
        },
        {
            "name": "Morphling",
            "Wraith King": 1.726
        },
        {
            "name": "Muerta",
            "Wraith King": -0.946
        },
        {
            "name": "Naga Siren",
            "Wraith King": 3.826
        },
        {
            "name": "Nature's Prophet",
            "Wraith King": -0.793
        },
        {
            "name": "Necrophos",
            "Wraith King": 4.271
        },
        {
            "name": "Night Stalker",
            "Wraith King": -1.19
        },
        {
            "name": "Nyx Assassin",
            "Wraith King": -0.584
        },
        {
            "name": "Ogre Magi",
            "Wraith King": 0.329
        },
        {
            "name": "Omniknight",
            "Wraith King": 0.765
        },
        {
            "name": "Oracle",
            "Wraith King": -0.326
        },
        {
            "name": "Outworld Destroyer",
            "Wraith King": -1.877
        },
        {
            "name": "Pangolier",
            "Wraith King": 1.982
        },
        {
            "name": "Phantom Assassin",
            "Wraith King": -0.902
        },
        {
            "name": "Phantom Lancer",
            "Wraith King": 5.222
        },
        {
            "name": "Phoenix",
            "Wraith King": 3.117
        },
        {
            "name": "Primal Beast",
            "Wraith King": 0.405
        },
        {
            "name": "Puck",
            "Wraith King": -1.319
        },
        {
            "name": "Pudge",
            "Wraith King": -0.955
        },
        {
            "name": "Pugna",
            "Wraith King": -0.029
        },
        {
            "name": "Queen of Pain",
            "Wraith King": 0.252
        },
        {
            "name": "Razor",
            "Wraith King": 0.555
        },
        {
            "name": "Riki",
            "Wraith King": -1.251
        },
        {
            "name": "Ringmaster",
            "Wraith King": -0.425
        },
        {
            "name": "Rubick",
            "Wraith King": 1.427
        },
        {
            "name": "Sand King",
            "Wraith King": 1.317
        },
        {
            "name": "Shadow Demon",
            "Wraith King": 0.264
        },
        {
            "name": "Shadow Fiend",
            "Wraith King": -1.307
        },
        {
            "name": "Shadow Shaman",
            "Wraith King": -1.308
        },
        {
            "name": "Silencer",
            "Wraith King": -1.713
        },
        {
            "name": "Skywrath Mage",
            "Wraith King": -1.51
        },
        {
            "name": "Slardar",
            "Wraith King": 0.484
        },
        {
            "name": "Slark",
            "Wraith King": 2.576
        },
        {
            "name": "Snapfire",
            "Wraith King": 0.526
        },
        {
            "name": "Sniper",
            "Wraith King": -0.013
        },
        {
            "name": "Spectre",
            "Wraith King": -0.672
        },
        {
            "name": "Spirit Breaker",
            "Wraith King": -1.436
        },
        {
            "name": "Storm Spirit",
            "Wraith King": 0.644
        },
        {
            "name": "Sven",
            "Wraith King": -1.508
        },
        {
            "name": "Techies",
            "Wraith King": -1.201
        },
        {
            "name": "Templar Assassin",
            "Wraith King": -0.519
        },
        {
            "name": "Terrorblade",
            "Wraith King": 2.305
        },
        {
            "name": "Tidehunter",
            "Wraith King": 3.731
        },
        {
            "name": "Timbersaw",
            "Wraith King": 2.147
        },
        {
            "name": "Tinker",
            "Wraith King": 1.035
        },
        {
            "name": "Tiny",
            "Wraith King": -0.57
        },
        {
            "name": "Treant Protector",
            "Wraith King": 1.591
        },
        {
            "name": "Troll Warlord",
            "Wraith King": -0.432
        },
        {
            "name": "Tusk",
            "Wraith King": 0.541
        },
        {
            "name": "Underlord",
            "Wraith King": 4.393
        },
        {
            "name": "Undying",
            "Wraith King": 2.866
        },
        {
            "name": "Ursa",
            "Wraith King": -2.872
        },
        {
            "name": "Vengeful Spirit",
            "Wraith King": -0.107
        },
        {
            "name": "Venomancer",
            "Wraith King": 1.758
        },
        {
            "name": "Viper",
            "Wraith King": -0.688
        },
        {
            "name": "Visage",
            "Wraith King": 0.533
        },
        {
            "name": "Void Spirit",
            "Wraith King": -0.597
        },
        {
            "name": "Warlock",
            "Wraith King": 3.693
        },
        {
            "name": "Weaver",
            "Wraith King": -0.251
        },
        {
            "name": "Windranger",
            "Wraith King": 0.013
        },
        {
            "name": "Winter Wyvern",
            "Wraith King": 0.986
        },
        {
            "name": "Witch Doctor",
            "Wraith King": -1.226
        },
        {
            "name": "Zeus",
            "Wraith King": -0.794
        }
    ],
    "Anti-Mage": [
        {
            "name": "Abaddon",
            "Anti-Mage": -2.537
        },
        {
            "name": "Alchemist",
            "Anti-Mage": -1.531
        },
        {
            "name": "Ancient Apparition",
            "Anti-Mage": -2.874
        },
        {
            "name": "Arc Warden",
            "Anti-Mage": -1.108
        },
        {
            "name": "Axe",
            "Anti-Mage": 4.703
        },
        {
            "name": "Bane",
            "Anti-Mage": -0.272
        },
        {
            "name": "Batrider",
            "Anti-Mage": 1.07
        },
        {
            "name": "Beastmaster",
            "Anti-Mage": 1.389
        },
        {
            "name": "Bloodseeker",
            "Anti-Mage": 2.532
        },
        {
            "name": "Bounty Hunter",
            "Anti-Mage": -0.743
        },
        {
            "name": "Brewmaster",
            "Anti-Mage": -0.133
        },
        {
            "name": "Bristleback",
            "Anti-Mage": -0.754
        },
        {
            "name": "Broodmother",
            "Anti-Mage": 1.453
        },
        {
            "name": "Centaur Warrunner",
            "Anti-Mage": 1.245
        },
        {
            "name": "Chaos Knight",
            "Anti-Mage": -2.141
        },
        {
            "name": "Chen",
            "Anti-Mage": -0.351
        },
        {
            "name": "Clinkz",
            "Anti-Mage": -0.595
        },
        {
            "name": "Clockwerk",
            "Anti-Mage": -2.358
        },
        {
            "name": "Crystal Maiden",
            "Anti-Mage": 0.842
        },
        {
            "name": "Dark Seer",
            "Anti-Mage": -0.402
        },
        {
            "name": "Dark Willow",
            "Anti-Mage": 0.324
        },
        {
            "name": "Dawnbreaker",
            "Anti-Mage": 0.131
        },
        {
            "name": "Dazzle",
            "Anti-Mage": -1.418
        },
        {
            "name": "Death Prophet",
            "Anti-Mage": -1.747
        },
        {
            "name": "Disruptor",
            "Anti-Mage": 1.219
        },
        {
            "name": "Doom",
            "Anti-Mage": -1.461
        },
        {
            "name": "Dragon Knight",
            "Anti-Mage": 0.01
        },
        {
            "name": "Drow Ranger",
            "Anti-Mage": 1.029
        },
        {
            "name": "Earth Spirit",
            "Anti-Mage": -0.27
        },
        {
            "name": "Earthshaker",
            "Anti-Mage": 2.534
        },
        {
            "name": "Elder Titan",
            "Anti-Mage": 1.028
        },
        {
            "name": "Ember Spirit",
            "Anti-Mage": -0.737
        },
        {
            "name": "Enchantress",
            "Anti-Mage": 3.232
        },
        {
            "name": "Enigma",
            "Anti-Mage": 1.221
        },
        {
            "name": "Faceless Void",
            "Anti-Mage": -1.012
        },
        {
            "name": "Grimstroke",
            "Anti-Mage": -0.739
        },
        {
            "name": "Gyrocopter",
            "Anti-Mage": -0.849
        },
        {
            "name": "Hoodwink",
            "Anti-Mage": 2.586
        },
        {
            "name": "Huskar",
            "Anti-Mage": 6.584
        },
        {
            "name": "Invoker",
            "Anti-Mage": -0.694
        },
        {
            "name": "Io",
            "Anti-Mage": 2.355
        },
        {
            "name": "Jakiro",
            "Anti-Mage": 1.723
        },
        {
            "name": "Juggernaut",
            "Anti-Mage": -2.687
        },
        {
            "name": "Keeper of the Light",
            "Anti-Mage": -1.359
        },
        {
            "name": "Kez",
            "Anti-Mage": 3.087
        },
        {
            "name": "Kunkka",
            "Anti-Mage": 0.594
        },
        {
            "name": "Legion Commander",
            "Anti-Mage": 3.2
        },
        {
            "name": "Leshrac",
            "Anti-Mage": 0.176
        },
        {
            "name": "Lich",
            "Anti-Mage": -1.115
        },
        {
            "name": "Lifestealer",
            "Anti-Mage": -1.979
        },
        {
            "name": "Lina",
            "Anti-Mage": 0.325
        },
        {
            "name": "Lion",
            "Anti-Mage": 1.198
        },
        {
            "name": "Lone Druid",
            "Anti-Mage": 4.175
        },
        {
            "name": "Luna",
            "Anti-Mage": 1.001
        },
        {
            "name": "Lycan",
            "Anti-Mage": 0.366
        },
        {
            "name": "Magnus",
            "Anti-Mage": 1.67
        },
        {
            "name": "Marci",
            "Anti-Mage": 3.189
        },
        {
            "name": "Mars",
            "Anti-Mage": -1.827
        },
        {
            "name": "Medusa",
            "Anti-Mage": -10.133
        },
        {
            "name": "Meepo",
            "Anti-Mage": 6.736
        },
        {
            "name": "Mirana",
            "Anti-Mage": 0.967
        },
        {
            "name": "Monkey King",
            "Anti-Mage": 2.246
        },
        {
            "name": "Morphling",
            "Anti-Mage": -0.903
        },
        {
            "name": "Muerta",
            "Anti-Mage": -0.411
        },
        {
            "name": "Naga Siren",
            "Anti-Mage": -0.168
        },
        {
            "name": "Nature's Prophet",
            "Anti-Mage": -1.255
        },
        {
            "name": "Necrophos",
            "Anti-Mage": -0.786
        },
        {
            "name": "Night Stalker",
            "Anti-Mage": 1.64
        },
        {
            "name": "Nyx Assassin",
            "Anti-Mage": 1.807
        },
        {
            "name": "Ogre Magi",
            "Anti-Mage": -2.914
        },
        {
            "name": "Omniknight",
            "Anti-Mage": 0.906
        },
        {
            "name": "Oracle",
            "Anti-Mage": -2.976
        },
        {
            "name": "Outworld Destroyer",
            "Anti-Mage": -2.149
        },
        {
            "name": "Pangolier",
            "Anti-Mage": 0.751
        },
        {
            "name": "Phantom Assassin",
            "Anti-Mage": 3.775
        },
        {
            "name": "Phantom Lancer",
            "Anti-Mage": -2.193
        },
        {
            "name": "Phoenix",
            "Anti-Mage": 1.463
        },
        {
            "name": "Primal Beast",
            "Anti-Mage": -0.756
        },
        {
            "name": "Puck",
            "Anti-Mage": 1.965
        },
        {
            "name": "Pudge",
            "Anti-Mage": -0.934
        },
        {
            "name": "Pugna",
            "Anti-Mage": -2.099
        },
        {
            "name": "Queen of Pain",
            "Anti-Mage": -2.183
        },
        {
            "name": "Razor",
            "Anti-Mage": -3.184
        },
        {
            "name": "Riki",
            "Anti-Mage": 1.982
        },
        {
            "name": "Ringmaster",
            "Anti-Mage": -0.619
        },
        {
            "name": "Rubick",
            "Anti-Mage": -0.172
        },
        {
            "name": "Sand King",
            "Anti-Mage": -0.953
        },
        {
            "name": "Shadow Demon",
            "Anti-Mage": -0.984
        },
        {
            "name": "Shadow Fiend",
            "Anti-Mage": 2.657
        },
        {
            "name": "Shadow Shaman",
            "Anti-Mage": 1.188
        },
        {
            "name": "Silencer",
            "Anti-Mage": -1.251
        },
        {
            "name": "Skywrath Mage",
            "Anti-Mage": -1.031
        },
        {
            "name": "Slardar",
            "Anti-Mage": 3.518
        },
        {
            "name": "Slark",
            "Anti-Mage": 0.52
        },
        {
            "name": "Snapfire",
            "Anti-Mage": 0.24
        },
        {
            "name": "Sniper",
            "Anti-Mage": 0.133
        },
        {
            "name": "Spectre",
            "Anti-Mage": -0.048
        },
        {
            "name": "Spirit Breaker",
            "Anti-Mage": 0.954
        },
        {
            "name": "Storm Spirit",
            "Anti-Mage": -3.796
        },
        {
            "name": "Sven",
            "Anti-Mage": 2.38
        },
        {
            "name": "Techies",
            "Anti-Mage": 0.761
        },
        {
            "name": "Templar Assassin",
            "Anti-Mage": 2.245
        },
        {
            "name": "Terrorblade",
            "Anti-Mage": 3.311
        },
        {
            "name": "Tidehunter",
            "Anti-Mage": -1.601
        },
        {
            "name": "Timbersaw",
            "Anti-Mage": 0.963
        },
        {
            "name": "Tinker",
            "Anti-Mage": -4.445
        },
        {
            "name": "Tiny",
            "Anti-Mage": 1.807
        },
        {
            "name": "Treant Protector",
            "Anti-Mage": -0.165
        },
        {
            "name": "Troll Warlord",
            "Anti-Mage": 3.15
        },
        {
            "name": "Tusk",
            "Anti-Mage": 0.923
        },
        {
            "name": "Underlord",
            "Anti-Mage": -1.109
        },
        {
            "name": "Undying",
            "Anti-Mage": -1.15
        },
        {
            "name": "Ursa",
            "Anti-Mage": 1.954
        },
        {
            "name": "Vengeful Spirit",
            "Anti-Mage": -1.601
        },
        {
            "name": "Venomancer",
            "Anti-Mage": -0.801
        },
        {
            "name": "Viper",
            "Anti-Mage": -0.062
        },
        {
            "name": "Visage",
            "Anti-Mage": -0.476
        },
        {
            "name": "Void Spirit",
            "Anti-Mage": -1.635
        },
        {
            "name": "Warlock",
            "Anti-Mage": -0.244
        },
        {
            "name": "Weaver",
            "Anti-Mage": -0.718
        },
        {
            "name": "Windranger",
            "Anti-Mage": 0.17
        },
        {
            "name": "Winter Wyvern",
            "Anti-Mage": -2.765
        },
        {
            "name": "Witch Doctor",
            "Anti-Mage": 0.322
        },
        {
            "name": "Wraith King",
            "Anti-Mage": -2.811
        },
        {
            "name": "Zeus",
            "Anti-Mage": -1.218
        }
    ],
    "Arc Warden": [
        {
            "name": "Abaddon",
            "Arc Warden": 2.656
        },
        {
            "name": "Alchemist",
            "Arc Warden": -0.897
        },
        {
            "name": "Ancient Apparition",
            "Arc Warden": -2.706
        },
        {
            "name": "Anti-Mage",
            "Arc Warden": 1.504
        },
        {
            "name": "Axe",
            "Arc Warden": 1.433
        },
        {
            "name": "Bane",
            "Arc Warden": -1.119
        },
        {
            "name": "Batrider",
            "Arc Warden": 1.057
        },
        {
            "name": "Beastmaster",
            "Arc Warden": 0.641
        },
        {
            "name": "Bloodseeker",
            "Arc Warden": -1.88
        },
        {
            "name": "Bounty Hunter",
            "Arc Warden": -0.435
        },
        {
            "name": "Brewmaster",
            "Arc Warden": 0.922
        },
        {
            "name": "Bristleback",
            "Arc Warden": 0.776
        },
        {
            "name": "Broodmother",
            "Arc Warden": 4.979
        },
        {
            "name": "Centaur Warrunner",
            "Arc Warden": 0.614
        },
        {
            "name": "Chaos Knight",
            "Arc Warden": 2.138
        },
        {
            "name": "Chen",
            "Arc Warden": 0.937
        },
        {
            "name": "Clinkz",
            "Arc Warden": -2.394
        },
        {
            "name": "Clockwerk",
            "Arc Warden": 0.177
        },
        {
            "name": "Crystal Maiden",
            "Arc Warden": 0.539
        },
        {
            "name": "Dark Seer",
            "Arc Warden": 1.178
        },
        {
            "name": "Dark Willow",
            "Arc Warden": -0.73
        },
        {
            "name": "Dawnbreaker",
            "Arc Warden": 1.947
        },
        {
            "name": "Dazzle",
            "Arc Warden": -0.462
        },
        {
            "name": "Death Prophet",
            "Arc Warden": -0.987
        },
        {
            "name": "Disruptor",
            "Arc Warden": -1.566
        },
        {
            "name": "Doom",
            "Arc Warden": -1.991
        },
        {
            "name": "Dragon Knight",
            "Arc Warden": -0.003
        },
        {
            "name": "Drow Ranger",
            "Arc Warden": -1.331
        },
        {
            "name": "Earth Spirit",
            "Arc Warden": 0.525
        },
        {
            "name": "Earthshaker",
            "Arc Warden": 0.962
        },
        {
            "name": "Elder Titan",
            "Arc Warden": 2.847
        },
        {
            "name": "Ember Spirit",
            "Arc Warden": 0.273
        },
        {
            "name": "Enchantress",
            "Arc Warden": -1.614
        },
        {
            "name": "Enigma",
            "Arc Warden": 0.374
        },
        {
            "name": "Faceless Void",
            "Arc Warden": -0.065
        },
        {
            "name": "Grimstroke",
            "Arc Warden": -0.411
        },
        {
            "name": "Gyrocopter",
            "Arc Warden": 0.295
        },
        {
            "name": "Hoodwink",
            "Arc Warden": -0.731
        },
        {
            "name": "Huskar",
            "Arc Warden": -1.462
        },
        {
            "name": "Invoker",
            "Arc Warden": 0.324
        },
        {
            "name": "Io",
            "Arc Warden": 3.234
        },
        {
            "name": "Jakiro",
            "Arc Warden": 1.085
        },
        {
            "name": "Juggernaut",
            "Arc Warden": -0.372
        },
        {
            "name": "Keeper of the Light",
            "Arc Warden": -1.354
        },
        {
            "name": "Kez",
            "Arc Warden": 0.951
        },
        {
            "name": "Kunkka",
            "Arc Warden": 2.478
        },
        {
            "name": "Legion Commander",
            "Arc Warden": 1.342
        },
        {
            "name": "Leshrac",
            "Arc Warden": 0.342
        },
        {
            "name": "Lich",
            "Arc Warden": -0.184
        },
        {
            "name": "Lifestealer",
            "Arc Warden": -0.445
        },
        {
            "name": "Lina",
            "Arc Warden": -1.108
        },
        {
            "name": "Lion",
            "Arc Warden": -0.95
        },
        {
            "name": "Lone Druid",
            "Arc Warden": 1.945
        },
        {
            "name": "Luna",
            "Arc Warden": -0.957
        },
        {
            "name": "Lycan",
            "Arc Warden": 2.075
        },
        {
            "name": "Magnus",
            "Arc Warden": 1.413
        },
        {
            "name": "Marci",
            "Arc Warden": 2.423
        },
        {
            "name": "Mars",
            "Arc Warden": 0.801
        },
        {
            "name": "Medusa",
            "Arc Warden": 0.339
        },
        {
            "name": "Meepo",
            "Arc Warden": 6.705
        },
        {
            "name": "Mirana",
            "Arc Warden": -0.28
        },
        {
            "name": "Monkey King",
            "Arc Warden": -0.632
        },
        {
            "name": "Morphling",
            "Arc Warden": -0.124
        },
        {
            "name": "Muerta",
            "Arc Warden": -0.139
        },
        {
            "name": "Naga Siren",
            "Arc Warden": 2.566
        },
        {
            "name": "Nature's Prophet",
            "Arc Warden": -1.759
        },
        {
            "name": "Necrophos",
            "Arc Warden": -2.915
        },
        {
            "name": "Night Stalker",
            "Arc Warden": -2.772
        },
        {
            "name": "Nyx Assassin",
            "Arc Warden": -0.493
        },
        {
            "name": "Ogre Magi",
            "Arc Warden": -1.065
        },
        {
            "name": "Omniknight",
            "Arc Warden": -0.412
        },
        {
            "name": "Oracle",
            "Arc Warden": 1.18
        },
        {
            "name": "Outworld Destroyer",
            "Arc Warden": -3.365
        },
        {
            "name": "Pangolier",
            "Arc Warden": 2.641
        },
        {
            "name": "Phantom Assassin",
            "Arc Warden": 1.197
        },
        {
            "name": "Phantom Lancer",
            "Arc Warden": 3.766
        },
        {
            "name": "Phoenix",
            "Arc Warden": -1.695
        },
        {
            "name": "Primal Beast",
            "Arc Warden": 1.67
        },
        {
            "name": "Puck",
            "Arc Warden": -0.796
        },
        {
            "name": "Pudge",
            "Arc Warden": 0.136
        },
        {
            "name": "Pugna",
            "Arc Warden": -0.239
        },
        {
            "name": "Queen of Pain",
            "Arc Warden": 0.103
        },
        {
            "name": "Razor",
            "Arc Warden": -1.248
        },
        {
            "name": "Riki",
            "Arc Warden": 0.243
        },
        {
            "name": "Ringmaster",
            "Arc Warden": 0.386
        },
        {
            "name": "Rubick",
            "Arc Warden": -0.026
        },
        {
            "name": "Sand King",
            "Arc Warden": 3.1
        },
        {
            "name": "Shadow Demon",
            "Arc Warden": -0.64
        },
        {
            "name": "Shadow Fiend",
            "Arc Warden": 0.016
        },
        {
            "name": "Shadow Shaman",
            "Arc Warden": -0.983
        },
        {
            "name": "Silencer",
            "Arc Warden": -3.301
        },
        {
            "name": "Skywrath Mage",
            "Arc Warden": -1.027
        },
        {
            "name": "Slardar",
            "Arc Warden": -1.173
        },
        {
            "name": "Slark",
            "Arc Warden": 1.222
        },
        {
            "name": "Snapfire",
            "Arc Warden": 1.856
        },
        {
            "name": "Sniper",
            "Arc Warden": -2.029
        },
        {
            "name": "Spectre",
            "Arc Warden": 0.757
        },
        {
            "name": "Spirit Breaker",
            "Arc Warden": 1.029
        },
        {
            "name": "Storm Spirit",
            "Arc Warden": 0.016
        },
        {
            "name": "Sven",
            "Arc Warden": 1.262
        },
        {
            "name": "Techies",
            "Arc Warden": 0.643
        },
        {
            "name": "Templar Assassin",
            "Arc Warden": -2.906
        },
        {
            "name": "Terrorblade",
            "Arc Warden": 1.26
        },
        {
            "name": "Tidehunter",
            "Arc Warden": 2.075
        },
        {
            "name": "Timbersaw",
            "Arc Warden": -0.108
        },
        {
            "name": "Tinker",
            "Arc Warden": 1.709
        },
        {
            "name": "Tiny",
            "Arc Warden": 0.317
        },
        {
            "name": "Treant Protector",
            "Arc Warden": -0.68
        },
        {
            "name": "Troll Warlord",
            "Arc Warden": 0.165
        },
        {
            "name": "Tusk",
            "Arc Warden": -0.905
        },
        {
            "name": "Underlord",
            "Arc Warden": -0.395
        },
        {
            "name": "Undying",
            "Arc Warden": 0.636
        },
        {
            "name": "Ursa",
            "Arc Warden": -1.512
        },
        {
            "name": "Vengeful Spirit",
            "Arc Warden": -0.622
        },
        {
            "name": "Venomancer",
            "Arc Warden": -1.97
        },
        {
            "name": "Viper",
            "Arc Warden": -4.45
        },
        {
            "name": "Visage",
            "Arc Warden": 0.826
        },
        {
            "name": "Void Spirit",
            "Arc Warden": 2.341
        },
        {
            "name": "Warlock",
            "Arc Warden": 2.738
        },
        {
            "name": "Weaver",
            "Arc Warden": -0.924
        },
        {
            "name": "Windranger",
            "Arc Warden": -0.101
        },
        {
            "name": "Winter Wyvern",
            "Arc Warden": 1.597
        },
        {
            "name": "Witch Doctor",
            "Arc Warden": -0.74
        },
        {
            "name": "Wraith King",
            "Arc Warden": -0.287
        },
        {
            "name": "Zeus",
            "Arc Warden": 0.0
        }
    ],
    "Bloodseeker": [
        {
            "name": "Abaddon",
            "Bloodseeker": 1.654
        },
        {
            "name": "Alchemist",
            "Bloodseeker": 0.01
        },
        {
            "name": "Ancient Apparition",
            "Bloodseeker": 1.215
        },
        {
            "name": "Anti-Mage",
            "Bloodseeker": -2.903
        },
        {
            "name": "Arc Warden",
            "Bloodseeker": 2.042
        },
        {
            "name": "Axe",
            "Bloodseeker": -0.47
        },
        {
            "name": "Bane",
            "Bloodseeker": -0.646
        },
        {
            "name": "Batrider",
            "Bloodseeker": 0.436
        },
        {
            "name": "Beastmaster",
            "Bloodseeker": -0.722
        },
        {
            "name": "Bounty Hunter",
            "Bloodseeker": 0.856
        },
        {
            "name": "Brewmaster",
            "Bloodseeker": 0.197
        },
        {
            "name": "Bristleback",
            "Bloodseeker": 1.667
        },
        {
            "name": "Broodmother",
            "Bloodseeker": 0.185
        },
        {
            "name": "Centaur Warrunner",
            "Bloodseeker": -1.474
        },
        {
            "name": "Chaos Knight",
            "Bloodseeker": 0.947
        },
        {
            "name": "Chen",
            "Bloodseeker": 1.964
        },
        {
            "name": "Clinkz",
            "Bloodseeker": 0.71
        },
        {
            "name": "Clockwerk",
            "Bloodseeker": 0.608
        },
        {
            "name": "Crystal Maiden",
            "Bloodseeker": 0.614
        },
        {
            "name": "Dark Seer",
            "Bloodseeker": -1.458
        },
        {
            "name": "Dark Willow",
            "Bloodseeker": 0.348
        },
        {
            "name": "Dawnbreaker",
            "Bloodseeker": -1.466
        },
        {
            "name": "Dazzle",
            "Bloodseeker": -0.504
        },
        {
            "name": "Death Prophet",
            "Bloodseeker": -0.024
        },
        {
            "name": "Disruptor",
            "Bloodseeker": -1.659
        },
        {
            "name": "Doom",
            "Bloodseeker": 1.426
        },
        {
            "name": "Dragon Knight",
            "Bloodseeker": 0.66
        },
        {
            "name": "Drow Ranger",
            "Bloodseeker": -1.094
        },
        {
            "name": "Earth Spirit",
            "Bloodseeker": -0.449
        },
        {
            "name": "Earthshaker",
            "Bloodseeker": -0.699
        },
        {
            "name": "Elder Titan",
            "Bloodseeker": -0.005
        },
        {
            "name": "Ember Spirit",
            "Bloodseeker": 2.448
        },
        {
            "name": "Enchantress",
            "Bloodseeker": 2.158
        },
        {
            "name": "Enigma",
            "Bloodseeker": -0.266
        },
        {
            "name": "Faceless Void",
            "Bloodseeker": 0.948
        },
        {
            "name": "Grimstroke",
            "Bloodseeker": -0.604
        },
        {
            "name": "Gyrocopter",
            "Bloodseeker": -0.621
        },
        {
            "name": "Hoodwink",
            "Bloodseeker": 0.076
        },
        {
            "name": "Huskar",
            "Bloodseeker": -2.13
        },
        {
            "name": "Invoker",
            "Bloodseeker": -0.353
        },
        {
            "name": "Io",
            "Bloodseeker": 2.188
        },
        {
            "name": "Jakiro",
            "Bloodseeker": 0.339
        },
        {
            "name": "Juggernaut",
            "Bloodseeker": 3.209
        },
        {
            "name": "Keeper of the Light",
            "Bloodseeker": 1.44
        },
        {
            "name": "Kez",
            "Bloodseeker": 1.223
        },
        {
            "name": "Kunkka",
            "Bloodseeker": -0.389
        },
        {
            "name": "Legion Commander",
            "Bloodseeker": 2.201
        },
        {
            "name": "Leshrac",
            "Bloodseeker": 0.418
        },
        {
            "name": "Lich",
            "Bloodseeker": -0.479
        },
        {
            "name": "Lifestealer",
            "Bloodseeker": 1.745
        },
        {
            "name": "Lina",
            "Bloodseeker": 0.614
        },
        {
            "name": "Lion",
            "Bloodseeker": -0.114
        },
        {
            "name": "Lone Druid",
            "Bloodseeker": -2.647
        },
        {
            "name": "Luna",
            "Bloodseeker": 0.806
        },
        {
            "name": "Lycan",
            "Bloodseeker": -0.9
        },
        {
            "name": "Magnus",
            "Bloodseeker": -0.606
        },
        {
            "name": "Marci",
            "Bloodseeker": 1.794
        },
        {
            "name": "Mars",
            "Bloodseeker": -1.11
        },
        {
            "name": "Medusa",
            "Bloodseeker": 6.501
        },
        {
            "name": "Meepo",
            "Bloodseeker": -1.847
        },
        {
            "name": "Mirana",
            "Bloodseeker": -0.795
        },
        {
            "name": "Monkey King",
            "Bloodseeker": 0.392
        },
        {
            "name": "Morphling",
            "Bloodseeker": 0.744
        },
        {
            "name": "Muerta",
            "Bloodseeker": 0.328
        },
        {
            "name": "Naga Siren",
            "Bloodseeker": 2.786
        },
        {
            "name": "Nature's Prophet",
            "Bloodseeker": 2.743
        },
        {
            "name": "Necrophos",
            "Bloodseeker": 0.489
        },
        {
            "name": "Night Stalker",
            "Bloodseeker": -2.27
        },
        {
            "name": "Nyx Assassin",
            "Bloodseeker": -0.285
        },
        {
            "name": "Ogre Magi",
            "Bloodseeker": 0.514
        },
        {
            "name": "Omniknight",
            "Bloodseeker": -0.62
        },
        {
            "name": "Oracle",
            "Bloodseeker": 1.966
        },
        {
            "name": "Outworld Destroyer",
            "Bloodseeker": -0.021
        },
        {
            "name": "Pangolier",
            "Bloodseeker": -3.72
        },
        {
            "name": "Phantom Assassin",
            "Bloodseeker": -0.342
        },
        {
            "name": "Phantom Lancer",
            "Bloodseeker": -0.059
        },
        {
            "name": "Phoenix",
            "Bloodseeker": -2.047
        },
        {
            "name": "Primal Beast",
            "Bloodseeker": -0.326
        },
        {
            "name": "Puck",
            "Bloodseeker": 0.952
        },
        {
            "name": "Pudge",
            "Bloodseeker": 1.078
        },
        {
            "name": "Pugna",
            "Bloodseeker": 0.022
        },
        {
            "name": "Queen of Pain",
            "Bloodseeker": 0.695
        },
        {
            "name": "Razor",
            "Bloodseeker": -1.654
        },
        {
            "name": "Riki",
            "Bloodseeker": -2.669
        },
        {
            "name": "Ringmaster",
            "Bloodseeker": -1.129
        },
        {
            "name": "Rubick",
            "Bloodseeker": -0.551
        },
        {
            "name": "Sand King",
            "Bloodseeker": -0.781
        },
        {
            "name": "Shadow Demon",
            "Bloodseeker": 0.19
        },
        {
            "name": "Shadow Fiend",
            "Bloodseeker": -0.001
        },
        {
            "name": "Shadow Shaman",
            "Bloodseeker": -0.372
        },
        {
            "name": "Silencer",
            "Bloodseeker": 0.77
        },
        {
            "name": "Skywrath Mage",
            "Bloodseeker": 1.653
        },
        {
            "name": "Slardar",
            "Bloodseeker": -0.005
        },
        {
            "name": "Slark",
            "Bloodseeker": -3.167
        },
        {
            "name": "Snapfire",
            "Bloodseeker": -1.292
        },
        {
            "name": "Sniper",
            "Bloodseeker": 0.739
        },
        {
            "name": "Spectre",
            "Bloodseeker": 0.398
        },
        {
            "name": "Spirit Breaker",
            "Bloodseeker": 0.793
        },
        {
            "name": "Storm Spirit",
            "Bloodseeker": 3.448
        },
        {
            "name": "Sven",
            "Bloodseeker": 1.027
        },
        {
            "name": "Techies",
            "Bloodseeker": 0.714
        },
        {
            "name": "Templar Assassin",
            "Bloodseeker": 0.616
        },
        {
            "name": "Terrorblade",
            "Bloodseeker": -1.087
        },
        {
            "name": "Tidehunter",
            "Bloodseeker": -2.228
        },
        {
            "name": "Timbersaw",
            "Bloodseeker": -1.748
        },
        {
            "name": "Tinker",
            "Bloodseeker": 1.909
        },
        {
            "name": "Tiny",
            "Bloodseeker": -1.68
        },
        {
            "name": "Treant Protector",
            "Bloodseeker": 0.37
        },
        {
            "name": "Troll Warlord",
            "Bloodseeker": 2.321
        },
        {
            "name": "Tusk",
            "Bloodseeker": -0.633
        },
        {
            "name": "Underlord",
            "Bloodseeker": 0.191
        },
        {
            "name": "Undying",
            "Bloodseeker": -1.261
        },
        {
            "name": "Ursa",
            "Bloodseeker": 0.314
        },
        {
            "name": "Vengeful Spirit",
            "Bloodseeker": -0.489
        },
        {
            "name": "Venomancer",
            "Bloodseeker": 0.992
        },
        {
            "name": "Viper",
            "Bloodseeker": 2.232
        },
        {
            "name": "Visage",
            "Bloodseeker": 1.014
        },
        {
            "name": "Void Spirit",
            "Bloodseeker": -1.138
        },
        {
            "name": "Warlock",
            "Bloodseeker": 0.846
        },
        {
            "name": "Weaver",
            "Bloodseeker": -0.242
        },
        {
            "name": "Windranger",
            "Bloodseeker": -0.385
        },
        {
            "name": "Winter Wyvern",
            "Bloodseeker": 1.149
        },
        {
            "name": "Witch Doctor",
            "Bloodseeker": 1.821
        },
        {
            "name": "Wraith King",
            "Bloodseeker": 0.212
        },
        {
            "name": "Zeus",
            "Bloodseeker": 0.644
        }
    ],
    "Bounty Hunter": [
        {
            "name": "Abaddon",
            "Bounty Hunter": 1.742
        },
        {
            "name": "Alchemist",
            "Bounty Hunter": -1.773
        },
        {
            "name": "Ancient Apparition",
            "Bounty Hunter": -0.637
        },
        {
            "name": "Anti-Mage",
            "Bounty Hunter": 0.792
        },
        {
            "name": "Arc Warden",
            "Bounty Hunter": 0.468
        },
        {
            "name": "Axe",
            "Bounty Hunter": -0.305
        },
        {
            "name": "Bane",
            "Bounty Hunter": 0.248
        },
        {
            "name": "Batrider",
            "Bounty Hunter": 0.707
        },
        {
            "name": "Beastmaster",
            "Bounty Hunter": 0.04
        },
        {
            "name": "Bloodseeker",
            "Bounty Hunter": -0.902
        },
        {
            "name": "Brewmaster",
            "Bounty Hunter": -1.112
        },
        {
            "name": "Bristleback",
            "Bounty Hunter": -1.767
        },
        {
            "name": "Broodmother",
            "Bounty Hunter": -1.071
        },
        {
            "name": "Centaur Warrunner",
            "Bounty Hunter": -0.861
        },
        {
            "name": "Chaos Knight",
            "Bounty Hunter": 1.39
        },
        {
            "name": "Chen",
            "Bounty Hunter": 0.562
        },
        {
            "name": "Clinkz",
            "Bounty Hunter": -2.298
        },
        {
            "name": "Clockwerk",
            "Bounty Hunter": 0.117
        },
        {
            "name": "Crystal Maiden",
            "Bounty Hunter": 1.253
        },
        {
            "name": "Dark Seer",
            "Bounty Hunter": -0.703
        },
        {
            "name": "Dark Willow",
            "Bounty Hunter": 0.679
        },
        {
            "name": "Dawnbreaker",
            "Bounty Hunter": -1.494
        },
        {
            "name": "Dazzle",
            "Bounty Hunter": -1.089
        },
        {
            "name": "Death Prophet",
            "Bounty Hunter": -0.643
        },
        {
            "name": "Disruptor",
            "Bounty Hunter": -0.978
        },
        {
            "name": "Doom",
            "Bounty Hunter": 0.223
        },
        {
            "name": "Dragon Knight",
            "Bounty Hunter": -0.316
        },
        {
            "name": "Drow Ranger",
            "Bounty Hunter": 0.051
        },
        {
            "name": "Earth Spirit",
            "Bounty Hunter": -0.284
        },
        {
            "name": "Earthshaker",
            "Bounty Hunter": 0.343
        },
        {
            "name": "Elder Titan",
            "Bounty Hunter": -0.323
        },
        {
            "name": "Ember Spirit",
            "Bounty Hunter": -0.442
        },
        {
            "name": "Enchantress",
            "Bounty Hunter": 0.088
        },
        {
            "name": "Enigma",
            "Bounty Hunter": 0.974
        },
        {
            "name": "Faceless Void",
            "Bounty Hunter": 0.465
        },
        {
            "name": "Grimstroke",
            "Bounty Hunter": -0.399
        },
        {
            "name": "Gyrocopter",
            "Bounty Hunter": -1.426
        },
        {
            "name": "Hoodwink",
            "Bounty Hunter": 0.465
        },
        {
            "name": "Huskar",
            "Bounty Hunter": 1.604
        },
        {
            "name": "Invoker",
            "Bounty Hunter": -1.385
        },
        {
            "name": "Io",
            "Bounty Hunter": 0.279
        },
        {
            "name": "Jakiro",
            "Bounty Hunter": 0.049
        },
        {
            "name": "Juggernaut",
            "Bounty Hunter": 1.095
        },
        {
            "name": "Keeper of the Light",
            "Bounty Hunter": 0.435
        },
        {
            "name": "Kez",
            "Bounty Hunter": 0.928
        },
        {
            "name": "Kunkka",
            "Bounty Hunter": -0.577
        },
        {
            "name": "Legion Commander",
            "Bounty Hunter": 1.678
        },
        {
            "name": "Leshrac",
            "Bounty Hunter": 0.265
        },
        {
            "name": "Lich",
            "Bounty Hunter": -0.313
        },
        {
            "name": "Lifestealer",
            "Bounty Hunter": -0.675
        },
        {
            "name": "Lina",
            "Bounty Hunter": -0.65
        },
        {
            "name": "Lion",
            "Bounty Hunter": 1.067
        },
        {
            "name": "Lone Druid",
            "Bounty Hunter": -0.086
        },
        {
            "name": "Luna",
            "Bounty Hunter": -0.466
        },
        {
            "name": "Lycan",
            "Bounty Hunter": -1.877
        },
        {
            "name": "Magnus",
            "Bounty Hunter": 1.185
        },
        {
            "name": "Marci",
            "Bounty Hunter": 1.131
        },
        {
            "name": "Mars",
            "Bounty Hunter": -0.952
        },
        {
            "name": "Medusa",
            "Bounty Hunter": -0.579
        },
        {
            "name": "Meepo",
            "Bounty Hunter": 2.653
        },
        {
            "name": "Mirana",
            "Bounty Hunter": -1.122
        },
        {
            "name": "Monkey King",
            "Bounty Hunter": -0.383
        },
        {
            "name": "Morphling",
            "Bounty Hunter": -0.984
        },
        {
            "name": "Muerta",
            "Bounty Hunter": -0.364
        },
        {
            "name": "Naga Siren",
            "Bounty Hunter": 3.867
        },
        {
            "name": "Nature's Prophet",
            "Bounty Hunter": -0.34
        },
        {
            "name": "Necrophos",
            "Bounty Hunter": 0.568
        },
        {
            "name": "Night Stalker",
            "Bounty Hunter": -2.496
        },
        {
            "name": "Nyx Assassin",
            "Bounty Hunter": 0.365
        },
        {
            "name": "Ogre Magi",
            "Bounty Hunter": 0.438
        },
        {
            "name": "Omniknight",
            "Bounty Hunter": -0.762
        },
        {
            "name": "Oracle",
            "Bounty Hunter": 0.983
        },
        {
            "name": "Outworld Destroyer",
            "Bounty Hunter": 0.216
        },
        {
            "name": "Pangolier",
            "Bounty Hunter": -0.555
        },
        {
            "name": "Phantom Assassin",
            "Bounty Hunter": -1.414
        },
        {
            "name": "Phantom Lancer",
            "Bounty Hunter": 2.28
        },
        {
            "name": "Phoenix",
            "Bounty Hunter": 0.152
        },
        {
            "name": "Primal Beast",
            "Bounty Hunter": 0.775
        },
        {
            "name": "Puck",
            "Bounty Hunter": -0.314
        },
        {
            "name": "Pudge",
            "Bounty Hunter": 0.027
        },
        {
            "name": "Pugna",
            "Bounty Hunter": -1.976
        },
        {
            "name": "Queen of Pain",
            "Bounty Hunter": 0.111
        },
        {
            "name": "Razor",
            "Bounty Hunter": 0.251
        },
        {
            "name": "Riki",
            "Bounty Hunter": -1.004
        },
        {
            "name": "Ringmaster",
            "Bounty Hunter": 0.219
        },
        {
            "name": "Rubick",
            "Bounty Hunter": -0.515
        },
        {
            "name": "Sand King",
            "Bounty Hunter": -1.016
        },
        {
            "name": "Shadow Demon",
            "Bounty Hunter": -0.844
        },
        {
            "name": "Shadow Fiend",
            "Bounty Hunter": -0.463
        },
        {
            "name": "Shadow Shaman",
            "Bounty Hunter": 2.29
        },
        {
            "name": "Silencer",
            "Bounty Hunter": -0.187
        },
        {
            "name": "Skywrath Mage",
            "Bounty Hunter": 0.132
        },
        {
            "name": "Slardar",
            "Bounty Hunter": 0.453
        },
        {
            "name": "Slark",
            "Bounty Hunter": 2.51
        },
        {
            "name": "Snapfire",
            "Bounty Hunter": -0.259
        },
        {
            "name": "Sniper",
            "Bounty Hunter": -0.162
        },
        {
            "name": "Spectre",
            "Bounty Hunter": -0.824
        },
        {
            "name": "Spirit Breaker",
            "Bounty Hunter": 0.65
        },
        {
            "name": "Storm Spirit",
            "Bounty Hunter": 1.119
        },
        {
            "name": "Sven",
            "Bounty Hunter": -0.807
        },
        {
            "name": "Techies",
            "Bounty Hunter": 0.808
        },
        {
            "name": "Templar Assassin",
            "Bounty Hunter": -0.343
        },
        {
            "name": "Terrorblade",
            "Bounty Hunter": -0.622
        },
        {
            "name": "Tidehunter",
            "Bounty Hunter": 1.323
        },
        {
            "name": "Timbersaw",
            "Bounty Hunter": -0.081
        },
        {
            "name": "Tinker",
            "Bounty Hunter": 1.636
        },
        {
            "name": "Tiny",
            "Bounty Hunter": -0.383
        },
        {
            "name": "Treant Protector",
            "Bounty Hunter": 0.585
        },
        {
            "name": "Troll Warlord",
            "Bounty Hunter": 1.075
        },
        {
            "name": "Tusk",
            "Bounty Hunter": 0.059
        },
        {
            "name": "Underlord",
            "Bounty Hunter": -1.038
        },
        {
            "name": "Undying",
            "Bounty Hunter": -1.445
        },
        {
            "name": "Ursa",
            "Bounty Hunter": -0.227
        },
        {
            "name": "Vengeful Spirit",
            "Bounty Hunter": -0.149
        },
        {
            "name": "Venomancer",
            "Bounty Hunter": 0.661
        },
        {
            "name": "Viper",
            "Bounty Hunter": 0.506
        },
        {
            "name": "Visage",
            "Bounty Hunter": -0.313
        },
        {
            "name": "Void Spirit",
            "Bounty Hunter": -0.926
        },
        {
            "name": "Warlock",
            "Bounty Hunter": -1.455
        },
        {
            "name": "Weaver",
            "Bounty Hunter": -1.307
        },
        {
            "name": "Windranger",
            "Bounty Hunter": 0.04
        },
        {
            "name": "Winter Wyvern",
            "Bounty Hunter": -0.755
        },
        {
            "name": "Witch Doctor",
            "Bounty Hunter": -0.728
        },
        {
            "name": "Wraith King",
            "Bounty Hunter": -1.311
        },
        {
            "name": "Zeus",
            "Bounty Hunter": -0.195
        }
    ],
    "Clinkz": [
        {
            "name": "Abaddon",
            "Clinkz": 1.795
        },
        {
            "name": "Alchemist",
            "Clinkz": -0.976
        },
        {
            "name": "Ancient Apparition",
            "Clinkz": -1.24
        },
        {
            "name": "Anti-Mage",
            "Clinkz": 0.534
        },
        {
            "name": "Arc Warden",
            "Clinkz": 2.415
        },
        {
            "name": "Axe",
            "Clinkz": 1.981
        },
        {
            "name": "Bane",
            "Clinkz": -0.579
        },
        {
            "name": "Batrider",
            "Clinkz": 0.01
        },
        {
            "name": "Beastmaster",
            "Clinkz": -1.822
        },
        {
            "name": "Bloodseeker",
            "Clinkz": -0.696
        },
        {
            "name": "Bounty Hunter",
            "Clinkz": 2.288
        },
        {
            "name": "Brewmaster",
            "Clinkz": 1.03
        },
        {
            "name": "Bristleback",
            "Clinkz": 3.101
        },
        {
            "name": "Broodmother",
            "Clinkz": 2.489
        },
        {
            "name": "Centaur Warrunner",
            "Clinkz": 0.823
        },
        {
            "name": "Chaos Knight",
            "Clinkz": 3.405
        },
        {
            "name": "Chen",
            "Clinkz": 1.361
        },
        {
            "name": "Clockwerk",
            "Clinkz": 0.386
        },
        {
            "name": "Crystal Maiden",
            "Clinkz": 0.509
        },
        {
            "name": "Dark Seer",
            "Clinkz": -0.678
        },
        {
            "name": "Dark Willow",
            "Clinkz": 0.268
        },
        {
            "name": "Dawnbreaker",
            "Clinkz": -0.586
        },
        {
            "name": "Dazzle",
            "Clinkz": -0.434
        },
        {
            "name": "Death Prophet",
            "Clinkz": -2.381
        },
        {
            "name": "Disruptor",
            "Clinkz": -1.487
        },
        {
            "name": "Doom",
            "Clinkz": -0.18
        },
        {
            "name": "Dragon Knight",
            "Clinkz": -0.032
        },
        {
            "name": "Drow Ranger",
            "Clinkz": 1.343
        },
        {
            "name": "Earth Spirit",
            "Clinkz": 0.339
        },
        {
            "name": "Earthshaker",
            "Clinkz": -2.042
        },
        {
            "name": "Elder Titan",
            "Clinkz": -3.577
        },
        {
            "name": "Ember Spirit",
            "Clinkz": -0.645
        },
        {
            "name": "Enchantress",
            "Clinkz": 2.25
        },
        {
            "name": "Enigma",
            "Clinkz": -2.175
        },
        {
            "name": "Faceless Void",
            "Clinkz": 1.421
        },
        {
            "name": "Grimstroke",
            "Clinkz": -0.741
        },
        {
            "name": "Gyrocopter",
            "Clinkz": 0.046
        },
        {
            "name": "Hoodwink",
            "Clinkz": 0.396
        },
        {
            "name": "Huskar",
            "Clinkz": -2.202
        },
        {
            "name": "Invoker",
            "Clinkz": -0.798
        },
        {
            "name": "Io",
            "Clinkz": 0.319
        },
        {
            "name": "Jakiro",
            "Clinkz": -0.823
        },
        {
            "name": "Juggernaut",
            "Clinkz": -2.608
        },
        {
            "name": "Keeper of the Light",
            "Clinkz": -0.66
        },
        {
            "name": "Kez",
            "Clinkz": -0.5
        },
        {
            "name": "Kunkka",
            "Clinkz": 0.428
        },
        {
            "name": "Legion Commander",
            "Clinkz": 1.51
        },
        {
            "name": "Leshrac",
            "Clinkz": -1.732
        },
        {
            "name": "Lich",
            "Clinkz": -0.898
        },
        {
            "name": "Lifestealer",
            "Clinkz": -2.981
        },
        {
            "name": "Lina",
            "Clinkz": -1.446
        },
        {
            "name": "Lion",
            "Clinkz": -0.032
        },
        {
            "name": "Lone Druid",
            "Clinkz": 1.306
        },
        {
            "name": "Luna",
            "Clinkz": 1.387
        },
        {
            "name": "Lycan",
            "Clinkz": 0.43
        },
        {
            "name": "Magnus",
            "Clinkz": -0.325
        },
        {
            "name": "Marci",
            "Clinkz": 0.834
        },
        {
            "name": "Mars",
            "Clinkz": 1.78
        },
        {
            "name": "Medusa",
            "Clinkz": 1.007
        },
        {
            "name": "Meepo",
            "Clinkz": 2.512
        },
        {
            "name": "Mirana",
            "Clinkz": 0.344
        },
        {
            "name": "Monkey King",
            "Clinkz": -1.465
        },
        {
            "name": "Morphling",
            "Clinkz": 2.768
        },
        {
            "name": "Muerta",
            "Clinkz": -1.361
        },
        {
            "name": "Naga Siren",
            "Clinkz": 4.123
        },
        {
            "name": "Nature's Prophet",
            "Clinkz": -0.593
        },
        {
            "name": "Necrophos",
            "Clinkz": -1.541
        },
        {
            "name": "Night Stalker",
            "Clinkz": -0.973
        },
        {
            "name": "Nyx Assassin",
            "Clinkz": 0.59
        },
        {
            "name": "Ogre Magi",
            "Clinkz": 0.786
        },
        {
            "name": "Omniknight",
            "Clinkz": 1.192
        },
        {
            "name": "Oracle",
            "Clinkz": 0.099
        },
        {
            "name": "Outworld Destroyer",
            "Clinkz": -1.488
        },
        {
            "name": "Pangolier",
            "Clinkz": -0.642
        },
        {
            "name": "Phantom Assassin",
            "Clinkz": 1.843
        },
        {
            "name": "Phantom Lancer",
            "Clinkz": 5.873
        },
        {
            "name": "Phoenix",
            "Clinkz": -1.588
        },
        {
            "name": "Primal Beast",
            "Clinkz": -0.51
        },
        {
            "name": "Puck",
            "Clinkz": -0.951
        },
        {
            "name": "Pudge",
            "Clinkz": 1.61
        },
        {
            "name": "Pugna",
            "Clinkz": 0.235
        },
        {
            "name": "Queen of Pain",
            "Clinkz": -0.848
        },
        {
            "name": "Razor",
            "Clinkz": 1.19
        },
        {
            "name": "Riki",
            "Clinkz": 2.068
        },
        {
            "name": "Ringmaster",
            "Clinkz": -0.442
        },
        {
            "name": "Rubick",
            "Clinkz": -1.431
        },
        {
            "name": "Sand King",
            "Clinkz": 0.505
        },
        {
            "name": "Shadow Demon",
            "Clinkz": -0.358
        },
        {
            "name": "Shadow Fiend",
            "Clinkz": -0.526
        },
        {
            "name": "Shadow Shaman",
            "Clinkz": -0.122
        },
        {
            "name": "Silencer",
            "Clinkz": 0.8
        },
        {
            "name": "Skywrath Mage",
            "Clinkz": -0.487
        },
        {
            "name": "Slardar",
            "Clinkz": 1.681
        },
        {
            "name": "Slark",
            "Clinkz": 0.363
        },
        {
            "name": "Snapfire",
            "Clinkz": -0.541
        },
        {
            "name": "Sniper",
            "Clinkz": 0.426
        },
        {
            "name": "Spectre",
            "Clinkz": 3.661
        },
        {
            "name": "Spirit Breaker",
            "Clinkz": 0.992
        },
        {
            "name": "Storm Spirit",
            "Clinkz": -0.02
        },
        {
            "name": "Sven",
            "Clinkz": -1.84
        },
        {
            "name": "Techies",
            "Clinkz": 1.351
        },
        {
            "name": "Templar Assassin",
            "Clinkz": 0.278
        },
        {
            "name": "Terrorblade",
            "Clinkz": 2.902
        },
        {
            "name": "Tidehunter",
            "Clinkz": -1.125
        },
        {
            "name": "Timbersaw",
            "Clinkz": -0.663
        },
        {
            "name": "Tinker",
            "Clinkz": -1.034
        },
        {
            "name": "Tiny",
            "Clinkz": -0.443
        },
        {
            "name": "Treant Protector",
            "Clinkz": 1.446
        },
        {
            "name": "Troll Warlord",
            "Clinkz": -0.545
        },
        {
            "name": "Tusk",
            "Clinkz": 0.688
        },
        {
            "name": "Underlord",
            "Clinkz": -1.409
        },
        {
            "name": "Undying",
            "Clinkz": -2.065
        },
        {
            "name": "Ursa",
            "Clinkz": -2.164
        },
        {
            "name": "Vengeful Spirit",
            "Clinkz": 1.033
        },
        {
            "name": "Venomancer",
            "Clinkz": -0.946
        },
        {
            "name": "Viper",
            "Clinkz": -1.514
        },
        {
            "name": "Visage",
            "Clinkz": 0.308
        },
        {
            "name": "Void Spirit",
            "Clinkz": 0.736
        },
        {
            "name": "Warlock",
            "Clinkz": -0.804
        },
        {
            "name": "Weaver",
            "Clinkz": -1.302
        },
        {
            "name": "Windranger",
            "Clinkz": 1.108
        },
        {
            "name": "Winter Wyvern",
            "Clinkz": 0.387
        },
        {
            "name": "Witch Doctor",
            "Clinkz": -0.319
        },
        {
            "name": "Wraith King",
            "Clinkz": 2.243
        },
        {
            "name": "Zeus",
            "Clinkz": -0.533
        }
    ],
    "Drow Ranger": [
        {
            "name": "Abaddon",
            "Drow Ranger": -1.149
        },
        {
            "name": "Alchemist",
            "Drow Ranger": -0.424
        },
        {
            "name": "Ancient Apparition",
            "Drow Ranger": -0.86
        },
        {
            "name": "Anti-Mage",
            "Drow Ranger": -1.04
        },
        {
            "name": "Arc Warden",
            "Drow Ranger": 1.465
        },
        {
            "name": "Axe",
            "Drow Ranger": 0.834
        },
        {
            "name": "Bane",
            "Drow Ranger": -0.045
        },
        {
            "name": "Batrider",
            "Drow Ranger": 1.786
        },
        {
            "name": "Beastmaster",
            "Drow Ranger": -0.337
        },
        {
            "name": "Bloodseeker",
            "Drow Ranger": 0.846
        },
        {
            "name": "Bounty Hunter",
            "Drow Ranger": -0.061
        },
        {
            "name": "Brewmaster",
            "Drow Ranger": 0.897
        },
        {
            "name": "Bristleback",
            "Drow Ranger": -1.476
        },
        {
            "name": "Broodmother",
            "Drow Ranger": 0.081
        },
        {
            "name": "Centaur Warrunner",
            "Drow Ranger": 2.504
        },
        {
            "name": "Chaos Knight",
            "Drow Ranger": 1.68
        },
        {
            "name": "Chen",
            "Drow Ranger": 2.034
        },
        {
            "name": "Clinkz",
            "Drow Ranger": -1.326
        },
        {
            "name": "Clockwerk",
            "Drow Ranger": 1.705
        },
        {
            "name": "Crystal Maiden",
            "Drow Ranger": -1.332
        },
        {
            "name": "Dark Seer",
            "Drow Ranger": 1.841
        },
        {
            "name": "Dark Willow",
            "Drow Ranger": 0.49
        },
        {
            "name": "Dawnbreaker",
            "Drow Ranger": -1.091
        },
        {
            "name": "Dazzle",
            "Drow Ranger": -0.637
        },
        {
            "name": "Death Prophet",
            "Drow Ranger": -1.933
        },
        {
            "name": "Disruptor",
            "Drow Ranger": -1.298
        },
        {
            "name": "Doom",
            "Drow Ranger": -1.154
        },
        {
            "name": "Dragon Knight",
            "Drow Ranger": 1.137
        },
        {
            "name": "Earth Spirit",
            "Drow Ranger": 3.167
        },
        {
            "name": "Earthshaker",
            "Drow Ranger": -0.837
        },
        {
            "name": "Elder Titan",
            "Drow Ranger": 0.456
        },
        {
            "name": "Ember Spirit",
            "Drow Ranger": -0.375
        },
        {
            "name": "Enchantress",
            "Drow Ranger": 0.681
        },
        {
            "name": "Enigma",
            "Drow Ranger": -0.557
        },
        {
            "name": "Faceless Void",
            "Drow Ranger": 0.648
        },
        {
            "name": "Grimstroke",
            "Drow Ranger": -0.597
        },
        {
            "name": "Gyrocopter",
            "Drow Ranger": 0.071
        },
        {
            "name": "Hoodwink",
            "Drow Ranger": 0.38
        },
        {
            "name": "Huskar",
            "Drow Ranger": -1.425
        },
        {
            "name": "Invoker",
            "Drow Ranger": 0.857
        },
        {
            "name": "Io",
            "Drow Ranger": -0.593
        },
        {
            "name": "Jakiro",
            "Drow Ranger": -0.608
        },
        {
            "name": "Juggernaut",
            "Drow Ranger": -0.778
        },
        {
            "name": "Keeper of the Light",
            "Drow Ranger": 1.295
        },
        {
            "name": "Kez",
            "Drow Ranger": -1.896
        },
        {
            "name": "Kunkka",
            "Drow Ranger": 1.056
        },
        {
            "name": "Legion Commander",
            "Drow Ranger": -0.539
        },
        {
            "name": "Leshrac",
            "Drow Ranger": -1.94
        },
        {
            "name": "Lich",
            "Drow Ranger": -1.001
        },
        {
            "name": "Lifestealer",
            "Drow Ranger": -1.723
        },
        {
            "name": "Lina",
            "Drow Ranger": -0.701
        },
        {
            "name": "Lion",
            "Drow Ranger": -0.877
        },
        {
            "name": "Lone Druid",
            "Drow Ranger": 0.579
        },
        {
            "name": "Luna",
            "Drow Ranger": 0.758
        },
        {
            "name": "Lycan",
            "Drow Ranger": 2.105
        },
        {
            "name": "Magnus",
            "Drow Ranger": 0.777
        },
        {
            "name": "Marci",
            "Drow Ranger": 0.995
        },
        {
            "name": "Mars",
            "Drow Ranger": 3.079
        },
        {
            "name": "Medusa",
            "Drow Ranger": -0.388
        },
        {
            "name": "Meepo",
            "Drow Ranger": -1.808
        },
        {
            "name": "Mirana",
            "Drow Ranger": -0.097
        },
        {
            "name": "Monkey King",
            "Drow Ranger": 0.4
        },
        {
            "name": "Morphling",
            "Drow Ranger": -0.207
        },
        {
            "name": "Muerta",
            "Drow Ranger": 0.827
        },
        {
            "name": "Naga Siren",
            "Drow Ranger": 1.979
        },
        {
            "name": "Nature's Prophet",
            "Drow Ranger": 0.611
        },
        {
            "name": "Necrophos",
            "Drow Ranger": -2.426
        },
        {
            "name": "Night Stalker",
            "Drow Ranger": 1.287
        },
        {
            "name": "Nyx Assassin",
            "Drow Ranger": 0.273
        },
        {
            "name": "Ogre Magi",
            "Drow Ranger": 1.504
        },
        {
            "name": "Omniknight",
            "Drow Ranger": -0.825
        },
        {
            "name": "Oracle",
            "Drow Ranger": -1.442
        },
        {
            "name": "Outworld Destroyer",
            "Drow Ranger": 1.01
        },
        {
            "name": "Pangolier",
            "Drow Ranger": -2.002
        },
        {
            "name": "Phantom Assassin",
            "Drow Ranger": -0.461
        },
        {
            "name": "Phantom Lancer",
            "Drow Ranger": 3.622
        },
        {
            "name": "Phoenix",
            "Drow Ranger": -1.253
        },
        {
            "name": "Primal Beast",
            "Drow Ranger": 1.789
        },
        {
            "name": "Puck",
            "Drow Ranger": 0.165
        },
        {
            "name": "Pudge",
            "Drow Ranger": 2.03
        },
        {
            "name": "Pugna",
            "Drow Ranger": -1.11
        },
        {
            "name": "Queen of Pain",
            "Drow Ranger": 0.186
        },
        {
            "name": "Razor",
            "Drow Ranger": -0.848
        },
        {
            "name": "Riki",
            "Drow Ranger": -1.882
        },
        {
            "name": "Ringmaster",
            "Drow Ranger": 0.523
        },
        {
            "name": "Rubick",
            "Drow Ranger": -1.043
        },
        {
            "name": "Sand King",
            "Drow Ranger": 1.696
        },
        {
            "name": "Shadow Demon",
            "Drow Ranger": 1.022
        },
        {
            "name": "Shadow Fiend",
            "Drow Ranger": -0.715
        },
        {
            "name": "Shadow Shaman",
            "Drow Ranger": -1.192
        },
        {
            "name": "Silencer",
            "Drow Ranger": 0.33
        },
        {
            "name": "Skywrath Mage",
            "Drow Ranger": 0.331
        },
        {
            "name": "Slardar",
            "Drow Ranger": -3.049
        },
        {
            "name": "Slark",
            "Drow Ranger": -1.475
        },
        {
            "name": "Snapfire",
            "Drow Ranger": 1.508
        },
        {
            "name": "Sniper",
            "Drow Ranger": 1.653
        },
        {
            "name": "Spectre",
            "Drow Ranger": 2.076
        },
        {
            "name": "Spirit Breaker",
            "Drow Ranger": 0.848
        },
        {
            "name": "Storm Spirit",
            "Drow Ranger": 1.394
        },
        {
            "name": "Sven",
            "Drow Ranger": 0.696
        },
        {
            "name": "Techies",
            "Drow Ranger": 0.478
        },
        {
            "name": "Templar Assassin",
            "Drow Ranger": 0.124
        },
        {
            "name": "Terrorblade",
            "Drow Ranger": -0.913
        },
        {
            "name": "Tidehunter",
            "Drow Ranger": -0.041
        },
        {
            "name": "Timbersaw",
            "Drow Ranger": 0.625
        },
        {
            "name": "Tinker",
            "Drow Ranger": -0.663
        },
        {
            "name": "Tiny",
            "Drow Ranger": 2.066
        },
        {
            "name": "Treant Protector",
            "Drow Ranger": 0.84
        },
        {
            "name": "Troll Warlord",
            "Drow Ranger": -0.917
        },
        {
            "name": "Tusk",
            "Drow Ranger": -0.146
        },
        {
            "name": "Underlord",
            "Drow Ranger": 0.321
        },
        {
            "name": "Undying",
            "Drow Ranger": 0.094
        },
        {
            "name": "Ursa",
            "Drow Ranger": -0.637
        },
        {
            "name": "Vengeful Spirit",
            "Drow Ranger": -0.279
        },
        {
            "name": "Venomancer",
            "Drow Ranger": 0.405
        },
        {
            "name": "Viper",
            "Drow Ranger": -1.43
        },
        {
            "name": "Visage",
            "Drow Ranger": -0.515
        },
        {
            "name": "Void Spirit",
            "Drow Ranger": 1.132
        },
        {
            "name": "Warlock",
            "Drow Ranger": -0.182
        },
        {
            "name": "Weaver",
            "Drow Ranger": -2.025
        },
        {
            "name": "Windranger",
            "Drow Ranger": 0.097
        },
        {
            "name": "Winter Wyvern",
            "Drow Ranger": 0.577
        },
        {
            "name": "Witch Doctor",
            "Drow Ranger": -1.263
        },
        {
            "name": "Wraith King",
            "Drow Ranger": 1.765
        },
        {
            "name": "Zeus",
            "Drow Ranger": 1.851
        }
    ],
    "Ember Spirit": [
        {
            "name": "Abaddon",
            "Ember Spirit": -0.233
        },
        {
            "name": "Alchemist",
            "Ember Spirit": 0.844
        },
        {
            "name": "Ancient Apparition",
            "Ember Spirit": -0.931
        },
        {
            "name": "Anti-Mage",
            "Ember Spirit": 0.757
        },
        {
            "name": "Arc Warden",
            "Ember Spirit": -0.096
        },
        {
            "name": "Axe",
            "Ember Spirit": -1.463
        },
        {
            "name": "Bane",
            "Ember Spirit": 2.49
        },
        {
            "name": "Batrider",
            "Ember Spirit": -0.4
        },
        {
            "name": "Beastmaster",
            "Ember Spirit": -0.502
        },
        {
            "name": "Bloodseeker",
            "Ember Spirit": -2.454
        },
        {
            "name": "Bounty Hunter",
            "Ember Spirit": 0.47
        },
        {
            "name": "Brewmaster",
            "Ember Spirit": 0.806
        },
        {
            "name": "Bristleback",
            "Ember Spirit": -2.139
        },
        {
            "name": "Broodmother",
            "Ember Spirit": 3.784
        },
        {
            "name": "Centaur Warrunner",
            "Ember Spirit": -2.659
        },
        {
            "name": "Chaos Knight",
            "Ember Spirit": -0.904
        },
        {
            "name": "Chen",
            "Ember Spirit": 0.348
        },
        {
            "name": "Clinkz",
            "Ember Spirit": 0.58
        },
        {
            "name": "Clockwerk",
            "Ember Spirit": -2.057
        },
        {
            "name": "Crystal Maiden",
            "Ember Spirit": 0.974
        },
        {
            "name": "Dark Seer",
            "Ember Spirit": -2.114
        },
        {
            "name": "Dark Willow",
            "Ember Spirit": 2.135
        },
        {
            "name": "Dawnbreaker",
            "Ember Spirit": -0.653
        },
        {
            "name": "Dazzle",
            "Ember Spirit": 1.442
        },
        {
            "name": "Death Prophet",
            "Ember Spirit": -0.361
        },
        {
            "name": "Disruptor",
            "Ember Spirit": 0.519
        },
        {
            "name": "Doom",
            "Ember Spirit": 1.112
        },
        {
            "name": "Dragon Knight",
            "Ember Spirit": 1.6
        },
        {
            "name": "Drow Ranger",
            "Ember Spirit": 0.357
        },
        {
            "name": "Earth Spirit",
            "Ember Spirit": -0.756
        },
        {
            "name": "Earthshaker",
            "Ember Spirit": -0.323
        },
        {
            "name": "Elder Titan",
            "Ember Spirit": 0.568
        },
        {
            "name": "Enchantress",
            "Ember Spirit": 1.305
        },
        {
            "name": "Enigma",
            "Ember Spirit": 1.398
        },
        {
            "name": "Faceless Void",
            "Ember Spirit": 0.295
        },
        {
            "name": "Grimstroke",
            "Ember Spirit": 1.054
        },
        {
            "name": "Gyrocopter",
            "Ember Spirit": -0.089
        },
        {
            "name": "Hoodwink",
            "Ember Spirit": 0.324
        },
        {
            "name": "Huskar",
            "Ember Spirit": 6.027
        },
        {
            "name": "Invoker",
            "Ember Spirit": -0.856
        },
        {
            "name": "Io",
            "Ember Spirit": 0.008
        },
        {
            "name": "Jakiro",
            "Ember Spirit": -0.792
        },
        {
            "name": "Juggernaut",
            "Ember Spirit": 1.131
        },
        {
            "name": "Keeper of the Light",
            "Ember Spirit": -0.02
        },
        {
            "name": "Kez",
            "Ember Spirit": 1.096
        },
        {
            "name": "Kunkka",
            "Ember Spirit": -1.788
        },
        {
            "name": "Legion Commander",
            "Ember Spirit": 1.564
        },
        {
            "name": "Leshrac",
            "Ember Spirit": -1.359
        },
        {
            "name": "Lich",
            "Ember Spirit": -0.87
        },
        {
            "name": "Lifestealer",
            "Ember Spirit": 0.436
        },
        {
            "name": "Lina",
            "Ember Spirit": -1.397
        },
        {
            "name": "Lion",
            "Ember Spirit": 0.119
        },
        {
            "name": "Lone Druid",
            "Ember Spirit": 3.887
        },
        {
            "name": "Luna",
            "Ember Spirit": 0.344
        },
        {
            "name": "Lycan",
            "Ember Spirit": -0.034
        },
        {
            "name": "Magnus",
            "Ember Spirit": -1.816
        },
        {
            "name": "Marci",
            "Ember Spirit": -0.973
        },
        {
            "name": "Mars",
            "Ember Spirit": -1.941
        },
        {
            "name": "Medusa",
            "Ember Spirit": -1.644
        },
        {
            "name": "Meepo",
            "Ember Spirit": 5.476
        },
        {
            "name": "Mirana",
            "Ember Spirit": -0.223
        },
        {
            "name": "Monkey King",
            "Ember Spirit": 4.847
        },
        {
            "name": "Morphling",
            "Ember Spirit": 2.694
        },
        {
            "name": "Muerta",
            "Ember Spirit": 1.672
        },
        {
            "name": "Naga Siren",
            "Ember Spirit": 0.324
        },
        {
            "name": "Nature's Prophet",
            "Ember Spirit": 0.852
        },
        {
            "name": "Necrophos",
            "Ember Spirit": 0.011
        },
        {
            "name": "Night Stalker",
            "Ember Spirit": 0.773
        },
        {
            "name": "Nyx Assassin",
            "Ember Spirit": -1.642
        },
        {
            "name": "Ogre Magi",
            "Ember Spirit": 0.036
        },
        {
            "name": "Omniknight",
            "Ember Spirit": 0.602
        },
        {
            "name": "Oracle",
            "Ember Spirit": 2.838
        },
        {
            "name": "Outworld Destroyer",
            "Ember Spirit": -0.21
        },
        {
            "name": "Pangolier",
            "Ember Spirit": -0.17
        },
        {
            "name": "Phantom Assassin",
            "Ember Spirit": -0.86
        },
        {
            "name": "Phantom Lancer",
            "Ember Spirit": -2.318
        },
        {
            "name": "Phoenix",
            "Ember Spirit": 0.147
        },
        {
            "name": "Primal Beast",
            "Ember Spirit": -1.267
        },
        {
            "name": "Puck",
            "Ember Spirit": -0.282
        },
        {
            "name": "Pudge",
            "Ember Spirit": -0.711
        },
        {
            "name": "Pugna",
            "Ember Spirit": 0.756
        },
        {
            "name": "Queen of Pain",
            "Ember Spirit": -3.863
        },
        {
            "name": "Razor",
            "Ember Spirit": -1.853
        },
        {
            "name": "Riki",
            "Ember Spirit": 1.016
        },
        {
            "name": "Ringmaster",
            "Ember Spirit": -0.302
        },
        {
            "name": "Rubick",
            "Ember Spirit": -1.531
        },
        {
            "name": "Sand King",
            "Ember Spirit": -2.041
        },
        {
            "name": "Shadow Demon",
            "Ember Spirit": -0.788
        },
        {
            "name": "Shadow Fiend",
            "Ember Spirit": 2.209
        },
        {
            "name": "Shadow Shaman",
            "Ember Spirit": 0.386
        },
        {
            "name": "Silencer",
            "Ember Spirit": 0.968
        },
        {
            "name": "Skywrath Mage",
            "Ember Spirit": 0.566
        },
        {
            "name": "Slardar",
            "Ember Spirit": 0.344
        },
        {
            "name": "Slark",
            "Ember Spirit": 4.383
        },
        {
            "name": "Snapfire",
            "Ember Spirit": 0.108
        },
        {
            "name": "Sniper",
            "Ember Spirit": 0.559
        },
        {
            "name": "Spectre",
            "Ember Spirit": -2.508
        },
        {
            "name": "Spirit Breaker",
            "Ember Spirit": -1.083
        },
        {
            "name": "Storm Spirit",
            "Ember Spirit": -1.801
        },
        {
            "name": "Sven",
            "Ember Spirit": 1.182
        },
        {
            "name": "Techies",
            "Ember Spirit": 0.511
        },
        {
            "name": "Templar Assassin",
            "Ember Spirit": 0.876
        },
        {
            "name": "Terrorblade",
            "Ember Spirit": -0.089
        },
        {
            "name": "Tidehunter",
            "Ember Spirit": 2.429
        },
        {
            "name": "Timbersaw",
            "Ember Spirit": -0.721
        },
        {
            "name": "Tinker",
            "Ember Spirit": 3.759
        },
        {
            "name": "Tiny",
            "Ember Spirit": -0.87
        },
        {
            "name": "Treant Protector",
            "Ember Spirit": 1.025
        },
        {
            "name": "Troll Warlord",
            "Ember Spirit": 3.052
        },
        {
            "name": "Tusk",
            "Ember Spirit": -1.381
        },
        {
            "name": "Underlord",
            "Ember Spirit": 1.131
        },
        {
            "name": "Undying",
            "Ember Spirit": -1.039
        },
        {
            "name": "Ursa",
            "Ember Spirit": 0.568
        },
        {
            "name": "Vengeful Spirit",
            "Ember Spirit": -0.658
        },
        {
            "name": "Venomancer",
            "Ember Spirit": 0.662
        },
        {
            "name": "Viper",
            "Ember Spirit": 0.848
        },
        {
            "name": "Visage",
            "Ember Spirit": -1.2
        },
        {
            "name": "Void Spirit",
            "Ember Spirit": -2.292
        },
        {
            "name": "Warlock",
            "Ember Spirit": -0.996
        },
        {
            "name": "Weaver",
            "Ember Spirit": 1.153
        },
        {
            "name": "Windranger",
            "Ember Spirit": -0.591
        },
        {
            "name": "Winter Wyvern",
            "Ember Spirit": -0.456
        },
        {
            "name": "Witch Doctor",
            "Ember Spirit": 0.117
        },
        {
            "name": "Wraith King",
            "Ember Spirit": -0.836
        },
        {
            "name": "Zeus",
            "Ember Spirit": -1.719
        }
    ],
    "Faceless Void": [
        {
            "name": "Abaddon",
            "Faceless Void": 0.522
        },
        {
            "name": "Alchemist",
            "Faceless Void": 0.337
        },
        {
            "name": "Ancient Apparition",
            "Faceless Void": -0.594
        },
        {
            "name": "Anti-Mage",
            "Faceless Void": 1.225
        },
        {
            "name": "Arc Warden",
            "Faceless Void": 0.058
        },
        {
            "name": "Axe",
            "Faceless Void": 1.199
        },
        {
            "name": "Bane",
            "Faceless Void": 1.025
        },
        {
            "name": "Batrider",
            "Faceless Void": -0.914
        },
        {
            "name": "Beastmaster",
            "Faceless Void": 0.528
        },
        {
            "name": "Bloodseeker",
            "Faceless Void": -0.946
        },
        {
            "name": "Bounty Hunter",
            "Faceless Void": -0.454
        },
        {
            "name": "Brewmaster",
            "Faceless Void": 0.053
        },
        {
            "name": "Bristleback",
            "Faceless Void": 0.509
        },
        {
            "name": "Broodmother",
            "Faceless Void": 2.002
        },
        {
            "name": "Centaur Warrunner",
            "Faceless Void": 0.381
        },
        {
            "name": "Chaos Knight",
            "Faceless Void": 2.193
        },
        {
            "name": "Chen",
            "Faceless Void": -1.319
        },
        {
            "name": "Clinkz",
            "Faceless Void": -1.488
        },
        {
            "name": "Clockwerk",
            "Faceless Void": -1.886
        },
        {
            "name": "Crystal Maiden",
            "Faceless Void": 0.352
        },
        {
            "name": "Dark Seer",
            "Faceless Void": -2.473
        },
        {
            "name": "Dark Willow",
            "Faceless Void": 0.304
        },
        {
            "name": "Dawnbreaker",
            "Faceless Void": -1.598
        },
        {
            "name": "Dazzle",
            "Faceless Void": 0.859
        },
        {
            "name": "Death Prophet",
            "Faceless Void": 0.764
        },
        {
            "name": "Disruptor",
            "Faceless Void": 0.51
        },
        {
            "name": "Doom",
            "Faceless Void": 0.36
        },
        {
            "name": "Dragon Knight",
            "Faceless Void": -0.556
        },
        {
            "name": "Drow Ranger",
            "Faceless Void": -0.562
        },
        {
            "name": "Earth Spirit",
            "Faceless Void": 0.339
        },
        {
            "name": "Earthshaker",
            "Faceless Void": 0.267
        },
        {
            "name": "Elder Titan",
            "Faceless Void": 0.053
        },
        {
            "name": "Ember Spirit",
            "Faceless Void": -0.226
        },
        {
            "name": "Enchantress",
            "Faceless Void": 2.415
        },
        {
            "name": "Enigma",
            "Faceless Void": 0.326
        },
        {
            "name": "Grimstroke",
            "Faceless Void": 1.464
        },
        {
            "name": "Gyrocopter",
            "Faceless Void": 0.175
        },
        {
            "name": "Hoodwink",
            "Faceless Void": 0.611
        },
        {
            "name": "Huskar",
            "Faceless Void": 2.035
        },
        {
            "name": "Invoker",
            "Faceless Void": -1.008
        },
        {
            "name": "Io",
            "Faceless Void": -0.879
        },
        {
            "name": "Jakiro",
            "Faceless Void": -1.534
        },
        {
            "name": "Juggernaut",
            "Faceless Void": -0.297
        },
        {
            "name": "Keeper of the Light",
            "Faceless Void": -1.378
        },
        {
            "name": "Kez",
            "Faceless Void": -0.8
        },
        {
            "name": "Kunkka",
            "Faceless Void": -1.346
        },
        {
            "name": "Legion Commander",
            "Faceless Void": 1.604
        },
        {
            "name": "Leshrac",
            "Faceless Void": 0.051
        },
        {
            "name": "Lich",
            "Faceless Void": -0.358
        },
        {
            "name": "Lifestealer",
            "Faceless Void": -1.453
        },
        {
            "name": "Lina",
            "Faceless Void": -0.68
        },
        {
            "name": "Lion",
            "Faceless Void": 1.274
        },
        {
            "name": "Lone Druid",
            "Faceless Void": 1.825
        },
        {
            "name": "Luna",
            "Faceless Void": 0.271
        },
        {
            "name": "Lycan",
            "Faceless Void": 1.528
        },
        {
            "name": "Magnus",
            "Faceless Void": -1.398
        },
        {
            "name": "Marci",
            "Faceless Void": 0.01
        },
        {
            "name": "Mars",
            "Faceless Void": -1.964
        },
        {
            "name": "Medusa",
            "Faceless Void": -0.57
        },
        {
            "name": "Meepo",
            "Faceless Void": 4.056
        },
        {
            "name": "Mirana",
            "Faceless Void": -0.671
        },
        {
            "name": "Monkey King",
            "Faceless Void": 0.124
        },
        {
            "name": "Morphling",
            "Faceless Void": 0.556
        },
        {
            "name": "Muerta",
            "Faceless Void": 0.89
        },
        {
            "name": "Naga Siren",
            "Faceless Void": 3.153
        },
        {
            "name": "Nature's Prophet",
            "Faceless Void": -0.353
        },
        {
            "name": "Necrophos",
            "Faceless Void": -0.879
        },
        {
            "name": "Night Stalker",
            "Faceless Void": 0.869
        },
        {
            "name": "Nyx Assassin",
            "Faceless Void": 0.282
        },
        {
            "name": "Ogre Magi",
            "Faceless Void": 0.509
        },
        {
            "name": "Omniknight",
            "Faceless Void": -2.245
        },
        {
            "name": "Oracle",
            "Faceless Void": 0.228
        },
        {
            "name": "Outworld Destroyer",
            "Faceless Void": 0.114
        },
        {
            "name": "Pangolier",
            "Faceless Void": -0.329
        },
        {
            "name": "Phantom Assassin",
            "Faceless Void": -0.543
        },
        {
            "name": "Phantom Lancer",
            "Faceless Void": 0.976
        },
        {
            "name": "Phoenix",
            "Faceless Void": 0.82
        },
        {
            "name": "Primal Beast",
            "Faceless Void": -2.259
        },
        {
            "name": "Puck",
            "Faceless Void": -0.306
        },
        {
            "name": "Pudge",
            "Faceless Void": 0.333
        },
        {
            "name": "Pugna",
            "Faceless Void": -0.757
        },
        {
            "name": "Queen of Pain",
            "Faceless Void": -1.918
        },
        {
            "name": "Razor",
            "Faceless Void": -1.82
        },
        {
            "name": "Riki",
            "Faceless Void": 1.617
        },
        {
            "name": "Ringmaster",
            "Faceless Void": -0.421
        },
        {
            "name": "Rubick",
            "Faceless Void": 0.337
        },
        {
            "name": "Sand King",
            "Faceless Void": -1.978
        },
        {
            "name": "Shadow Demon",
            "Faceless Void": -1.036
        },
        {
            "name": "Shadow Fiend",
            "Faceless Void": 0.836
        },
        {
            "name": "Shadow Shaman",
            "Faceless Void": 0.797
        },
        {
            "name": "Silencer",
            "Faceless Void": 0.974
        },
        {
            "name": "Skywrath Mage",
            "Faceless Void": 1.183
        },
        {
            "name": "Slardar",
            "Faceless Void": 1.014
        },
        {
            "name": "Slark",
            "Faceless Void": 1.958
        },
        {
            "name": "Snapfire",
            "Faceless Void": -1.025
        },
        {
            "name": "Sniper",
            "Faceless Void": 0.011
        },
        {
            "name": "Spectre",
            "Faceless Void": 1.465
        },
        {
            "name": "Spirit Breaker",
            "Faceless Void": 0.122
        },
        {
            "name": "Storm Spirit",
            "Faceless Void": -0.143
        },
        {
            "name": "Sven",
            "Faceless Void": -0.793
        },
        {
            "name": "Techies",
            "Faceless Void": -0.404
        },
        {
            "name": "Templar Assassin",
            "Faceless Void": -0.776
        },
        {
            "name": "Terrorblade",
            "Faceless Void": -0.844
        },
        {
            "name": "Tidehunter",
            "Faceless Void": 1.482
        },
        {
            "name": "Timbersaw",
            "Faceless Void": -2.428
        },
        {
            "name": "Tinker",
            "Faceless Void": -0.394
        },
        {
            "name": "Tiny",
            "Faceless Void": -0.249
        },
        {
            "name": "Treant Protector",
            "Faceless Void": 0.163
        },
        {
            "name": "Troll Warlord",
            "Faceless Void": 1.372
        },
        {
            "name": "Tusk",
            "Faceless Void": -1.023
        },
        {
            "name": "Underlord",
            "Faceless Void": 0.264
        },
        {
            "name": "Undying",
            "Faceless Void": -1.711
        },
        {
            "name": "Ursa",
            "Faceless Void": 0.192
        },
        {
            "name": "Vengeful Spirit",
            "Faceless Void": 0.406
        },
        {
            "name": "Venomancer",
            "Faceless Void": -0.397
        },
        {
            "name": "Viper",
            "Faceless Void": 0.466
        },
        {
            "name": "Visage",
            "Faceless Void": -0.294
        },
        {
            "name": "Void Spirit",
            "Faceless Void": 0.06
        },
        {
            "name": "Warlock",
            "Faceless Void": -0.207
        },
        {
            "name": "Weaver",
            "Faceless Void": -3.449
        },
        {
            "name": "Windranger",
            "Faceless Void": -1.029
        },
        {
            "name": "Winter Wyvern",
            "Faceless Void": -0.441
        },
        {
            "name": "Witch Doctor",
            "Faceless Void": -1.702
        },
        {
            "name": "Wraith King",
            "Faceless Void": 0.521
        },
        {
            "name": "Zeus",
            "Faceless Void": -1.199
        }
    ],
    "Gyrocopter": [
        {
            "name": "Abaddon",
            "Gyrocopter": 0.689
        },
        {
            "name": "Alchemist",
            "Gyrocopter": -0.524
        },
        {
            "name": "Ancient Apparition",
            "Gyrocopter": 1.562
        },
        {
            "name": "Anti-Mage",
            "Gyrocopter": 1.064
        },
        {
            "name": "Arc Warden",
            "Gyrocopter": -0.259
        },
        {
            "name": "Axe",
            "Gyrocopter": -0.874
        },
        {
            "name": "Bane",
            "Gyrocopter": 0.559
        },
        {
            "name": "Batrider",
            "Gyrocopter": -0.345
        },
        {
            "name": "Beastmaster",
            "Gyrocopter": -2.382
        },
        {
            "name": "Bloodseeker",
            "Gyrocopter": 0.783
        },
        {
            "name": "Bounty Hunter",
            "Gyrocopter": 1.593
        },
        {
            "name": "Brewmaster",
            "Gyrocopter": -0.305
        },
        {
            "name": "Bristleback",
            "Gyrocopter": 1.853
        },
        {
            "name": "Broodmother",
            "Gyrocopter": 1.117
        },
        {
            "name": "Centaur Warrunner",
            "Gyrocopter": 0.111
        },
        {
            "name": "Chaos Knight",
            "Gyrocopter": -1.465
        },
        {
            "name": "Chen",
            "Gyrocopter": -1.026
        },
        {
            "name": "Clinkz",
            "Gyrocopter": -0.046
        },
        {
            "name": "Clockwerk",
            "Gyrocopter": -0.14
        },
        {
            "name": "Crystal Maiden",
            "Gyrocopter": -0.736
        },
        {
            "name": "Dark Seer",
            "Gyrocopter": -2.161
        },
        {
            "name": "Dark Willow",
            "Gyrocopter": 0.58
        },
        {
            "name": "Dawnbreaker",
            "Gyrocopter": -0.054
        },
        {
            "name": "Dazzle",
            "Gyrocopter": -0.411
        },
        {
            "name": "Death Prophet",
            "Gyrocopter": -1.044
        },
        {
            "name": "Disruptor",
            "Gyrocopter": -1.094
        },
        {
            "name": "Doom",
            "Gyrocopter": 0.153
        },
        {
            "name": "Dragon Knight",
            "Gyrocopter": 0.139
        },
        {
            "name": "Drow Ranger",
            "Gyrocopter": 0.084
        },
        {
            "name": "Earth Spirit",
            "Gyrocopter": -0.022
        },
        {
            "name": "Earthshaker",
            "Gyrocopter": -0.851
        },
        {
            "name": "Elder Titan",
            "Gyrocopter": 0.65
        },
        {
            "name": "Ember Spirit",
            "Gyrocopter": 0.18
        },
        {
            "name": "Enchantress",
            "Gyrocopter": 1.012
        },
        {
            "name": "Enigma",
            "Gyrocopter": -2.186
        },
        {
            "name": "Faceless Void",
            "Gyrocopter": -0.16
        },
        {
            "name": "Grimstroke",
            "Gyrocopter": -1.944
        },
        {
            "name": "Hoodwink",
            "Gyrocopter": -0.223
        },
        {
            "name": "Huskar",
            "Gyrocopter": 0.53
        },
        {
            "name": "Invoker",
            "Gyrocopter": -0.85
        },
        {
            "name": "Io",
            "Gyrocopter": -0.459
        },
        {
            "name": "Jakiro",
            "Gyrocopter": -0.275
        },
        {
            "name": "Juggernaut",
            "Gyrocopter": 1.536
        },
        {
            "name": "Keeper of the Light",
            "Gyrocopter": 0.536
        },
        {
            "name": "Kez",
            "Gyrocopter": 0.217
        },
        {
            "name": "Kunkka",
            "Gyrocopter": 0.114
        },
        {
            "name": "Legion Commander",
            "Gyrocopter": 0.769
        },
        {
            "name": "Leshrac",
            "Gyrocopter": -0.713
        },
        {
            "name": "Lich",
            "Gyrocopter": 0.613
        },
        {
            "name": "Lifestealer",
            "Gyrocopter": 1.852
        },
        {
            "name": "Lina",
            "Gyrocopter": 0.264
        },
        {
            "name": "Lion",
            "Gyrocopter": -1.05
        },
        {
            "name": "Lone Druid",
            "Gyrocopter": -1.452
        },
        {
            "name": "Luna",
            "Gyrocopter": 0.179
        },
        {
            "name": "Lycan",
            "Gyrocopter": 0.538
        },
        {
            "name": "Magnus",
            "Gyrocopter": -0.881
        },
        {
            "name": "Marci",
            "Gyrocopter": -0.734
        },
        {
            "name": "Mars",
            "Gyrocopter": 1.106
        },
        {
            "name": "Medusa",
            "Gyrocopter": 0.287
        },
        {
            "name": "Meepo",
            "Gyrocopter": -1.117
        },
        {
            "name": "Mirana",
            "Gyrocopter": -0.434
        },
        {
            "name": "Monkey King",
            "Gyrocopter": 0.07
        },
        {
            "name": "Morphling",
            "Gyrocopter": 0.819
        },
        {
            "name": "Muerta",
            "Gyrocopter": -0.264
        },
        {
            "name": "Naga Siren",
            "Gyrocopter": -2.327
        },
        {
            "name": "Nature's Prophet",
            "Gyrocopter": 0.263
        },
        {
            "name": "Necrophos",
            "Gyrocopter": -0.689
        },
        {
            "name": "Night Stalker",
            "Gyrocopter": -1.192
        },
        {
            "name": "Nyx Assassin",
            "Gyrocopter": 0.416
        },
        {
            "name": "Ogre Magi",
            "Gyrocopter": 0.828
        },
        {
            "name": "Omniknight",
            "Gyrocopter": 0.64
        },
        {
            "name": "Oracle",
            "Gyrocopter": -0.203
        },
        {
            "name": "Outworld Destroyer",
            "Gyrocopter": 1.335
        },
        {
            "name": "Pangolier",
            "Gyrocopter": -1.387
        },
        {
            "name": "Phantom Assassin",
            "Gyrocopter": -0.091
        },
        {
            "name": "Phantom Lancer",
            "Gyrocopter": -0.4
        },
        {
            "name": "Phoenix",
            "Gyrocopter": 0.482
        },
        {
            "name": "Primal Beast",
            "Gyrocopter": -1.12
        },
        {
            "name": "Puck",
            "Gyrocopter": -0.717
        },
        {
            "name": "Pudge",
            "Gyrocopter": 0.227
        },
        {
            "name": "Pugna",
            "Gyrocopter": 0.189
        },
        {
            "name": "Queen of Pain",
            "Gyrocopter": 0.458
        },
        {
            "name": "Razor",
            "Gyrocopter": 1.546
        },
        {
            "name": "Riki",
            "Gyrocopter": -0.201
        },
        {
            "name": "Ringmaster",
            "Gyrocopter": -0.205
        },
        {
            "name": "Rubick",
            "Gyrocopter": -0.261
        },
        {
            "name": "Sand King",
            "Gyrocopter": 0.177
        },
        {
            "name": "Shadow Demon",
            "Gyrocopter": 1.345
        },
        {
            "name": "Shadow Fiend",
            "Gyrocopter": -1.308
        },
        {
            "name": "Shadow Shaman",
            "Gyrocopter": -0.962
        },
        {
            "name": "Silencer",
            "Gyrocopter": 0.446
        },
        {
            "name": "Skywrath Mage",
            "Gyrocopter": 1.041
        },
        {
            "name": "Slardar",
            "Gyrocopter": -1.011
        },
        {
            "name": "Slark",
            "Gyrocopter": -0.96
        },
        {
            "name": "Snapfire",
            "Gyrocopter": -1.147
        },
        {
            "name": "Sniper",
            "Gyrocopter": 1.336
        },
        {
            "name": "Spectre",
            "Gyrocopter": 0.67
        },
        {
            "name": "Spirit Breaker",
            "Gyrocopter": -0.01
        },
        {
            "name": "Storm Spirit",
            "Gyrocopter": -0.363
        },
        {
            "name": "Sven",
            "Gyrocopter": -1.13
        },
        {
            "name": "Techies",
            "Gyrocopter": 0.444
        },
        {
            "name": "Templar Assassin",
            "Gyrocopter": -0.24
        },
        {
            "name": "Terrorblade",
            "Gyrocopter": -0.423
        },
        {
            "name": "Tidehunter",
            "Gyrocopter": -0.465
        },
        {
            "name": "Timbersaw",
            "Gyrocopter": -1.188
        },
        {
            "name": "Tinker",
            "Gyrocopter": -0.352
        },
        {
            "name": "Tiny",
            "Gyrocopter": -1.443
        },
        {
            "name": "Treant Protector",
            "Gyrocopter": -0.253
        },
        {
            "name": "Troll Warlord",
            "Gyrocopter": -0.008
        },
        {
            "name": "Tusk",
            "Gyrocopter": -0.441
        },
        {
            "name": "Underlord",
            "Gyrocopter": 2.095
        },
        {
            "name": "Undying",
            "Gyrocopter": -1.441
        },
        {
            "name": "Ursa",
            "Gyrocopter": 0.234
        },
        {
            "name": "Vengeful Spirit",
            "Gyrocopter": -0.787
        },
        {
            "name": "Venomancer",
            "Gyrocopter": 1.056
        },
        {
            "name": "Viper",
            "Gyrocopter": 1.4
        },
        {
            "name": "Visage",
            "Gyrocopter": -0.529
        },
        {
            "name": "Void Spirit",
            "Gyrocopter": -0.309
        },
        {
            "name": "Warlock",
            "Gyrocopter": -0.371
        },
        {
            "name": "Weaver",
            "Gyrocopter": -1.481
        },
        {
            "name": "Windranger",
            "Gyrocopter": 0.229
        },
        {
            "name": "Winter Wyvern",
            "Gyrocopter": -1.699
        },
        {
            "name": "Witch Doctor",
            "Gyrocopter": 0.687
        },
        {
            "name": "Wraith King",
            "Gyrocopter": 0.706
        },
        {
            "name": "Zeus",
            "Gyrocopter": 0.713
        }
    ],
    "Hoodwink": [
        {
            "name": "Abaddon",
            "Hoodwink": -0.471
        },
        {
            "name": "Alchemist",
            "Hoodwink": -0.309
        },
        {
            "name": "Ancient Apparition",
            "Hoodwink": -0.782
        },
        {
            "name": "Anti-Mage",
            "Hoodwink": -2.406
        },
        {
            "name": "Arc Warden",
            "Hoodwink": 0.614
        },
        {
            "name": "Axe",
            "Hoodwink": 1.67
        },
        {
            "name": "Bane",
            "Hoodwink": 0.046
        },
        {
            "name": "Batrider",
            "Hoodwink": -0.035
        },
        {
            "name": "Beastmaster",
            "Hoodwink": -0.297
        },
        {
            "name": "Bloodseeker",
            "Hoodwink": 0.049
        },
        {
            "name": "Bounty Hunter",
            "Hoodwink": -0.394
        },
        {
            "name": "Brewmaster",
            "Hoodwink": 0.605
        },
        {
            "name": "Bristleback",
            "Hoodwink": -2.215
        },
        {
            "name": "Broodmother",
            "Hoodwink": -0.546
        },
        {
            "name": "Centaur Warrunner",
            "Hoodwink": 1.288
        },
        {
            "name": "Chaos Knight",
            "Hoodwink": -0.3
        },
        {
            "name": "Chen",
            "Hoodwink": 0.399
        },
        {
            "name": "Clinkz",
            "Hoodwink": -0.567
        },
        {
            "name": "Clockwerk",
            "Hoodwink": 0.804
        },
        {
            "name": "Crystal Maiden",
            "Hoodwink": 0.171
        },
        {
            "name": "Dark Seer",
            "Hoodwink": -0.219
        },
        {
            "name": "Dark Willow",
            "Hoodwink": -1.007
        },
        {
            "name": "Dawnbreaker",
            "Hoodwink": 0.234
        },
        {
            "name": "Dazzle",
            "Hoodwink": -0.899
        },
        {
            "name": "Death Prophet",
            "Hoodwink": 0.004
        },
        {
            "name": "Disruptor",
            "Hoodwink": 0.176
        },
        {
            "name": "Doom",
            "Hoodwink": -0.298
        },
        {
            "name": "Dragon Knight",
            "Hoodwink": 0.467
        },
        {
            "name": "Drow Ranger",
            "Hoodwink": -0.089
        },
        {
            "name": "Earth Spirit",
            "Hoodwink": 0.502
        },
        {
            "name": "Earthshaker",
            "Hoodwink": -1.02
        },
        {
            "name": "Elder Titan",
            "Hoodwink": -2.588
        },
        {
            "name": "Ember Spirit",
            "Hoodwink": -0.22
        },
        {
            "name": "Enchantress",
            "Hoodwink": -1.043
        },
        {
            "name": "Enigma",
            "Hoodwink": -1.026
        },
        {
            "name": "Faceless Void",
            "Hoodwink": -0.579
        },
        {
            "name": "Grimstroke",
            "Hoodwink": -0.553
        },
        {
            "name": "Gyrocopter",
            "Hoodwink": 0.204
        },
        {
            "name": "Huskar",
            "Hoodwink": -1.392
        },
        {
            "name": "Invoker",
            "Hoodwink": -1.158
        },
        {
            "name": "Io",
            "Hoodwink": -1.276
        },
        {
            "name": "Jakiro",
            "Hoodwink": -0.209
        },
        {
            "name": "Juggernaut",
            "Hoodwink": 0.372
        },
        {
            "name": "Keeper of the Light",
            "Hoodwink": 0.58
        },
        {
            "name": "Kez",
            "Hoodwink": -0.005
        },
        {
            "name": "Kunkka",
            "Hoodwink": -0.895
        },
        {
            "name": "Legion Commander",
            "Hoodwink": 0.458
        },
        {
            "name": "Leshrac",
            "Hoodwink": -0.338
        },
        {
            "name": "Lich",
            "Hoodwink": -0.024
        },
        {
            "name": "Lifestealer",
            "Hoodwink": 0.289
        },
        {
            "name": "Lina",
            "Hoodwink": -0.137
        },
        {
            "name": "Lion",
            "Hoodwink": -0.268
        },
        {
            "name": "Lone Druid",
            "Hoodwink": -1.195
        },
        {
            "name": "Luna",
            "Hoodwink": -0.975
        },
        {
            "name": "Lycan",
            "Hoodwink": -1.104
        },
        {
            "name": "Magnus",
            "Hoodwink": -0.323
        },
        {
            "name": "Marci",
            "Hoodwink": 0.392
        },
        {
            "name": "Mars",
            "Hoodwink": 1.246
        },
        {
            "name": "Medusa",
            "Hoodwink": -0.957
        },
        {
            "name": "Meepo",
            "Hoodwink": -1.206
        },
        {
            "name": "Mirana",
            "Hoodwink": 0.248
        },
        {
            "name": "Monkey King",
            "Hoodwink": -1.653
        },
        {
            "name": "Morphling",
            "Hoodwink": -1.253
        },
        {
            "name": "Muerta",
            "Hoodwink": 0.035
        },
        {
            "name": "Naga Siren",
            "Hoodwink": 0.433
        },
        {
            "name": "Nature's Prophet",
            "Hoodwink": -1.509
        },
        {
            "name": "Necrophos",
            "Hoodwink": 0.199
        },
        {
            "name": "Night Stalker",
            "Hoodwink": 0.032
        },
        {
            "name": "Nyx Assassin",
            "Hoodwink": 0.683
        },
        {
            "name": "Ogre Magi",
            "Hoodwink": 1.291
        },
        {
            "name": "Omniknight",
            "Hoodwink": 0.074
        },
        {
            "name": "Oracle",
            "Hoodwink": -0.309
        },
        {
            "name": "Outworld Destroyer",
            "Hoodwink": -0.269
        },
        {
            "name": "Pangolier",
            "Hoodwink": -0.431
        },
        {
            "name": "Phantom Assassin",
            "Hoodwink": 0.804
        },
        {
            "name": "Phantom Lancer",
            "Hoodwink": 1.114
        },
        {
            "name": "Phoenix",
            "Hoodwink": -0.196
        },
        {
            "name": "Primal Beast",
            "Hoodwink": 1.487
        },
        {
            "name": "Puck",
            "Hoodwink": 0.72
        },
        {
            "name": "Pudge",
            "Hoodwink": -0.646
        },
        {
            "name": "Pugna",
            "Hoodwink": -0.38
        },
        {
            "name": "Queen of Pain",
            "Hoodwink": 0.623
        },
        {
            "name": "Razor",
            "Hoodwink": -0.68
        },
        {
            "name": "Riki",
            "Hoodwink": -0.241
        },
        {
            "name": "Ringmaster",
            "Hoodwink": -0.243
        },
        {
            "name": "Rubick",
            "Hoodwink": -1.188
        },
        {
            "name": "Sand King",
            "Hoodwink": 0.894
        },
        {
            "name": "Shadow Demon",
            "Hoodwink": 0.215
        },
        {
            "name": "Shadow Fiend",
            "Hoodwink": -1.4
        },
        {
            "name": "Shadow Shaman",
            "Hoodwink": -0.222
        },
        {
            "name": "Silencer",
            "Hoodwink": -0.04
        },
        {
            "name": "Skywrath Mage",
            "Hoodwink": 0.379
        },
        {
            "name": "Slardar",
            "Hoodwink": -0.531
        },
        {
            "name": "Slark",
            "Hoodwink": -0.172
        },
        {
            "name": "Snapfire",
            "Hoodwink": -0.422
        },
        {
            "name": "Sniper",
            "Hoodwink": -0.029
        },
        {
            "name": "Spectre",
            "Hoodwink": 0.012
        },
        {
            "name": "Spirit Breaker",
            "Hoodwink": 1.725
        },
        {
            "name": "Storm Spirit",
            "Hoodwink": 0.478
        },
        {
            "name": "Sven",
            "Hoodwink": 0.327
        },
        {
            "name": "Techies",
            "Hoodwink": -0.137
        },
        {
            "name": "Templar Assassin",
            "Hoodwink": -0.55
        },
        {
            "name": "Terrorblade",
            "Hoodwink": 0.295
        },
        {
            "name": "Tidehunter",
            "Hoodwink": -0.515
        },
        {
            "name": "Timbersaw",
            "Hoodwink": 2.067
        },
        {
            "name": "Tinker",
            "Hoodwink": 0.857
        },
        {
            "name": "Tiny",
            "Hoodwink": 0.82
        },
        {
            "name": "Treant Protector",
            "Hoodwink": 0.387
        },
        {
            "name": "Troll Warlord",
            "Hoodwink": -0.276
        },
        {
            "name": "Tusk",
            "Hoodwink": 0.039
        },
        {
            "name": "Underlord",
            "Hoodwink": -0.448
        },
        {
            "name": "Undying",
            "Hoodwink": -0.514
        },
        {
            "name": "Ursa",
            "Hoodwink": -1.186
        },
        {
            "name": "Vengeful Spirit",
            "Hoodwink": -0.002
        },
        {
            "name": "Venomancer",
            "Hoodwink": 0.18
        },
        {
            "name": "Viper",
            "Hoodwink": -0.69
        },
        {
            "name": "Visage",
            "Hoodwink": -0.635
        },
        {
            "name": "Void Spirit",
            "Hoodwink": 0.997
        },
        {
            "name": "Warlock",
            "Hoodwink": -0.134
        },
        {
            "name": "Weaver",
            "Hoodwink": 0.094
        },
        {
            "name": "Windranger",
            "Hoodwink": 0.476
        },
        {
            "name": "Winter Wyvern",
            "Hoodwink": 0.04
        },
        {
            "name": "Witch Doctor",
            "Hoodwink": -0.525
        },
        {
            "name": "Wraith King",
            "Hoodwink": 0.791
        },
        {
            "name": "Zeus",
            "Hoodwink": 0.45
        }
    ],
    "Juggernaut": [
        {
            "name": "Abaddon",
            "Juggernaut": 0.797
        },
        {
            "name": "Alchemist",
            "Juggernaut": -0.924
        },
        {
            "name": "Ancient Apparition",
            "Juggernaut": -0.664
        },
        {
            "name": "Anti-Mage",
            "Juggernaut": 2.686
        },
        {
            "name": "Arc Warden",
            "Juggernaut": 0.849
        },
        {
            "name": "Axe",
            "Juggernaut": 1.137
        },
        {
            "name": "Bane",
            "Juggernaut": 0.772
        },
        {
            "name": "Batrider",
            "Juggernaut": 1.265
        },
        {
            "name": "Beastmaster",
            "Juggernaut": 1.102
        },
        {
            "name": "Bloodseeker",
            "Juggernaut": -3.075
        },
        {
            "name": "Bounty Hunter",
            "Juggernaut": -0.839
        },
        {
            "name": "Brewmaster",
            "Juggernaut": 0.414
        },
        {
            "name": "Bristleback",
            "Juggernaut": -1.66
        },
        {
            "name": "Broodmother",
            "Juggernaut": 1.941
        },
        {
            "name": "Centaur Warrunner",
            "Juggernaut": -0.993
        },
        {
            "name": "Chaos Knight",
            "Juggernaut": 0.378
        },
        {
            "name": "Chen",
            "Juggernaut": 3.446
        },
        {
            "name": "Clinkz",
            "Juggernaut": 2.419
        },
        {
            "name": "Clockwerk",
            "Juggernaut": -1.541
        },
        {
            "name": "Crystal Maiden",
            "Juggernaut": -1.248
        },
        {
            "name": "Dark Seer",
            "Juggernaut": -0.695
        },
        {
            "name": "Dark Willow",
            "Juggernaut": -1.425
        },
        {
            "name": "Dawnbreaker",
            "Juggernaut": -1.116
        },
        {
            "name": "Dazzle",
            "Juggernaut": -0.023
        },
        {
            "name": "Death Prophet",
            "Juggernaut": 2.423
        },
        {
            "name": "Disruptor",
            "Juggernaut": 0.661
        },
        {
            "name": "Doom",
            "Juggernaut": 2.041
        },
        {
            "name": "Dragon Knight",
            "Juggernaut": 0.054
        },
        {
            "name": "Drow Ranger",
            "Juggernaut": 0.801
        },
        {
            "name": "Earth Spirit",
            "Juggernaut": -1.411
        },
        {
            "name": "Earthshaker",
            "Juggernaut": -0.082
        },
        {
            "name": "Elder Titan",
            "Juggernaut": -1.156
        },
        {
            "name": "Ember Spirit",
            "Juggernaut": -0.989
        },
        {
            "name": "Enchantress",
            "Juggernaut": 1.795
        },
        {
            "name": "Enigma",
            "Juggernaut": 0.71
        },
        {
            "name": "Faceless Void",
            "Juggernaut": 0.611
        },
        {
            "name": "Grimstroke",
            "Juggernaut": 0.522
        },
        {
            "name": "Gyrocopter",
            "Juggernaut": -0.946
        },
        {
            "name": "Hoodwink",
            "Juggernaut": 0.247
        },
        {
            "name": "Huskar",
            "Juggernaut": 1.478
        },
        {
            "name": "Invoker",
            "Juggernaut": -0.499
        },
        {
            "name": "Io",
            "Juggernaut": 1.326
        },
        {
            "name": "Jakiro",
            "Juggernaut": -1.428
        },
        {
            "name": "Keeper of the Light",
            "Juggernaut": -0.363
        },
        {
            "name": "Kez",
            "Juggernaut": 1.531
        },
        {
            "name": "Kunkka",
            "Juggernaut": -0.233
        },
        {
            "name": "Legion Commander",
            "Juggernaut": 1.138
        },
        {
            "name": "Leshrac",
            "Juggernaut": -0.089
        },
        {
            "name": "Lich",
            "Juggernaut": -0.022
        },
        {
            "name": "Lifestealer",
            "Juggernaut": -1.945
        },
        {
            "name": "Lina",
            "Juggernaut": 1.455
        },
        {
            "name": "Lion",
            "Juggernaut": 1.537
        },
        {
            "name": "Lone Druid",
            "Juggernaut": 1.956
        },
        {
            "name": "Luna",
            "Juggernaut": 0.156
        },
        {
            "name": "Lycan",
            "Juggernaut": 1.656
        },
        {
            "name": "Magnus",
            "Juggernaut": -1.184
        },
        {
            "name": "Marci",
            "Juggernaut": -0.537
        },
        {
            "name": "Mars",
            "Juggernaut": 0.045
        },
        {
            "name": "Medusa",
            "Juggernaut": -0.62
        },
        {
            "name": "Meepo",
            "Juggernaut": 0.96
        },
        {
            "name": "Mirana",
            "Juggernaut": -0.152
        },
        {
            "name": "Monkey King",
            "Juggernaut": -0.114
        },
        {
            "name": "Morphling",
            "Juggernaut": 3.004
        },
        {
            "name": "Muerta",
            "Juggernaut": 1.123
        },
        {
            "name": "Naga Siren",
            "Juggernaut": -0.476
        },
        {
            "name": "Nature's Prophet",
            "Juggernaut": 1.821
        },
        {
            "name": "Necrophos",
            "Juggernaut": 0.184
        },
        {
            "name": "Night Stalker",
            "Juggernaut": 2.418
        },
        {
            "name": "Nyx Assassin",
            "Juggernaut": -0.272
        },
        {
            "name": "Ogre Magi",
            "Juggernaut": -1.023
        },
        {
            "name": "Omniknight",
            "Juggernaut": 0.903
        },
        {
            "name": "Oracle",
            "Juggernaut": 0.626
        },
        {
            "name": "Outworld Destroyer",
            "Juggernaut": 1.154
        },
        {
            "name": "Pangolier",
            "Juggernaut": -0.438
        },
        {
            "name": "Phantom Assassin",
            "Juggernaut": 0.67
        },
        {
            "name": "Phantom Lancer",
            "Juggernaut": 1.442
        },
        {
            "name": "Phoenix",
            "Juggernaut": -2.998
        },
        {
            "name": "Primal Beast",
            "Juggernaut": -0.747
        },
        {
            "name": "Puck",
            "Juggernaut": 1.211
        },
        {
            "name": "Pudge",
            "Juggernaut": 0.457
        },
        {
            "name": "Pugna",
            "Juggernaut": 1.452
        },
        {
            "name": "Queen of Pain",
            "Juggernaut": 0.75
        },
        {
            "name": "Razor",
            "Juggernaut": 0.961
        },
        {
            "name": "Riki",
            "Juggernaut": 3.288
        },
        {
            "name": "Ringmaster",
            "Juggernaut": -0.326
        },
        {
            "name": "Rubick",
            "Juggernaut": -0.147
        },
        {
            "name": "Sand King",
            "Juggernaut": -0.871
        },
        {
            "name": "Shadow Demon",
            "Juggernaut": 0.948
        },
        {
            "name": "Shadow Fiend",
            "Juggernaut": -0.023
        },
        {
            "name": "Shadow Shaman",
            "Juggernaut": 0.738
        },
        {
            "name": "Silencer",
            "Juggernaut": 0.925
        },
        {
            "name": "Skywrath Mage",
            "Juggernaut": 1.66
        },
        {
            "name": "Slardar",
            "Juggernaut": -1.897
        },
        {
            "name": "Slark",
            "Juggernaut": 1.402
        },
        {
            "name": "Snapfire",
            "Juggernaut": 0.212
        },
        {
            "name": "Sniper",
            "Juggernaut": 0.543
        },
        {
            "name": "Spectre",
            "Juggernaut": 0.11
        },
        {
            "name": "Spirit Breaker",
            "Juggernaut": 1.821
        },
        {
            "name": "Storm Spirit",
            "Juggernaut": 1.372
        },
        {
            "name": "Sven",
            "Juggernaut": 0.435
        },
        {
            "name": "Techies",
            "Juggernaut": -0.918
        },
        {
            "name": "Templar Assassin",
            "Juggernaut": 2.286
        },
        {
            "name": "Terrorblade",
            "Juggernaut": -0.235
        },
        {
            "name": "Tidehunter",
            "Juggernaut": -3.45
        },
        {
            "name": "Timbersaw",
            "Juggernaut": 0.409
        },
        {
            "name": "Tinker",
            "Juggernaut": -2.449
        },
        {
            "name": "Tiny",
            "Juggernaut": -0.624
        },
        {
            "name": "Treant Protector",
            "Juggernaut": -1.52
        },
        {
            "name": "Troll Warlord",
            "Juggernaut": 0.487
        },
        {
            "name": "Tusk",
            "Juggernaut": 0.719
        },
        {
            "name": "Underlord",
            "Juggernaut": -2.277
        },
        {
            "name": "Undying",
            "Juggernaut": -0.496
        },
        {
            "name": "Ursa",
            "Juggernaut": 2.079
        },
        {
            "name": "Vengeful Spirit",
            "Juggernaut": -0.184
        },
        {
            "name": "Venomancer",
            "Juggernaut": -0.921
        },
        {
            "name": "Viper",
            "Juggernaut": -1.503
        },
        {
            "name": "Visage",
            "Juggernaut": 2.559
        },
        {
            "name": "Void Spirit",
            "Juggernaut": 2.405
        },
        {
            "name": "Warlock",
            "Juggernaut": 0.464
        },
        {
            "name": "Weaver",
            "Juggernaut": 1.619
        },
        {
            "name": "Windranger",
            "Juggernaut": 1.651
        },
        {
            "name": "Winter Wyvern",
            "Juggernaut": -0.304
        },
        {
            "name": "Witch Doctor",
            "Juggernaut": 0.756
        },
        {
            "name": "Wraith King",
            "Juggernaut": 0.51
        },
        {
            "name": "Zeus",
            "Juggernaut": -0.435
        }
    ],
    "Kez": [
        {
            "name": "Abaddon",
            "Kez": 1.062
        },
        {
            "name": "Alchemist",
            "Kez": -1.531
        },
        {
            "name": "Ancient Apparition",
            "Kez": -0.565
        },
        {
            "name": "Anti-Mage",
            "Kez": -2.944
        },
        {
            "name": "Arc Warden",
            "Kez": -0.989
        },
        {
            "name": "Axe",
            "Kez": 1.65
        },
        {
            "name": "Bane",
            "Kez": 2.33
        },
        {
            "name": "Batrider",
            "Kez": 0.186
        },
        {
            "name": "Beastmaster",
            "Kez": -1.32
        },
        {
            "name": "Bloodseeker",
            "Kez": -0.892
        },
        {
            "name": "Bounty Hunter",
            "Kez": -0.737
        },
        {
            "name": "Brewmaster",
            "Kez": 0.329
        },
        {
            "name": "Bristleback",
            "Kez": -0.243
        },
        {
            "name": "Broodmother",
            "Kez": -0.781
        },
        {
            "name": "Centaur Warrunner",
            "Kez": -1.952
        },
        {
            "name": "Chaos Knight",
            "Kez": 0.309
        },
        {
            "name": "Chen",
            "Kez": -0.785
        },
        {
            "name": "Clinkz",
            "Kez": 0.468
        },
        {
            "name": "Clockwerk",
            "Kez": -0.158
        },
        {
            "name": "Crystal Maiden",
            "Kez": -0.225
        },
        {
            "name": "Dark Seer",
            "Kez": -0.69
        },
        {
            "name": "Dark Willow",
            "Kez": 1.545
        },
        {
            "name": "Dawnbreaker",
            "Kez": -0.638
        },
        {
            "name": "Dazzle",
            "Kez": -0.354
        },
        {
            "name": "Death Prophet",
            "Kez": 0.945
        },
        {
            "name": "Disruptor",
            "Kez": -0.693
        },
        {
            "name": "Doom",
            "Kez": -0.597
        },
        {
            "name": "Dragon Knight",
            "Kez": 0.944
        },
        {
            "name": "Drow Ranger",
            "Kez": 2.419
        },
        {
            "name": "Earth Spirit",
            "Kez": -2.735
        },
        {
            "name": "Earthshaker",
            "Kez": -0.452
        },
        {
            "name": "Elder Titan",
            "Kez": -0.459
        },
        {
            "name": "Ember Spirit",
            "Kez": -0.903
        },
        {
            "name": "Enchantress",
            "Kez": -0.736
        },
        {
            "name": "Enigma",
            "Kez": -0.79
        },
        {
            "name": "Faceless Void",
            "Kez": 0.87
        },
        {
            "name": "Grimstroke",
            "Kez": -0.332
        },
        {
            "name": "Gyrocopter",
            "Kez": -0.144
        },
        {
            "name": "Hoodwink",
            "Kez": -0.031
        },
        {
            "name": "Huskar",
            "Kez": -2.372
        },
        {
            "name": "Invoker",
            "Kez": -1.02
        },
        {
            "name": "Io",
            "Kez": -0.541
        },
        {
            "name": "Jakiro",
            "Kez": 1.156
        },
        {
            "name": "Juggernaut",
            "Kez": -0.923
        },
        {
            "name": "Keeper of the Light",
            "Kez": -0.802
        },
        {
            "name": "Kunkka",
            "Kez": -0.107
        },
        {
            "name": "Legion Commander",
            "Kez": 1.353
        },
        {
            "name": "Leshrac",
            "Kez": 1.22
        },
        {
            "name": "Lich",
            "Kez": -0.71
        },
        {
            "name": "Lifestealer",
            "Kez": -1.089
        },
        {
            "name": "Lina",
            "Kez": -0.177
        },
        {
            "name": "Lion",
            "Kez": 0.896
        },
        {
            "name": "Lone Druid",
            "Kez": -0.815
        },
        {
            "name": "Luna",
            "Kez": -0.236
        },
        {
            "name": "Lycan",
            "Kez": -3.053
        },
        {
            "name": "Magnus",
            "Kez": -0.553
        },
        {
            "name": "Marci",
            "Kez": 1.588
        },
        {
            "name": "Mars",
            "Kez": -2.355
        },
        {
            "name": "Medusa",
            "Kez": 1.157
        },
        {
            "name": "Meepo",
            "Kez": -1.798
        },
        {
            "name": "Mirana",
            "Kez": 0.713
        },
        {
            "name": "Monkey King",
            "Kez": 1.29
        },
        {
            "name": "Morphling",
            "Kez": -1.253
        },
        {
            "name": "Muerta",
            "Kez": 1.726
        },
        {
            "name": "Naga Siren",
            "Kez": 0.773
        },
        {
            "name": "Nature's Prophet",
            "Kez": -1.155
        },
        {
            "name": "Necrophos",
            "Kez": 1.36
        },
        {
            "name": "Night Stalker",
            "Kez": -1.135
        },
        {
            "name": "Nyx Assassin",
            "Kez": -1.636
        },
        {
            "name": "Ogre Magi",
            "Kez": 0.547
        },
        {
            "name": "Omniknight",
            "Kez": 1.063
        },
        {
            "name": "Oracle",
            "Kez": 0.616
        },
        {
            "name": "Outworld Destroyer",
            "Kez": 0.143
        },
        {
            "name": "Pangolier",
            "Kez": -1.305
        },
        {
            "name": "Phantom Assassin",
            "Kez": 0.802
        },
        {
            "name": "Phantom Lancer",
            "Kez": 2.116
        },
        {
            "name": "Phoenix",
            "Kez": -1.125
        },
        {
            "name": "Primal Beast",
            "Kez": 0.524
        },
        {
            "name": "Puck",
            "Kez": -1.65
        },
        {
            "name": "Pudge",
            "Kez": -0.401
        },
        {
            "name": "Pugna",
            "Kez": 0.884
        },
        {
            "name": "Queen of Pain",
            "Kez": -0.73
        },
        {
            "name": "Razor",
            "Kez": 0.286
        },
        {
            "name": "Riki",
            "Kez": 1.002
        },
        {
            "name": "Ringmaster",
            "Kez": -0.121
        },
        {
            "name": "Rubick",
            "Kez": -1.527
        },
        {
            "name": "Sand King",
            "Kez": -1.065
        },
        {
            "name": "Shadow Demon",
            "Kez": -1.583
        },
        {
            "name": "Shadow Fiend",
            "Kez": -0.589
        },
        {
            "name": "Shadow Shaman",
            "Kez": 1.822
        },
        {
            "name": "Silencer",
            "Kez": 0.293
        },
        {
            "name": "Skywrath Mage",
            "Kez": -0.344
        },
        {
            "name": "Slardar",
            "Kez": -0.343
        },
        {
            "name": "Slark",
            "Kez": 0.959
        },
        {
            "name": "Snapfire",
            "Kez": -1.357
        },
        {
            "name": "Sniper",
            "Kez": 0.37
        },
        {
            "name": "Spectre",
            "Kez": -2.68
        },
        {
            "name": "Spirit Breaker",
            "Kez": 1.125
        },
        {
            "name": "Storm Spirit",
            "Kez": -1.832
        },
        {
            "name": "Sven",
            "Kez": 1.838
        },
        {
            "name": "Techies",
            "Kez": 0.482
        },
        {
            "name": "Templar Assassin",
            "Kez": 0.418
        },
        {
            "name": "Terrorblade",
            "Kez": 1.031
        },
        {
            "name": "Tidehunter",
            "Kez": 1.553
        },
        {
            "name": "Timbersaw",
            "Kez": -0.659
        },
        {
            "name": "Tinker",
            "Kez": 2.771
        },
        {
            "name": "Tiny",
            "Kez": -0.671
        },
        {
            "name": "Treant Protector",
            "Kez": 0.045
        },
        {
            "name": "Troll Warlord",
            "Kez": 2.337
        },
        {
            "name": "Tusk",
            "Kez": 0.695
        },
        {
            "name": "Underlord",
            "Kez": 0.556
        },
        {
            "name": "Undying",
            "Kez": -0.793
        },
        {
            "name": "Ursa",
            "Kez": 0.203
        },
        {
            "name": "Vengeful Spirit",
            "Kez": 0.549
        },
        {
            "name": "Venomancer",
            "Kez": -0.807
        },
        {
            "name": "Viper",
            "Kez": -1.56
        },
        {
            "name": "Visage",
            "Kez": -0.199
        },
        {
            "name": "Void Spirit",
            "Kez": -0.725
        },
        {
            "name": "Warlock",
            "Kez": -1.06
        },
        {
            "name": "Weaver",
            "Kez": 0.06
        },
        {
            "name": "Windranger",
            "Kez": 1.985
        },
        {
            "name": "Winter Wyvern",
            "Kez": 2.472
        },
        {
            "name": "Witch Doctor",
            "Kez": -0.164
        },
        {
            "name": "Wraith King",
            "Kez": 0.464
        },
        {
            "name": "Zeus",
            "Kez": 0.021
        }
    ],
    "Luna": [
        {
            "name": "Abaddon",
            "Luna": 0.947
        },
        {
            "name": "Alchemist",
            "Luna": 0.014
        },
        {
            "name": "Ancient Apparition",
            "Luna": 0.769
        },
        {
            "name": "Anti-Mage",
            "Luna": -0.82
        },
        {
            "name": "Arc Warden",
            "Luna": 0.937
        },
        {
            "name": "Axe",
            "Luna": -0.466
        },
        {
            "name": "Bane",
            "Luna": -0.248
        },
        {
            "name": "Batrider",
            "Luna": 0.319
        },
        {
            "name": "Beastmaster",
            "Luna": 0.255
        },
        {
            "name": "Bloodseeker",
            "Luna": -0.59
        },
        {
            "name": "Bounty Hunter",
            "Luna": 0.586
        },
        {
            "name": "Brewmaster",
            "Luna": -1.903
        },
        {
            "name": "Bristleback",
            "Luna": 2.566
        },
        {
            "name": "Broodmother",
            "Luna": 1.494
        },
        {
            "name": "Centaur Warrunner",
            "Luna": 1.605
        },
        {
            "name": "Chaos Knight",
            "Luna": -0.592
        },
        {
            "name": "Chen",
            "Luna": 0.442
        },
        {
            "name": "Clinkz",
            "Luna": -1.361
        },
        {
            "name": "Clockwerk",
            "Luna": 0.531
        },
        {
            "name": "Crystal Maiden",
            "Luna": -0.487
        },
        {
            "name": "Dark Seer",
            "Luna": 2.23
        },
        {
            "name": "Dark Willow",
            "Luna": 0.469
        },
        {
            "name": "Dawnbreaker",
            "Luna": 0.189
        },
        {
            "name": "Dazzle",
            "Luna": -0.375
        },
        {
            "name": "Death Prophet",
            "Luna": -1.779
        },
        {
            "name": "Disruptor",
            "Luna": -1.282
        },
        {
            "name": "Doom",
            "Luna": -1.311
        },
        {
            "name": "Dragon Knight",
            "Luna": 0.834
        },
        {
            "name": "Drow Ranger",
            "Luna": -0.525
        },
        {
            "name": "Earth Spirit",
            "Luna": -1.006
        },
        {
            "name": "Earthshaker",
            "Luna": -0.657
        },
        {
            "name": "Elder Titan",
            "Luna": -1.164
        },
        {
            "name": "Ember Spirit",
            "Luna": -0.225
        },
        {
            "name": "Enchantress",
            "Luna": -0.018
        },
        {
            "name": "Enigma",
            "Luna": -0.767
        },
        {
            "name": "Faceless Void",
            "Luna": -0.275
        },
        {
            "name": "Grimstroke",
            "Luna": -0.456
        },
        {
            "name": "Gyrocopter",
            "Luna": -0.166
        },
        {
            "name": "Hoodwink",
            "Luna": 0.958
        },
        {
            "name": "Huskar",
            "Luna": -1.862
        },
        {
            "name": "Invoker",
            "Luna": 0.906
        },
        {
            "name": "Io",
            "Luna": -0.615
        },
        {
            "name": "Jakiro",
            "Luna": 1.552
        },
        {
            "name": "Juggernaut",
            "Luna": 0.474
        },
        {
            "name": "Keeper of the Light",
            "Luna": -0.433
        },
        {
            "name": "Kez",
            "Luna": 0.208
        },
        {
            "name": "Kunkka",
            "Luna": 0.982
        },
        {
            "name": "Legion Commander",
            "Luna": -0.712
        },
        {
            "name": "Leshrac",
            "Luna": 0.306
        },
        {
            "name": "Lich",
            "Luna": 0.87
        },
        {
            "name": "Lifestealer",
            "Luna": 0.031
        },
        {
            "name": "Lina",
            "Luna": -0.122
        },
        {
            "name": "Lion",
            "Luna": -0.519
        },
        {
            "name": "Lone Druid",
            "Luna": -1.978
        },
        {
            "name": "Lycan",
            "Luna": -0.707
        },
        {
            "name": "Magnus",
            "Luna": -0.177
        },
        {
            "name": "Marci",
            "Luna": -1.265
        },
        {
            "name": "Mars",
            "Luna": 1.198
        },
        {
            "name": "Medusa",
            "Luna": 1.988
        },
        {
            "name": "Meepo",
            "Luna": -1.03
        },
        {
            "name": "Mirana",
            "Luna": -0.79
        },
        {
            "name": "Monkey King",
            "Luna": -1.255
        },
        {
            "name": "Morphling",
            "Luna": -0.239
        },
        {
            "name": "Muerta",
            "Luna": -0.011
        },
        {
            "name": "Naga Siren",
            "Luna": -2.471
        },
        {
            "name": "Nature's Prophet",
            "Luna": -0.824
        },
        {
            "name": "Necrophos",
            "Luna": 0.066
        },
        {
            "name": "Night Stalker",
            "Luna": -2.862
        },
        {
            "name": "Nyx Assassin",
            "Luna": 1.674
        },
        {
            "name": "Ogre Magi",
            "Luna": 0.03
        },
        {
            "name": "Omniknight",
            "Luna": -0.601
        },
        {
            "name": "Oracle",
            "Luna": 0.072
        },
        {
            "name": "Outworld Destroyer",
            "Luna": -0.757
        },
        {
            "name": "Pangolier",
            "Luna": -2.234
        },
        {
            "name": "Phantom Assassin",
            "Luna": -0.428
        },
        {
            "name": "Phantom Lancer",
            "Luna": 1.265
        },
        {
            "name": "Phoenix",
            "Luna": 0.726
        },
        {
            "name": "Primal Beast",
            "Luna": 0.092
        },
        {
            "name": "Puck",
            "Luna": -1.388
        },
        {
            "name": "Pudge",
            "Luna": 1.333
        },
        {
            "name": "Pugna",
            "Luna": -0.743
        },
        {
            "name": "Queen of Pain",
            "Luna": 0.507
        },
        {
            "name": "Razor",
            "Luna": -0.824
        },
        {
            "name": "Riki",
            "Luna": -1.596
        },
        {
            "name": "Ringmaster",
            "Luna": -0.071
        },
        {
            "name": "Rubick",
            "Luna": 1.16
        },
        {
            "name": "Sand King",
            "Luna": 1.554
        },
        {
            "name": "Shadow Demon",
            "Luna": 0.699
        },
        {
            "name": "Shadow Fiend",
            "Luna": -1.504
        },
        {
            "name": "Shadow Shaman",
            "Luna": -1.555
        },
        {
            "name": "Silencer",
            "Luna": -0.833
        },
        {
            "name": "Skywrath Mage",
            "Luna": -0.351
        },
        {
            "name": "Slardar",
            "Luna": -2.827
        },
        {
            "name": "Slark",
            "Luna": 1.491
        },
        {
            "name": "Snapfire",
            "Luna": -0.369
        },
        {
            "name": "Sniper",
            "Luna": 0.598
        },
        {
            "name": "Spectre",
            "Luna": 1.414
        },
        {
            "name": "Spirit Breaker",
            "Luna": 0.318
        },
        {
            "name": "Storm Spirit",
            "Luna": -0.303
        },
        {
            "name": "Sven",
            "Luna": -0.984
        },
        {
            "name": "Techies",
            "Luna": 0.833
        },
        {
            "name": "Templar Assassin",
            "Luna": -0.779
        },
        {
            "name": "Terrorblade",
            "Luna": 1.561
        },
        {
            "name": "Tidehunter",
            "Luna": 0.568
        },
        {
            "name": "Timbersaw",
            "Luna": 0.134
        },
        {
            "name": "Tinker",
            "Luna": -0.743
        },
        {
            "name": "Tiny",
            "Luna": -0.996
        },
        {
            "name": "Treant Protector",
            "Luna": 1.218
        },
        {
            "name": "Troll Warlord",
            "Luna": -0.184
        },
        {
            "name": "Tusk",
            "Luna": -1.543
        },
        {
            "name": "Underlord",
            "Luna": 1.453
        },
        {
            "name": "Undying",
            "Luna": -2.764
        },
        {
            "name": "Ursa",
            "Luna": -0.682
        },
        {
            "name": "Vengeful Spirit",
            "Luna": -0.878
        },
        {
            "name": "Venomancer",
            "Luna": 0.436
        },
        {
            "name": "Viper",
            "Luna": 0.527
        },
        {
            "name": "Visage",
            "Luna": -1.66
        },
        {
            "name": "Void Spirit",
            "Luna": -0.729
        },
        {
            "name": "Warlock",
            "Luna": 1.401
        },
        {
            "name": "Weaver",
            "Luna": -1.604
        },
        {
            "name": "Windranger",
            "Luna": -0.729
        },
        {
            "name": "Winter Wyvern",
            "Luna": -0.016
        },
        {
            "name": "Witch Doctor",
            "Luna": 1.308
        },
        {
            "name": "Wraith King",
            "Luna": 0.545
        },
        {
            "name": "Zeus",
            "Luna": 1.541
        }
    ],
    "Medusa": [
        {
            "name": "Abaddon",
            "Medusa": -1.026
        },
        {
            "name": "Alchemist",
            "Medusa": 4.528
        },
        {
            "name": "Ancient Apparition",
            "Medusa": -1.991
        },
        {
            "name": "Anti-Mage",
            "Medusa": 10.861
        },
        {
            "name": "Arc Warden",
            "Medusa": -0.18
        },
        {
            "name": "Axe",
            "Medusa": 1.245
        },
        {
            "name": "Bane",
            "Medusa": 0.376
        },
        {
            "name": "Batrider",
            "Medusa": 0.904
        },
        {
            "name": "Beastmaster",
            "Medusa": -6.444
        },
        {
            "name": "Bloodseeker",
            "Medusa": -6.596
        },
        {
            "name": "Bounty Hunter",
            "Medusa": 0.534
        },
        {
            "name": "Brewmaster",
            "Medusa": 0.344
        },
        {
            "name": "Bristleback",
            "Medusa": 0.993
        },
        {
            "name": "Broodmother",
            "Medusa": 0.178
        },
        {
            "name": "Centaur Warrunner",
            "Medusa": 4.348
        },
        {
            "name": "Chaos Knight",
            "Medusa": -3.183
        },
        {
            "name": "Chen",
            "Medusa": 3.524
        },
        {
            "name": "Clinkz",
            "Medusa": -0.958
        },
        {
            "name": "Clockwerk",
            "Medusa": 1.212
        },
        {
            "name": "Crystal Maiden",
            "Medusa": -0.083
        },
        {
            "name": "Dark Seer",
            "Medusa": 7.113
        },
        {
            "name": "Dark Willow",
            "Medusa": 0.245
        },
        {
            "name": "Dawnbreaker",
            "Medusa": 0.277
        },
        {
            "name": "Dazzle",
            "Medusa": 2.124
        },
        {
            "name": "Death Prophet",
            "Medusa": -1.87
        },
        {
            "name": "Disruptor",
            "Medusa": -0.462
        },
        {
            "name": "Doom",
            "Medusa": -1.858
        },
        {
            "name": "Dragon Knight",
            "Medusa": 0.94
        },
        {
            "name": "Drow Ranger",
            "Medusa": 0.156
        },
        {
            "name": "Earth Spirit",
            "Medusa": 0.419
        },
        {
            "name": "Earthshaker",
            "Medusa": 0.451
        },
        {
            "name": "Elder Titan",
            "Medusa": -1.662
        },
        {
            "name": "Ember Spirit",
            "Medusa": 1.686
        },
        {
            "name": "Enchantress",
            "Medusa": -1.221
        },
        {
            "name": "Enigma",
            "Medusa": -3.676
        },
        {
            "name": "Faceless Void",
            "Medusa": 0.519
        },
        {
            "name": "Grimstroke",
            "Medusa": -1.811
        },
        {
            "name": "Gyrocopter",
            "Medusa": -0.227
        },
        {
            "name": "Hoodwink",
            "Medusa": 0.938
        },
        {
            "name": "Huskar",
            "Medusa": -1.718
        },
        {
            "name": "Invoker",
            "Medusa": 2.614
        },
        {
            "name": "Io",
            "Medusa": -0.377
        },
        {
            "name": "Jakiro",
            "Medusa": 1.399
        },
        {
            "name": "Juggernaut",
            "Medusa": 0.583
        },
        {
            "name": "Keeper of the Light",
            "Medusa": -0.422
        },
        {
            "name": "Kez",
            "Medusa": -1.014
        },
        {
            "name": "Kunkka",
            "Medusa": 2.703
        },
        {
            "name": "Legion Commander",
            "Medusa": -2.22
        },
        {
            "name": "Leshrac",
            "Medusa": -3.206
        },
        {
            "name": "Lich",
            "Medusa": 0.794
        },
        {
            "name": "Lifestealer",
            "Medusa": -1.332
        },
        {
            "name": "Lina",
            "Medusa": -0.652
        },
        {
            "name": "Lion",
            "Medusa": 0.549
        },
        {
            "name": "Lone Druid",
            "Medusa": 1.208
        },
        {
            "name": "Luna",
            "Medusa": -1.818
        },
        {
            "name": "Lycan",
            "Medusa": 1.703
        },
        {
            "name": "Magnus",
            "Medusa": -0.435
        },
        {
            "name": "Marci",
            "Medusa": -0.995
        },
        {
            "name": "Mars",
            "Medusa": 1.342
        },
        {
            "name": "Meepo",
            "Medusa": -1.041
        },
        {
            "name": "Mirana",
            "Medusa": 0.731
        },
        {
            "name": "Monkey King",
            "Medusa": 3.556
        },
        {
            "name": "Morphling",
            "Medusa": -0.946
        },
        {
            "name": "Muerta",
            "Medusa": -0.747
        },
        {
            "name": "Naga Siren",
            "Medusa": -2.351
        },
        {
            "name": "Nature's Prophet",
            "Medusa": -0.661
        },
        {
            "name": "Necrophos",
            "Medusa": -1.687
        },
        {
            "name": "Night Stalker",
            "Medusa": 0.197
        },
        {
            "name": "Nyx Assassin",
            "Medusa": 2.922
        },
        {
            "name": "Ogre Magi",
            "Medusa": 0.555
        },
        {
            "name": "Omniknight",
            "Medusa": -0.713
        },
        {
            "name": "Oracle",
            "Medusa": 0.463
        },
        {
            "name": "Outworld Destroyer",
            "Medusa": -2.304
        },
        {
            "name": "Pangolier",
            "Medusa": 3.175
        },
        {
            "name": "Phantom Assassin",
            "Medusa": -0.537
        },
        {
            "name": "Phantom Lancer",
            "Medusa": -0.681
        },
        {
            "name": "Phoenix",
            "Medusa": 0.194
        },
        {
            "name": "Primal Beast",
            "Medusa": -0.293
        },
        {
            "name": "Puck",
            "Medusa": -1.281
        },
        {
            "name": "Pudge",
            "Medusa": -0.252
        },
        {
            "name": "Pugna",
            "Medusa": -1.551
        },
        {
            "name": "Queen of Pain",
            "Medusa": -4.121
        },
        {
            "name": "Razor",
            "Medusa": 0.159
        },
        {
            "name": "Riki",
            "Medusa": 1.334
        },
        {
            "name": "Ringmaster",
            "Medusa": 0.195
        },
        {
            "name": "Rubick",
            "Medusa": 1.093
        },
        {
            "name": "Sand King",
            "Medusa": 2.366
        },
        {
            "name": "Shadow Demon",
            "Medusa": 3.949
        },
        {
            "name": "Shadow Fiend",
            "Medusa": -1.69
        },
        {
            "name": "Shadow Shaman",
            "Medusa": -2.31
        },
        {
            "name": "Silencer",
            "Medusa": -1.854
        },
        {
            "name": "Skywrath Mage",
            "Medusa": -2.497
        },
        {
            "name": "Slardar",
            "Medusa": -1.89
        },
        {
            "name": "Slark",
            "Medusa": 4.366
        },
        {
            "name": "Snapfire",
            "Medusa": 2.002
        },
        {
            "name": "Sniper",
            "Medusa": 3.941
        },
        {
            "name": "Spectre",
            "Medusa": -2.216
        },
        {
            "name": "Spirit Breaker",
            "Medusa": -0.142
        },
        {
            "name": "Storm Spirit",
            "Medusa": -0.371
        },
        {
            "name": "Sven",
            "Medusa": -0.919
        },
        {
            "name": "Techies",
            "Medusa": -1.406
        },
        {
            "name": "Templar Assassin",
            "Medusa": -1.936
        },
        {
            "name": "Terrorblade",
            "Medusa": 1.896
        },
        {
            "name": "Tidehunter",
            "Medusa": 3.04
        },
        {
            "name": "Timbersaw",
            "Medusa": -2.69
        },
        {
            "name": "Tinker",
            "Medusa": -1.359
        },
        {
            "name": "Tiny",
            "Medusa": 2.189
        },
        {
            "name": "Treant Protector",
            "Medusa": 1.696
        },
        {
            "name": "Troll Warlord",
            "Medusa": -1.443
        },
        {
            "name": "Tusk",
            "Medusa": 0.282
        },
        {
            "name": "Underlord",
            "Medusa": 3.152
        },
        {
            "name": "Undying",
            "Medusa": -5.106
        },
        {
            "name": "Ursa",
            "Medusa": 0.187
        },
        {
            "name": "Vengeful Spirit",
            "Medusa": -0.436
        },
        {
            "name": "Venomancer",
            "Medusa": -0.98
        },
        {
            "name": "Viper",
            "Medusa": -1.916
        },
        {
            "name": "Visage",
            "Medusa": -2.348
        },
        {
            "name": "Void Spirit",
            "Medusa": -0.645
        },
        {
            "name": "Warlock",
            "Medusa": 0.541
        },
        {
            "name": "Weaver",
            "Medusa": -0.857
        },
        {
            "name": "Windranger",
            "Medusa": 1.303
        },
        {
            "name": "Winter Wyvern",
            "Medusa": 2.046
        },
        {
            "name": "Witch Doctor",
            "Medusa": -3.547
        },
        {
            "name": "Wraith King",
            "Medusa": -0.75
        },
        {
            "name": "Zeus",
            "Medusa": -1.074
        }
    ],
    "Meepo": [
        {
            "name": "Abaddon",
            "Meepo": -3.04
        },
        {
            "name": "Alchemist",
            "Meepo": 1.243
        },
        {
            "name": "Ancient Apparition",
            "Meepo": 1.081
        },
        {
            "name": "Anti-Mage",
            "Meepo": -7.704
        },
        {
            "name": "Arc Warden",
            "Meepo": -6.884
        },
        {
            "name": "Axe",
            "Meepo": 1.63
        },
        {
            "name": "Bane",
            "Meepo": -4.618
        },
        {
            "name": "Batrider",
            "Meepo": 1.423
        },
        {
            "name": "Beastmaster",
            "Meepo": 2.301
        },
        {
            "name": "Bloodseeker",
            "Meepo": 2.029
        },
        {
            "name": "Bounty Hunter",
            "Meepo": -2.946
        },
        {
            "name": "Brewmaster",
            "Meepo": 1.932
        },
        {
            "name": "Bristleback",
            "Meepo": 2.97
        },
        {
            "name": "Broodmother",
            "Meepo": -4.088
        },
        {
            "name": "Centaur Warrunner",
            "Meepo": -0.454
        },
        {
            "name": "Chaos Knight",
            "Meepo": -1.369
        },
        {
            "name": "Chen",
            "Meepo": 1.752
        },
        {
            "name": "Clinkz",
            "Meepo": -2.661
        },
        {
            "name": "Clockwerk",
            "Meepo": -3.748
        },
        {
            "name": "Crystal Maiden",
            "Meepo": 3.413
        },
        {
            "name": "Dark Seer",
            "Meepo": -1.894
        },
        {
            "name": "Dark Willow",
            "Meepo": 0.3
        },
        {
            "name": "Dawnbreaker",
            "Meepo": 1.504
        },
        {
            "name": "Dazzle",
            "Meepo": 0.816
        },
        {
            "name": "Death Prophet",
            "Meepo": 2.364
        },
        {
            "name": "Disruptor",
            "Meepo": 1.645
        },
        {
            "name": "Doom",
            "Meepo": -2.699
        },
        {
            "name": "Dragon Knight",
            "Meepo": 1.113
        },
        {
            "name": "Drow Ranger",
            "Meepo": 1.736
        },
        {
            "name": "Earth Spirit",
            "Meepo": -3.493
        },
        {
            "name": "Earthshaker",
            "Meepo": 8.485
        },
        {
            "name": "Elder Titan",
            "Meepo": 7.965
        },
        {
            "name": "Ember Spirit",
            "Meepo": -5.893
        },
        {
            "name": "Enchantress",
            "Meepo": 1.495
        },
        {
            "name": "Enigma",
            "Meepo": 2.95
        },
        {
            "name": "Faceless Void",
            "Meepo": -4.321
        },
        {
            "name": "Grimstroke",
            "Meepo": -1.242
        },
        {
            "name": "Gyrocopter",
            "Meepo": 1.057
        },
        {
            "name": "Hoodwink",
            "Meepo": 0.958
        },
        {
            "name": "Huskar",
            "Meepo": 2.911
        },
        {
            "name": "Invoker",
            "Meepo": -1.837
        },
        {
            "name": "Io",
            "Meepo": 1.034
        },
        {
            "name": "Jakiro",
            "Meepo": 5.126
        },
        {
            "name": "Juggernaut",
            "Meepo": -1.058
        },
        {
            "name": "Keeper of the Light",
            "Meepo": -0.672
        },
        {
            "name": "Kez",
            "Meepo": 1.778
        },
        {
            "name": "Kunkka",
            "Meepo": 3.846
        },
        {
            "name": "Legion Commander",
            "Meepo": 2.017
        },
        {
            "name": "Leshrac",
            "Meepo": 5.064
        },
        {
            "name": "Lich",
            "Meepo": 1.797
        },
        {
            "name": "Lifestealer",
            "Meepo": -1.236
        },
        {
            "name": "Lina",
            "Meepo": 4.265
        },
        {
            "name": "Lion",
            "Meepo": 0.995
        },
        {
            "name": "Lone Druid",
            "Meepo": -1.043
        },
        {
            "name": "Luna",
            "Meepo": 0.947
        },
        {
            "name": "Lycan",
            "Meepo": -0.829
        },
        {
            "name": "Magnus",
            "Meepo": 0.48
        },
        {
            "name": "Marci",
            "Meepo": 0.797
        },
        {
            "name": "Mars",
            "Meepo": -2.251
        },
        {
            "name": "Medusa",
            "Meepo": 1.137
        },
        {
            "name": "Mirana",
            "Meepo": -2.22
        },
        {
            "name": "Monkey King",
            "Meepo": 4.21
        },
        {
            "name": "Morphling",
            "Meepo": -2.938
        },
        {
            "name": "Muerta",
            "Meepo": 0.832
        },
        {
            "name": "Naga Siren",
            "Meepo": 0.885
        },
        {
            "name": "Nature's Prophet",
            "Meepo": -2.031
        },
        {
            "name": "Necrophos",
            "Meepo": 2.334
        },
        {
            "name": "Night Stalker",
            "Meepo": -4.359
        },
        {
            "name": "Nyx Assassin",
            "Meepo": 0.043
        },
        {
            "name": "Ogre Magi",
            "Meepo": -2.041
        },
        {
            "name": "Omniknight",
            "Meepo": 3.585
        },
        {
            "name": "Oracle",
            "Meepo": -2.633
        },
        {
            "name": "Outworld Destroyer",
            "Meepo": 0.553
        },
        {
            "name": "Pangolier",
            "Meepo": 0.729
        },
        {
            "name": "Phantom Assassin",
            "Meepo": -1.796
        },
        {
            "name": "Phantom Lancer",
            "Meepo": -3.471
        },
        {
            "name": "Phoenix",
            "Meepo": -0.235
        },
        {
            "name": "Primal Beast",
            "Meepo": 0.117
        },
        {
            "name": "Puck",
            "Meepo": -1.88
        },
        {
            "name": "Pudge",
            "Meepo": -0.392
        },
        {
            "name": "Pugna",
            "Meepo": -0.253
        },
        {
            "name": "Queen of Pain",
            "Meepo": -1.953
        },
        {
            "name": "Razor",
            "Meepo": 0.255
        },
        {
            "name": "Riki",
            "Meepo": 0.064
        },
        {
            "name": "Ringmaster",
            "Meepo": 2.919
        },
        {
            "name": "Rubick",
            "Meepo": -0.074
        },
        {
            "name": "Sand King",
            "Meepo": 1.47
        },
        {
            "name": "Shadow Demon",
            "Meepo": -3.716
        },
        {
            "name": "Shadow Fiend",
            "Meepo": 5.433
        },
        {
            "name": "Shadow Shaman",
            "Meepo": -2.297
        },
        {
            "name": "Silencer",
            "Meepo": -0.722
        },
        {
            "name": "Skywrath Mage",
            "Meepo": -0.793
        },
        {
            "name": "Slardar",
            "Meepo": -0.673
        },
        {
            "name": "Slark",
            "Meepo": 1.062
        },
        {
            "name": "Snapfire",
            "Meepo": 2.283
        },
        {
            "name": "Sniper",
            "Meepo": -2.132
        },
        {
            "name": "Spectre",
            "Meepo": -3.932
        },
        {
            "name": "Spirit Breaker",
            "Meepo": -1.813
        },
        {
            "name": "Storm Spirit",
            "Meepo": -5.101
        },
        {
            "name": "Sven",
            "Meepo": 4.809
        },
        {
            "name": "Techies",
            "Meepo": 2.705
        },
        {
            "name": "Templar Assassin",
            "Meepo": -0.309
        },
        {
            "name": "Terrorblade",
            "Meepo": 0.862
        },
        {
            "name": "Tidehunter",
            "Meepo": 1.55
        },
        {
            "name": "Timbersaw",
            "Meepo": 5.147
        },
        {
            "name": "Tinker",
            "Meepo": 2.328
        },
        {
            "name": "Tiny",
            "Meepo": 0.375
        },
        {
            "name": "Treant Protector",
            "Meepo": 2.112
        },
        {
            "name": "Troll Warlord",
            "Meepo": 6.033
        },
        {
            "name": "Tusk",
            "Meepo": -0.427
        },
        {
            "name": "Underlord",
            "Meepo": 5.948
        },
        {
            "name": "Undying",
            "Meepo": -2.044
        },
        {
            "name": "Ursa",
            "Meepo": -0.235
        },
        {
            "name": "Vengeful Spirit",
            "Meepo": -0.691
        },
        {
            "name": "Venomancer",
            "Meepo": 0.175
        },
        {
            "name": "Viper",
            "Meepo": 0.557
        },
        {
            "name": "Visage",
            "Meepo": 1.538
        },
        {
            "name": "Void Spirit",
            "Meepo": -5.833
        },
        {
            "name": "Warlock",
            "Meepo": 2.595
        },
        {
            "name": "Weaver",
            "Meepo": -0.676
        },
        {
            "name": "Windranger",
            "Meepo": 0.328
        },
        {
            "name": "Winter Wyvern",
            "Meepo": 8.096
        },
        {
            "name": "Witch Doctor",
            "Meepo": 2.134
        },
        {
            "name": "Wraith King",
            "Meepo": -3.072
        },
        {
            "name": "Zeus",
            "Meepo": -3.878
        }
    ],
    "Monkey King": [
        {
            "name": "Abaddon",
            "Monkey King": -2.617
        },
        {
            "name": "Alchemist",
            "Monkey King": -1.192
        },
        {
            "name": "Ancient Apparition",
            "Monkey King": 1.885
        },
        {
            "name": "Anti-Mage",
            "Monkey King": -2.198
        },
        {
            "name": "Arc Warden",
            "Monkey King": 0.554
        },
        {
            "name": "Axe",
            "Monkey King": 0.183
        },
        {
            "name": "Bane",
            "Monkey King": -0.262
        },
        {
            "name": "Batrider",
            "Monkey King": 2.516
        },
        {
            "name": "Beastmaster",
            "Monkey King": 1.518
        },
        {
            "name": "Bloodseeker",
            "Monkey King": -0.464
        },
        {
            "name": "Bounty Hunter",
            "Monkey King": 0.393
        },
        {
            "name": "Brewmaster",
            "Monkey King": 0.451
        },
        {
            "name": "Bristleback",
            "Monkey King": -0.343
        },
        {
            "name": "Broodmother",
            "Monkey King": -1.842
        },
        {
            "name": "Centaur Warrunner",
            "Monkey King": -1.123
        },
        {
            "name": "Chaos Knight",
            "Monkey King": -0.103
        },
        {
            "name": "Chen",
            "Monkey King": -2.112
        },
        {
            "name": "Clinkz",
            "Monkey King": 1.262
        },
        {
            "name": "Clockwerk",
            "Monkey King": -0.282
        },
        {
            "name": "Crystal Maiden",
            "Monkey King": -0.748
        },
        {
            "name": "Dark Seer",
            "Monkey King": -0.711
        },
        {
            "name": "Dark Willow",
            "Monkey King": 0.693
        },
        {
            "name": "Dawnbreaker",
            "Monkey King": -1.476
        },
        {
            "name": "Dazzle",
            "Monkey King": -1.941
        },
        {
            "name": "Death Prophet",
            "Monkey King": -0.436
        },
        {
            "name": "Disruptor",
            "Monkey King": -0.882
        },
        {
            "name": "Doom",
            "Monkey King": 0.353
        },
        {
            "name": "Dragon Knight",
            "Monkey King": -0.119
        },
        {
            "name": "Drow Ranger",
            "Monkey King": -0.279
        },
        {
            "name": "Earth Spirit",
            "Monkey King": -0.962
        },
        {
            "name": "Earthshaker",
            "Monkey King": 0.281
        },
        {
            "name": "Elder Titan",
            "Monkey King": -0.217
        },
        {
            "name": "Ember Spirit",
            "Monkey King": -4.843
        },
        {
            "name": "Enchantress",
            "Monkey King": 0.581
        },
        {
            "name": "Enigma",
            "Monkey King": 0.997
        },
        {
            "name": "Faceless Void",
            "Monkey King": -0.15
        },
        {
            "name": "Grimstroke",
            "Monkey King": -0.202
        },
        {
            "name": "Gyrocopter",
            "Monkey King": -0.126
        },
        {
            "name": "Hoodwink",
            "Monkey King": 1.671
        },
        {
            "name": "Huskar",
            "Monkey King": -0.873
        },
        {
            "name": "Invoker",
            "Monkey King": 1.339
        },
        {
            "name": "Io",
            "Monkey King": -2.844
        },
        {
            "name": "Jakiro",
            "Monkey King": 0.364
        },
        {
            "name": "Juggernaut",
            "Monkey King": 0.562
        },
        {
            "name": "Keeper of the Light",
            "Monkey King": 0.665
        },
        {
            "name": "Kez",
            "Monkey King": -1.197
        },
        {
            "name": "Kunkka",
            "Monkey King": -0.521
        },
        {
            "name": "Legion Commander",
            "Monkey King": -2.197
        },
        {
            "name": "Leshrac",
            "Monkey King": 0.239
        },
        {
            "name": "Lich",
            "Monkey King": 1.086
        },
        {
            "name": "Lifestealer",
            "Monkey King": -1.266
        },
        {
            "name": "Lina",
            "Monkey King": 0.992
        },
        {
            "name": "Lion",
            "Monkey King": 0.341
        },
        {
            "name": "Lone Druid",
            "Monkey King": -4.086
        },
        {
            "name": "Luna",
            "Monkey King": 1.18
        },
        {
            "name": "Lycan",
            "Monkey King": -1.001
        },
        {
            "name": "Magnus",
            "Monkey King": 0.244
        },
        {
            "name": "Marci",
            "Monkey King": -1.834
        },
        {
            "name": "Mars",
            "Monkey King": -0.461
        },
        {
            "name": "Medusa",
            "Monkey King": -3.621
        },
        {
            "name": "Meepo",
            "Monkey King": -4.321
        },
        {
            "name": "Mirana",
            "Monkey King": 0.714
        },
        {
            "name": "Morphling",
            "Monkey King": 0.168
        },
        {
            "name": "Muerta",
            "Monkey King": 2.177
        },
        {
            "name": "Naga Siren",
            "Monkey King": 0.224
        },
        {
            "name": "Nature's Prophet",
            "Monkey King": -1.063
        },
        {
            "name": "Necrophos",
            "Monkey King": -0.237
        },
        {
            "name": "Night Stalker",
            "Monkey King": -0.518
        },
        {
            "name": "Nyx Assassin",
            "Monkey King": 0.942
        },
        {
            "name": "Ogre Magi",
            "Monkey King": 1.464
        },
        {
            "name": "Omniknight",
            "Monkey King": -0.59
        },
        {
            "name": "Oracle",
            "Monkey King": -1.306
        },
        {
            "name": "Outworld Destroyer",
            "Monkey King": 1.083
        },
        {
            "name": "Pangolier",
            "Monkey King": -3.757
        },
        {
            "name": "Phantom Assassin",
            "Monkey King": 0.534
        },
        {
            "name": "Phantom Lancer",
            "Monkey King": 1.073
        },
        {
            "name": "Phoenix",
            "Monkey King": 2.186
        },
        {
            "name": "Primal Beast",
            "Monkey King": 0.814
        },
        {
            "name": "Puck",
            "Monkey King": -1.378
        },
        {
            "name": "Pudge",
            "Monkey King": -1.458
        },
        {
            "name": "Pugna",
            "Monkey King": 0.234
        },
        {
            "name": "Queen of Pain",
            "Monkey King": 1.683
        },
        {
            "name": "Razor",
            "Monkey King": -0.21
        },
        {
            "name": "Riki",
            "Monkey King": -0.662
        },
        {
            "name": "Ringmaster",
            "Monkey King": 0.29
        },
        {
            "name": "Rubick",
            "Monkey King": -0.027
        },
        {
            "name": "Sand King",
            "Monkey King": 0.474
        },
        {
            "name": "Shadow Demon",
            "Monkey King": 0.511
        },
        {
            "name": "Shadow Fiend",
            "Monkey King": 0.274
        },
        {
            "name": "Shadow Shaman",
            "Monkey King": -0.226
        },
        {
            "name": "Silencer",
            "Monkey King": -0.849
        },
        {
            "name": "Skywrath Mage",
            "Monkey King": 0.325
        },
        {
            "name": "Slardar",
            "Monkey King": -0.521
        },
        {
            "name": "Slark",
            "Monkey King": 0.094
        },
        {
            "name": "Snapfire",
            "Monkey King": 1.255
        },
        {
            "name": "Sniper",
            "Monkey King": 1.245
        },
        {
            "name": "Spectre",
            "Monkey King": 0.341
        },
        {
            "name": "Spirit Breaker",
            "Monkey King": 0.561
        },
        {
            "name": "Storm Spirit",
            "Monkey King": 1.049
        },
        {
            "name": "Sven",
            "Monkey King": -1.587
        },
        {
            "name": "Techies",
            "Monkey King": 2.842
        },
        {
            "name": "Templar Assassin",
            "Monkey King": -0.402
        },
        {
            "name": "Terrorblade",
            "Monkey King": 1.416
        },
        {
            "name": "Tidehunter",
            "Monkey King": -1.967
        },
        {
            "name": "Timbersaw",
            "Monkey King": 1.536
        },
        {
            "name": "Tinker",
            "Monkey King": 0.819
        },
        {
            "name": "Tiny",
            "Monkey King": -0.625
        },
        {
            "name": "Treant Protector",
            "Monkey King": 0.58
        },
        {
            "name": "Troll Warlord",
            "Monkey King": 0.422
        },
        {
            "name": "Tusk",
            "Monkey King": -0.64
        },
        {
            "name": "Underlord",
            "Monkey King": -1.474
        },
        {
            "name": "Undying",
            "Monkey King": -0.859
        },
        {
            "name": "Ursa",
            "Monkey King": 0.042
        },
        {
            "name": "Vengeful Spirit",
            "Monkey King": -0.387
        },
        {
            "name": "Venomancer",
            "Monkey King": 1.346
        },
        {
            "name": "Viper",
            "Monkey King": 1.507
        },
        {
            "name": "Visage",
            "Monkey King": 0.318
        },
        {
            "name": "Void Spirit",
            "Monkey King": -2.322
        },
        {
            "name": "Warlock",
            "Monkey King": -1.439
        },
        {
            "name": "Weaver",
            "Monkey King": -0.006
        },
        {
            "name": "Windranger",
            "Monkey King": 1.669
        },
        {
            "name": "Winter Wyvern",
            "Monkey King": -1.204
        },
        {
            "name": "Witch Doctor",
            "Monkey King": 0.829
        },
        {
            "name": "Wraith King",
            "Monkey King": -1.518
        },
        {
            "name": "Zeus",
            "Monkey King": 1.492
        }
    ],
    "Morphling": [
        {
            "name": "Abaddon",
            "Morphling": 0.346
        },
        {
            "name": "Alchemist",
            "Morphling": 0.002
        },
        {
            "name": "Ancient Apparition",
            "Morphling": 1.747
        },
        {
            "name": "Anti-Mage",
            "Morphling": 1.223
        },
        {
            "name": "Arc Warden",
            "Morphling": 0.07
        },
        {
            "name": "Axe",
            "Morphling": 2.463
        },
        {
            "name": "Bane",
            "Morphling": 1.651
        },
        {
            "name": "Batrider",
            "Morphling": 0.875
        },
        {
            "name": "Beastmaster",
            "Morphling": 1.529
        },
        {
            "name": "Bloodseeker",
            "Morphling": -0.746
        },
        {
            "name": "Bounty Hunter",
            "Morphling": 1.022
        },
        {
            "name": "Brewmaster",
            "Morphling": -0.753
        },
        {
            "name": "Bristleback",
            "Morphling": -1.626
        },
        {
            "name": "Broodmother",
            "Morphling": 2.217
        },
        {
            "name": "Centaur Warrunner",
            "Morphling": 1.019
        },
        {
            "name": "Chaos Knight",
            "Morphling": 1.149
        },
        {
            "name": "Chen",
            "Morphling": 0.745
        },
        {
            "name": "Clinkz",
            "Morphling": -2.833
        },
        {
            "name": "Clockwerk",
            "Morphling": -2.155
        },
        {
            "name": "Crystal Maiden",
            "Morphling": 0.591
        },
        {
            "name": "Dark Seer",
            "Morphling": 0.375
        },
        {
            "name": "Dark Willow",
            "Morphling": -0.33
        },
        {
            "name": "Dawnbreaker",
            "Morphling": -1.626
        },
        {
            "name": "Dazzle",
            "Morphling": -1.476
        },
        {
            "name": "Death Prophet",
            "Morphling": -0.437
        },
        {
            "name": "Disruptor",
            "Morphling": -0.611
        },
        {
            "name": "Doom",
            "Morphling": 2.916
        },
        {
            "name": "Dragon Knight",
            "Morphling": 1.395
        },
        {
            "name": "Drow Ranger",
            "Morphling": 0.418
        },
        {
            "name": "Earth Spirit",
            "Morphling": -0.238
        },
        {
            "name": "Earthshaker",
            "Morphling": 1.535
        },
        {
            "name": "Elder Titan",
            "Morphling": 1.214
        },
        {
            "name": "Ember Spirit",
            "Morphling": -2.631
        },
        {
            "name": "Enchantress",
            "Morphling": -0.812
        },
        {
            "name": "Enigma",
            "Morphling": 0.672
        },
        {
            "name": "Faceless Void",
            "Morphling": -0.524
        },
        {
            "name": "Grimstroke",
            "Morphling": 0.785
        },
        {
            "name": "Gyrocopter",
            "Morphling": -0.845
        },
        {
            "name": "Hoodwink",
            "Morphling": 1.231
        },
        {
            "name": "Huskar",
            "Morphling": -1.153
        },
        {
            "name": "Invoker",
            "Morphling": 0.403
        },
        {
            "name": "Io",
            "Morphling": 0.454
        },
        {
            "name": "Jakiro",
            "Morphling": -0.804
        },
        {
            "name": "Juggernaut",
            "Morphling": -2.77
        },
        {
            "name": "Keeper of the Light",
            "Morphling": 1.642
        },
        {
            "name": "Kez",
            "Morphling": 1.208
        },
        {
            "name": "Kunkka",
            "Morphling": -0.025
        },
        {
            "name": "Legion Commander",
            "Morphling": 0.002
        },
        {
            "name": "Leshrac",
            "Morphling": 0.394
        },
        {
            "name": "Lich",
            "Morphling": -0.444
        },
        {
            "name": "Lifestealer",
            "Morphling": -2.946
        },
        {
            "name": "Lina",
            "Morphling": 2.218
        },
        {
            "name": "Lion",
            "Morphling": 2.046
        },
        {
            "name": "Lone Druid",
            "Morphling": 2.164
        },
        {
            "name": "Luna",
            "Morphling": 0.232
        },
        {
            "name": "Lycan",
            "Morphling": -0.871
        },
        {
            "name": "Magnus",
            "Morphling": -0.192
        },
        {
            "name": "Marci",
            "Morphling": 1.695
        },
        {
            "name": "Mars",
            "Morphling": -1.186
        },
        {
            "name": "Medusa",
            "Morphling": 0.855
        },
        {
            "name": "Meepo",
            "Morphling": 2.542
        },
        {
            "name": "Mirana",
            "Morphling": -0.733
        },
        {
            "name": "Monkey King",
            "Morphling": -0.138
        },
        {
            "name": "Muerta",
            "Morphling": 0.547
        },
        {
            "name": "Naga Siren",
            "Morphling": 1.779
        },
        {
            "name": "Nature's Prophet",
            "Morphling": -2.187
        },
        {
            "name": "Necrophos",
            "Morphling": 0.266
        },
        {
            "name": "Night Stalker",
            "Morphling": -0.74
        },
        {
            "name": "Nyx Assassin",
            "Morphling": 2.331
        },
        {
            "name": "Ogre Magi",
            "Morphling": 0.562
        },
        {
            "name": "Omniknight",
            "Morphling": -0.82
        },
        {
            "name": "Oracle",
            "Morphling": 0.366
        },
        {
            "name": "Outworld Destroyer",
            "Morphling": 0.983
        },
        {
            "name": "Pangolier",
            "Morphling": -0.936
        },
        {
            "name": "Phantom Assassin",
            "Morphling": -1.433
        },
        {
            "name": "Phantom Lancer",
            "Morphling": 2.683
        },
        {
            "name": "Phoenix",
            "Morphling": -2.261
        },
        {
            "name": "Primal Beast",
            "Morphling": -0.634
        },
        {
            "name": "Puck",
            "Morphling": -0.05
        },
        {
            "name": "Pudge",
            "Morphling": -0.229
        },
        {
            "name": "Pugna",
            "Morphling": -0.04
        },
        {
            "name": "Queen of Pain",
            "Morphling": -1.348
        },
        {
            "name": "Razor",
            "Morphling": -4.481
        },
        {
            "name": "Riki",
            "Morphling": 0.769
        },
        {
            "name": "Ringmaster",
            "Morphling": 0.065
        },
        {
            "name": "Rubick",
            "Morphling": 2.205
        },
        {
            "name": "Sand King",
            "Morphling": -0.383
        },
        {
            "name": "Shadow Demon",
            "Morphling": -1.31
        },
        {
            "name": "Shadow Fiend",
            "Morphling": 0.828
        },
        {
            "name": "Shadow Shaman",
            "Morphling": 1.278
        },
        {
            "name": "Silencer",
            "Morphling": -2.012
        },
        {
            "name": "Skywrath Mage",
            "Morphling": -0.13
        },
        {
            "name": "Slardar",
            "Morphling": -0.79
        },
        {
            "name": "Slark",
            "Morphling": 1.278
        },
        {
            "name": "Snapfire",
            "Morphling": -0.72
        },
        {
            "name": "Sniper",
            "Morphling": -3.16
        },
        {
            "name": "Spectre",
            "Morphling": -0.147
        },
        {
            "name": "Spirit Breaker",
            "Morphling": -0.871
        },
        {
            "name": "Storm Spirit",
            "Morphling": 0.039
        },
        {
            "name": "Sven",
            "Morphling": -0.117
        },
        {
            "name": "Techies",
            "Morphling": 1.546
        },
        {
            "name": "Templar Assassin",
            "Morphling": -0.556
        },
        {
            "name": "Terrorblade",
            "Morphling": 2.296
        },
        {
            "name": "Tidehunter",
            "Morphling": 0.209
        },
        {
            "name": "Timbersaw",
            "Morphling": 2.455
        },
        {
            "name": "Tinker",
            "Morphling": 2.124
        },
        {
            "name": "Tiny",
            "Morphling": -0.152
        },
        {
            "name": "Treant Protector",
            "Morphling": -1.472
        },
        {
            "name": "Troll Warlord",
            "Morphling": 1.202
        },
        {
            "name": "Tusk",
            "Morphling": -0.853
        },
        {
            "name": "Underlord",
            "Morphling": -1.882
        },
        {
            "name": "Undying",
            "Morphling": -1.235
        },
        {
            "name": "Ursa",
            "Morphling": 0.461
        },
        {
            "name": "Vengeful Spirit",
            "Morphling": -0.45
        },
        {
            "name": "Venomancer",
            "Morphling": -1.683
        },
        {
            "name": "Viper",
            "Morphling": -3.614
        },
        {
            "name": "Visage",
            "Morphling": 1.02
        },
        {
            "name": "Void Spirit",
            "Morphling": 0.178
        },
        {
            "name": "Warlock",
            "Morphling": -1.239
        },
        {
            "name": "Weaver",
            "Morphling": -2.717
        },
        {
            "name": "Windranger",
            "Morphling": 0.695
        },
        {
            "name": "Winter Wyvern",
            "Morphling": 0.443
        },
        {
            "name": "Witch Doctor",
            "Morphling": -0.981
        },
        {
            "name": "Wraith King",
            "Morphling": -1.575
        },
        {
            "name": "Zeus",
            "Morphling": 0.376
        }
    ],
    "Naga Siren": [
        {
            "name": "Abaddon",
            "Naga Siren": 0.283
        },
        {
            "name": "Alchemist",
            "Naga Siren": 1.19
        },
        {
            "name": "Ancient Apparition",
            "Naga Siren": -0.366
        },
        {
            "name": "Anti-Mage",
            "Naga Siren": 0.71
        },
        {
            "name": "Arc Warden",
            "Naga Siren": -2.613
        },
        {
            "name": "Axe",
            "Naga Siren": 5.468
        },
        {
            "name": "Bane",
            "Naga Siren": -4.213
        },
        {
            "name": "Batrider",
            "Naga Siren": 0.864
        },
        {
            "name": "Beastmaster",
            "Naga Siren": 1.67
        },
        {
            "name": "Bloodseeker",
            "Naga Siren": -2.621
        },
        {
            "name": "Bounty Hunter",
            "Naga Siren": -3.883
        },
        {
            "name": "Brewmaster",
            "Naga Siren": -1.002
        },
        {
            "name": "Bristleback",
            "Naga Siren": -0.731
        },
        {
            "name": "Broodmother",
            "Naga Siren": -4.223
        },
        {
            "name": "Centaur Warrunner",
            "Naga Siren": 1.988
        },
        {
            "name": "Chaos Knight",
            "Naga Siren": -3.822
        },
        {
            "name": "Chen",
            "Naga Siren": -5.883
        },
        {
            "name": "Clinkz",
            "Naga Siren": -4.196
        },
        {
            "name": "Clockwerk",
            "Naga Siren": 0.155
        },
        {
            "name": "Crystal Maiden",
            "Naga Siren": 1.131
        },
        {
            "name": "Dark Seer",
            "Naga Siren": 2.929
        },
        {
            "name": "Dark Willow",
            "Naga Siren": -1.719
        },
        {
            "name": "Dawnbreaker",
            "Naga Siren": 3.404
        },
        {
            "name": "Dazzle",
            "Naga Siren": 1.378
        },
        {
            "name": "Death Prophet",
            "Naga Siren": -0.592
        },
        {
            "name": "Disruptor",
            "Naga Siren": 0.01
        },
        {
            "name": "Doom",
            "Naga Siren": -0.24
        },
        {
            "name": "Dragon Knight",
            "Naga Siren": 2.519
        },
        {
            "name": "Drow Ranger",
            "Naga Siren": -1.765
        },
        {
            "name": "Earth Spirit",
            "Naga Siren": -1.407
        },
        {
            "name": "Earthshaker",
            "Naga Siren": 3.949
        },
        {
            "name": "Elder Titan",
            "Naga Siren": -1.2
        },
        {
            "name": "Ember Spirit",
            "Naga Siren": -0.109
        },
        {
            "name": "Enchantress",
            "Naga Siren": -1.537
        },
        {
            "name": "Enigma",
            "Naga Siren": -0.226
        },
        {
            "name": "Faceless Void",
            "Naga Siren": -3.213
        },
        {
            "name": "Grimstroke",
            "Naga Siren": 0.584
        },
        {
            "name": "Gyrocopter",
            "Naga Siren": 2.504
        },
        {
            "name": "Hoodwink",
            "Naga Siren": -0.512
        },
        {
            "name": "Huskar",
            "Naga Siren": -3.361
        },
        {
            "name": "Invoker",
            "Naga Siren": -0.751
        },
        {
            "name": "Io",
            "Naga Siren": 2.507
        },
        {
            "name": "Jakiro",
            "Naga Siren": 3.182
        },
        {
            "name": "Juggernaut",
            "Naga Siren": 1.23
        },
        {
            "name": "Keeper of the Light",
            "Naga Siren": -2.979
        },
        {
            "name": "Kez",
            "Naga Siren": -0.713
        },
        {
            "name": "Kunkka",
            "Naga Siren": 0.959
        },
        {
            "name": "Legion Commander",
            "Naga Siren": 0.963
        },
        {
            "name": "Leshrac",
            "Naga Siren": 6.624
        },
        {
            "name": "Lich",
            "Naga Siren": 1.609
        },
        {
            "name": "Lifestealer",
            "Naga Siren": -0.54
        },
        {
            "name": "Lina",
            "Naga Siren": 3.475
        },
        {
            "name": "Lion",
            "Naga Siren": 1.075
        },
        {
            "name": "Lone Druid",
            "Naga Siren": -7.618
        },
        {
            "name": "Luna",
            "Naga Siren": 2.463
        },
        {
            "name": "Lycan",
            "Naga Siren": -7.004
        },
        {
            "name": "Magnus",
            "Naga Siren": 2.524
        },
        {
            "name": "Marci",
            "Naga Siren": 0.439
        },
        {
            "name": "Mars",
            "Naga Siren": 0.497
        },
        {
            "name": "Medusa",
            "Naga Siren": 2.91
        },
        {
            "name": "Meepo",
            "Naga Siren": -0.672
        },
        {
            "name": "Mirana",
            "Naga Siren": -1.125
        },
        {
            "name": "Monkey King",
            "Naga Siren": -0.323
        },
        {
            "name": "Morphling",
            "Naga Siren": -1.912
        },
        {
            "name": "Muerta",
            "Naga Siren": -1.79
        },
        {
            "name": "Nature's Prophet",
            "Naga Siren": -0.142
        },
        {
            "name": "Necrophos",
            "Naga Siren": 5.711
        },
        {
            "name": "Night Stalker",
            "Naga Siren": -5.337
        },
        {
            "name": "Nyx Assassin",
            "Naga Siren": -1.516
        },
        {
            "name": "Ogre Magi",
            "Naga Siren": -3.767
        },
        {
            "name": "Omniknight",
            "Naga Siren": -0.851
        },
        {
            "name": "Oracle",
            "Naga Siren": 0.236
        },
        {
            "name": "Outworld Destroyer",
            "Naga Siren": -0.909
        },
        {
            "name": "Pangolier",
            "Naga Siren": 1.083
        },
        {
            "name": "Phantom Assassin",
            "Naga Siren": -0.405
        },
        {
            "name": "Phantom Lancer",
            "Naga Siren": -0.907
        },
        {
            "name": "Phoenix",
            "Naga Siren": 3.856
        },
        {
            "name": "Primal Beast",
            "Naga Siren": 4.2
        },
        {
            "name": "Puck",
            "Naga Siren": 0.987
        },
        {
            "name": "Pudge",
            "Naga Siren": 1.593
        },
        {
            "name": "Pugna",
            "Naga Siren": 0.233
        },
        {
            "name": "Queen of Pain",
            "Naga Siren": 1.132
        },
        {
            "name": "Razor",
            "Naga Siren": 0.301
        },
        {
            "name": "Riki",
            "Naga Siren": -5.976
        },
        {
            "name": "Ringmaster",
            "Naga Siren": 0.101
        },
        {
            "name": "Rubick",
            "Naga Siren": 1.092
        },
        {
            "name": "Sand King",
            "Naga Siren": 4.611
        },
        {
            "name": "Shadow Demon",
            "Naga Siren": -1.052
        },
        {
            "name": "Shadow Fiend",
            "Naga Siren": 1.224
        },
        {
            "name": "Shadow Shaman",
            "Naga Siren": -0.583
        },
        {
            "name": "Silencer",
            "Naga Siren": -3.033
        },
        {
            "name": "Skywrath Mage",
            "Naga Siren": 0.812
        },
        {
            "name": "Slardar",
            "Naga Siren": -3.916
        },
        {
            "name": "Slark",
            "Naga Siren": -1.615
        },
        {
            "name": "Snapfire",
            "Naga Siren": 1.489
        },
        {
            "name": "Sniper",
            "Naga Siren": -0.85
        },
        {
            "name": "Spectre",
            "Naga Siren": -0.911
        },
        {
            "name": "Spirit Breaker",
            "Naga Siren": -1.149
        },
        {
            "name": "Storm Spirit",
            "Naga Siren": -1.667
        },
        {
            "name": "Sven",
            "Naga Siren": 4.384
        },
        {
            "name": "Techies",
            "Naga Siren": 2.187
        },
        {
            "name": "Templar Assassin",
            "Naga Siren": -2.691
        },
        {
            "name": "Terrorblade",
            "Naga Siren": -1.93
        },
        {
            "name": "Tidehunter",
            "Naga Siren": 5.101
        },
        {
            "name": "Timbersaw",
            "Naga Siren": 4.117
        },
        {
            "name": "Tinker",
            "Naga Siren": 1.429
        },
        {
            "name": "Tiny",
            "Naga Siren": 3.632
        },
        {
            "name": "Treant Protector",
            "Naga Siren": 1.049
        },
        {
            "name": "Troll Warlord",
            "Naga Siren": -2.381
        },
        {
            "name": "Tusk",
            "Naga Siren": -2.291
        },
        {
            "name": "Underlord",
            "Naga Siren": 3.783
        },
        {
            "name": "Undying",
            "Naga Siren": -1.595
        },
        {
            "name": "Ursa",
            "Naga Siren": -4.061
        },
        {
            "name": "Vengeful Spirit",
            "Naga Siren": -0.829
        },
        {
            "name": "Venomancer",
            "Naga Siren": -0.777
        },
        {
            "name": "Viper",
            "Naga Siren": -5.021
        },
        {
            "name": "Visage",
            "Naga Siren": -5.82
        },
        {
            "name": "Void Spirit",
            "Naga Siren": -2.747
        },
        {
            "name": "Warlock",
            "Naga Siren": 3.21
        },
        {
            "name": "Weaver",
            "Naga Siren": -3.063
        },
        {
            "name": "Windranger",
            "Naga Siren": -1.1
        },
        {
            "name": "Winter Wyvern",
            "Naga Siren": 2.176
        },
        {
            "name": "Witch Doctor",
            "Naga Siren": -0.622
        },
        {
            "name": "Wraith King",
            "Naga Siren": -3.45
        },
        {
            "name": "Zeus",
            "Naga Siren": -0.714
        }
    ],
    "Phantom Assassin": [
        {
            "name": "Abaddon",
            "Phantom Assassin": -1.547
        },
        {
            "name": "Alchemist",
            "Phantom Assassin": -0.742
        },
        {
            "name": "Ancient Apparition",
            "Phantom Assassin": -2.331
        },
        {
            "name": "Anti-Mage",
            "Phantom Assassin": -3.601
        },
        {
            "name": "Arc Warden",
            "Phantom Assassin": -0.865
        },
        {
            "name": "Axe",
            "Phantom Assassin": 2.502
        },
        {
            "name": "Bane",
            "Phantom Assassin": 1.341
        },
        {
            "name": "Batrider",
            "Phantom Assassin": 1.375
        },
        {
            "name": "Beastmaster",
            "Phantom Assassin": -0.523
        },
        {
            "name": "Bloodseeker",
            "Phantom Assassin": -0.903
        },
        {
            "name": "Bounty Hunter",
            "Phantom Assassin": 0.934
        },
        {
            "name": "Brewmaster",
            "Phantom Assassin": 0.125
        },
        {
            "name": "Bristleback",
            "Phantom Assassin": 0.031
        },
        {
            "name": "Broodmother",
            "Phantom Assassin": -2.181
        },
        {
            "name": "Centaur Warrunner",
            "Phantom Assassin": 1.15
        },
        {
            "name": "Chaos Knight",
            "Phantom Assassin": -0.423
        },
        {
            "name": "Chen",
            "Phantom Assassin": 0.92
        },
        {
            "name": "Clinkz",
            "Phantom Assassin": -2.147
        },
        {
            "name": "Clockwerk",
            "Phantom Assassin": -0.441
        },
        {
            "name": "Crystal Maiden",
            "Phantom Assassin": -0.435
        },
        {
            "name": "Dark Seer",
            "Phantom Assassin": -0.868
        },
        {
            "name": "Dark Willow",
            "Phantom Assassin": -0.28
        },
        {
            "name": "Dawnbreaker",
            "Phantom Assassin": 1.312
        },
        {
            "name": "Dazzle",
            "Phantom Assassin": -0.873
        },
        {
            "name": "Death Prophet",
            "Phantom Assassin": 0.564
        },
        {
            "name": "Disruptor",
            "Phantom Assassin": -0.801
        },
        {
            "name": "Doom",
            "Phantom Assassin": 0.905
        },
        {
            "name": "Dragon Knight",
            "Phantom Assassin": -0.466
        },
        {
            "name": "Drow Ranger",
            "Phantom Assassin": 0.106
        },
        {
            "name": "Earth Spirit",
            "Phantom Assassin": 1.878
        },
        {
            "name": "Earthshaker",
            "Phantom Assassin": -0.835
        },
        {
            "name": "Elder Titan",
            "Phantom Assassin": -2.855
        },
        {
            "name": "Ember Spirit",
            "Phantom Assassin": 0.432
        },
        {
            "name": "Enchantress",
            "Phantom Assassin": -3.598
        },
        {
            "name": "Enigma",
            "Phantom Assassin": -0.803
        },
        {
            "name": "Faceless Void",
            "Phantom Assassin": 0.489
        },
        {
            "name": "Grimstroke",
            "Phantom Assassin": -1.143
        },
        {
            "name": "Gyrocopter",
            "Phantom Assassin": 0.056
        },
        {
            "name": "Hoodwink",
            "Phantom Assassin": -0.101
        },
        {
            "name": "Huskar",
            "Phantom Assassin": -3.174
        },
        {
            "name": "Invoker",
            "Phantom Assassin": -1.277
        },
        {
            "name": "Io",
            "Phantom Assassin": -0.787
        },
        {
            "name": "Jakiro",
            "Phantom Assassin": 0.502
        },
        {
            "name": "Juggernaut",
            "Phantom Assassin": -1.098
        },
        {
            "name": "Keeper of the Light",
            "Phantom Assassin": -0.772
        },
        {
            "name": "Kez",
            "Phantom Assassin": -0.066
        },
        {
            "name": "Kunkka",
            "Phantom Assassin": 0.258
        },
        {
            "name": "Legion Commander",
            "Phantom Assassin": -0.755
        },
        {
            "name": "Leshrac",
            "Phantom Assassin": 1.442
        },
        {
            "name": "Lich",
            "Phantom Assassin": -0.468
        },
        {
            "name": "Lifestealer",
            "Phantom Assassin": -2.198
        },
        {
            "name": "Lina",
            "Phantom Assassin": -0.697
        },
        {
            "name": "Lion",
            "Phantom Assassin": -0.699
        },
        {
            "name": "Lone Druid",
            "Phantom Assassin": -3.174
        },
        {
            "name": "Luna",
            "Phantom Assassin": 0.564
        },
        {
            "name": "Lycan",
            "Phantom Assassin": -1.734
        },
        {
            "name": "Magnus",
            "Phantom Assassin": 0.887
        },
        {
            "name": "Marci",
            "Phantom Assassin": 0.429
        },
        {
            "name": "Mars",
            "Phantom Assassin": -0.295
        },
        {
            "name": "Medusa",
            "Phantom Assassin": -0.5
        },
        {
            "name": "Meepo",
            "Phantom Assassin": 0.623
        },
        {
            "name": "Mirana",
            "Phantom Assassin": 0.566
        },
        {
            "name": "Monkey King",
            "Phantom Assassin": -0.179
        },
        {
            "name": "Morphling",
            "Phantom Assassin": 1.523
        },
        {
            "name": "Muerta",
            "Phantom Assassin": -0.234
        },
        {
            "name": "Naga Siren",
            "Phantom Assassin": 0.692
        },
        {
            "name": "Nature's Prophet",
            "Phantom Assassin": -2.004
        },
        {
            "name": "Necrophos",
            "Phantom Assassin": -0.831
        },
        {
            "name": "Night Stalker",
            "Phantom Assassin": -1.713
        },
        {
            "name": "Nyx Assassin",
            "Phantom Assassin": 0.819
        },
        {
            "name": "Ogre Magi",
            "Phantom Assassin": 0.772
        },
        {
            "name": "Omniknight",
            "Phantom Assassin": 0.995
        },
        {
            "name": "Oracle",
            "Phantom Assassin": -1.321
        },
        {
            "name": "Outworld Destroyer",
            "Phantom Assassin": -1.541
        },
        {
            "name": "Pangolier",
            "Phantom Assassin": 1.505
        },
        {
            "name": "Phantom Lancer",
            "Phantom Assassin": 0.669
        },
        {
            "name": "Phoenix",
            "Phantom Assassin": 1.277
        },
        {
            "name": "Primal Beast",
            "Phantom Assassin": 2.852
        },
        {
            "name": "Puck",
            "Phantom Assassin": 1.114
        },
        {
            "name": "Pudge",
            "Phantom Assassin": 1.745
        },
        {
            "name": "Pugna",
            "Phantom Assassin": -0.092
        },
        {
            "name": "Queen of Pain",
            "Phantom Assassin": 1.18
        },
        {
            "name": "Razor",
            "Phantom Assassin": 0.525
        },
        {
            "name": "Riki",
            "Phantom Assassin": -1.128
        },
        {
            "name": "Ringmaster",
            "Phantom Assassin": -0.219
        },
        {
            "name": "Rubick",
            "Phantom Assassin": -1.101
        },
        {
            "name": "Sand King",
            "Phantom Assassin": 0.706
        },
        {
            "name": "Shadow Demon",
            "Phantom Assassin": -0.841
        },
        {
            "name": "Shadow Fiend",
            "Phantom Assassin": 0.016
        },
        {
            "name": "Shadow Shaman",
            "Phantom Assassin": -0.539
        },
        {
            "name": "Silencer",
            "Phantom Assassin": -0.403
        },
        {
            "name": "Skywrath Mage",
            "Phantom Assassin": -0.852
        },
        {
            "name": "Slardar",
            "Phantom Assassin": -0.418
        },
        {
            "name": "Slark",
            "Phantom Assassin": -0.384
        },
        {
            "name": "Snapfire",
            "Phantom Assassin": -0.621
        },
        {
            "name": "Sniper",
            "Phantom Assassin": -1.305
        },
        {
            "name": "Spectre",
            "Phantom Assassin": -1.182
        },
        {
            "name": "Spirit Breaker",
            "Phantom Assassin": 0.56
        },
        {
            "name": "Storm Spirit",
            "Phantom Assassin": 0.534
        },
        {
            "name": "Sven",
            "Phantom Assassin": -1.354
        },
        {
            "name": "Techies",
            "Phantom Assassin": 0.98
        },
        {
            "name": "Templar Assassin",
            "Phantom Assassin": 1.306
        },
        {
            "name": "Terrorblade",
            "Phantom Assassin": 0.115
        },
        {
            "name": "Tidehunter",
            "Phantom Assassin": 0.737
        },
        {
            "name": "Timbersaw",
            "Phantom Assassin": 2.059
        },
        {
            "name": "Tinker",
            "Phantom Assassin": -0.239
        },
        {
            "name": "Tiny",
            "Phantom Assassin": 1.365
        },
        {
            "name": "Treant Protector",
            "Phantom Assassin": 0.767
        },
        {
            "name": "Troll Warlord",
            "Phantom Assassin": 0.575
        },
        {
            "name": "Tusk",
            "Phantom Assassin": 1.555
        },
        {
            "name": "Underlord",
            "Phantom Assassin": -0.456
        },
        {
            "name": "Undying",
            "Phantom Assassin": -0.745
        },
        {
            "name": "Ursa",
            "Phantom Assassin": -0.745
        },
        {
            "name": "Vengeful Spirit",
            "Phantom Assassin": -0.336
        },
        {
            "name": "Venomancer",
            "Phantom Assassin": -1.126
        },
        {
            "name": "Viper",
            "Phantom Assassin": -0.407
        },
        {
            "name": "Visage",
            "Phantom Assassin": -2.478
        },
        {
            "name": "Void Spirit",
            "Phantom Assassin": 0.348
        },
        {
            "name": "Warlock",
            "Phantom Assassin": -1.603
        },
        {
            "name": "Weaver",
            "Phantom Assassin": 0.143
        },
        {
            "name": "Windranger",
            "Phantom Assassin": 0.893
        },
        {
            "name": "Winter Wyvern",
            "Phantom Assassin": -0.841
        },
        {
            "name": "Witch Doctor",
            "Phantom Assassin": -1.78
        },
        {
            "name": "Wraith King",
            "Phantom Assassin": -0.193
        },
        {
            "name": "Zeus",
            "Phantom Assassin": -0.099
        }
    ],
    "Phantom Lancer": [
        {
            "name": "Abaddon",
            "Phantom Lancer": -0.118
        },
        {
            "name": "Alchemist",
            "Phantom Lancer": 1.66
        },
        {
            "name": "Ancient Apparition",
            "Phantom Lancer": -2.744
        },
        {
            "name": "Anti-Mage",
            "Phantom Lancer": 2.82
        },
        {
            "name": "Arc Warden",
            "Phantom Lancer": -3.727
        },
        {
            "name": "Axe",
            "Phantom Lancer": 3.478
        },
        {
            "name": "Bane",
            "Phantom Lancer": -2.997
        },
        {
            "name": "Batrider",
            "Phantom Lancer": -0.57
        },
        {
            "name": "Beastmaster",
            "Phantom Lancer": 4.294
        },
        {
            "name": "Bloodseeker",
            "Phantom Lancer": 0.297
        },
        {
            "name": "Bounty Hunter",
            "Phantom Lancer": -2.215
        },
        {
            "name": "Brewmaster",
            "Phantom Lancer": -0.153
        },
        {
            "name": "Bristleback",
            "Phantom Lancer": -2.429
        },
        {
            "name": "Broodmother",
            "Phantom Lancer": -2.42
        },
        {
            "name": "Centaur Warrunner",
            "Phantom Lancer": 2.047
        },
        {
            "name": "Chaos Knight",
            "Phantom Lancer": -4.434
        },
        {
            "name": "Chen",
            "Phantom Lancer": -4.996
        },
        {
            "name": "Clinkz",
            "Phantom Lancer": -5.935
        },
        {
            "name": "Clockwerk",
            "Phantom Lancer": -1.831
        },
        {
            "name": "Crystal Maiden",
            "Phantom Lancer": 3.598
        },
        {
            "name": "Dark Seer",
            "Phantom Lancer": 6.039
        },
        {
            "name": "Dark Willow",
            "Phantom Lancer": -1.282
        },
        {
            "name": "Dawnbreaker",
            "Phantom Lancer": 1.308
        },
        {
            "name": "Dazzle",
            "Phantom Lancer": -2.531
        },
        {
            "name": "Death Prophet",
            "Phantom Lancer": -2.68
        },
        {
            "name": "Disruptor",
            "Phantom Lancer": -0.469
        },
        {
            "name": "Doom",
            "Phantom Lancer": 0.794
        },
        {
            "name": "Dragon Knight",
            "Phantom Lancer": 2.991
        },
        {
            "name": "Drow Ranger",
            "Phantom Lancer": -3.554
        },
        {
            "name": "Earth Spirit",
            "Phantom Lancer": 1.742
        },
        {
            "name": "Earthshaker",
            "Phantom Lancer": 3.267
        },
        {
            "name": "Elder Titan",
            "Phantom Lancer": 1.467
        },
        {
            "name": "Ember Spirit",
            "Phantom Lancer": 2.622
        },
        {
            "name": "Enchantress",
            "Phantom Lancer": -2.644
        },
        {
            "name": "Enigma",
            "Phantom Lancer": -0.299
        },
        {
            "name": "Faceless Void",
            "Phantom Lancer": -0.941
        },
        {
            "name": "Grimstroke",
            "Phantom Lancer": 0.883
        },
        {
            "name": "Gyrocopter",
            "Phantom Lancer": 0.402
        },
        {
            "name": "Hoodwink",
            "Phantom Lancer": -1.129
        },
        {
            "name": "Huskar",
            "Phantom Lancer": -3.087
        },
        {
            "name": "Invoker",
            "Phantom Lancer": -1.49
        },
        {
            "name": "Io",
            "Phantom Lancer": -0.357
        },
        {
            "name": "Jakiro",
            "Phantom Lancer": 2.251
        },
        {
            "name": "Juggernaut",
            "Phantom Lancer": -0.782
        },
        {
            "name": "Keeper of the Light",
            "Phantom Lancer": -1.043
        },
        {
            "name": "Kez",
            "Phantom Lancer": -2.082
        },
        {
            "name": "Kunkka",
            "Phantom Lancer": 1.129
        },
        {
            "name": "Legion Commander",
            "Phantom Lancer": 1.359
        },
        {
            "name": "Leshrac",
            "Phantom Lancer": 5.862
        },
        {
            "name": "Lich",
            "Phantom Lancer": -0.801
        },
        {
            "name": "Lifestealer",
            "Phantom Lancer": 0.295
        },
        {
            "name": "Lina",
            "Phantom Lancer": 1.756
        },
        {
            "name": "Lion",
            "Phantom Lancer": -0.169
        },
        {
            "name": "Lone Druid",
            "Phantom Lancer": -3.81
        },
        {
            "name": "Luna",
            "Phantom Lancer": -1.347
        },
        {
            "name": "Lycan",
            "Phantom Lancer": -3.302
        },
        {
            "name": "Magnus",
            "Phantom Lancer": 2.793
        },
        {
            "name": "Marci",
            "Phantom Lancer": 1.13
        },
        {
            "name": "Mars",
            "Phantom Lancer": -1.305
        },
        {
            "name": "Medusa",
            "Phantom Lancer": 0.862
        },
        {
            "name": "Meepo",
            "Phantom Lancer": 3.222
        },
        {
            "name": "Mirana",
            "Phantom Lancer": -0.801
        },
        {
            "name": "Monkey King",
            "Phantom Lancer": -1.042
        },
        {
            "name": "Morphling",
            "Phantom Lancer": -2.756
        },
        {
            "name": "Muerta",
            "Phantom Lancer": 1.592
        },
        {
            "name": "Naga Siren",
            "Phantom Lancer": 0.959
        },
        {
            "name": "Nature's Prophet",
            "Phantom Lancer": 0.255
        },
        {
            "name": "Necrophos",
            "Phantom Lancer": 4.229
        },
        {
            "name": "Night Stalker",
            "Phantom Lancer": -1.323
        },
        {
            "name": "Nyx Assassin",
            "Phantom Lancer": 0.946
        },
        {
            "name": "Ogre Magi",
            "Phantom Lancer": -4.53
        },
        {
            "name": "Omniknight",
            "Phantom Lancer": -3.573
        },
        {
            "name": "Oracle",
            "Phantom Lancer": -1.062
        },
        {
            "name": "Outworld Destroyer",
            "Phantom Lancer": -3.203
        },
        {
            "name": "Pangolier",
            "Phantom Lancer": 0.324
        },
        {
            "name": "Phantom Assassin",
            "Phantom Lancer": -0.157
        },
        {
            "name": "Phoenix",
            "Phantom Lancer": 1.96
        },
        {
            "name": "Primal Beast",
            "Phantom Lancer": 1.496
        },
        {
            "name": "Puck",
            "Phantom Lancer": 3.588
        },
        {
            "name": "Pudge",
            "Phantom Lancer": 0.556
        },
        {
            "name": "Pugna",
            "Phantom Lancer": -1.671
        },
        {
            "name": "Queen of Pain",
            "Phantom Lancer": 0.32
        },
        {
            "name": "Razor",
            "Phantom Lancer": 1.011
        },
        {
            "name": "Riki",
            "Phantom Lancer": 0.205
        },
        {
            "name": "Ringmaster",
            "Phantom Lancer": -1.183
        },
        {
            "name": "Rubick",
            "Phantom Lancer": 2.415
        },
        {
            "name": "Sand King",
            "Phantom Lancer": 5.981
        },
        {
            "name": "Shadow Demon",
            "Phantom Lancer": -1.913
        },
        {
            "name": "Shadow Fiend",
            "Phantom Lancer": -1.055
        },
        {
            "name": "Shadow Shaman",
            "Phantom Lancer": -2.991
        },
        {
            "name": "Silencer",
            "Phantom Lancer": -2.846
        },
        {
            "name": "Skywrath Mage",
            "Phantom Lancer": -0.976
        },
        {
            "name": "Slardar",
            "Phantom Lancer": -4.16
        },
        {
            "name": "Slark",
            "Phantom Lancer": -1.378
        },
        {
            "name": "Snapfire",
            "Phantom Lancer": 0.732
        },
        {
            "name": "Sniper",
            "Phantom Lancer": -1.424
        },
        {
            "name": "Spectre",
            "Phantom Lancer": -1.758
        },
        {
            "name": "Spirit Breaker",
            "Phantom Lancer": -1.274
        },
        {
            "name": "Storm Spirit",
            "Phantom Lancer": 1.569
        },
        {
            "name": "Sven",
            "Phantom Lancer": 3.873
        },
        {
            "name": "Techies",
            "Phantom Lancer": 2.223
        },
        {
            "name": "Templar Assassin",
            "Phantom Lancer": -5.958
        },
        {
            "name": "Terrorblade",
            "Phantom Lancer": -3.384
        },
        {
            "name": "Tidehunter",
            "Phantom Lancer": 4.51
        },
        {
            "name": "Timbersaw",
            "Phantom Lancer": 3.366
        },
        {
            "name": "Tinker",
            "Phantom Lancer": 0.518
        },
        {
            "name": "Tiny",
            "Phantom Lancer": 2.128
        },
        {
            "name": "Treant Protector",
            "Phantom Lancer": 1.901
        },
        {
            "name": "Troll Warlord",
            "Phantom Lancer": -1.162
        },
        {
            "name": "Tusk",
            "Phantom Lancer": -1.199
        },
        {
            "name": "Underlord",
            "Phantom Lancer": 3.76
        },
        {
            "name": "Undying",
            "Phantom Lancer": -1.97
        },
        {
            "name": "Ursa",
            "Phantom Lancer": -4.576
        },
        {
            "name": "Vengeful Spirit",
            "Phantom Lancer": -4.032
        },
        {
            "name": "Venomancer",
            "Phantom Lancer": -1.761
        },
        {
            "name": "Viper",
            "Phantom Lancer": -6.21
        },
        {
            "name": "Visage",
            "Phantom Lancer": -3.313
        },
        {
            "name": "Void Spirit",
            "Phantom Lancer": 0.084
        },
        {
            "name": "Warlock",
            "Phantom Lancer": 4.859
        },
        {
            "name": "Weaver",
            "Phantom Lancer": -3.229
        },
        {
            "name": "Windranger",
            "Phantom Lancer": -1.11
        },
        {
            "name": "Winter Wyvern",
            "Phantom Lancer": 1.572
        },
        {
            "name": "Witch Doctor",
            "Phantom Lancer": 1.004
        },
        {
            "name": "Wraith King",
            "Phantom Lancer": -4.982
        },
        {
            "name": "Zeus",
            "Phantom Lancer": 1.017
        }
    ],
    "Razor": [
        {
            "name": "Abaddon",
            "Razor": -1.509
        },
        {
            "name": "Alchemist",
            "Razor": -0.664
        },
        {
            "name": "Ancient Apparition",
            "Razor": 0.558
        },
        {
            "name": "Anti-Mage",
            "Razor": 3.355
        },
        {
            "name": "Arc Warden",
            "Razor": 1.22
        },
        {
            "name": "Axe",
            "Razor": -1.085
        },
        {
            "name": "Bane",
            "Razor": -0.803
        },
        {
            "name": "Batrider",
            "Razor": -0.036
        },
        {
            "name": "Beastmaster",
            "Razor": -0.836
        },
        {
            "name": "Bloodseeker",
            "Razor": 1.75
        },
        {
            "name": "Bounty Hunter",
            "Razor": -0.271
        },
        {
            "name": "Brewmaster",
            "Razor": 0.995
        },
        {
            "name": "Bristleback",
            "Razor": 1.765
        },
        {
            "name": "Broodmother",
            "Razor": -0.208
        },
        {
            "name": "Centaur Warrunner",
            "Razor": 1.156
        },
        {
            "name": "Chaos Knight",
            "Razor": 1.265
        },
        {
            "name": "Chen",
            "Razor": -0.426
        },
        {
            "name": "Clinkz",
            "Razor": -1.245
        },
        {
            "name": "Clockwerk",
            "Razor": 1.689
        },
        {
            "name": "Crystal Maiden",
            "Razor": 1.047
        },
        {
            "name": "Dark Seer",
            "Razor": 0.007
        },
        {
            "name": "Dark Willow",
            "Razor": 1.525
        },
        {
            "name": "Dawnbreaker",
            "Razor": -0.257
        },
        {
            "name": "Dazzle",
            "Razor": -1.387
        },
        {
            "name": "Death Prophet",
            "Razor": -2.009
        },
        {
            "name": "Disruptor",
            "Razor": -0.244
        },
        {
            "name": "Doom",
            "Razor": -0.552
        },
        {
            "name": "Dragon Knight",
            "Razor": -0.815
        },
        {
            "name": "Drow Ranger",
            "Razor": 0.837
        },
        {
            "name": "Earth Spirit",
            "Razor": 2.057
        },
        {
            "name": "Earthshaker",
            "Razor": -0.576
        },
        {
            "name": "Elder Titan",
            "Razor": 1.379
        },
        {
            "name": "Ember Spirit",
            "Razor": 1.912
        },
        {
            "name": "Enchantress",
            "Razor": 0.012
        },
        {
            "name": "Enigma",
            "Razor": 0.722
        },
        {
            "name": "Faceless Void",
            "Razor": 1.805
        },
        {
            "name": "Grimstroke",
            "Razor": -1.729
        },
        {
            "name": "Gyrocopter",
            "Razor": -1.496
        },
        {
            "name": "Hoodwink",
            "Razor": 0.587
        },
        {
            "name": "Huskar",
            "Razor": 0.114
        },
        {
            "name": "Invoker",
            "Razor": -0.194
        },
        {
            "name": "Io",
            "Razor": -1.68
        },
        {
            "name": "Jakiro",
            "Razor": -0.362
        },
        {
            "name": "Juggernaut",
            "Razor": -0.906
        },
        {
            "name": "Keeper of the Light",
            "Razor": 0.562
        },
        {
            "name": "Kez",
            "Razor": -0.208
        },
        {
            "name": "Kunkka",
            "Razor": -0.644
        },
        {
            "name": "Legion Commander",
            "Razor": 0.357
        },
        {
            "name": "Leshrac",
            "Razor": -0.179
        },
        {
            "name": "Lich",
            "Razor": -0.713
        },
        {
            "name": "Lifestealer",
            "Razor": -3.156
        },
        {
            "name": "Lina",
            "Razor": -1.332
        },
        {
            "name": "Lion",
            "Razor": -2.059
        },
        {
            "name": "Lone Druid",
            "Razor": -2.121
        },
        {
            "name": "Luna",
            "Razor": 0.87
        },
        {
            "name": "Lycan",
            "Razor": 1.347
        },
        {
            "name": "Magnus",
            "Razor": 0.807
        },
        {
            "name": "Marci",
            "Razor": -0.034
        },
        {
            "name": "Mars",
            "Razor": 0.392
        },
        {
            "name": "Medusa",
            "Razor": -0.166
        },
        {
            "name": "Meepo",
            "Razor": -0.284
        },
        {
            "name": "Mirana",
            "Razor": 1.599
        },
        {
            "name": "Monkey King",
            "Razor": 0.116
        },
        {
            "name": "Morphling",
            "Razor": 4.498
        },
        {
            "name": "Muerta",
            "Razor": 0.73
        },
        {
            "name": "Naga Siren",
            "Razor": -0.165
        },
        {
            "name": "Nature's Prophet",
            "Razor": 2.062
        },
        {
            "name": "Necrophos",
            "Razor": 0.422
        },
        {
            "name": "Night Stalker",
            "Razor": -0.806
        },
        {
            "name": "Nyx Assassin",
            "Razor": 0.215
        },
        {
            "name": "Ogre Magi",
            "Razor": 0.52
        },
        {
            "name": "Omniknight",
            "Razor": -1.533
        },
        {
            "name": "Oracle",
            "Razor": -2.738
        },
        {
            "name": "Outworld Destroyer",
            "Razor": 0.921
        },
        {
            "name": "Pangolier",
            "Razor": -1.267
        },
        {
            "name": "Phantom Assassin",
            "Razor": -1.218
        },
        {
            "name": "Phantom Lancer",
            "Razor": -0.97
        },
        {
            "name": "Phoenix",
            "Razor": 1.589
        },
        {
            "name": "Primal Beast",
            "Razor": 1.783
        },
        {
            "name": "Puck",
            "Razor": 0.723
        },
        {
            "name": "Pudge",
            "Razor": 0.671
        },
        {
            "name": "Pugna",
            "Razor": 0.666
        },
        {
            "name": "Queen of Pain",
            "Razor": 3.523
        },
        {
            "name": "Riki",
            "Razor": -0.029
        },
        {
            "name": "Ringmaster",
            "Razor": 0.961
        },
        {
            "name": "Rubick",
            "Razor": -1.613
        },
        {
            "name": "Sand King",
            "Razor": 1.22
        },
        {
            "name": "Shadow Demon",
            "Razor": -1.48
        },
        {
            "name": "Shadow Fiend",
            "Razor": 0.094
        },
        {
            "name": "Shadow Shaman",
            "Razor": -1.531
        },
        {
            "name": "Silencer",
            "Razor": -1.127
        },
        {
            "name": "Skywrath Mage",
            "Razor": -0.999
        },
        {
            "name": "Slardar",
            "Razor": -1.122
        },
        {
            "name": "Slark",
            "Razor": 1.06
        },
        {
            "name": "Snapfire",
            "Razor": -0.179
        },
        {
            "name": "Sniper",
            "Razor": 0.642
        },
        {
            "name": "Spectre",
            "Razor": 0.37
        },
        {
            "name": "Spirit Breaker",
            "Razor": -0.292
        },
        {
            "name": "Storm Spirit",
            "Razor": 2.232
        },
        {
            "name": "Sven",
            "Razor": -1.654
        },
        {
            "name": "Techies",
            "Razor": 2.262
        },
        {
            "name": "Templar Assassin",
            "Razor": 0.898
        },
        {
            "name": "Terrorblade",
            "Razor": 2.415
        },
        {
            "name": "Tidehunter",
            "Razor": -3.302
        },
        {
            "name": "Timbersaw",
            "Razor": 0.892
        },
        {
            "name": "Tinker",
            "Razor": 0.445
        },
        {
            "name": "Tiny",
            "Razor": -0.39
        },
        {
            "name": "Treant Protector",
            "Razor": -0.138
        },
        {
            "name": "Troll Warlord",
            "Razor": -2.715
        },
        {
            "name": "Tusk",
            "Razor": 0.293
        },
        {
            "name": "Underlord",
            "Razor": 1.047
        },
        {
            "name": "Undying",
            "Razor": 0.047
        },
        {
            "name": "Ursa",
            "Razor": -2.067
        },
        {
            "name": "Vengeful Spirit",
            "Razor": 0.353
        },
        {
            "name": "Venomancer",
            "Razor": -0.053
        },
        {
            "name": "Viper",
            "Razor": 1.334
        },
        {
            "name": "Visage",
            "Razor": -2.93
        },
        {
            "name": "Void Spirit",
            "Razor": 0.64
        },
        {
            "name": "Warlock",
            "Razor": -0.622
        },
        {
            "name": "Weaver",
            "Razor": 2.38
        },
        {
            "name": "Windranger",
            "Razor": -1.389
        },
        {
            "name": "Winter Wyvern",
            "Razor": -0.465
        },
        {
            "name": "Witch Doctor",
            "Razor": 1.895
        },
        {
            "name": "Wraith King",
            "Razor": -0.425
        },
        {
            "name": "Zeus",
            "Razor": -0.245
        }
    ],
    "Riki": [
        {
            "name": "Abaddon",
            "Riki": -0.29
        },
        {
            "name": "Alchemist",
            "Riki": -0.007
        },
        {
            "name": "Ancient Apparition",
            "Riki": -1.465
        },
        {
            "name": "Anti-Mage",
            "Riki": -2.066
        },
        {
            "name": "Arc Warden",
            "Riki": -0.104
        },
        {
            "name": "Axe",
            "Riki": 0.93
        },
        {
            "name": "Bane",
            "Riki": -0.268
        },
        {
            "name": "Batrider",
            "Riki": 1.119
        },
        {
            "name": "Beastmaster",
            "Riki": 1.302
        },
        {
            "name": "Bloodseeker",
            "Riki": 2.669
        },
        {
            "name": "Bounty Hunter",
            "Riki": 0.982
        },
        {
            "name": "Brewmaster",
            "Riki": 0.922
        },
        {
            "name": "Bristleback",
            "Riki": 2.972
        },
        {
            "name": "Broodmother",
            "Riki": 1.644
        },
        {
            "name": "Centaur Warrunner",
            "Riki": 0.563
        },
        {
            "name": "Chaos Knight",
            "Riki": 0.186
        },
        {
            "name": "Chen",
            "Riki": 0.471
        },
        {
            "name": "Clinkz",
            "Riki": -2.085
        },
        {
            "name": "Clockwerk",
            "Riki": 0.8
        },
        {
            "name": "Crystal Maiden",
            "Riki": -0.3
        },
        {
            "name": "Dark Seer",
            "Riki": 0.676
        },
        {
            "name": "Dark Willow",
            "Riki": 0.721
        },
        {
            "name": "Dawnbreaker",
            "Riki": -0.85
        },
        {
            "name": "Dazzle",
            "Riki": -2.04
        },
        {
            "name": "Death Prophet",
            "Riki": -0.293
        },
        {
            "name": "Disruptor",
            "Riki": 1.002
        },
        {
            "name": "Doom",
            "Riki": 0.165
        },
        {
            "name": "Dragon Knight",
            "Riki": 1.366
        },
        {
            "name": "Drow Ranger",
            "Riki": 1.93
        },
        {
            "name": "Earth Spirit",
            "Riki": 0.471
        },
        {
            "name": "Earthshaker",
            "Riki": 0.288
        },
        {
            "name": "Elder Titan",
            "Riki": 0.993
        },
        {
            "name": "Ember Spirit",
            "Riki": -1.028
        },
        {
            "name": "Enchantress",
            "Riki": -0.688
        },
        {
            "name": "Enigma",
            "Riki": -0.657
        },
        {
            "name": "Faceless Void",
            "Riki": -1.616
        },
        {
            "name": "Grimstroke",
            "Riki": -0.778
        },
        {
            "name": "Gyrocopter",
            "Riki": 0.241
        },
        {
            "name": "Hoodwink",
            "Riki": 0.293
        },
        {
            "name": "Huskar",
            "Riki": -0.686
        },
        {
            "name": "Invoker",
            "Riki": -1.821
        },
        {
            "name": "Io",
            "Riki": -0.145
        },
        {
            "name": "Jakiro",
            "Riki": 1.401
        },
        {
            "name": "Juggernaut",
            "Riki": -3.387
        },
        {
            "name": "Keeper of the Light",
            "Riki": -0.927
        },
        {
            "name": "Kez",
            "Riki": -0.841
        },
        {
            "name": "Kunkka",
            "Riki": 0.958
        },
        {
            "name": "Legion Commander",
            "Riki": -0.807
        },
        {
            "name": "Leshrac",
            "Riki": 1.74
        },
        {
            "name": "Lich",
            "Riki": -0.118
        },
        {
            "name": "Lifestealer",
            "Riki": -1.212
        },
        {
            "name": "Lina",
            "Riki": 0.131
        },
        {
            "name": "Lion",
            "Riki": 0.288
        },
        {
            "name": "Lone Druid",
            "Riki": -1.271
        },
        {
            "name": "Luna",
            "Riki": 1.629
        },
        {
            "name": "Lycan",
            "Riki": 0.852
        },
        {
            "name": "Magnus",
            "Riki": -0.016
        },
        {
            "name": "Marci",
            "Riki": 0.891
        },
        {
            "name": "Mars",
            "Riki": -0.82
        },
        {
            "name": "Medusa",
            "Riki": -1.355
        },
        {
            "name": "Meepo",
            "Riki": -0.101
        },
        {
            "name": "Mirana",
            "Riki": -0.071
        },
        {
            "name": "Monkey King",
            "Riki": 0.621
        },
        {
            "name": "Morphling",
            "Riki": -0.771
        },
        {
            "name": "Muerta",
            "Riki": 1.318
        },
        {
            "name": "Naga Siren",
            "Riki": 5.866
        },
        {
            "name": "Nature's Prophet",
            "Riki": 0.615
        },
        {
            "name": "Necrophos",
            "Riki": 0.535
        },
        {
            "name": "Night Stalker",
            "Riki": 1.194
        },
        {
            "name": "Nyx Assassin",
            "Riki": 0.538
        },
        {
            "name": "Ogre Magi",
            "Riki": 0.857
        },
        {
            "name": "Omniknight",
            "Riki": 0.517
        },
        {
            "name": "Oracle",
            "Riki": -2.261
        },
        {
            "name": "Outworld Destroyer",
            "Riki": -0.231
        },
        {
            "name": "Pangolier",
            "Riki": -0.431
        },
        {
            "name": "Phantom Assassin",
            "Riki": 0.861
        },
        {
            "name": "Phantom Lancer",
            "Riki": -0.103
        },
        {
            "name": "Phoenix",
            "Riki": -0.372
        },
        {
            "name": "Primal Beast",
            "Riki": 1.719
        },
        {
            "name": "Puck",
            "Riki": -3.802
        },
        {
            "name": "Pudge",
            "Riki": 0.164
        },
        {
            "name": "Pugna",
            "Riki": -1.108
        },
        {
            "name": "Queen of Pain",
            "Riki": -1.848
        },
        {
            "name": "Razor",
            "Riki": 0.013
        },
        {
            "name": "Ringmaster",
            "Riki": -1.291
        },
        {
            "name": "Rubick",
            "Riki": -0.724
        },
        {
            "name": "Sand King",
            "Riki": 1.408
        },
        {
            "name": "Shadow Demon",
            "Riki": -1.616
        },
        {
            "name": "Shadow Fiend",
            "Riki": 0.465
        },
        {
            "name": "Shadow Shaman",
            "Riki": 0.339
        },
        {
            "name": "Silencer",
            "Riki": 0.932
        },
        {
            "name": "Skywrath Mage",
            "Riki": -0.784
        },
        {
            "name": "Slardar",
            "Riki": 0.534
        },
        {
            "name": "Slark",
            "Riki": -3.119
        },
        {
            "name": "Snapfire",
            "Riki": 0.963
        },
        {
            "name": "Sniper",
            "Riki": 0.936
        },
        {
            "name": "Spectre",
            "Riki": 2.356
        },
        {
            "name": "Spirit Breaker",
            "Riki": 0.761
        },
        {
            "name": "Storm Spirit",
            "Riki": -2.437
        },
        {
            "name": "Sven",
            "Riki": 0.971
        },
        {
            "name": "Techies",
            "Riki": 1.355
        },
        {
            "name": "Templar Assassin",
            "Riki": 0.374
        },
        {
            "name": "Terrorblade",
            "Riki": 0.202
        },
        {
            "name": "Tidehunter",
            "Riki": 0.489
        },
        {
            "name": "Timbersaw",
            "Riki": -0.122
        },
        {
            "name": "Tinker",
            "Riki": 0.161
        },
        {
            "name": "Tiny",
            "Riki": 2.641
        },
        {
            "name": "Treant Protector",
            "Riki": 0.327
        },
        {
            "name": "Troll Warlord",
            "Riki": 0.674
        },
        {
            "name": "Tusk",
            "Riki": 0.343
        },
        {
            "name": "Underlord",
            "Riki": 3.188
        },
        {
            "name": "Undying",
            "Riki": -1.528
        },
        {
            "name": "Ursa",
            "Riki": -2.465
        },
        {
            "name": "Vengeful Spirit",
            "Riki": 0.505
        },
        {
            "name": "Venomancer",
            "Riki": 0.543
        },
        {
            "name": "Viper",
            "Riki": -1.064
        },
        {
            "name": "Visage",
            "Riki": -2.938
        },
        {
            "name": "Void Spirit",
            "Riki": -1.691
        },
        {
            "name": "Warlock",
            "Riki": -0.225
        },
        {
            "name": "Weaver",
            "Riki": -2.561
        },
        {
            "name": "Windranger",
            "Riki": 1.112
        },
        {
            "name": "Winter Wyvern",
            "Riki": 0.247
        },
        {
            "name": "Witch Doctor",
            "Riki": -2.228
        },
        {
            "name": "Wraith King",
            "Riki": 1.438
        },
        {
            "name": "Zeus",
            "Riki": 1.819
        }
    ],
    "Shadow Fiend": [
        {
            "name": "Abaddon",
            "Shadow Fiend": -1.467
        },
        {
            "name": "Alchemist",
            "Shadow Fiend": -0.446
        },
        {
            "name": "Ancient Apparition",
            "Shadow Fiend": -0.189
        },
        {
            "name": "Anti-Mage",
            "Shadow Fiend": -2.685
        },
        {
            "name": "Arc Warden",
            "Shadow Fiend": 0.064
        },
        {
            "name": "Axe",
            "Shadow Fiend": 0.245
        },
        {
            "name": "Bane",
            "Shadow Fiend": -0.947
        },
        {
            "name": "Batrider",
            "Shadow Fiend": 1.235
        },
        {
            "name": "Beastmaster",
            "Shadow Fiend": -0.402
        },
        {
            "name": "Bloodseeker",
            "Shadow Fiend": -0.17
        },
        {
            "name": "Bounty Hunter",
            "Shadow Fiend": 0.439
        },
        {
            "name": "Brewmaster",
            "Shadow Fiend": 1.553
        },
        {
            "name": "Bristleback",
            "Shadow Fiend": 0.97
        },
        {
            "name": "Broodmother",
            "Shadow Fiend": -1.889
        },
        {
            "name": "Centaur Warrunner",
            "Shadow Fiend": 0.512
        },
        {
            "name": "Chaos Knight",
            "Shadow Fiend": 0.082
        },
        {
            "name": "Chen",
            "Shadow Fiend": 0.007
        },
        {
            "name": "Clinkz",
            "Shadow Fiend": 0.284
        },
        {
            "name": "Clockwerk",
            "Shadow Fiend": 0.477
        },
        {
            "name": "Crystal Maiden",
            "Shadow Fiend": -1.02
        },
        {
            "name": "Dark Seer",
            "Shadow Fiend": 0.698
        },
        {
            "name": "Dark Willow",
            "Shadow Fiend": -0.592
        },
        {
            "name": "Dawnbreaker",
            "Shadow Fiend": -0.517
        },
        {
            "name": "Dazzle",
            "Shadow Fiend": -1.346
        },
        {
            "name": "Death Prophet",
            "Shadow Fiend": -0.241
        },
        {
            "name": "Disruptor",
            "Shadow Fiend": -0.241
        },
        {
            "name": "Doom",
            "Shadow Fiend": -0.682
        },
        {
            "name": "Dragon Knight",
            "Shadow Fiend": 0.591
        },
        {
            "name": "Drow Ranger",
            "Shadow Fiend": 0.774
        },
        {
            "name": "Earth Spirit",
            "Shadow Fiend": 0.163
        },
        {
            "name": "Earthshaker",
            "Shadow Fiend": -0.118
        },
        {
            "name": "Elder Titan",
            "Shadow Fiend": -1.368
        },
        {
            "name": "Ember Spirit",
            "Shadow Fiend": -2.208
        },
        {
            "name": "Enchantress",
            "Shadow Fiend": 0.522
        },
        {
            "name": "Enigma",
            "Shadow Fiend": -0.147
        },
        {
            "name": "Faceless Void",
            "Shadow Fiend": -0.787
        },
        {
            "name": "Grimstroke",
            "Shadow Fiend": -0.379
        },
        {
            "name": "Gyrocopter",
            "Shadow Fiend": 1.289
        },
        {
            "name": "Hoodwink",
            "Shadow Fiend": 1.487
        },
        {
            "name": "Huskar",
            "Shadow Fiend": -1.047
        },
        {
            "name": "Invoker",
            "Shadow Fiend": 0.573
        },
        {
            "name": "Io",
            "Shadow Fiend": -1.559
        },
        {
            "name": "Jakiro",
            "Shadow Fiend": 0.458
        },
        {
            "name": "Juggernaut",
            "Shadow Fiend": 0.205
        },
        {
            "name": "Keeper of the Light",
            "Shadow Fiend": 0.406
        },
        {
            "name": "Kez",
            "Shadow Fiend": 0.688
        },
        {
            "name": "Kunkka",
            "Shadow Fiend": 0.591
        },
        {
            "name": "Legion Commander",
            "Shadow Fiend": -0.711
        },
        {
            "name": "Leshrac",
            "Shadow Fiend": -0.978
        },
        {
            "name": "Lich",
            "Shadow Fiend": 0.874
        },
        {
            "name": "Lifestealer",
            "Shadow Fiend": 0.257
        },
        {
            "name": "Lina",
            "Shadow Fiend": 1.97
        },
        {
            "name": "Lion",
            "Shadow Fiend": 0.348
        },
        {
            "name": "Lone Druid",
            "Shadow Fiend": -0.384
        },
        {
            "name": "Luna",
            "Shadow Fiend": 1.446
        },
        {
            "name": "Lycan",
            "Shadow Fiend": 0.44
        },
        {
            "name": "Magnus",
            "Shadow Fiend": -0.472
        },
        {
            "name": "Marci",
            "Shadow Fiend": -0.426
        },
        {
            "name": "Mars",
            "Shadow Fiend": 0.163
        },
        {
            "name": "Medusa",
            "Shadow Fiend": 1.455
        },
        {
            "name": "Meepo",
            "Shadow Fiend": -5.25
        },
        {
            "name": "Mirana",
            "Shadow Fiend": 0.131
        },
        {
            "name": "Monkey King",
            "Shadow Fiend": -0.254
        },
        {
            "name": "Morphling",
            "Shadow Fiend": -0.743
        },
        {
            "name": "Muerta",
            "Shadow Fiend": -0.214
        },
        {
            "name": "Naga Siren",
            "Shadow Fiend": -0.943
        },
        {
            "name": "Nature's Prophet",
            "Shadow Fiend": -1.045
        },
        {
            "name": "Necrophos",
            "Shadow Fiend": -1.922
        },
        {
            "name": "Night Stalker",
            "Shadow Fiend": -0.822
        },
        {
            "name": "Nyx Assassin",
            "Shadow Fiend": 1.377
        },
        {
            "name": "Ogre Magi",
            "Shadow Fiend": 1.044
        },
        {
            "name": "Omniknight",
            "Shadow Fiend": -0.628
        },
        {
            "name": "Oracle",
            "Shadow Fiend": -2.561
        },
        {
            "name": "Outworld Destroyer",
            "Shadow Fiend": 0.416
        },
        {
            "name": "Pangolier",
            "Shadow Fiend": -1.792
        },
        {
            "name": "Phantom Assassin",
            "Shadow Fiend": -0.075
        },
        {
            "name": "Phantom Lancer",
            "Shadow Fiend": 1.16
        },
        {
            "name": "Phoenix",
            "Shadow Fiend": 0.647
        },
        {
            "name": "Primal Beast",
            "Shadow Fiend": 2.307
        },
        {
            "name": "Puck",
            "Shadow Fiend": -1.026
        },
        {
            "name": "Pudge",
            "Shadow Fiend": 0.095
        },
        {
            "name": "Pugna",
            "Shadow Fiend": -1.197
        },
        {
            "name": "Queen of Pain",
            "Shadow Fiend": -0.427
        },
        {
            "name": "Razor",
            "Shadow Fiend": -0.187
        },
        {
            "name": "Riki",
            "Shadow Fiend": -0.511
        },
        {
            "name": "Ringmaster",
            "Shadow Fiend": -0.156
        },
        {
            "name": "Rubick",
            "Shadow Fiend": -0.235
        },
        {
            "name": "Sand King",
            "Shadow Fiend": 0.149
        },
        {
            "name": "Shadow Demon",
            "Shadow Fiend": 0.954
        },
        {
            "name": "Shadow Shaman",
            "Shadow Fiend": 0.482
        },
        {
            "name": "Silencer",
            "Shadow Fiend": 0.025
        },
        {
            "name": "Skywrath Mage",
            "Shadow Fiend": 0.185
        },
        {
            "name": "Slardar",
            "Shadow Fiend": 0.189
        },
        {
            "name": "Slark",
            "Shadow Fiend": -1.769
        },
        {
            "name": "Snapfire",
            "Shadow Fiend": 0.165
        },
        {
            "name": "Sniper",
            "Shadow Fiend": 1.426
        },
        {
            "name": "Spectre",
            "Shadow Fiend": 0.189
        },
        {
            "name": "Spirit Breaker",
            "Shadow Fiend": 1.622
        },
        {
            "name": "Storm Spirit",
            "Shadow Fiend": -1.114
        },
        {
            "name": "Sven",
            "Shadow Fiend": -0.433
        },
        {
            "name": "Techies",
            "Shadow Fiend": 0.73
        },
        {
            "name": "Templar Assassin",
            "Shadow Fiend": 1.089
        },
        {
            "name": "Terrorblade",
            "Shadow Fiend": 0.776
        },
        {
            "name": "Tidehunter",
            "Shadow Fiend": -1.311
        },
        {
            "name": "Timbersaw",
            "Shadow Fiend": -0.212
        },
        {
            "name": "Tinker",
            "Shadow Fiend": -1.718
        },
        {
            "name": "Tiny",
            "Shadow Fiend": 0.471
        },
        {
            "name": "Treant Protector",
            "Shadow Fiend": 0.979
        },
        {
            "name": "Troll Warlord",
            "Shadow Fiend": 1.161
        },
        {
            "name": "Tusk",
            "Shadow Fiend": -0.224
        },
        {
            "name": "Underlord",
            "Shadow Fiend": -0.513
        },
        {
            "name": "Undying",
            "Shadow Fiend": -0.432
        },
        {
            "name": "Ursa",
            "Shadow Fiend": 1.0
        },
        {
            "name": "Vengeful Spirit",
            "Shadow Fiend": 0.492
        },
        {
            "name": "Venomancer",
            "Shadow Fiend": 0.345
        },
        {
            "name": "Viper",
            "Shadow Fiend": 0.364
        },
        {
            "name": "Visage",
            "Shadow Fiend": 0.999
        },
        {
            "name": "Void Spirit",
            "Shadow Fiend": -1.364
        },
        {
            "name": "Warlock",
            "Shadow Fiend": -1.602
        },
        {
            "name": "Weaver",
            "Shadow Fiend": -0.638
        },
        {
            "name": "Windranger",
            "Shadow Fiend": 1.675
        },
        {
            "name": "Winter Wyvern",
            "Shadow Fiend": -0.591
        },
        {
            "name": "Witch Doctor",
            "Shadow Fiend": -0.6
        },
        {
            "name": "Wraith King",
            "Shadow Fiend": 1.295
        },
        {
            "name": "Zeus",
            "Shadow Fiend": 1.879
        }
    ],
    "Slark": [
        {
            "name": "Abaddon",
            "Slark": -2.151
        },
        {
            "name": "Alchemist",
            "Slark": -1.358
        },
        {
            "name": "Ancient Apparition",
            "Slark": 0.661
        },
        {
            "name": "Anti-Mage",
            "Slark": -0.514
        },
        {
            "name": "Arc Warden",
            "Slark": -0.886
        },
        {
            "name": "Axe",
            "Slark": 0.728
        },
        {
            "name": "Bane",
            "Slark": -1.482
        },
        {
            "name": "Batrider",
            "Slark": -0.647
        },
        {
            "name": "Beastmaster",
            "Slark": 0.645
        },
        {
            "name": "Bloodseeker",
            "Slark": 2.687
        },
        {
            "name": "Bounty Hunter",
            "Slark": -2.335
        },
        {
            "name": "Brewmaster",
            "Slark": -1.806
        },
        {
            "name": "Bristleback",
            "Slark": -4.549
        },
        {
            "name": "Broodmother",
            "Slark": 0.537
        },
        {
            "name": "Centaur Warrunner",
            "Slark": 0.289
        },
        {
            "name": "Chaos Knight",
            "Slark": 0.49
        },
        {
            "name": "Chen",
            "Slark": -1.266
        },
        {
            "name": "Clinkz",
            "Slark": -0.377
        },
        {
            "name": "Clockwerk",
            "Slark": 2.723
        },
        {
            "name": "Crystal Maiden",
            "Slark": 1.827
        },
        {
            "name": "Dark Seer",
            "Slark": -1.272
        },
        {
            "name": "Dark Willow",
            "Slark": 0.539
        },
        {
            "name": "Dawnbreaker",
            "Slark": 1.828
        },
        {
            "name": "Dazzle",
            "Slark": -2.95
        },
        {
            "name": "Death Prophet",
            "Slark": -0.263
        },
        {
            "name": "Disruptor",
            "Slark": 4.148
        },
        {
            "name": "Doom",
            "Slark": 1.389
        },
        {
            "name": "Dragon Knight",
            "Slark": -1.997
        },
        {
            "name": "Drow Ranger",
            "Slark": 1.478
        },
        {
            "name": "Earth Spirit",
            "Slark": -0.586
        },
        {
            "name": "Earthshaker",
            "Slark": 2.482
        },
        {
            "name": "Elder Titan",
            "Slark": 3.998
        },
        {
            "name": "Ember Spirit",
            "Slark": -4.145
        },
        {
            "name": "Enchantress",
            "Slark": -1.051
        },
        {
            "name": "Enigma",
            "Slark": 1.598
        },
        {
            "name": "Faceless Void",
            "Slark": -1.641
        },
        {
            "name": "Grimstroke",
            "Slark": 3.291
        },
        {
            "name": "Gyrocopter",
            "Slark": 1.037
        },
        {
            "name": "Hoodwink",
            "Slark": 0.495
        },
        {
            "name": "Huskar",
            "Slark": 0.794
        },
        {
            "name": "Invoker",
            "Slark": 2.466
        },
        {
            "name": "Io",
            "Slark": -0.443
        },
        {
            "name": "Jakiro",
            "Slark": -0.964
        },
        {
            "name": "Juggernaut",
            "Slark": -1.387
        },
        {
            "name": "Keeper of the Light",
            "Slark": -1.077
        },
        {
            "name": "Kez",
            "Slark": -0.488
        },
        {
            "name": "Kunkka",
            "Slark": 0.787
        },
        {
            "name": "Legion Commander",
            "Slark": 1.555
        },
        {
            "name": "Leshrac",
            "Slark": 3.871
        },
        {
            "name": "Lich",
            "Slark": -0.728
        },
        {
            "name": "Lifestealer",
            "Slark": -1.585
        },
        {
            "name": "Lina",
            "Slark": -0.246
        },
        {
            "name": "Lion",
            "Slark": 1.939
        },
        {
            "name": "Lone Druid",
            "Slark": 0.381
        },
        {
            "name": "Luna",
            "Slark": -1.056
        },
        {
            "name": "Lycan",
            "Slark": -0.129
        },
        {
            "name": "Magnus",
            "Slark": 1.187
        },
        {
            "name": "Marci",
            "Slark": 0.762
        },
        {
            "name": "Mars",
            "Slark": 3.748
        },
        {
            "name": "Medusa",
            "Slark": -4.299
        },
        {
            "name": "Meepo",
            "Slark": -1.127
        },
        {
            "name": "Mirana",
            "Slark": 0.324
        },
        {
            "name": "Monkey King",
            "Slark": 0.157
        },
        {
            "name": "Morphling",
            "Slark": -0.947
        },
        {
            "name": "Muerta",
            "Slark": 2.406
        },
        {
            "name": "Naga Siren",
            "Slark": 1.856
        },
        {
            "name": "Nature's Prophet",
            "Slark": -0.582
        },
        {
            "name": "Necrophos",
            "Slark": 1.558
        },
        {
            "name": "Night Stalker",
            "Slark": 3.103
        },
        {
            "name": "Nyx Assassin",
            "Slark": 0.258
        },
        {
            "name": "Ogre Magi",
            "Slark": -1.859
        },
        {
            "name": "Omniknight",
            "Slark": 1.742
        },
        {
            "name": "Oracle",
            "Slark": -0.379
        },
        {
            "name": "Outworld Destroyer",
            "Slark": 1.628
        },
        {
            "name": "Pangolier",
            "Slark": 0.789
        },
        {
            "name": "Phantom Assassin",
            "Slark": 0.113
        },
        {
            "name": "Phantom Lancer",
            "Slark": 1.636
        },
        {
            "name": "Phoenix",
            "Slark": -0.969
        },
        {
            "name": "Primal Beast",
            "Slark": 1.169
        },
        {
            "name": "Puck",
            "Slark": 1.905
        },
        {
            "name": "Pudge",
            "Slark": 0.359
        },
        {
            "name": "Pugna",
            "Slark": 2.039
        },
        {
            "name": "Queen of Pain",
            "Slark": -0.252
        },
        {
            "name": "Razor",
            "Slark": -1.028
        },
        {
            "name": "Riki",
            "Slark": 2.954
        },
        {
            "name": "Ringmaster",
            "Slark": 2.37
        },
        {
            "name": "Rubick",
            "Slark": -0.415
        },
        {
            "name": "Sand King",
            "Slark": 1.112
        },
        {
            "name": "Shadow Demon",
            "Slark": 1.623
        },
        {
            "name": "Shadow Fiend",
            "Slark": 1.761
        },
        {
            "name": "Shadow Shaman",
            "Slark": 0.355
        },
        {
            "name": "Silencer",
            "Slark": -2.299
        },
        {
            "name": "Skywrath Mage",
            "Slark": 0.439
        },
        {
            "name": "Slardar",
            "Slark": -1.025
        },
        {
            "name": "Snapfire",
            "Slark": 0.929
        },
        {
            "name": "Sniper",
            "Slark": -1.429
        },
        {
            "name": "Spectre",
            "Slark": 0.196
        },
        {
            "name": "Spirit Breaker",
            "Slark": 0.374
        },
        {
            "name": "Storm Spirit",
            "Slark": -1.572
        },
        {
            "name": "Sven",
            "Slark": -0.331
        },
        {
            "name": "Techies",
            "Slark": 3.08
        },
        {
            "name": "Templar Assassin",
            "Slark": -1.165
        },
        {
            "name": "Terrorblade",
            "Slark": -0.989
        },
        {
            "name": "Tidehunter",
            "Slark": -2.594
        },
        {
            "name": "Timbersaw",
            "Slark": 2.261
        },
        {
            "name": "Tinker",
            "Slark": 0.672
        },
        {
            "name": "Tiny",
            "Slark": 1.823
        },
        {
            "name": "Treant Protector",
            "Slark": -1.664
        },
        {
            "name": "Troll Warlord",
            "Slark": -3.691
        },
        {
            "name": "Tusk",
            "Slark": 1.172
        },
        {
            "name": "Underlord",
            "Slark": -0.789
        },
        {
            "name": "Undying",
            "Slark": -0.584
        },
        {
            "name": "Ursa",
            "Slark": -0.223
        },
        {
            "name": "Vengeful Spirit",
            "Slark": -0.243
        },
        {
            "name": "Venomancer",
            "Slark": -1.609
        },
        {
            "name": "Viper",
            "Slark": -1.132
        },
        {
            "name": "Visage",
            "Slark": 0.074
        },
        {
            "name": "Void Spirit",
            "Slark": -0.921
        },
        {
            "name": "Warlock",
            "Slark": -1.113
        },
        {
            "name": "Weaver",
            "Slark": -1.038
        },
        {
            "name": "Windranger",
            "Slark": 1.152
        },
        {
            "name": "Winter Wyvern",
            "Slark": -1.351
        },
        {
            "name": "Witch Doctor",
            "Slark": -1.149
        },
        {
            "name": "Wraith King",
            "Slark": -2.64
        },
        {
            "name": "Zeus",
            "Slark": 0.397
        }
    ],
    "Sniper": [
        {
            "name": "Abaddon",
            "Sniper": -1.089
        },
        {
            "name": "Alchemist",
            "Sniper": -0.569
        },
        {
            "name": "Ancient Apparition",
            "Sniper": -1.311
        },
        {
            "name": "Anti-Mage",
            "Sniper": -0.246
        },
        {
            "name": "Arc Warden",
            "Sniper": 1.916
        },
        {
            "name": "Axe",
            "Sniper": 1.678
        },
        {
            "name": "Bane",
            "Sniper": 0.103
        },
        {
            "name": "Batrider",
            "Sniper": 1.031
        },
        {
            "name": "Beastmaster",
            "Sniper": -0.622
        },
        {
            "name": "Bloodseeker",
            "Sniper": -1.31
        },
        {
            "name": "Bounty Hunter",
            "Sniper": 0.031
        },
        {
            "name": "Brewmaster",
            "Sniper": 0.709
        },
        {
            "name": "Bristleback",
            "Sniper": -0.363
        },
        {
            "name": "Broodmother",
            "Sniper": -0.987
        },
        {
            "name": "Centaur Warrunner",
            "Sniper": 2.979
        },
        {
            "name": "Chaos Knight",
            "Sniper": 0.781
        },
        {
            "name": "Chen",
            "Sniper": 2.399
        },
        {
            "name": "Clinkz",
            "Sniper": -0.76
        },
        {
            "name": "Clockwerk",
            "Sniper": 0.968
        },
        {
            "name": "Crystal Maiden",
            "Sniper": -0.68
        },
        {
            "name": "Dark Seer",
            "Sniper": -0.43
        },
        {
            "name": "Dark Willow",
            "Sniper": 0.457
        },
        {
            "name": "Dawnbreaker",
            "Sniper": -0.417
        },
        {
            "name": "Dazzle",
            "Sniper": -1.457
        },
        {
            "name": "Death Prophet",
            "Sniper": -1.483
        },
        {
            "name": "Disruptor",
            "Sniper": -2.124
        },
        {
            "name": "Doom",
            "Sniper": 0.174
        },
        {
            "name": "Dragon Knight",
            "Sniper": -1.02
        },
        {
            "name": "Drow Ranger",
            "Sniper": -1.631
        },
        {
            "name": "Earth Spirit",
            "Sniper": 2.44
        },
        {
            "name": "Earthshaker",
            "Sniper": -1.409
        },
        {
            "name": "Elder Titan",
            "Sniper": -1.128
        },
        {
            "name": "Ember Spirit",
            "Sniper": -0.643
        },
        {
            "name": "Enchantress",
            "Sniper": 1.833
        },
        {
            "name": "Enigma",
            "Sniper": -1.818
        },
        {
            "name": "Faceless Void",
            "Sniper": 0.138
        },
        {
            "name": "Grimstroke",
            "Sniper": -1.543
        },
        {
            "name": "Gyrocopter",
            "Sniper": -1.031
        },
        {
            "name": "Hoodwink",
            "Sniper": 0.568
        },
        {
            "name": "Huskar",
            "Sniper": -2.714
        },
        {
            "name": "Invoker",
            "Sniper": 0.335
        },
        {
            "name": "Io",
            "Sniper": -0.471
        },
        {
            "name": "Jakiro",
            "Sniper": -1.291
        },
        {
            "name": "Juggernaut",
            "Sniper": -0.745
        },
        {
            "name": "Keeper of the Light",
            "Sniper": -0.325
        },
        {
            "name": "Kez",
            "Sniper": 0.211
        },
        {
            "name": "Kunkka",
            "Sniper": 0.794
        },
        {
            "name": "Legion Commander",
            "Sniper": -0.211
        },
        {
            "name": "Leshrac",
            "Sniper": -1.76
        },
        {
            "name": "Lich",
            "Sniper": -0.524
        },
        {
            "name": "Lifestealer",
            "Sniper": -0.469
        },
        {
            "name": "Lina",
            "Sniper": 0.081
        },
        {
            "name": "Lion",
            "Sniper": -0.48
        },
        {
            "name": "Lone Druid",
            "Sniper": -0.629
        },
        {
            "name": "Luna",
            "Sniper": -0.213
        },
        {
            "name": "Lycan",
            "Sniper": 2.675
        },
        {
            "name": "Magnus",
            "Sniper": -0.18
        },
        {
            "name": "Marci",
            "Sniper": 0.189
        },
        {
            "name": "Mars",
            "Sniper": 2.427
        },
        {
            "name": "Medusa",
            "Sniper": -4.031
        },
        {
            "name": "Meepo",
            "Sniper": 1.278
        },
        {
            "name": "Mirana",
            "Sniper": 0.716
        },
        {
            "name": "Monkey King",
            "Sniper": -0.809
        },
        {
            "name": "Morphling",
            "Sniper": 3.125
        },
        {
            "name": "Muerta",
            "Sniper": -0.562
        },
        {
            "name": "Naga Siren",
            "Sniper": 1.147
        },
        {
            "name": "Nature's Prophet",
            "Sniper": 0.26
        },
        {
            "name": "Necrophos",
            "Sniper": -1.86
        },
        {
            "name": "Night Stalker",
            "Sniper": -1.322
        },
        {
            "name": "Nyx Assassin",
            "Sniper": 2.059
        },
        {
            "name": "Ogre Magi",
            "Sniper": 0.149
        },
        {
            "name": "Omniknight",
            "Sniper": -0.464
        },
        {
            "name": "Oracle",
            "Sniper": -0.785
        },
        {
            "name": "Outworld Destroyer",
            "Sniper": -0.973
        },
        {
            "name": "Pangolier",
            "Sniper": 0.829
        },
        {
            "name": "Phantom Assassin",
            "Sniper": 1.315
        },
        {
            "name": "Phantom Lancer",
            "Sniper": 1.734
        },
        {
            "name": "Phoenix",
            "Sniper": -0.507
        },
        {
            "name": "Primal Beast",
            "Sniper": 2.595
        },
        {
            "name": "Puck",
            "Sniper": -1.18
        },
        {
            "name": "Pudge",
            "Sniper": 2.751
        },
        {
            "name": "Pugna",
            "Sniper": -1.018
        },
        {
            "name": "Queen of Pain",
            "Sniper": 0.823
        },
        {
            "name": "Razor",
            "Sniper": -0.858
        },
        {
            "name": "Riki",
            "Sniper": -1.033
        },
        {
            "name": "Ringmaster",
            "Sniper": 0.145
        },
        {
            "name": "Rubick",
            "Sniper": -0.004
        },
        {
            "name": "Sand King",
            "Sniper": -0.075
        },
        {
            "name": "Shadow Demon",
            "Sniper": 0.937
        },
        {
            "name": "Shadow Fiend",
            "Sniper": -1.234
        },
        {
            "name": "Shadow Shaman",
            "Sniper": -1.097
        },
        {
            "name": "Silencer",
            "Sniper": -1.691
        },
        {
            "name": "Skywrath Mage",
            "Sniper": -0.152
        },
        {
            "name": "Slardar",
            "Sniper": -0.931
        },
        {
            "name": "Slark",
            "Sniper": 1.237
        },
        {
            "name": "Snapfire",
            "Sniper": 0.983
        },
        {
            "name": "Spectre",
            "Sniper": 3.085
        },
        {
            "name": "Spirit Breaker",
            "Sniper": 1.402
        },
        {
            "name": "Storm Spirit",
            "Sniper": 3.085
        },
        {
            "name": "Sven",
            "Sniper": -0.406
        },
        {
            "name": "Techies",
            "Sniper": 0.615
        },
        {
            "name": "Templar Assassin",
            "Sniper": -0.538
        },
        {
            "name": "Terrorblade",
            "Sniper": 0.007
        },
        {
            "name": "Tidehunter",
            "Sniper": -1.884
        },
        {
            "name": "Timbersaw",
            "Sniper": 1.427
        },
        {
            "name": "Tinker",
            "Sniper": -0.579
        },
        {
            "name": "Tiny",
            "Sniper": 0.093
        },
        {
            "name": "Treant Protector",
            "Sniper": 0.058
        },
        {
            "name": "Troll Warlord",
            "Sniper": -0.473
        },
        {
            "name": "Tusk",
            "Sniper": 0.493
        },
        {
            "name": "Underlord",
            "Sniper": -1.694
        },
        {
            "name": "Undying",
            "Sniper": -1.227
        },
        {
            "name": "Ursa",
            "Sniper": 0.396
        },
        {
            "name": "Vengeful Spirit",
            "Sniper": -0.301
        },
        {
            "name": "Venomancer",
            "Sniper": -1.503
        },
        {
            "name": "Viper",
            "Sniper": -0.725
        },
        {
            "name": "Visage",
            "Sniper": -1.052
        },
        {
            "name": "Void Spirit",
            "Sniper": 1.542
        },
        {
            "name": "Warlock",
            "Sniper": -0.947
        },
        {
            "name": "Weaver",
            "Sniper": 0.543
        },
        {
            "name": "Windranger",
            "Sniper": 1.299
        },
        {
            "name": "Winter Wyvern",
            "Sniper": -1.574
        },
        {
            "name": "Witch Doctor",
            "Sniper": -1.315
        },
        {
            "name": "Wraith King",
            "Sniper": -0.633
        },
        {
            "name": "Zeus",
            "Sniper": 1.012
        }
    ],
    "Spectre": [
        {
            "name": "Abaddon",
            "Spectre": 4.152
        },
        {
            "name": "Alchemist",
            "Spectre": 0.701
        },
        {
            "name": "Ancient Apparition",
            "Spectre": -2.91
        },
        {
            "name": "Anti-Mage",
            "Spectre": 0.026
        },
        {
            "name": "Arc Warden",
            "Spectre": -0.435
        },
        {
            "name": "Axe",
            "Spectre": -2.294
        },
        {
            "name": "Bane",
            "Spectre": -1.607
        },
        {
            "name": "Batrider",
            "Spectre": 0.963
        },
        {
            "name": "Beastmaster",
            "Spectre": 3.71
        },
        {
            "name": "Bloodseeker",
            "Spectre": -0.506
        },
        {
            "name": "Bounty Hunter",
            "Spectre": 0.824
        },
        {
            "name": "Brewmaster",
            "Spectre": 0.495
        },
        {
            "name": "Bristleback",
            "Spectre": 1.813
        },
        {
            "name": "Broodmother",
            "Spectre": 1.765
        },
        {
            "name": "Centaur Warrunner",
            "Spectre": 0.861
        },
        {
            "name": "Chaos Knight",
            "Spectre": 0.388
        },
        {
            "name": "Chen",
            "Spectre": 2.928
        },
        {
            "name": "Clinkz",
            "Spectre": -3.448
        },
        {
            "name": "Clockwerk",
            "Spectre": 0.864
        },
        {
            "name": "Crystal Maiden",
            "Spectre": 0.944
        },
        {
            "name": "Dark Seer",
            "Spectre": 1.171
        },
        {
            "name": "Dark Willow",
            "Spectre": -0.221
        },
        {
            "name": "Dawnbreaker",
            "Spectre": 2.283
        },
        {
            "name": "Dazzle",
            "Spectre": 0.571
        },
        {
            "name": "Death Prophet",
            "Spectre": 0.062
        },
        {
            "name": "Disruptor",
            "Spectre": 0.484
        },
        {
            "name": "Doom",
            "Spectre": -1.063
        },
        {
            "name": "Dragon Knight",
            "Spectre": 0.306
        },
        {
            "name": "Drow Ranger",
            "Spectre": -2.153
        },
        {
            "name": "Earth Spirit",
            "Spectre": 0.734
        },
        {
            "name": "Earthshaker",
            "Spectre": -0.66
        },
        {
            "name": "Elder Titan",
            "Spectre": -0.307
        },
        {
            "name": "Ember Spirit",
            "Spectre": 2.466
        },
        {
            "name": "Enchantress",
            "Spectre": 2.261
        },
        {
            "name": "Enigma",
            "Spectre": -1.652
        },
        {
            "name": "Faceless Void",
            "Spectre": -1.29
        },
        {
            "name": "Grimstroke",
            "Spectre": -1.893
        },
        {
            "name": "Gyrocopter",
            "Spectre": -0.398
        },
        {
            "name": "Hoodwink",
            "Spectre": 0.312
        },
        {
            "name": "Huskar",
            "Spectre": 0.748
        },
        {
            "name": "Invoker",
            "Spectre": 0.399
        },
        {
            "name": "Io",
            "Spectre": 2.548
        },
        {
            "name": "Jakiro",
            "Spectre": 1.767
        },
        {
            "name": "Juggernaut",
            "Spectre": -0.08
        },
        {
            "name": "Keeper of the Light",
            "Spectre": 1.154
        },
        {
            "name": "Kez",
            "Spectre": 2.919
        },
        {
            "name": "Kunkka",
            "Spectre": 0.25
        },
        {
            "name": "Legion Commander",
            "Spectre": 0.573
        },
        {
            "name": "Leshrac",
            "Spectre": 1.305
        },
        {
            "name": "Lich",
            "Spectre": -0.175
        },
        {
            "name": "Lifestealer",
            "Spectre": 0.548
        },
        {
            "name": "Lina",
            "Spectre": 0.731
        },
        {
            "name": "Lion",
            "Spectre": -0.977
        },
        {
            "name": "Lone Druid",
            "Spectre": 2.651
        },
        {
            "name": "Luna",
            "Spectre": -1.084
        },
        {
            "name": "Lycan",
            "Spectre": 2.379
        },
        {
            "name": "Magnus",
            "Spectre": 0.879
        },
        {
            "name": "Marci",
            "Spectre": 2.701
        },
        {
            "name": "Mars",
            "Spectre": -0.686
        },
        {
            "name": "Medusa",
            "Spectre": 1.987
        },
        {
            "name": "Meepo",
            "Spectre": 3.548
        },
        {
            "name": "Mirana",
            "Spectre": -0.627
        },
        {
            "name": "Monkey King",
            "Spectre": -0.183
        },
        {
            "name": "Morphling",
            "Spectre": 0.363
        },
        {
            "name": "Muerta",
            "Spectre": -1.627
        },
        {
            "name": "Naga Siren",
            "Spectre": 1.251
        },
        {
            "name": "Nature's Prophet",
            "Spectre": -3.448
        },
        {
            "name": "Necrophos",
            "Spectre": 1.382
        },
        {
            "name": "Night Stalker",
            "Spectre": -0.065
        },
        {
            "name": "Nyx Assassin",
            "Spectre": -0.517
        },
        {
            "name": "Ogre Magi",
            "Spectre": 0.075
        },
        {
            "name": "Omniknight",
            "Spectre": 1.598
        },
        {
            "name": "Oracle",
            "Spectre": 1.354
        },
        {
            "name": "Outworld Destroyer",
            "Spectre": -0.352
        },
        {
            "name": "Pangolier",
            "Spectre": 1.085
        },
        {
            "name": "Phantom Assassin",
            "Spectre": 0.759
        },
        {
            "name": "Phantom Lancer",
            "Spectre": 2.06
        },
        {
            "name": "Phoenix",
            "Spectre": 0.494
        },
        {
            "name": "Primal Beast",
            "Spectre": 0.899
        },
        {
            "name": "Puck",
            "Spectre": -2.288
        },
        {
            "name": "Pudge",
            "Spectre": 1.207
        },
        {
            "name": "Pugna",
            "Spectre": -0.602
        },
        {
            "name": "Queen of Pain",
            "Spectre": 0.318
        },
        {
            "name": "Razor",
            "Spectre": -0.314
        },
        {
            "name": "Riki",
            "Spectre": -2.271
        },
        {
            "name": "Ringmaster",
            "Spectre": 0.726
        },
        {
            "name": "Rubick",
            "Spectre": -0.337
        },
        {
            "name": "Sand King",
            "Spectre": -0.221
        },
        {
            "name": "Shadow Demon",
            "Spectre": 0.265
        },
        {
            "name": "Shadow Fiend",
            "Spectre": -0.167
        },
        {
            "name": "Shadow Shaman",
            "Spectre": -1.154
        },
        {
            "name": "Silencer",
            "Spectre": -1.086
        },
        {
            "name": "Skywrath Mage",
            "Spectre": -0.178
        },
        {
            "name": "Slardar",
            "Spectre": 1.719
        },
        {
            "name": "Slark",
            "Spectre": -0.222
        },
        {
            "name": "Snapfire",
            "Spectre": 0.348
        },
        {
            "name": "Sniper",
            "Spectre": -3.79
        },
        {
            "name": "Spirit Breaker",
            "Spectre": -1.137
        },
        {
            "name": "Storm Spirit",
            "Spectre": -0.375
        },
        {
            "name": "Sven",
            "Spectre": 1.555
        },
        {
            "name": "Techies",
            "Spectre": -1.311
        },
        {
            "name": "Templar Assassin",
            "Spectre": -1.119
        },
        {
            "name": "Terrorblade",
            "Spectre": 0.807
        },
        {
            "name": "Tidehunter",
            "Spectre": 2.924
        },
        {
            "name": "Timbersaw",
            "Spectre": -0.736
        },
        {
            "name": "Tinker",
            "Spectre": 0.122
        },
        {
            "name": "Tiny",
            "Spectre": 0.831
        },
        {
            "name": "Treant Protector",
            "Spectre": 1.07
        },
        {
            "name": "Troll Warlord",
            "Spectre": -0.588
        },
        {
            "name": "Tusk",
            "Spectre": -0.115
        },
        {
            "name": "Underlord",
            "Spectre": 1.518
        },
        {
            "name": "Undying",
            "Spectre": 2.849
        },
        {
            "name": "Ursa",
            "Spectre": -1.463
        },
        {
            "name": "Vengeful Spirit",
            "Spectre": -0.801
        },
        {
            "name": "Venomancer",
            "Spectre": 0.952
        },
        {
            "name": "Viper",
            "Spectre": 0.744
        },
        {
            "name": "Visage",
            "Spectre": 1.01
        },
        {
            "name": "Void Spirit",
            "Spectre": 0.569
        },
        {
            "name": "Warlock",
            "Spectre": 1.823
        },
        {
            "name": "Weaver",
            "Spectre": -0.503
        },
        {
            "name": "Windranger",
            "Spectre": -0.96
        },
        {
            "name": "Winter Wyvern",
            "Spectre": -0.449
        },
        {
            "name": "Witch Doctor",
            "Spectre": 0.165
        },
        {
            "name": "Wraith King",
            "Spectre": 0.632
        },
        {
            "name": "Zeus",
            "Spectre": -1.488
        }
    ],
    "Templar Assassin": [
        {
            "name": "Abaddon",
            "Templar Assassin": 0.223
        },
        {
            "name": "Alchemist",
            "Templar Assassin": 0.606
        },
        {
            "name": "Ancient Apparition",
            "Templar Assassin": -0.673
        },
        {
            "name": "Anti-Mage",
            "Templar Assassin": -1.864
        },
        {
            "name": "Arc Warden",
            "Templar Assassin": 2.845
        },
        {
            "name": "Axe",
            "Templar Assassin": 1.262
        },
        {
            "name": "Bane",
            "Templar Assassin": -1.2
        },
        {
            "name": "Batrider",
            "Templar Assassin": 3.705
        },
        {
            "name": "Beastmaster",
            "Templar Assassin": 1.289
        },
        {
            "name": "Bloodseeker",
            "Templar Assassin": -0.245
        },
        {
            "name": "Bounty Hunter",
            "Templar Assassin": 0.607
        },
        {
            "name": "Brewmaster",
            "Templar Assassin": 1.505
        },
        {
            "name": "Bristleback",
            "Templar Assassin": 0.883
        },
        {
            "name": "Broodmother",
            "Templar Assassin": 0.186
        },
        {
            "name": "Centaur Warrunner",
            "Templar Assassin": -0.169
        },
        {
            "name": "Chaos Knight",
            "Templar Assassin": 0.934
        },
        {
            "name": "Chen",
            "Templar Assassin": -1.255
        },
        {
            "name": "Clinkz",
            "Templar Assassin": -0.221
        },
        {
            "name": "Clockwerk",
            "Templar Assassin": 0.351
        },
        {
            "name": "Crystal Maiden",
            "Templar Assassin": 0.914
        },
        {
            "name": "Dark Seer",
            "Templar Assassin": 2.093
        },
        {
            "name": "Dark Willow",
            "Templar Assassin": 1.692
        },
        {
            "name": "Dawnbreaker",
            "Templar Assassin": -0.204
        },
        {
            "name": "Dazzle",
            "Templar Assassin": -0.355
        },
        {
            "name": "Death Prophet",
            "Templar Assassin": -0.33
        },
        {
            "name": "Disruptor",
            "Templar Assassin": -1.074
        },
        {
            "name": "Doom",
            "Templar Assassin": -0.172
        },
        {
            "name": "Dragon Knight",
            "Templar Assassin": 2.034
        },
        {
            "name": "Drow Ranger",
            "Templar Assassin": 0.406
        },
        {
            "name": "Earth Spirit",
            "Templar Assassin": 0.522
        },
        {
            "name": "Earthshaker",
            "Templar Assassin": -2.22
        },
        {
            "name": "Elder Titan",
            "Templar Assassin": -1.661
        },
        {
            "name": "Ember Spirit",
            "Templar Assassin": -0.604
        },
        {
            "name": "Enchantress",
            "Templar Assassin": 1.454
        },
        {
            "name": "Enigma",
            "Templar Assassin": -2.161
        },
        {
            "name": "Faceless Void",
            "Templar Assassin": 0.904
        },
        {
            "name": "Grimstroke",
            "Templar Assassin": -0.536
        },
        {
            "name": "Gyrocopter",
            "Templar Assassin": 0.265
        },
        {
            "name": "Hoodwink",
            "Templar Assassin": 0.606
        },
        {
            "name": "Huskar",
            "Templar Assassin": -1.461
        },
        {
            "name": "Invoker",
            "Templar Assassin": -1.637
        },
        {
            "name": "Io",
            "Templar Assassin": -0.362
        },
        {
            "name": "Jakiro",
            "Templar Assassin": 0.697
        },
        {
            "name": "Juggernaut",
            "Templar Assassin": -1.537
        },
        {
            "name": "Keeper of the Light",
            "Templar Assassin": -1.72
        },
        {
            "name": "Kez",
            "Templar Assassin": -0.375
        },
        {
            "name": "Kunkka",
            "Templar Assassin": 0.141
        },
        {
            "name": "Legion Commander",
            "Templar Assassin": -1.11
        },
        {
            "name": "Leshrac",
            "Templar Assassin": 0.473
        },
        {
            "name": "Lich",
            "Templar Assassin": 0.098
        },
        {
            "name": "Lifestealer",
            "Templar Assassin": -0.421
        },
        {
            "name": "Lina",
            "Templar Assassin": -1.87
        },
        {
            "name": "Lion",
            "Templar Assassin": -0.641
        },
        {
            "name": "Lone Druid",
            "Templar Assassin": -0.078
        },
        {
            "name": "Luna",
            "Templar Assassin": 0.725
        },
        {
            "name": "Lycan",
            "Templar Assassin": -0.375
        },
        {
            "name": "Magnus",
            "Templar Assassin": -0.187
        },
        {
            "name": "Marci",
            "Templar Assassin": -1.332
        },
        {
            "name": "Mars",
            "Templar Assassin": 1.563
        },
        {
            "name": "Medusa",
            "Templar Assassin": 2.152
        },
        {
            "name": "Meepo",
            "Templar Assassin": 0.156
        },
        {
            "name": "Mirana",
            "Templar Assassin": -0.046
        },
        {
            "name": "Monkey King",
            "Templar Assassin": 0.53
        },
        {
            "name": "Morphling",
            "Templar Assassin": 0.673
        },
        {
            "name": "Muerta",
            "Templar Assassin": -0.396
        },
        {
            "name": "Naga Siren",
            "Templar Assassin": 2.521
        },
        {
            "name": "Nature's Prophet",
            "Templar Assassin": -1.728
        },
        {
            "name": "Necrophos",
            "Templar Assassin": 1.633
        },
        {
            "name": "Night Stalker",
            "Templar Assassin": -2.163
        },
        {
            "name": "Nyx Assassin",
            "Templar Assassin": -0.442
        },
        {
            "name": "Ogre Magi",
            "Templar Assassin": 1.351
        },
        {
            "name": "Omniknight",
            "Templar Assassin": -0.41
        },
        {
            "name": "Oracle",
            "Templar Assassin": -2.165
        },
        {
            "name": "Outworld Destroyer",
            "Templar Assassin": -2.23
        },
        {
            "name": "Pangolier",
            "Templar Assassin": -1.649
        },
        {
            "name": "Phantom Assassin",
            "Templar Assassin": -0.532
        },
        {
            "name": "Phantom Lancer",
            "Templar Assassin": 6.024
        },
        {
            "name": "Phoenix",
            "Templar Assassin": 2.224
        },
        {
            "name": "Primal Beast",
            "Templar Assassin": 0.57
        },
        {
            "name": "Puck",
            "Templar Assassin": -0.958
        },
        {
            "name": "Pudge",
            "Templar Assassin": 0.55
        },
        {
            "name": "Pugna",
            "Templar Assassin": -0.011
        },
        {
            "name": "Queen of Pain",
            "Templar Assassin": -1.165
        },
        {
            "name": "Razor",
            "Templar Assassin": -0.822
        },
        {
            "name": "Riki",
            "Templar Assassin": -0.152
        },
        {
            "name": "Ringmaster",
            "Templar Assassin": 0.386
        },
        {
            "name": "Rubick",
            "Templar Assassin": -2.112
        },
        {
            "name": "Sand King",
            "Templar Assassin": 2.205
        },
        {
            "name": "Shadow Demon",
            "Templar Assassin": 0.068
        },
        {
            "name": "Shadow Fiend",
            "Templar Assassin": -0.806
        },
        {
            "name": "Shadow Shaman",
            "Templar Assassin": 0.831
        },
        {
            "name": "Silencer",
            "Templar Assassin": -0.593
        },
        {
            "name": "Skywrath Mage",
            "Templar Assassin": -1.941
        },
        {
            "name": "Slardar",
            "Templar Assassin": 0.724
        },
        {
            "name": "Slark",
            "Templar Assassin": 1.846
        },
        {
            "name": "Snapfire",
            "Templar Assassin": -0.727
        },
        {
            "name": "Sniper",
            "Templar Assassin": 1.589
        },
        {
            "name": "Spectre",
            "Templar Assassin": 1.755
        },
        {
            "name": "Spirit Breaker",
            "Templar Assassin": 0.149
        },
        {
            "name": "Storm Spirit",
            "Templar Assassin": 0.91
        },
        {
            "name": "Sven",
            "Templar Assassin": -2.898
        },
        {
            "name": "Techies",
            "Templar Assassin": -1.791
        },
        {
            "name": "Terrorblade",
            "Templar Assassin": 2.857
        },
        {
            "name": "Tidehunter",
            "Templar Assassin": 0.689
        },
        {
            "name": "Timbersaw",
            "Templar Assassin": -1.634
        },
        {
            "name": "Tinker",
            "Templar Assassin": 0.848
        },
        {
            "name": "Tiny",
            "Templar Assassin": -0.201
        },
        {
            "name": "Treant Protector",
            "Templar Assassin": 1.901
        },
        {
            "name": "Troll Warlord",
            "Templar Assassin": 1.86
        },
        {
            "name": "Tusk",
            "Templar Assassin": -1.556
        },
        {
            "name": "Underlord",
            "Templar Assassin": 1.315
        },
        {
            "name": "Undying",
            "Templar Assassin": -0.608
        },
        {
            "name": "Ursa",
            "Templar Assassin": -1.781
        },
        {
            "name": "Vengeful Spirit",
            "Templar Assassin": 0.259
        },
        {
            "name": "Venomancer",
            "Templar Assassin": 1.205
        },
        {
            "name": "Viper",
            "Templar Assassin": 0.716
        },
        {
            "name": "Visage",
            "Templar Assassin": -0.539
        },
        {
            "name": "Void Spirit",
            "Templar Assassin": -1.729
        },
        {
            "name": "Warlock",
            "Templar Assassin": -0.185
        },
        {
            "name": "Weaver",
            "Templar Assassin": -1.008
        },
        {
            "name": "Windranger",
            "Templar Assassin": 0.586
        },
        {
            "name": "Winter Wyvern",
            "Templar Assassin": 1.736
        },
        {
            "name": "Witch Doctor",
            "Templar Assassin": -1.211
        },
        {
            "name": "Wraith King",
            "Templar Assassin": 1.264
        },
        {
            "name": "Zeus",
            "Templar Assassin": -0.601
        }
    ],
    "Terrorblade": [
        {
            "name": "Abaddon",
            "Terrorblade": -1.867
        },
        {
            "name": "Alchemist",
            "Terrorblade": 0.01
        },
        {
            "name": "Ancient Apparition",
            "Terrorblade": -0.261
        },
        {
            "name": "Anti-Mage",
            "Terrorblade": -3.19
        },
        {
            "name": "Arc Warden",
            "Terrorblade": -1.281
        },
        {
            "name": "Axe",
            "Terrorblade": 4.527
        },
        {
            "name": "Bane",
            "Terrorblade": -1.359
        },
        {
            "name": "Batrider",
            "Terrorblade": 0.134
        },
        {
            "name": "Beastmaster",
            "Terrorblade": 0.215
        },
        {
            "name": "Bloodseeker",
            "Terrorblade": 1.392
        },
        {
            "name": "Bounty Hunter",
            "Terrorblade": 0.805
        },
        {
            "name": "Brewmaster",
            "Terrorblade": 2.147
        },
        {
            "name": "Bristleback",
            "Terrorblade": 0.313
        },
        {
            "name": "Broodmother",
            "Terrorblade": -4.072
        },
        {
            "name": "Centaur Warrunner",
            "Terrorblade": 0.603
        },
        {
            "name": "Chaos Knight",
            "Terrorblade": -1.863
        },
        {
            "name": "Chen",
            "Terrorblade": 1.092
        },
        {
            "name": "Clinkz",
            "Terrorblade": -2.966
        },
        {
            "name": "Clockwerk",
            "Terrorblade": -1.494
        },
        {
            "name": "Crystal Maiden",
            "Terrorblade": 0.21
        },
        {
            "name": "Dark Seer",
            "Terrorblade": 2.935
        },
        {
            "name": "Dark Willow",
            "Terrorblade": 0.361
        },
        {
            "name": "Dawnbreaker",
            "Terrorblade": 0.567
        },
        {
            "name": "Dazzle",
            "Terrorblade": -0.617
        },
        {
            "name": "Death Prophet",
            "Terrorblade": -2.078
        },
        {
            "name": "Disruptor",
            "Terrorblade": -0.762
        },
        {
            "name": "Doom",
            "Terrorblade": -0.423
        },
        {
            "name": "Dragon Knight",
            "Terrorblade": -0.477
        },
        {
            "name": "Drow Ranger",
            "Terrorblade": 1.306
        },
        {
            "name": "Earth Spirit",
            "Terrorblade": 0.817
        },
        {
            "name": "Earthshaker",
            "Terrorblade": 2.201
        },
        {
            "name": "Elder Titan",
            "Terrorblade": -0.065
        },
        {
            "name": "Ember Spirit",
            "Terrorblade": 0.303
        },
        {
            "name": "Enchantress",
            "Terrorblade": 0.127
        },
        {
            "name": "Enigma",
            "Terrorblade": -0.04
        },
        {
            "name": "Faceless Void",
            "Terrorblade": 0.97
        },
        {
            "name": "Grimstroke",
            "Terrorblade": -0.739
        },
        {
            "name": "Gyrocopter",
            "Terrorblade": 0.449
        },
        {
            "name": "Hoodwink",
            "Terrorblade": -0.295
        },
        {
            "name": "Huskar",
            "Terrorblade": -1.926
        },
        {
            "name": "Invoker",
            "Terrorblade": 0.931
        },
        {
            "name": "Io",
            "Terrorblade": -0.808
        },
        {
            "name": "Jakiro",
            "Terrorblade": 1.58
        },
        {
            "name": "Juggernaut",
            "Terrorblade": 0.842
        },
        {
            "name": "Keeper of the Light",
            "Terrorblade": 1.929
        },
        {
            "name": "Kez",
            "Terrorblade": -1.024
        },
        {
            "name": "Kunkka",
            "Terrorblade": 1.501
        },
        {
            "name": "Legion Commander",
            "Terrorblade": 2.043
        },
        {
            "name": "Leshrac",
            "Terrorblade": 1.59
        },
        {
            "name": "Lich",
            "Terrorblade": 1.978
        },
        {
            "name": "Lifestealer",
            "Terrorblade": 0.685
        },
        {
            "name": "Lina",
            "Terrorblade": 2.516
        },
        {
            "name": "Lion",
            "Terrorblade": 0.114
        },
        {
            "name": "Lone Druid",
            "Terrorblade": -0.782
        },
        {
            "name": "Luna",
            "Terrorblade": -1.587
        },
        {
            "name": "Lycan",
            "Terrorblade": -1.994
        },
        {
            "name": "Magnus",
            "Terrorblade": 0.981
        },
        {
            "name": "Marci",
            "Terrorblade": -0.236
        },
        {
            "name": "Mars",
            "Terrorblade": 0.762
        },
        {
            "name": "Medusa",
            "Terrorblade": -1.745
        },
        {
            "name": "Meepo",
            "Terrorblade": -0.971
        },
        {
            "name": "Mirana",
            "Terrorblade": -1.363
        },
        {
            "name": "Monkey King",
            "Terrorblade": -1.472
        },
        {
            "name": "Morphling",
            "Terrorblade": -2.388
        },
        {
            "name": "Muerta",
            "Terrorblade": 0.05
        },
        {
            "name": "Naga Siren",
            "Terrorblade": 1.875
        },
        {
            "name": "Nature's Prophet",
            "Terrorblade": -1.092
        },
        {
            "name": "Necrophos",
            "Terrorblade": 0.014
        },
        {
            "name": "Night Stalker",
            "Terrorblade": -3.488
        },
        {
            "name": "Nyx Assassin",
            "Terrorblade": -0.232
        },
        {
            "name": "Ogre Magi",
            "Terrorblade": -0.518
        },
        {
            "name": "Omniknight",
            "Terrorblade": 0.223
        },
        {
            "name": "Oracle",
            "Terrorblade": -1.205
        },
        {
            "name": "Outworld Destroyer",
            "Terrorblade": -0.622
        },
        {
            "name": "Pangolier",
            "Terrorblade": -1.13
        },
        {
            "name": "Phantom Assassin",
            "Terrorblade": 0.26
        },
        {
            "name": "Phantom Lancer",
            "Terrorblade": 3.464
        },
        {
            "name": "Phoenix",
            "Terrorblade": 1.251
        },
        {
            "name": "Primal Beast",
            "Terrorblade": 0.599
        },
        {
            "name": "Puck",
            "Terrorblade": 0.812
        },
        {
            "name": "Pudge",
            "Terrorblade": -1.124
        },
        {
            "name": "Pugna",
            "Terrorblade": -0.08
        },
        {
            "name": "Queen of Pain",
            "Terrorblade": 0.293
        },
        {
            "name": "Razor",
            "Terrorblade": -2.347
        },
        {
            "name": "Riki",
            "Terrorblade": -0.081
        },
        {
            "name": "Ringmaster",
            "Terrorblade": 1.566
        },
        {
            "name": "Rubick",
            "Terrorblade": 0.414
        },
        {
            "name": "Sand King",
            "Terrorblade": 3.187
        },
        {
            "name": "Shadow Demon",
            "Terrorblade": -0.913
        },
        {
            "name": "Shadow Fiend",
            "Terrorblade": -0.63
        },
        {
            "name": "Shadow Shaman",
            "Terrorblade": -1.772
        },
        {
            "name": "Silencer",
            "Terrorblade": -1.124
        },
        {
            "name": "Skywrath Mage",
            "Terrorblade": 0.908
        },
        {
            "name": "Slardar",
            "Terrorblade": -3.754
        },
        {
            "name": "Slark",
            "Terrorblade": 1.407
        },
        {
            "name": "Snapfire",
            "Terrorblade": 1.674
        },
        {
            "name": "Sniper",
            "Terrorblade": 0.392
        },
        {
            "name": "Spectre",
            "Terrorblade": -0.53
        },
        {
            "name": "Spirit Breaker",
            "Terrorblade": -0.128
        },
        {
            "name": "Storm Spirit",
            "Terrorblade": -0.154
        },
        {
            "name": "Sven",
            "Terrorblade": -2.013
        },
        {
            "name": "Techies",
            "Terrorblade": 1.941
        },
        {
            "name": "Templar Assassin",
            "Terrorblade": -2.866
        },
        {
            "name": "Tidehunter",
            "Terrorblade": 0.826
        },
        {
            "name": "Timbersaw",
            "Terrorblade": 1.776
        },
        {
            "name": "Tinker",
            "Terrorblade": 2.685
        },
        {
            "name": "Tiny",
            "Terrorblade": -1.662
        },
        {
            "name": "Treant Protector",
            "Terrorblade": -0.27
        },
        {
            "name": "Troll Warlord",
            "Terrorblade": -0.28
        },
        {
            "name": "Tusk",
            "Terrorblade": -1.315
        },
        {
            "name": "Underlord",
            "Terrorblade": 1.467
        },
        {
            "name": "Undying",
            "Terrorblade": -0.725
        },
        {
            "name": "Ursa",
            "Terrorblade": -1.893
        },
        {
            "name": "Vengeful Spirit",
            "Terrorblade": -2.501
        },
        {
            "name": "Venomancer",
            "Terrorblade": -1.601
        },
        {
            "name": "Viper",
            "Terrorblade": -3.695
        },
        {
            "name": "Visage",
            "Terrorblade": -0.841
        },
        {
            "name": "Void Spirit",
            "Terrorblade": -2.183
        },
        {
            "name": "Warlock",
            "Terrorblade": 3.672
        },
        {
            "name": "Weaver",
            "Terrorblade": -1.343
        },
        {
            "name": "Windranger",
            "Terrorblade": 0.788
        },
        {
            "name": "Winter Wyvern",
            "Terrorblade": 4.035
        },
        {
            "name": "Witch Doctor",
            "Terrorblade": 1.381
        },
        {
            "name": "Wraith King",
            "Terrorblade": -1.97
        },
        {
            "name": "Zeus",
            "Terrorblade": -0.028
        }
    ],
    "Troll Warlord": [
        {
            "name": "Abaddon",
            "Troll Warlord": 1.255
        },
        {
            "name": "Alchemist",
            "Troll Warlord": -0.025
        },
        {
            "name": "Ancient Apparition",
            "Troll Warlord": 2.631
        },
        {
            "name": "Anti-Mage",
            "Troll Warlord": -3.537
        },
        {
            "name": "Arc Warden",
            "Troll Warlord": -0.069
        },
        {
            "name": "Axe",
            "Troll Warlord": 4.955
        },
        {
            "name": "Bane",
            "Troll Warlord": 0.974
        },
        {
            "name": "Batrider",
            "Troll Warlord": -0.125
        },
        {
            "name": "Beastmaster",
            "Troll Warlord": -0.117
        },
        {
            "name": "Bloodseeker",
            "Troll Warlord": -2.378
        },
        {
            "name": "Bounty Hunter",
            "Troll Warlord": -1.195
        },
        {
            "name": "Brewmaster",
            "Troll Warlord": 2.709
        },
        {
            "name": "Bristleback",
            "Troll Warlord": 0.288
        },
        {
            "name": "Broodmother",
            "Troll Warlord": -1.273
        },
        {
            "name": "Centaur Warrunner",
            "Troll Warlord": 0.388
        },
        {
            "name": "Chaos Knight",
            "Troll Warlord": -0.89
        },
        {
            "name": "Chen",
            "Troll Warlord": -0.712
        },
        {
            "name": "Clinkz",
            "Troll Warlord": 0.465
        },
        {
            "name": "Clockwerk",
            "Troll Warlord": 0.121
        },
        {
            "name": "Crystal Maiden",
            "Troll Warlord": 0.89
        },
        {
            "name": "Dark Seer",
            "Troll Warlord": -0.683
        },
        {
            "name": "Dark Willow",
            "Troll Warlord": 0.72
        },
        {
            "name": "Dawnbreaker",
            "Troll Warlord": -0.614
        },
        {
            "name": "Dazzle",
            "Troll Warlord": -1.122
        },
        {
            "name": "Death Prophet",
            "Troll Warlord": -0.207
        },
        {
            "name": "Disruptor",
            "Troll Warlord": 0.601
        },
        {
            "name": "Doom",
            "Troll Warlord": 1.118
        },
        {
            "name": "Dragon Knight",
            "Troll Warlord": -0.41
        },
        {
            "name": "Drow Ranger",
            "Troll Warlord": 0.829
        },
        {
            "name": "Earth Spirit",
            "Troll Warlord": -2.186
        },
        {
            "name": "Earthshaker",
            "Troll Warlord": -0.735
        },
        {
            "name": "Elder Titan",
            "Troll Warlord": -3.289
        },
        {
            "name": "Ember Spirit",
            "Troll Warlord": -3.164
        },
        {
            "name": "Enchantress",
            "Troll Warlord": 0.896
        },
        {
            "name": "Enigma",
            "Troll Warlord": -0.067
        },
        {
            "name": "Faceless Void",
            "Troll Warlord": -1.485
        },
        {
            "name": "Grimstroke",
            "Troll Warlord": 0.968
        },
        {
            "name": "Gyrocopter",
            "Troll Warlord": 0.072
        },
        {
            "name": "Hoodwink",
            "Troll Warlord": 0.15
        },
        {
            "name": "Huskar",
            "Troll Warlord": -2.287
        },
        {
            "name": "Invoker",
            "Troll Warlord": 1.838
        },
        {
            "name": "Io",
            "Troll Warlord": -3.325
        },
        {
            "name": "Jakiro",
            "Troll Warlord": 0.967
        },
        {
            "name": "Juggernaut",
            "Troll Warlord": -0.5
        },
        {
            "name": "Keeper of the Light",
            "Troll Warlord": 1.139
        },
        {
            "name": "Kez",
            "Troll Warlord": -2.34
        },
        {
            "name": "Kunkka",
            "Troll Warlord": 0.347
        },
        {
            "name": "Legion Commander",
            "Troll Warlord": -0.633
        },
        {
            "name": "Leshrac",
            "Troll Warlord": 0.288
        },
        {
            "name": "Lich",
            "Troll Warlord": 0.0
        },
        {
            "name": "Lifestealer",
            "Troll Warlord": -1.482
        },
        {
            "name": "Lina",
            "Troll Warlord": 1.078
        },
        {
            "name": "Lion",
            "Troll Warlord": 2.063
        },
        {
            "name": "Lone Druid",
            "Troll Warlord": -1.593
        },
        {
            "name": "Luna",
            "Troll Warlord": 0.221
        },
        {
            "name": "Lycan",
            "Troll Warlord": -4.256
        },
        {
            "name": "Magnus",
            "Troll Warlord": -1.365
        },
        {
            "name": "Marci",
            "Troll Warlord": -3.885
        },
        {
            "name": "Mars",
            "Troll Warlord": 0.174
        },
        {
            "name": "Medusa",
            "Troll Warlord": 1.476
        },
        {
            "name": "Meepo",
            "Troll Warlord": -5.914
        },
        {
            "name": "Mirana",
            "Troll Warlord": 1.831
        },
        {
            "name": "Monkey King",
            "Troll Warlord": -0.584
        },
        {
            "name": "Morphling",
            "Troll Warlord": -1.33
        },
        {
            "name": "Muerta",
            "Troll Warlord": 1.221
        },
        {
            "name": "Naga Siren",
            "Troll Warlord": 2.25
        },
        {
            "name": "Nature's Prophet",
            "Troll Warlord": 1.08
        },
        {
            "name": "Necrophos",
            "Troll Warlord": 1.666
        },
        {
            "name": "Night Stalker",
            "Troll Warlord": -2.179
        },
        {
            "name": "Nyx Assassin",
            "Troll Warlord": -0.478
        },
        {
            "name": "Ogre Magi",
            "Troll Warlord": 1.374
        },
        {
            "name": "Omniknight",
            "Troll Warlord": 1.269
        },
        {
            "name": "Oracle",
            "Troll Warlord": 0.193
        },
        {
            "name": "Outworld Destroyer",
            "Troll Warlord": 2.981
        },
        {
            "name": "Pangolier",
            "Troll Warlord": -1.275
        },
        {
            "name": "Phantom Assassin",
            "Troll Warlord": -1.694
        },
        {
            "name": "Phantom Lancer",
            "Troll Warlord": 1.188
        },
        {
            "name": "Phoenix",
            "Troll Warlord": 1.197
        },
        {
            "name": "Primal Beast",
            "Troll Warlord": 0.307
        },
        {
            "name": "Puck",
            "Troll Warlord": -0.264
        },
        {
            "name": "Pudge",
            "Troll Warlord": -0.388
        },
        {
            "name": "Pugna",
            "Troll Warlord": 1.945
        },
        {
            "name": "Queen of Pain",
            "Troll Warlord": -0.393
        },
        {
            "name": "Razor",
            "Troll Warlord": 2.731
        },
        {
            "name": "Riki",
            "Troll Warlord": -0.745
        },
        {
            "name": "Ringmaster",
            "Troll Warlord": 2.491
        },
        {
            "name": "Rubick",
            "Troll Warlord": 0.908
        },
        {
            "name": "Sand King",
            "Troll Warlord": -0.27
        },
        {
            "name": "Shadow Demon",
            "Troll Warlord": 0.296
        },
        {
            "name": "Shadow Fiend",
            "Troll Warlord": -1.489
        },
        {
            "name": "Shadow Shaman",
            "Troll Warlord": 1.521
        },
        {
            "name": "Silencer",
            "Troll Warlord": 0.415
        },
        {
            "name": "Skywrath Mage",
            "Troll Warlord": 0.268
        },
        {
            "name": "Slardar",
            "Troll Warlord": -3.221
        },
        {
            "name": "Slark",
            "Troll Warlord": 3.898
        },
        {
            "name": "Snapfire",
            "Troll Warlord": -0.07
        },
        {
            "name": "Sniper",
            "Troll Warlord": 0.038
        },
        {
            "name": "Spectre",
            "Troll Warlord": 0.579
        },
        {
            "name": "Spirit Breaker",
            "Troll Warlord": -0.057
        },
        {
            "name": "Storm Spirit",
            "Troll Warlord": -1.876
        },
        {
            "name": "Sven",
            "Troll Warlord": -3.065
        },
        {
            "name": "Techies",
            "Troll Warlord": 1.654
        },
        {
            "name": "Templar Assassin",
            "Troll Warlord": -1.799
        },
        {
            "name": "Terrorblade",
            "Troll Warlord": 0.371
        },
        {
            "name": "Tidehunter",
            "Troll Warlord": 2.805
        },
        {
            "name": "Timbersaw",
            "Troll Warlord": -1.62
        },
        {
            "name": "Tinker",
            "Troll Warlord": 2.183
        },
        {
            "name": "Tiny",
            "Troll Warlord": 0.781
        },
        {
            "name": "Treant Protector",
            "Troll Warlord": 0.089
        },
        {
            "name": "Tusk",
            "Troll Warlord": -0.316
        },
        {
            "name": "Underlord",
            "Troll Warlord": 0.98
        },
        {
            "name": "Undying",
            "Troll Warlord": -1.331
        },
        {
            "name": "Ursa",
            "Troll Warlord": -2.762
        },
        {
            "name": "Vengeful Spirit",
            "Troll Warlord": 0.034
        },
        {
            "name": "Venomancer",
            "Troll Warlord": -0.536
        },
        {
            "name": "Viper",
            "Troll Warlord": -0.444
        },
        {
            "name": "Visage",
            "Troll Warlord": -0.555
        },
        {
            "name": "Void Spirit",
            "Troll Warlord": -2.022
        },
        {
            "name": "Warlock",
            "Troll Warlord": -0.725
        },
        {
            "name": "Weaver",
            "Troll Warlord": 0.058
        },
        {
            "name": "Windranger",
            "Troll Warlord": 2.155
        },
        {
            "name": "Winter Wyvern",
            "Troll Warlord": 2.719
        },
        {
            "name": "Witch Doctor",
            "Troll Warlord": -0.009
        },
        {
            "name": "Wraith King",
            "Troll Warlord": 0.629
        },
        {
            "name": "Zeus",
            "Troll Warlord": 0.271
        }
    ],
    "Ursa": [
        {
            "name": "Abaddon",
            "Ursa": -0.636
        },
        {
            "name": "Alchemist",
            "Ursa": 0.017
        },
        {
            "name": "Ancient Apparition",
            "Ursa": -1.702
        },
        {
            "name": "Anti-Mage",
            "Ursa": -1.997
        },
        {
            "name": "Arc Warden",
            "Ursa": 1.525
        },
        {
            "name": "Axe",
            "Ursa": -0.284
        },
        {
            "name": "Bane",
            "Ursa": -1.364
        },
        {
            "name": "Batrider",
            "Ursa": 0.303
        },
        {
            "name": "Beastmaster",
            "Ursa": 0.012
        },
        {
            "name": "Bloodseeker",
            "Ursa": -0.366
        },
        {
            "name": "Bounty Hunter",
            "Ursa": 0.234
        },
        {
            "name": "Brewmaster",
            "Ursa": 2.145
        },
        {
            "name": "Bristleback",
            "Ursa": 2.726
        },
        {
            "name": "Broodmother",
            "Ursa": 1.875
        },
        {
            "name": "Centaur Warrunner",
            "Ursa": -0.367
        },
        {
            "name": "Chaos Knight",
            "Ursa": 2.534
        },
        {
            "name": "Chen",
            "Ursa": -0.807
        },
        {
            "name": "Clinkz",
            "Ursa": 1.999
        },
        {
            "name": "Clockwerk",
            "Ursa": -0.31
        },
        {
            "name": "Crystal Maiden",
            "Ursa": -0.061
        },
        {
            "name": "Dark Seer",
            "Ursa": -3.363
        },
        {
            "name": "Dark Willow",
            "Ursa": -0.012
        },
        {
            "name": "Dawnbreaker",
            "Ursa": -1.536
        },
        {
            "name": "Dazzle",
            "Ursa": -0.418
        },
        {
            "name": "Death Prophet",
            "Ursa": 1.023
        },
        {
            "name": "Disruptor",
            "Ursa": 1.119
        },
        {
            "name": "Doom",
            "Ursa": 0.286
        },
        {
            "name": "Dragon Knight",
            "Ursa": -0.444
        },
        {
            "name": "Drow Ranger",
            "Ursa": 0.659
        },
        {
            "name": "Earth Spirit",
            "Ursa": -1.474
        },
        {
            "name": "Earthshaker",
            "Ursa": 0.007
        },
        {
            "name": "Elder Titan",
            "Ursa": -2.106
        },
        {
            "name": "Ember Spirit",
            "Ursa": -0.519
        },
        {
            "name": "Enchantress",
            "Ursa": 0.425
        },
        {
            "name": "Enigma",
            "Ursa": 0.921
        },
        {
            "name": "Faceless Void",
            "Ursa": -0.209
        },
        {
            "name": "Grimstroke",
            "Ursa": 0.359
        },
        {
            "name": "Gyrocopter",
            "Ursa": -0.189
        },
        {
            "name": "Hoodwink",
            "Ursa": 1.222
        },
        {
            "name": "Huskar",
            "Ursa": -1.252
        },
        {
            "name": "Invoker",
            "Ursa": -0.349
        },
        {
            "name": "Io",
            "Ursa": -0.431
        },
        {
            "name": "Jakiro",
            "Ursa": -0.412
        },
        {
            "name": "Juggernaut",
            "Ursa": -2.002
        },
        {
            "name": "Keeper of the Light",
            "Ursa": -0.406
        },
        {
            "name": "Kez",
            "Ursa": -0.096
        },
        {
            "name": "Kunkka",
            "Ursa": 0.515
        },
        {
            "name": "Legion Commander",
            "Ursa": -0.921
        },
        {
            "name": "Leshrac",
            "Ursa": -2.172
        },
        {
            "name": "Lich",
            "Ursa": 0.361
        },
        {
            "name": "Lifestealer",
            "Ursa": -1.011
        },
        {
            "name": "Lina",
            "Ursa": -0.746
        },
        {
            "name": "Lion",
            "Ursa": 0.646
        },
        {
            "name": "Lone Druid",
            "Ursa": 0.631
        },
        {
            "name": "Luna",
            "Ursa": 0.802
        },
        {
            "name": "Lycan",
            "Ursa": 0.497
        },
        {
            "name": "Magnus",
            "Ursa": -1.69
        },
        {
            "name": "Marci",
            "Ursa": -0.886
        },
        {
            "name": "Mars",
            "Ursa": 0.7
        },
        {
            "name": "Medusa",
            "Ursa": -0.234
        },
        {
            "name": "Meepo",
            "Ursa": 0.06
        },
        {
            "name": "Mirana",
            "Ursa": 0.719
        },
        {
            "name": "Monkey King",
            "Ursa": -0.03
        },
        {
            "name": "Morphling",
            "Ursa": -0.423
        },
        {
            "name": "Muerta",
            "Ursa": 0.07
        },
        {
            "name": "Naga Siren",
            "Ursa": 3.997
        },
        {
            "name": "Nature's Prophet",
            "Ursa": -0.851
        },
        {
            "name": "Necrophos",
            "Ursa": 1.14
        },
        {
            "name": "Night Stalker",
            "Ursa": 1.321
        },
        {
            "name": "Nyx Assassin",
            "Ursa": -1.361
        },
        {
            "name": "Ogre Magi",
            "Ursa": 2.298
        },
        {
            "name": "Omniknight",
            "Ursa": 1.326
        },
        {
            "name": "Oracle",
            "Ursa": -0.395
        },
        {
            "name": "Outworld Destroyer",
            "Ursa": -0.439
        },
        {
            "name": "Pangolier",
            "Ursa": -0.336
        },
        {
            "name": "Phantom Assassin",
            "Ursa": 0.621
        },
        {
            "name": "Phantom Lancer",
            "Ursa": 4.568
        },
        {
            "name": "Phoenix",
            "Ursa": -0.063
        },
        {
            "name": "Primal Beast",
            "Ursa": 0.789
        },
        {
            "name": "Puck",
            "Ursa": -0.561
        },
        {
            "name": "Pudge",
            "Ursa": -2.474
        },
        {
            "name": "Pugna",
            "Ursa": -0.955
        },
        {
            "name": "Queen of Pain",
            "Ursa": -0.503
        },
        {
            "name": "Razor",
            "Ursa": 2.014
        },
        {
            "name": "Riki",
            "Ursa": 2.422
        },
        {
            "name": "Ringmaster",
            "Ursa": -0.057
        },
        {
            "name": "Rubick",
            "Ursa": -1.59
        },
        {
            "name": "Sand King",
            "Ursa": 0.114
        },
        {
            "name": "Shadow Demon",
            "Ursa": 0.595
        },
        {
            "name": "Shadow Fiend",
            "Ursa": -1.044
        },
        {
            "name": "Shadow Shaman",
            "Ursa": 2.761
        },
        {
            "name": "Silencer",
            "Ursa": 0.881
        },
        {
            "name": "Skywrath Mage",
            "Ursa": 0.042
        },
        {
            "name": "Slardar",
            "Ursa": 1.241
        },
        {
            "name": "Slark",
            "Ursa": 0.306
        },
        {
            "name": "Snapfire",
            "Ursa": -0.066
        },
        {
            "name": "Sniper",
            "Ursa": -0.51
        },
        {
            "name": "Spectre",
            "Ursa": 1.571
        },
        {
            "name": "Spirit Breaker",
            "Ursa": 0.121
        },
        {
            "name": "Storm Spirit",
            "Ursa": -0.055
        },
        {
            "name": "Sven",
            "Ursa": -1.264
        },
        {
            "name": "Techies",
            "Ursa": 0.673
        },
        {
            "name": "Templar Assassin",
            "Ursa": 1.969
        },
        {
            "name": "Terrorblade",
            "Ursa": 1.939
        },
        {
            "name": "Tidehunter",
            "Ursa": -2.706
        },
        {
            "name": "Timbersaw",
            "Ursa": -1.447
        },
        {
            "name": "Tinker",
            "Ursa": 0.709
        },
        {
            "name": "Tiny",
            "Ursa": 0.154
        },
        {
            "name": "Treant Protector",
            "Ursa": 0.354
        },
        {
            "name": "Troll Warlord",
            "Ursa": 2.576
        },
        {
            "name": "Tusk",
            "Ursa": 0.074
        },
        {
            "name": "Underlord",
            "Ursa": -2.251
        },
        {
            "name": "Undying",
            "Ursa": 1.605
        },
        {
            "name": "Vengeful Spirit",
            "Ursa": 0.268
        },
        {
            "name": "Venomancer",
            "Ursa": 1.567
        },
        {
            "name": "Viper",
            "Ursa": 0.614
        },
        {
            "name": "Visage",
            "Ursa": -0.038
        },
        {
            "name": "Void Spirit",
            "Ursa": -0.349
        },
        {
            "name": "Warlock",
            "Ursa": -1.413
        },
        {
            "name": "Weaver",
            "Ursa": 2.096
        },
        {
            "name": "Windranger",
            "Ursa": 2.622
        },
        {
            "name": "Winter Wyvern",
            "Ursa": 0.716
        },
        {
            "name": "Witch Doctor",
            "Ursa": -0.894
        },
        {
            "name": "Wraith King",
            "Ursa": 2.989
        },
        {
            "name": "Zeus",
            "Ursa": 0.148
        }
    ],
    "Viper": [
        {
            "name": "Abaddon",
            "Viper": -0.474
        },
        {
            "name": "Alchemist",
            "Viper": -0.94
        },
        {
            "name": "Ancient Apparition",
            "Viper": -0.208
        },
        {
            "name": "Anti-Mage",
            "Viper": 0.113
        },
        {
            "name": "Arc Warden",
            "Viper": 4.356
        },
        {
            "name": "Axe",
            "Viper": -0.763
        },
        {
            "name": "Bane",
            "Viper": 0.426
        },
        {
            "name": "Batrider",
            "Viper": -2.463
        },
        {
            "name": "Beastmaster",
            "Viper": -1.261
        },
        {
            "name": "Bloodseeker",
            "Viper": -2.272
        },
        {
            "name": "Bounty Hunter",
            "Viper": -0.516
        },
        {
            "name": "Brewmaster",
            "Viper": 2.937
        },
        {
            "name": "Bristleback",
            "Viper": -3.793
        },
        {
            "name": "Broodmother",
            "Viper": 0.795
        },
        {
            "name": "Centaur Warrunner",
            "Viper": 1.4
        },
        {
            "name": "Chaos Knight",
            "Viper": 3.487
        },
        {
            "name": "Chen",
            "Viper": 0.054
        },
        {
            "name": "Clinkz",
            "Viper": 1.321
        },
        {
            "name": "Clockwerk",
            "Viper": -1.099
        },
        {
            "name": "Crystal Maiden",
            "Viper": 0.741
        },
        {
            "name": "Dark Seer",
            "Viper": 3.469
        },
        {
            "name": "Dark Willow",
            "Viper": -0.548
        },
        {
            "name": "Dawnbreaker",
            "Viper": -0.771
        },
        {
            "name": "Dazzle",
            "Viper": -0.174
        },
        {
            "name": "Death Prophet",
            "Viper": 0.376
        },
        {
            "name": "Disruptor",
            "Viper": -1.582
        },
        {
            "name": "Doom",
            "Viper": -0.828
        },
        {
            "name": "Dragon Knight",
            "Viper": -4.268
        },
        {
            "name": "Drow Ranger",
            "Viper": 1.548
        },
        {
            "name": "Earth Spirit",
            "Viper": 0.216
        },
        {
            "name": "Earthshaker",
            "Viper": -1.777
        },
        {
            "name": "Elder Titan",
            "Viper": -0.636
        },
        {
            "name": "Ember Spirit",
            "Viper": -0.847
        },
        {
            "name": "Enchantress",
            "Viper": 0.268
        },
        {
            "name": "Enigma",
            "Viper": 0.088
        },
        {
            "name": "Faceless Void",
            "Viper": -0.461
        },
        {
            "name": "Grimstroke",
            "Viper": 0.118
        },
        {
            "name": "Gyrocopter",
            "Viper": -1.369
        },
        {
            "name": "Hoodwink",
            "Viper": 0.727
        },
        {
            "name": "Huskar",
            "Viper": -7.326
        },
        {
            "name": "Invoker",
            "Viper": 1.451
        },
        {
            "name": "Io",
            "Viper": -1.065
        },
        {
            "name": "Jakiro",
            "Viper": 0.026
        },
        {
            "name": "Juggernaut",
            "Viper": 1.923
        },
        {
            "name": "Keeper of the Light",
            "Viper": 0.463
        },
        {
            "name": "Kez",
            "Viper": 1.621
        },
        {
            "name": "Kunkka",
            "Viper": 0.106
        },
        {
            "name": "Legion Commander",
            "Viper": 1.464
        },
        {
            "name": "Leshrac",
            "Viper": -0.124
        },
        {
            "name": "Lich",
            "Viper": -0.128
        },
        {
            "name": "Lifestealer",
            "Viper": -1.214
        },
        {
            "name": "Lina",
            "Viper": 0.435
        },
        {
            "name": "Lion",
            "Viper": -0.867
        },
        {
            "name": "Lone Druid",
            "Viper": -1.463
        },
        {
            "name": "Luna",
            "Viper": -0.468
        },
        {
            "name": "Lycan",
            "Viper": 2.362
        },
        {
            "name": "Magnus",
            "Viper": 0.229
        },
        {
            "name": "Marci",
            "Viper": 1.824
        },
        {
            "name": "Mars",
            "Viper": 0.246
        },
        {
            "name": "Medusa",
            "Viper": 1.798
        },
        {
            "name": "Meepo",
            "Viper": -0.797
        },
        {
            "name": "Mirana",
            "Viper": 0.198
        },
        {
            "name": "Monkey King",
            "Viper": -1.489
        },
        {
            "name": "Morphling",
            "Viper": 3.6
        },
        {
            "name": "Muerta",
            "Viper": -0.46
        },
        {
            "name": "Naga Siren",
            "Viper": 4.82
        },
        {
            "name": "Nature's Prophet",
            "Viper": 0.583
        },
        {
            "name": "Necrophos",
            "Viper": -2.139
        },
        {
            "name": "Night Stalker",
            "Viper": -1.718
        },
        {
            "name": "Nyx Assassin",
            "Viper": -0.779
        },
        {
            "name": "Ogre Magi",
            "Viper": -0.434
        },
        {
            "name": "Omniknight",
            "Viper": 0.416
        },
        {
            "name": "Oracle",
            "Viper": -0.048
        },
        {
            "name": "Outworld Destroyer",
            "Viper": -0.34
        },
        {
            "name": "Pangolier",
            "Viper": 0.697
        },
        {
            "name": "Phantom Assassin",
            "Viper": 0.335
        },
        {
            "name": "Phantom Lancer",
            "Viper": 6.094
        },
        {
            "name": "Phoenix",
            "Viper": -0.241
        },
        {
            "name": "Primal Beast",
            "Viper": 0.271
        },
        {
            "name": "Puck",
            "Viper": -0.232
        },
        {
            "name": "Pudge",
            "Viper": 2.463
        },
        {
            "name": "Pugna",
            "Viper": -0.114
        },
        {
            "name": "Queen of Pain",
            "Viper": -0.175
        },
        {
            "name": "Razor",
            "Viper": -1.357
        },
        {
            "name": "Riki",
            "Viper": 1.024
        },
        {
            "name": "Ringmaster",
            "Viper": 0.245
        },
        {
            "name": "Rubick",
            "Viper": 0.259
        },
        {
            "name": "Sand King",
            "Viper": 1.061
        },
        {
            "name": "Shadow Demon",
            "Viper": 1.705
        },
        {
            "name": "Shadow Fiend",
            "Viper": -0.438
        },
        {
            "name": "Shadow Shaman",
            "Viper": 0.654
        },
        {
            "name": "Silencer",
            "Viper": -0.323
        },
        {
            "name": "Skywrath Mage",
            "Viper": -0.024
        },
        {
            "name": "Slardar",
            "Viper": -0.56
        },
        {
            "name": "Slark",
            "Viper": 1.255
        },
        {
            "name": "Snapfire",
            "Viper": 0.694
        },
        {
            "name": "Sniper",
            "Viper": 0.818
        },
        {
            "name": "Spectre",
            "Viper": -0.726
        },
        {
            "name": "Spirit Breaker",
            "Viper": 0.154
        },
        {
            "name": "Storm Spirit",
            "Viper": 1.225
        },
        {
            "name": "Sven",
            "Viper": -0.888
        },
        {
            "name": "Techies",
            "Viper": 0.522
        },
        {
            "name": "Templar Assassin",
            "Viper": -0.485
        },
        {
            "name": "Terrorblade",
            "Viper": 3.607
        },
        {
            "name": "Tidehunter",
            "Viper": -1.774
        },
        {
            "name": "Timbersaw",
            "Viper": 0.302
        },
        {
            "name": "Tinker",
            "Viper": 3.098
        },
        {
            "name": "Tiny",
            "Viper": -3.082
        },
        {
            "name": "Treant Protector",
            "Viper": -0.434
        },
        {
            "name": "Troll Warlord",
            "Viper": 0.346
        },
        {
            "name": "Tusk",
            "Viper": 0.347
        },
        {
            "name": "Underlord",
            "Viper": -0.258
        },
        {
            "name": "Undying",
            "Viper": 0.643
        },
        {
            "name": "Ursa",
            "Viper": -0.653
        },
        {
            "name": "Vengeful Spirit",
            "Viper": -0.147
        },
        {
            "name": "Venomancer",
            "Viper": -1.09
        },
        {
            "name": "Visage",
            "Viper": -0.02
        },
        {
            "name": "Void Spirit",
            "Viper": 1.091
        },
        {
            "name": "Warlock",
            "Viper": 0.57
        },
        {
            "name": "Weaver",
            "Viper": 1.881
        },
        {
            "name": "Windranger",
            "Viper": 2.355
        },
        {
            "name": "Winter Wyvern",
            "Viper": -1.229
        },
        {
            "name": "Witch Doctor",
            "Viper": 2.121
        },
        {
            "name": "Wraith King",
            "Viper": 0.837
        },
        {
            "name": "Zeus",
            "Viper": -1.244
        }
    ],
    "Weaver": [
        {
            "name": "Abaddon",
            "Weaver": -2.073
        },
        {
            "name": "Alchemist",
            "Weaver": 0.026
        },
        {
            "name": "Ancient Apparition",
            "Weaver": -2.48
        },
        {
            "name": "Anti-Mage",
            "Weaver": 0.712
        },
        {
            "name": "Arc Warden",
            "Weaver": 0.957
        },
        {
            "name": "Axe",
            "Weaver": 1.907
        },
        {
            "name": "Bane",
            "Weaver": 0.932
        },
        {
            "name": "Batrider",
            "Weaver": -0.915
        },
        {
            "name": "Beastmaster",
            "Weaver": -0.461
        },
        {
            "name": "Bloodseeker",
            "Weaver": 0.189
        },
        {
            "name": "Bounty Hunter",
            "Weaver": 1.31
        },
        {
            "name": "Brewmaster",
            "Weaver": -1.613
        },
        {
            "name": "Bristleback",
            "Weaver": 0.118
        },
        {
            "name": "Broodmother",
            "Weaver": 1.112
        },
        {
            "name": "Centaur Warrunner",
            "Weaver": -0.631
        },
        {
            "name": "Chaos Knight",
            "Weaver": 0.896
        },
        {
            "name": "Chen",
            "Weaver": -0.836
        },
        {
            "name": "Clinkz",
            "Weaver": 1.18
        },
        {
            "name": "Clockwerk",
            "Weaver": 0.868
        },
        {
            "name": "Crystal Maiden",
            "Weaver": 0.538
        },
        {
            "name": "Dark Seer",
            "Weaver": -1.768
        },
        {
            "name": "Dark Willow",
            "Weaver": 2.168
        },
        {
            "name": "Dawnbreaker",
            "Weaver": -1.265
        },
        {
            "name": "Dazzle",
            "Weaver": -2.455
        },
        {
            "name": "Death Prophet",
            "Weaver": -2.246
        },
        {
            "name": "Disruptor",
            "Weaver": 0.468
        },
        {
            "name": "Doom",
            "Weaver": -0.386
        },
        {
            "name": "Dragon Knight",
            "Weaver": 0.366
        },
        {
            "name": "Drow Ranger",
            "Weaver": 2.112
        },
        {
            "name": "Earth Spirit",
            "Weaver": -0.903
        },
        {
            "name": "Earthshaker",
            "Weaver": 0.723
        },
        {
            "name": "Elder Titan",
            "Weaver": 0.607
        },
        {
            "name": "Ember Spirit",
            "Weaver": -1.144
        },
        {
            "name": "Enchantress",
            "Weaver": 0.127
        },
        {
            "name": "Enigma",
            "Weaver": 0.472
        },
        {
            "name": "Faceless Void",
            "Weaver": 3.417
        },
        {
            "name": "Grimstroke",
            "Weaver": -1.19
        },
        {
            "name": "Gyrocopter",
            "Weaver": 1.496
        },
        {
            "name": "Hoodwink",
            "Weaver": 0.017
        },
        {
            "name": "Huskar",
            "Weaver": 1.345
        },
        {
            "name": "Invoker",
            "Weaver": -0.759
        },
        {
            "name": "Io",
            "Weaver": -0.645
        },
        {
            "name": "Jakiro",
            "Weaver": -1.024
        },
        {
            "name": "Juggernaut",
            "Weaver": -1.593
        },
        {
            "name": "Keeper of the Light",
            "Weaver": -0.754
        },
        {
            "name": "Kez",
            "Weaver": 0.067
        },
        {
            "name": "Kunkka",
            "Weaver": 0.077
        },
        {
            "name": "Legion Commander",
            "Weaver": 1.619
        },
        {
            "name": "Leshrac",
            "Weaver": -0.908
        },
        {
            "name": "Lich",
            "Weaver": -1.232
        },
        {
            "name": "Lifestealer",
            "Weaver": -1.487
        },
        {
            "name": "Lina",
            "Weaver": -0.266
        },
        {
            "name": "Lion",
            "Weaver": 1.373
        },
        {
            "name": "Lone Druid",
            "Weaver": -1.159
        },
        {
            "name": "Luna",
            "Weaver": 1.686
        },
        {
            "name": "Lycan",
            "Weaver": -0.123
        },
        {
            "name": "Magnus",
            "Weaver": -0.925
        },
        {
            "name": "Marci",
            "Weaver": -0.433
        },
        {
            "name": "Mars",
            "Weaver": 0.134
        },
        {
            "name": "Medusa",
            "Weaver": 0.795
        },
        {
            "name": "Meepo",
            "Weaver": 0.615
        },
        {
            "name": "Mirana",
            "Weaver": 1.258
        },
        {
            "name": "Monkey King",
            "Weaver": 0.006
        },
        {
            "name": "Morphling",
            "Weaver": 2.721
        },
        {
            "name": "Muerta",
            "Weaver": 1.701
        },
        {
            "name": "Naga Siren",
            "Weaver": 3.21
        },
        {
            "name": "Nature's Prophet",
            "Weaver": 0.726
        },
        {
            "name": "Necrophos",
            "Weaver": 0.379
        },
        {
            "name": "Night Stalker",
            "Weaver": 0.686
        },
        {
            "name": "Nyx Assassin",
            "Weaver": 2.728
        },
        {
            "name": "Ogre Magi",
            "Weaver": 0.452
        },
        {
            "name": "Omniknight",
            "Weaver": -0.698
        },
        {
            "name": "Oracle",
            "Weaver": -0.967
        },
        {
            "name": "Outworld Destroyer",
            "Weaver": 0.348
        },
        {
            "name": "Pangolier",
            "Weaver": -1.494
        },
        {
            "name": "Phantom Assassin",
            "Weaver": -0.566
        },
        {
            "name": "Phantom Lancer",
            "Weaver": 3.279
        },
        {
            "name": "Phoenix",
            "Weaver": -0.706
        },
        {
            "name": "Primal Beast",
            "Weaver": -0.416
        },
        {
            "name": "Puck",
            "Weaver": 1.843
        },
        {
            "name": "Pudge",
            "Weaver": -1.905
        },
        {
            "name": "Pugna",
            "Weaver": -0.303
        },
        {
            "name": "Queen of Pain",
            "Weaver": -0.347
        },
        {
            "name": "Razor",
            "Weaver": -2.347
        },
        {
            "name": "Riki",
            "Weaver": 2.531
        },
        {
            "name": "Ringmaster",
            "Weaver": -0.371
        },
        {
            "name": "Rubick",
            "Weaver": 2.211
        },
        {
            "name": "Sand King",
            "Weaver": -0.361
        },
        {
            "name": "Shadow Demon",
            "Weaver": -1.621
        },
        {
            "name": "Shadow Fiend",
            "Weaver": 0.605
        },
        {
            "name": "Shadow Shaman",
            "Weaver": 1.17
        },
        {
            "name": "Silencer",
            "Weaver": 0.285
        },
        {
            "name": "Skywrath Mage",
            "Weaver": 0.361
        },
        {
            "name": "Slardar",
            "Weaver": -0.121
        },
        {
            "name": "Slark",
            "Weaver": 1.103
        },
        {
            "name": "Snapfire",
            "Weaver": -0.873
        },
        {
            "name": "Sniper",
            "Weaver": -0.815
        },
        {
            "name": "Spectre",
            "Weaver": 0.532
        },
        {
            "name": "Spirit Breaker",
            "Weaver": 0.622
        },
        {
            "name": "Storm Spirit",
            "Weaver": 0.013
        },
        {
            "name": "Sven",
            "Weaver": -0.544
        },
        {
            "name": "Techies",
            "Weaver": 1.657
        },
        {
            "name": "Templar Assassin",
            "Weaver": 1.174
        },
        {
            "name": "Terrorblade",
            "Weaver": 1.419
        },
        {
            "name": "Tidehunter",
            "Weaver": -1.618
        },
        {
            "name": "Timbersaw",
            "Weaver": -1.558
        },
        {
            "name": "Tinker",
            "Weaver": 0.676
        },
        {
            "name": "Tiny",
            "Weaver": -0.892
        },
        {
            "name": "Treant Protector",
            "Weaver": 0.15
        },
        {
            "name": "Troll Warlord",
            "Weaver": -0.119
        },
        {
            "name": "Tusk",
            "Weaver": -0.675
        },
        {
            "name": "Underlord",
            "Weaver": 0.643
        },
        {
            "name": "Undying",
            "Weaver": -1.569
        },
        {
            "name": "Ursa",
            "Weaver": -2.105
        },
        {
            "name": "Vengeful Spirit",
            "Weaver": 0.282
        },
        {
            "name": "Venomancer",
            "Weaver": -1.111
        },
        {
            "name": "Viper",
            "Weaver": -1.909
        },
        {
            "name": "Visage",
            "Weaver": -2.032
        },
        {
            "name": "Void Spirit",
            "Weaver": 1.267
        },
        {
            "name": "Warlock",
            "Weaver": -2.248
        },
        {
            "name": "Windranger",
            "Weaver": 0.023
        },
        {
            "name": "Winter Wyvern",
            "Weaver": -1.881
        },
        {
            "name": "Witch Doctor",
            "Weaver": -0.252
        },
        {
            "name": "Wraith King",
            "Weaver": 0.349
        },
        {
            "name": "Zeus",
            "Weaver": 0.095
        }
    ],
    "Ancient Apparition": [
        {
            "name": "Abaddon",
            "Ancient Apparition": -2.024
        },
        {
            "name": "Alchemist",
            "Ancient Apparition": -0.589
        },
        {
            "name": "Anti-Mage",
            "Ancient Apparition": 2.843
        },
        {
            "name": "Arc Warden",
            "Ancient Apparition": 2.843
        },
        {
            "name": "Axe",
            "Ancient Apparition": -0.703
        },
        {
            "name": "Bane",
            "Ancient Apparition": 0.971
        },
        {
            "name": "Batrider",
            "Ancient Apparition": 1.957
        },
        {
            "name": "Beastmaster",
            "Ancient Apparition": 0.806
        },
        {
            "name": "Bloodseeker",
            "Ancient Apparition": -1.229
        },
        {
            "name": "Bounty Hunter",
            "Ancient Apparition": 0.635
        },
        {
            "name": "Brewmaster",
            "Ancient Apparition": 4.115
        },
        {
            "name": "Bristleback",
            "Ancient Apparition": -1.552
        },
        {
            "name": "Broodmother",
            "Ancient Apparition": 0.896
        },
        {
            "name": "Centaur Warrunner",
            "Ancient Apparition": 0.812
        },
        {
            "name": "Chaos Knight",
            "Ancient Apparition": 1.277
        },
        {
            "name": "Chen",
            "Ancient Apparition": -0.811
        },
        {
            "name": "Clinkz",
            "Ancient Apparition": 1.33
        },
        {
            "name": "Clockwerk",
            "Ancient Apparition": -0.799
        },
        {
            "name": "Crystal Maiden",
            "Ancient Apparition": -0.025
        },
        {
            "name": "Dark Seer",
            "Ancient Apparition": -0.958
        },
        {
            "name": "Dark Willow",
            "Ancient Apparition": -0.492
        },
        {
            "name": "Dawnbreaker",
            "Ancient Apparition": -1.614
        },
        {
            "name": "Dazzle",
            "Ancient Apparition": -1.869
        },
        {
            "name": "Death Prophet",
            "Ancient Apparition": -2.654
        },
        {
            "name": "Disruptor",
            "Ancient Apparition": -0.265
        },
        {
            "name": "Doom",
            "Ancient Apparition": 0.642
        },
        {
            "name": "Dragon Knight",
            "Ancient Apparition": -1.968
        },
        {
            "name": "Drow Ranger",
            "Ancient Apparition": 0.712
        },
        {
            "name": "Earth Spirit",
            "Ancient Apparition": 0.237
        },
        {
            "name": "Earthshaker",
            "Ancient Apparition": -0.0
        },
        {
            "name": "Elder Titan",
            "Ancient Apparition": 1.225
        },
        {
            "name": "Ember Spirit",
            "Ancient Apparition": 0.946
        },
        {
            "name": "Enchantress",
            "Ancient Apparition": 0.262
        },
        {
            "name": "Enigma",
            "Ancient Apparition": 0.866
        },
        {
            "name": "Faceless Void",
            "Ancient Apparition": 0.653
        },
        {
            "name": "Grimstroke",
            "Ancient Apparition": 0.65
        },
        {
            "name": "Gyrocopter",
            "Ancient Apparition": -1.294
        },
        {
            "name": "Hoodwink",
            "Ancient Apparition": 0.939
        },
        {
            "name": "Huskar",
            "Ancient Apparition": -7.332
        },
        {
            "name": "Invoker",
            "Ancient Apparition": 1.458
        },
        {
            "name": "Io",
            "Ancient Apparition": -2.584
        },
        {
            "name": "Jakiro",
            "Ancient Apparition": -0.644
        },
        {
            "name": "Juggernaut",
            "Ancient Apparition": 0.595
        },
        {
            "name": "Keeper of the Light",
            "Ancient Apparition": 0.784
        },
        {
            "name": "Kez",
            "Ancient Apparition": 0.857
        },
        {
            "name": "Kunkka",
            "Ancient Apparition": -0.652
        },
        {
            "name": "Legion Commander",
            "Ancient Apparition": -0.107
        },
        {
            "name": "Leshrac",
            "Ancient Apparition": -2.05
        },
        {
            "name": "Lich",
            "Ancient Apparition": -0.059
        },
        {
            "name": "Lifestealer",
            "Ancient Apparition": -0.807
        },
        {
            "name": "Lina",
            "Ancient Apparition": 0.598
        },
        {
            "name": "Lion",
            "Ancient Apparition": 0.867
        },
        {
            "name": "Lone Druid",
            "Ancient Apparition": 0.598
        },
        {
            "name": "Luna",
            "Ancient Apparition": -0.489
        },
        {
            "name": "Lycan",
            "Ancient Apparition": 3.723
        },
        {
            "name": "Magnus",
            "Ancient Apparition": 0.265
        },
        {
            "name": "Marci",
            "Ancient Apparition": -0.572
        },
        {
            "name": "Mars",
            "Ancient Apparition": -0.207
        },
        {
            "name": "Medusa",
            "Ancient Apparition": 1.959
        },
        {
            "name": "Meepo",
            "Ancient Apparition": -0.967
        },
        {
            "name": "Mirana",
            "Ancient Apparition": 1.614
        },
        {
            "name": "Monkey King",
            "Ancient Apparition": -1.862
        },
        {
            "name": "Morphling",
            "Ancient Apparition": -1.65
        },
        {
            "name": "Muerta",
            "Ancient Apparition": -0.236
        },
        {
            "name": "Naga Siren",
            "Ancient Apparition": 0.721
        },
        {
            "name": "Nature's Prophet",
            "Ancient Apparition": 1.842
        },
        {
            "name": "Necrophos",
            "Ancient Apparition": -5.352
        },
        {
            "name": "Night Stalker",
            "Ancient Apparition": 2.415
        },
        {
            "name": "Nyx Assassin",
            "Ancient Apparition": 0.856
        },
        {
            "name": "Ogre Magi",
            "Ancient Apparition": -0.185
        },
        {
            "name": "Omniknight",
            "Ancient Apparition": -2.99
        },
        {
            "name": "Oracle",
            "Ancient Apparition": -0.302
        },
        {
            "name": "Outworld Destroyer",
            "Ancient Apparition": 1.493
        },
        {
            "name": "Pangolier",
            "Ancient Apparition": 1.444
        },
        {
            "name": "Phantom Assassin",
            "Ancient Apparition": 1.694
        },
        {
            "name": "Phantom Lancer",
            "Ancient Apparition": 2.954
        },
        {
            "name": "Phoenix",
            "Ancient Apparition": -0.082
        },
        {
            "name": "Primal Beast",
            "Ancient Apparition": -0.103
        },
        {
            "name": "Puck",
            "Ancient Apparition": 2.779
        },
        {
            "name": "Pudge",
            "Ancient Apparition": -0.083
        },
        {
            "name": "Pugna",
            "Ancient Apparition": -0.229
        },
        {
            "name": "Queen of Pain",
            "Ancient Apparition": 0.429
        },
        {
            "name": "Razor",
            "Ancient Apparition": -0.48
        },
        {
            "name": "Riki",
            "Ancient Apparition": 1.488
        },
        {
            "name": "Ringmaster",
            "Ancient Apparition": -0.207
        },
        {
            "name": "Rubick",
            "Ancient Apparition": -0.222
        },
        {
            "name": "Sand King",
            "Ancient Apparition": -1.38
        },
        {
            "name": "Shadow Demon",
            "Ancient Apparition": 1.185
        },
        {
            "name": "Shadow Fiend",
            "Ancient Apparition": 0.092
        },
        {
            "name": "Shadow Shaman",
            "Ancient Apparition": -0.078
        },
        {
            "name": "Silencer",
            "Ancient Apparition": 0.817
        },
        {
            "name": "Skywrath Mage",
            "Ancient Apparition": 1.28
        },
        {
            "name": "Slardar",
            "Ancient Apparition": 0.887
        },
        {
            "name": "Slark",
            "Ancient Apparition": -0.845
        },
        {
            "name": "Snapfire",
            "Ancient Apparition": 0.103
        },
        {
            "name": "Sniper",
            "Ancient Apparition": 0.801
        },
        {
            "name": "Spectre",
            "Ancient Apparition": 2.922
        },
        {
            "name": "Spirit Breaker",
            "Ancient Apparition": 0.777
        },
        {
            "name": "Storm Spirit",
            "Ancient Apparition": 3.011
        },
        {
            "name": "Sven",
            "Ancient Apparition": -0.491
        },
        {
            "name": "Techies",
            "Ancient Apparition": 0.498
        },
        {
            "name": "Templar Assassin",
            "Ancient Apparition": 1.031
        },
        {
            "name": "Terrorblade",
            "Ancient Apparition": 0.554
        },
        {
            "name": "Tidehunter",
            "Ancient Apparition": -0.28
        },
        {
            "name": "Timbersaw",
            "Ancient Apparition": -0.914
        },
        {
            "name": "Tinker",
            "Ancient Apparition": 1.863
        },
        {
            "name": "Tiny",
            "Ancient Apparition": -0.153
        },
        {
            "name": "Treant Protector",
            "Ancient Apparition": -1.373
        },
        {
            "name": "Troll Warlord",
            "Ancient Apparition": -2.488
        },
        {
            "name": "Tusk",
            "Ancient Apparition": 0.635
        },
        {
            "name": "Underlord",
            "Ancient Apparition": -1.555
        },
        {
            "name": "Undying",
            "Ancient Apparition": -2.203
        },
        {
            "name": "Ursa",
            "Ancient Apparition": 1.699
        },
        {
            "name": "Vengeful Spirit",
            "Ancient Apparition": 0.808
        },
        {
            "name": "Venomancer",
            "Ancient Apparition": -0.314
        },
        {
            "name": "Viper",
            "Ancient Apparition": 0.232
        },
        {
            "name": "Visage",
            "Ancient Apparition": 1.138
        },
        {
            "name": "Void Spirit",
            "Ancient Apparition": 1.99
        },
        {
            "name": "Warlock",
            "Ancient Apparition": -0.46
        },
        {
            "name": "Weaver",
            "Ancient Apparition": 2.496
        },
        {
            "name": "Windranger",
            "Ancient Apparition": 1.566
        },
        {
            "name": "Winter Wyvern",
            "Ancient Apparition": -2.551
        },
        {
            "name": "Witch Doctor",
            "Ancient Apparition": -0.357
        },
        {
            "name": "Wraith King",
            "Ancient Apparition": 0.371
        },
        {
            "name": "Zeus",
            "Ancient Apparition": 1.43
        }
    ],
    "Crystal Maiden": [
        {
            "name": "Abaddon",
            "Crystal Maiden": -0.912
        },
        {
            "name": "Alchemist",
            "Crystal Maiden": -0.319
        },
        {
            "name": "Ancient Apparition",
            "Crystal Maiden": -0.129
        },
        {
            "name": "Anti-Mage",
            "Crystal Maiden": -0.867
        },
        {
            "name": "Arc Warden",
            "Crystal Maiden": -0.318
        },
        {
            "name": "Axe",
            "Crystal Maiden": 0.386
        },
        {
            "name": "Bane",
            "Crystal Maiden": -0.259
        },
        {
            "name": "Batrider",
            "Crystal Maiden": -0.409
        },
        {
            "name": "Beastmaster",
            "Crystal Maiden": -0.395
        },
        {
            "name": "Bloodseeker",
            "Crystal Maiden": -0.795
        },
        {
            "name": "Bounty Hunter",
            "Crystal Maiden": -1.202
        },
        {
            "name": "Brewmaster",
            "Crystal Maiden": -0.577
        },
        {
            "name": "Bristleback",
            "Crystal Maiden": 0.408
        },
        {
            "name": "Broodmother",
            "Crystal Maiden": -3.275
        },
        {
            "name": "Centaur Warrunner",
            "Crystal Maiden": -0.87
        },
        {
            "name": "Chaos Knight",
            "Crystal Maiden": -1.588
        },
        {
            "name": "Chen",
            "Crystal Maiden": 1.002
        },
        {
            "name": "Clinkz",
            "Crystal Maiden": -0.595
        },
        {
            "name": "Clockwerk",
            "Crystal Maiden": 0.471
        },
        {
            "name": "Dark Seer",
            "Crystal Maiden": -0.382
        },
        {
            "name": "Dark Willow",
            "Crystal Maiden": -0.177
        },
        {
            "name": "Dawnbreaker",
            "Crystal Maiden": 1.553
        },
        {
            "name": "Dazzle",
            "Crystal Maiden": -0.608
        },
        {
            "name": "Death Prophet",
            "Crystal Maiden": 1.314
        },
        {
            "name": "Disruptor",
            "Crystal Maiden": 0.018
        },
        {
            "name": "Doom",
            "Crystal Maiden": -0.884
        },
        {
            "name": "Dragon Knight",
            "Crystal Maiden": -0.703
        },
        {
            "name": "Drow Ranger",
            "Crystal Maiden": 1.32
        },
        {
            "name": "Earth Spirit",
            "Crystal Maiden": 0.307
        },
        {
            "name": "Earthshaker",
            "Crystal Maiden": 0.578
        },
        {
            "name": "Elder Titan",
            "Crystal Maiden": -0.179
        },
        {
            "name": "Ember Spirit",
            "Crystal Maiden": -0.97
        },
        {
            "name": "Enchantress",
            "Crystal Maiden": -1.906
        },
        {
            "name": "Enigma",
            "Crystal Maiden": 1.173
        },
        {
            "name": "Faceless Void",
            "Crystal Maiden": -0.271
        },
        {
            "name": "Grimstroke",
            "Crystal Maiden": 0.847
        },
        {
            "name": "Gyrocopter",
            "Crystal Maiden": 0.764
        },
        {
            "name": "Hoodwink",
            "Crystal Maiden": 0.118
        },
        {
            "name": "Huskar",
            "Crystal Maiden": 0.045
        },
        {
            "name": "Invoker",
            "Crystal Maiden": 0.011
        },
        {
            "name": "Io",
            "Crystal Maiden": -1.816
        },
        {
            "name": "Jakiro",
            "Crystal Maiden": 0.476
        },
        {
            "name": "Juggernaut",
            "Crystal Maiden": 1.345
        },
        {
            "name": "Keeper of the Light",
            "Crystal Maiden": -1.198
        },
        {
            "name": "Kez",
            "Crystal Maiden": 0.534
        },
        {
            "name": "Kunkka",
            "Crystal Maiden": 0.198
        },
        {
            "name": "Legion Commander",
            "Crystal Maiden": 0.301
        },
        {
            "name": "Leshrac",
            "Crystal Maiden": 0.842
        },
        {
            "name": "Lich",
            "Crystal Maiden": -0.24
        },
        {
            "name": "Lifestealer",
            "Crystal Maiden": -0.041
        },
        {
            "name": "Lina",
            "Crystal Maiden": 0.018
        },
        {
            "name": "Lion",
            "Crystal Maiden": -0.13
        },
        {
            "name": "Lone Druid",
            "Crystal Maiden": -2.172
        },
        {
            "name": "Luna",
            "Crystal Maiden": 0.669
        },
        {
            "name": "Lycan",
            "Crystal Maiden": -1.501
        },
        {
            "name": "Magnus",
            "Crystal Maiden": 0.847
        },
        {
            "name": "Marci",
            "Crystal Maiden": 0.567
        },
        {
            "name": "Mars",
            "Crystal Maiden": 0.745
        },
        {
            "name": "Medusa",
            "Crystal Maiden": -0.133
        },
        {
            "name": "Meepo",
            "Crystal Maiden": -3.308
        },
        {
            "name": "Mirana",
            "Crystal Maiden": -1.165
        },
        {
            "name": "Monkey King",
            "Crystal Maiden": 0.868
        },
        {
            "name": "Morphling",
            "Crystal Maiden": -0.409
        },
        {
            "name": "Muerta",
            "Crystal Maiden": 0.452
        },
        {
            "name": "Naga Siren",
            "Crystal Maiden": -0.637
        },
        {
            "name": "Nature's Prophet",
            "Crystal Maiden": -0.838
        },
        {
            "name": "Necrophos",
            "Crystal Maiden": -1.733
        },
        {
            "name": "Night Stalker",
            "Crystal Maiden": -0.818
        },
        {
            "name": "Nyx Assassin",
            "Crystal Maiden": 0.447
        },
        {
            "name": "Ogre Magi",
            "Crystal Maiden": -0.196
        },
        {
            "name": "Omniknight",
            "Crystal Maiden": -0.417
        },
        {
            "name": "Oracle",
            "Crystal Maiden": -1.318
        },
        {
            "name": "Outworld Destroyer",
            "Crystal Maiden": -0.231
        },
        {
            "name": "Pangolier",
            "Crystal Maiden": 0.666
        },
        {
            "name": "Phantom Assassin",
            "Crystal Maiden": 0.195
        },
        {
            "name": "Phantom Lancer",
            "Crystal Maiden": -3.009
        },
        {
            "name": "Phoenix",
            "Crystal Maiden": 0.747
        },
        {
            "name": "Primal Beast",
            "Crystal Maiden": 0.339
        },
        {
            "name": "Puck",
            "Crystal Maiden": 0.89
        },
        {
            "name": "Pudge",
            "Crystal Maiden": 1.44
        },
        {
            "name": "Pugna",
            "Crystal Maiden": 0.997
        },
        {
            "name": "Queen of Pain",
            "Crystal Maiden": -0.07
        },
        {
            "name": "Razor",
            "Crystal Maiden": -1.05
        },
        {
            "name": "Riki",
            "Crystal Maiden": 0.269
        },
        {
            "name": "Ringmaster",
            "Crystal Maiden": 0.498
        },
        {
            "name": "Rubick",
            "Crystal Maiden": 2.261
        },
        {
            "name": "Sand King",
            "Crystal Maiden": 0.105
        },
        {
            "name": "Shadow Demon",
            "Crystal Maiden": -0.951
        },
        {
            "name": "Shadow Fiend",
            "Crystal Maiden": 1.0
        },
        {
            "name": "Shadow Shaman",
            "Crystal Maiden": 0.536
        },
        {
            "name": "Silencer",
            "Crystal Maiden": 0.479
        },
        {
            "name": "Skywrath Mage",
            "Crystal Maiden": 0.17
        },
        {
            "name": "Slardar",
            "Crystal Maiden": -0.153
        },
        {
            "name": "Slark",
            "Crystal Maiden": -1.856
        },
        {
            "name": "Snapfire",
            "Crystal Maiden": 0.348
        },
        {
            "name": "Sniper",
            "Crystal Maiden": 0.622
        },
        {
            "name": "Spectre",
            "Crystal Maiden": -0.938
        },
        {
            "name": "Spirit Breaker",
            "Crystal Maiden": -0.843
        },
        {
            "name": "Storm Spirit",
            "Crystal Maiden": -1.336
        },
        {
            "name": "Sven",
            "Crystal Maiden": -0.152
        },
        {
            "name": "Techies",
            "Crystal Maiden": 0.472
        },
        {
            "name": "Templar Assassin",
            "Crystal Maiden": -0.386
        },
        {
            "name": "Terrorblade",
            "Crystal Maiden": 0.076
        },
        {
            "name": "Tidehunter",
            "Crystal Maiden": 0.255
        },
        {
            "name": "Timbersaw",
            "Crystal Maiden": -0.387
        },
        {
            "name": "Tinker",
            "Crystal Maiden": 0.357
        },
        {
            "name": "Tiny",
            "Crystal Maiden": 0.074
        },
        {
            "name": "Treant Protector",
            "Crystal Maiden": -0.311
        },
        {
            "name": "Troll Warlord",
            "Crystal Maiden": -0.982
        },
        {
            "name": "Tusk",
            "Crystal Maiden": -0.161
        },
        {
            "name": "Underlord",
            "Crystal Maiden": 0.15
        },
        {
            "name": "Undying",
            "Crystal Maiden": 0.658
        },
        {
            "name": "Ursa",
            "Crystal Maiden": 0.06
        },
        {
            "name": "Vengeful Spirit",
            "Crystal Maiden": -0.949
        },
        {
            "name": "Venomancer",
            "Crystal Maiden": -0.627
        },
        {
            "name": "Viper",
            "Crystal Maiden": -0.688
        },
        {
            "name": "Visage",
            "Crystal Maiden": -1.316
        },
        {
            "name": "Void Spirit",
            "Crystal Maiden": -0.173
        },
        {
            "name": "Warlock",
            "Crystal Maiden": -0.287
        },
        {
            "name": "Weaver",
            "Crystal Maiden": -0.572
        },
        {
            "name": "Windranger",
            "Crystal Maiden": 0.021
        },
        {
            "name": "Winter Wyvern",
            "Crystal Maiden": 0.421
        },
        {
            "name": "Witch Doctor",
            "Crystal Maiden": 1.168
        },
        {
            "name": "Wraith King",
            "Crystal Maiden": -1.81
        },
        {
            "name": "Zeus",
            "Crystal Maiden": 0.482
        }
    ],
    "Death Prophet": [
        {
            "name": "Abaddon",
            "Death Prophet": -0.579
        },
        {
            "name": "Alchemist",
            "Death Prophet": -0.175
        },
        {
            "name": "Ancient Apparition",
            "Death Prophet": 2.972
        },
        {
            "name": "Anti-Mage",
            "Death Prophet": 1.943
        },
        {
            "name": "Arc Warden",
            "Death Prophet": 0.946
        },
        {
            "name": "Axe",
            "Death Prophet": -1.289
        },
        {
            "name": "Bane",
            "Death Prophet": -0.364
        },
        {
            "name": "Batrider",
            "Death Prophet": 1.424
        },
        {
            "name": "Beastmaster",
            "Death Prophet": 1.285
        },
        {
            "name": "Bloodseeker",
            "Death Prophet": 0.233
        },
        {
            "name": "Bounty Hunter",
            "Death Prophet": 0.689
        },
        {
            "name": "Brewmaster",
            "Death Prophet": -1.669
        },
        {
            "name": "Bristleback",
            "Death Prophet": 2.474
        },
        {
            "name": "Broodmother",
            "Death Prophet": 1.586
        },
        {
            "name": "Centaur Warrunner",
            "Death Prophet": -0.539
        },
        {
            "name": "Chaos Knight",
            "Death Prophet": 1.55
        },
        {
            "name": "Chen",
            "Death Prophet": 0.58
        },
        {
            "name": "Clinkz",
            "Death Prophet": 2.381
        },
        {
            "name": "Clockwerk",
            "Death Prophet": 0.388
        },
        {
            "name": "Crystal Maiden",
            "Death Prophet": -1.435
        },
        {
            "name": "Dark Seer",
            "Death Prophet": 0.85
        },
        {
            "name": "Dark Willow",
            "Death Prophet": 0.409
        },
        {
            "name": "Dawnbreaker",
            "Death Prophet": -1.991
        },
        {
            "name": "Dazzle",
            "Death Prophet": 0.24
        },
        {
            "name": "Disruptor",
            "Death Prophet": 0.164
        },
        {
            "name": "Doom",
            "Death Prophet": 0.21
        },
        {
            "name": "Dragon Knight",
            "Death Prophet": 1.321
        },
        {
            "name": "Drow Ranger",
            "Death Prophet": 2.167
        },
        {
            "name": "Earth Spirit",
            "Death Prophet": -1.157
        },
        {
            "name": "Earthshaker",
            "Death Prophet": -1.271
        },
        {
            "name": "Elder Titan",
            "Death Prophet": -0.029
        },
        {
            "name": "Ember Spirit",
            "Death Prophet": 0.399
        },
        {
            "name": "Enchantress",
            "Death Prophet": -0.156
        },
        {
            "name": "Enigma",
            "Death Prophet": -0.393
        },
        {
            "name": "Faceless Void",
            "Death Prophet": -0.908
        },
        {
            "name": "Grimstroke",
            "Death Prophet": 0.235
        },
        {
            "name": "Gyrocopter",
            "Death Prophet": 1.013
        },
        {
            "name": "Hoodwink",
            "Death Prophet": -0.113
        },
        {
            "name": "Huskar",
            "Death Prophet": -2.187
        },
        {
            "name": "Invoker",
            "Death Prophet": 0.382
        },
        {
            "name": "Io",
            "Death Prophet": 0.057
        },
        {
            "name": "Jakiro",
            "Death Prophet": 0.39
        },
        {
            "name": "Juggernaut",
            "Death Prophet": -2.398
        },
        {
            "name": "Keeper of the Light",
            "Death Prophet": 1.481
        },
        {
            "name": "Kez",
            "Death Prophet": -0.975
        },
        {
            "name": "Kunkka",
            "Death Prophet": 0.215
        },
        {
            "name": "Legion Commander",
            "Death Prophet": 0.31
        },
        {
            "name": "Leshrac",
            "Death Prophet": -0.367
        },
        {
            "name": "Lich",
            "Death Prophet": -1.337
        },
        {
            "name": "Lifestealer",
            "Death Prophet": -2.073
        },
        {
            "name": "Lina",
            "Death Prophet": 0.604
        },
        {
            "name": "Lion",
            "Death Prophet": -0.144
        },
        {
            "name": "Lone Druid",
            "Death Prophet": 1.553
        },
        {
            "name": "Luna",
            "Death Prophet": 1.702
        },
        {
            "name": "Lycan",
            "Death Prophet": 1.861
        },
        {
            "name": "Magnus",
            "Death Prophet": -0.721
        },
        {
            "name": "Marci",
            "Death Prophet": 0.184
        },
        {
            "name": "Mars",
            "Death Prophet": -1.39
        },
        {
            "name": "Medusa",
            "Death Prophet": 2.0
        },
        {
            "name": "Meepo",
            "Death Prophet": -2.309
        },
        {
            "name": "Mirana",
            "Death Prophet": 1.176
        },
        {
            "name": "Monkey King",
            "Death Prophet": 0.351
        },
        {
            "name": "Morphling",
            "Death Prophet": 0.342
        },
        {
            "name": "Muerta",
            "Death Prophet": -0.07
        },
        {
            "name": "Naga Siren",
            "Death Prophet": 0.56
        },
        {
            "name": "Nature's Prophet",
            "Death Prophet": 1.55
        },
        {
            "name": "Necrophos",
            "Death Prophet": -3.972
        },
        {
            "name": "Night Stalker",
            "Death Prophet": 0.76
        },
        {
            "name": "Nyx Assassin",
            "Death Prophet": -0.457
        },
        {
            "name": "Ogre Magi",
            "Death Prophet": 1.005
        },
        {
            "name": "Omniknight",
            "Death Prophet": 0.225
        },
        {
            "name": "Oracle",
            "Death Prophet": -2.601
        },
        {
            "name": "Outworld Destroyer",
            "Death Prophet": -0.189
        },
        {
            "name": "Pangolier",
            "Death Prophet": -0.159
        },
        {
            "name": "Phantom Assassin",
            "Death Prophet": -1.016
        },
        {
            "name": "Phantom Lancer",
            "Death Prophet": 2.669
        },
        {
            "name": "Phoenix",
            "Death Prophet": -1.666
        },
        {
            "name": "Primal Beast",
            "Death Prophet": -0.153
        },
        {
            "name": "Puck",
            "Death Prophet": -1.382
        },
        {
            "name": "Pudge",
            "Death Prophet": -1.122
        },
        {
            "name": "Pugna",
            "Death Prophet": -0.59
        },
        {
            "name": "Queen of Pain",
            "Death Prophet": 0.294
        },
        {
            "name": "Razor",
            "Death Prophet": 2.15
        },
        {
            "name": "Riki",
            "Death Prophet": 0.293
        },
        {
            "name": "Ringmaster",
            "Death Prophet": -0.451
        },
        {
            "name": "Rubick",
            "Death Prophet": -0.231
        },
        {
            "name": "Sand King",
            "Death Prophet": 0.041
        },
        {
            "name": "Shadow Demon",
            "Death Prophet": 1.009
        },
        {
            "name": "Shadow Fiend",
            "Death Prophet": 0.179
        },
        {
            "name": "Shadow Shaman",
            "Death Prophet": -0.022
        },
        {
            "name": "Silencer",
            "Death Prophet": 1.01
        },
        {
            "name": "Skywrath Mage",
            "Death Prophet": -0.214
        },
        {
            "name": "Slardar",
            "Death Prophet": 0.485
        },
        {
            "name": "Slark",
            "Death Prophet": 0.33
        },
        {
            "name": "Snapfire",
            "Death Prophet": -0.634
        },
        {
            "name": "Sniper",
            "Death Prophet": 1.649
        },
        {
            "name": "Spectre",
            "Death Prophet": 0.017
        },
        {
            "name": "Spirit Breaker",
            "Death Prophet": 0.824
        },
        {
            "name": "Storm Spirit",
            "Death Prophet": -0.972
        },
        {
            "name": "Sven",
            "Death Prophet": 0.748
        },
        {
            "name": "Techies",
            "Death Prophet": 0.238
        },
        {
            "name": "Templar Assassin",
            "Death Prophet": 0.342
        },
        {
            "name": "Terrorblade",
            "Death Prophet": 2.161
        },
        {
            "name": "Tidehunter",
            "Death Prophet": -2.789
        },
        {
            "name": "Timbersaw",
            "Death Prophet": -2.626
        },
        {
            "name": "Tinker",
            "Death Prophet": -2.259
        },
        {
            "name": "Tiny",
            "Death Prophet": -1.186
        },
        {
            "name": "Treant Protector",
            "Death Prophet": -1.672
        },
        {
            "name": "Troll Warlord",
            "Death Prophet": 0.153
        },
        {
            "name": "Tusk",
            "Death Prophet": -1.171
        },
        {
            "name": "Underlord",
            "Death Prophet": 0.639
        },
        {
            "name": "Undying",
            "Death Prophet": 2.514
        },
        {
            "name": "Ursa",
            "Death Prophet": -1.127
        },
        {
            "name": "Vengeful Spirit",
            "Death Prophet": 0.616
        },
        {
            "name": "Venomancer",
            "Death Prophet": 0.833
        },
        {
            "name": "Viper",
            "Death Prophet": -0.468
        },
        {
            "name": "Visage",
            "Death Prophet": 1.404
        },
        {
            "name": "Void Spirit",
            "Death Prophet": 0.907
        },
        {
            "name": "Warlock",
            "Death Prophet": 1.049
        },
        {
            "name": "Weaver",
            "Death Prophet": 2.326
        },
        {
            "name": "Windranger",
            "Death Prophet": 0.938
        },
        {
            "name": "Winter Wyvern",
            "Death Prophet": -1.319
        },
        {
            "name": "Witch Doctor",
            "Death Prophet": 0.027
        },
        {
            "name": "Wraith King",
            "Death Prophet": -0.162
        },
        {
            "name": "Zeus",
            "Death Prophet": -0.112
        }
    ],
    "Disruptor": [
        {
            "name": "Abaddon",
            "Disruptor": 0.072
        },
        {
            "name": "Alchemist",
            "Disruptor": 0.296
        },
        {
            "name": "Ancient Apparition",
            "Disruptor": 0.35
        },
        {
            "name": "Anti-Mage",
            "Disruptor": -1.387
        },
        {
            "name": "Arc Warden",
            "Disruptor": 1.649
        },
        {
            "name": "Axe",
            "Disruptor": -0.234
        },
        {
            "name": "Bane",
            "Disruptor": 0.572
        },
        {
            "name": "Batrider",
            "Disruptor": 0.095
        },
        {
            "name": "Beastmaster",
            "Disruptor": 0.842
        },
        {
            "name": "Bloodseeker",
            "Disruptor": 1.688
        },
        {
            "name": "Bounty Hunter",
            "Disruptor": 0.944
        },
        {
            "name": "Brewmaster",
            "Disruptor": 0.475
        },
        {
            "name": "Bristleback",
            "Disruptor": -0.177
        },
        {
            "name": "Broodmother",
            "Disruptor": -0.407
        },
        {
            "name": "Centaur Warrunner",
            "Disruptor": -0.514
        },
        {
            "name": "Chaos Knight",
            "Disruptor": 0.689
        },
        {
            "name": "Chen",
            "Disruptor": 0.939
        },
        {
            "name": "Clinkz",
            "Disruptor": 1.389
        },
        {
            "name": "Clockwerk",
            "Disruptor": -0.361
        },
        {
            "name": "Crystal Maiden",
            "Disruptor": -0.117
        },
        {
            "name": "Dark Seer",
            "Disruptor": -0.683
        },
        {
            "name": "Dark Willow",
            "Disruptor": 0.64
        },
        {
            "name": "Dawnbreaker",
            "Disruptor": -0.504
        },
        {
            "name": "Dazzle",
            "Disruptor": 0.324
        },
        {
            "name": "Death Prophet",
            "Disruptor": -0.098
        },
        {
            "name": "Doom",
            "Disruptor": 0.177
        },
        {
            "name": "Dragon Knight",
            "Disruptor": 1.017
        },
        {
            "name": "Drow Ranger",
            "Disruptor": 1.278
        },
        {
            "name": "Earth Spirit",
            "Disruptor": 0.916
        },
        {
            "name": "Earthshaker",
            "Disruptor": -1.033
        },
        {
            "name": "Elder Titan",
            "Disruptor": 0.164
        },
        {
            "name": "Ember Spirit",
            "Disruptor": -0.519
        },
        {
            "name": "Enchantress",
            "Disruptor": 0.638
        },
        {
            "name": "Enigma",
            "Disruptor": 0.294
        },
        {
            "name": "Faceless Void",
            "Disruptor": -0.589
        },
        {
            "name": "Grimstroke",
            "Disruptor": -0.824
        },
        {
            "name": "Gyrocopter",
            "Disruptor": 1.105
        },
        {
            "name": "Hoodwink",
            "Disruptor": -0.186
        },
        {
            "name": "Huskar",
            "Disruptor": 1.277
        },
        {
            "name": "Invoker",
            "Disruptor": 0.693
        },
        {
            "name": "Io",
            "Disruptor": -0.624
        },
        {
            "name": "Jakiro",
            "Disruptor": -0.408
        },
        {
            "name": "Juggernaut",
            "Disruptor": -0.668
        },
        {
            "name": "Keeper of the Light",
            "Disruptor": 0.051
        },
        {
            "name": "Kez",
            "Disruptor": 0.787
        },
        {
            "name": "Kunkka",
            "Disruptor": 0.591
        },
        {
            "name": "Legion Commander",
            "Disruptor": 0.733
        },
        {
            "name": "Leshrac",
            "Disruptor": -1.143
        },
        {
            "name": "Lich",
            "Disruptor": 0.267
        },
        {
            "name": "Lifestealer",
            "Disruptor": 0.832
        },
        {
            "name": "Lina",
            "Disruptor": 0.735
        },
        {
            "name": "Lion",
            "Disruptor": -0.366
        },
        {
            "name": "Lone Druid",
            "Disruptor": 0.981
        },
        {
            "name": "Luna",
            "Disruptor": 1.357
        },
        {
            "name": "Lycan",
            "Disruptor": 1.562
        },
        {
            "name": "Magnus",
            "Disruptor": -0.626
        },
        {
            "name": "Marci",
            "Disruptor": -0.692
        },
        {
            "name": "Mars",
            "Disruptor": -0.169
        },
        {
            "name": "Medusa",
            "Disruptor": 0.434
        },
        {
            "name": "Meepo",
            "Disruptor": -1.673
        },
        {
            "name": "Mirana",
            "Disruptor": -0.65
        },
        {
            "name": "Monkey King",
            "Disruptor": 0.777
        },
        {
            "name": "Morphling",
            "Disruptor": 0.561
        },
        {
            "name": "Muerta",
            "Disruptor": -0.382
        },
        {
            "name": "Naga Siren",
            "Disruptor": 0.189
        },
        {
            "name": "Nature's Prophet",
            "Disruptor": 2.098
        },
        {
            "name": "Necrophos",
            "Disruptor": -1.791
        },
        {
            "name": "Night Stalker",
            "Disruptor": -1.031
        },
        {
            "name": "Nyx Assassin",
            "Disruptor": -0.545
        },
        {
            "name": "Ogre Magi",
            "Disruptor": 0.643
        },
        {
            "name": "Omniknight",
            "Disruptor": 1.666
        },
        {
            "name": "Oracle",
            "Disruptor": 1.564
        },
        {
            "name": "Outworld Destroyer",
            "Disruptor": -0.23
        },
        {
            "name": "Pangolier",
            "Disruptor": 1.635
        },
        {
            "name": "Phantom Assassin",
            "Disruptor": 0.239
        },
        {
            "name": "Phantom Lancer",
            "Disruptor": 0.489
        },
        {
            "name": "Phoenix",
            "Disruptor": -0.692
        },
        {
            "name": "Primal Beast",
            "Disruptor": -1.3
        },
        {
            "name": "Puck",
            "Disruptor": -0.398
        },
        {
            "name": "Pudge",
            "Disruptor": -0.77
        },
        {
            "name": "Pugna",
            "Disruptor": 1.895
        },
        {
            "name": "Queen of Pain",
            "Disruptor": -0.23
        },
        {
            "name": "Razor",
            "Disruptor": 0.228
        },
        {
            "name": "Riki",
            "Disruptor": -1.059
        },
        {
            "name": "Ringmaster",
            "Disruptor": 1.196
        },
        {
            "name": "Rubick",
            "Disruptor": -0.326
        },
        {
            "name": "Sand King",
            "Disruptor": -0.906
        },
        {
            "name": "Shadow Demon",
            "Disruptor": 2.322
        },
        {
            "name": "Shadow Fiend",
            "Disruptor": 0.164
        },
        {
            "name": "Shadow Shaman",
            "Disruptor": 0.212
        },
        {
            "name": "Silencer",
            "Disruptor": -0.473
        },
        {
            "name": "Skywrath Mage",
            "Disruptor": 0.043
        },
        {
            "name": "Slardar",
            "Disruptor": -0.761
        },
        {
            "name": "Slark",
            "Disruptor": -4.528
        },
        {
            "name": "Snapfire",
            "Disruptor": -0.269
        },
        {
            "name": "Sniper",
            "Disruptor": 2.003
        },
        {
            "name": "Spectre",
            "Disruptor": -0.541
        },
        {
            "name": "Spirit Breaker",
            "Disruptor": -0.586
        },
        {
            "name": "Storm Spirit",
            "Disruptor": -1.114
        },
        {
            "name": "Sven",
            "Disruptor": 0.27
        },
        {
            "name": "Techies",
            "Disruptor": 0.042
        },
        {
            "name": "Templar Assassin",
            "Disruptor": 1.22
        },
        {
            "name": "Terrorblade",
            "Disruptor": 0.937
        },
        {
            "name": "Tidehunter",
            "Disruptor": -0.391
        },
        {
            "name": "Timbersaw",
            "Disruptor": -0.349
        },
        {
            "name": "Tinker",
            "Disruptor": 1.043
        },
        {
            "name": "Tiny",
            "Disruptor": -0.074
        },
        {
            "name": "Treant Protector",
            "Disruptor": 0.374
        },
        {
            "name": "Troll Warlord",
            "Disruptor": -0.638
        },
        {
            "name": "Tusk",
            "Disruptor": 0.208
        },
        {
            "name": "Underlord",
            "Disruptor": 0.342
        },
        {
            "name": "Undying",
            "Disruptor": 0.885
        },
        {
            "name": "Ursa",
            "Disruptor": -1.209
        },
        {
            "name": "Vengeful Spirit",
            "Disruptor": 0.555
        },
        {
            "name": "Venomancer",
            "Disruptor": 0.813
        },
        {
            "name": "Viper",
            "Disruptor": 1.562
        },
        {
            "name": "Visage",
            "Disruptor": -0.941
        },
        {
            "name": "Void Spirit",
            "Disruptor": -1.63
        },
        {
            "name": "Warlock",
            "Disruptor": 0.3
        },
        {
            "name": "Weaver",
            "Disruptor": -0.504
        },
        {
            "name": "Windranger",
            "Disruptor": 0.382
        },
        {
            "name": "Winter Wyvern",
            "Disruptor": 0.636
        },
        {
            "name": "Witch Doctor",
            "Disruptor": -0.605
        },
        {
            "name": "Wraith King",
            "Disruptor": 0.349
        },
        {
            "name": "Zeus",
            "Disruptor": 0.345
        }
    ],
    "Enchantress": [
        {
            "name": "Abaddon",
            "Enchantress": -0.58
        },
        {
            "name": "Alchemist",
            "Enchantress": -0.857
        },
        {
            "name": "Ancient Apparition",
            "Enchantress": 0.102
        },
        {
            "name": "Anti-Mage",
            "Enchantress": -3.294
        },
        {
            "name": "Arc Warden",
            "Enchantress": 1.605
        },
        {
            "name": "Axe",
            "Enchantress": 0.593
        },
        {
            "name": "Bane",
            "Enchantress": -2.483
        },
        {
            "name": "Batrider",
            "Enchantress": -0.695
        },
        {
            "name": "Beastmaster",
            "Enchantress": -0.885
        },
        {
            "name": "Bloodseeker",
            "Enchantress": -1.993
        },
        {
            "name": "Bounty Hunter",
            "Enchantress": 0.085
        },
        {
            "name": "Brewmaster",
            "Enchantress": 0.566
        },
        {
            "name": "Bristleback",
            "Enchantress": 1.604
        },
        {
            "name": "Broodmother",
            "Enchantress": 0.719
        },
        {
            "name": "Centaur Warrunner",
            "Enchantress": 0.083
        },
        {
            "name": "Chaos Knight",
            "Enchantress": 2.013
        },
        {
            "name": "Chen",
            "Enchantress": 2.348
        },
        {
            "name": "Clinkz",
            "Enchantress": -2.37
        },
        {
            "name": "Clockwerk",
            "Enchantress": 0.279
        },
        {
            "name": "Crystal Maiden",
            "Enchantress": 2.32
        },
        {
            "name": "Dark Seer",
            "Enchantress": -1.174
        },
        {
            "name": "Dark Willow",
            "Enchantress": 0.111
        },
        {
            "name": "Dawnbreaker",
            "Enchantress": 1.005
        },
        {
            "name": "Dazzle",
            "Enchantress": 0.713
        },
        {
            "name": "Death Prophet",
            "Enchantress": 0.121
        },
        {
            "name": "Disruptor",
            "Enchantress": -0.542
        },
        {
            "name": "Doom",
            "Enchantress": 0.352
        },
        {
            "name": "Dragon Knight",
            "Enchantress": -0.358
        },
        {
            "name": "Drow Ranger",
            "Enchantress": -0.438
        },
        {
            "name": "Earth Spirit",
            "Enchantress": 0.761
        },
        {
            "name": "Earthshaker",
            "Enchantress": -1.148
        },
        {
            "name": "Elder Titan",
            "Enchantress": -0.723
        },
        {
            "name": "Ember Spirit",
            "Enchantress": -1.17
        },
        {
            "name": "Enigma",
            "Enchantress": -1.213
        },
        {
            "name": "Faceless Void",
            "Enchantress": -2.52
        },
        {
            "name": "Grimstroke",
            "Enchantress": -0.114
        },
        {
            "name": "Gyrocopter",
            "Enchantress": -1.0
        },
        {
            "name": "Hoodwink",
            "Enchantress": 0.919
        },
        {
            "name": "Huskar",
            "Enchantress": -1.078
        },
        {
            "name": "Invoker",
            "Enchantress": -0.999
        },
        {
            "name": "Io",
            "Enchantress": 1.944
        },
        {
            "name": "Jakiro",
            "Enchantress": 0.429
        },
        {
            "name": "Juggernaut",
            "Enchantress": -1.31
        },
        {
            "name": "Keeper of the Light",
            "Enchantress": -0.318
        },
        {
            "name": "Kez",
            "Enchantress": 0.705
        },
        {
            "name": "Kunkka",
            "Enchantress": -0.643
        },
        {
            "name": "Legion Commander",
            "Enchantress": 0.891
        },
        {
            "name": "Leshrac",
            "Enchantress": 1.247
        },
        {
            "name": "Lich",
            "Enchantress": 0.494
        },
        {
            "name": "Lifestealer",
            "Enchantress": -0.137
        },
        {
            "name": "Lina",
            "Enchantress": -0.159
        },
        {
            "name": "Lion",
            "Enchantress": 0.315
        },
        {
            "name": "Lone Druid",
            "Enchantress": -1.849
        },
        {
            "name": "Luna",
            "Enchantress": -0.055
        },
        {
            "name": "Lycan",
            "Enchantress": -2.329
        },
        {
            "name": "Magnus",
            "Enchantress": 0.595
        },
        {
            "name": "Marci",
            "Enchantress": 4.407
        },
        {
            "name": "Mars",
            "Enchantress": 2.759
        },
        {
            "name": "Medusa",
            "Enchantress": 1.428
        },
        {
            "name": "Meepo",
            "Enchantress": -1.61
        },
        {
            "name": "Mirana",
            "Enchantress": -0.61
        },
        {
            "name": "Monkey King",
            "Enchantress": -0.712
        },
        {
            "name": "Morphling",
            "Enchantress": 0.816
        },
        {
            "name": "Muerta",
            "Enchantress": -0.438
        },
        {
            "name": "Naga Siren",
            "Enchantress": 1.453
        },
        {
            "name": "Nature's Prophet",
            "Enchantress": -0.834
        },
        {
            "name": "Necrophos",
            "Enchantress": 0.309
        },
        {
            "name": "Night Stalker",
            "Enchantress": -1.619
        },
        {
            "name": "Nyx Assassin",
            "Enchantress": 0.399
        },
        {
            "name": "Ogre Magi",
            "Enchantress": 1.814
        },
        {
            "name": "Omniknight",
            "Enchantress": -0.181
        },
        {
            "name": "Oracle",
            "Enchantress": -2.297
        },
        {
            "name": "Outworld Destroyer",
            "Enchantress": -0.455
        },
        {
            "name": "Pangolier",
            "Enchantress": 0.406
        },
        {
            "name": "Phantom Assassin",
            "Enchantress": 4.728
        },
        {
            "name": "Phantom Lancer",
            "Enchantress": 2.555
        },
        {
            "name": "Phoenix",
            "Enchantress": 0.204
        },
        {
            "name": "Primal Beast",
            "Enchantress": 1.862
        },
        {
            "name": "Puck",
            "Enchantress": -0.772
        },
        {
            "name": "Pudge",
            "Enchantress": 1.406
        },
        {
            "name": "Pugna",
            "Enchantress": -1.338
        },
        {
            "name": "Queen of Pain",
            "Enchantress": 0.494
        },
        {
            "name": "Razor",
            "Enchantress": 0.056
        },
        {
            "name": "Riki",
            "Enchantress": 0.827
        },
        {
            "name": "Ringmaster",
            "Enchantress": 0.62
        },
        {
            "name": "Rubick",
            "Enchantress": -1.187
        },
        {
            "name": "Sand King",
            "Enchantress": 1.346
        },
        {
            "name": "Shadow Demon",
            "Enchantress": -0.862
        },
        {
            "name": "Shadow Fiend",
            "Enchantress": -0.502
        },
        {
            "name": "Shadow Shaman",
            "Enchantress": -0.838
        },
        {
            "name": "Silencer",
            "Enchantress": 0.002
        },
        {
            "name": "Skywrath Mage",
            "Enchantress": 0.174
        },
        {
            "name": "Slardar",
            "Enchantress": -0.576
        },
        {
            "name": "Slark",
            "Enchantress": 1.44
        },
        {
            "name": "Snapfire",
            "Enchantress": 0.097
        },
        {
            "name": "Sniper",
            "Enchantress": -1.936
        },
        {
            "name": "Spectre",
            "Enchantress": -2.117
        },
        {
            "name": "Spirit Breaker",
            "Enchantress": -0.897
        },
        {
            "name": "Storm Spirit",
            "Enchantress": 0.016
        },
        {
            "name": "Sven",
            "Enchantress": -1.617
        },
        {
            "name": "Techies",
            "Enchantress": 0.518
        },
        {
            "name": "Templar Assassin",
            "Enchantress": -1.571
        },
        {
            "name": "Terrorblade",
            "Enchantress": -0.081
        },
        {
            "name": "Tidehunter",
            "Enchantress": -0.965
        },
        {
            "name": "Timbersaw",
            "Enchantress": -0.858
        },
        {
            "name": "Tinker",
            "Enchantress": 1.667
        },
        {
            "name": "Tiny",
            "Enchantress": -0.009
        },
        {
            "name": "Treant Protector",
            "Enchantress": -0.171
        },
        {
            "name": "Troll Warlord",
            "Enchantress": -0.827
        },
        {
            "name": "Tusk",
            "Enchantress": -0.443
        },
        {
            "name": "Underlord",
            "Enchantress": -0.471
        },
        {
            "name": "Undying",
            "Enchantress": -0.188
        },
        {
            "name": "Ursa",
            "Enchantress": -0.389
        },
        {
            "name": "Vengeful Spirit",
            "Enchantress": -0.72
        },
        {
            "name": "Venomancer",
            "Enchantress": -0.698
        },
        {
            "name": "Viper",
            "Enchantress": -0.264
        },
        {
            "name": "Visage",
            "Enchantress": -2.017
        },
        {
            "name": "Void Spirit",
            "Enchantress": -0.254
        },
        {
            "name": "Warlock",
            "Enchantress": 0.632
        },
        {
            "name": "Weaver",
            "Enchantress": -0.011
        },
        {
            "name": "Windranger",
            "Enchantress": 0.263
        },
        {
            "name": "Winter Wyvern",
            "Enchantress": 0.444
        },
        {
            "name": "Witch Doctor",
            "Enchantress": 0.401
        },
        {
            "name": "Wraith King",
            "Enchantress": -0.619
        },
        {
            "name": "Zeus",
            "Enchantress": -1.1
        }
    ],
    "Grimstroke": [
        {
            "name": "Abaddon",
            "Grimstroke": -1.299
        },
        {
            "name": "Alchemist",
            "Grimstroke": -1.139
        },
        {
            "name": "Ancient Apparition",
            "Grimstroke": -0.512
        },
        {
            "name": "Anti-Mage",
            "Grimstroke": 0.826
        },
        {
            "name": "Arc Warden",
            "Grimstroke": 0.48
        },
        {
            "name": "Axe",
            "Grimstroke": -0.375
        },
        {
            "name": "Bane",
            "Grimstroke": 0.771
        },
        {
            "name": "Batrider",
            "Grimstroke": -0.82
        },
        {
            "name": "Beastmaster",
            "Grimstroke": 0.449
        },
        {
            "name": "Bloodseeker",
            "Grimstroke": 0.73
        },
        {
            "name": "Bounty Hunter",
            "Grimstroke": 0.424
        },
        {
            "name": "Brewmaster",
            "Grimstroke": -0.591
        },
        {
            "name": "Bristleback",
            "Grimstroke": 1.139
        },
        {
            "name": "Broodmother",
            "Grimstroke": -1.946
        },
        {
            "name": "Centaur Warrunner",
            "Grimstroke": 0.069
        },
        {
            "name": "Chaos Knight",
            "Grimstroke": 0.738
        },
        {
            "name": "Chen",
            "Grimstroke": -0.027
        },
        {
            "name": "Clinkz",
            "Grimstroke": 0.612
        },
        {
            "name": "Clockwerk",
            "Grimstroke": -0.588
        },
        {
            "name": "Crystal Maiden",
            "Grimstroke": -0.954
        },
        {
            "name": "Dark Seer",
            "Grimstroke": 0.004
        },
        {
            "name": "Dark Willow",
            "Grimstroke": 0.35
        },
        {
            "name": "Dawnbreaker",
            "Grimstroke": 0.115
        },
        {
            "name": "Dazzle",
            "Grimstroke": -0.52
        },
        {
            "name": "Death Prophet",
            "Grimstroke": -0.221
        },
        {
            "name": "Disruptor",
            "Grimstroke": 0.873
        },
        {
            "name": "Doom",
            "Grimstroke": -0.681
        },
        {
            "name": "Dragon Knight",
            "Grimstroke": 0.72
        },
        {
            "name": "Drow Ranger",
            "Grimstroke": 0.605
        },
        {
            "name": "Earth Spirit",
            "Grimstroke": -0.25
        },
        {
            "name": "Earthshaker",
            "Grimstroke": -0.939
        },
        {
            "name": "Elder Titan",
            "Grimstroke": 0.023
        },
        {
            "name": "Ember Spirit",
            "Grimstroke": -1.079
        },
        {
            "name": "Enchantress",
            "Grimstroke": 0.068
        },
        {
            "name": "Enigma",
            "Grimstroke": -0.359
        },
        {
            "name": "Faceless Void",
            "Grimstroke": -1.533
        },
        {
            "name": "Gyrocopter",
            "Grimstroke": 1.94
        },
        {
            "name": "Hoodwink",
            "Grimstroke": 0.452
        },
        {
            "name": "Huskar",
            "Grimstroke": 0.566
        },
        {
            "name": "Invoker",
            "Grimstroke": 0.679
        },
        {
            "name": "Io",
            "Grimstroke": 0.222
        },
        {
            "name": "Jakiro",
            "Grimstroke": 0.788
        },
        {
            "name": "Juggernaut",
            "Grimstroke": -0.32
        },
        {
            "name": "Keeper of the Light",
            "Grimstroke": 0.752
        },
        {
            "name": "Kez",
            "Grimstroke": 0.38
        },
        {
            "name": "Kunkka",
            "Grimstroke": 0.231
        },
        {
            "name": "Legion Commander",
            "Grimstroke": 0.433
        },
        {
            "name": "Leshrac",
            "Grimstroke": 0.23
        },
        {
            "name": "Lich",
            "Grimstroke": 0.178
        },
        {
            "name": "Lifestealer",
            "Grimstroke": -0.847
        },
        {
            "name": "Lina",
            "Grimstroke": 1.612
        },
        {
            "name": "Lion",
            "Grimstroke": -0.429
        },
        {
            "name": "Lone Druid",
            "Grimstroke": 1.802
        },
        {
            "name": "Luna",
            "Grimstroke": 0.431
        },
        {
            "name": "Lycan",
            "Grimstroke": 2.965
        },
        {
            "name": "Magnus",
            "Grimstroke": -0.817
        },
        {
            "name": "Marci",
            "Grimstroke": 1.034
        },
        {
            "name": "Mars",
            "Grimstroke": 0.91
        },
        {
            "name": "Medusa",
            "Grimstroke": 1.877
        },
        {
            "name": "Meepo",
            "Grimstroke": 1.117
        },
        {
            "name": "Mirana",
            "Grimstroke": 0.829
        },
        {
            "name": "Monkey King",
            "Grimstroke": 0.121
        },
        {
            "name": "Morphling",
            "Grimstroke": -0.931
        },
        {
            "name": "Muerta",
            "Grimstroke": 0.083
        },
        {
            "name": "Naga Siren",
            "Grimstroke": -0.559
        },
        {
            "name": "Nature's Prophet",
            "Grimstroke": 0.791
        },
        {
            "name": "Necrophos",
            "Grimstroke": -2.794
        },
        {
            "name": "Night Stalker",
            "Grimstroke": 0.97
        },
        {
            "name": "Nyx Assassin",
            "Grimstroke": -1.161
        },
        {
            "name": "Ogre Magi",
            "Grimstroke": 0.007
        },
        {
            "name": "Omniknight",
            "Grimstroke": -0.805
        },
        {
            "name": "Oracle",
            "Grimstroke": -0.411
        },
        {
            "name": "Outworld Destroyer",
            "Grimstroke": 0.18
        },
        {
            "name": "Pangolier",
            "Grimstroke": -2.459
        },
        {
            "name": "Phantom Assassin",
            "Grimstroke": 0.913
        },
        {
            "name": "Phantom Lancer",
            "Grimstroke": -0.914
        },
        {
            "name": "Phoenix",
            "Grimstroke": -0.341
        },
        {
            "name": "Primal Beast",
            "Grimstroke": -2.742
        },
        {
            "name": "Puck",
            "Grimstroke": -0.244
        },
        {
            "name": "Pudge",
            "Grimstroke": -0.852
        },
        {
            "name": "Pugna",
            "Grimstroke": -0.274
        },
        {
            "name": "Queen of Pain",
            "Grimstroke": -0.482
        },
        {
            "name": "Razor",
            "Grimstroke": 1.715
        },
        {
            "name": "Riki",
            "Grimstroke": 0.783
        },
        {
            "name": "Ringmaster",
            "Grimstroke": 0.96
        },
        {
            "name": "Rubick",
            "Grimstroke": -0.328
        },
        {
            "name": "Sand King",
            "Grimstroke": 1.025
        },
        {
            "name": "Shadow Demon",
            "Grimstroke": 1.841
        },
        {
            "name": "Shadow Fiend",
            "Grimstroke": 0.292
        },
        {
            "name": "Shadow Shaman",
            "Grimstroke": -0.148
        },
        {
            "name": "Silencer",
            "Grimstroke": 1.144
        },
        {
            "name": "Skywrath Mage",
            "Grimstroke": 0.091
        },
        {
            "name": "Slardar",
            "Grimstroke": 0.366
        },
        {
            "name": "Slark",
            "Grimstroke": -3.514
        },
        {
            "name": "Snapfire",
            "Grimstroke": 0.993
        },
        {
            "name": "Sniper",
            "Grimstroke": 1.588
        },
        {
            "name": "Spectre",
            "Grimstroke": 2.109
        },
        {
            "name": "Spirit Breaker",
            "Grimstroke": -0.018
        },
        {
            "name": "Storm Spirit",
            "Grimstroke": -1.968
        },
        {
            "name": "Sven",
            "Grimstroke": 0.825
        },
        {
            "name": "Techies",
            "Grimstroke": -0.585
        },
        {
            "name": "Templar Assassin",
            "Grimstroke": 0.556
        },
        {
            "name": "Terrorblade",
            "Grimstroke": 0.73
        },
        {
            "name": "Tidehunter",
            "Grimstroke": -1.949
        },
        {
            "name": "Timbersaw",
            "Grimstroke": -1.984
        },
        {
            "name": "Tinker",
            "Grimstroke": -0.901
        },
        {
            "name": "Tiny",
            "Grimstroke": -0.317
        },
        {
            "name": "Treant Protector",
            "Grimstroke": -0.551
        },
        {
            "name": "Troll Warlord",
            "Grimstroke": -0.989
        },
        {
            "name": "Tusk",
            "Grimstroke": -0.375
        },
        {
            "name": "Underlord",
            "Grimstroke": 0.542
        },
        {
            "name": "Undying",
            "Grimstroke": -0.334
        },
        {
            "name": "Ursa",
            "Grimstroke": -0.399
        },
        {
            "name": "Vengeful Spirit",
            "Grimstroke": 0.759
        },
        {
            "name": "Venomancer",
            "Grimstroke": -0.195
        },
        {
            "name": "Viper",
            "Grimstroke": -0.221
        },
        {
            "name": "Visage",
            "Grimstroke": 1.493
        },
        {
            "name": "Void Spirit",
            "Grimstroke": -0.681
        },
        {
            "name": "Warlock",
            "Grimstroke": 0.94
        },
        {
            "name": "Weaver",
            "Grimstroke": 1.213
        },
        {
            "name": "Windranger",
            "Grimstroke": 0.788
        },
        {
            "name": "Winter Wyvern",
            "Grimstroke": -0.447
        },
        {
            "name": "Witch Doctor",
            "Grimstroke": -0.009
        },
        {
            "name": "Wraith King",
            "Grimstroke": 0.875
        },
        {
            "name": "Zeus",
            "Grimstroke": 0.345
        }
    ],
    "Jakiro": [
        {
            "name": "Abaddon",
            "Jakiro": 0.086
        },
        {
            "name": "Alchemist",
            "Jakiro": 0.477
        },
        {
            "name": "Ancient Apparition",
            "Jakiro": 0.267
        },
        {
            "name": "Anti-Mage",
            "Jakiro": -1.549
        },
        {
            "name": "Arc Warden",
            "Jakiro": -0.392
        },
        {
            "name": "Axe",
            "Jakiro": -1.047
        },
        {
            "name": "Bane",
            "Jakiro": 0.359
        },
        {
            "name": "Batrider",
            "Jakiro": 0.808
        },
        {
            "name": "Beastmaster",
            "Jakiro": -1.303
        },
        {
            "name": "Bloodseeker",
            "Jakiro": -0.547
        },
        {
            "name": "Bounty Hunter",
            "Jakiro": 0.175
        },
        {
            "name": "Brewmaster",
            "Jakiro": -0.579
        },
        {
            "name": "Bristleback",
            "Jakiro": -0.317
        },
        {
            "name": "Broodmother",
            "Jakiro": -1.596
        },
        {
            "name": "Centaur Warrunner",
            "Jakiro": -0.056
        },
        {
            "name": "Chaos Knight",
            "Jakiro": -1.969
        },
        {
            "name": "Chen",
            "Jakiro": 1.242
        },
        {
            "name": "Clinkz",
            "Jakiro": 0.715
        },
        {
            "name": "Clockwerk",
            "Jakiro": -1.074
        },
        {
            "name": "Crystal Maiden",
            "Jakiro": -0.316
        },
        {
            "name": "Dark Seer",
            "Jakiro": -0.299
        },
        {
            "name": "Dark Willow",
            "Jakiro": 0.091
        },
        {
            "name": "Dawnbreaker",
            "Jakiro": -1.085
        },
        {
            "name": "Dazzle",
            "Jakiro": 0.984
        },
        {
            "name": "Death Prophet",
            "Jakiro": -0.039
        },
        {
            "name": "Disruptor",
            "Jakiro": 0.325
        },
        {
            "name": "Doom",
            "Jakiro": 0.115
        },
        {
            "name": "Dragon Knight",
            "Jakiro": -0.969
        },
        {
            "name": "Drow Ranger",
            "Jakiro": 0.661
        },
        {
            "name": "Earth Spirit",
            "Jakiro": 0.568
        },
        {
            "name": "Earthshaker",
            "Jakiro": -0.695
        },
        {
            "name": "Elder Titan",
            "Jakiro": 0.141
        },
        {
            "name": "Ember Spirit",
            "Jakiro": 0.827
        },
        {
            "name": "Enchantress",
            "Jakiro": 0.203
        },
        {
            "name": "Enigma",
            "Jakiro": -0.744
        },
        {
            "name": "Faceless Void",
            "Jakiro": 1.781
        },
        {
            "name": "Grimstroke",
            "Jakiro": -0.503
        },
        {
            "name": "Gyrocopter",
            "Jakiro": 0.692
        },
        {
            "name": "Hoodwink",
            "Jakiro": 0.985
        },
        {
            "name": "Huskar",
            "Jakiro": 0.779
        },
        {
            "name": "Invoker",
            "Jakiro": 0.67
        },
        {
            "name": "Io",
            "Jakiro": -0.319
        },
        {
            "name": "Juggernaut",
            "Jakiro": 1.278
        },
        {
            "name": "Keeper of the Light",
            "Jakiro": 1.434
        },
        {
            "name": "Kez",
            "Jakiro": -0.256
        },
        {
            "name": "Kunkka",
            "Jakiro": -0.196
        },
        {
            "name": "Legion Commander",
            "Jakiro": 0.807
        },
        {
            "name": "Leshrac",
            "Jakiro": -0.225
        },
        {
            "name": "Lich",
            "Jakiro": -0.766
        },
        {
            "name": "Lifestealer",
            "Jakiro": 0.351
        },
        {
            "name": "Lina",
            "Jakiro": -0.218
        },
        {
            "name": "Lion",
            "Jakiro": -0.213
        },
        {
            "name": "Lone Druid",
            "Jakiro": -2.583
        },
        {
            "name": "Luna",
            "Jakiro": -0.774
        },
        {
            "name": "Lycan",
            "Jakiro": -1.181
        },
        {
            "name": "Magnus",
            "Jakiro": 0.363
        },
        {
            "name": "Marci",
            "Jakiro": -0.706
        },
        {
            "name": "Mars",
            "Jakiro": 0.222
        },
        {
            "name": "Medusa",
            "Jakiro": -1.467
        },
        {
            "name": "Meepo",
            "Jakiro": -4.562
        },
        {
            "name": "Mirana",
            "Jakiro": 0.73
        },
        {
            "name": "Monkey King",
            "Jakiro": 0.245
        },
        {
            "name": "Morphling",
            "Jakiro": 1.321
        },
        {
            "name": "Muerta",
            "Jakiro": 0.641
        },
        {
            "name": "Naga Siren",
            "Jakiro": -2.032
        },
        {
            "name": "Nature's Prophet",
            "Jakiro": 1.377
        },
        {
            "name": "Necrophos",
            "Jakiro": -0.633
        },
        {
            "name": "Night Stalker",
            "Jakiro": 0.152
        },
        {
            "name": "Nyx Assassin",
            "Jakiro": -0.23
        },
        {
            "name": "Ogre Magi",
            "Jakiro": -0.109
        },
        {
            "name": "Omniknight",
            "Jakiro": -0.212
        },
        {
            "name": "Oracle",
            "Jakiro": 0.402
        },
        {
            "name": "Outworld Destroyer",
            "Jakiro": -0.161
        },
        {
            "name": "Pangolier",
            "Jakiro": 2.361
        },
        {
            "name": "Phantom Assassin",
            "Jakiro": -0.683
        },
        {
            "name": "Phantom Lancer",
            "Jakiro": -1.179
        },
        {
            "name": "Phoenix",
            "Jakiro": -1.091
        },
        {
            "name": "Primal Beast",
            "Jakiro": -0.429
        },
        {
            "name": "Puck",
            "Jakiro": 0.15
        },
        {
            "name": "Pudge",
            "Jakiro": 0.443
        },
        {
            "name": "Pugna",
            "Jakiro": 1.046
        },
        {
            "name": "Queen of Pain",
            "Jakiro": 0.71
        },
        {
            "name": "Razor",
            "Jakiro": 0.42
        },
        {
            "name": "Riki",
            "Jakiro": -1.095
        },
        {
            "name": "Ringmaster",
            "Jakiro": 0.89
        },
        {
            "name": "Rubick",
            "Jakiro": 4.562
        },
        {
            "name": "Sand King",
            "Jakiro": -0.698
        },
        {
            "name": "Shadow Demon",
            "Jakiro": 0.33
        },
        {
            "name": "Shadow Fiend",
            "Jakiro": -0.17
        },
        {
            "name": "Shadow Shaman",
            "Jakiro": -0.716
        },
        {
            "name": "Silencer",
            "Jakiro": 0.135
        },
        {
            "name": "Skywrath Mage",
            "Jakiro": 0.028
        },
        {
            "name": "Slardar",
            "Jakiro": -0.89
        },
        {
            "name": "Slark",
            "Jakiro": 0.98
        },
        {
            "name": "Snapfire",
            "Jakiro": 1.059
        },
        {
            "name": "Sniper",
            "Jakiro": 1.287
        },
        {
            "name": "Spectre",
            "Jakiro": -1.616
        },
        {
            "name": "Spirit Breaker",
            "Jakiro": -1.558
        },
        {
            "name": "Storm Spirit",
            "Jakiro": 0.402
        },
        {
            "name": "Sven",
            "Jakiro": 0.235
        },
        {
            "name": "Techies",
            "Jakiro": 0.06
        },
        {
            "name": "Templar Assassin",
            "Jakiro": 0.343
        },
        {
            "name": "Terrorblade",
            "Jakiro": -0.762
        },
        {
            "name": "Tidehunter",
            "Jakiro": 0.8
        },
        {
            "name": "Timbersaw",
            "Jakiro": -0.089
        },
        {
            "name": "Tinker",
            "Jakiro": 0.517
        },
        {
            "name": "Tiny",
            "Jakiro": 0.638
        },
        {
            "name": "Treant Protector",
            "Jakiro": -0.066
        },
        {
            "name": "Troll Warlord",
            "Jakiro": -0.901
        },
        {
            "name": "Tusk",
            "Jakiro": 0.122
        },
        {
            "name": "Underlord",
            "Jakiro": 0.188
        },
        {
            "name": "Undying",
            "Jakiro": -0.405
        },
        {
            "name": "Ursa",
            "Jakiro": 0.579
        },
        {
            "name": "Vengeful Spirit",
            "Jakiro": -0.593
        },
        {
            "name": "Venomancer",
            "Jakiro": 0.817
        },
        {
            "name": "Viper",
            "Jakiro": 0.224
        },
        {
            "name": "Visage",
            "Jakiro": -1.315
        },
        {
            "name": "Void Spirit",
            "Jakiro": -0.207
        },
        {
            "name": "Warlock",
            "Jakiro": -1.442
        },
        {
            "name": "Weaver",
            "Jakiro": 1.043
        },
        {
            "name": "Windranger",
            "Jakiro": 1.604
        },
        {
            "name": "Winter Wyvern",
            "Jakiro": 0.125
        },
        {
            "name": "Witch Doctor",
            "Jakiro": -0.449
        },
        {
            "name": "Wraith King",
            "Jakiro": -2.314
        },
        {
            "name": "Zeus",
            "Jakiro": 0.41
        }
    ],
    "Keeper of the Light": [
        {
            "name": "Abaddon",
            "Keeper of the Light": -0.678
        },
        {
            "name": "Alchemist",
            "Keeper of the Light": -1.516
        },
        {
            "name": "Ancient Apparition",
            "Keeper of the Light": -0.368
        },
        {
            "name": "Anti-Mage",
            "Keeper of the Light": 1.853
        },
        {
            "name": "Arc Warden",
            "Keeper of the Light": 1.31
        },
        {
            "name": "Axe",
            "Keeper of the Light": -0.374
        },
        {
            "name": "Bane",
            "Keeper of the Light": 0.055
        },
        {
            "name": "Batrider",
            "Keeper of the Light": -0.294
        },
        {
            "name": "Beastmaster",
            "Keeper of the Light": -0.998
        },
        {
            "name": "Bloodseeker",
            "Keeper of the Light": -1.196
        },
        {
            "name": "Bounty Hunter",
            "Keeper of the Light": -0.304
        },
        {
            "name": "Brewmaster",
            "Keeper of the Light": 1.549
        },
        {
            "name": "Bristleback",
            "Keeper of the Light": -0.341
        },
        {
            "name": "Broodmother",
            "Keeper of the Light": 1.143
        },
        {
            "name": "Centaur Warrunner",
            "Keeper of the Light": -0.202
        },
        {
            "name": "Chaos Knight",
            "Keeper of the Light": 0.538
        },
        {
            "name": "Chen",
            "Keeper of the Light": -0.601
        },
        {
            "name": "Clinkz",
            "Keeper of the Light": 0.801
        },
        {
            "name": "Clockwerk",
            "Keeper of the Light": -1.124
        },
        {
            "name": "Crystal Maiden",
            "Keeper of the Light": 1.538
        },
        {
            "name": "Dark Seer",
            "Keeper of the Light": 0.399
        },
        {
            "name": "Dark Willow",
            "Keeper of the Light": -1.164
        },
        {
            "name": "Dawnbreaker",
            "Keeper of the Light": -1.682
        },
        {
            "name": "Dazzle",
            "Keeper of the Light": 0.27
        },
        {
            "name": "Death Prophet",
            "Keeper of the Light": -1.482
        },
        {
            "name": "Disruptor",
            "Keeper of the Light": 0.02
        },
        {
            "name": "Doom",
            "Keeper of the Light": -0.956
        },
        {
            "name": "Dragon Knight",
            "Keeper of the Light": -0.687
        },
        {
            "name": "Drow Ranger",
            "Keeper of the Light": -1.129
        },
        {
            "name": "Earth Spirit",
            "Keeper of the Light": -1.59
        },
        {
            "name": "Earthshaker",
            "Keeper of the Light": 0.201
        },
        {
            "name": "Elder Titan",
            "Keeper of the Light": -0.051
        },
        {
            "name": "Ember Spirit",
            "Keeper of the Light": 0.226
        },
        {
            "name": "Enchantress",
            "Keeper of the Light": 0.332
        },
        {
            "name": "Enigma",
            "Keeper of the Light": 0.195
        },
        {
            "name": "Faceless Void",
            "Keeper of the Light": 1.502
        },
        {
            "name": "Grimstroke",
            "Keeper of the Light": -0.761
        },
        {
            "name": "Gyrocopter",
            "Keeper of the Light": -0.463
        },
        {
            "name": "Hoodwink",
            "Keeper of the Light": -0.682
        },
        {
            "name": "Huskar",
            "Keeper of the Light": 2.541
        },
        {
            "name": "Invoker",
            "Keeper of the Light": -0.398
        },
        {
            "name": "Io",
            "Keeper of the Light": 0.008
        },
        {
            "name": "Jakiro",
            "Keeper of the Light": -0.887
        },
        {
            "name": "Juggernaut",
            "Keeper of the Light": 1.041
        },
        {
            "name": "Kez",
            "Keeper of the Light": 0.777
        },
        {
            "name": "Kunkka",
            "Keeper of the Light": -0.455
        },
        {
            "name": "Legion Commander",
            "Keeper of the Light": 1.028
        },
        {
            "name": "Leshrac",
            "Keeper of the Light": -2.253
        },
        {
            "name": "Lich",
            "Keeper of the Light": -0.291
        },
        {
            "name": "Lifestealer",
            "Keeper of the Light": 0.029
        },
        {
            "name": "Lina",
            "Keeper of the Light": -0.754
        },
        {
            "name": "Lion",
            "Keeper of the Light": 0.366
        },
        {
            "name": "Lone Druid",
            "Keeper of the Light": 1.066
        },
        {
            "name": "Luna",
            "Keeper of the Light": 0.407
        },
        {
            "name": "Lycan",
            "Keeper of the Light": 1.107
        },
        {
            "name": "Magnus",
            "Keeper of the Light": 0.836
        },
        {
            "name": "Marci",
            "Keeper of the Light": 0.906
        },
        {
            "name": "Mars",
            "Keeper of the Light": -0.55
        },
        {
            "name": "Medusa",
            "Keeper of the Light": 0.602
        },
        {
            "name": "Meepo",
            "Keeper of the Light": 0.435
        },
        {
            "name": "Mirana",
            "Keeper of the Light": -0.088
        },
        {
            "name": "Monkey King",
            "Keeper of the Light": -0.781
        },
        {
            "name": "Morphling",
            "Keeper of the Light": -1.74
        },
        {
            "name": "Muerta",
            "Keeper of the Light": -2.005
        },
        {
            "name": "Naga Siren",
            "Keeper of the Light": 2.91
        },
        {
            "name": "Nature's Prophet",
            "Keeper of the Light": 0.437
        },
        {
            "name": "Necrophos",
            "Keeper of the Light": -1.604
        },
        {
            "name": "Night Stalker",
            "Keeper of the Light": -0.779
        },
        {
            "name": "Nyx Assassin",
            "Keeper of the Light": 1.706
        },
        {
            "name": "Ogre Magi",
            "Keeper of the Light": 0.875
        },
        {
            "name": "Omniknight",
            "Keeper of the Light": 0.162
        },
        {
            "name": "Oracle",
            "Keeper of the Light": 1.151
        },
        {
            "name": "Outworld Destroyer",
            "Keeper of the Light": -1.892
        },
        {
            "name": "Pangolier",
            "Keeper of the Light": 0.347
        },
        {
            "name": "Phantom Assassin",
            "Keeper of the Light": 1.283
        },
        {
            "name": "Phantom Lancer",
            "Keeper of the Light": 1.028
        },
        {
            "name": "Phoenix",
            "Keeper of the Light": -1.179
        },
        {
            "name": "Primal Beast",
            "Keeper of the Light": 0.287
        },
        {
            "name": "Puck",
            "Keeper of the Light": -1.258
        },
        {
            "name": "Pudge",
            "Keeper of the Light": 0.443
        },
        {
            "name": "Pugna",
            "Keeper of the Light": 0.329
        },
        {
            "name": "Queen of Pain",
            "Keeper of the Light": 1.086
        },
        {
            "name": "Razor",
            "Keeper of the Light": -0.5
        },
        {
            "name": "Riki",
            "Keeper of the Light": 1.093
        },
        {
            "name": "Ringmaster",
            "Keeper of the Light": -1.829
        },
        {
            "name": "Rubick",
            "Keeper of the Light": -1.74
        },
        {
            "name": "Sand King",
            "Keeper of the Light": -0.629
        },
        {
            "name": "Shadow Demon",
            "Keeper of the Light": -0.915
        },
        {
            "name": "Shadow Fiend",
            "Keeper of the Light": -0.307
        },
        {
            "name": "Shadow Shaman",
            "Keeper of the Light": 0.634
        },
        {
            "name": "Silencer",
            "Keeper of the Light": -0.422
        },
        {
            "name": "Skywrath Mage",
            "Keeper of the Light": 1.284
        },
        {
            "name": "Slardar",
            "Keeper of the Light": 1.928
        },
        {
            "name": "Slark",
            "Keeper of the Light": 1.531
        },
        {
            "name": "Snapfire",
            "Keeper of the Light": -0.194
        },
        {
            "name": "Sniper",
            "Keeper of the Light": 0.757
        },
        {
            "name": "Spectre",
            "Keeper of the Light": -0.861
        },
        {
            "name": "Spirit Breaker",
            "Keeper of the Light": 1.121
        },
        {
            "name": "Storm Spirit",
            "Keeper of the Light": 2.0
        },
        {
            "name": "Sven",
            "Keeper of the Light": -0.071
        },
        {
            "name": "Techies",
            "Keeper of the Light": -0.695
        },
        {
            "name": "Templar Assassin",
            "Keeper of the Light": 1.66
        },
        {
            "name": "Terrorblade",
            "Keeper of the Light": -1.888
        },
        {
            "name": "Tidehunter",
            "Keeper of the Light": 0.157
        },
        {
            "name": "Timbersaw",
            "Keeper of the Light": 0.026
        },
        {
            "name": "Tinker",
            "Keeper of the Light": 0.674
        },
        {
            "name": "Tiny",
            "Keeper of the Light": -1.366
        },
        {
            "name": "Treant Protector",
            "Keeper of the Light": 0.366
        },
        {
            "name": "Troll Warlord",
            "Keeper of the Light": -1.106
        },
        {
            "name": "Tusk",
            "Keeper of the Light": 1.829
        },
        {
            "name": "Underlord",
            "Keeper of the Light": -0.414
        },
        {
            "name": "Undying",
            "Keeper of the Light": -0.565
        },
        {
            "name": "Ursa",
            "Keeper of the Light": 0.48
        },
        {
            "name": "Vengeful Spirit",
            "Keeper of the Light": 0.295
        },
        {
            "name": "Venomancer",
            "Keeper of the Light": -0.609
        },
        {
            "name": "Viper",
            "Keeper of the Light": -0.384
        },
        {
            "name": "Visage",
            "Keeper of the Light": 0.166
        },
        {
            "name": "Void Spirit",
            "Keeper of the Light": -0.315
        },
        {
            "name": "Warlock",
            "Keeper of the Light": -0.777
        },
        {
            "name": "Weaver",
            "Keeper of the Light": 0.979
        },
        {
            "name": "Windranger",
            "Keeper of the Light": 1.338
        },
        {
            "name": "Winter Wyvern",
            "Keeper of the Light": -1.015
        },
        {
            "name": "Witch Doctor",
            "Keeper of the Light": 0.146
        },
        {
            "name": "Wraith King",
            "Keeper of the Light": -2.172
        },
        {
            "name": "Zeus",
            "Keeper of the Light": -0.584
        }
    ],
    "Leshrac": [
        {
            "name": "Abaddon",
            "Leshrac": 0.636
        },
        {
            "name": "Alchemist",
            "Leshrac": -0.498
        },
        {
            "name": "Ancient Apparition",
            "Leshrac": 2.23
        },
        {
            "name": "Anti-Mage",
            "Leshrac": -0.107
        },
        {
            "name": "Arc Warden",
            "Leshrac": -0.305
        },
        {
            "name": "Axe",
            "Leshrac": -2.535
        },
        {
            "name": "Bane",
            "Leshrac": 0.577
        },
        {
            "name": "Batrider",
            "Leshrac": -0.788
        },
        {
            "name": "Beastmaster",
            "Leshrac": -0.037
        },
        {
            "name": "Bloodseeker",
            "Leshrac": -0.41
        },
        {
            "name": "Bounty Hunter",
            "Leshrac": -0.243
        },
        {
            "name": "Brewmaster",
            "Leshrac": -4.47
        },
        {
            "name": "Bristleback",
            "Leshrac": 0.948
        },
        {
            "name": "Broodmother",
            "Leshrac": -0.626
        },
        {
            "name": "Centaur Warrunner",
            "Leshrac": -0.116
        },
        {
            "name": "Chaos Knight",
            "Leshrac": -2.978
        },
        {
            "name": "Chen",
            "Leshrac": -0.666
        },
        {
            "name": "Clinkz",
            "Leshrac": 1.679
        },
        {
            "name": "Clockwerk",
            "Leshrac": 0.915
        },
        {
            "name": "Crystal Maiden",
            "Leshrac": -0.893
        },
        {
            "name": "Dark Seer",
            "Leshrac": -1.83
        },
        {
            "name": "Dark Willow",
            "Leshrac": -1.185
        },
        {
            "name": "Dawnbreaker",
            "Leshrac": 0.145
        },
        {
            "name": "Dazzle",
            "Leshrac": 1.905
        },
        {
            "name": "Death Prophet",
            "Leshrac": 0.318
        },
        {
            "name": "Disruptor",
            "Leshrac": 1.149
        },
        {
            "name": "Doom",
            "Leshrac": -0.967
        },
        {
            "name": "Dragon Knight",
            "Leshrac": 0.4
        },
        {
            "name": "Drow Ranger",
            "Leshrac": 2.037
        },
        {
            "name": "Earth Spirit",
            "Leshrac": -1.259
        },
        {
            "name": "Earthshaker",
            "Leshrac": -0.188
        },
        {
            "name": "Elder Titan",
            "Leshrac": 0.324
        },
        {
            "name": "Ember Spirit",
            "Leshrac": 1.48
        },
        {
            "name": "Enchantress",
            "Leshrac": -1.131
        },
        {
            "name": "Enigma",
            "Leshrac": 1.078
        },
        {
            "name": "Faceless Void",
            "Leshrac": -0.088
        },
        {
            "name": "Grimstroke",
            "Leshrac": -0.244
        },
        {
            "name": "Gyrocopter",
            "Leshrac": 0.717
        },
        {
            "name": "Hoodwink",
            "Leshrac": 0.281
        },
        {
            "name": "Huskar",
            "Leshrac": -0.881
        },
        {
            "name": "Invoker",
            "Leshrac": 1.447
        },
        {
            "name": "Io",
            "Leshrac": -0.313
        },
        {
            "name": "Jakiro",
            "Leshrac": 0.549
        },
        {
            "name": "Juggernaut",
            "Leshrac": 0.282
        },
        {
            "name": "Keeper of the Light",
            "Leshrac": 2.145
        },
        {
            "name": "Kez",
            "Leshrac": -1.157
        },
        {
            "name": "Kunkka",
            "Leshrac": 1.184
        },
        {
            "name": "Legion Commander",
            "Leshrac": 0.307
        },
        {
            "name": "Lich",
            "Leshrac": -0.924
        },
        {
            "name": "Lifestealer",
            "Leshrac": 2.036
        },
        {
            "name": "Lina",
            "Leshrac": -0.594
        },
        {
            "name": "Lion",
            "Leshrac": 0.221
        },
        {
            "name": "Lone Druid",
            "Leshrac": 0.021
        },
        {
            "name": "Luna",
            "Leshrac": -0.245
        },
        {
            "name": "Lycan",
            "Leshrac": -1.03
        },
        {
            "name": "Magnus",
            "Leshrac": -0.137
        },
        {
            "name": "Marci",
            "Leshrac": -0.485
        },
        {
            "name": "Mars",
            "Leshrac": 0.116
        },
        {
            "name": "Medusa",
            "Leshrac": 3.27
        },
        {
            "name": "Meepo",
            "Leshrac": -4.971
        },
        {
            "name": "Mirana",
            "Leshrac": 0.252
        },
        {
            "name": "Monkey King",
            "Leshrac": -0.324
        },
        {
            "name": "Morphling",
            "Leshrac": -0.421
        },
        {
            "name": "Muerta",
            "Leshrac": -0.751
        },
        {
            "name": "Naga Siren",
            "Leshrac": -6.416
        },
        {
            "name": "Nature's Prophet",
            "Leshrac": 1.163
        },
        {
            "name": "Necrophos",
            "Leshrac": -4.06
        },
        {
            "name": "Night Stalker",
            "Leshrac": 0.368
        },
        {
            "name": "Nyx Assassin",
            "Leshrac": 0.895
        },
        {
            "name": "Ogre Magi",
            "Leshrac": 0.724
        },
        {
            "name": "Omniknight",
            "Leshrac": -2.434
        },
        {
            "name": "Oracle",
            "Leshrac": -0.134
        },
        {
            "name": "Outworld Destroyer",
            "Leshrac": 2.313
        },
        {
            "name": "Pangolier",
            "Leshrac": -0.395
        },
        {
            "name": "Phantom Assassin",
            "Leshrac": -2.037
        },
        {
            "name": "Phantom Lancer",
            "Leshrac": -5.802
        },
        {
            "name": "Phoenix",
            "Leshrac": 0.63
        },
        {
            "name": "Primal Beast",
            "Leshrac": -1.612
        },
        {
            "name": "Puck",
            "Leshrac": 0.093
        },
        {
            "name": "Pudge",
            "Leshrac": -0.075
        },
        {
            "name": "Pugna",
            "Leshrac": 0.144
        },
        {
            "name": "Queen of Pain",
            "Leshrac": 2.277
        },
        {
            "name": "Razor",
            "Leshrac": 0.224
        },
        {
            "name": "Riki",
            "Leshrac": -1.753
        },
        {
            "name": "Ringmaster",
            "Leshrac": -0.191
        },
        {
            "name": "Rubick",
            "Leshrac": -0.535
        },
        {
            "name": "Sand King",
            "Leshrac": -2.546
        },
        {
            "name": "Shadow Demon",
            "Leshrac": -0.644
        },
        {
            "name": "Shadow Fiend",
            "Leshrac": 0.943
        },
        {
            "name": "Shadow Shaman",
            "Leshrac": -0.45
        },
        {
            "name": "Silencer",
            "Leshrac": 0.78
        },
        {
            "name": "Skywrath Mage",
            "Leshrac": 0.641
        },
        {
            "name": "Slardar",
            "Leshrac": 0.124
        },
        {
            "name": "Slark",
            "Leshrac": -4.159
        },
        {
            "name": "Snapfire",
            "Leshrac": 1.286
        },
        {
            "name": "Sniper",
            "Leshrac": 1.871
        },
        {
            "name": "Spectre",
            "Leshrac": -1.315
        },
        {
            "name": "Spirit Breaker",
            "Leshrac": -0.367
        },
        {
            "name": "Storm Spirit",
            "Leshrac": -1.173
        },
        {
            "name": "Sven",
            "Leshrac": 1.185
        },
        {
            "name": "Techies",
            "Leshrac": -1.089
        },
        {
            "name": "Templar Assassin",
            "Leshrac": -0.421
        },
        {
            "name": "Terrorblade",
            "Leshrac": -1.481
        },
        {
            "name": "Tidehunter",
            "Leshrac": -1.976
        },
        {
            "name": "Timbersaw",
            "Leshrac": -1.484
        },
        {
            "name": "Tinker",
            "Leshrac": -2.176
        },
        {
            "name": "Tiny",
            "Leshrac": -0.47
        },
        {
            "name": "Treant Protector",
            "Leshrac": -1.229
        },
        {
            "name": "Troll Warlord",
            "Leshrac": -0.264
        },
        {
            "name": "Tusk",
            "Leshrac": -0.715
        },
        {
            "name": "Underlord",
            "Leshrac": -0.163
        },
        {
            "name": "Undying",
            "Leshrac": 3.811
        },
        {
            "name": "Ursa",
            "Leshrac": 2.23
        },
        {
            "name": "Vengeful Spirit",
            "Leshrac": -0.396
        },
        {
            "name": "Venomancer",
            "Leshrac": 1.369
        },
        {
            "name": "Viper",
            "Leshrac": 0.07
        },
        {
            "name": "Visage",
            "Leshrac": 0.101
        },
        {
            "name": "Void Spirit",
            "Leshrac": 0.373
        },
        {
            "name": "Warlock",
            "Leshrac": -1.522
        },
        {
            "name": "Weaver",
            "Leshrac": 0.928
        },
        {
            "name": "Windranger",
            "Leshrac": -0.267
        },
        {
            "name": "Winter Wyvern",
            "Leshrac": -0.888
        },
        {
            "name": "Witch Doctor",
            "Leshrac": 1.261
        },
        {
            "name": "Wraith King",
            "Leshrac": -0.447
        },
        {
            "name": "Zeus",
            "Leshrac": 0.588
        }
    ],
    "Lich": [
        {
            "name": "Abaddon",
            "Lich": -0.415
        },
        {
            "name": "Alchemist",
            "Lich": -0.023
        },
        {
            "name": "Ancient Apparition",
            "Lich": -0.002
        },
        {
            "name": "Anti-Mage",
            "Lich": 1.124
        },
        {
            "name": "Arc Warden",
            "Lich": 0.684
        },
        {
            "name": "Axe",
            "Lich": 0.128
        },
        {
            "name": "Bane",
            "Lich": 1.277
        },
        {
            "name": "Batrider",
            "Lich": 0.412
        },
        {
            "name": "Beastmaster",
            "Lich": -0.137
        },
        {
            "name": "Bloodseeker",
            "Lich": 0.379
        },
        {
            "name": "Bounty Hunter",
            "Lich": 0.458
        },
        {
            "name": "Brewmaster",
            "Lich": 1.189
        },
        {
            "name": "Bristleback",
            "Lich": 1.56
        },
        {
            "name": "Broodmother",
            "Lich": 0.014
        },
        {
            "name": "Centaur Warrunner",
            "Lich": 1.056
        },
        {
            "name": "Chaos Knight",
            "Lich": -1.859
        },
        {
            "name": "Chen",
            "Lich": 0.95
        },
        {
            "name": "Clinkz",
            "Lich": 0.947
        },
        {
            "name": "Clockwerk",
            "Lich": -0.671
        },
        {
            "name": "Crystal Maiden",
            "Lich": 0.23
        },
        {
            "name": "Dark Seer",
            "Lich": 0.621
        },
        {
            "name": "Dark Willow",
            "Lich": 0.365
        },
        {
            "name": "Dawnbreaker",
            "Lich": -0.733
        },
        {
            "name": "Dazzle",
            "Lich": -0.073
        },
        {
            "name": "Death Prophet",
            "Lich": 1.489
        },
        {
            "name": "Disruptor",
            "Lich": -0.157
        },
        {
            "name": "Doom",
            "Lich": 0.333
        },
        {
            "name": "Dragon Knight",
            "Lich": -0.659
        },
        {
            "name": "Drow Ranger",
            "Lich": 0.992
        },
        {
            "name": "Earth Spirit",
            "Lich": 1.417
        },
        {
            "name": "Earthshaker",
            "Lich": -0.491
        },
        {
            "name": "Elder Titan",
            "Lich": -0.443
        },
        {
            "name": "Ember Spirit",
            "Lich": 0.948
        },
        {
            "name": "Enchantress",
            "Lich": 0.143
        },
        {
            "name": "Enigma",
            "Lich": 0.996
        },
        {
            "name": "Faceless Void",
            "Lich": 0.607
        },
        {
            "name": "Grimstroke",
            "Lich": 0.077
        },
        {
            "name": "Gyrocopter",
            "Lich": -0.172
        },
        {
            "name": "Hoodwink",
            "Lich": 0.633
        },
        {
            "name": "Huskar",
            "Lich": -0.198
        },
        {
            "name": "Invoker",
            "Lich": 1.017
        },
        {
            "name": "Io",
            "Lich": -0.736
        },
        {
            "name": "Jakiro",
            "Lich": 0.691
        },
        {
            "name": "Juggernaut",
            "Lich": 0.007
        },
        {
            "name": "Keeper of the Light",
            "Lich": 0.866
        },
        {
            "name": "Kez",
            "Lich": 1.396
        },
        {
            "name": "Kunkka",
            "Lich": -0.145
        },
        {
            "name": "Legion Commander",
            "Lich": -0.714
        },
        {
            "name": "Leshrac",
            "Lich": 1.058
        },
        {
            "name": "Lifestealer",
            "Lich": -0.538
        },
        {
            "name": "Lina",
            "Lich": 1.001
        },
        {
            "name": "Lion",
            "Lich": -0.182
        },
        {
            "name": "Lone Druid",
            "Lich": -1.147
        },
        {
            "name": "Luna",
            "Lich": -0.382
        },
        {
            "name": "Lycan",
            "Lich": 1.516
        },
        {
            "name": "Magnus",
            "Lich": -0.145
        },
        {
            "name": "Marci",
            "Lich": 0.266
        },
        {
            "name": "Mars",
            "Lich": -0.208
        },
        {
            "name": "Medusa",
            "Lich": -0.756
        },
        {
            "name": "Meepo",
            "Lich": -1.497
        },
        {
            "name": "Mirana",
            "Lich": 1.117
        },
        {
            "name": "Monkey King",
            "Lich": -0.737
        },
        {
            "name": "Morphling",
            "Lich": 0.831
        },
        {
            "name": "Muerta",
            "Lich": 0.463
        },
        {
            "name": "Naga Siren",
            "Lich": -0.859
        },
        {
            "name": "Nature's Prophet",
            "Lich": 0.847
        },
        {
            "name": "Necrophos",
            "Lich": -1.528
        },
        {
            "name": "Night Stalker",
            "Lich": 0.117
        },
        {
            "name": "Nyx Assassin",
            "Lich": -0.538
        },
        {
            "name": "Ogre Magi",
            "Lich": -0.204
        },
        {
            "name": "Omniknight",
            "Lich": 0.469
        },
        {
            "name": "Oracle",
            "Lich": 1.194
        },
        {
            "name": "Outworld Destroyer",
            "Lich": 0.709
        },
        {
            "name": "Pangolier",
            "Lich": 1.325
        },
        {
            "name": "Phantom Assassin",
            "Lich": -0.166
        },
        {
            "name": "Phantom Lancer",
            "Lich": 1.296
        },
        {
            "name": "Phoenix",
            "Lich": 0.398
        },
        {
            "name": "Primal Beast",
            "Lich": -0.154
        },
        {
            "name": "Puck",
            "Lich": 0.907
        },
        {
            "name": "Pudge",
            "Lich": 0.481
        },
        {
            "name": "Pugna",
            "Lich": 1.665
        },
        {
            "name": "Queen of Pain",
            "Lich": 1.224
        },
        {
            "name": "Razor",
            "Lich": 0.808
        },
        {
            "name": "Riki",
            "Lich": 0.263
        },
        {
            "name": "Ringmaster",
            "Lich": 1.966
        },
        {
            "name": "Rubick",
            "Lich": -0.005
        },
        {
            "name": "Sand King",
            "Lich": 0.715
        },
        {
            "name": "Shadow Demon",
            "Lich": 1.349
        },
        {
            "name": "Shadow Fiend",
            "Lich": -0.723
        },
        {
            "name": "Shadow Shaman",
            "Lich": 0.661
        },
        {
            "name": "Silencer",
            "Lich": 0.94
        },
        {
            "name": "Skywrath Mage",
            "Lich": 0.083
        },
        {
            "name": "Slardar",
            "Lich": 0.183
        },
        {
            "name": "Slark",
            "Lich": 0.732
        },
        {
            "name": "Snapfire",
            "Lich": 0.38
        },
        {
            "name": "Sniper",
            "Lich": 0.195
        },
        {
            "name": "Spectre",
            "Lich": 0.198
        },
        {
            "name": "Spirit Breaker",
            "Lich": -1.081
        },
        {
            "name": "Storm Spirit",
            "Lich": 0.475
        },
        {
            "name": "Sven",
            "Lich": 0.67
        },
        {
            "name": "Techies",
            "Lich": -0.003
        },
        {
            "name": "Templar Assassin",
            "Lich": 0.775
        },
        {
            "name": "Terrorblade",
            "Lich": -1.275
        },
        {
            "name": "Tidehunter",
            "Lich": -0.882
        },
        {
            "name": "Timbersaw",
            "Lich": 1.605
        },
        {
            "name": "Tinker",
            "Lich": 0.621
        },
        {
            "name": "Tiny",
            "Lich": -0.292
        },
        {
            "name": "Treant Protector",
            "Lich": -0.12
        },
        {
            "name": "Troll Warlord",
            "Lich": 0.063
        },
        {
            "name": "Tusk",
            "Lich": -0.443
        },
        {
            "name": "Underlord",
            "Lich": 0.323
        },
        {
            "name": "Undying",
            "Lich": 1.402
        },
        {
            "name": "Ursa",
            "Lich": -0.202
        },
        {
            "name": "Vengeful Spirit",
            "Lich": -1.219
        },
        {
            "name": "Venomancer",
            "Lich": 0.493
        },
        {
            "name": "Viper",
            "Lich": 0.328
        },
        {
            "name": "Visage",
            "Lich": 0.6
        },
        {
            "name": "Void Spirit",
            "Lich": 0.9
        },
        {
            "name": "Warlock",
            "Lich": 0.731
        },
        {
            "name": "Weaver",
            "Lich": 1.287
        },
        {
            "name": "Windranger",
            "Lich": 0.731
        },
        {
            "name": "Winter Wyvern",
            "Lich": 1.184
        },
        {
            "name": "Witch Doctor",
            "Lich": 0.24
        },
        {
            "name": "Wraith King",
            "Lich": -0.736
        },
        {
            "name": "Zeus",
            "Lich": 0.797
        }
    ],
    "Lina": [
        {
            "name": "Abaddon",
            "Lina": -1.005
        },
        {
            "name": "Alchemist",
            "Lina": -1.052
        },
        {
            "name": "Ancient Apparition",
            "Lina": -0.776
        },
        {
            "name": "Anti-Mage",
            "Lina": -0.295
        },
        {
            "name": "Arc Warden",
            "Lina": 0.956
        },
        {
            "name": "Axe",
            "Lina": 1.4
        },
        {
            "name": "Bane",
            "Lina": -0.25
        },
        {
            "name": "Batrider",
            "Lina": 1.138
        },
        {
            "name": "Beastmaster",
            "Lina": -1.6
        },
        {
            "name": "Bloodseeker",
            "Lina": -0.949
        },
        {
            "name": "Bounty Hunter",
            "Lina": 0.499
        },
        {
            "name": "Brewmaster",
            "Lina": -2.153
        },
        {
            "name": "Bristleback",
            "Lina": 1.777
        },
        {
            "name": "Broodmother",
            "Lina": -2.187
        },
        {
            "name": "Centaur Warrunner",
            "Lina": 0.455
        },
        {
            "name": "Chaos Knight",
            "Lina": -1.044
        },
        {
            "name": "Chen",
            "Lina": 0.674
        },
        {
            "name": "Clinkz",
            "Lina": 0.963
        },
        {
            "name": "Clockwerk",
            "Lina": -0.668
        },
        {
            "name": "Crystal Maiden",
            "Lina": -0.011
        },
        {
            "name": "Dark Seer",
            "Lina": -0.605
        },
        {
            "name": "Dark Willow",
            "Lina": -0.301
        },
        {
            "name": "Dawnbreaker",
            "Lina": -1.653
        },
        {
            "name": "Dazzle",
            "Lina": -0.738
        },
        {
            "name": "Death Prophet",
            "Lina": -0.733
        },
        {
            "name": "Disruptor",
            "Lina": -0.954
        },
        {
            "name": "Doom",
            "Lina": -1.092
        },
        {
            "name": "Dragon Knight",
            "Lina": 0.313
        },
        {
            "name": "Drow Ranger",
            "Lina": 0.732
        },
        {
            "name": "Earth Spirit",
            "Lina": -0.799
        },
        {
            "name": "Earthshaker",
            "Lina": -1.011
        },
        {
            "name": "Elder Titan",
            "Lina": -1.932
        },
        {
            "name": "Ember Spirit",
            "Lina": 1.294
        },
        {
            "name": "Enchantress",
            "Lina": 0.057
        },
        {
            "name": "Enigma",
            "Lina": -0.143
        },
        {
            "name": "Faceless Void",
            "Lina": 0.606
        },
        {
            "name": "Grimstroke",
            "Lina": -1.743
        },
        {
            "name": "Gyrocopter",
            "Lina": -0.315
        },
        {
            "name": "Hoodwink",
            "Lina": 0.262
        },
        {
            "name": "Huskar",
            "Lina": -1.496
        },
        {
            "name": "Invoker",
            "Lina": 0.076
        },
        {
            "name": "Io",
            "Lina": -0.773
        },
        {
            "name": "Jakiro",
            "Lina": 0.584
        },
        {
            "name": "Juggernaut",
            "Lina": -1.297
        },
        {
            "name": "Keeper of the Light",
            "Lina": 0.686
        },
        {
            "name": "Kez",
            "Lina": 0.307
        },
        {
            "name": "Kunkka",
            "Lina": 0.717
        },
        {
            "name": "Legion Commander",
            "Lina": -0.192
        },
        {
            "name": "Leshrac",
            "Lina": 0.427
        },
        {
            "name": "Lich",
            "Lina": -0.921
        },
        {
            "name": "Lifestealer",
            "Lina": -0.384
        },
        {
            "name": "Lion",
            "Lina": -0.713
        },
        {
            "name": "Lone Druid",
            "Lina": -1.269
        },
        {
            "name": "Luna",
            "Lina": 0.1
        },
        {
            "name": "Lycan",
            "Lina": -0.658
        },
        {
            "name": "Magnus",
            "Lina": 0.141
        },
        {
            "name": "Marci",
            "Lina": 1.687
        },
        {
            "name": "Mars",
            "Lina": 0.941
        },
        {
            "name": "Medusa",
            "Lina": 0.248
        },
        {
            "name": "Meepo",
            "Lina": -4.374
        },
        {
            "name": "Mirana",
            "Lina": 0.439
        },
        {
            "name": "Monkey King",
            "Lina": -0.931
        },
        {
            "name": "Morphling",
            "Lina": -2.079
        },
        {
            "name": "Muerta",
            "Lina": -0.235
        },
        {
            "name": "Naga Siren",
            "Lina": -3.149
        },
        {
            "name": "Nature's Prophet",
            "Lina": -0.329
        },
        {
            "name": "Necrophos",
            "Lina": -2.293
        },
        {
            "name": "Night Stalker",
            "Lina": 0.43
        },
        {
            "name": "Nyx Assassin",
            "Lina": -0.538
        },
        {
            "name": "Ogre Magi",
            "Lina": 0.121
        },
        {
            "name": "Omniknight",
            "Lina": -0.532
        },
        {
            "name": "Oracle",
            "Lina": -0.422
        },
        {
            "name": "Outworld Destroyer",
            "Lina": 0.088
        },
        {
            "name": "Pangolier",
            "Lina": 0.147
        },
        {
            "name": "Phantom Assassin",
            "Lina": 0.976
        },
        {
            "name": "Phantom Lancer",
            "Lina": -1.569
        },
        {
            "name": "Phoenix",
            "Lina": -3.8
        },
        {
            "name": "Primal Beast",
            "Lina": -0.102
        },
        {
            "name": "Puck",
            "Lina": -0.397
        },
        {
            "name": "Pudge",
            "Lina": 0.575
        },
        {
            "name": "Pugna",
            "Lina": -0.821
        },
        {
            "name": "Queen of Pain",
            "Lina": -0.553
        },
        {
            "name": "Razor",
            "Lina": 1.007
        },
        {
            "name": "Riki",
            "Lina": -0.301
        },
        {
            "name": "Ringmaster",
            "Lina": 0.492
        },
        {
            "name": "Rubick",
            "Lina": 0.222
        },
        {
            "name": "Sand King",
            "Lina": 0.191
        },
        {
            "name": "Shadow Demon",
            "Lina": -0.73
        },
        {
            "name": "Shadow Fiend",
            "Lina": -1.974
        },
        {
            "name": "Shadow Shaman",
            "Lina": -0.14
        },
        {
            "name": "Silencer",
            "Lina": 1.089
        },
        {
            "name": "Skywrath Mage",
            "Lina": 0.42
        },
        {
            "name": "Slardar",
            "Lina": 0.775
        },
        {
            "name": "Slark",
            "Lina": 0.28
        },
        {
            "name": "Snapfire",
            "Lina": -0.593
        },
        {
            "name": "Sniper",
            "Lina": 0.131
        },
        {
            "name": "Spectre",
            "Lina": -0.775
        },
        {
            "name": "Spirit Breaker",
            "Lina": 0.966
        },
        {
            "name": "Storm Spirit",
            "Lina": 1.279
        },
        {
            "name": "Sven",
            "Lina": 0.951
        },
        {
            "name": "Techies",
            "Lina": -0.04
        },
        {
            "name": "Templar Assassin",
            "Lina": 2.01
        },
        {
            "name": "Terrorblade",
            "Lina": -2.312
        },
        {
            "name": "Tidehunter",
            "Lina": 0.539
        },
        {
            "name": "Timbersaw",
            "Lina": -0.497
        },
        {
            "name": "Tinker",
            "Lina": -1.042
        },
        {
            "name": "Tiny",
            "Lina": -0.189
        },
        {
            "name": "Treant Protector",
            "Lina": -0.398
        },
        {
            "name": "Troll Warlord",
            "Lina": -1.324
        },
        {
            "name": "Tusk",
            "Lina": -0.252
        },
        {
            "name": "Underlord",
            "Lina": 0.256
        },
        {
            "name": "Undying",
            "Lina": -0.95
        },
        {
            "name": "Ursa",
            "Lina": 0.662
        },
        {
            "name": "Vengeful Spirit",
            "Lina": -0.546
        },
        {
            "name": "Venomancer",
            "Lina": -0.444
        },
        {
            "name": "Viper",
            "Lina": -0.502
        },
        {
            "name": "Visage",
            "Lina": -0.641
        },
        {
            "name": "Void Spirit",
            "Lina": 1.111
        },
        {
            "name": "Warlock",
            "Lina": -0.737
        },
        {
            "name": "Weaver",
            "Lina": 0.145
        },
        {
            "name": "Windranger",
            "Lina": 1.315
        },
        {
            "name": "Winter Wyvern",
            "Lina": 2.585
        },
        {
            "name": "Witch Doctor",
            "Lina": -0.508
        },
        {
            "name": "Wraith King",
            "Lina": -0.138
        },
        {
            "name": "Zeus",
            "Lina": -0.177
        }
    ],
    "Lion": [
        {
            "name": "Abaddon",
            "Lion": -2.046
        },
        {
            "name": "Alchemist",
            "Lion": -0.513
        },
        {
            "name": "Ancient Apparition",
            "Lion": -1.494
        },
        {
            "name": "Anti-Mage",
            "Lion": -1.195
        },
        {
            "name": "Arc Warden",
            "Lion": 1.144
        },
        {
            "name": "Axe",
            "Lion": 0.377
        },
        {
            "name": "Bane",
            "Lion": 0.056
        },
        {
            "name": "Batrider",
            "Lion": 0.895
        },
        {
            "name": "Beastmaster",
            "Lion": 1.336
        },
        {
            "name": "Bloodseeker",
            "Lion": -0.714
        },
        {
            "name": "Bounty Hunter",
            "Lion": -0.98
        },
        {
            "name": "Brewmaster",
            "Lion": 0.527
        },
        {
            "name": "Bristleback",
            "Lion": 0.337
        },
        {
            "name": "Broodmother",
            "Lion": -1.227
        },
        {
            "name": "Centaur Warrunner",
            "Lion": 0.444
        },
        {
            "name": "Chaos Knight",
            "Lion": 0.103
        },
        {
            "name": "Chen",
            "Lion": 1.114
        },
        {
            "name": "Clinkz",
            "Lion": -0.328
        },
        {
            "name": "Clockwerk",
            "Lion": -0.666
        },
        {
            "name": "Crystal Maiden",
            "Lion": 0.021
        },
        {
            "name": "Dark Seer",
            "Lion": -0.173
        },
        {
            "name": "Dark Willow",
            "Lion": 0.125
        },
        {
            "name": "Dawnbreaker",
            "Lion": 0.148
        },
        {
            "name": "Dazzle",
            "Lion": 0.427
        },
        {
            "name": "Death Prophet",
            "Lion": 0.105
        },
        {
            "name": "Disruptor",
            "Lion": -0.124
        },
        {
            "name": "Doom",
            "Lion": 0.691
        },
        {
            "name": "Dragon Knight",
            "Lion": 0.041
        },
        {
            "name": "Drow Ranger",
            "Lion": 0.687
        },
        {
            "name": "Earth Spirit",
            "Lion": 0.064
        },
        {
            "name": "Earthshaker",
            "Lion": -0.501
        },
        {
            "name": "Elder Titan",
            "Lion": -0.869
        },
        {
            "name": "Ember Spirit",
            "Lion": -0.26
        },
        {
            "name": "Enchantress",
            "Lion": 0.199
        },
        {
            "name": "Enigma",
            "Lion": 0.065
        },
        {
            "name": "Faceless Void",
            "Lion": -0.814
        },
        {
            "name": "Grimstroke",
            "Lion": 0.181
        },
        {
            "name": "Gyrocopter",
            "Lion": 1.104
        },
        {
            "name": "Hoodwink",
            "Lion": 1.11
        },
        {
            "name": "Huskar",
            "Lion": -0.643
        },
        {
            "name": "Invoker",
            "Lion": 0.495
        },
        {
            "name": "Io",
            "Lion": 0.08
        },
        {
            "name": "Jakiro",
            "Lion": 0.06
        },
        {
            "name": "Juggernaut",
            "Lion": -1.706
        },
        {
            "name": "Keeper of the Light",
            "Lion": 0.218
        },
        {
            "name": "Kez",
            "Lion": 0.005
        },
        {
            "name": "Kunkka",
            "Lion": 0.971
        },
        {
            "name": "Legion Commander",
            "Lion": -1.379
        },
        {
            "name": "Leshrac",
            "Lion": -0.267
        },
        {
            "name": "Lich",
            "Lion": -0.385
        },
        {
            "name": "Lifestealer",
            "Lion": -0.684
        },
        {
            "name": "Lina",
            "Lion": 0.969
        },
        {
            "name": "Lone Druid",
            "Lion": 0.649
        },
        {
            "name": "Luna",
            "Lion": 0.857
        },
        {
            "name": "Lycan",
            "Lion": 1.055
        },
        {
            "name": "Magnus",
            "Lion": -0.328
        },
        {
            "name": "Marci",
            "Lion": 0.452
        },
        {
            "name": "Mars",
            "Lion": 1.047
        },
        {
            "name": "Medusa",
            "Lion": -1.056
        },
        {
            "name": "Meepo",
            "Lion": -1.367
        },
        {
            "name": "Mirana",
            "Lion": -0.241
        },
        {
            "name": "Monkey King",
            "Lion": 0.201
        },
        {
            "name": "Morphling",
            "Lion": -1.282
        },
        {
            "name": "Muerta",
            "Lion": -0.412
        },
        {
            "name": "Naga Siren",
            "Lion": -0.233
        },
        {
            "name": "Nature's Prophet",
            "Lion": 0.413
        },
        {
            "name": "Necrophos",
            "Lion": -0.644
        },
        {
            "name": "Night Stalker",
            "Lion": -0.007
        },
        {
            "name": "Nyx Assassin",
            "Lion": -1.143
        },
        {
            "name": "Ogre Magi",
            "Lion": -0.081
        },
        {
            "name": "Omniknight",
            "Lion": 1.421
        },
        {
            "name": "Oracle",
            "Lion": 0.182
        },
        {
            "name": "Outworld Destroyer",
            "Lion": 0.378
        },
        {
            "name": "Pangolier",
            "Lion": 0.593
        },
        {
            "name": "Phantom Assassin",
            "Lion": 0.684
        },
        {
            "name": "Phantom Lancer",
            "Lion": 0.967
        },
        {
            "name": "Phoenix",
            "Lion": -0.81
        },
        {
            "name": "Primal Beast",
            "Lion": -0.32
        },
        {
            "name": "Puck",
            "Lion": -0.838
        },
        {
            "name": "Pudge",
            "Lion": 0.333
        },
        {
            "name": "Pugna",
            "Lion": 0.21
        },
        {
            "name": "Queen of Pain",
            "Lion": -0.512
        },
        {
            "name": "Razor",
            "Lion": 1.464
        },
        {
            "name": "Riki",
            "Lion": -0.426
        },
        {
            "name": "Ringmaster",
            "Lion": 0.495
        },
        {
            "name": "Rubick",
            "Lion": 1.345
        },
        {
            "name": "Sand King",
            "Lion": 0.332
        },
        {
            "name": "Shadow Demon",
            "Lion": 0.7
        },
        {
            "name": "Shadow Fiend",
            "Lion": -0.144
        },
        {
            "name": "Shadow Shaman",
            "Lion": -0.01
        },
        {
            "name": "Silencer",
            "Lion": 0.47
        },
        {
            "name": "Skywrath Mage",
            "Lion": -0.019
        },
        {
            "name": "Slardar",
            "Lion": -0.526
        },
        {
            "name": "Slark",
            "Lion": -1.825
        },
        {
            "name": "Snapfire",
            "Lion": 0.379
        },
        {
            "name": "Sniper",
            "Lion": 0.477
        },
        {
            "name": "Spectre",
            "Lion": 0.559
        },
        {
            "name": "Spirit Breaker",
            "Lion": 0.041
        },
        {
            "name": "Storm Spirit",
            "Lion": -1.393
        },
        {
            "name": "Sven",
            "Lion": 0.286
        },
        {
            "name": "Techies",
            "Lion": -0.043
        },
        {
            "name": "Templar Assassin",
            "Lion": 1.75
        },
        {
            "name": "Terrorblade",
            "Lion": 0.501
        },
        {
            "name": "Tidehunter",
            "Lion": 0.044
        },
        {
            "name": "Timbersaw",
            "Lion": -0.156
        },
        {
            "name": "Tinker",
            "Lion": -0.945
        },
        {
            "name": "Tiny",
            "Lion": 0.713
        },
        {
            "name": "Treant Protector",
            "Lion": 0.576
        },
        {
            "name": "Troll Warlord",
            "Lion": -2.145
        },
        {
            "name": "Tusk",
            "Lion": 0.503
        },
        {
            "name": "Underlord",
            "Lion": -0.233
        },
        {
            "name": "Undying",
            "Lion": -0.648
        },
        {
            "name": "Ursa",
            "Lion": -0.573
        },
        {
            "name": "Vengeful Spirit",
            "Lion": 0.262
        },
        {
            "name": "Venomancer",
            "Lion": 0.504
        },
        {
            "name": "Viper",
            "Lion": 0.775
        },
        {
            "name": "Visage",
            "Lion": 0.495
        },
        {
            "name": "Void Spirit",
            "Lion": -0.375
        },
        {
            "name": "Warlock",
            "Lion": 0.038
        },
        {
            "name": "Weaver",
            "Lion": -1.29
        },
        {
            "name": "Windranger",
            "Lion": 0.575
        },
        {
            "name": "Winter Wyvern",
            "Lion": 0.078
        },
        {
            "name": "Witch Doctor",
            "Lion": -1.155
        },
        {
            "name": "Wraith King",
            "Lion": 0.002
        },
        {
            "name": "Zeus",
            "Lion": -0.518
        }
    ],
    "Muerta": [
        {
            "name": "Abaddon",
            "Muerta": -0.147
        },
        {
            "name": "Alchemist",
            "Muerta": 0.523
        },
        {
            "name": "Ancient Apparition",
            "Muerta": 0.447
        },
        {
            "name": "Anti-Mage",
            "Muerta": 0.572
        },
        {
            "name": "Arc Warden",
            "Muerta": 0.151
        },
        {
            "name": "Axe",
            "Muerta": 1.175
        },
        {
            "name": "Bane",
            "Muerta": 1.601
        },
        {
            "name": "Batrider",
            "Muerta": 1.427
        },
        {
            "name": "Beastmaster",
            "Muerta": 0.033
        },
        {
            "name": "Bloodseeker",
            "Muerta": -0.217
        },
        {
            "name": "Bounty Hunter",
            "Muerta": 0.438
        },
        {
            "name": "Brewmaster",
            "Muerta": 0.36
        },
        {
            "name": "Bristleback",
            "Muerta": 0.273
        },
        {
            "name": "Broodmother",
            "Muerta": 0.316
        },
        {
            "name": "Centaur Warrunner",
            "Muerta": 1.529
        },
        {
            "name": "Chaos Knight",
            "Muerta": -0.447
        },
        {
            "name": "Chen",
            "Muerta": 0.403
        },
        {
            "name": "Clinkz",
            "Muerta": 1.302
        },
        {
            "name": "Clockwerk",
            "Muerta": -0.091
        },
        {
            "name": "Crystal Maiden",
            "Muerta": -0.373
        },
        {
            "name": "Dark Seer",
            "Muerta": 1.105
        },
        {
            "name": "Dark Willow",
            "Muerta": 0.018
        },
        {
            "name": "Dawnbreaker",
            "Muerta": -0.811
        },
        {
            "name": "Dazzle",
            "Muerta": 0.169
        },
        {
            "name": "Death Prophet",
            "Muerta": 0.069
        },
        {
            "name": "Disruptor",
            "Muerta": 0.445
        },
        {
            "name": "Doom",
            "Muerta": 1.052
        },
        {
            "name": "Dragon Knight",
            "Muerta": 0.405
        },
        {
            "name": "Drow Ranger",
            "Muerta": -0.76
        },
        {
            "name": "Earth Spirit",
            "Muerta": -0.307
        },
        {
            "name": "Earthshaker",
            "Muerta": 0.087
        },
        {
            "name": "Elder Titan",
            "Muerta": -0.522
        },
        {
            "name": "Ember Spirit",
            "Muerta": -1.657
        },
        {
            "name": "Enchantress",
            "Muerta": 0.433
        },
        {
            "name": "Enigma",
            "Muerta": -0.319
        },
        {
            "name": "Faceless Void",
            "Muerta": -0.937
        },
        {
            "name": "Grimstroke",
            "Muerta": -0.075
        },
        {
            "name": "Gyrocopter",
            "Muerta": 0.235
        },
        {
            "name": "Hoodwink",
            "Muerta": -0.071
        },
        {
            "name": "Huskar",
            "Muerta": -0.048
        },
        {
            "name": "Invoker",
            "Muerta": 2.185
        },
        {
            "name": "Io",
            "Muerta": -1.316
        },
        {
            "name": "Jakiro",
            "Muerta": -0.195
        },
        {
            "name": "Juggernaut",
            "Muerta": -0.874
        },
        {
            "name": "Keeper of the Light",
            "Muerta": 1.939
        },
        {
            "name": "Kez",
            "Muerta": -1.689
        },
        {
            "name": "Kunkka",
            "Muerta": 0.996
        },
        {
            "name": "Legion Commander",
            "Muerta": 0.645
        },
        {
            "name": "Leshrac",
            "Muerta": 0.74
        },
        {
            "name": "Lich",
            "Muerta": -0.128
        },
        {
            "name": "Lifestealer",
            "Muerta": 0.681
        },
        {
            "name": "Lina",
            "Muerta": 0.183
        },
        {
            "name": "Lion",
            "Muerta": 0.727
        },
        {
            "name": "Lone Druid",
            "Muerta": -0.035
        },
        {
            "name": "Luna",
            "Muerta": 0.02
        },
        {
            "name": "Lycan",
            "Muerta": 0.626
        },
        {
            "name": "Magnus",
            "Muerta": 0.2
        },
        {
            "name": "Marci",
            "Muerta": -1.839
        },
        {
            "name": "Mars",
            "Muerta": -0.725
        },
        {
            "name": "Medusa",
            "Muerta": 0.771
        },
        {
            "name": "Meepo",
            "Muerta": -0.936
        },
        {
            "name": "Mirana",
            "Muerta": 0.397
        },
        {
            "name": "Monkey King",
            "Muerta": -2.304
        },
        {
            "name": "Morphling",
            "Muerta": -0.65
        },
        {
            "name": "Naga Siren",
            "Muerta": 1.67
        },
        {
            "name": "Nature's Prophet",
            "Muerta": 1.463
        },
        {
            "name": "Necrophos",
            "Muerta": -2.811
        },
        {
            "name": "Night Stalker",
            "Muerta": 1.1
        },
        {
            "name": "Nyx Assassin",
            "Muerta": 1.517
        },
        {
            "name": "Ogre Magi",
            "Muerta": 0.934
        },
        {
            "name": "Omniknight",
            "Muerta": -1.37
        },
        {
            "name": "Oracle",
            "Muerta": -0.054
        },
        {
            "name": "Outworld Destroyer",
            "Muerta": -0.421
        },
        {
            "name": "Pangolier",
            "Muerta": -1.381
        },
        {
            "name": "Phantom Assassin",
            "Muerta": 0.184
        },
        {
            "name": "Phantom Lancer",
            "Muerta": -1.613
        },
        {
            "name": "Phoenix",
            "Muerta": -2.632
        },
        {
            "name": "Primal Beast",
            "Muerta": 2.444
        },
        {
            "name": "Puck",
            "Muerta": -1.617
        },
        {
            "name": "Pudge",
            "Muerta": 0.026
        },
        {
            "name": "Pugna",
            "Muerta": 0.819
        },
        {
            "name": "Queen of Pain",
            "Muerta": 0.229
        },
        {
            "name": "Razor",
            "Muerta": -0.712
        },
        {
            "name": "Riki",
            "Muerta": -1.3
        },
        {
            "name": "Ringmaster",
            "Muerta": 0.398
        },
        {
            "name": "Rubick",
            "Muerta": 1.684
        },
        {
            "name": "Sand King",
            "Muerta": -1.219
        },
        {
            "name": "Shadow Demon",
            "Muerta": 0.736
        },
        {
            "name": "Shadow Fiend",
            "Muerta": 0.189
        },
        {
            "name": "Shadow Shaman",
            "Muerta": -0.465
        },
        {
            "name": "Silencer",
            "Muerta": 0.244
        },
        {
            "name": "Skywrath Mage",
            "Muerta": 0.835
        },
        {
            "name": "Slardar",
            "Muerta": -2.079
        },
        {
            "name": "Slark",
            "Muerta": -2.433
        },
        {
            "name": "Snapfire",
            "Muerta": 1.224
        },
        {
            "name": "Sniper",
            "Muerta": 0.732
        },
        {
            "name": "Spectre",
            "Muerta": 1.86
        },
        {
            "name": "Spirit Breaker",
            "Muerta": -0.025
        },
        {
            "name": "Storm Spirit",
            "Muerta": -1.423
        },
        {
            "name": "Sven",
            "Muerta": -0.437
        },
        {
            "name": "Techies",
            "Muerta": 0.382
        },
        {
            "name": "Templar Assassin",
            "Muerta": 0.36
        },
        {
            "name": "Terrorblade",
            "Muerta": -0.001
        },
        {
            "name": "Tidehunter",
            "Muerta": -1.959
        },
        {
            "name": "Timbersaw",
            "Muerta": -1.178
        },
        {
            "name": "Tinker",
            "Muerta": 1.354
        },
        {
            "name": "Tiny",
            "Muerta": 0.293
        },
        {
            "name": "Treant Protector",
            "Muerta": 0.27
        },
        {
            "name": "Troll Warlord",
            "Muerta": -1.229
        },
        {
            "name": "Tusk",
            "Muerta": -1.167
        },
        {
            "name": "Underlord",
            "Muerta": 0.108
        },
        {
            "name": "Undying",
            "Muerta": -0.733
        },
        {
            "name": "Ursa",
            "Muerta": -0.021
        },
        {
            "name": "Vengeful Spirit",
            "Muerta": -1.538
        },
        {
            "name": "Venomancer",
            "Muerta": -1.287
        },
        {
            "name": "Viper",
            "Muerta": 0.417
        },
        {
            "name": "Visage",
            "Muerta": -0.043
        },
        {
            "name": "Void Spirit",
            "Muerta": -1.27
        },
        {
            "name": "Warlock",
            "Muerta": -0.743
        },
        {
            "name": "Weaver",
            "Muerta": -1.709
        },
        {
            "name": "Windranger",
            "Muerta": 0.372
        },
        {
            "name": "Winter Wyvern",
            "Muerta": -0.331
        },
        {
            "name": "Witch Doctor",
            "Muerta": -1.456
        },
        {
            "name": "Wraith King",
            "Muerta": 1.313
        },
        {
            "name": "Zeus",
            "Muerta": 0.962
        }
    ],
    "Nature's Prophet": [
        {
            "name": "Abaddon",
            "Nature's Prophet": -1.461
        },
        {
            "name": "Alchemist",
            "Nature's Prophet": -0.611
        },
        {
            "name": "Ancient Apparition",
            "Nature's Prophet": -1.968
        },
        {
            "name": "Anti-Mage",
            "Nature's Prophet": 1.26
        },
        {
            "name": "Arc Warden",
            "Nature's Prophet": 1.702
        },
        {
            "name": "Axe",
            "Nature's Prophet": 0.186
        },
        {
            "name": "Bane",
            "Nature's Prophet": -0.067
        },
        {
            "name": "Batrider",
            "Nature's Prophet": 1.635
        },
        {
            "name": "Beastmaster",
            "Nature's Prophet": 0.016
        },
        {
            "name": "Bloodseeker",
            "Nature's Prophet": -2.852
        },
        {
            "name": "Bounty Hunter",
            "Nature's Prophet": 0.264
        },
        {
            "name": "Brewmaster",
            "Nature's Prophet": -0.756
        },
        {
            "name": "Bristleback",
            "Nature's Prophet": -0.877
        },
        {
            "name": "Broodmother",
            "Nature's Prophet": 2.575
        },
        {
            "name": "Centaur Warrunner",
            "Nature's Prophet": -0.586
        },
        {
            "name": "Chaos Knight",
            "Nature's Prophet": 1.965
        },
        {
            "name": "Chen",
            "Nature's Prophet": 1.49
        },
        {
            "name": "Clinkz",
            "Nature's Prophet": 0.339
        },
        {
            "name": "Clockwerk",
            "Nature's Prophet": 0.146
        },
        {
            "name": "Crystal Maiden",
            "Nature's Prophet": 0.839
        },
        {
            "name": "Dark Seer",
            "Nature's Prophet": -1.676
        },
        {
            "name": "Dark Willow",
            "Nature's Prophet": -0.647
        },
        {
            "name": "Dawnbreaker",
            "Nature's Prophet": 1.689
        },
        {
            "name": "Dazzle",
            "Nature's Prophet": -0.48
        },
        {
            "name": "Death Prophet",
            "Nature's Prophet": -1.489
        },
        {
            "name": "Disruptor",
            "Nature's Prophet": -2.111
        },
        {
            "name": "Doom",
            "Nature's Prophet": -0.848
        },
        {
            "name": "Dragon Knight",
            "Nature's Prophet": -0.294
        },
        {
            "name": "Drow Ranger",
            "Nature's Prophet": -0.621
        },
        {
            "name": "Earth Spirit",
            "Nature's Prophet": 1.3
        },
        {
            "name": "Earthshaker",
            "Nature's Prophet": -0.2
        },
        {
            "name": "Elder Titan",
            "Nature's Prophet": -1.981
        },
        {
            "name": "Ember Spirit",
            "Nature's Prophet": -0.847
        },
        {
            "name": "Enchantress",
            "Nature's Prophet": 0.84
        },
        {
            "name": "Enigma",
            "Nature's Prophet": -1.141
        },
        {
            "name": "Faceless Void",
            "Nature's Prophet": 0.343
        },
        {
            "name": "Grimstroke",
            "Nature's Prophet": -0.849
        },
        {
            "name": "Gyrocopter",
            "Nature's Prophet": -0.235
        },
        {
            "name": "Hoodwink",
            "Nature's Prophet": 1.61
        },
        {
            "name": "Huskar",
            "Nature's Prophet": -0.682
        },
        {
            "name": "Invoker",
            "Nature's Prophet": -0.617
        },
        {
            "name": "Io",
            "Nature's Prophet": 1.283
        },
        {
            "name": "Jakiro",
            "Nature's Prophet": -1.237
        },
        {
            "name": "Juggernaut",
            "Nature's Prophet": -1.77
        },
        {
            "name": "Keeper of the Light",
            "Nature's Prophet": -0.235
        },
        {
            "name": "Kez",
            "Nature's Prophet": 1.258
        },
        {
            "name": "Kunkka",
            "Nature's Prophet": 0.666
        },
        {
            "name": "Legion Commander",
            "Nature's Prophet": 0.645
        },
        {
            "name": "Leshrac",
            "Nature's Prophet": -1.195
        },
        {
            "name": "Lich",
            "Nature's Prophet": -0.821
        },
        {
            "name": "Lifestealer",
            "Nature's Prophet": -3.279
        },
        {
            "name": "Lina",
            "Nature's Prophet": 0.335
        },
        {
            "name": "Lion",
            "Nature's Prophet": -0.349
        },
        {
            "name": "Lone Druid",
            "Nature's Prophet": -0.064
        },
        {
            "name": "Luna",
            "Nature's Prophet": 0.834
        },
        {
            "name": "Lycan",
            "Nature's Prophet": 0.279
        },
        {
            "name": "Magnus",
            "Nature's Prophet": -0.565
        },
        {
            "name": "Marci",
            "Nature's Prophet": 0.233
        },
        {
            "name": "Mars",
            "Nature's Prophet": 1.203
        },
        {
            "name": "Medusa",
            "Nature's Prophet": 0.349
        },
        {
            "name": "Meepo",
            "Nature's Prophet": 1.446
        },
        {
            "name": "Mirana",
            "Nature's Prophet": 0.243
        },
        {
            "name": "Monkey King",
            "Nature's Prophet": 1.098
        },
        {
            "name": "Morphling",
            "Nature's Prophet": 2.205
        },
        {
            "name": "Muerta",
            "Nature's Prophet": -1.342
        },
        {
            "name": "Naga Siren",
            "Nature's Prophet": 0.368
        },
        {
            "name": "Necrophos",
            "Nature's Prophet": -1.178
        },
        {
            "name": "Night Stalker",
            "Nature's Prophet": 0.149
        },
        {
            "name": "Nyx Assassin",
            "Nature's Prophet": 1.53
        },
        {
            "name": "Ogre Magi",
            "Nature's Prophet": -0.336
        },
        {
            "name": "Omniknight",
            "Nature's Prophet": -1.414
        },
        {
            "name": "Oracle",
            "Nature's Prophet": -0.609
        },
        {
            "name": "Outworld Destroyer",
            "Nature's Prophet": -1.081
        },
        {
            "name": "Pangolier",
            "Nature's Prophet": 0.51
        },
        {
            "name": "Phantom Assassin",
            "Nature's Prophet": 2.182
        },
        {
            "name": "Phantom Lancer",
            "Nature's Prophet": -0.021
        },
        {
            "name": "Phoenix",
            "Nature's Prophet": -1.02
        },
        {
            "name": "Primal Beast",
            "Nature's Prophet": 1.456
        },
        {
            "name": "Puck",
            "Nature's Prophet": -1.398
        },
        {
            "name": "Pudge",
            "Nature's Prophet": 0.976
        },
        {
            "name": "Pugna",
            "Nature's Prophet": -0.617
        },
        {
            "name": "Queen of Pain",
            "Nature's Prophet": -0.216
        },
        {
            "name": "Razor",
            "Nature's Prophet": -2.11
        },
        {
            "name": "Riki",
            "Nature's Prophet": -0.655
        },
        {
            "name": "Ringmaster",
            "Nature's Prophet": -0.619
        },
        {
            "name": "Rubick",
            "Nature's Prophet": -0.455
        },
        {
            "name": "Sand King",
            "Nature's Prophet": 0.33
        },
        {
            "name": "Shadow Demon",
            "Nature's Prophet": 0.266
        },
        {
            "name": "Shadow Fiend",
            "Nature's Prophet": 1.039
        },
        {
            "name": "Shadow Shaman",
            "Nature's Prophet": 0.086
        },
        {
            "name": "Silencer",
            "Nature's Prophet": -1.739
        },
        {
            "name": "Skywrath Mage",
            "Nature's Prophet": -0.265
        },
        {
            "name": "Slardar",
            "Nature's Prophet": 0.129
        },
        {
            "name": "Slark",
            "Nature's Prophet": 0.63
        },
        {
            "name": "Snapfire",
            "Nature's Prophet": 0.641
        },
        {
            "name": "Sniper",
            "Nature's Prophet": -0.272
        },
        {
            "name": "Spectre",
            "Nature's Prophet": 3.384
        },
        {
            "name": "Spirit Breaker",
            "Nature's Prophet": 0.915
        },
        {
            "name": "Storm Spirit",
            "Nature's Prophet": 0.818
        },
        {
            "name": "Sven",
            "Nature's Prophet": 0.131
        },
        {
            "name": "Techies",
            "Nature's Prophet": 0.359
        },
        {
            "name": "Templar Assassin",
            "Nature's Prophet": 1.945
        },
        {
            "name": "Terrorblade",
            "Nature's Prophet": 1.174
        },
        {
            "name": "Tidehunter",
            "Nature's Prophet": -0.73
        },
        {
            "name": "Timbersaw",
            "Nature's Prophet": 0.491
        },
        {
            "name": "Tinker",
            "Nature's Prophet": 1.084
        },
        {
            "name": "Tiny",
            "Nature's Prophet": -0.457
        },
        {
            "name": "Treant Protector",
            "Nature's Prophet": 2.041
        },
        {
            "name": "Troll Warlord",
            "Nature's Prophet": -1.258
        },
        {
            "name": "Tusk",
            "Nature's Prophet": 0.318
        },
        {
            "name": "Underlord",
            "Nature's Prophet": -0.658
        },
        {
            "name": "Undying",
            "Nature's Prophet": -0.531
        },
        {
            "name": "Ursa",
            "Nature's Prophet": 0.762
        },
        {
            "name": "Vengeful Spirit",
            "Nature's Prophet": 0.834
        },
        {
            "name": "Venomancer",
            "Nature's Prophet": -1.627
        },
        {
            "name": "Viper",
            "Nature's Prophet": -0.612
        },
        {
            "name": "Visage",
            "Nature's Prophet": 0.08
        },
        {
            "name": "Void Spirit",
            "Nature's Prophet": 0.877
        },
        {
            "name": "Warlock",
            "Nature's Prophet": -1.425
        },
        {
            "name": "Weaver",
            "Nature's Prophet": -0.744
        },
        {
            "name": "Windranger",
            "Nature's Prophet": -0.179
        },
        {
            "name": "Winter Wyvern",
            "Nature's Prophet": -0.367
        },
        {
            "name": "Witch Doctor",
            "Nature's Prophet": -0.449
        },
        {
            "name": "Wraith King",
            "Nature's Prophet": 0.656
        },
        {
            "name": "Zeus",
            "Nature's Prophet": 0.114
        }
    ],
    "Necrophos": [
        {
            "name": "Abaddon",
            "Necrophos": -0.048
        },
        {
            "name": "Alchemist",
            "Necrophos": -0.06
        },
        {
            "name": "Ancient Apparition",
            "Necrophos": 5.072
        },
        {
            "name": "Anti-Mage",
            "Necrophos": 0.785
        },
        {
            "name": "Arc Warden",
            "Necrophos": 3.018
        },
        {
            "name": "Axe",
            "Necrophos": -2.452
        },
        {
            "name": "Bane",
            "Necrophos": -1.083
        },
        {
            "name": "Batrider",
            "Necrophos": 0.642
        },
        {
            "name": "Beastmaster",
            "Necrophos": 0.61
        },
        {
            "name": "Bloodseeker",
            "Necrophos": -0.546
        },
        {
            "name": "Bounty Hunter",
            "Necrophos": -0.455
        },
        {
            "name": "Brewmaster",
            "Necrophos": -0.473
        },
        {
            "name": "Bristleback",
            "Necrophos": -0.691
        },
        {
            "name": "Broodmother",
            "Necrophos": -2.708
        },
        {
            "name": "Centaur Warrunner",
            "Necrophos": -1.226
        },
        {
            "name": "Chaos Knight",
            "Necrophos": -1.785
        },
        {
            "name": "Chen",
            "Necrophos": -1.025
        },
        {
            "name": "Clinkz",
            "Necrophos": 1.416
        },
        {
            "name": "Clockwerk",
            "Necrophos": -0.172
        },
        {
            "name": "Crystal Maiden",
            "Necrophos": 1.709
        },
        {
            "name": "Dark Seer",
            "Necrophos": 1.522
        },
        {
            "name": "Dark Willow",
            "Necrophos": 0.313
        },
        {
            "name": "Dawnbreaker",
            "Necrophos": 0.422
        },
        {
            "name": "Dazzle",
            "Necrophos": 1.96
        },
        {
            "name": "Death Prophet",
            "Necrophos": 3.845
        },
        {
            "name": "Disruptor",
            "Necrophos": 1.7
        },
        {
            "name": "Doom",
            "Necrophos": 3.815
        },
        {
            "name": "Dragon Knight",
            "Necrophos": -1.865
        },
        {
            "name": "Drow Ranger",
            "Necrophos": 2.409
        },
        {
            "name": "Earth Spirit",
            "Necrophos": -0.0
        },
        {
            "name": "Earthshaker",
            "Necrophos": -0.622
        },
        {
            "name": "Elder Titan",
            "Necrophos": 0.031
        },
        {
            "name": "Ember Spirit",
            "Necrophos": 0.028
        },
        {
            "name": "Enchantress",
            "Necrophos": 0.158
        },
        {
            "name": "Enigma",
            "Necrophos": -1.504
        },
        {
            "name": "Faceless Void",
            "Necrophos": 1.002
        },
        {
            "name": "Grimstroke",
            "Necrophos": 2.711
        },
        {
            "name": "Gyrocopter",
            "Necrophos": 0.91
        },
        {
            "name": "Hoodwink",
            "Necrophos": 0.192
        },
        {
            "name": "Huskar",
            "Necrophos": -3.835
        },
        {
            "name": "Invoker",
            "Necrophos": -0.398
        },
        {
            "name": "Io",
            "Necrophos": 0.235
        },
        {
            "name": "Jakiro",
            "Necrophos": 0.627
        },
        {
            "name": "Juggernaut",
            "Necrophos": -0.19
        },
        {
            "name": "Keeper of the Light",
            "Necrophos": 1.926
        },
        {
            "name": "Kez",
            "Necrophos": -0.748
        },
        {
            "name": "Kunkka",
            "Necrophos": -0.197
        },
        {
            "name": "Legion Commander",
            "Necrophos": 1.039
        },
        {
            "name": "Leshrac",
            "Necrophos": 3.938
        },
        {
            "name": "Lich",
            "Necrophos": 1.521
        },
        {
            "name": "Lifestealer",
            "Necrophos": -0.72
        },
        {
            "name": "Lina",
            "Necrophos": 2.317
        },
        {
            "name": "Lion",
            "Necrophos": 0.359
        },
        {
            "name": "Lone Druid",
            "Necrophos": -0.68
        },
        {
            "name": "Luna",
            "Necrophos": 0.301
        },
        {
            "name": "Lycan",
            "Necrophos": -2.108
        },
        {
            "name": "Magnus",
            "Necrophos": -0.232
        },
        {
            "name": "Marci",
            "Necrophos": 1.066
        },
        {
            "name": "Mars",
            "Necrophos": 0.08
        },
        {
            "name": "Medusa",
            "Necrophos": 1.484
        },
        {
            "name": "Meepo",
            "Necrophos": -2.151
        },
        {
            "name": "Mirana",
            "Necrophos": -0.762
        },
        {
            "name": "Monkey King",
            "Necrophos": 0.447
        },
        {
            "name": "Morphling",
            "Necrophos": -0.01
        },
        {
            "name": "Muerta",
            "Necrophos": 2.849
        },
        {
            "name": "Naga Siren",
            "Necrophos": -4.641
        },
        {
            "name": "Nature's Prophet",
            "Necrophos": 1.184
        },
        {
            "name": "Night Stalker",
            "Necrophos": 0.404
        },
        {
            "name": "Nyx Assassin",
            "Necrophos": -0.006
        },
        {
            "name": "Ogre Magi",
            "Necrophos": -0.458
        },
        {
            "name": "Omniknight",
            "Necrophos": -0.349
        },
        {
            "name": "Oracle",
            "Necrophos": 3.696
        },
        {
            "name": "Outworld Destroyer",
            "Necrophos": 1.048
        },
        {
            "name": "Pangolier",
            "Necrophos": 0.782
        },
        {
            "name": "Phantom Assassin",
            "Necrophos": 0.402
        },
        {
            "name": "Phantom Lancer",
            "Necrophos": -3.434
        },
        {
            "name": "Phoenix",
            "Necrophos": 0.102
        },
        {
            "name": "Primal Beast",
            "Necrophos": 0.59
        },
        {
            "name": "Puck",
            "Necrophos": 0.595
        },
        {
            "name": "Pudge",
            "Necrophos": 2.181
        },
        {
            "name": "Pugna",
            "Necrophos": 3.155
        },
        {
            "name": "Queen of Pain",
            "Necrophos": 0.758
        },
        {
            "name": "Razor",
            "Necrophos": -0.359
        },
        {
            "name": "Riki",
            "Necrophos": -0.437
        },
        {
            "name": "Ringmaster",
            "Necrophos": 0.748
        },
        {
            "name": "Rubick",
            "Necrophos": 0.147
        },
        {
            "name": "Sand King",
            "Necrophos": 0.833
        },
        {
            "name": "Shadow Demon",
            "Necrophos": 2.832
        },
        {
            "name": "Shadow Fiend",
            "Necrophos": 1.947
        },
        {
            "name": "Shadow Shaman",
            "Necrophos": -1.296
        },
        {
            "name": "Silencer",
            "Necrophos": 2.634
        },
        {
            "name": "Skywrath Mage",
            "Necrophos": 3.301
        },
        {
            "name": "Slardar",
            "Necrophos": -0.233
        },
        {
            "name": "Slark",
            "Necrophos": -1.557
        },
        {
            "name": "Snapfire",
            "Necrophos": 0.851
        },
        {
            "name": "Sniper",
            "Necrophos": 1.729
        },
        {
            "name": "Spectre",
            "Necrophos": -1.351
        },
        {
            "name": "Spirit Breaker",
            "Necrophos": -2.295
        },
        {
            "name": "Storm Spirit",
            "Necrophos": -0.686
        },
        {
            "name": "Sven",
            "Necrophos": -0.517
        },
        {
            "name": "Techies",
            "Necrophos": -0.414
        },
        {
            "name": "Templar Assassin",
            "Necrophos": -0.894
        },
        {
            "name": "Terrorblade",
            "Necrophos": 0.349
        },
        {
            "name": "Tidehunter",
            "Necrophos": -2.814
        },
        {
            "name": "Timbersaw",
            "Necrophos": -0.405
        },
        {
            "name": "Tinker",
            "Necrophos": -0.127
        },
        {
            "name": "Tiny",
            "Necrophos": -3.423
        },
        {
            "name": "Treant Protector",
            "Necrophos": -0.829
        },
        {
            "name": "Troll Warlord",
            "Necrophos": -1.523
        },
        {
            "name": "Tusk",
            "Necrophos": -0.554
        },
        {
            "name": "Underlord",
            "Necrophos": 1.325
        },
        {
            "name": "Undying",
            "Necrophos": -0.512
        },
        {
            "name": "Ursa",
            "Necrophos": -1.004
        },
        {
            "name": "Vengeful Spirit",
            "Necrophos": -0.979
        },
        {
            "name": "Venomancer",
            "Necrophos": 0.6
        },
        {
            "name": "Viper",
            "Necrophos": 2.169
        },
        {
            "name": "Visage",
            "Necrophos": 2.025
        },
        {
            "name": "Void Spirit",
            "Necrophos": -0.566
        },
        {
            "name": "Warlock",
            "Necrophos": 0.431
        },
        {
            "name": "Weaver",
            "Necrophos": -0.287
        },
        {
            "name": "Windranger",
            "Necrophos": -0.179
        },
        {
            "name": "Winter Wyvern",
            "Necrophos": -1.923
        },
        {
            "name": "Witch Doctor",
            "Necrophos": -0.067
        },
        {
            "name": "Wraith King",
            "Necrophos": -4.252
        },
        {
            "name": "Zeus",
            "Necrophos": -0.944
        }
    ],
    "Oracle": [
        {
            "name": "Abaddon",
            "Oracle": -0.23
        },
        {
            "name": "Alchemist",
            "Oracle": 0.171
        },
        {
            "name": "Ancient Apparition",
            "Oracle": 0.41
        },
        {
            "name": "Anti-Mage",
            "Oracle": 3.03
        },
        {
            "name": "Arc Warden",
            "Oracle": -1.053
        },
        {
            "name": "Axe",
            "Oracle": 0.393
        },
        {
            "name": "Bane",
            "Oracle": 0.675
        },
        {
            "name": "Batrider",
            "Oracle": -0.966
        },
        {
            "name": "Beastmaster",
            "Oracle": -0.735
        },
        {
            "name": "Bloodseeker",
            "Oracle": -2.027
        },
        {
            "name": "Bounty Hunter",
            "Oracle": -1.122
        },
        {
            "name": "Brewmaster",
            "Oracle": -1.129
        },
        {
            "name": "Bristleback",
            "Oracle": 0.871
        },
        {
            "name": "Broodmother",
            "Oracle": 1.905
        },
        {
            "name": "Centaur Warrunner",
            "Oracle": -2.216
        },
        {
            "name": "Chaos Knight",
            "Oracle": 2.385
        },
        {
            "name": "Chen",
            "Oracle": -0.645
        },
        {
            "name": "Clinkz",
            "Oracle": -0.103
        },
        {
            "name": "Clockwerk",
            "Oracle": -0.366
        },
        {
            "name": "Crystal Maiden",
            "Oracle": 1.209
        },
        {
            "name": "Dark Seer",
            "Oracle": -2.586
        },
        {
            "name": "Dark Willow",
            "Oracle": -0.009
        },
        {
            "name": "Dawnbreaker",
            "Oracle": 0.626
        },
        {
            "name": "Dazzle",
            "Oracle": 0.757
        },
        {
            "name": "Death Prophet",
            "Oracle": 2.655
        },
        {
            "name": "Disruptor",
            "Oracle": -1.681
        },
        {
            "name": "Doom",
            "Oracle": 0.984
        },
        {
            "name": "Dragon Knight",
            "Oracle": 0.8
        },
        {
            "name": "Drow Ranger",
            "Oracle": 1.316
        },
        {
            "name": "Earth Spirit",
            "Oracle": -0.41
        },
        {
            "name": "Earthshaker",
            "Oracle": 1.015
        },
        {
            "name": "Elder Titan",
            "Oracle": 1.064
        },
        {
            "name": "Ember Spirit",
            "Oracle": -3.054
        },
        {
            "name": "Enchantress",
            "Oracle": 2.271
        },
        {
            "name": "Enigma",
            "Oracle": 1.979
        },
        {
            "name": "Faceless Void",
            "Oracle": -0.322
        },
        {
            "name": "Grimstroke",
            "Oracle": 0.397
        },
        {
            "name": "Gyrocopter",
            "Oracle": 0.152
        },
        {
            "name": "Hoodwink",
            "Oracle": 0.101
        },
        {
            "name": "Huskar",
            "Oracle": 1.159
        },
        {
            "name": "Invoker",
            "Oracle": 0.278
        },
        {
            "name": "Io",
            "Oracle": 2.227
        },
        {
            "name": "Jakiro",
            "Oracle": -0.778
        },
        {
            "name": "Juggernaut",
            "Oracle": -0.728
        },
        {
            "name": "Keeper of the Light",
            "Oracle": -0.99
        },
        {
            "name": "Kez",
            "Oracle": -0.647
        },
        {
            "name": "Kunkka",
            "Oracle": 1.238
        },
        {
            "name": "Legion Commander",
            "Oracle": -2.182
        },
        {
            "name": "Leshrac",
            "Oracle": 0.087
        },
        {
            "name": "Lich",
            "Oracle": -1.334
        },
        {
            "name": "Lifestealer",
            "Oracle": -0.449
        },
        {
            "name": "Lina",
            "Oracle": 0.004
        },
        {
            "name": "Lion",
            "Oracle": -1.123
        },
        {
            "name": "Lone Druid",
            "Oracle": 2.634
        },
        {
            "name": "Luna",
            "Oracle": 0.01
        },
        {
            "name": "Lycan",
            "Oracle": 2.814
        },
        {
            "name": "Magnus",
            "Oracle": 0.786
        },
        {
            "name": "Marci",
            "Oracle": 3.268
        },
        {
            "name": "Mars",
            "Oracle": 0.557
        },
        {
            "name": "Medusa",
            "Oracle": -0.461
        },
        {
            "name": "Meepo",
            "Oracle": 2.604
        },
        {
            "name": "Mirana",
            "Oracle": -0.276
        },
        {
            "name": "Monkey King",
            "Oracle": 1.077
        },
        {
            "name": "Morphling",
            "Oracle": -0.614
        },
        {
            "name": "Muerta",
            "Oracle": 0.077
        },
        {
            "name": "Naga Siren",
            "Oracle": -0.124
        },
        {
            "name": "Nature's Prophet",
            "Oracle": 0.281
        },
        {
            "name": "Necrophos",
            "Oracle": -4.055
        },
        {
            "name": "Night Stalker",
            "Oracle": 0.722
        },
        {
            "name": "Nyx Assassin",
            "Oracle": -0.154
        },
        {
            "name": "Ogre Magi",
            "Oracle": -1.428
        },
        {
            "name": "Omniknight",
            "Oracle": 1.449
        },
        {
            "name": "Outworld Destroyer",
            "Oracle": 2.424
        },
        {
            "name": "Pangolier",
            "Oracle": 2.311
        },
        {
            "name": "Phantom Assassin",
            "Oracle": 0.521
        },
        {
            "name": "Phantom Lancer",
            "Oracle": 1.042
        },
        {
            "name": "Phoenix",
            "Oracle": -1.59
        },
        {
            "name": "Primal Beast",
            "Oracle": -0.918
        },
        {
            "name": "Puck",
            "Oracle": -0.617
        },
        {
            "name": "Pudge",
            "Oracle": 0.654
        },
        {
            "name": "Pugna",
            "Oracle": 0.713
        },
        {
            "name": "Queen of Pain",
            "Oracle": -1.583
        },
        {
            "name": "Razor",
            "Oracle": 2.722
        },
        {
            "name": "Riki",
            "Oracle": 2.241
        },
        {
            "name": "Ringmaster",
            "Oracle": -0.868
        },
        {
            "name": "Rubick",
            "Oracle": -1.743
        },
        {
            "name": "Sand King",
            "Oracle": -1.241
        },
        {
            "name": "Shadow Demon",
            "Oracle": 2.239
        },
        {
            "name": "Shadow Fiend",
            "Oracle": 2.402
        },
        {
            "name": "Shadow Shaman",
            "Oracle": 1.784
        },
        {
            "name": "Silencer",
            "Oracle": -0.668
        },
        {
            "name": "Skywrath Mage",
            "Oracle": -1.538
        },
        {
            "name": "Slardar",
            "Oracle": 1.609
        },
        {
            "name": "Slark",
            "Oracle": 0.145
        },
        {
            "name": "Snapfire",
            "Oracle": 0.217
        },
        {
            "name": "Sniper",
            "Oracle": 0.232
        },
        {
            "name": "Spectre",
            "Oracle": -1.579
        },
        {
            "name": "Spirit Breaker",
            "Oracle": -1.458
        },
        {
            "name": "Storm Spirit",
            "Oracle": -2.936
        },
        {
            "name": "Sven",
            "Oracle": 0.951
        },
        {
            "name": "Techies",
            "Oracle": -1.042
        },
        {
            "name": "Templar Assassin",
            "Oracle": 2.16
        },
        {
            "name": "Terrorblade",
            "Oracle": 1.257
        },
        {
            "name": "Tidehunter",
            "Oracle": 0.798
        },
        {
            "name": "Timbersaw",
            "Oracle": 0.229
        },
        {
            "name": "Tinker",
            "Oracle": 0.553
        },
        {
            "name": "Tiny",
            "Oracle": 0.456
        },
        {
            "name": "Treant Protector",
            "Oracle": -1.917
        },
        {
            "name": "Troll Warlord",
            "Oracle": -0.259
        },
        {
            "name": "Tusk",
            "Oracle": 1.813
        },
        {
            "name": "Underlord",
            "Oracle": -1.586
        },
        {
            "name": "Undying",
            "Oracle": 2.827
        },
        {
            "name": "Ursa",
            "Oracle": 0.27
        },
        {
            "name": "Vengeful Spirit",
            "Oracle": 0.924
        },
        {
            "name": "Venomancer",
            "Oracle": -0.542
        },
        {
            "name": "Viper",
            "Oracle": -0.032
        },
        {
            "name": "Visage",
            "Oracle": 0.984
        },
        {
            "name": "Void Spirit",
            "Oracle": -1.245
        },
        {
            "name": "Warlock",
            "Oracle": -0.384
        },
        {
            "name": "Weaver",
            "Oracle": 0.895
        },
        {
            "name": "Windranger",
            "Oracle": -0.42
        },
        {
            "name": "Winter Wyvern",
            "Oracle": 0.624
        },
        {
            "name": "Witch Doctor",
            "Oracle": -0.198
        },
        {
            "name": "Wraith King",
            "Oracle": 0.454
        },
        {
            "name": "Zeus",
            "Oracle": -1.245
        }
    ],
    "Outworld Destroyer": [
        {
            "name": "Abaddon",
            "Outworld Destroyer": -2.843
        },
        {
            "name": "Alchemist",
            "Outworld Destroyer": -0.468
        },
        {
            "name": "Ancient Apparition",
            "Outworld Destroyer": -1.504
        },
        {
            "name": "Anti-Mage",
            "Outworld Destroyer": 2.17
        },
        {
            "name": "Arc Warden",
            "Outworld Destroyer": 3.303
        },
        {
            "name": "Axe",
            "Outworld Destroyer": -2.584
        },
        {
            "name": "Bane",
            "Outworld Destroyer": -0.198
        },
        {
            "name": "Batrider",
            "Outworld Destroyer": -0.006
        },
        {
            "name": "Beastmaster",
            "Outworld Destroyer": 1.254
        },
        {
            "name": "Bloodseeker",
            "Outworld Destroyer": 0.066
        },
        {
            "name": "Bounty Hunter",
            "Outworld Destroyer": -0.265
        },
        {
            "name": "Brewmaster",
            "Outworld Destroyer": 1.224
        },
        {
            "name": "Bristleback",
            "Outworld Destroyer": 1.192
        },
        {
            "name": "Broodmother",
            "Outworld Destroyer": 4.234
        },
        {
            "name": "Centaur Warrunner",
            "Outworld Destroyer": -0.332
        },
        {
            "name": "Chaos Knight",
            "Outworld Destroyer": 2.837
        },
        {
            "name": "Chen",
            "Outworld Destroyer": 2.071
        },
        {
            "name": "Clinkz",
            "Outworld Destroyer": 1.475
        },
        {
            "name": "Clockwerk",
            "Outworld Destroyer": -0.317
        },
        {
            "name": "Crystal Maiden",
            "Outworld Destroyer": 0.135
        },
        {
            "name": "Dark Seer",
            "Outworld Destroyer": -0.419
        },
        {
            "name": "Dark Willow",
            "Outworld Destroyer": 0.062
        },
        {
            "name": "Dawnbreaker",
            "Outworld Destroyer": -0.764
        },
        {
            "name": "Dazzle",
            "Outworld Destroyer": -0.153
        },
        {
            "name": "Death Prophet",
            "Outworld Destroyer": 0.27
        },
        {
            "name": "Disruptor",
            "Outworld Destroyer": 0.214
        },
        {
            "name": "Doom",
            "Outworld Destroyer": 0.131
        },
        {
            "name": "Dragon Knight",
            "Outworld Destroyer": -0.963
        },
        {
            "name": "Drow Ranger",
            "Outworld Destroyer": -1.191
        },
        {
            "name": "Earth Spirit",
            "Outworld Destroyer": -0.607
        },
        {
            "name": "Earthshaker",
            "Outworld Destroyer": 0.251
        },
        {
            "name": "Elder Titan",
            "Outworld Destroyer": 0.546
        },
        {
            "name": "Ember Spirit",
            "Outworld Destroyer": 0.159
        },
        {
            "name": "Enchantress",
            "Outworld Destroyer": 0.553
        },
        {
            "name": "Enigma",
            "Outworld Destroyer": 1.543
        },
        {
            "name": "Faceless Void",
            "Outworld Destroyer": -0.156
        },
        {
            "name": "Grimstroke",
            "Outworld Destroyer": -0.123
        },
        {
            "name": "Gyrocopter",
            "Outworld Destroyer": -1.278
        },
        {
            "name": "Hoodwink",
            "Outworld Destroyer": 0.261
        },
        {
            "name": "Huskar",
            "Outworld Destroyer": 1.563
        },
        {
            "name": "Invoker",
            "Outworld Destroyer": -0.769
        },
        {
            "name": "Io",
            "Outworld Destroyer": -0.271
        },
        {
            "name": "Jakiro",
            "Outworld Destroyer": 0.066
        },
        {
            "name": "Juggernaut",
            "Outworld Destroyer": -1.232
        },
        {
            "name": "Keeper of the Light",
            "Outworld Destroyer": 2.027
        },
        {
            "name": "Kez",
            "Outworld Destroyer": -0.045
        },
        {
            "name": "Kunkka",
            "Outworld Destroyer": 0.833
        },
        {
            "name": "Legion Commander",
            "Outworld Destroyer": -1.253
        },
        {
            "name": "Leshrac",
            "Outworld Destroyer": -2.221
        },
        {
            "name": "Lich",
            "Outworld Destroyer": -0.69
        },
        {
            "name": "Lifestealer",
            "Outworld Destroyer": 0.678
        },
        {
            "name": "Lina",
            "Outworld Destroyer": -0.404
        },
        {
            "name": "Lion",
            "Outworld Destroyer": -0.981
        },
        {
            "name": "Lone Druid",
            "Outworld Destroyer": 0.622
        },
        {
            "name": "Luna",
            "Outworld Destroyer": 0.854
        },
        {
            "name": "Lycan",
            "Outworld Destroyer": 1.758
        },
        {
            "name": "Magnus",
            "Outworld Destroyer": 0.191
        },
        {
            "name": "Marci",
            "Outworld Destroyer": 1.19
        },
        {
            "name": "Mars",
            "Outworld Destroyer": 0.725
        },
        {
            "name": "Medusa",
            "Outworld Destroyer": 2.331
        },
        {
            "name": "Meepo",
            "Outworld Destroyer": -0.494
        },
        {
            "name": "Mirana",
            "Outworld Destroyer": -0.793
        },
        {
            "name": "Monkey King",
            "Outworld Destroyer": -1.203
        },
        {
            "name": "Morphling",
            "Outworld Destroyer": -1.069
        },
        {
            "name": "Muerta",
            "Outworld Destroyer": 0.484
        },
        {
            "name": "Naga Siren",
            "Outworld Destroyer": 1.124
        },
        {
            "name": "Nature's Prophet",
            "Outworld Destroyer": 0.974
        },
        {
            "name": "Necrophos",
            "Outworld Destroyer": -1.112
        },
        {
            "name": "Night Stalker",
            "Outworld Destroyer": 0.951
        },
        {
            "name": "Nyx Assassin",
            "Outworld Destroyer": 3.184
        },
        {
            "name": "Ogre Magi",
            "Outworld Destroyer": 0.991
        },
        {
            "name": "Omniknight",
            "Outworld Destroyer": -2.246
        },
        {
            "name": "Oracle",
            "Outworld Destroyer": -2.405
        },
        {
            "name": "Pangolier",
            "Outworld Destroyer": 2.195
        },
        {
            "name": "Phantom Assassin",
            "Outworld Destroyer": 1.061
        },
        {
            "name": "Phantom Lancer",
            "Outworld Destroyer": 3.249
        },
        {
            "name": "Phoenix",
            "Outworld Destroyer": 0.759
        },
        {
            "name": "Primal Beast",
            "Outworld Destroyer": -1.999
        },
        {
            "name": "Puck",
            "Outworld Destroyer": 1.729
        },
        {
            "name": "Pudge",
            "Outworld Destroyer": -0.205
        },
        {
            "name": "Pugna",
            "Outworld Destroyer": 0.175
        },
        {
            "name": "Queen of Pain",
            "Outworld Destroyer": 0.651
        },
        {
            "name": "Razor",
            "Outworld Destroyer": -0.89
        },
        {
            "name": "Riki",
            "Outworld Destroyer": 0.216
        },
        {
            "name": "Ringmaster",
            "Outworld Destroyer": -0.084
        },
        {
            "name": "Rubick",
            "Outworld Destroyer": 2.315
        },
        {
            "name": "Sand King",
            "Outworld Destroyer": 0.914
        },
        {
            "name": "Shadow Demon",
            "Outworld Destroyer": 0.026
        },
        {
            "name": "Shadow Fiend",
            "Outworld Destroyer": -0.583
        },
        {
            "name": "Shadow Shaman",
            "Outworld Destroyer": 1.159
        },
        {
            "name": "Silencer",
            "Outworld Destroyer": 2.236
        },
        {
            "name": "Skywrath Mage",
            "Outworld Destroyer": 0.332
        },
        {
            "name": "Slardar",
            "Outworld Destroyer": -0.306
        },
        {
            "name": "Slark",
            "Outworld Destroyer": -1.836
        },
        {
            "name": "Snapfire",
            "Outworld Destroyer": -0.753
        },
        {
            "name": "Sniper",
            "Outworld Destroyer": 0.592
        },
        {
            "name": "Spectre",
            "Outworld Destroyer": 0.341
        },
        {
            "name": "Spirit Breaker",
            "Outworld Destroyer": 0.157
        },
        {
            "name": "Storm Spirit",
            "Outworld Destroyer": 1.692
        },
        {
            "name": "Sven",
            "Outworld Destroyer": 0.026
        },
        {
            "name": "Techies",
            "Outworld Destroyer": -0.384
        },
        {
            "name": "Templar Assassin",
            "Outworld Destroyer": 2.325
        },
        {
            "name": "Terrorblade",
            "Outworld Destroyer": 0.691
        },
        {
            "name": "Tidehunter",
            "Outworld Destroyer": -2.705
        },
        {
            "name": "Timbersaw",
            "Outworld Destroyer": -3.236
        },
        {
            "name": "Tinker",
            "Outworld Destroyer": 0.967
        },
        {
            "name": "Tiny",
            "Outworld Destroyer": -0.364
        },
        {
            "name": "Treant Protector",
            "Outworld Destroyer": 0.098
        },
        {
            "name": "Troll Warlord",
            "Outworld Destroyer": -2.993
        },
        {
            "name": "Tusk",
            "Outworld Destroyer": 0.319
        },
        {
            "name": "Underlord",
            "Outworld Destroyer": -1.636
        },
        {
            "name": "Undying",
            "Outworld Destroyer": 1.623
        },
        {
            "name": "Ursa",
            "Outworld Destroyer": 0.379
        },
        {
            "name": "Vengeful Spirit",
            "Outworld Destroyer": -0.867
        },
        {
            "name": "Venomancer",
            "Outworld Destroyer": 0.154
        },
        {
            "name": "Viper",
            "Outworld Destroyer": 0.283
        },
        {
            "name": "Visage",
            "Outworld Destroyer": 3.016
        },
        {
            "name": "Void Spirit",
            "Outworld Destroyer": 1.087
        },
        {
            "name": "Warlock",
            "Outworld Destroyer": 0.352
        },
        {
            "name": "Weaver",
            "Outworld Destroyer": -0.4
        },
        {
            "name": "Windranger",
            "Outworld Destroyer": -0.634
        },
        {
            "name": "Winter Wyvern",
            "Outworld Destroyer": -1.849
        },
        {
            "name": "Witch Doctor",
            "Outworld Destroyer": 0.136
        },
        {
            "name": "Wraith King",
            "Outworld Destroyer": 2.06
        },
        {
            "name": "Zeus",
            "Outworld Destroyer": 0.927
        }
    ],
    "Puck": [
        {
            "name": "Abaddon",
            "Puck": 1.078
        },
        {
            "name": "Alchemist",
            "Puck": 1.113
        },
        {
            "name": "Ancient Apparition",
            "Puck": -2.707
        },
        {
            "name": "Anti-Mage",
            "Puck": -2.007
        },
        {
            "name": "Arc Warden",
            "Puck": 0.81
        },
        {
            "name": "Axe",
            "Puck": 1.066
        },
        {
            "name": "Bane",
            "Puck": 0.9
        },
        {
            "name": "Batrider",
            "Puck": 0.426
        },
        {
            "name": "Beastmaster",
            "Puck": 1.327
        },
        {
            "name": "Bloodseeker",
            "Puck": -0.913
        },
        {
            "name": "Bounty Hunter",
            "Puck": 0.308
        },
        {
            "name": "Brewmaster",
            "Puck": -1.494
        },
        {
            "name": "Bristleback",
            "Puck": -0.302
        },
        {
            "name": "Broodmother",
            "Puck": 3.514
        },
        {
            "name": "Centaur Warrunner",
            "Puck": 0.511
        },
        {
            "name": "Chaos Knight",
            "Puck": -1.142
        },
        {
            "name": "Chen",
            "Puck": 0.851
        },
        {
            "name": "Clinkz",
            "Puck": 0.91
        },
        {
            "name": "Clockwerk",
            "Puck": -0.925
        },
        {
            "name": "Crystal Maiden",
            "Puck": -0.871
        },
        {
            "name": "Dark Seer",
            "Puck": -2.119
        },
        {
            "name": "Dark Willow",
            "Puck": -1.276
        },
        {
            "name": "Dawnbreaker",
            "Puck": -0.209
        },
        {
            "name": "Dazzle",
            "Puck": -0.655
        },
        {
            "name": "Death Prophet",
            "Puck": 1.363
        },
        {
            "name": "Disruptor",
            "Puck": 0.394
        },
        {
            "name": "Doom",
            "Puck": -0.572
        },
        {
            "name": "Dragon Knight",
            "Puck": 2.419
        },
        {
            "name": "Drow Ranger",
            "Puck": -0.116
        },
        {
            "name": "Earth Spirit",
            "Puck": 0.423
        },
        {
            "name": "Earthshaker",
            "Puck": 0.406
        },
        {
            "name": "Elder Titan",
            "Puck": -1.834
        },
        {
            "name": "Ember Spirit",
            "Puck": 0.363
        },
        {
            "name": "Enchantress",
            "Puck": 0.793
        },
        {
            "name": "Enigma",
            "Puck": 0.915
        },
        {
            "name": "Faceless Void",
            "Puck": 0.341
        },
        {
            "name": "Grimstroke",
            "Puck": 0.185
        },
        {
            "name": "Gyrocopter",
            "Puck": 0.74
        },
        {
            "name": "Hoodwink",
            "Puck": -0.692
        },
        {
            "name": "Huskar",
            "Puck": 2.52
        },
        {
            "name": "Invoker",
            "Puck": -3.914
        },
        {
            "name": "Io",
            "Puck": 1.285
        },
        {
            "name": "Jakiro",
            "Puck": 0.206
        },
        {
            "name": "Juggernaut",
            "Puck": -1.01
        },
        {
            "name": "Keeper of the Light",
            "Puck": 1.309
        },
        {
            "name": "Kez",
            "Puck": 1.674
        },
        {
            "name": "Kunkka",
            "Puck": -0.469
        },
        {
            "name": "Legion Commander",
            "Puck": 1.325
        },
        {
            "name": "Leshrac",
            "Puck": -0.077
        },
        {
            "name": "Lich",
            "Puck": -0.654
        },
        {
            "name": "Lifestealer",
            "Puck": -0.337
        },
        {
            "name": "Lina",
            "Puck": 0.327
        },
        {
            "name": "Lion",
            "Puck": 1.106
        },
        {
            "name": "Lone Druid",
            "Puck": 2.487
        },
        {
            "name": "Luna",
            "Puck": 1.375
        },
        {
            "name": "Lycan",
            "Puck": 0.873
        },
        {
            "name": "Magnus",
            "Puck": -1.649
        },
        {
            "name": "Marci",
            "Puck": -0.586
        },
        {
            "name": "Mars",
            "Puck": -2.098
        },
        {
            "name": "Medusa",
            "Puck": 1.266
        },
        {
            "name": "Meepo",
            "Puck": 1.715
        },
        {
            "name": "Mirana",
            "Puck": -1.669
        },
        {
            "name": "Monkey King",
            "Puck": 1.378
        },
        {
            "name": "Morphling",
            "Puck": 0.017
        },
        {
            "name": "Muerta",
            "Puck": 1.604
        },
        {
            "name": "Naga Siren",
            "Puck": -0.83
        },
        {
            "name": "Nature's Prophet",
            "Puck": 1.463
        },
        {
            "name": "Necrophos",
            "Puck": -0.48
        },
        {
            "name": "Night Stalker",
            "Puck": 4.257
        },
        {
            "name": "Nyx Assassin",
            "Puck": 2.214
        },
        {
            "name": "Ogre Magi",
            "Puck": -0.716
        },
        {
            "name": "Omniknight",
            "Puck": 1.401
        },
        {
            "name": "Oracle",
            "Puck": 0.475
        },
        {
            "name": "Outworld Destroyer",
            "Puck": -1.714
        },
        {
            "name": "Pangolier",
            "Puck": -1.455
        },
        {
            "name": "Phantom Assassin",
            "Puck": -1.444
        },
        {
            "name": "Phantom Lancer",
            "Puck": -3.422
        },
        {
            "name": "Phoenix",
            "Puck": -1.449
        },
        {
            "name": "Primal Beast",
            "Puck": -0.708
        },
        {
            "name": "Pudge",
            "Puck": -0.853
        },
        {
            "name": "Pugna",
            "Puck": 2.037
        },
        {
            "name": "Queen of Pain",
            "Puck": -2.563
        },
        {
            "name": "Razor",
            "Puck": -0.697
        },
        {
            "name": "Riki",
            "Puck": 3.834
        },
        {
            "name": "Ringmaster",
            "Puck": -0.752
        },
        {
            "name": "Rubick",
            "Puck": 0.983
        },
        {
            "name": "Sand King",
            "Puck": -0.916
        },
        {
            "name": "Shadow Demon",
            "Puck": -0.849
        },
        {
            "name": "Shadow Fiend",
            "Puck": 1.05
        },
        {
            "name": "Shadow Shaman",
            "Puck": 1.666
        },
        {
            "name": "Silencer",
            "Puck": 1.36
        },
        {
            "name": "Skywrath Mage",
            "Puck": 0.317
        },
        {
            "name": "Slardar",
            "Puck": 0.006
        },
        {
            "name": "Slark",
            "Puck": -1.925
        },
        {
            "name": "Snapfire",
            "Puck": -0.713
        },
        {
            "name": "Sniper",
            "Puck": 1.339
        },
        {
            "name": "Spectre",
            "Puck": 2.492
        },
        {
            "name": "Spirit Breaker",
            "Puck": -0.249
        },
        {
            "name": "Storm Spirit",
            "Puck": -2.298
        },
        {
            "name": "Sven",
            "Puck": 0.683
        },
        {
            "name": "Techies",
            "Puck": -0.234
        },
        {
            "name": "Templar Assassin",
            "Puck": 1.039
        },
        {
            "name": "Terrorblade",
            "Puck": -0.717
        },
        {
            "name": "Tidehunter",
            "Puck": 0.685
        },
        {
            "name": "Timbersaw",
            "Puck": -1.622
        },
        {
            "name": "Tinker",
            "Puck": 1.325
        },
        {
            "name": "Tiny",
            "Puck": 2.345
        },
        {
            "name": "Treant Protector",
            "Puck": 0.559
        },
        {
            "name": "Troll Warlord",
            "Puck": 0.155
        },
        {
            "name": "Tusk",
            "Puck": -0.798
        },
        {
            "name": "Underlord",
            "Puck": 0.489
        },
        {
            "name": "Undying",
            "Puck": 0.897
        },
        {
            "name": "Ursa",
            "Puck": 0.61
        },
        {
            "name": "Vengeful Spirit",
            "Puck": -0.898
        },
        {
            "name": "Venomancer",
            "Puck": -0.197
        },
        {
            "name": "Viper",
            "Puck": 0.223
        },
        {
            "name": "Visage",
            "Puck": -0.736
        },
        {
            "name": "Void Spirit",
            "Puck": 1.356
        },
        {
            "name": "Warlock",
            "Puck": -0.573
        },
        {
            "name": "Weaver",
            "Puck": -1.868
        },
        {
            "name": "Windranger",
            "Puck": -1.308
        },
        {
            "name": "Winter Wyvern",
            "Puck": -0.628
        },
        {
            "name": "Witch Doctor",
            "Puck": -1.465
        },
        {
            "name": "Wraith King",
            "Puck": 1.56
        },
        {
            "name": "Zeus",
            "Puck": 0.693
        }
    ],
    "Pugna": [
        {
            "name": "Abaddon",
            "Pugna": -0.673
        },
        {
            "name": "Alchemist",
            "Pugna": 0.165
        },
        {
            "name": "Ancient Apparition",
            "Pugna": 0.487
        },
        {
            "name": "Anti-Mage",
            "Pugna": 2.421
        },
        {
            "name": "Arc Warden",
            "Pugna": 0.269
        },
        {
            "name": "Axe",
            "Pugna": -0.691
        },
        {
            "name": "Bane",
            "Pugna": 1.128
        },
        {
            "name": "Batrider",
            "Pugna": 0.507
        },
        {
            "name": "Beastmaster",
            "Pugna": -0.356
        },
        {
            "name": "Bloodseeker",
            "Pugna": 0.189
        },
        {
            "name": "Bounty Hunter",
            "Pugna": 2.074
        },
        {
            "name": "Brewmaster",
            "Pugna": -0.591
        },
        {
            "name": "Bristleback",
            "Pugna": 0.672
        },
        {
            "name": "Broodmother",
            "Pugna": 0.742
        },
        {
            "name": "Centaur Warrunner",
            "Pugna": 0.943
        },
        {
            "name": "Chaos Knight",
            "Pugna": 3.179
        },
        {
            "name": "Chen",
            "Pugna": 3.934
        },
        {
            "name": "Clinkz",
            "Pugna": -0.349
        },
        {
            "name": "Clockwerk",
            "Pugna": 0.249
        },
        {
            "name": "Crystal Maiden",
            "Pugna": -0.966
        },
        {
            "name": "Dark Seer",
            "Pugna": 0.495
        },
        {
            "name": "Dark Willow",
            "Pugna": -0.586
        },
        {
            "name": "Dawnbreaker",
            "Pugna": -0.801
        },
        {
            "name": "Dazzle",
            "Pugna": -0.635
        },
        {
            "name": "Death Prophet",
            "Pugna": 0.542
        },
        {
            "name": "Disruptor",
            "Pugna": -1.89
        },
        {
            "name": "Doom",
            "Pugna": 1.483
        },
        {
            "name": "Dragon Knight",
            "Pugna": -0.107
        },
        {
            "name": "Drow Ranger",
            "Pugna": 1.361
        },
        {
            "name": "Earth Spirit",
            "Pugna": -0.226
        },
        {
            "name": "Earthshaker",
            "Pugna": 1.048
        },
        {
            "name": "Elder Titan",
            "Pugna": 0.93
        },
        {
            "name": "Ember Spirit",
            "Pugna": -0.69
        },
        {
            "name": "Enchantress",
            "Pugna": 1.354
        },
        {
            "name": "Enigma",
            "Pugna": 0.272
        },
        {
            "name": "Faceless Void",
            "Pugna": 0.764
        },
        {
            "name": "Grimstroke",
            "Pugna": 0.3
        },
        {
            "name": "Gyrocopter",
            "Pugna": -0.189
        },
        {
            "name": "Hoodwink",
            "Pugna": 0.281
        },
        {
            "name": "Huskar",
            "Pugna": 3.227
        },
        {
            "name": "Invoker",
            "Pugna": 0.292
        },
        {
            "name": "Io",
            "Pugna": 2.428
        },
        {
            "name": "Jakiro",
            "Pugna": -0.73
        },
        {
            "name": "Juggernaut",
            "Pugna": -1.2
        },
        {
            "name": "Keeper of the Light",
            "Pugna": -0.368
        },
        {
            "name": "Kez",
            "Pugna": -0.925
        },
        {
            "name": "Kunkka",
            "Pugna": 0.411
        },
        {
            "name": "Legion Commander",
            "Pugna": -0.982
        },
        {
            "name": "Leshrac",
            "Pugna": -0.186
        },
        {
            "name": "Lich",
            "Pugna": -1.372
        },
        {
            "name": "Lifestealer",
            "Pugna": -0.751
        },
        {
            "name": "Lina",
            "Pugna": 0.729
        },
        {
            "name": "Lion",
            "Pugna": -0.075
        },
        {
            "name": "Lone Druid",
            "Pugna": 1.074
        },
        {
            "name": "Luna",
            "Pugna": 0.732
        },
        {
            "name": "Lycan",
            "Pugna": 1.542
        },
        {
            "name": "Magnus",
            "Pugna": 1.116
        },
        {
            "name": "Marci",
            "Pugna": 0.999
        },
        {
            "name": "Mars",
            "Pugna": -1.205
        },
        {
            "name": "Medusa",
            "Pugna": 1.679
        },
        {
            "name": "Meepo",
            "Pugna": 0.269
        },
        {
            "name": "Mirana",
            "Pugna": -0.376
        },
        {
            "name": "Monkey King",
            "Pugna": -0.262
        },
        {
            "name": "Morphling",
            "Pugna": -0.042
        },
        {
            "name": "Muerta",
            "Pugna": -0.873
        },
        {
            "name": "Naga Siren",
            "Pugna": -0.086
        },
        {
            "name": "Nature's Prophet",
            "Pugna": 0.677
        },
        {
            "name": "Necrophos",
            "Pugna": -3.162
        },
        {
            "name": "Night Stalker",
            "Pugna": 1.542
        },
        {
            "name": "Nyx Assassin",
            "Pugna": 1.117
        },
        {
            "name": "Ogre Magi",
            "Pugna": 0.682
        },
        {
            "name": "Omniknight",
            "Pugna": -2.04
        },
        {
            "name": "Oracle",
            "Pugna": -0.739
        },
        {
            "name": "Outworld Destroyer",
            "Pugna": -0.053
        },
        {
            "name": "Pangolier",
            "Pugna": 0.926
        },
        {
            "name": "Phantom Assassin",
            "Pugna": -0.012
        },
        {
            "name": "Phantom Lancer",
            "Pugna": 1.638
        },
        {
            "name": "Phoenix",
            "Pugna": -0.575
        },
        {
            "name": "Primal Beast",
            "Pugna": 1.405
        },
        {
            "name": "Puck",
            "Pugna": -2.102
        },
        {
            "name": "Pudge",
            "Pugna": 2.997
        },
        {
            "name": "Queen of Pain",
            "Pugna": -1.865
        },
        {
            "name": "Razor",
            "Pugna": -0.68
        },
        {
            "name": "Riki",
            "Pugna": 1.158
        },
        {
            "name": "Ringmaster",
            "Pugna": -1.563
        },
        {
            "name": "Rubick",
            "Pugna": -0.512
        },
        {
            "name": "Sand King",
            "Pugna": -0.557
        },
        {
            "name": "Shadow Demon",
            "Pugna": -0.865
        },
        {
            "name": "Shadow Fiend",
            "Pugna": 1.224
        },
        {
            "name": "Shadow Shaman",
            "Pugna": -0.077
        },
        {
            "name": "Silencer",
            "Pugna": 0.882
        },
        {
            "name": "Skywrath Mage",
            "Pugna": -0.458
        },
        {
            "name": "Slardar",
            "Pugna": 1.659
        },
        {
            "name": "Slark",
            "Pugna": -2.058
        },
        {
            "name": "Snapfire",
            "Pugna": 0.583
        },
        {
            "name": "Sniper",
            "Pugna": 1.241
        },
        {
            "name": "Spectre",
            "Pugna": 0.827
        },
        {
            "name": "Spirit Breaker",
            "Pugna": 1.314
        },
        {
            "name": "Storm Spirit",
            "Pugna": -3.83
        },
        {
            "name": "Sven",
            "Pugna": 1.337
        },
        {
            "name": "Techies",
            "Pugna": -0.11
        },
        {
            "name": "Templar Assassin",
            "Pugna": -0.043
        },
        {
            "name": "Terrorblade",
            "Pugna": 0.078
        },
        {
            "name": "Tidehunter",
            "Pugna": -2.961
        },
        {
            "name": "Timbersaw",
            "Pugna": -3.997
        },
        {
            "name": "Tinker",
            "Pugna": -3.091
        },
        {
            "name": "Tiny",
            "Pugna": -0.305
        },
        {
            "name": "Treant Protector",
            "Pugna": 0.025
        },
        {
            "name": "Troll Warlord",
            "Pugna": -1.924
        },
        {
            "name": "Tusk",
            "Pugna": -0.467
        },
        {
            "name": "Underlord",
            "Pugna": -0.938
        },
        {
            "name": "Undying",
            "Pugna": -0.769
        },
        {
            "name": "Ursa",
            "Pugna": 0.984
        },
        {
            "name": "Vengeful Spirit",
            "Pugna": 0.333
        },
        {
            "name": "Venomancer",
            "Pugna": -0.666
        },
        {
            "name": "Viper",
            "Pugna": 0.095
        },
        {
            "name": "Visage",
            "Pugna": 1.319
        },
        {
            "name": "Void Spirit",
            "Pugna": -0.377
        },
        {
            "name": "Warlock",
            "Pugna": -0.008
        },
        {
            "name": "Weaver",
            "Pugna": 0.37
        },
        {
            "name": "Windranger",
            "Pugna": -0.214
        },
        {
            "name": "Winter Wyvern",
            "Pugna": -2.96
        },
        {
            "name": "Witch Doctor",
            "Pugna": -0.239
        },
        {
            "name": "Wraith King",
            "Pugna": 0.399
        },
        {
            "name": "Zeus",
            "Pugna": -0.92
        }
    ],
    "Queen of Pain": [
        {
            "name": "Abaddon",
            "Queen of Pain": 1.155
        },
        {
            "name": "Alchemist",
            "Queen of Pain": -0.459
        },
        {
            "name": "Ancient Apparition",
            "Queen of Pain": -0.59
        },
        {
            "name": "Anti-Mage",
            "Queen of Pain": 2.191
        },
        {
            "name": "Arc Warden",
            "Queen of Pain": 0.059
        },
        {
            "name": "Axe",
            "Queen of Pain": -1.328
        },
        {
            "name": "Bane",
            "Queen of Pain": 0.936
        },
        {
            "name": "Batrider",
            "Queen of Pain": -0.662
        },
        {
            "name": "Beastmaster",
            "Queen of Pain": 0.063
        },
        {
            "name": "Bloodseeker",
            "Queen of Pain": -0.895
        },
        {
            "name": "Bounty Hunter",
            "Queen of Pain": -0.114
        },
        {
            "name": "Brewmaster",
            "Queen of Pain": -0.616
        },
        {
            "name": "Bristleback",
            "Queen of Pain": -0.235
        },
        {
            "name": "Broodmother",
            "Queen of Pain": -1.086
        },
        {
            "name": "Centaur Warrunner",
            "Queen of Pain": -0.749
        },
        {
            "name": "Chaos Knight",
            "Queen of Pain": 0.664
        },
        {
            "name": "Chen",
            "Queen of Pain": 1.495
        },
        {
            "name": "Clinkz",
            "Queen of Pain": 0.671
        },
        {
            "name": "Clockwerk",
            "Queen of Pain": -1.853
        },
        {
            "name": "Crystal Maiden",
            "Queen of Pain": 0.06
        },
        {
            "name": "Dark Seer",
            "Queen of Pain": -2.024
        },
        {
            "name": "Dark Willow",
            "Queen of Pain": -0.502
        },
        {
            "name": "Dawnbreaker",
            "Queen of Pain": -0.515
        },
        {
            "name": "Dazzle",
            "Queen of Pain": 0.351
        },
        {
            "name": "Death Prophet",
            "Queen of Pain": -0.275
        },
        {
            "name": "Disruptor",
            "Queen of Pain": 0.074
        },
        {
            "name": "Doom",
            "Queen of Pain": 0.366
        },
        {
            "name": "Dragon Knight",
            "Queen of Pain": 0.255
        },
        {
            "name": "Drow Ranger",
            "Queen of Pain": -0.175
        },
        {
            "name": "Earth Spirit",
            "Queen of Pain": 1.048
        },
        {
            "name": "Earthshaker",
            "Queen of Pain": 0.005
        },
        {
            "name": "Elder Titan",
            "Queen of Pain": 0.121
        },
        {
            "name": "Ember Spirit",
            "Queen of Pain": 3.608
        },
        {
            "name": "Enchantress",
            "Queen of Pain": -0.232
        },
        {
            "name": "Enigma",
            "Queen of Pain": 0.41
        },
        {
            "name": "Faceless Void",
            "Queen of Pain": 1.918
        },
        {
            "name": "Grimstroke",
            "Queen of Pain": 0.395
        },
        {
            "name": "Gyrocopter",
            "Queen of Pain": -0.288
        },
        {
            "name": "Hoodwink",
            "Queen of Pain": -0.301
        },
        {
            "name": "Huskar",
            "Queen of Pain": 3.336
        },
        {
            "name": "Invoker",
            "Queen of Pain": -0.634
        },
        {
            "name": "Io",
            "Queen of Pain": 0.862
        },
        {
            "name": "Jakiro",
            "Queen of Pain": -0.614
        },
        {
            "name": "Juggernaut",
            "Queen of Pain": -0.693
        },
        {
            "name": "Keeper of the Light",
            "Queen of Pain": -0.738
        },
        {
            "name": "Kez",
            "Queen of Pain": 1.018
        },
        {
            "name": "Kunkka",
            "Queen of Pain": 0.013
        },
        {
            "name": "Legion Commander",
            "Queen of Pain": 1.502
        },
        {
            "name": "Leshrac",
            "Queen of Pain": -2.111
        },
        {
            "name": "Lich",
            "Queen of Pain": -1.222
        },
        {
            "name": "Lifestealer",
            "Queen of Pain": -1.241
        },
        {
            "name": "Lina",
            "Queen of Pain": 0.598
        },
        {
            "name": "Lion",
            "Queen of Pain": 0.577
        },
        {
            "name": "Lone Druid",
            "Queen of Pain": 1.055
        },
        {
            "name": "Luna",
            "Queen of Pain": -0.28
        },
        {
            "name": "Lycan",
            "Queen of Pain": -0.105
        },
        {
            "name": "Magnus",
            "Queen of Pain": -0.466
        },
        {
            "name": "Marci",
            "Queen of Pain": -0.318
        },
        {
            "name": "Mars",
            "Queen of Pain": -2.028
        },
        {
            "name": "Medusa",
            "Queen of Pain": 3.591
        },
        {
            "name": "Meepo",
            "Queen of Pain": 1.515
        },
        {
            "name": "Mirana",
            "Queen of Pain": -0.877
        },
        {
            "name": "Monkey King",
            "Queen of Pain": -1.428
        },
        {
            "name": "Morphling",
            "Queen of Pain": 1.465
        },
        {
            "name": "Muerta",
            "Queen of Pain": -0.109
        },
        {
            "name": "Naga Siren",
            "Queen of Pain": -0.716
        },
        {
            "name": "Nature's Prophet",
            "Queen of Pain": 0.244
        },
        {
            "name": "Necrophos",
            "Queen of Pain": -0.756
        },
        {
            "name": "Night Stalker",
            "Queen of Pain": 0.897
        },
        {
            "name": "Nyx Assassin",
            "Queen of Pain": 0.308
        },
        {
            "name": "Ogre Magi",
            "Queen of Pain": 0.105
        },
        {
            "name": "Omniknight",
            "Queen of Pain": -1.023
        },
        {
            "name": "Oracle",
            "Queen of Pain": 1.211
        },
        {
            "name": "Outworld Destroyer",
            "Queen of Pain": -0.783
        },
        {
            "name": "Pangolier",
            "Queen of Pain": 0.565
        },
        {
            "name": "Phantom Assassin",
            "Queen of Pain": -1.498
        },
        {
            "name": "Phantom Lancer",
            "Queen of Pain": 0.01
        },
        {
            "name": "Phoenix",
            "Queen of Pain": -0.883
        },
        {
            "name": "Primal Beast",
            "Queen of Pain": -0.386
        },
        {
            "name": "Puck",
            "Queen of Pain": 2.42
        },
        {
            "name": "Pudge",
            "Queen of Pain": -0.75
        },
        {
            "name": "Pugna",
            "Queen of Pain": 1.815
        },
        {
            "name": "Razor",
            "Queen of Pain": -3.33
        },
        {
            "name": "Riki",
            "Queen of Pain": 1.725
        },
        {
            "name": "Ringmaster",
            "Queen of Pain": -0.744
        },
        {
            "name": "Rubick",
            "Queen of Pain": 0.119
        },
        {
            "name": "Sand King",
            "Queen of Pain": -1.802
        },
        {
            "name": "Shadow Demon",
            "Queen of Pain": -0.98
        },
        {
            "name": "Shadow Fiend",
            "Queen of Pain": 0.465
        },
        {
            "name": "Shadow Shaman",
            "Queen of Pain": 1.052
        },
        {
            "name": "Silencer",
            "Queen of Pain": 0.506
        },
        {
            "name": "Skywrath Mage",
            "Queen of Pain": 1.298
        },
        {
            "name": "Slardar",
            "Queen of Pain": -0.157
        },
        {
            "name": "Slark",
            "Queen of Pain": 0.27
        },
        {
            "name": "Snapfire",
            "Queen of Pain": -0.76
        },
        {
            "name": "Sniper",
            "Queen of Pain": -0.924
        },
        {
            "name": "Spectre",
            "Queen of Pain": -0.346
        },
        {
            "name": "Spirit Breaker",
            "Queen of Pain": -0.682
        },
        {
            "name": "Storm Spirit",
            "Queen of Pain": -0.33
        },
        {
            "name": "Sven",
            "Queen of Pain": 0.62
        },
        {
            "name": "Techies",
            "Queen of Pain": -0.323
        },
        {
            "name": "Templar Assassin",
            "Queen of Pain": 1.524
        },
        {
            "name": "Terrorblade",
            "Queen of Pain": -0.039
        },
        {
            "name": "Tidehunter",
            "Queen of Pain": -0.115
        },
        {
            "name": "Timbersaw",
            "Queen of Pain": -1.607
        },
        {
            "name": "Tinker",
            "Queen of Pain": 0.344
        },
        {
            "name": "Tiny",
            "Queen of Pain": -0.415
        },
        {
            "name": "Treant Protector",
            "Queen of Pain": -0.142
        },
        {
            "name": "Troll Warlord",
            "Queen of Pain": 0.178
        },
        {
            "name": "Tusk",
            "Queen of Pain": 0.459
        },
        {
            "name": "Underlord",
            "Queen of Pain": -0.28
        },
        {
            "name": "Undying",
            "Queen of Pain": -0.254
        },
        {
            "name": "Ursa",
            "Queen of Pain": 0.493
        },
        {
            "name": "Vengeful Spirit",
            "Queen of Pain": -0.255
        },
        {
            "name": "Venomancer",
            "Queen of Pain": -0.924
        },
        {
            "name": "Viper",
            "Queen of Pain": 0.191
        },
        {
            "name": "Visage",
            "Queen of Pain": -0.652
        },
        {
            "name": "Void Spirit",
            "Queen of Pain": 1.399
        },
        {
            "name": "Warlock",
            "Queen of Pain": -0.689
        },
        {
            "name": "Weaver",
            "Queen of Pain": 0.318
        },
        {
            "name": "Windranger",
            "Queen of Pain": -0.98
        },
        {
            "name": "Winter Wyvern",
            "Queen of Pain": -1.582
        },
        {
            "name": "Witch Doctor",
            "Queen of Pain": 1.104
        },
        {
            "name": "Wraith King",
            "Queen of Pain": -0.401
        },
        {
            "name": "Zeus",
            "Queen of Pain": -0.939
        }
    ],
    "Ringmaster": [
        {
            "name": "Abaddon",
            "Ringmaster": -0.205
        },
        {
            "name": "Alchemist",
            "Ringmaster": 0.383
        },
        {
            "name": "Ancient Apparition",
            "Ringmaster": 0.54
        },
        {
            "name": "Anti-Mage",
            "Ringmaster": 0.957
        },
        {
            "name": "Arc Warden",
            "Ringmaster": -0.469
        },
        {
            "name": "Axe",
            "Ringmaster": -0.99
        },
        {
            "name": "Bane",
            "Ringmaster": 0.655
        },
        {
            "name": "Batrider",
            "Ringmaster": -0.626
        },
        {
            "name": "Beastmaster",
            "Ringmaster": -0.317
        },
        {
            "name": "Bloodseeker",
            "Ringmaster": 1.393
        },
        {
            "name": "Bounty Hunter",
            "Ringmaster": -0.095
        },
        {
            "name": "Brewmaster",
            "Ringmaster": -0.401
        },
        {
            "name": "Bristleback",
            "Ringmaster": 0.212
        },
        {
            "name": "Broodmother",
            "Ringmaster": 1.232
        },
        {
            "name": "Centaur Warrunner",
            "Ringmaster": -0.186
        },
        {
            "name": "Chaos Knight",
            "Ringmaster": -1.558
        },
        {
            "name": "Chen",
            "Ringmaster": 1.55
        },
        {
            "name": "Clinkz",
            "Ringmaster": 0.506
        },
        {
            "name": "Clockwerk",
            "Ringmaster": -1.388
        },
        {
            "name": "Crystal Maiden",
            "Ringmaster": -0.266
        },
        {
            "name": "Dark Seer",
            "Ringmaster": -0.774
        },
        {
            "name": "Dark Willow",
            "Ringmaster": -0.107
        },
        {
            "name": "Dawnbreaker",
            "Ringmaster": -0.497
        },
        {
            "name": "Dazzle",
            "Ringmaster": 0.665
        },
        {
            "name": "Death Prophet",
            "Ringmaster": 0.452
        },
        {
            "name": "Disruptor",
            "Ringmaster": -1.121
        },
        {
            "name": "Doom",
            "Ringmaster": 1.411
        },
        {
            "name": "Dragon Knight",
            "Ringmaster": -0.09
        },
        {
            "name": "Drow Ranger",
            "Ringmaster": -0.305
        },
        {
            "name": "Earth Spirit",
            "Ringmaster": 1.014
        },
        {
            "name": "Earthshaker",
            "Ringmaster": 0.508
        },
        {
            "name": "Elder Titan",
            "Ringmaster": 2.519
        },
        {
            "name": "Ember Spirit",
            "Ringmaster": 0.511
        },
        {
            "name": "Enchantress",
            "Ringmaster": -0.672
        },
        {
            "name": "Enigma",
            "Ringmaster": 0.473
        },
        {
            "name": "Faceless Void",
            "Ringmaster": 0.476
        },
        {
            "name": "Grimstroke",
            "Ringmaster": -0.953
        },
        {
            "name": "Gyrocopter",
            "Ringmaster": 0.265
        },
        {
            "name": "Hoodwink",
            "Ringmaster": 0.188
        },
        {
            "name": "Huskar",
            "Ringmaster": -0.213
        },
        {
            "name": "Invoker",
            "Ringmaster": -0.048
        },
        {
            "name": "Io",
            "Ringmaster": 0.106
        },
        {
            "name": "Jakiro",
            "Ringmaster": -0.161
        },
        {
            "name": "Juggernaut",
            "Ringmaster": 0.906
        },
        {
            "name": "Keeper of the Light",
            "Ringmaster": 1.705
        },
        {
            "name": "Kez",
            "Ringmaster": 0.109
        },
        {
            "name": "Kunkka",
            "Ringmaster": 0.425
        },
        {
            "name": "Legion Commander",
            "Ringmaster": -2.255
        },
        {
            "name": "Leshrac",
            "Ringmaster": 0.258
        },
        {
            "name": "Lich",
            "Ringmaster": -1.563
        },
        {
            "name": "Lifestealer",
            "Ringmaster": 0.697
        },
        {
            "name": "Lina",
            "Ringmaster": -0.5
        },
        {
            "name": "Lion",
            "Ringmaster": -0.04
        },
        {
            "name": "Lone Druid",
            "Ringmaster": 0.123
        },
        {
            "name": "Luna",
            "Ringmaster": 0.038
        },
        {
            "name": "Lycan",
            "Ringmaster": 1.683
        },
        {
            "name": "Magnus",
            "Ringmaster": 0.424
        },
        {
            "name": "Marci",
            "Ringmaster": -0.369
        },
        {
            "name": "Mars",
            "Ringmaster": -0.392
        },
        {
            "name": "Medusa",
            "Ringmaster": -0.069
        },
        {
            "name": "Meepo",
            "Ringmaster": -2.927
        },
        {
            "name": "Mirana",
            "Ringmaster": -0.109
        },
        {
            "name": "Monkey King",
            "Ringmaster": -0.363
        },
        {
            "name": "Morphling",
            "Ringmaster": -0.064
        },
        {
            "name": "Muerta",
            "Ringmaster": -0.406
        },
        {
            "name": "Naga Siren",
            "Ringmaster": 0.023
        },
        {
            "name": "Nature's Prophet",
            "Ringmaster": 0.743
        },
        {
            "name": "Necrophos",
            "Ringmaster": -0.398
        },
        {
            "name": "Night Stalker",
            "Ringmaster": 0.794
        },
        {
            "name": "Nyx Assassin",
            "Ringmaster": 1.37
        },
        {
            "name": "Ogre Magi",
            "Ringmaster": 0.077
        },
        {
            "name": "Omniknight",
            "Ringmaster": -0.237
        },
        {
            "name": "Oracle",
            "Ringmaster": 0.913
        },
        {
            "name": "Outworld Destroyer",
            "Ringmaster": 0.226
        },
        {
            "name": "Pangolier",
            "Ringmaster": 1.725
        },
        {
            "name": "Phantom Assassin",
            "Ringmaster": 0.504
        },
        {
            "name": "Phantom Lancer",
            "Ringmaster": 1.151
        },
        {
            "name": "Phoenix",
            "Ringmaster": 0.866
        },
        {
            "name": "Primal Beast",
            "Ringmaster": 0.166
        },
        {
            "name": "Puck",
            "Ringmaster": 0.868
        },
        {
            "name": "Pudge",
            "Ringmaster": -1.35
        },
        {
            "name": "Pugna",
            "Ringmaster": 1.566
        },
        {
            "name": "Queen of Pain",
            "Ringmaster": 1.055
        },
        {
            "name": "Razor",
            "Ringmaster": -0.91
        },
        {
            "name": "Riki",
            "Ringmaster": 1.413
        },
        {
            "name": "Rubick",
            "Ringmaster": -0.185
        },
        {
            "name": "Sand King",
            "Ringmaster": -1.102
        },
        {
            "name": "Shadow Demon",
            "Ringmaster": 0.947
        },
        {
            "name": "Shadow Fiend",
            "Ringmaster": 0.242
        },
        {
            "name": "Shadow Shaman",
            "Ringmaster": -0.838
        },
        {
            "name": "Silencer",
            "Ringmaster": -0.068
        },
        {
            "name": "Skywrath Mage",
            "Ringmaster": -1.209
        },
        {
            "name": "Slardar",
            "Ringmaster": 0.277
        },
        {
            "name": "Slark",
            "Ringmaster": -2.285
        },
        {
            "name": "Snapfire",
            "Ringmaster": -0.046
        },
        {
            "name": "Sniper",
            "Ringmaster": 0.123
        },
        {
            "name": "Spectre",
            "Ringmaster": -0.524
        },
        {
            "name": "Spirit Breaker",
            "Ringmaster": -0.139
        },
        {
            "name": "Storm Spirit",
            "Ringmaster": 0.172
        },
        {
            "name": "Sven",
            "Ringmaster": 0.475
        },
        {
            "name": "Techies",
            "Ringmaster": 0.653
        },
        {
            "name": "Templar Assassin",
            "Ringmaster": -0.404
        },
        {
            "name": "Terrorblade",
            "Ringmaster": -1.585
        },
        {
            "name": "Tidehunter",
            "Ringmaster": -0.94
        },
        {
            "name": "Timbersaw",
            "Ringmaster": 1.062
        },
        {
            "name": "Tinker",
            "Ringmaster": 1.795
        },
        {
            "name": "Tiny",
            "Ringmaster": -0.136
        },
        {
            "name": "Treant Protector",
            "Ringmaster": -0.363
        },
        {
            "name": "Troll Warlord",
            "Ringmaster": -2.461
        },
        {
            "name": "Tusk",
            "Ringmaster": -1.085
        },
        {
            "name": "Underlord",
            "Ringmaster": -0.353
        },
        {
            "name": "Undying",
            "Ringmaster": 1.124
        },
        {
            "name": "Ursa",
            "Ringmaster": 0.217
        },
        {
            "name": "Vengeful Spirit",
            "Ringmaster": -0.28
        },
        {
            "name": "Venomancer",
            "Ringmaster": 0.927
        },
        {
            "name": "Viper",
            "Ringmaster": -0.207
        },
        {
            "name": "Visage",
            "Ringmaster": 0.306
        },
        {
            "name": "Void Spirit",
            "Ringmaster": -0.458
        },
        {
            "name": "Warlock",
            "Ringmaster": -0.193
        },
        {
            "name": "Weaver",
            "Ringmaster": 0.511
        },
        {
            "name": "Windranger",
            "Ringmaster": 0.092
        },
        {
            "name": "Winter Wyvern",
            "Ringmaster": -0.227
        },
        {
            "name": "Witch Doctor",
            "Ringmaster": -0.726
        },
        {
            "name": "Wraith King",
            "Ringmaster": 0.931
        },
        {
            "name": "Zeus",
            "Ringmaster": 0.357
        }
    ],
    "Rubick": [
        {
            "name": "Abaddon",
            "Rubick": -2.312
        },
        {
            "name": "Alchemist",
            "Rubick": 0.171
        },
        {
            "name": "Ancient Apparition",
            "Rubick": -0.253
        },
        {
            "name": "Anti-Mage",
            "Rubick": 0.064
        },
        {
            "name": "Arc Warden",
            "Rubick": 0.103
        },
        {
            "name": "Axe",
            "Rubick": 1.124
        },
        {
            "name": "Bane",
            "Rubick": 0.576
        },
        {
            "name": "Batrider",
            "Rubick": 1.158
        },
        {
            "name": "Beastmaster",
            "Rubick": -0.618
        },
        {
            "name": "Bloodseeker",
            "Rubick": -0.019
        },
        {
            "name": "Bounty Hunter",
            "Rubick": 0.331
        },
        {
            "name": "Brewmaster",
            "Rubick": -0.035
        },
        {
            "name": "Bristleback",
            "Rubick": 0.977
        },
        {
            "name": "Broodmother",
            "Rubick": -0.438
        },
        {
            "name": "Centaur Warrunner",
            "Rubick": 0.328
        },
        {
            "name": "Chaos Knight",
            "Rubick": -0.233
        },
        {
            "name": "Chen",
            "Rubick": 1.232
        },
        {
            "name": "Clinkz",
            "Rubick": 0.953
        },
        {
            "name": "Clockwerk",
            "Rubick": 0.119
        },
        {
            "name": "Crystal Maiden",
            "Rubick": -2.273
        },
        {
            "name": "Dark Seer",
            "Rubick": -1.767
        },
        {
            "name": "Dark Willow",
            "Rubick": 0.136
        },
        {
            "name": "Dawnbreaker",
            "Rubick": -0.701
        },
        {
            "name": "Dazzle",
            "Rubick": 1.277
        },
        {
            "name": "Death Prophet",
            "Rubick": 0.057
        },
        {
            "name": "Disruptor",
            "Rubick": -0.117
        },
        {
            "name": "Doom",
            "Rubick": -0.975
        },
        {
            "name": "Dragon Knight",
            "Rubick": -0.692
        },
        {
            "name": "Drow Ranger",
            "Rubick": 0.918
        },
        {
            "name": "Earth Spirit",
            "Rubick": 1.917
        },
        {
            "name": "Earthshaker",
            "Rubick": 0.557
        },
        {
            "name": "Elder Titan",
            "Rubick": 1.353
        },
        {
            "name": "Ember Spirit",
            "Rubick": 1.256
        },
        {
            "name": "Enchantress",
            "Rubick": 1.12
        },
        {
            "name": "Enigma",
            "Rubick": -4.161
        },
        {
            "name": "Faceless Void",
            "Rubick": -0.296
        },
        {
            "name": "Grimstroke",
            "Rubick": 0.034
        },
        {
            "name": "Gyrocopter",
            "Rubick": 0.226
        },
        {
            "name": "Hoodwink",
            "Rubick": 1.366
        },
        {
            "name": "Huskar",
            "Rubick": 0.781
        },
        {
            "name": "Invoker",
            "Rubick": 0.165
        },
        {
            "name": "Io",
            "Rubick": 0.257
        },
        {
            "name": "Jakiro",
            "Rubick": -4.595
        },
        {
            "name": "Juggernaut",
            "Rubick": 0.055
        },
        {
            "name": "Keeper of the Light",
            "Rubick": 1.615
        },
        {
            "name": "Kez",
            "Rubick": 1.637
        },
        {
            "name": "Kunkka",
            "Rubick": -1.231
        },
        {
            "name": "Legion Commander",
            "Rubick": 1.113
        },
        {
            "name": "Leshrac",
            "Rubick": 0.275
        },
        {
            "name": "Lich",
            "Rubick": -0.239
        },
        {
            "name": "Lifestealer",
            "Rubick": 0.381
        },
        {
            "name": "Lina",
            "Rubick": -0.159
        },
        {
            "name": "Lion",
            "Rubick": -1.344
        },
        {
            "name": "Lone Druid",
            "Rubick": -0.12
        },
        {
            "name": "Luna",
            "Rubick": -0.924
        },
        {
            "name": "Lycan",
            "Rubick": 1.218
        },
        {
            "name": "Magnus",
            "Rubick": -1.536
        },
        {
            "name": "Marci",
            "Rubick": 0.385
        },
        {
            "name": "Mars",
            "Rubick": 0.824
        },
        {
            "name": "Medusa",
            "Rubick": -1.487
        },
        {
            "name": "Meepo",
            "Rubick": -0.44
        },
        {
            "name": "Mirana",
            "Rubick": -1.049
        },
        {
            "name": "Monkey King",
            "Rubick": 0.188
        },
        {
            "name": "Morphling",
            "Rubick": -1.829
        },
        {
            "name": "Muerta",
            "Rubick": -1.514
        },
        {
            "name": "Naga Siren",
            "Rubick": -0.699
        },
        {
            "name": "Nature's Prophet",
            "Rubick": 0.423
        },
        {
            "name": "Necrophos",
            "Rubick": -0.302
        },
        {
            "name": "Night Stalker",
            "Rubick": 0.339
        },
        {
            "name": "Nyx Assassin",
            "Rubick": -0.239
        },
        {
            "name": "Ogre Magi",
            "Rubick": 0.11
        },
        {
            "name": "Omniknight",
            "Rubick": 0.127
        },
        {
            "name": "Oracle",
            "Rubick": 0.937
        },
        {
            "name": "Outworld Destroyer",
            "Rubick": -2.449
        },
        {
            "name": "Pangolier",
            "Rubick": 0.906
        },
        {
            "name": "Phantom Assassin",
            "Rubick": 1.178
        },
        {
            "name": "Phantom Lancer",
            "Rubick": -1.875
        },
        {
            "name": "Phoenix",
            "Rubick": 1.065
        },
        {
            "name": "Primal Beast",
            "Rubick": 0.786
        },
        {
            "name": "Puck",
            "Rubick": -0.95
        },
        {
            "name": "Pudge",
            "Rubick": 1.094
        },
        {
            "name": "Pugna",
            "Rubick": 0.405
        },
        {
            "name": "Queen of Pain",
            "Rubick": -0.159
        },
        {
            "name": "Razor",
            "Rubick": 1.169
        },
        {
            "name": "Riki",
            "Rubick": 0.465
        },
        {
            "name": "Ringmaster",
            "Rubick": 0.279
        },
        {
            "name": "Sand King",
            "Rubick": -0.067
        },
        {
            "name": "Shadow Demon",
            "Rubick": 0.622
        },
        {
            "name": "Shadow Fiend",
            "Rubick": 0.208
        },
        {
            "name": "Shadow Shaman",
            "Rubick": -0.693
        },
        {
            "name": "Silencer",
            "Rubick": -0.07
        },
        {
            "name": "Skywrath Mage",
            "Rubick": 0.652
        },
        {
            "name": "Slardar",
            "Rubick": 0.68
        },
        {
            "name": "Slark",
            "Rubick": 0.341
        },
        {
            "name": "Snapfire",
            "Rubick": 0.357
        },
        {
            "name": "Sniper",
            "Rubick": 0.034
        },
        {
            "name": "Spectre",
            "Rubick": 0.133
        },
        {
            "name": "Spirit Breaker",
            "Rubick": 0.331
        },
        {
            "name": "Storm Spirit",
            "Rubick": 0.648
        },
        {
            "name": "Sven",
            "Rubick": -0.553
        },
        {
            "name": "Techies",
            "Rubick": -0.31
        },
        {
            "name": "Templar Assassin",
            "Rubick": 2.404
        },
        {
            "name": "Terrorblade",
            "Rubick": -0.13
        },
        {
            "name": "Tidehunter",
            "Rubick": -0.698
        },
        {
            "name": "Timbersaw",
            "Rubick": 0.506
        },
        {
            "name": "Tinker",
            "Rubick": 0.037
        },
        {
            "name": "Tiny",
            "Rubick": -0.724
        },
        {
            "name": "Treant Protector",
            "Rubick": 0.117
        },
        {
            "name": "Troll Warlord",
            "Rubick": -1.166
        },
        {
            "name": "Tusk",
            "Rubick": 1.483
        },
        {
            "name": "Underlord",
            "Rubick": -5.801
        },
        {
            "name": "Undying",
            "Rubick": -1.409
        },
        {
            "name": "Ursa",
            "Rubick": 1.367
        },
        {
            "name": "Vengeful Spirit",
            "Rubick": -0.402
        },
        {
            "name": "Venomancer",
            "Rubick": -0.196
        },
        {
            "name": "Viper",
            "Rubick": -0.322
        },
        {
            "name": "Visage",
            "Rubick": 0.181
        },
        {
            "name": "Void Spirit",
            "Rubick": -0.241
        },
        {
            "name": "Warlock",
            "Rubick": -0.353
        },
        {
            "name": "Weaver",
            "Rubick": -2.213
        },
        {
            "name": "Windranger",
            "Rubick": 0.128
        },
        {
            "name": "Winter Wyvern",
            "Rubick": -0.343
        },
        {
            "name": "Witch Doctor",
            "Rubick": -0.843
        },
        {
            "name": "Wraith King",
            "Rubick": -1.755
        },
        {
            "name": "Zeus",
            "Rubick": -0.459
        }
    ],
    "Shadow Demon": [
        {
            "name": "Abaddon",
            "Shadow Demon": -4.06
        },
        {
            "name": "Alchemist",
            "Shadow Demon": -1.074
        },
        {
            "name": "Ancient Apparition",
            "Shadow Demon": -0.96
        },
        {
            "name": "Anti-Mage",
            "Shadow Demon": 1.325
        },
        {
            "name": "Arc Warden",
            "Shadow Demon": 0.641
        },
        {
            "name": "Axe",
            "Shadow Demon": 0.713
        },
        {
            "name": "Bane",
            "Shadow Demon": -0.676
        },
        {
            "name": "Batrider",
            "Shadow Demon": -1.918
        },
        {
            "name": "Beastmaster",
            "Shadow Demon": 0.625
        },
        {
            "name": "Bloodseeker",
            "Shadow Demon": 0.113
        },
        {
            "name": "Bounty Hunter",
            "Shadow Demon": 0.928
        },
        {
            "name": "Brewmaster",
            "Shadow Demon": 1.421
        },
        {
            "name": "Bristleback",
            "Shadow Demon": -0.45
        },
        {
            "name": "Broodmother",
            "Shadow Demon": -0.405
        },
        {
            "name": "Centaur Warrunner",
            "Shadow Demon": 0.092
        },
        {
            "name": "Chaos Knight",
            "Shadow Demon": 2.12
        },
        {
            "name": "Chen",
            "Shadow Demon": 3.524
        },
        {
            "name": "Clinkz",
            "Shadow Demon": 0.381
        },
        {
            "name": "Clockwerk",
            "Shadow Demon": -1.383
        },
        {
            "name": "Crystal Maiden",
            "Shadow Demon": 1.194
        },
        {
            "name": "Dark Seer",
            "Shadow Demon": -0.324
        },
        {
            "name": "Dark Willow",
            "Shadow Demon": -0.419
        },
        {
            "name": "Dawnbreaker",
            "Shadow Demon": 1.674
        },
        {
            "name": "Dazzle",
            "Shadow Demon": -0.006
        },
        {
            "name": "Death Prophet",
            "Shadow Demon": -0.989
        },
        {
            "name": "Disruptor",
            "Shadow Demon": -2.328
        },
        {
            "name": "Doom",
            "Shadow Demon": 0.656
        },
        {
            "name": "Dragon Knight",
            "Shadow Demon": 0.06
        },
        {
            "name": "Drow Ranger",
            "Shadow Demon": -0.962
        },
        {
            "name": "Earth Spirit",
            "Shadow Demon": 0.757
        },
        {
            "name": "Earthshaker",
            "Shadow Demon": 1.456
        },
        {
            "name": "Elder Titan",
            "Shadow Demon": -0.438
        },
        {
            "name": "Ember Spirit",
            "Shadow Demon": 1.024
        },
        {
            "name": "Enchantress",
            "Shadow Demon": 0.718
        },
        {
            "name": "Enigma",
            "Shadow Demon": -0.849
        },
        {
            "name": "Faceless Void",
            "Shadow Demon": 1.04
        },
        {
            "name": "Grimstroke",
            "Shadow Demon": -1.853
        },
        {
            "name": "Gyrocopter",
            "Shadow Demon": -1.37
        },
        {
            "name": "Hoodwink",
            "Shadow Demon": -0.318
        },
        {
            "name": "Huskar",
            "Shadow Demon": -1.73
        },
        {
            "name": "Invoker",
            "Shadow Demon": -0.988
        },
        {
            "name": "Io",
            "Shadow Demon": 1.744
        },
        {
            "name": "Jakiro",
            "Shadow Demon": 0.254
        },
        {
            "name": "Juggernaut",
            "Shadow Demon": -0.553
        },
        {
            "name": "Keeper of the Light",
            "Shadow Demon": 0.842
        },
        {
            "name": "Kez",
            "Shadow Demon": 1.541
        },
        {
            "name": "Kunkka",
            "Shadow Demon": -0.195
        },
        {
            "name": "Legion Commander",
            "Shadow Demon": -2.705
        },
        {
            "name": "Leshrac",
            "Shadow Demon": 0.706
        },
        {
            "name": "Lich",
            "Shadow Demon": -0.852
        },
        {
            "name": "Lifestealer",
            "Shadow Demon": 0.846
        },
        {
            "name": "Lina",
            "Shadow Demon": 0.703
        },
        {
            "name": "Lion",
            "Shadow Demon": -0.531
        },
        {
            "name": "Lone Druid",
            "Shadow Demon": 3.072
        },
        {
            "name": "Luna",
            "Shadow Demon": -0.843
        },
        {
            "name": "Lycan",
            "Shadow Demon": 0.818
        },
        {
            "name": "Magnus",
            "Shadow Demon": 1.262
        },
        {
            "name": "Marci",
            "Shadow Demon": 2.062
        },
        {
            "name": "Mars",
            "Shadow Demon": 1.036
        },
        {
            "name": "Medusa",
            "Shadow Demon": -3.956
        },
        {
            "name": "Meepo",
            "Shadow Demon": 3.803
        },
        {
            "name": "Mirana",
            "Shadow Demon": -1.465
        },
        {
            "name": "Monkey King",
            "Shadow Demon": -0.61
        },
        {
            "name": "Morphling",
            "Shadow Demon": 1.308
        },
        {
            "name": "Muerta",
            "Shadow Demon": -0.7
        },
        {
            "name": "Naga Siren",
            "Shadow Demon": 1.166
        },
        {
            "name": "Nature's Prophet",
            "Shadow Demon": -0.277
        },
        {
            "name": "Necrophos",
            "Shadow Demon": -2.7
        },
        {
            "name": "Night Stalker",
            "Shadow Demon": 0.25
        },
        {
            "name": "Nyx Assassin",
            "Shadow Demon": 1.116
        },
        {
            "name": "Ogre Magi",
            "Shadow Demon": -0.636
        },
        {
            "name": "Omniknight",
            "Shadow Demon": -3.652
        },
        {
            "name": "Oracle",
            "Shadow Demon": -2.241
        },
        {
            "name": "Outworld Destroyer",
            "Shadow Demon": 0.108
        },
        {
            "name": "Pangolier",
            "Shadow Demon": 0.157
        },
        {
            "name": "Phantom Assassin",
            "Shadow Demon": 1.105
        },
        {
            "name": "Phantom Lancer",
            "Shadow Demon": 1.876
        },
        {
            "name": "Phoenix",
            "Shadow Demon": 1.45
        },
        {
            "name": "Primal Beast",
            "Shadow Demon": -0.468
        },
        {
            "name": "Puck",
            "Shadow Demon": 0.948
        },
        {
            "name": "Pudge",
            "Shadow Demon": 0.493
        },
        {
            "name": "Pugna",
            "Shadow Demon": 0.832
        },
        {
            "name": "Queen of Pain",
            "Shadow Demon": 1.262
        },
        {
            "name": "Razor",
            "Shadow Demon": 1.636
        },
        {
            "name": "Riki",
            "Shadow Demon": 1.799
        },
        {
            "name": "Ringmaster",
            "Shadow Demon": -0.978
        },
        {
            "name": "Rubick",
            "Shadow Demon": -0.738
        },
        {
            "name": "Sand King",
            "Shadow Demon": 2.647
        },
        {
            "name": "Shadow Fiend",
            "Shadow Demon": -1.02
        },
        {
            "name": "Shadow Shaman",
            "Shadow Demon": 0.36
        },
        {
            "name": "Silencer",
            "Shadow Demon": -1.431
        },
        {
            "name": "Skywrath Mage",
            "Shadow Demon": -1.293
        },
        {
            "name": "Slardar",
            "Shadow Demon": -1.102
        },
        {
            "name": "Slark",
            "Shadow Demon": -1.539
        },
        {
            "name": "Snapfire",
            "Shadow Demon": 0.633
        },
        {
            "name": "Sniper",
            "Shadow Demon": -0.861
        },
        {
            "name": "Spectre",
            "Shadow Demon": 0.004
        },
        {
            "name": "Spirit Breaker",
            "Shadow Demon": 0.737
        },
        {
            "name": "Storm Spirit",
            "Shadow Demon": 1.269
        },
        {
            "name": "Sven",
            "Shadow Demon": 0.64
        },
        {
            "name": "Techies",
            "Shadow Demon": -0.51
        },
        {
            "name": "Templar Assassin",
            "Shadow Demon": -0.175
        },
        {
            "name": "Terrorblade",
            "Shadow Demon": 0.854
        },
        {
            "name": "Tidehunter",
            "Shadow Demon": 0.849
        },
        {
            "name": "Timbersaw",
            "Shadow Demon": 2.802
        },
        {
            "name": "Tinker",
            "Shadow Demon": 0.335
        },
        {
            "name": "Tiny",
            "Shadow Demon": 1.01
        },
        {
            "name": "Treant Protector",
            "Shadow Demon": 0.022
        },
        {
            "name": "Troll Warlord",
            "Shadow Demon": -0.242
        },
        {
            "name": "Tusk",
            "Shadow Demon": 1.571
        },
        {
            "name": "Underlord",
            "Shadow Demon": 1.247
        },
        {
            "name": "Undying",
            "Shadow Demon": 0.485
        },
        {
            "name": "Ursa",
            "Shadow Demon": -0.486
        },
        {
            "name": "Vengeful Spirit",
            "Shadow Demon": 0.662
        },
        {
            "name": "Venomancer",
            "Shadow Demon": -0.674
        },
        {
            "name": "Viper",
            "Shadow Demon": -1.763
        },
        {
            "name": "Visage",
            "Shadow Demon": 0.265
        },
        {
            "name": "Void Spirit",
            "Shadow Demon": 1.476
        },
        {
            "name": "Warlock",
            "Shadow Demon": 0.914
        },
        {
            "name": "Weaver",
            "Shadow Demon": 1.827
        },
        {
            "name": "Windranger",
            "Shadow Demon": 0.061
        },
        {
            "name": "Winter Wyvern",
            "Shadow Demon": 0.811
        },
        {
            "name": "Witch Doctor",
            "Shadow Demon": -0.484
        },
        {
            "name": "Wraith King",
            "Shadow Demon": 0.179
        },
        {
            "name": "Zeus",
            "Shadow Demon": -0.492
        }
    ],
    "Shadow Shaman": [
        {
            "name": "Abaddon",
            "Shadow Shaman": 0.383
        },
        {
            "name": "Alchemist",
            "Shadow Shaman": 0.855
        },
        {
            "name": "Ancient Apparition",
            "Shadow Shaman": -0.07
        },
        {
            "name": "Anti-Mage",
            "Shadow Shaman": -1.082
        },
        {
            "name": "Arc Warden",
            "Shadow Shaman": 1.456
        },
        {
            "name": "Axe",
            "Shadow Shaman": 0.766
        },
        {
            "name": "Bane",
            "Shadow Shaman": -0.581
        },
        {
            "name": "Batrider",
            "Shadow Shaman": 1.009
        },
        {
            "name": "Beastmaster",
            "Shadow Shaman": 1.32
        },
        {
            "name": "Bloodseeker",
            "Shadow Shaman": 0.204
        },
        {
            "name": "Bounty Hunter",
            "Shadow Shaman": -1.942
        },
        {
            "name": "Brewmaster",
            "Shadow Shaman": 0.574
        },
        {
            "name": "Bristleback",
            "Shadow Shaman": -1.25
        },
        {
            "name": "Broodmother",
            "Shadow Shaman": -0.397
        },
        {
            "name": "Centaur Warrunner",
            "Shadow Shaman": 0.343
        },
        {
            "name": "Chaos Knight",
            "Shadow Shaman": 1.424
        },
        {
            "name": "Chen",
            "Shadow Shaman": 0.956
        },
        {
            "name": "Clinkz",
            "Shadow Shaman": 0.221
        },
        {
            "name": "Clockwerk",
            "Shadow Shaman": 0.423
        },
        {
            "name": "Crystal Maiden",
            "Shadow Shaman": -0.414
        },
        {
            "name": "Dark Seer",
            "Shadow Shaman": 1.608
        },
        {
            "name": "Dark Willow",
            "Shadow Shaman": 0.453
        },
        {
            "name": "Dawnbreaker",
            "Shadow Shaman": 1.09
        },
        {
            "name": "Dazzle",
            "Shadow Shaman": -0.134
        },
        {
            "name": "Death Prophet",
            "Shadow Shaman": 0.321
        },
        {
            "name": "Disruptor",
            "Shadow Shaman": -0.154
        },
        {
            "name": "Doom",
            "Shadow Shaman": 0.823
        },
        {
            "name": "Dragon Knight",
            "Shadow Shaman": 0.372
        },
        {
            "name": "Drow Ranger",
            "Shadow Shaman": 1.223
        },
        {
            "name": "Earth Spirit",
            "Shadow Shaman": -0.35
        },
        {
            "name": "Earthshaker",
            "Shadow Shaman": 0.165
        },
        {
            "name": "Elder Titan",
            "Shadow Shaman": 0.371
        },
        {
            "name": "Ember Spirit",
            "Shadow Shaman": -0.251
        },
        {
            "name": "Enchantress",
            "Shadow Shaman": 1.34
        },
        {
            "name": "Enigma",
            "Shadow Shaman": 0.828
        },
        {
            "name": "Faceless Void",
            "Shadow Shaman": -0.371
        },
        {
            "name": "Grimstroke",
            "Shadow Shaman": 0.387
        },
        {
            "name": "Gyrocopter",
            "Shadow Shaman": 1.286
        },
        {
            "name": "Hoodwink",
            "Shadow Shaman": 0.939
        },
        {
            "name": "Huskar",
            "Shadow Shaman": -1.206
        },
        {
            "name": "Invoker",
            "Shadow Shaman": 0.379
        },
        {
            "name": "Io",
            "Shadow Shaman": -0.076
        },
        {
            "name": "Jakiro",
            "Shadow Shaman": 0.683
        },
        {
            "name": "Juggernaut",
            "Shadow Shaman": -0.755
        },
        {
            "name": "Keeper of the Light",
            "Shadow Shaman": 0.085
        },
        {
            "name": "Kez",
            "Shadow Shaman": -0.907
        },
        {
            "name": "Kunkka",
            "Shadow Shaman": 1.032
        },
        {
            "name": "Legion Commander",
            "Shadow Shaman": -0.496
        },
        {
            "name": "Leshrac",
            "Shadow Shaman": 0.626
        },
        {
            "name": "Lich",
            "Shadow Shaman": -0.686
        },
        {
            "name": "Lifestealer",
            "Shadow Shaman": -0.648
        },
        {
            "name": "Lina",
            "Shadow Shaman": 0.397
        },
        {
            "name": "Lion",
            "Shadow Shaman": -0.328
        },
        {
            "name": "Lone Druid",
            "Shadow Shaman": 1.909
        },
        {
            "name": "Luna",
            "Shadow Shaman": 1.928
        },
        {
            "name": "Lycan",
            "Shadow Shaman": 0.089
        },
        {
            "name": "Magnus",
            "Shadow Shaman": -0.279
        },
        {
            "name": "Marci",
            "Shadow Shaman": 0.757
        },
        {
            "name": "Mars",
            "Shadow Shaman": 0.776
        },
        {
            "name": "Medusa",
            "Shadow Shaman": 1.973
        },
        {
            "name": "Meepo",
            "Shadow Shaman": 1.989
        },
        {
            "name": "Mirana",
            "Shadow Shaman": 0.436
        },
        {
            "name": "Monkey King",
            "Shadow Shaman": 0.659
        },
        {
            "name": "Morphling",
            "Shadow Shaman": -0.729
        },
        {
            "name": "Muerta",
            "Shadow Shaman": 0.801
        },
        {
            "name": "Naga Siren",
            "Shadow Shaman": 1.306
        },
        {
            "name": "Nature's Prophet",
            "Shadow Shaman": 0.071
        },
        {
            "name": "Necrophos",
            "Shadow Shaman": 1.231
        },
        {
            "name": "Night Stalker",
            "Shadow Shaman": -0.651
        },
        {
            "name": "Nyx Assassin",
            "Shadow Shaman": -0.82
        },
        {
            "name": "Ogre Magi",
            "Shadow Shaman": 0.096
        },
        {
            "name": "Omniknight",
            "Shadow Shaman": 1.681
        },
        {
            "name": "Oracle",
            "Shadow Shaman": -1.66
        },
        {
            "name": "Outworld Destroyer",
            "Shadow Shaman": -1.071
        },
        {
            "name": "Pangolier",
            "Shadow Shaman": 0.705
        },
        {
            "name": "Phantom Assassin",
            "Shadow Shaman": 0.2
        },
        {
            "name": "Phantom Lancer",
            "Shadow Shaman": 3.454
        },
        {
            "name": "Phoenix",
            "Shadow Shaman": -0.024
        },
        {
            "name": "Primal Beast",
            "Shadow Shaman": 0.132
        },
        {
            "name": "Puck",
            "Shadow Shaman": -1.261
        },
        {
            "name": "Pudge",
            "Shadow Shaman": 0.999
        },
        {
            "name": "Pugna",
            "Shadow Shaman": 0.46
        },
        {
            "name": "Queen of Pain",
            "Shadow Shaman": -0.941
        },
        {
            "name": "Razor",
            "Shadow Shaman": 1.52
        },
        {
            "name": "Riki",
            "Shadow Shaman": -0.16
        },
        {
            "name": "Ringmaster",
            "Shadow Shaman": 1.331
        },
        {
            "name": "Rubick",
            "Shadow Shaman": 0.658
        },
        {
            "name": "Sand King",
            "Shadow Shaman": 1.708
        },
        {
            "name": "Shadow Demon",
            "Shadow Shaman": 0.236
        },
        {
            "name": "Shadow Fiend",
            "Shadow Shaman": -0.238
        },
        {
            "name": "Silencer",
            "Shadow Shaman": 1.212
        },
        {
            "name": "Skywrath Mage",
            "Shadow Shaman": -0.472
        },
        {
            "name": "Slardar",
            "Shadow Shaman": -0.907
        },
        {
            "name": "Slark",
            "Shadow Shaman": -0.288
        },
        {
            "name": "Snapfire",
            "Shadow Shaman": 1.082
        },
        {
            "name": "Sniper",
            "Shadow Shaman": 0.98
        },
        {
            "name": "Spectre",
            "Shadow Shaman": 1.127
        },
        {
            "name": "Spirit Breaker",
            "Shadow Shaman": 0.375
        },
        {
            "name": "Storm Spirit",
            "Shadow Shaman": -1.354
        },
        {
            "name": "Sven",
            "Shadow Shaman": -0.679
        },
        {
            "name": "Techies",
            "Shadow Shaman": 0.62
        },
        {
            "name": "Templar Assassin",
            "Shadow Shaman": 0.285
        },
        {
            "name": "Terrorblade",
            "Shadow Shaman": 2.207
        },
        {
            "name": "Tidehunter",
            "Shadow Shaman": 0.448
        },
        {
            "name": "Timbersaw",
            "Shadow Shaman": 0.402
        },
        {
            "name": "Tinker",
            "Shadow Shaman": -1.5
        },
        {
            "name": "Tiny",
            "Shadow Shaman": 0.496
        },
        {
            "name": "Treant Protector",
            "Shadow Shaman": 0.933
        },
        {
            "name": "Troll Warlord",
            "Shadow Shaman": -1.362
        },
        {
            "name": "Tusk",
            "Shadow Shaman": 0.806
        },
        {
            "name": "Underlord",
            "Shadow Shaman": 0.359
        },
        {
            "name": "Undying",
            "Shadow Shaman": 0.193
        },
        {
            "name": "Ursa",
            "Shadow Shaman": -2.395
        },
        {
            "name": "Vengeful Spirit",
            "Shadow Shaman": 0.45
        },
        {
            "name": "Venomancer",
            "Shadow Shaman": 0.142
        },
        {
            "name": "Viper",
            "Shadow Shaman": -0.365
        },
        {
            "name": "Visage",
            "Shadow Shaman": 0.809
        },
        {
            "name": "Void Spirit",
            "Shadow Shaman": -0.578
        },
        {
            "name": "Warlock",
            "Shadow Shaman": 1.214
        },
        {
            "name": "Weaver",
            "Shadow Shaman": -0.94
        },
        {
            "name": "Windranger",
            "Shadow Shaman": 0.988
        },
        {
            "name": "Winter Wyvern",
            "Shadow Shaman": 0.388
        },
        {
            "name": "Witch Doctor",
            "Shadow Shaman": 0.574
        },
        {
            "name": "Wraith King",
            "Shadow Shaman": 1.049
        },
        {
            "name": "Zeus",
            "Shadow Shaman": -0.227
        }
    ],
    "Silencer": [
        {
            "name": "Abaddon",
            "Silencer": 3.131
        },
        {
            "name": "Alchemist",
            "Silencer": 0.825
        },
        {
            "name": "Ancient Apparition",
            "Silencer": -0.874
        },
        {
            "name": "Anti-Mage",
            "Silencer": 1.241
        },
        {
            "name": "Arc Warden",
            "Silencer": 3.436
        },
        {
            "name": "Axe",
            "Silencer": -1.982
        },
        {
            "name": "Bane",
            "Silencer": -1.397
        },
        {
            "name": "Batrider",
            "Silencer": 0.61
        },
        {
            "name": "Beastmaster",
            "Silencer": 0.444
        },
        {
            "name": "Bloodseeker",
            "Silencer": -0.824
        },
        {
            "name": "Bounty Hunter",
            "Silencer": 0.305
        },
        {
            "name": "Brewmaster",
            "Silencer": 0.642
        },
        {
            "name": "Bristleback",
            "Silencer": -1.611
        },
        {
            "name": "Broodmother",
            "Silencer": 3.252
        },
        {
            "name": "Centaur Warrunner",
            "Silencer": -0.076
        },
        {
            "name": "Chaos Knight",
            "Silencer": 0.64
        },
        {
            "name": "Chen",
            "Silencer": 2.801
        },
        {
            "name": "Clinkz",
            "Silencer": -0.642
        },
        {
            "name": "Clockwerk",
            "Silencer": 0.015
        },
        {
            "name": "Crystal Maiden",
            "Silencer": -0.464
        },
        {
            "name": "Dark Seer",
            "Silencer": 0.047
        },
        {
            "name": "Dark Willow",
            "Silencer": 0.747
        },
        {
            "name": "Dawnbreaker",
            "Silencer": -2.692
        },
        {
            "name": "Dazzle",
            "Silencer": -0.314
        },
        {
            "name": "Death Prophet",
            "Silencer": -0.644
        },
        {
            "name": "Disruptor",
            "Silencer": 0.472
        },
        {
            "name": "Doom",
            "Silencer": 0.259
        },
        {
            "name": "Dragon Knight",
            "Silencer": 0.399
        },
        {
            "name": "Drow Ranger",
            "Silencer": -0.306
        },
        {
            "name": "Earth Spirit",
            "Silencer": -0.516
        },
        {
            "name": "Earthshaker",
            "Silencer": -1.252
        },
        {
            "name": "Elder Titan",
            "Silencer": -0.213
        },
        {
            "name": "Ember Spirit",
            "Silencer": -0.821
        },
        {
            "name": "Enchantress",
            "Silencer": 0.475
        },
        {
            "name": "Enigma",
            "Silencer": -1.799
        },
        {
            "name": "Faceless Void",
            "Silencer": -0.665
        },
        {
            "name": "Grimstroke",
            "Silencer": -0.886
        },
        {
            "name": "Gyrocopter",
            "Silencer": -0.039
        },
        {
            "name": "Hoodwink",
            "Silencer": 0.475
        },
        {
            "name": "Huskar",
            "Silencer": 1.618
        },
        {
            "name": "Invoker",
            "Silencer": 0.555
        },
        {
            "name": "Io",
            "Silencer": 0.995
        },
        {
            "name": "Jakiro",
            "Silencer": -0.22
        },
        {
            "name": "Juggernaut",
            "Silencer": -0.937
        },
        {
            "name": "Keeper of the Light",
            "Silencer": 0.86
        },
        {
            "name": "Kez",
            "Silencer": 0.307
        },
        {
            "name": "Kunkka",
            "Silencer": 0.078
        },
        {
            "name": "Legion Commander",
            "Silencer": 1.049
        },
        {
            "name": "Leshrac",
            "Silencer": -0.526
        },
        {
            "name": "Lich",
            "Silencer": -0.94
        },
        {
            "name": "Lifestealer",
            "Silencer": -0.198
        },
        {
            "name": "Lina",
            "Silencer": -0.986
        },
        {
            "name": "Lion",
            "Silencer": -0.901
        },
        {
            "name": "Lone Druid",
            "Silencer": 3.348
        },
        {
            "name": "Luna",
            "Silencer": 1.227
        },
        {
            "name": "Lycan",
            "Silencer": 3.651
        },
        {
            "name": "Magnus",
            "Silencer": -0.379
        },
        {
            "name": "Marci",
            "Silencer": 1.164
        },
        {
            "name": "Mars",
            "Silencer": -0.669
        },
        {
            "name": "Medusa",
            "Silencer": 1.597
        },
        {
            "name": "Meepo",
            "Silencer": 0.684
        },
        {
            "name": "Mirana",
            "Silencer": 0.968
        },
        {
            "name": "Monkey King",
            "Silencer": 1.135
        },
        {
            "name": "Morphling",
            "Silencer": 2.205
        },
        {
            "name": "Muerta",
            "Silencer": 0.07
        },
        {
            "name": "Naga Siren",
            "Silencer": 3.46
        },
        {
            "name": "Nature's Prophet",
            "Silencer": 1.765
        },
        {
            "name": "Necrophos",
            "Silencer": -2.63
        },
        {
            "name": "Night Stalker",
            "Silencer": 1.664
        },
        {
            "name": "Nyx Assassin",
            "Silencer": -0.624
        },
        {
            "name": "Ogre Magi",
            "Silencer": 1.034
        },
        {
            "name": "Omniknight",
            "Silencer": -0.201
        },
        {
            "name": "Oracle",
            "Silencer": 0.521
        },
        {
            "name": "Outworld Destroyer",
            "Silencer": -2.064
        },
        {
            "name": "Pangolier",
            "Silencer": 0.653
        },
        {
            "name": "Phantom Assassin",
            "Silencer": -0.022
        },
        {
            "name": "Phantom Lancer",
            "Silencer": 3.19
        },
        {
            "name": "Phoenix",
            "Silencer": -0.728
        },
        {
            "name": "Primal Beast",
            "Silencer": -0.528
        },
        {
            "name": "Puck",
            "Silencer": -1.046
        },
        {
            "name": "Pudge",
            "Silencer": -0.81
        },
        {
            "name": "Pugna",
            "Silencer": -0.511
        },
        {
            "name": "Queen of Pain",
            "Silencer": -0.458
        },
        {
            "name": "Razor",
            "Silencer": 1.125
        },
        {
            "name": "Riki",
            "Silencer": -0.773
        },
        {
            "name": "Ringmaster",
            "Silencer": 0.535
        },
        {
            "name": "Rubick",
            "Silencer": -0.065
        },
        {
            "name": "Sand King",
            "Silencer": -1.223
        },
        {
            "name": "Shadow Demon",
            "Silencer": 1.689
        },
        {
            "name": "Shadow Fiend",
            "Silencer": 0.079
        },
        {
            "name": "Shadow Shaman",
            "Silencer": -1.246
        },
        {
            "name": "Skywrath Mage",
            "Silencer": -0.584
        },
        {
            "name": "Slardar",
            "Silencer": -0.424
        },
        {
            "name": "Slark",
            "Silencer": 2.329
        },
        {
            "name": "Snapfire",
            "Silencer": -0.073
        },
        {
            "name": "Sniper",
            "Silencer": 1.541
        },
        {
            "name": "Spectre",
            "Silencer": 1.082
        },
        {
            "name": "Spirit Breaker",
            "Silencer": 1.218
        },
        {
            "name": "Storm Spirit",
            "Silencer": -1.862
        },
        {
            "name": "Sven",
            "Silencer": -0.115
        },
        {
            "name": "Techies",
            "Silencer": 0.649
        },
        {
            "name": "Templar Assassin",
            "Silencer": 1.246
        },
        {
            "name": "Terrorblade",
            "Silencer": 1.49
        },
        {
            "name": "Tidehunter",
            "Silencer": 2.298
        },
        {
            "name": "Timbersaw",
            "Silencer": -3.437
        },
        {
            "name": "Tinker",
            "Silencer": 0.231
        },
        {
            "name": "Tiny",
            "Silencer": 0.511
        },
        {
            "name": "Treant Protector",
            "Silencer": 0.611
        },
        {
            "name": "Troll Warlord",
            "Silencer": -0.356
        },
        {
            "name": "Tusk",
            "Silencer": 0.811
        },
        {
            "name": "Underlord",
            "Silencer": -0.196
        },
        {
            "name": "Undying",
            "Silencer": -0.546
        },
        {
            "name": "Ursa",
            "Silencer": -0.729
        },
        {
            "name": "Vengeful Spirit",
            "Silencer": -0.302
        },
        {
            "name": "Venomancer",
            "Silencer": 0.606
        },
        {
            "name": "Viper",
            "Silencer": 0.507
        },
        {
            "name": "Visage",
            "Silencer": -0.16
        },
        {
            "name": "Void Spirit",
            "Silencer": -0.258
        },
        {
            "name": "Warlock",
            "Silencer": 0.141
        },
        {
            "name": "Weaver",
            "Silencer": -0.179
        },
        {
            "name": "Windranger",
            "Silencer": 1.292
        },
        {
            "name": "Winter Wyvern",
            "Silencer": 0.251
        },
        {
            "name": "Witch Doctor",
            "Silencer": -1.518
        },
        {
            "name": "Wraith King",
            "Silencer": 1.578
        },
        {
            "name": "Zeus",
            "Silencer": 0.015
        }
    ],
    "Skywrath Mage": [
        {
            "name": "Abaddon",
            "Skywrath Mage": 1.998
        },
        {
            "name": "Alchemist",
            "Skywrath Mage": -0.521
        },
        {
            "name": "Ancient Apparition",
            "Skywrath Mage": -1.218
        },
        {
            "name": "Anti-Mage",
            "Skywrath Mage": 1.151
        },
        {
            "name": "Arc Warden",
            "Skywrath Mage": 0.987
        },
        {
            "name": "Axe",
            "Skywrath Mage": -1.136
        },
        {
            "name": "Bane",
            "Skywrath Mage": 0.695
        },
        {
            "name": "Batrider",
            "Skywrath Mage": 0.139
        },
        {
            "name": "Beastmaster",
            "Skywrath Mage": 0.905
        },
        {
            "name": "Bloodseeker",
            "Skywrath Mage": -1.633
        },
        {
            "name": "Bounty Hunter",
            "Skywrath Mage": -0.116
        },
        {
            "name": "Brewmaster",
            "Skywrath Mage": -1.152
        },
        {
            "name": "Bristleback",
            "Skywrath Mage": 1.373
        },
        {
            "name": "Broodmother",
            "Skywrath Mage": 0.558
        },
        {
            "name": "Centaur Warrunner",
            "Skywrath Mage": 1.806
        },
        {
            "name": "Chaos Knight",
            "Skywrath Mage": 1.396
        },
        {
            "name": "Chen",
            "Skywrath Mage": 0.295
        },
        {
            "name": "Clinkz",
            "Skywrath Mage": 0.441
        },
        {
            "name": "Clockwerk",
            "Skywrath Mage": -0.535
        },
        {
            "name": "Crystal Maiden",
            "Skywrath Mage": -0.152
        },
        {
            "name": "Dark Seer",
            "Skywrath Mage": -0.216
        },
        {
            "name": "Dark Willow",
            "Skywrath Mage": 0.445
        },
        {
            "name": "Dawnbreaker",
            "Skywrath Mage": 0.351
        },
        {
            "name": "Dazzle",
            "Skywrath Mage": 0.336
        },
        {
            "name": "Death Prophet",
            "Skywrath Mage": 0.268
        },
        {
            "name": "Disruptor",
            "Skywrath Mage": -0.079
        },
        {
            "name": "Doom",
            "Skywrath Mage": -0.634
        },
        {
            "name": "Dragon Knight",
            "Skywrath Mage": 1.304
        },
        {
            "name": "Drow Ranger",
            "Skywrath Mage": -0.335
        },
        {
            "name": "Earth Spirit",
            "Skywrath Mage": -0.837
        },
        {
            "name": "Earthshaker",
            "Skywrath Mage": -0.725
        },
        {
            "name": "Elder Titan",
            "Skywrath Mage": -2.072
        },
        {
            "name": "Ember Spirit",
            "Skywrath Mage": -0.571
        },
        {
            "name": "Enchantress",
            "Skywrath Mage": -0.112
        },
        {
            "name": "Enigma",
            "Skywrath Mage": -1.022
        },
        {
            "name": "Faceless Void",
            "Skywrath Mage": -1.205
        },
        {
            "name": "Grimstroke",
            "Skywrath Mage": -0.146
        },
        {
            "name": "Gyrocopter",
            "Skywrath Mage": -0.977
        },
        {
            "name": "Hoodwink",
            "Skywrath Mage": -0.424
        },
        {
            "name": "Huskar",
            "Skywrath Mage": 0.206
        },
        {
            "name": "Invoker",
            "Skywrath Mage": -0.318
        },
        {
            "name": "Io",
            "Skywrath Mage": 0.818
        },
        {
            "name": "Jakiro",
            "Skywrath Mage": 0.227
        },
        {
            "name": "Juggernaut",
            "Skywrath Mage": -1.544
        },
        {
            "name": "Keeper of the Light",
            "Skywrath Mage": -1.198
        },
        {
            "name": "Kez",
            "Skywrath Mage": 0.422
        },
        {
            "name": "Kunkka",
            "Skywrath Mage": 0.62
        },
        {
            "name": "Legion Commander",
            "Skywrath Mage": -0.385
        },
        {
            "name": "Leshrac",
            "Skywrath Mage": -0.625
        },
        {
            "name": "Lich",
            "Skywrath Mage": 0.161
        },
        {
            "name": "Lifestealer",
            "Skywrath Mage": -1.137
        },
        {
            "name": "Lina",
            "Skywrath Mage": -0.6
        },
        {
            "name": "Lion",
            "Skywrath Mage": -0.006
        },
        {
            "name": "Lone Druid",
            "Skywrath Mage": 1.947
        },
        {
            "name": "Luna",
            "Skywrath Mage": 0.453
        },
        {
            "name": "Lycan",
            "Skywrath Mage": 4.258
        },
        {
            "name": "Magnus",
            "Skywrath Mage": 0.361
        },
        {
            "name": "Marci",
            "Skywrath Mage": 1.553
        },
        {
            "name": "Mars",
            "Skywrath Mage": 0.869
        },
        {
            "name": "Medusa",
            "Skywrath Mage": 2.483
        },
        {
            "name": "Meepo",
            "Skywrath Mage": 0.793
        },
        {
            "name": "Mirana",
            "Skywrath Mage": -0.019
        },
        {
            "name": "Monkey King",
            "Skywrath Mage": -0.381
        },
        {
            "name": "Morphling",
            "Skywrath Mage": 0.139
        },
        {
            "name": "Muerta",
            "Skywrath Mage": -0.855
        },
        {
            "name": "Naga Siren",
            "Skywrath Mage": -0.579
        },
        {
            "name": "Nature's Prophet",
            "Skywrath Mage": 0.239
        },
        {
            "name": "Necrophos",
            "Skywrath Mage": -3.414
        },
        {
            "name": "Night Stalker",
            "Skywrath Mage": 1.203
        },
        {
            "name": "Nyx Assassin",
            "Skywrath Mage": -1.054
        },
        {
            "name": "Ogre Magi",
            "Skywrath Mage": 0.113
        },
        {
            "name": "Omniknight",
            "Skywrath Mage": 0.988
        },
        {
            "name": "Oracle",
            "Skywrath Mage": 1.443
        },
        {
            "name": "Outworld Destroyer",
            "Skywrath Mage": -0.318
        },
        {
            "name": "Pangolier",
            "Skywrath Mage": 0.733
        },
        {
            "name": "Phantom Assassin",
            "Skywrath Mage": 0.724
        },
        {
            "name": "Phantom Lancer",
            "Skywrath Mage": 0.989
        },
        {
            "name": "Phoenix",
            "Skywrath Mage": -2.254
        },
        {
            "name": "Primal Beast",
            "Skywrath Mage": -1.213
        },
        {
            "name": "Puck",
            "Skywrath Mage": -0.347
        },
        {
            "name": "Pudge",
            "Skywrath Mage": -0.78
        },
        {
            "name": "Pugna",
            "Skywrath Mage": 0.458
        },
        {
            "name": "Queen of Pain",
            "Skywrath Mage": -1.398
        },
        {
            "name": "Razor",
            "Skywrath Mage": 0.965
        },
        {
            "name": "Riki",
            "Skywrath Mage": 0.793
        },
        {
            "name": "Ringmaster",
            "Skywrath Mage": 1.284
        },
        {
            "name": "Rubick",
            "Skywrath Mage": -0.901
        },
        {
            "name": "Sand King",
            "Skywrath Mage": -0.111
        },
        {
            "name": "Shadow Demon",
            "Skywrath Mage": 1.176
        },
        {
            "name": "Shadow Fiend",
            "Skywrath Mage": -0.256
        },
        {
            "name": "Shadow Shaman",
            "Skywrath Mage": 0.822
        },
        {
            "name": "Silencer",
            "Skywrath Mage": 0.857
        },
        {
            "name": "Slardar",
            "Skywrath Mage": 0.388
        },
        {
            "name": "Slark",
            "Skywrath Mage": -0.4
        },
        {
            "name": "Snapfire",
            "Skywrath Mage": -0.326
        },
        {
            "name": "Sniper",
            "Skywrath Mage": 0.08
        },
        {
            "name": "Spectre",
            "Skywrath Mage": 0.288
        },
        {
            "name": "Spirit Breaker",
            "Skywrath Mage": 0.993
        },
        {
            "name": "Storm Spirit",
            "Skywrath Mage": -1.261
        },
        {
            "name": "Sven",
            "Skywrath Mage": 0.723
        },
        {
            "name": "Techies",
            "Skywrath Mage": -0.186
        },
        {
            "name": "Templar Assassin",
            "Skywrath Mage": 2.045
        },
        {
            "name": "Terrorblade",
            "Skywrath Mage": -0.827
        },
        {
            "name": "Tidehunter",
            "Skywrath Mage": 1.291
        },
        {
            "name": "Timbersaw",
            "Skywrath Mage": -2.211
        },
        {
            "name": "Tinker",
            "Skywrath Mage": -1.028
        },
        {
            "name": "Tiny",
            "Skywrath Mage": 0.485
        },
        {
            "name": "Treant Protector",
            "Skywrath Mage": -1.02
        },
        {
            "name": "Troll Warlord",
            "Skywrath Mage": -0.266
        },
        {
            "name": "Tusk",
            "Skywrath Mage": 1.281
        },
        {
            "name": "Underlord",
            "Skywrath Mage": -0.64
        },
        {
            "name": "Undying",
            "Skywrath Mage": -0.153
        },
        {
            "name": "Ursa",
            "Skywrath Mage": -0.084
        },
        {
            "name": "Vengeful Spirit",
            "Skywrath Mage": 0.818
        },
        {
            "name": "Venomancer",
            "Skywrath Mage": -0.383
        },
        {
            "name": "Viper",
            "Skywrath Mage": -0.008
        },
        {
            "name": "Visage",
            "Skywrath Mage": 0.236
        },
        {
            "name": "Void Spirit",
            "Skywrath Mage": 0.382
        },
        {
            "name": "Warlock",
            "Skywrath Mage": 0.664
        },
        {
            "name": "Weaver",
            "Skywrath Mage": -0.404
        },
        {
            "name": "Windranger",
            "Skywrath Mage": 0.282
        },
        {
            "name": "Winter Wyvern",
            "Skywrath Mage": -0.091
        },
        {
            "name": "Witch Doctor",
            "Skywrath Mage": -0.207
        },
        {
            "name": "Wraith King",
            "Skywrath Mage": 1.746
        },
        {
            "name": "Zeus",
            "Skywrath Mage": -1.364
        }
    ],
    "Storm Spirit": [
        {
            "name": "Abaddon",
            "Storm Spirit": 0.592
        },
        {
            "name": "Alchemist",
            "Storm Spirit": -0.281
        },
        {
            "name": "Ancient Apparition",
            "Storm Spirit": -3.017
        },
        {
            "name": "Anti-Mage",
            "Storm Spirit": 4.056
        },
        {
            "name": "Arc Warden",
            "Storm Spirit": 0.078
        },
        {
            "name": "Axe",
            "Storm Spirit": 1.509
        },
        {
            "name": "Bane",
            "Storm Spirit": 2.18
        },
        {
            "name": "Batrider",
            "Storm Spirit": -1.547
        },
        {
            "name": "Beastmaster",
            "Storm Spirit": 0.49
        },
        {
            "name": "Bloodseeker",
            "Storm Spirit": -3.471
        },
        {
            "name": "Bounty Hunter",
            "Storm Spirit": -1.126
        },
        {
            "name": "Brewmaster",
            "Storm Spirit": 0.537
        },
        {
            "name": "Bristleback",
            "Storm Spirit": -1.922
        },
        {
            "name": "Broodmother",
            "Storm Spirit": -2.685
        },
        {
            "name": "Centaur Warrunner",
            "Storm Spirit": 0.612
        },
        {
            "name": "Chaos Knight",
            "Storm Spirit": -0.102
        },
        {
            "name": "Chen",
            "Storm Spirit": 0.773
        },
        {
            "name": "Clinkz",
            "Storm Spirit": -0.016
        },
        {
            "name": "Clockwerk",
            "Storm Spirit": -2.252
        },
        {
            "name": "Crystal Maiden",
            "Storm Spirit": 1.416
        },
        {
            "name": "Dark Seer",
            "Storm Spirit": -0.002
        },
        {
            "name": "Dark Willow",
            "Storm Spirit": -0.062
        },
        {
            "name": "Dawnbreaker",
            "Storm Spirit": 0.454
        },
        {
            "name": "Dazzle",
            "Storm Spirit": 0.793
        },
        {
            "name": "Death Prophet",
            "Storm Spirit": 0.911
        },
        {
            "name": "Disruptor",
            "Storm Spirit": 1.047
        },
        {
            "name": "Doom",
            "Storm Spirit": -0.14
        },
        {
            "name": "Dragon Knight",
            "Storm Spirit": 0.222
        },
        {
            "name": "Drow Ranger",
            "Storm Spirit": -1.439
        },
        {
            "name": "Earth Spirit",
            "Storm Spirit": 0.198
        },
        {
            "name": "Earthshaker",
            "Storm Spirit": 0.465
        },
        {
            "name": "Elder Titan",
            "Storm Spirit": -1.632
        },
        {
            "name": "Ember Spirit",
            "Storm Spirit": 1.874
        },
        {
            "name": "Enchantress",
            "Storm Spirit": 0.099
        },
        {
            "name": "Enigma",
            "Storm Spirit": 1.154
        },
        {
            "name": "Faceless Void",
            "Storm Spirit": 0.203
        },
        {
            "name": "Grimstroke",
            "Storm Spirit": 1.92
        },
        {
            "name": "Gyrocopter",
            "Storm Spirit": 0.428
        },
        {
            "name": "Hoodwink",
            "Storm Spirit": -0.4
        },
        {
            "name": "Huskar",
            "Storm Spirit": 1.478
        },
        {
            "name": "Invoker",
            "Storm Spirit": -0.945
        },
        {
            "name": "Io",
            "Storm Spirit": 1.579
        },
        {
            "name": "Jakiro",
            "Storm Spirit": -0.212
        },
        {
            "name": "Juggernaut",
            "Storm Spirit": -1.298
        },
        {
            "name": "Keeper of the Light",
            "Storm Spirit": -1.817
        },
        {
            "name": "Kez",
            "Storm Spirit": 1.937
        },
        {
            "name": "Kunkka",
            "Storm Spirit": -0.905
        },
        {
            "name": "Legion Commander",
            "Storm Spirit": 0.582
        },
        {
            "name": "Leshrac",
            "Storm Spirit": 1.138
        },
        {
            "name": "Lich",
            "Storm Spirit": -0.325
        },
        {
            "name": "Lifestealer",
            "Storm Spirit": -1.304
        },
        {
            "name": "Lina",
            "Storm Spirit": -1.443
        },
        {
            "name": "Lion",
            "Storm Spirit": 1.574
        },
        {
            "name": "Lone Druid",
            "Storm Spirit": 3.048
        },
        {
            "name": "Luna",
            "Storm Spirit": 0.373
        },
        {
            "name": "Lycan",
            "Storm Spirit": -0.494
        },
        {
            "name": "Magnus",
            "Storm Spirit": 0.248
        },
        {
            "name": "Marci",
            "Storm Spirit": 0.224
        },
        {
            "name": "Mars",
            "Storm Spirit": -1.495
        },
        {
            "name": "Medusa",
            "Storm Spirit": 0.308
        },
        {
            "name": "Meepo",
            "Storm Spirit": 4.745
        },
        {
            "name": "Mirana",
            "Storm Spirit": -0.112
        },
        {
            "name": "Monkey King",
            "Storm Spirit": -1.053
        },
        {
            "name": "Morphling",
            "Storm Spirit": 0.007
        },
        {
            "name": "Muerta",
            "Storm Spirit": 1.424
        },
        {
            "name": "Naga Siren",
            "Storm Spirit": 1.688
        },
        {
            "name": "Nature's Prophet",
            "Storm Spirit": -0.912
        },
        {
            "name": "Necrophos",
            "Storm Spirit": 0.824
        },
        {
            "name": "Night Stalker",
            "Storm Spirit": 1.683
        },
        {
            "name": "Nyx Assassin",
            "Storm Spirit": 1.496
        },
        {
            "name": "Ogre Magi",
            "Storm Spirit": -1.074
        },
        {
            "name": "Omniknight",
            "Storm Spirit": 1.728
        },
        {
            "name": "Oracle",
            "Storm Spirit": 2.729
        },
        {
            "name": "Outworld Destroyer",
            "Storm Spirit": -1.698
        },
        {
            "name": "Pangolier",
            "Storm Spirit": -0.734
        },
        {
            "name": "Phantom Assassin",
            "Storm Spirit": -0.932
        },
        {
            "name": "Phantom Lancer",
            "Storm Spirit": -1.46
        },
        {
            "name": "Phoenix",
            "Storm Spirit": -0.633
        },
        {
            "name": "Primal Beast",
            "Storm Spirit": -1.02
        },
        {
            "name": "Puck",
            "Storm Spirit": 2.338
        },
        {
            "name": "Pudge",
            "Storm Spirit": -1.148
        },
        {
            "name": "Pugna",
            "Storm Spirit": 3.704
        },
        {
            "name": "Queen of Pain",
            "Storm Spirit": 0.354
        },
        {
            "name": "Razor",
            "Storm Spirit": -2.199
        },
        {
            "name": "Riki",
            "Storm Spirit": 2.417
        },
        {
            "name": "Ringmaster",
            "Storm Spirit": -0.043
        },
        {
            "name": "Rubick",
            "Storm Spirit": -0.854
        },
        {
            "name": "Sand King",
            "Storm Spirit": -2.097
        },
        {
            "name": "Shadow Demon",
            "Storm Spirit": -1.153
        },
        {
            "name": "Shadow Fiend",
            "Storm Spirit": 1.106
        },
        {
            "name": "Shadow Shaman",
            "Storm Spirit": 1.69
        },
        {
            "name": "Silencer",
            "Storm Spirit": 2.097
        },
        {
            "name": "Skywrath Mage",
            "Storm Spirit": 1.217
        },
        {
            "name": "Slardar",
            "Storm Spirit": 0.084
        },
        {
            "name": "Slark",
            "Storm Spirit": 1.727
        },
        {
            "name": "Snapfire",
            "Storm Spirit": -0.519
        },
        {
            "name": "Sniper",
            "Storm Spirit": -3.677
        },
        {
            "name": "Spectre",
            "Storm Spirit": 0.441
        },
        {
            "name": "Spirit Breaker",
            "Storm Spirit": 0.218
        },
        {
            "name": "Sven",
            "Storm Spirit": 1.076
        },
        {
            "name": "Techies",
            "Storm Spirit": -0.543
        },
        {
            "name": "Templar Assassin",
            "Storm Spirit": -0.705
        },
        {
            "name": "Terrorblade",
            "Storm Spirit": 0.26
        },
        {
            "name": "Tidehunter",
            "Storm Spirit": 2.418
        },
        {
            "name": "Timbersaw",
            "Storm Spirit": -1.606
        },
        {
            "name": "Tinker",
            "Storm Spirit": 0.095
        },
        {
            "name": "Tiny",
            "Storm Spirit": 1.031
        },
        {
            "name": "Treant Protector",
            "Storm Spirit": 0.849
        },
        {
            "name": "Troll Warlord",
            "Storm Spirit": 1.72
        },
        {
            "name": "Tusk",
            "Storm Spirit": 0.045
        },
        {
            "name": "Underlord",
            "Storm Spirit": 0.412
        },
        {
            "name": "Undying",
            "Storm Spirit": -0.322
        },
        {
            "name": "Ursa",
            "Storm Spirit": 0.068
        },
        {
            "name": "Vengeful Spirit",
            "Storm Spirit": -0.639
        },
        {
            "name": "Venomancer",
            "Storm Spirit": -2.445
        },
        {
            "name": "Viper",
            "Storm Spirit": -1.239
        },
        {
            "name": "Visage",
            "Storm Spirit": -0.163
        },
        {
            "name": "Void Spirit",
            "Storm Spirit": 0.586
        },
        {
            "name": "Warlock",
            "Storm Spirit": -0.899
        },
        {
            "name": "Weaver",
            "Storm Spirit": -0.028
        },
        {
            "name": "Windranger",
            "Storm Spirit": 0.485
        },
        {
            "name": "Winter Wyvern",
            "Storm Spirit": 0.127
        },
        {
            "name": "Witch Doctor",
            "Storm Spirit": 0.409
        },
        {
            "name": "Wraith King",
            "Storm Spirit": -0.5
        },
        {
            "name": "Zeus",
            "Storm Spirit": -1.884
        }
    ],
    "Tinker": [
        {
            "name": "Abaddon",
            "Tinker": -1.772
        },
        {
            "name": "Alchemist",
            "Tinker": 0.547
        },
        {
            "name": "Ancient Apparition",
            "Tinker": -1.821
        },
        {
            "name": "Anti-Mage",
            "Tinker": 4.764
        },
        {
            "name": "Arc Warden",
            "Tinker": -1.478
        },
        {
            "name": "Axe",
            "Tinker": -1.5
        },
        {
            "name": "Bane",
            "Tinker": 1.005
        },
        {
            "name": "Batrider",
            "Tinker": -0.323
        },
        {
            "name": "Beastmaster",
            "Tinker": 1.053
        },
        {
            "name": "Bloodseeker",
            "Tinker": -1.913
        },
        {
            "name": "Bounty Hunter",
            "Tinker": -1.709
        },
        {
            "name": "Brewmaster",
            "Tinker": 0.728
        },
        {
            "name": "Bristleback",
            "Tinker": -1.773
        },
        {
            "name": "Broodmother",
            "Tinker": -0.836
        },
        {
            "name": "Centaur Warrunner",
            "Tinker": -1.246
        },
        {
            "name": "Chaos Knight",
            "Tinker": 0.925
        },
        {
            "name": "Chen",
            "Tinker": -2.367
        },
        {
            "name": "Clinkz",
            "Tinker": 0.997
        },
        {
            "name": "Clockwerk",
            "Tinker": 0.353
        },
        {
            "name": "Crystal Maiden",
            "Tinker": -0.405
        },
        {
            "name": "Dark Seer",
            "Tinker": -1.099
        },
        {
            "name": "Dark Willow",
            "Tinker": -0.82
        },
        {
            "name": "Dawnbreaker",
            "Tinker": -0.042
        },
        {
            "name": "Dazzle",
            "Tinker": -1.275
        },
        {
            "name": "Death Prophet",
            "Tinker": 2.221
        },
        {
            "name": "Disruptor",
            "Tinker": -1.082
        },
        {
            "name": "Doom",
            "Tinker": -0.052
        },
        {
            "name": "Dragon Knight",
            "Tinker": -1.644
        },
        {
            "name": "Drow Ranger",
            "Tinker": 0.67
        },
        {
            "name": "Earth Spirit",
            "Tinker": -0.286
        },
        {
            "name": "Earthshaker",
            "Tinker": 1.383
        },
        {
            "name": "Elder Titan",
            "Tinker": 0.796
        },
        {
            "name": "Ember Spirit",
            "Tinker": -3.805
        },
        {
            "name": "Enchantress",
            "Tinker": -1.541
        },
        {
            "name": "Enigma",
            "Tinker": 2.436
        },
        {
            "name": "Faceless Void",
            "Tinker": 0.413
        },
        {
            "name": "Grimstroke",
            "Tinker": 0.876
        },
        {
            "name": "Gyrocopter",
            "Tinker": 0.369
        },
        {
            "name": "Hoodwink",
            "Tinker": -0.852
        },
        {
            "name": "Huskar",
            "Tinker": 1.537
        },
        {
            "name": "Invoker",
            "Tinker": 1.875
        },
        {
            "name": "Io",
            "Tinker": 0.931
        },
        {
            "name": "Jakiro",
            "Tinker": -0.481
        },
        {
            "name": "Juggernaut",
            "Tinker": 2.769
        },
        {
            "name": "Keeper of the Light",
            "Tinker": -0.469
        },
        {
            "name": "Kez",
            "Tinker": -2.612
        },
        {
            "name": "Kunkka",
            "Tinker": -0.041
        },
        {
            "name": "Legion Commander",
            "Tinker": 0.391
        },
        {
            "name": "Leshrac",
            "Tinker": 2.134
        },
        {
            "name": "Lich",
            "Tinker": -0.5
        },
        {
            "name": "Lifestealer",
            "Tinker": 0.541
        },
        {
            "name": "Lina",
            "Tinker": 0.945
        },
        {
            "name": "Lion",
            "Tinker": 0.914
        },
        {
            "name": "Lone Druid",
            "Tinker": -0.908
        },
        {
            "name": "Luna",
            "Tinker": 0.821
        },
        {
            "name": "Lycan",
            "Tinker": -0.047
        },
        {
            "name": "Magnus",
            "Tinker": 0.62
        },
        {
            "name": "Marci",
            "Tinker": 0.831
        },
        {
            "name": "Mars",
            "Tinker": -0.261
        },
        {
            "name": "Medusa",
            "Tinker": 1.337
        },
        {
            "name": "Meepo",
            "Tinker": -2.321
        },
        {
            "name": "Mirana",
            "Tinker": 0.375
        },
        {
            "name": "Monkey King",
            "Tinker": -0.869
        },
        {
            "name": "Morphling",
            "Tinker": -2.181
        },
        {
            "name": "Muerta",
            "Tinker": -1.255
        },
        {
            "name": "Naga Siren",
            "Tinker": -1.234
        },
        {
            "name": "Nature's Prophet",
            "Tinker": -1.286
        },
        {
            "name": "Necrophos",
            "Tinker": 0.216
        },
        {
            "name": "Night Stalker",
            "Tinker": 0.775
        },
        {
            "name": "Nyx Assassin",
            "Tinker": 2.468
        },
        {
            "name": "Ogre Magi",
            "Tinker": 0.448
        },
        {
            "name": "Omniknight",
            "Tinker": 0.379
        },
        {
            "name": "Oracle",
            "Tinker": -0.633
        },
        {
            "name": "Outworld Destroyer",
            "Tinker": -1.009
        },
        {
            "name": "Pangolier",
            "Tinker": 0.473
        },
        {
            "name": "Phantom Assassin",
            "Tinker": -0.203
        },
        {
            "name": "Phantom Lancer",
            "Tinker": -0.444
        },
        {
            "name": "Phoenix",
            "Tinker": -1.45
        },
        {
            "name": "Primal Beast",
            "Tinker": 1.22
        },
        {
            "name": "Puck",
            "Tinker": -1.324
        },
        {
            "name": "Pudge",
            "Tinker": 1.86
        },
        {
            "name": "Pugna",
            "Tinker": 3.114
        },
        {
            "name": "Queen of Pain",
            "Tinker": -0.39
        },
        {
            "name": "Razor",
            "Tinker": -0.469
        },
        {
            "name": "Riki",
            "Tinker": -0.214
        },
        {
            "name": "Ringmaster",
            "Tinker": -1.571
        },
        {
            "name": "Rubick",
            "Tinker": -0.325
        },
        {
            "name": "Sand King",
            "Tinker": 0.628
        },
        {
            "name": "Shadow Demon",
            "Tinker": -0.243
        },
        {
            "name": "Shadow Fiend",
            "Tinker": 1.693
        },
        {
            "name": "Shadow Shaman",
            "Tinker": 1.789
        },
        {
            "name": "Silencer",
            "Tinker": -0.116
        },
        {
            "name": "Skywrath Mage",
            "Tinker": 1.062
        },
        {
            "name": "Slardar",
            "Tinker": 0.544
        },
        {
            "name": "Slark",
            "Tinker": -0.712
        },
        {
            "name": "Snapfire",
            "Tinker": 0.755
        },
        {
            "name": "Sniper",
            "Tinker": 0.441
        },
        {
            "name": "Spectre",
            "Tinker": -0.112
        },
        {
            "name": "Spirit Breaker",
            "Tinker": 0.054
        },
        {
            "name": "Storm Spirit",
            "Tinker": -0.079
        },
        {
            "name": "Sven",
            "Tinker": 0.308
        },
        {
            "name": "Techies",
            "Tinker": -1.172
        },
        {
            "name": "Templar Assassin",
            "Tinker": -0.676
        },
        {
            "name": "Terrorblade",
            "Tinker": -2.494
        },
        {
            "name": "Tidehunter",
            "Tinker": -2.389
        },
        {
            "name": "Timbersaw",
            "Tinker": -0.986
        },
        {
            "name": "Tiny",
            "Tinker": -0.294
        },
        {
            "name": "Treant Protector",
            "Tinker": -0.865
        },
        {
            "name": "Troll Warlord",
            "Tinker": -2.174
        },
        {
            "name": "Tusk",
            "Tinker": 0.123
        },
        {
            "name": "Underlord",
            "Tinker": -1.449
        },
        {
            "name": "Undying",
            "Tinker": -0.58
        },
        {
            "name": "Ursa",
            "Tinker": -0.739
        },
        {
            "name": "Vengeful Spirit",
            "Tinker": 0.732
        },
        {
            "name": "Venomancer",
            "Tinker": -2.105
        },
        {
            "name": "Viper",
            "Tinker": -3.117
        },
        {
            "name": "Visage",
            "Tinker": 2.644
        },
        {
            "name": "Void Spirit",
            "Tinker": -0.206
        },
        {
            "name": "Warlock",
            "Tinker": -0.521
        },
        {
            "name": "Weaver",
            "Tinker": -0.667
        },
        {
            "name": "Windranger",
            "Tinker": 0.229
        },
        {
            "name": "Winter Wyvern",
            "Tinker": -2.054
        },
        {
            "name": "Witch Doctor",
            "Tinker": 1.251
        },
        {
            "name": "Wraith King",
            "Tinker": -0.97
        },
        {
            "name": "Zeus",
            "Tinker": 1.63
        }
    ],
    "Warlock": [
        {
            "name": "Abaddon",
            "Warlock": 0.82
        },
        {
            "name": "Alchemist",
            "Warlock": 0.94
        },
        {
            "name": "Ancient Apparition",
            "Warlock": 0.397
        },
        {
            "name": "Anti-Mage",
            "Warlock": 0.186
        },
        {
            "name": "Arc Warden",
            "Warlock": -2.318
        },
        {
            "name": "Axe",
            "Warlock": -2.562
        },
        {
            "name": "Bane",
            "Warlock": -0.003
        },
        {
            "name": "Batrider",
            "Warlock": 0.673
        },
        {
            "name": "Beastmaster",
            "Warlock": -1.524
        },
        {
            "name": "Bloodseeker",
            "Warlock": -0.867
        },
        {
            "name": "Bounty Hunter",
            "Warlock": 1.483
        },
        {
            "name": "Brewmaster",
            "Warlock": -1.074
        },
        {
            "name": "Bristleback",
            "Warlock": 1.914
        },
        {
            "name": "Broodmother",
            "Warlock": 0.239
        },
        {
            "name": "Centaur Warrunner",
            "Warlock": -1.963
        },
        {
            "name": "Chaos Knight",
            "Warlock": -4.048
        },
        {
            "name": "Chen",
            "Warlock": 0.677
        },
        {
            "name": "Clinkz",
            "Warlock": 0.809
        },
        {
            "name": "Clockwerk",
            "Warlock": -1.457
        },
        {
            "name": "Crystal Maiden",
            "Warlock": 0.179
        },
        {
            "name": "Dark Seer",
            "Warlock": -0.445
        },
        {
            "name": "Dark Willow",
            "Warlock": -0.477
        },
        {
            "name": "Dawnbreaker",
            "Warlock": 0.084
        },
        {
            "name": "Dazzle",
            "Warlock": 0.601
        },
        {
            "name": "Death Prophet",
            "Warlock": -0.847
        },
        {
            "name": "Disruptor",
            "Warlock": -0.282
        },
        {
            "name": "Doom",
            "Warlock": 0.395
        },
        {
            "name": "Dragon Knight",
            "Warlock": 0.214
        },
        {
            "name": "Drow Ranger",
            "Warlock": 0.075
        },
        {
            "name": "Earth Spirit",
            "Warlock": -0.241
        },
        {
            "name": "Earthshaker",
            "Warlock": 1.044
        },
        {
            "name": "Elder Titan",
            "Warlock": 0.28
        },
        {
            "name": "Ember Spirit",
            "Warlock": 1.02
        },
        {
            "name": "Enchantress",
            "Warlock": -0.287
        },
        {
            "name": "Enigma",
            "Warlock": -3.495
        },
        {
            "name": "Faceless Void",
            "Warlock": 0.292
        },
        {
            "name": "Grimstroke",
            "Warlock": -0.784
        },
        {
            "name": "Gyrocopter",
            "Warlock": 0.518
        },
        {
            "name": "Hoodwink",
            "Warlock": 0.348
        },
        {
            "name": "Huskar",
            "Warlock": 0.753
        },
        {
            "name": "Invoker",
            "Warlock": 0.534
        },
        {
            "name": "Io",
            "Warlock": 0.263
        },
        {
            "name": "Jakiro",
            "Warlock": 1.454
        },
        {
            "name": "Juggernaut",
            "Warlock": -0.488
        },
        {
            "name": "Keeper of the Light",
            "Warlock": 1.058
        },
        {
            "name": "Kez",
            "Warlock": 1.293
        },
        {
            "name": "Kunkka",
            "Warlock": -0.57
        },
        {
            "name": "Legion Commander",
            "Warlock": 0.048
        },
        {
            "name": "Leshrac",
            "Warlock": 1.601
        },
        {
            "name": "Lich",
            "Warlock": -0.761
        },
        {
            "name": "Lifestealer",
            "Warlock": 0.562
        },
        {
            "name": "Lina",
            "Warlock": 0.65
        },
        {
            "name": "Lion",
            "Warlock": -0.632
        },
        {
            "name": "Lone Druid",
            "Warlock": -2.188
        },
        {
            "name": "Luna",
            "Warlock": -1.067
        },
        {
            "name": "Lycan",
            "Warlock": 1.04
        },
        {
            "name": "Magnus",
            "Warlock": 0.423
        },
        {
            "name": "Marci",
            "Warlock": 0.694
        },
        {
            "name": "Mars",
            "Warlock": -0.676
        },
        {
            "name": "Medusa",
            "Warlock": -0.582
        },
        {
            "name": "Meepo",
            "Warlock": -2.356
        },
        {
            "name": "Mirana",
            "Warlock": 0.46
        },
        {
            "name": "Monkey King",
            "Warlock": 1.463
        },
        {
            "name": "Morphling",
            "Warlock": 1.339
        },
        {
            "name": "Muerta",
            "Warlock": 0.884
        },
        {
            "name": "Naga Siren",
            "Warlock": -2.585
        },
        {
            "name": "Nature's Prophet",
            "Warlock": 1.333
        },
        {
            "name": "Necrophos",
            "Warlock": -0.464
        },
        {
            "name": "Night Stalker",
            "Warlock": 0.757
        },
        {
            "name": "Nyx Assassin",
            "Warlock": 0.531
        },
        {
            "name": "Ogre Magi",
            "Warlock": -1.886
        },
        {
            "name": "Omniknight",
            "Warlock": -1.251
        },
        {
            "name": "Oracle",
            "Warlock": 0.339
        },
        {
            "name": "Outworld Destroyer",
            "Warlock": -0.299
        },
        {
            "name": "Pangolier",
            "Warlock": 2.048
        },
        {
            "name": "Phantom Assassin",
            "Warlock": 1.087
        },
        {
            "name": "Phantom Lancer",
            "Warlock": -4.401
        },
        {
            "name": "Phoenix",
            "Warlock": 1.697
        },
        {
            "name": "Primal Beast",
            "Warlock": 0.984
        },
        {
            "name": "Puck",
            "Warlock": 0.649
        },
        {
            "name": "Pudge",
            "Warlock": -0.251
        },
        {
            "name": "Pugna",
            "Warlock": 0.16
        },
        {
            "name": "Queen of Pain",
            "Warlock": 0.614
        },
        {
            "name": "Razor",
            "Warlock": 0.647
        },
        {
            "name": "Riki",
            "Warlock": 0.253
        },
        {
            "name": "Ringmaster",
            "Warlock": 0.469
        },
        {
            "name": "Rubick",
            "Warlock": 0.004
        },
        {
            "name": "Sand King",
            "Warlock": 2.042
        },
        {
            "name": "Shadow Demon",
            "Warlock": -0.591
        },
        {
            "name": "Shadow Fiend",
            "Warlock": 1.567
        },
        {
            "name": "Shadow Shaman",
            "Warlock": -1.367
        },
        {
            "name": "Silencer",
            "Warlock": -0.177
        },
        {
            "name": "Skywrath Mage",
            "Warlock": -0.53
        },
        {
            "name": "Slardar",
            "Warlock": 0.719
        },
        {
            "name": "Slark",
            "Warlock": 1.056
        },
        {
            "name": "Snapfire",
            "Warlock": 1.004
        },
        {
            "name": "Sniper",
            "Warlock": 0.597
        },
        {
            "name": "Spectre",
            "Warlock": -1.9
        },
        {
            "name": "Spirit Breaker",
            "Warlock": -0.283
        },
        {
            "name": "Storm Spirit",
            "Warlock": 0.882
        },
        {
            "name": "Sven",
            "Warlock": 1.884
        },
        {
            "name": "Techies",
            "Warlock": -0.473
        },
        {
            "name": "Templar Assassin",
            "Warlock": 0.609
        },
        {
            "name": "Terrorblade",
            "Warlock": -3.176
        },
        {
            "name": "Tidehunter",
            "Warlock": 2.415
        },
        {
            "name": "Timbersaw",
            "Warlock": -0.043
        },
        {
            "name": "Tinker",
            "Warlock": 0.544
        },
        {
            "name": "Tiny",
            "Warlock": 0.095
        },
        {
            "name": "Treant Protector",
            "Warlock": 0.208
        },
        {
            "name": "Troll Warlord",
            "Warlock": 0.698
        },
        {
            "name": "Tusk",
            "Warlock": 1.052
        },
        {
            "name": "Underlord",
            "Warlock": 0.77
        },
        {
            "name": "Undying",
            "Warlock": -0.548
        },
        {
            "name": "Ursa",
            "Warlock": 1.462
        },
        {
            "name": "Vengeful Spirit",
            "Warlock": -1.342
        },
        {
            "name": "Venomancer",
            "Warlock": 0.134
        },
        {
            "name": "Viper",
            "Warlock": -0.516
        },
        {
            "name": "Visage",
            "Warlock": -1.179
        },
        {
            "name": "Void Spirit",
            "Warlock": -0.908
        },
        {
            "name": "Weaver",
            "Warlock": 2.257
        },
        {
            "name": "Windranger",
            "Warlock": 0.247
        },
        {
            "name": "Winter Wyvern",
            "Warlock": -0.306
        },
        {
            "name": "Witch Doctor",
            "Warlock": -0.35
        },
        {
            "name": "Wraith King",
            "Warlock": -3.717
        },
        {
            "name": "Zeus",
            "Warlock": -0.235
        }
    ],
    "Witch Doctor": [
        {
            "name": "Abaddon",
            "Witch Doctor": 0.504
        },
        {
            "name": "Alchemist",
            "Witch Doctor": 0.327
        },
        {
            "name": "Ancient Apparition",
            "Witch Doctor": 0.17
        },
        {
            "name": "Anti-Mage",
            "Witch Doctor": -0.18
        },
        {
            "name": "Arc Warden",
            "Witch Doctor": 1.345
        },
        {
            "name": "Axe",
            "Witch Doctor": -1.911
        },
        {
            "name": "Bane",
            "Witch Doctor": 0.346
        },
        {
            "name": "Batrider",
            "Witch Doctor": 1.325
        },
        {
            "name": "Beastmaster",
            "Witch Doctor": 0.032
        },
        {
            "name": "Bloodseeker",
            "Witch Doctor": -1.768
        },
        {
            "name": "Bounty Hunter",
            "Witch Doctor": 0.916
        },
        {
            "name": "Brewmaster",
            "Witch Doctor": 1.254
        },
        {
            "name": "Bristleback",
            "Witch Doctor": 2.4
        },
        {
            "name": "Broodmother",
            "Witch Doctor": 1.639
        },
        {
            "name": "Centaur Warrunner",
            "Witch Doctor": -1.449
        },
        {
            "name": "Chaos Knight",
            "Witch Doctor": -0.687
        },
        {
            "name": "Chen",
            "Witch Doctor": 0.68
        },
        {
            "name": "Clinkz",
            "Witch Doctor": 0.427
        },
        {
            "name": "Clockwerk",
            "Witch Doctor": -0.71
        },
        {
            "name": "Crystal Maiden",
            "Witch Doctor": -0.961
        },
        {
            "name": "Dark Seer",
            "Witch Doctor": -1.012
        },
        {
            "name": "Dark Willow",
            "Witch Doctor": 1.112
        },
        {
            "name": "Dawnbreaker",
            "Witch Doctor": -0.467
        },
        {
            "name": "Dazzle",
            "Witch Doctor": 0.097
        },
        {
            "name": "Death Prophet",
            "Witch Doctor": 0.36
        },
        {
            "name": "Disruptor",
            "Witch Doctor": 0.649
        },
        {
            "name": "Doom",
            "Witch Doctor": 0.313
        },
        {
            "name": "Dragon Knight",
            "Witch Doctor": -1.665
        },
        {
            "name": "Drow Ranger",
            "Witch Doctor": 1.316
        },
        {
            "name": "Earth Spirit",
            "Witch Doctor": -0.609
        },
        {
            "name": "Earthshaker",
            "Witch Doctor": 0.609
        },
        {
            "name": "Elder Titan",
            "Witch Doctor": 0.19
        },
        {
            "name": "Ember Spirit",
            "Witch Doctor": 0.078
        },
        {
            "name": "Enchantress",
            "Witch Doctor": 0.397
        },
        {
            "name": "Enigma",
            "Witch Doctor": 0.69
        },
        {
            "name": "Faceless Void",
            "Witch Doctor": 1.992
        },
        {
            "name": "Grimstroke",
            "Witch Doctor": 0.332
        },
        {
            "name": "Gyrocopter",
            "Witch Doctor": -0.076
        },
        {
            "name": "Hoodwink",
            "Witch Doctor": 1.378
        },
        {
            "name": "Huskar",
            "Witch Doctor": -1.107
        },
        {
            "name": "Invoker",
            "Witch Doctor": 0.757
        },
        {
            "name": "Io",
            "Witch Doctor": -0.217
        },
        {
            "name": "Jakiro",
            "Witch Doctor": 0.41
        },
        {
            "name": "Juggernaut",
            "Witch Doctor": -0.728
        },
        {
            "name": "Keeper of the Light",
            "Witch Doctor": 0.664
        },
        {
            "name": "Kez",
            "Witch Doctor": 0.998
        },
        {
            "name": "Kunkka",
            "Witch Doctor": 0.463
        },
        {
            "name": "Legion Commander",
            "Witch Doctor": 0.006
        },
        {
            "name": "Leshrac",
            "Witch Doctor": -0.828
        },
        {
            "name": "Lich",
            "Witch Doctor": -0.274
        },
        {
            "name": "Lifestealer",
            "Witch Doctor": -0.915
        },
        {
            "name": "Lina",
            "Witch Doctor": 0.837
        },
        {
            "name": "Lion",
            "Witch Doctor": 0.913
        },
        {
            "name": "Lone Druid",
            "Witch Doctor": -0.514
        },
        {
            "name": "Luna",
            "Witch Doctor": -0.498
        },
        {
            "name": "Lycan",
            "Witch Doctor": 1.337
        },
        {
            "name": "Magnus",
            "Witch Doctor": 0.138
        },
        {
            "name": "Marci",
            "Witch Doctor": 0.117
        },
        {
            "name": "Mars",
            "Witch Doctor": 0.696
        },
        {
            "name": "Medusa",
            "Witch Doctor": 3.075
        },
        {
            "name": "Meepo",
            "Witch Doctor": -1.795
        },
        {
            "name": "Mirana",
            "Witch Doctor": 0.418
        },
        {
            "name": "Monkey King",
            "Witch Doctor": -0.209
        },
        {
            "name": "Morphling",
            "Witch Doctor": 1.492
        },
        {
            "name": "Muerta",
            "Witch Doctor": 1.774
        },
        {
            "name": "Naga Siren",
            "Witch Doctor": 1.453
        },
        {
            "name": "Nature's Prophet",
            "Witch Doctor": 0.634
        },
        {
            "name": "Necrophos",
            "Witch Doctor": 0.085
        },
        {
            "name": "Night Stalker",
            "Witch Doctor": 1.581
        },
        {
            "name": "Nyx Assassin",
            "Witch Doctor": 0.318
        },
        {
            "name": "Ogre Magi",
            "Witch Doctor": -0.303
        },
        {
            "name": "Omniknight",
            "Witch Doctor": -0.846
        },
        {
            "name": "Oracle",
            "Witch Doctor": 0.132
        },
        {
            "name": "Outworld Destroyer",
            "Witch Doctor": -0.069
        },
        {
            "name": "Pangolier",
            "Witch Doctor": 1.886
        },
        {
            "name": "Phantom Assassin",
            "Witch Doctor": 1.538
        },
        {
            "name": "Phantom Lancer",
            "Witch Doctor": 0.006
        },
        {
            "name": "Phoenix",
            "Witch Doctor": 0.821
        },
        {
            "name": "Primal Beast",
            "Witch Doctor": -0.282
        },
        {
            "name": "Puck",
            "Witch Doctor": 1.717
        },
        {
            "name": "Pudge",
            "Witch Doctor": -0.215
        },
        {
            "name": "Pugna",
            "Witch Doctor": 0.719
        },
        {
            "name": "Queen of Pain",
            "Witch Doctor": -0.938
        },
        {
            "name": "Razor",
            "Witch Doctor": -1.514
        },
        {
            "name": "Riki",
            "Witch Doctor": 2.217
        },
        {
            "name": "Ringmaster",
            "Witch Doctor": 1.392
        },
        {
            "name": "Rubick",
            "Witch Doctor": 0.878
        },
        {
            "name": "Sand King",
            "Witch Doctor": 1.136
        },
        {
            "name": "Shadow Demon",
            "Witch Doctor": 0.993
        },
        {
            "name": "Shadow Fiend",
            "Witch Doctor": 0.864
        },
        {
            "name": "Shadow Shaman",
            "Witch Doctor": -0.582
        },
        {
            "name": "Silencer",
            "Witch Doctor": 1.465
        },
        {
            "name": "Skywrath Mage",
            "Witch Doctor": 0.563
        },
        {
            "name": "Slardar",
            "Witch Doctor": 0.233
        },
        {
            "name": "Slark",
            "Witch Doctor": 1.179
        },
        {
            "name": "Snapfire",
            "Witch Doctor": 0.868
        },
        {
            "name": "Sniper",
            "Witch Doctor": 1.226
        },
        {
            "name": "Spectre",
            "Witch Doctor": -0.067
        },
        {
            "name": "Spirit Breaker",
            "Witch Doctor": -0.46
        },
        {
            "name": "Storm Spirit",
            "Witch Doctor": -0.103
        },
        {
            "name": "Sven",
            "Witch Doctor": -0.4
        },
        {
            "name": "Techies",
            "Witch Doctor": 0.233
        },
        {
            "name": "Templar Assassin",
            "Witch Doctor": 2.237
        },
        {
            "name": "Terrorblade",
            "Witch Doctor": -0.485
        },
        {
            "name": "Tidehunter",
            "Witch Doctor": -2.524
        },
        {
            "name": "Timbersaw",
            "Witch Doctor": -0.794
        },
        {
            "name": "Tinker",
            "Witch Doctor": -0.913
        },
        {
            "name": "Tiny",
            "Witch Doctor": -0.589
        },
        {
            "name": "Treant Protector",
            "Witch Doctor": 0.398
        },
        {
            "name": "Troll Warlord",
            "Witch Doctor": 0.126
        },
        {
            "name": "Tusk",
            "Witch Doctor": 0.764
        },
        {
            "name": "Underlord",
            "Witch Doctor": -1.122
        },
        {
            "name": "Undying",
            "Witch Doctor": -1.643
        },
        {
            "name": "Ursa",
            "Witch Doctor": 1.102
        },
        {
            "name": "Vengeful Spirit",
            "Witch Doctor": -0.273
        },
        {
            "name": "Venomancer",
            "Witch Doctor": -0.012
        },
        {
            "name": "Viper",
            "Witch Doctor": -1.563
        },
        {
            "name": "Visage",
            "Witch Doctor": 0.32
        },
        {
            "name": "Void Spirit",
            "Witch Doctor": 1.077
        },
        {
            "name": "Warlock",
            "Witch Doctor": 0.315
        },
        {
            "name": "Weaver",
            "Witch Doctor": 0.436
        },
        {
            "name": "Windranger",
            "Witch Doctor": 1.59
        },
        {
            "name": "Winter Wyvern",
            "Witch Doctor": -0.246
        },
        {
            "name": "Wraith King",
            "Witch Doctor": 0.936
        },
        {
            "name": "Zeus",
            "Witch Doctor": 1.406
        }
    ],
    "Zeus": [
        {
            "name": "Abaddon",
            "Zeus": 0.084
        },
        {
            "name": "Alchemist",
            "Zeus": 0.161
        },
        {
            "name": "Ancient Apparition",
            "Zeus": -1.55
        },
        {
            "name": "Anti-Mage",
            "Zeus": 1.216
        },
        {
            "name": "Arc Warden",
            "Zeus": 0.475
        },
        {
            "name": "Axe",
            "Zeus": -1.333
        },
        {
            "name": "Bane",
            "Zeus": 0.97
        },
        {
            "name": "Batrider",
            "Zeus": 1.05
        },
        {
            "name": "Beastmaster",
            "Zeus": 1.278
        },
        {
            "name": "Bloodseeker",
            "Zeus": -0.873
        },
        {
            "name": "Bounty Hunter",
            "Zeus": 0.343
        },
        {
            "name": "Brewmaster",
            "Zeus": 1.069
        },
        {
            "name": "Bristleback",
            "Zeus": 1.158
        },
        {
            "name": "Broodmother",
            "Zeus": 1.45
        },
        {
            "name": "Centaur Warrunner",
            "Zeus": 0.915
        },
        {
            "name": "Chaos Knight",
            "Zeus": 1.653
        },
        {
            "name": "Chen",
            "Zeus": 4.057
        },
        {
            "name": "Clinkz",
            "Zeus": 0.46
        },
        {
            "name": "Clockwerk",
            "Zeus": -0.104
        },
        {
            "name": "Crystal Maiden",
            "Zeus": -0.388
        },
        {
            "name": "Dark Seer",
            "Zeus": 0.205
        },
        {
            "name": "Dark Willow",
            "Zeus": 0.387
        },
        {
            "name": "Dawnbreaker",
            "Zeus": 0.153
        },
        {
            "name": "Dazzle",
            "Zeus": 0.409
        },
        {
            "name": "Death Prophet",
            "Zeus": 0.282
        },
        {
            "name": "Disruptor",
            "Zeus": -0.368
        },
        {
            "name": "Doom",
            "Zeus": 0.857
        },
        {
            "name": "Dragon Knight",
            "Zeus": 0.177
        },
        {
            "name": "Drow Ranger",
            "Zeus": -1.717
        },
        {
            "name": "Earth Spirit",
            "Zeus": 0.256
        },
        {
            "name": "Earthshaker",
            "Zeus": -0.519
        },
        {
            "name": "Elder Titan",
            "Zeus": -0.933
        },
        {
            "name": "Ember Spirit",
            "Zeus": 1.64
        },
        {
            "name": "Enchantress",
            "Zeus": 1.459
        },
        {
            "name": "Enigma",
            "Zeus": 0.234
        },
        {
            "name": "Faceless Void",
            "Zeus": 1.397
        },
        {
            "name": "Grimstroke",
            "Zeus": -0.144
        },
        {
            "name": "Gyrocopter",
            "Zeus": -0.225
        },
        {
            "name": "Hoodwink",
            "Zeus": 0.311
        },
        {
            "name": "Huskar",
            "Zeus": 1.871
        },
        {
            "name": "Invoker",
            "Zeus": -0.047
        },
        {
            "name": "Io",
            "Zeus": 1.556
        },
        {
            "name": "Jakiro",
            "Zeus": -0.414
        },
        {
            "name": "Juggernaut",
            "Zeus": 0.385
        },
        {
            "name": "Keeper of the Light",
            "Zeus": 1.106
        },
        {
            "name": "Kez",
            "Zeus": 0.798
        },
        {
            "name": "Kunkka",
            "Zeus": 1.062
        },
        {
            "name": "Legion Commander",
            "Zeus": -0.683
        },
        {
            "name": "Leshrac",
            "Zeus": -0.361
        },
        {
            "name": "Lich",
            "Zeus": -0.869
        },
        {
            "name": "Lifestealer",
            "Zeus": 0.943
        },
        {
            "name": "Lina",
            "Zeus": 0.461
        },
        {
            "name": "Lion",
            "Zeus": 0.376
        },
        {
            "name": "Lone Druid",
            "Zeus": 0.958
        },
        {
            "name": "Luna",
            "Zeus": -0.805
        },
        {
            "name": "Lycan",
            "Zeus": 2.349
        },
        {
            "name": "Magnus",
            "Zeus": 0.089
        },
        {
            "name": "Marci",
            "Zeus": 1.152
        },
        {
            "name": "Mars",
            "Zeus": 0.572
        },
        {
            "name": "Medusa",
            "Zeus": 0.695
        },
        {
            "name": "Meepo",
            "Zeus": 3.152
        },
        {
            "name": "Mirana",
            "Zeus": -0.546
        },
        {
            "name": "Monkey King",
            "Zeus": -0.891
        },
        {
            "name": "Morphling",
            "Zeus": 0.119
        },
        {
            "name": "Muerta",
            "Zeus": -0.492
        },
        {
            "name": "Naga Siren",
            "Zeus": 1.265
        },
        {
            "name": "Nature's Prophet",
            "Zeus": 0.047
        },
        {
            "name": "Necrophos",
            "Zeus": 0.842
        },
        {
            "name": "Night Stalker",
            "Zeus": 0.101
        },
        {
            "name": "Nyx Assassin",
            "Zeus": -0.476
        },
        {
            "name": "Ogre Magi",
            "Zeus": 0.322
        },
        {
            "name": "Omniknight",
            "Zeus": 0.855
        },
        {
            "name": "Oracle",
            "Zeus": 0.851
        },
        {
            "name": "Outworld Destroyer",
            "Zeus": -0.937
        },
        {
            "name": "Pangolier",
            "Zeus": 0.688
        },
        {
            "name": "Phantom Assassin",
            "Zeus": -0.132
        },
        {
            "name": "Phantom Lancer",
            "Zeus": -0.212
        },
        {
            "name": "Phoenix",
            "Zeus": 0.022
        },
        {
            "name": "Primal Beast",
            "Zeus": -0.197
        },
        {
            "name": "Puck",
            "Zeus": -0.356
        },
        {
            "name": "Pudge",
            "Zeus": 0.376
        },
        {
            "name": "Pugna",
            "Zeus": 1.184
        },
        {
            "name": "Queen of Pain",
            "Zeus": 0.989
        },
        {
            "name": "Razor",
            "Zeus": 0.267
        },
        {
            "name": "Riki",
            "Zeus": -1.537
        },
        {
            "name": "Ringmaster",
            "Zeus": 0.256
        },
        {
            "name": "Rubick",
            "Zeus": 0.499
        },
        {
            "name": "Sand King",
            "Zeus": -2.884
        },
        {
            "name": "Shadow Demon",
            "Zeus": 0.944
        },
        {
            "name": "Shadow Fiend",
            "Zeus": -1.551
        },
        {
            "name": "Shadow Shaman",
            "Zeus": 0.207
        },
        {
            "name": "Silencer",
            "Zeus": -0.049
        },
        {
            "name": "Skywrath Mage",
            "Zeus": 1.413
        },
        {
            "name": "Slardar",
            "Zeus": -0.12
        },
        {
            "name": "Slark",
            "Zeus": -0.338
        },
        {
            "name": "Snapfire",
            "Zeus": 0.535
        },
        {
            "name": "Sniper",
            "Zeus": -1.107
        },
        {
            "name": "Spectre",
            "Zeus": 1.378
        },
        {
            "name": "Spirit Breaker",
            "Zeus": 0.415
        },
        {
            "name": "Storm Spirit",
            "Zeus": 1.856
        },
        {
            "name": "Sven",
            "Zeus": 0.578
        },
        {
            "name": "Techies",
            "Zeus": 0.027
        },
        {
            "name": "Templar Assassin",
            "Zeus": 1.509
        },
        {
            "name": "Terrorblade",
            "Zeus": 0.603
        },
        {
            "name": "Tidehunter",
            "Zeus": -1.004
        },
        {
            "name": "Timbersaw",
            "Zeus": 0.07
        },
        {
            "name": "Tinker",
            "Zeus": -1.409
        },
        {
            "name": "Tiny",
            "Zeus": -0.344
        },
        {
            "name": "Treant Protector",
            "Zeus": -0.018
        },
        {
            "name": "Troll Warlord",
            "Zeus": -0.298
        },
        {
            "name": "Tusk",
            "Zeus": 1.025
        },
        {
            "name": "Underlord",
            "Zeus": 1.313
        },
        {
            "name": "Undying",
            "Zeus": 0.809
        },
        {
            "name": "Ursa",
            "Zeus": -0.003
        },
        {
            "name": "Vengeful Spirit",
            "Zeus": 0.301
        },
        {
            "name": "Venomancer",
            "Zeus": 0.214
        },
        {
            "name": "Viper",
            "Zeus": 1.358
        },
        {
            "name": "Visage",
            "Zeus": 1.177
        },
        {
            "name": "Void Spirit",
            "Zeus": 1.683
        },
        {
            "name": "Warlock",
            "Zeus": 0.088
        },
        {
            "name": "Weaver",
            "Zeus": -0.006
        },
        {
            "name": "Windranger",
            "Zeus": 0.226
        },
        {
            "name": "Winter Wyvern",
            "Zeus": -0.666
        },
        {
            "name": "Witch Doctor",
            "Zeus": -1.433
        },
        {
            "name": "Wraith King",
            "Zeus": 0.458
        }
    ],
    "Abaddon": [
        {
            "name": "Alchemist",
            "Abaddon": 1.203
        },
        {
            "name": "Ancient Apparition",
            "Abaddon": 2.102
        },
        {
            "name": "Anti-Mage",
            "Abaddon": 2.673
        },
        {
            "name": "Arc Warden",
            "Abaddon": -1.322
        },
        {
            "name": "Axe",
            "Abaddon": -2.15
        },
        {
            "name": "Bane",
            "Abaddon": -1.31
        },
        {
            "name": "Batrider",
            "Abaddon": -0.09
        },
        {
            "name": "Beastmaster",
            "Abaddon": 0.481
        },
        {
            "name": "Bloodseeker",
            "Abaddon": -1.332
        },
        {
            "name": "Bounty Hunter",
            "Abaddon": -1.104
        },
        {
            "name": "Brewmaster",
            "Abaddon": 3.005
        },
        {
            "name": "Bristleback",
            "Abaddon": 0.53
        },
        {
            "name": "Broodmother",
            "Abaddon": 2.53
        },
        {
            "name": "Centaur Warrunner",
            "Abaddon": -0.041
        },
        {
            "name": "Chaos Knight",
            "Abaddon": 0.556
        },
        {
            "name": "Chen",
            "Abaddon": 2.031
        },
        {
            "name": "Clinkz",
            "Abaddon": -1.119
        },
        {
            "name": "Clockwerk",
            "Abaddon": 0.727
        },
        {
            "name": "Crystal Maiden",
            "Abaddon": 1.096
        },
        {
            "name": "Dark Seer",
            "Abaddon": 3.304
        },
        {
            "name": "Dark Willow",
            "Abaddon": 0.608
        },
        {
            "name": "Dawnbreaker",
            "Abaddon": 0.751
        },
        {
            "name": "Dazzle",
            "Abaddon": -1.751
        },
        {
            "name": "Death Prophet",
            "Abaddon": 1.272
        },
        {
            "name": "Disruptor",
            "Abaddon": 0.398
        },
        {
            "name": "Doom",
            "Abaddon": 3.406
        },
        {
            "name": "Dragon Knight",
            "Abaddon": -0.003
        },
        {
            "name": "Drow Ranger",
            "Abaddon": 1.332
        },
        {
            "name": "Earth Spirit",
            "Abaddon": -0.094
        },
        {
            "name": "Earthshaker",
            "Abaddon": 2.481
        },
        {
            "name": "Elder Titan",
            "Abaddon": 3.703
        },
        {
            "name": "Ember Spirit",
            "Abaddon": 0.691
        },
        {
            "name": "Enchantress",
            "Abaddon": 1.502
        },
        {
            "name": "Enigma",
            "Abaddon": 0.229
        },
        {
            "name": "Faceless Void",
            "Abaddon": 0.139
        },
        {
            "name": "Grimstroke",
            "Abaddon": 1.882
        },
        {
            "name": "Gyrocopter",
            "Abaddon": 0.191
        },
        {
            "name": "Hoodwink",
            "Abaddon": 1.456
        },
        {
            "name": "Huskar",
            "Abaddon": -0.701
        },
        {
            "name": "Invoker",
            "Abaddon": 0.67
        },
        {
            "name": "Io",
            "Abaddon": 0.717
        },
        {
            "name": "Jakiro",
            "Abaddon": -0.389
        },
        {
            "name": "Juggernaut",
            "Abaddon": -0.798
        },
        {
            "name": "Keeper of the Light",
            "Abaddon": 1.652
        },
        {
            "name": "Kez",
            "Abaddon": 0.232
        },
        {
            "name": "Kunkka",
            "Abaddon": 1.918
        },
        {
            "name": "Legion Commander",
            "Abaddon": -1.364
        },
        {
            "name": "Leshrac",
            "Abaddon": 0.183
        },
        {
            "name": "Lich",
            "Abaddon": 0.486
        },
        {
            "name": "Lifestealer",
            "Abaddon": -0.85
        },
        {
            "name": "Lina",
            "Abaddon": 1.326
        },
        {
            "name": "Lion",
            "Abaddon": 1.426
        },
        {
            "name": "Lone Druid",
            "Abaddon": 2.41
        },
        {
            "name": "Luna",
            "Abaddon": 0.015
        },
        {
            "name": "Lycan",
            "Abaddon": 2.242
        },
        {
            "name": "Magnus",
            "Abaddon": 1.825
        },
        {
            "name": "Marci",
            "Abaddon": 2.465
        },
        {
            "name": "Mars",
            "Abaddon": 1.519
        },
        {
            "name": "Medusa",
            "Abaddon": 1.257
        },
        {
            "name": "Meepo",
            "Abaddon": 3.269
        },
        {
            "name": "Mirana",
            "Abaddon": 0.282
        },
        {
            "name": "Monkey King",
            "Abaddon": 3.202
        },
        {
            "name": "Morphling",
            "Abaddon": 0.471
        },
        {
            "name": "Muerta",
            "Abaddon": 1.04
        },
        {
            "name": "Naga Siren",
            "Abaddon": 0.873
        },
        {
            "name": "Nature's Prophet",
            "Abaddon": 1.679
        },
        {
            "name": "Necrophos",
            "Abaddon": 0.185
        },
        {
            "name": "Night Stalker",
            "Abaddon": 0.131
        },
        {
            "name": "Nyx Assassin",
            "Abaddon": 0.166
        },
        {
            "name": "Ogre Magi",
            "Abaddon": -0.81
        },
        {
            "name": "Omniknight",
            "Abaddon": 1.755
        },
        {
            "name": "Oracle",
            "Abaddon": 0.65
        },
        {
            "name": "Outworld Destroyer",
            "Abaddon": 3.019
        },
        {
            "name": "Pangolier",
            "Abaddon": 2.846
        },
        {
            "name": "Phantom Assassin",
            "Abaddon": 0.839
        },
        {
            "name": "Phantom Lancer",
            "Abaddon": 1.231
        },
        {
            "name": "Phoenix",
            "Abaddon": -0.311
        },
        {
            "name": "Primal Beast",
            "Abaddon": 1.684
        },
        {
            "name": "Puck",
            "Abaddon": -0.258
        },
        {
            "name": "Pudge",
            "Abaddon": 0.333
        },
        {
            "name": "Pugna",
            "Abaddon": 1.429
        },
        {
            "name": "Queen of Pain",
            "Abaddon": -0.953
        },
        {
            "name": "Razor",
            "Abaddon": 1.937
        },
        {
            "name": "Riki",
            "Abaddon": 0.748
        },
        {
            "name": "Ringmaster",
            "Abaddon": 1.294
        },
        {
            "name": "Rubick",
            "Abaddon": 2.226
        },
        {
            "name": "Sand King",
            "Abaddon": -0.61
        },
        {
            "name": "Shadow Demon",
            "Abaddon": 4.673
        },
        {
            "name": "Shadow Fiend",
            "Abaddon": 1.817
        },
        {
            "name": "Shadow Shaman",
            "Abaddon": -0.438
        },
        {
            "name": "Silencer",
            "Abaddon": -2.98
        },
        {
            "name": "Skywrath Mage",
            "Abaddon": -1.244
        },
        {
            "name": "Slardar",
            "Abaddon": 0.003
        },
        {
            "name": "Slark",
            "Abaddon": 2.272
        },
        {
            "name": "Snapfire",
            "Abaddon": 1.025
        },
        {
            "name": "Sniper",
            "Abaddon": 0.756
        },
        {
            "name": "Spectre",
            "Abaddon": -3.781
        },
        {
            "name": "Spirit Breaker",
            "Abaddon": 0.139
        },
        {
            "name": "Storm Spirit",
            "Abaddon": 0.015
        },
        {
            "name": "Sven",
            "Abaddon": 2.13
        },
        {
            "name": "Techies",
            "Abaddon": 1.792
        },
        {
            "name": "Templar Assassin",
            "Abaddon": 1.087
        },
        {
            "name": "Terrorblade",
            "Abaddon": 2.879
        },
        {
            "name": "Tidehunter",
            "Abaddon": 0.403
        },
        {
            "name": "Timbersaw",
            "Abaddon": 0.854
        },
        {
            "name": "Tinker",
            "Abaddon": 2.19
        },
        {
            "name": "Tiny",
            "Abaddon": 1.571
        },
        {
            "name": "Treant Protector",
            "Abaddon": 0.16
        },
        {
            "name": "Troll Warlord",
            "Abaddon": -0.669
        },
        {
            "name": "Tusk",
            "Abaddon": 2.673
        },
        {
            "name": "Underlord",
            "Abaddon": 1.561
        },
        {
            "name": "Undying",
            "Abaddon": 3.086
        },
        {
            "name": "Ursa",
            "Abaddon": 1.03
        },
        {
            "name": "Vengeful Spirit",
            "Abaddon": -0.081
        },
        {
            "name": "Venomancer",
            "Abaddon": 0.257
        },
        {
            "name": "Viper",
            "Abaddon": 0.992
        },
        {
            "name": "Visage",
            "Abaddon": 0.808
        },
        {
            "name": "Void Spirit",
            "Abaddon": -0.373
        },
        {
            "name": "Warlock",
            "Abaddon": -0.531
        },
        {
            "name": "Weaver",
            "Abaddon": 2.385
        },
        {
            "name": "Windranger",
            "Abaddon": 1.512
        },
        {
            "name": "Winter Wyvern",
            "Abaddon": 0.14
        },
        {
            "name": "Witch Doctor",
            "Abaddon": -0.628
        },
        {
            "name": "Wraith King",
            "Abaddon": -1.666
        },
        {
            "name": "Zeus",
            "Abaddon": -0.249
        }
    ],
    "Bane": [
        {
            "name": "Abaddon",
            "Bane": 2.415
        },
        {
            "name": "Alchemist",
            "Bane": -0.993
        },
        {
            "name": "Ancient Apparition",
            "Bane": -0.824
        },
        {
            "name": "Anti-Mage",
            "Bane": 0.427
        },
        {
            "name": "Arc Warden",
            "Bane": 1.112
        },
        {
            "name": "Axe",
            "Bane": 0.118
        },
        {
            "name": "Batrider",
            "Bane": -1.512
        },
        {
            "name": "Beastmaster",
            "Bane": 1.603
        },
        {
            "name": "Bloodseeker",
            "Bane": 0.865
        },
        {
            "name": "Bounty Hunter",
            "Bane": -0.234
        },
        {
            "name": "Brewmaster",
            "Bane": -0.958
        },
        {
            "name": "Bristleback",
            "Bane": -0.384
        },
        {
            "name": "Broodmother",
            "Bane": -0.016
        },
        {
            "name": "Centaur Warrunner",
            "Bane": 0.84
        },
        {
            "name": "Chaos Knight",
            "Bane": 2.302
        },
        {
            "name": "Chen",
            "Bane": 1.269
        },
        {
            "name": "Clinkz",
            "Bane": 0.607
        },
        {
            "name": "Clockwerk",
            "Bane": 0.043
        },
        {
            "name": "Crystal Maiden",
            "Bane": 0.282
        },
        {
            "name": "Dark Seer",
            "Bane": -0.706
        },
        {
            "name": "Dark Willow",
            "Bane": 0.113
        },
        {
            "name": "Dawnbreaker",
            "Bane": -0.172
        },
        {
            "name": "Dazzle",
            "Bane": 0.441
        },
        {
            "name": "Death Prophet",
            "Bane": 0.332
        },
        {
            "name": "Disruptor",
            "Bane": -0.537
        },
        {
            "name": "Doom",
            "Bane": 0.537
        },
        {
            "name": "Dragon Knight",
            "Bane": -0.759
        },
        {
            "name": "Drow Ranger",
            "Bane": 0.158
        },
        {
            "name": "Earth Spirit",
            "Bane": 0.102
        },
        {
            "name": "Earthshaker",
            "Bane": 0.324
        },
        {
            "name": "Elder Titan",
            "Bane": 0.392
        },
        {
            "name": "Ember Spirit",
            "Bane": -2.573
        },
        {
            "name": "Enchantress",
            "Bane": 2.515
        },
        {
            "name": "Enigma",
            "Bane": 1.081
        },
        {
            "name": "Faceless Void",
            "Bane": -1.158
        },
        {
            "name": "Grimstroke",
            "Bane": -0.786
        },
        {
            "name": "Gyrocopter",
            "Bane": -0.588
        },
        {
            "name": "Hoodwink",
            "Bane": -0.246
        },
        {
            "name": "Huskar",
            "Bane": -0.379
        },
        {
            "name": "Invoker",
            "Bane": 0.329
        },
        {
            "name": "Io",
            "Bane": -0.617
        },
        {
            "name": "Jakiro",
            "Bane": -0.124
        },
        {
            "name": "Juggernaut",
            "Bane": -0.619
        },
        {
            "name": "Keeper of the Light",
            "Bane": -0.185
        },
        {
            "name": "Kez",
            "Bane": -2.458
        },
        {
            "name": "Kunkka",
            "Bane": 0.195
        },
        {
            "name": "Legion Commander",
            "Bane": -0.736
        },
        {
            "name": "Leshrac",
            "Bane": -0.544
        },
        {
            "name": "Lich",
            "Bane": -1.012
        },
        {
            "name": "Lifestealer",
            "Bane": -1.388
        },
        {
            "name": "Lina",
            "Bane": 0.003
        },
        {
            "name": "Lion",
            "Bane": -0.134
        },
        {
            "name": "Lone Druid",
            "Bane": 2.743
        },
        {
            "name": "Luna",
            "Bane": 0.162
        },
        {
            "name": "Lycan",
            "Bane": -0.415
        },
        {
            "name": "Magnus",
            "Bane": -0.506
        },
        {
            "name": "Marci",
            "Bane": 1.005
        },
        {
            "name": "Mars",
            "Bane": -0.548
        },
        {
            "name": "Medusa",
            "Bane": -0.268
        },
        {
            "name": "Meepo",
            "Bane": 4.653
        },
        {
            "name": "Mirana",
            "Bane": -0.574
        },
        {
            "name": "Monkey King",
            "Bane": 0.073
        },
        {
            "name": "Morphling",
            "Bane": -1.867
        },
        {
            "name": "Muerta",
            "Bane": -1.664
        },
        {
            "name": "Naga Siren",
            "Bane": 4.23
        },
        {
            "name": "Nature's Prophet",
            "Bane": -0.056
        },
        {
            "name": "Necrophos",
            "Bane": 1.42
        },
        {
            "name": "Night Stalker",
            "Bane": -1.155
        },
        {
            "name": "Nyx Assassin",
            "Bane": -0.665
        },
        {
            "name": "Ogre Magi",
            "Bane": 0.452
        },
        {
            "name": "Omniknight",
            "Bane": -1.692
        },
        {
            "name": "Oracle",
            "Bane": -0.663
        },
        {
            "name": "Outworld Destroyer",
            "Bane": 0.212
        },
        {
            "name": "Pangolier",
            "Bane": -1.482
        },
        {
            "name": "Phantom Assassin",
            "Bane": -1.986
        },
        {
            "name": "Phantom Lancer",
            "Bane": 3.02
        },
        {
            "name": "Phoenix",
            "Bane": 0.586
        },
        {
            "name": "Primal Beast",
            "Bane": -0.562
        },
        {
            "name": "Puck",
            "Bane": -0.972
        },
        {
            "name": "Pudge",
            "Bane": 1.016
        },
        {
            "name": "Pugna",
            "Bane": -1.18
        },
        {
            "name": "Queen of Pain",
            "Bane": -1.023
        },
        {
            "name": "Razor",
            "Bane": 0.919
        },
        {
            "name": "Riki",
            "Bane": 0.311
        },
        {
            "name": "Ringmaster",
            "Bane": -0.703
        },
        {
            "name": "Rubick",
            "Bane": -0.854
        },
        {
            "name": "Sand King",
            "Bane": 2.223
        },
        {
            "name": "Shadow Demon",
            "Bane": 0.68
        },
        {
            "name": "Shadow Fiend",
            "Bane": 0.904
        },
        {
            "name": "Shadow Shaman",
            "Bane": 1.02
        },
        {
            "name": "Silencer",
            "Bane": 1.879
        },
        {
            "name": "Skywrath Mage",
            "Bane": -0.714
        },
        {
            "name": "Slardar",
            "Bane": 1.197
        },
        {
            "name": "Slark",
            "Bane": 1.694
        },
        {
            "name": "Snapfire",
            "Bane": 1.767
        },
        {
            "name": "Sniper",
            "Bane": -0.266
        },
        {
            "name": "Spectre",
            "Bane": 1.876
        },
        {
            "name": "Spirit Breaker",
            "Bane": 1.872
        },
        {
            "name": "Storm Spirit",
            "Bane": -2.363
        },
        {
            "name": "Sven",
            "Bane": -1.428
        },
        {
            "name": "Techies",
            "Bane": -1.012
        },
        {
            "name": "Templar Assassin",
            "Bane": 1.097
        },
        {
            "name": "Terrorblade",
            "Bane": 1.274
        },
        {
            "name": "Tidehunter",
            "Bane": 1.004
        },
        {
            "name": "Timbersaw",
            "Bane": -1.191
        },
        {
            "name": "Tinker",
            "Bane": -0.993
        },
        {
            "name": "Tiny",
            "Bane": -0.195
        },
        {
            "name": "Treant Protector",
            "Bane": 0.432
        },
        {
            "name": "Troll Warlord",
            "Bane": -0.975
        },
        {
            "name": "Tusk",
            "Bane": -0.419
        },
        {
            "name": "Underlord",
            "Bane": 0.321
        },
        {
            "name": "Undying",
            "Bane": 0.876
        },
        {
            "name": "Ursa",
            "Bane": 1.415
        },
        {
            "name": "Vengeful Spirit",
            "Bane": 0.699
        },
        {
            "name": "Venomancer",
            "Bane": -1.137
        },
        {
            "name": "Viper",
            "Bane": -0.591
        },
        {
            "name": "Visage",
            "Bane": 0.67
        },
        {
            "name": "Void Spirit",
            "Bane": 1.152
        },
        {
            "name": "Warlock",
            "Bane": 0.195
        },
        {
            "name": "Weaver",
            "Bane": -0.975
        },
        {
            "name": "Windranger",
            "Bane": -1.462
        },
        {
            "name": "Winter Wyvern",
            "Bane": 1.261
        },
        {
            "name": "Witch Doctor",
            "Bane": 0.122
        },
        {
            "name": "Wraith King",
            "Bane": -0.286
        },
        {
            "name": "Zeus",
            "Bane": -0.829
        }
    ],
    "Batrider": [
        {
            "name": "Abaddon",
            "Batrider": 2.066
        },
        {
            "name": "Alchemist",
            "Batrider": -0.822
        },
        {
            "name": "Ancient Apparition",
            "Batrider": -1.392
        },
        {
            "name": "Anti-Mage",
            "Batrider": -0.525
        },
        {
            "name": "Arc Warden",
            "Batrider": -0.966
        },
        {
            "name": "Axe",
            "Batrider": -1.549
        },
        {
            "name": "Bane",
            "Batrider": 1.613
        },
        {
            "name": "Beastmaster",
            "Batrider": -0.485
        },
        {
            "name": "Bloodseeker",
            "Batrider": 0.218
        },
        {
            "name": "Bounty Hunter",
            "Batrider": -0.281
        },
        {
            "name": "Brewmaster",
            "Batrider": -0.698
        },
        {
            "name": "Bristleback",
            "Batrider": -0.548
        },
        {
            "name": "Broodmother",
            "Batrider": -1.526
        },
        {
            "name": "Centaur Warrunner",
            "Batrider": -0.695
        },
        {
            "name": "Chaos Knight",
            "Batrider": 0.116
        },
        {
            "name": "Chen",
            "Batrider": -3.386
        },
        {
            "name": "Clinkz",
            "Batrider": 0.189
        },
        {
            "name": "Clockwerk",
            "Batrider": 0.251
        },
        {
            "name": "Crystal Maiden",
            "Batrider": 1.151
        },
        {
            "name": "Dark Seer",
            "Batrider": -2.361
        },
        {
            "name": "Dark Willow",
            "Batrider": 0.259
        },
        {
            "name": "Dawnbreaker",
            "Batrider": -0.737
        },
        {
            "name": "Dazzle",
            "Batrider": -0.493
        },
        {
            "name": "Death Prophet",
            "Batrider": -1.322
        },
        {
            "name": "Disruptor",
            "Batrider": 0.228
        },
        {
            "name": "Doom",
            "Batrider": -1.84
        },
        {
            "name": "Dragon Knight",
            "Batrider": -0.622
        },
        {
            "name": "Drow Ranger",
            "Batrider": -1.271
        },
        {
            "name": "Earth Spirit",
            "Batrider": 2.122
        },
        {
            "name": "Earthshaker",
            "Batrider": -0.642
        },
        {
            "name": "Elder Titan",
            "Batrider": -1.865
        },
        {
            "name": "Ember Spirit",
            "Batrider": 1.04
        },
        {
            "name": "Enchantress",
            "Batrider": 0.875
        },
        {
            "name": "Enigma",
            "Batrider": 2.174
        },
        {
            "name": "Faceless Void",
            "Batrider": 1.193
        },
        {
            "name": "Grimstroke",
            "Batrider": 1.049
        },
        {
            "name": "Gyrocopter",
            "Batrider": 0.477
        },
        {
            "name": "Hoodwink",
            "Batrider": 0.106
        },
        {
            "name": "Huskar",
            "Batrider": 0.904
        },
        {
            "name": "Invoker",
            "Batrider": -0.602
        },
        {
            "name": "Io",
            "Batrider": -2.218
        },
        {
            "name": "Jakiro",
            "Batrider": 0.576
        },
        {
            "name": "Juggernaut",
            "Batrider": -0.239
        },
        {
            "name": "Keeper of the Light",
            "Batrider": 0.318
        },
        {
            "name": "Kez",
            "Batrider": -0.014
        },
        {
            "name": "Kunkka",
            "Batrider": -0.035
        },
        {
            "name": "Legion Commander",
            "Batrider": 1.216
        },
        {
            "name": "Leshrac",
            "Batrider": 1.069
        },
        {
            "name": "Lich",
            "Batrider": 0.758
        },
        {
            "name": "Lifestealer",
            "Batrider": -0.779
        },
        {
            "name": "Lina",
            "Batrider": -0.878
        },
        {
            "name": "Lion",
            "Batrider": 0.5
        },
        {
            "name": "Lone Druid",
            "Batrider": -0.788
        },
        {
            "name": "Luna",
            "Batrider": -0.23
        },
        {
            "name": "Lycan",
            "Batrider": -1.149
        },
        {
            "name": "Magnus",
            "Batrider": -0.211
        },
        {
            "name": "Marci",
            "Batrider": -0.277
        },
        {
            "name": "Mars",
            "Batrider": -0.269
        },
        {
            "name": "Medusa",
            "Batrider": -0.534
        },
        {
            "name": "Meepo",
            "Batrider": -1.492
        },
        {
            "name": "Mirana",
            "Batrider": -0.013
        },
        {
            "name": "Monkey King",
            "Batrider": -2.546
        },
        {
            "name": "Morphling",
            "Batrider": -0.764
        },
        {
            "name": "Muerta",
            "Batrider": -1.296
        },
        {
            "name": "Naga Siren",
            "Batrider": -0.81
        },
        {
            "name": "Nature's Prophet",
            "Batrider": -1.323
        },
        {
            "name": "Necrophos",
            "Batrider": 0.142
        },
        {
            "name": "Night Stalker",
            "Batrider": -0.657
        },
        {
            "name": "Nyx Assassin",
            "Batrider": -0.055
        },
        {
            "name": "Ogre Magi",
            "Batrider": 0.547
        },
        {
            "name": "Omniknight",
            "Batrider": -1.106
        },
        {
            "name": "Oracle",
            "Batrider": 1.132
        },
        {
            "name": "Outworld Destroyer",
            "Batrider": 0.372
        },
        {
            "name": "Pangolier",
            "Batrider": 1.16
        },
        {
            "name": "Phantom Assassin",
            "Batrider": -0.534
        },
        {
            "name": "Phantom Lancer",
            "Batrider": 0.66
        },
        {
            "name": "Phoenix",
            "Batrider": -0.369
        },
        {
            "name": "Primal Beast",
            "Batrider": 0.015
        },
        {
            "name": "Puck",
            "Batrider": -0.207
        },
        {
            "name": "Pudge",
            "Batrider": -0.373
        },
        {
            "name": "Pugna",
            "Batrider": -0.401
        },
        {
            "name": "Queen of Pain",
            "Batrider": 1.488
        },
        {
            "name": "Razor",
            "Batrider": 0.284
        },
        {
            "name": "Riki",
            "Batrider": -0.798
        },
        {
            "name": "Ringmaster",
            "Batrider": 0.724
        },
        {
            "name": "Rubick",
            "Batrider": -0.589
        },
        {
            "name": "Sand King",
            "Batrider": 1.913
        },
        {
            "name": "Shadow Demon",
            "Batrider": 2.006
        },
        {
            "name": "Shadow Fiend",
            "Batrider": -0.881
        },
        {
            "name": "Shadow Shaman",
            "Batrider": 0.197
        },
        {
            "name": "Silencer",
            "Batrider": 0.253
        },
        {
            "name": "Skywrath Mage",
            "Batrider": 0.061
        },
        {
            "name": "Slardar",
            "Batrider": -0.033
        },
        {
            "name": "Slark",
            "Batrider": 1.563
        },
        {
            "name": "Snapfire",
            "Batrider": -0.679
        },
        {
            "name": "Sniper",
            "Batrider": -0.19
        },
        {
            "name": "Spectre",
            "Batrider": -0.313
        },
        {
            "name": "Spirit Breaker",
            "Batrider": -0.576
        },
        {
            "name": "Storm Spirit",
            "Batrider": 1.978
        },
        {
            "name": "Sven",
            "Batrider": -1.924
        },
        {
            "name": "Techies",
            "Batrider": 0.426
        },
        {
            "name": "Templar Assassin",
            "Batrider": -3.771
        },
        {
            "name": "Terrorblade",
            "Batrider": -0.161
        },
        {
            "name": "Tidehunter",
            "Batrider": 3.448
        },
        {
            "name": "Timbersaw",
            "Batrider": -1.508
        },
        {
            "name": "Tinker",
            "Batrider": 0.66
        },
        {
            "name": "Tiny",
            "Batrider": 0.085
        },
        {
            "name": "Treant Protector",
            "Batrider": -0.549
        },
        {
            "name": "Troll Warlord",
            "Batrider": 0.355
        },
        {
            "name": "Tusk",
            "Batrider": -0.602
        },
        {
            "name": "Underlord",
            "Batrider": -1.198
        },
        {
            "name": "Undying",
            "Batrider": -0.037
        },
        {
            "name": "Ursa",
            "Batrider": 0.068
        },
        {
            "name": "Vengeful Spirit",
            "Batrider": 0.614
        },
        {
            "name": "Venomancer",
            "Batrider": 0.072
        },
        {
            "name": "Viper",
            "Batrider": 2.857
        },
        {
            "name": "Visage",
            "Batrider": -0.667
        },
        {
            "name": "Void Spirit",
            "Batrider": -0.522
        },
        {
            "name": "Warlock",
            "Batrider": -0.126
        },
        {
            "name": "Weaver",
            "Batrider": 1.361
        },
        {
            "name": "Windranger",
            "Batrider": 0.629
        },
        {
            "name": "Winter Wyvern",
            "Batrider": 1.84
        },
        {
            "name": "Witch Doctor",
            "Batrider": 0.005
        },
        {
            "name": "Wraith King",
            "Batrider": 0.073
        },
        {
            "name": "Zeus",
            "Batrider": 0.19
        }
    ],
    "Beastmaster": [
        {
            "name": "Abaddon",
            "Beastmaster": 0.685
        },
        {
            "name": "Alchemist",
            "Beastmaster": 0.637
        },
        {
            "name": "Ancient Apparition",
            "Beastmaster": -0.573
        },
        {
            "name": "Anti-Mage",
            "Beastmaster": -1.244
        },
        {
            "name": "Arc Warden",
            "Beastmaster": -0.614
        },
        {
            "name": "Axe",
            "Beastmaster": -0.058
        },
        {
            "name": "Bane",
            "Beastmaster": -1.672
        },
        {
            "name": "Batrider",
            "Beastmaster": 0.585
        },
        {
            "name": "Bloodseeker",
            "Beastmaster": 0.985
        },
        {
            "name": "Bounty Hunter",
            "Beastmaster": 0.033
        },
        {
            "name": "Brewmaster",
            "Beastmaster": 2.026
        },
        {
            "name": "Bristleback",
            "Beastmaster": 2.64
        },
        {
            "name": "Broodmother",
            "Beastmaster": -1.965
        },
        {
            "name": "Centaur Warrunner",
            "Beastmaster": -0.04
        },
        {
            "name": "Chaos Knight",
            "Beastmaster": -1.752
        },
        {
            "name": "Chen",
            "Beastmaster": 1.619
        },
        {
            "name": "Clinkz",
            "Beastmaster": 1.806
        },
        {
            "name": "Clockwerk",
            "Beastmaster": -2.331
        },
        {
            "name": "Crystal Maiden",
            "Beastmaster": 0.603
        },
        {
            "name": "Dark Seer",
            "Beastmaster": 0.653
        },
        {
            "name": "Dark Willow",
            "Beastmaster": -1.322
        },
        {
            "name": "Dawnbreaker",
            "Beastmaster": 2.426
        },
        {
            "name": "Dazzle",
            "Beastmaster": 1.886
        },
        {
            "name": "Death Prophet",
            "Beastmaster": -1.3
        },
        {
            "name": "Disruptor",
            "Beastmaster": -0.742
        },
        {
            "name": "Doom",
            "Beastmaster": 0.265
        },
        {
            "name": "Dragon Knight",
            "Beastmaster": -0.562
        },
        {
            "name": "Drow Ranger",
            "Beastmaster": 0.585
        },
        {
            "name": "Earth Spirit",
            "Beastmaster": 0.367
        },
        {
            "name": "Earthshaker",
            "Beastmaster": 2.325
        },
        {
            "name": "Elder Titan",
            "Beastmaster": 0.589
        },
        {
            "name": "Ember Spirit",
            "Beastmaster": 0.714
        },
        {
            "name": "Enchantress",
            "Beastmaster": 0.887
        },
        {
            "name": "Enigma",
            "Beastmaster": -0.404
        },
        {
            "name": "Faceless Void",
            "Beastmaster": -0.477
        },
        {
            "name": "Grimstroke",
            "Beastmaster": -0.507
        },
        {
            "name": "Gyrocopter",
            "Beastmaster": 2.35
        },
        {
            "name": "Hoodwink",
            "Beastmaster": 0.229
        },
        {
            "name": "Huskar",
            "Beastmaster": -0.909
        },
        {
            "name": "Invoker",
            "Beastmaster": -0.307
        },
        {
            "name": "Io",
            "Beastmaster": -0.917
        },
        {
            "name": "Jakiro",
            "Beastmaster": 2.183
        },
        {
            "name": "Juggernaut",
            "Beastmaster": -0.662
        },
        {
            "name": "Keeper of the Light",
            "Beastmaster": 0.877
        },
        {
            "name": "Kez",
            "Beastmaster": 1.302
        },
        {
            "name": "Kunkka",
            "Beastmaster": 0.08
        },
        {
            "name": "Legion Commander",
            "Beastmaster": 0.16
        },
        {
            "name": "Leshrac",
            "Beastmaster": 0.112
        },
        {
            "name": "Lich",
            "Beastmaster": 0.745
        },
        {
            "name": "Lifestealer",
            "Beastmaster": -1.287
        },
        {
            "name": "Lina",
            "Beastmaster": 1.73
        },
        {
            "name": "Lion",
            "Beastmaster": -1.102
        },
        {
            "name": "Lone Druid",
            "Beastmaster": -1.487
        },
        {
            "name": "Luna",
            "Beastmaster": -0.285
        },
        {
            "name": "Lycan",
            "Beastmaster": 1.044
        },
        {
            "name": "Magnus",
            "Beastmaster": -0.259
        },
        {
            "name": "Marci",
            "Beastmaster": -0.195
        },
        {
            "name": "Mars",
            "Beastmaster": -1.023
        },
        {
            "name": "Medusa",
            "Beastmaster": 6.754
        },
        {
            "name": "Meepo",
            "Beastmaster": -2.361
        },
        {
            "name": "Mirana",
            "Beastmaster": -0.412
        },
        {
            "name": "Monkey King",
            "Beastmaster": -1.586
        },
        {
            "name": "Morphling",
            "Beastmaster": -1.612
        },
        {
            "name": "Muerta",
            "Beastmaster": -0.025
        },
        {
            "name": "Naga Siren",
            "Beastmaster": -1.582
        },
        {
            "name": "Nature's Prophet",
            "Beastmaster": 0.066
        },
        {
            "name": "Necrophos",
            "Beastmaster": -0.321
        },
        {
            "name": "Night Stalker",
            "Beastmaster": -3.656
        },
        {
            "name": "Nyx Assassin",
            "Beastmaster": -0.754
        },
        {
            "name": "Ogre Magi",
            "Beastmaster": -1.679
        },
        {
            "name": "Omniknight",
            "Beastmaster": 0.311
        },
        {
            "name": "Oracle",
            "Beastmaster": 0.733
        },
        {
            "name": "Outworld Destroyer",
            "Beastmaster": -1.171
        },
        {
            "name": "Pangolier",
            "Beastmaster": 0.903
        },
        {
            "name": "Phantom Assassin",
            "Beastmaster": 0.882
        },
        {
            "name": "Phantom Lancer",
            "Beastmaster": -4.373
        },
        {
            "name": "Phoenix",
            "Beastmaster": 1.237
        },
        {
            "name": "Primal Beast",
            "Beastmaster": 0.136
        },
        {
            "name": "Puck",
            "Beastmaster": -1.327
        },
        {
            "name": "Pudge",
            "Beastmaster": -0.975
        },
        {
            "name": "Pugna",
            "Beastmaster": 0.371
        },
        {
            "name": "Queen of Pain",
            "Beastmaster": 0.162
        },
        {
            "name": "Razor",
            "Beastmaster": 0.899
        },
        {
            "name": "Riki",
            "Beastmaster": -1.255
        },
        {
            "name": "Ringmaster",
            "Beastmaster": 0.369
        },
        {
            "name": "Rubick",
            "Beastmaster": 0.822
        },
        {
            "name": "Sand King",
            "Beastmaster": 2.821
        },
        {
            "name": "Shadow Demon",
            "Beastmaster": -0.647
        },
        {
            "name": "Shadow Fiend",
            "Beastmaster": 0.503
        },
        {
            "name": "Shadow Shaman",
            "Beastmaster": -0.806
        },
        {
            "name": "Silencer",
            "Beastmaster": -0.07
        },
        {
            "name": "Skywrath Mage",
            "Beastmaster": -0.9
        },
        {
            "name": "Slardar",
            "Beastmaster": -1.732
        },
        {
            "name": "Slark",
            "Beastmaster": -0.457
        },
        {
            "name": "Snapfire",
            "Beastmaster": 1.719
        },
        {
            "name": "Sniper",
            "Beastmaster": 0.97
        },
        {
            "name": "Spectre",
            "Beastmaster": -3.717
        },
        {
            "name": "Spirit Breaker",
            "Beastmaster": -0.792
        },
        {
            "name": "Storm Spirit",
            "Beastmaster": -0.403
        },
        {
            "name": "Sven",
            "Beastmaster": 0.205
        },
        {
            "name": "Techies",
            "Beastmaster": 0.063
        },
        {
            "name": "Templar Assassin",
            "Beastmaster": -1.295
        },
        {
            "name": "Terrorblade",
            "Beastmaster": -0.239
        },
        {
            "name": "Tidehunter",
            "Beastmaster": 1.852
        },
        {
            "name": "Timbersaw",
            "Beastmaster": -0.303
        },
        {
            "name": "Tinker",
            "Beastmaster": -1.035
        },
        {
            "name": "Tiny",
            "Beastmaster": -0.49
        },
        {
            "name": "Treant Protector",
            "Beastmaster": -0.555
        },
        {
            "name": "Troll Warlord",
            "Beastmaster": 0.141
        },
        {
            "name": "Tusk",
            "Beastmaster": 1.238
        },
        {
            "name": "Underlord",
            "Beastmaster": -0.337
        },
        {
            "name": "Undying",
            "Beastmaster": 1.795
        },
        {
            "name": "Ursa",
            "Beastmaster": 0.101
        },
        {
            "name": "Vengeful Spirit",
            "Beastmaster": -1.302
        },
        {
            "name": "Venomancer",
            "Beastmaster": 0.785
        },
        {
            "name": "Viper",
            "Beastmaster": 1.276
        },
        {
            "name": "Visage",
            "Beastmaster": -1.455
        },
        {
            "name": "Void Spirit",
            "Beastmaster": -1.042
        },
        {
            "name": "Warlock",
            "Beastmaster": 1.901
        },
        {
            "name": "Weaver",
            "Beastmaster": 0.559
        },
        {
            "name": "Windranger",
            "Beastmaster": -0.131
        },
        {
            "name": "Winter Wyvern",
            "Beastmaster": 0.079
        },
        {
            "name": "Witch Doctor",
            "Beastmaster": 0.741
        },
        {
            "name": "Wraith King",
            "Beastmaster": -1.824
        },
        {
            "name": "Zeus",
            "Beastmaster": -0.856
        }
    ],
    "Brewmaster": [
        {
            "name": "Abaddon",
            "Brewmaster": -1.835
        },
        {
            "name": "Alchemist",
            "Brewmaster": -0.15
        },
        {
            "name": "Ancient Apparition",
            "Brewmaster": -3.915
        },
        {
            "name": "Anti-Mage",
            "Brewmaster": 0.539
        },
        {
            "name": "Arc Warden",
            "Brewmaster": -1.013
        },
        {
            "name": "Axe",
            "Brewmaster": -0.523
        },
        {
            "name": "Bane",
            "Brewmaster": 0.99
        },
        {
            "name": "Batrider",
            "Brewmaster": 0.686
        },
        {
            "name": "Beastmaster",
            "Brewmaster": -2.101
        },
        {
            "name": "Bloodseeker",
            "Brewmaster": 0.172
        },
        {
            "name": "Bounty Hunter",
            "Brewmaster": 1.286
        },
        {
            "name": "Bristleback",
            "Brewmaster": 2.565
        },
        {
            "name": "Broodmother",
            "Brewmaster": 1.472
        },
        {
            "name": "Centaur Warrunner",
            "Brewmaster": 0.335
        },
        {
            "name": "Chaos Knight",
            "Brewmaster": -1.165
        },
        {
            "name": "Chen",
            "Brewmaster": 1.263
        },
        {
            "name": "Clinkz",
            "Brewmaster": -1.085
        },
        {
            "name": "Clockwerk",
            "Brewmaster": -2.104
        },
        {
            "name": "Crystal Maiden",
            "Brewmaster": 0.757
        },
        {
            "name": "Dark Seer",
            "Brewmaster": -1.738
        },
        {
            "name": "Dark Willow",
            "Brewmaster": -2.015
        },
        {
            "name": "Dawnbreaker",
            "Brewmaster": 0.099
        },
        {
            "name": "Dazzle",
            "Brewmaster": -0.918
        },
        {
            "name": "Death Prophet",
            "Brewmaster": 1.721
        },
        {
            "name": "Disruptor",
            "Brewmaster": -0.476
        },
        {
            "name": "Doom",
            "Brewmaster": 1.129
        },
        {
            "name": "Dragon Knight",
            "Brewmaster": -0.732
        },
        {
            "name": "Drow Ranger",
            "Brewmaster": -0.857
        },
        {
            "name": "Earth Spirit",
            "Brewmaster": 2.462
        },
        {
            "name": "Earthshaker",
            "Brewmaster": 1.604
        },
        {
            "name": "Elder Titan",
            "Brewmaster": 0.886
        },
        {
            "name": "Ember Spirit",
            "Brewmaster": -0.72
        },
        {
            "name": "Enchantress",
            "Brewmaster": -0.612
        },
        {
            "name": "Enigma",
            "Brewmaster": -0.612
        },
        {
            "name": "Faceless Void",
            "Brewmaster": -0.035
        },
        {
            "name": "Grimstroke",
            "Brewmaster": 0.534
        },
        {
            "name": "Gyrocopter",
            "Brewmaster": 0.358
        },
        {
            "name": "Hoodwink",
            "Brewmaster": -0.804
        },
        {
            "name": "Huskar",
            "Brewmaster": 0.697
        },
        {
            "name": "Invoker",
            "Brewmaster": -0.692
        },
        {
            "name": "Io",
            "Brewmaster": 1.556
        },
        {
            "name": "Jakiro",
            "Brewmaster": 1.397
        },
        {
            "name": "Juggernaut",
            "Brewmaster": 0.12
        },
        {
            "name": "Keeper of the Light",
            "Brewmaster": -1.735
        },
        {
            "name": "Kez",
            "Brewmaster": -0.476
        },
        {
            "name": "Kunkka",
            "Brewmaster": 0.735
        },
        {
            "name": "Legion Commander",
            "Brewmaster": 1.129
        },
        {
            "name": "Leshrac",
            "Brewmaster": 4.699
        },
        {
            "name": "Lich",
            "Brewmaster": -0.655
        },
        {
            "name": "Lifestealer",
            "Brewmaster": 2.147
        },
        {
            "name": "Lina",
            "Brewmaster": 2.281
        },
        {
            "name": "Lion",
            "Brewmaster": -0.26
        },
        {
            "name": "Lone Druid",
            "Brewmaster": -3.977
        },
        {
            "name": "Luna",
            "Brewmaster": 1.867
        },
        {
            "name": "Lycan",
            "Brewmaster": -0.13
        },
        {
            "name": "Magnus",
            "Brewmaster": -0.103
        },
        {
            "name": "Marci",
            "Brewmaster": 0.478
        },
        {
            "name": "Mars",
            "Brewmaster": 0.898
        },
        {
            "name": "Medusa",
            "Brewmaster": -0.197
        },
        {
            "name": "Meepo",
            "Brewmaster": -1.984
        },
        {
            "name": "Mirana",
            "Brewmaster": 2.423
        },
        {
            "name": "Monkey King",
            "Brewmaster": -0.589
        },
        {
            "name": "Morphling",
            "Brewmaster": 0.692
        },
        {
            "name": "Muerta",
            "Brewmaster": -0.4
        },
        {
            "name": "Naga Siren",
            "Brewmaster": 0.709
        },
        {
            "name": "Nature's Prophet",
            "Brewmaster": 0.878
        },
        {
            "name": "Necrophos",
            "Brewmaster": 0.839
        },
        {
            "name": "Night Stalker",
            "Brewmaster": -0.345
        },
        {
            "name": "Nyx Assassin",
            "Brewmaster": 0.198
        },
        {
            "name": "Ogre Magi",
            "Brewmaster": 0.033
        },
        {
            "name": "Omniknight",
            "Brewmaster": -1.923
        },
        {
            "name": "Oracle",
            "Brewmaster": 1.152
        },
        {
            "name": "Outworld Destroyer",
            "Brewmaster": -1.185
        },
        {
            "name": "Pangolier",
            "Brewmaster": 1.865
        },
        {
            "name": "Phantom Assassin",
            "Brewmaster": -0.162
        },
        {
            "name": "Phantom Lancer",
            "Brewmaster": 0.029
        },
        {
            "name": "Phoenix",
            "Brewmaster": 0.781
        },
        {
            "name": "Primal Beast",
            "Brewmaster": -0.967
        },
        {
            "name": "Puck",
            "Brewmaster": 1.616
        },
        {
            "name": "Pudge",
            "Brewmaster": 0.09
        },
        {
            "name": "Pugna",
            "Brewmaster": 0.543
        },
        {
            "name": "Queen of Pain",
            "Brewmaster": 0.838
        },
        {
            "name": "Razor",
            "Brewmaster": -1.024
        },
        {
            "name": "Riki",
            "Brewmaster": -0.704
        },
        {
            "name": "Ringmaster",
            "Brewmaster": 0.308
        },
        {
            "name": "Rubick",
            "Brewmaster": 0.088
        },
        {
            "name": "Sand King",
            "Brewmaster": 2.777
        },
        {
            "name": "Shadow Demon",
            "Brewmaster": -1.391
        },
        {
            "name": "Shadow Fiend",
            "Brewmaster": -1.733
        },
        {
            "name": "Shadow Shaman",
            "Brewmaster": 0.032
        },
        {
            "name": "Silencer",
            "Brewmaster": -0.282
        },
        {
            "name": "Skywrath Mage",
            "Brewmaster": 1.264
        },
        {
            "name": "Slardar",
            "Brewmaster": 0.008
        },
        {
            "name": "Slark",
            "Brewmaster": 2.251
        },
        {
            "name": "Snapfire",
            "Brewmaster": -0.018
        },
        {
            "name": "Sniper",
            "Brewmaster": -0.669
        },
        {
            "name": "Spectre",
            "Brewmaster": -0.254
        },
        {
            "name": "Spirit Breaker",
            "Brewmaster": 0.716
        },
        {
            "name": "Storm Spirit",
            "Brewmaster": -0.378
        },
        {
            "name": "Sven",
            "Brewmaster": 1.464
        },
        {
            "name": "Techies",
            "Brewmaster": -0.61
        },
        {
            "name": "Templar Assassin",
            "Brewmaster": -1.682
        },
        {
            "name": "Terrorblade",
            "Brewmaster": -2.182
        },
        {
            "name": "Tidehunter",
            "Brewmaster": 1.256
        },
        {
            "name": "Timbersaw",
            "Brewmaster": 0.183
        },
        {
            "name": "Tinker",
            "Brewmaster": -0.716
        },
        {
            "name": "Tiny",
            "Brewmaster": 0.061
        },
        {
            "name": "Treant Protector",
            "Brewmaster": 0.421
        },
        {
            "name": "Troll Warlord",
            "Brewmaster": -2.651
        },
        {
            "name": "Tusk",
            "Brewmaster": -1.063
        },
        {
            "name": "Underlord",
            "Brewmaster": -0.588
        },
        {
            "name": "Undying",
            "Brewmaster": 0.287
        },
        {
            "name": "Ursa",
            "Brewmaster": -2.181
        },
        {
            "name": "Vengeful Spirit",
            "Brewmaster": -1.61
        },
        {
            "name": "Venomancer",
            "Brewmaster": -0.998
        },
        {
            "name": "Viper",
            "Brewmaster": -3.226
        },
        {
            "name": "Visage",
            "Brewmaster": -0.408
        },
        {
            "name": "Void Spirit",
            "Brewmaster": -0.463
        },
        {
            "name": "Warlock",
            "Brewmaster": 1.468
        },
        {
            "name": "Weaver",
            "Brewmaster": 1.743
        },
        {
            "name": "Windranger",
            "Brewmaster": 0.562
        },
        {
            "name": "Winter Wyvern",
            "Brewmaster": -0.188
        },
        {
            "name": "Witch Doctor",
            "Brewmaster": -0.743
        },
        {
            "name": "Wraith King",
            "Brewmaster": -1.774
        },
        {
            "name": "Zeus",
            "Brewmaster": -0.719
        }
    ],
    "Broodmother": [
        {
            "name": "Abaddon",
            "Broodmother": -2.067
        },
        {
            "name": "Alchemist",
            "Broodmother": -0.119
        },
        {
            "name": "Ancient Apparition",
            "Broodmother": -0.835
        },
        {
            "name": "Anti-Mage",
            "Broodmother": -1.65
        },
        {
            "name": "Arc Warden",
            "Broodmother": -4.903
        },
        {
            "name": "Axe",
            "Broodmother": 3.748
        },
        {
            "name": "Bane",
            "Broodmother": -0.018
        },
        {
            "name": "Batrider",
            "Broodmother": 1.558
        },
        {
            "name": "Beastmaster",
            "Broodmother": 1.956
        },
        {
            "name": "Bloodseeker",
            "Broodmother": -0.099
        },
        {
            "name": "Bounty Hunter",
            "Broodmother": 1.085
        },
        {
            "name": "Brewmaster",
            "Broodmother": -1.343
        },
        {
            "name": "Bristleback",
            "Broodmother": 1.076
        },
        {
            "name": "Centaur Warrunner",
            "Broodmother": -0.46
        },
        {
            "name": "Chaos Knight",
            "Broodmother": 0.004
        },
        {
            "name": "Chen",
            "Broodmother": -1.351
        },
        {
            "name": "Clinkz",
            "Broodmother": -2.499
        },
        {
            "name": "Clockwerk",
            "Broodmother": -2.093
        },
        {
            "name": "Crystal Maiden",
            "Broodmother": 3.457
        },
        {
            "name": "Dark Seer",
            "Broodmother": 2.948
        },
        {
            "name": "Dark Willow",
            "Broodmother": 0.084
        },
        {
            "name": "Dawnbreaker",
            "Broodmother": -0.91
        },
        {
            "name": "Dazzle",
            "Broodmother": -1.283
        },
        {
            "name": "Death Prophet",
            "Broodmother": -1.567
        },
        {
            "name": "Disruptor",
            "Broodmother": 0.39
        },
        {
            "name": "Doom",
            "Broodmother": -1.047
        },
        {
            "name": "Dragon Knight",
            "Broodmother": 0.931
        },
        {
            "name": "Drow Ranger",
            "Broodmother": -0.162
        },
        {
            "name": "Earth Spirit",
            "Broodmother": -0.687
        },
        {
            "name": "Earthshaker",
            "Broodmother": 7.818
        },
        {
            "name": "Elder Titan",
            "Broodmother": -1.884
        },
        {
            "name": "Ember Spirit",
            "Broodmother": -3.957
        },
        {
            "name": "Enchantress",
            "Broodmother": -0.691
        },
        {
            "name": "Enigma",
            "Broodmother": 0.643
        },
        {
            "name": "Faceless Void",
            "Broodmother": -2.183
        },
        {
            "name": "Grimstroke",
            "Broodmother": 1.936
        },
        {
            "name": "Gyrocopter",
            "Broodmother": -1.162
        },
        {
            "name": "Hoodwink",
            "Broodmother": 0.404
        },
        {
            "name": "Huskar",
            "Broodmother": -3.414
        },
        {
            "name": "Invoker",
            "Broodmother": -3.435
        },
        {
            "name": "Io",
            "Broodmother": -1.115
        },
        {
            "name": "Jakiro",
            "Broodmother": 1.926
        },
        {
            "name": "Juggernaut",
            "Broodmother": -2.031
        },
        {
            "name": "Keeper of the Light",
            "Broodmother": -1.147
        },
        {
            "name": "Kez",
            "Broodmother": 0.725
        },
        {
            "name": "Kunkka",
            "Broodmother": 0.916
        },
        {
            "name": "Legion Commander",
            "Broodmother": 0.641
        },
        {
            "name": "Leshrac",
            "Broodmother": 0.531
        },
        {
            "name": "Lich",
            "Broodmother": 0.243
        },
        {
            "name": "Lifestealer",
            "Broodmother": -2.968
        },
        {
            "name": "Lina",
            "Broodmother": 2.024
        },
        {
            "name": "Lion",
            "Broodmother": 1.129
        },
        {
            "name": "Lone Druid",
            "Broodmother": -0.699
        },
        {
            "name": "Luna",
            "Broodmother": -1.465
        },
        {
            "name": "Lycan",
            "Broodmother": -2.449
        },
        {
            "name": "Magnus",
            "Broodmother": 1.419
        },
        {
            "name": "Marci",
            "Broodmother": 2.132
        },
        {
            "name": "Mars",
            "Broodmother": 0.511
        },
        {
            "name": "Medusa",
            "Broodmother": -0.157
        },
        {
            "name": "Meepo",
            "Broodmother": 4.004
        },
        {
            "name": "Mirana",
            "Broodmother": 0.035
        },
        {
            "name": "Monkey King",
            "Broodmother": 1.782
        },
        {
            "name": "Morphling",
            "Broodmother": -2.554
        },
        {
            "name": "Muerta",
            "Broodmother": -0.329
        },
        {
            "name": "Naga Siren",
            "Broodmother": 4.206
        },
        {
            "name": "Nature's Prophet",
            "Broodmother": -3.043
        },
        {
            "name": "Necrophos",
            "Broodmother": 2.985
        },
        {
            "name": "Night Stalker",
            "Broodmother": -4.276
        },
        {
            "name": "Nyx Assassin",
            "Broodmother": -0.401
        },
        {
            "name": "Ogre Magi",
            "Broodmother": -1.312
        },
        {
            "name": "Omniknight",
            "Broodmother": 3.238
        },
        {
            "name": "Oracle",
            "Broodmother": -1.91
        },
        {
            "name": "Outworld Destroyer",
            "Broodmother": -4.321
        },
        {
            "name": "Pangolier",
            "Broodmother": -0.341
        },
        {
            "name": "Phantom Assassin",
            "Broodmother": 1.999
        },
        {
            "name": "Phantom Lancer",
            "Broodmother": 2.364
        },
        {
            "name": "Phoenix",
            "Broodmother": 1.671
        },
        {
            "name": "Primal Beast",
            "Broodmother": 1.61
        },
        {
            "name": "Puck",
            "Broodmother": -3.679
        },
        {
            "name": "Pudge",
            "Broodmother": -0.866
        },
        {
            "name": "Pugna",
            "Broodmother": -0.75
        },
        {
            "name": "Queen of Pain",
            "Broodmother": 1.051
        },
        {
            "name": "Razor",
            "Broodmother": 0.254
        },
        {
            "name": "Riki",
            "Broodmother": -1.724
        },
        {
            "name": "Ringmaster",
            "Broodmother": -1.216
        },
        {
            "name": "Rubick",
            "Broodmother": 0.096
        },
        {
            "name": "Sand King",
            "Broodmother": 3.161
        },
        {
            "name": "Shadow Demon",
            "Broodmother": 0.444
        },
        {
            "name": "Shadow Fiend",
            "Broodmother": 1.818
        },
        {
            "name": "Shadow Shaman",
            "Broodmother": 0.61
        },
        {
            "name": "Silencer",
            "Broodmother": -3.457
        },
        {
            "name": "Skywrath Mage",
            "Broodmother": -0.627
        },
        {
            "name": "Slardar",
            "Broodmother": 1.579
        },
        {
            "name": "Slark",
            "Broodmother": -0.646
        },
        {
            "name": "Snapfire",
            "Broodmother": -0.57
        },
        {
            "name": "Sniper",
            "Broodmother": 0.743
        },
        {
            "name": "Spectre",
            "Broodmother": -1.895
        },
        {
            "name": "Spirit Breaker",
            "Broodmother": 2.786
        },
        {
            "name": "Storm Spirit",
            "Broodmother": 2.72
        },
        {
            "name": "Sven",
            "Broodmother": 0.44
        },
        {
            "name": "Techies",
            "Broodmother": 2.331
        },
        {
            "name": "Templar Assassin",
            "Broodmother": -0.246
        },
        {
            "name": "Terrorblade",
            "Broodmother": 4.015
        },
        {
            "name": "Tidehunter",
            "Broodmother": 2.69
        },
        {
            "name": "Timbersaw",
            "Broodmother": 0.726
        },
        {
            "name": "Tinker",
            "Broodmother": 0.892
        },
        {
            "name": "Tiny",
            "Broodmother": 1.474
        },
        {
            "name": "Treant Protector",
            "Broodmother": 1.548
        },
        {
            "name": "Troll Warlord",
            "Broodmother": 1.255
        },
        {
            "name": "Tusk",
            "Broodmother": 0.473
        },
        {
            "name": "Underlord",
            "Broodmother": 0.679
        },
        {
            "name": "Undying",
            "Broodmother": -1.267
        },
        {
            "name": "Ursa",
            "Broodmother": -2.067
        },
        {
            "name": "Vengeful Spirit",
            "Broodmother": 0.527
        },
        {
            "name": "Venomancer",
            "Broodmother": -0.85
        },
        {
            "name": "Viper",
            "Broodmother": -0.981
        },
        {
            "name": "Visage",
            "Broodmother": 1.822
        },
        {
            "name": "Void Spirit",
            "Broodmother": -2.701
        },
        {
            "name": "Warlock",
            "Broodmother": -0.148
        },
        {
            "name": "Weaver",
            "Broodmother": -1.22
        },
        {
            "name": "Windranger",
            "Broodmother": 0.015
        },
        {
            "name": "Winter Wyvern",
            "Broodmother": 2.92
        },
        {
            "name": "Witch Doctor",
            "Broodmother": -1.624
        },
        {
            "name": "Wraith King",
            "Broodmother": -2.141
        },
        {
            "name": "Zeus",
            "Broodmother": -1.641
        }
    ],
    "Chen": [
        {
            "name": "Abaddon",
            "Chen": -0.164
        },
        {
            "name": "Alchemist",
            "Chen": -2.257
        },
        {
            "name": "Ancient Apparition",
            "Chen": 1.626
        },
        {
            "name": "Anti-Mage",
            "Chen": 1.41
        },
        {
            "name": "Arc Warden",
            "Chen": -0.778
        },
        {
            "name": "Axe",
            "Chen": 0.961
        },
        {
            "name": "Bane",
            "Chen": -1.1
        },
        {
            "name": "Batrider",
            "Chen": 3.341
        },
        {
            "name": "Beastmaster",
            "Chen": -1.545
        },
        {
            "name": "Bloodseeker",
            "Chen": -1.384
        },
        {
            "name": "Bounty Hunter",
            "Chen": -0.025
        },
        {
            "name": "Brewmaster",
            "Chen": -1.315
        },
        {
            "name": "Bristleback",
            "Chen": 0.708
        },
        {
            "name": "Broodmother",
            "Chen": 1.493
        },
        {
            "name": "Centaur Warrunner",
            "Chen": 0.15
        },
        {
            "name": "Chaos Knight",
            "Chen": 1.473
        },
        {
            "name": "Clinkz",
            "Chen": -1.138
        },
        {
            "name": "Clockwerk",
            "Chen": -0.305
        },
        {
            "name": "Crystal Maiden",
            "Chen": -0.021
        },
        {
            "name": "Dark Seer",
            "Chen": -1.517
        },
        {
            "name": "Dark Willow",
            "Chen": -1.076
        },
        {
            "name": "Dawnbreaker",
            "Chen": 0.704
        },
        {
            "name": "Dazzle",
            "Chen": 0.564
        },
        {
            "name": "Death Prophet",
            "Chen": -0.288
        },
        {
            "name": "Disruptor",
            "Chen": -0.521
        },
        {
            "name": "Doom",
            "Chen": 2.647
        },
        {
            "name": "Dragon Knight",
            "Chen": 0.783
        },
        {
            "name": "Drow Ranger",
            "Chen": -1.31
        },
        {
            "name": "Earth Spirit",
            "Chen": -0.161
        },
        {
            "name": "Earthshaker",
            "Chen": 2.661
        },
        {
            "name": "Elder Titan",
            "Chen": -4.001
        },
        {
            "name": "Ember Spirit",
            "Chen": 0.155
        },
        {
            "name": "Enchantress",
            "Chen": -2.403
        },
        {
            "name": "Enigma",
            "Chen": 0.635
        },
        {
            "name": "Faceless Void",
            "Chen": 1.686
        },
        {
            "name": "Grimstroke",
            "Chen": 0.39
        },
        {
            "name": "Gyrocopter",
            "Chen": 1.432
        },
        {
            "name": "Hoodwink",
            "Chen": -0.294
        },
        {
            "name": "Huskar",
            "Chen": -2.618
        },
        {
            "name": "Invoker",
            "Chen": 0.184
        },
        {
            "name": "Io",
            "Chen": 3.441
        },
        {
            "name": "Jakiro",
            "Chen": 0.214
        },
        {
            "name": "Juggernaut",
            "Chen": -2.579
        },
        {
            "name": "Keeper of the Light",
            "Chen": 0.93
        },
        {
            "name": "Kez",
            "Chen": 0.931
        },
        {
            "name": "Kunkka",
            "Chen": 2.826
        },
        {
            "name": "Legion Commander",
            "Chen": -0.047
        },
        {
            "name": "Leshrac",
            "Chen": 0.9
        },
        {
            "name": "Lich",
            "Chen": 0.326
        },
        {
            "name": "Lifestealer",
            "Chen": -0.551
        },
        {
            "name": "Lina",
            "Chen": -0.191
        },
        {
            "name": "Lion",
            "Chen": 0.734
        },
        {
            "name": "Lone Druid",
            "Chen": 0.202
        },
        {
            "name": "Luna",
            "Chen": -0.427
        },
        {
            "name": "Lycan",
            "Chen": -2.774
        },
        {
            "name": "Magnus",
            "Chen": 1.807
        },
        {
            "name": "Marci",
            "Chen": 1.504
        },
        {
            "name": "Mars",
            "Chen": -4.409
        },
        {
            "name": "Medusa",
            "Chen": -3.087
        },
        {
            "name": "Meepo",
            "Chen": -1.612
        },
        {
            "name": "Mirana",
            "Chen": 0.121
        },
        {
            "name": "Monkey King",
            "Chen": 2.531
        },
        {
            "name": "Morphling",
            "Chen": -0.461
        },
        {
            "name": "Muerta",
            "Chen": -0.137
        },
        {
            "name": "Naga Siren",
            "Chen": 5.91
        },
        {
            "name": "Nature's Prophet",
            "Chen": -0.855
        },
        {
            "name": "Necrophos",
            "Chen": 2.288
        },
        {
            "name": "Night Stalker",
            "Chen": -1.305
        },
        {
            "name": "Nyx Assassin",
            "Chen": -0.655
        },
        {
            "name": "Ogre Magi",
            "Chen": -0.316
        },
        {
            "name": "Omniknight",
            "Chen": -0.07
        },
        {
            "name": "Oracle",
            "Chen": 0.824
        },
        {
            "name": "Outworld Destroyer",
            "Chen": -1.708
        },
        {
            "name": "Pangolier",
            "Chen": -1.697
        },
        {
            "name": "Phantom Assassin",
            "Chen": 0.449
        },
        {
            "name": "Phantom Lancer",
            "Chen": 5.512
        },
        {
            "name": "Phoenix",
            "Chen": 0.599
        },
        {
            "name": "Primal Beast",
            "Chen": 2.741
        },
        {
            "name": "Puck",
            "Chen": -0.541
        },
        {
            "name": "Pudge",
            "Chen": -0.611
        },
        {
            "name": "Pugna",
            "Chen": -3.862
        },
        {
            "name": "Queen of Pain",
            "Chen": -0.724
        },
        {
            "name": "Razor",
            "Chen": 0.807
        },
        {
            "name": "Riki",
            "Chen": 0.098
        },
        {
            "name": "Ringmaster",
            "Chen": -1.552
        },
        {
            "name": "Rubick",
            "Chen": -0.536
        },
        {
            "name": "Sand King",
            "Chen": -1.725
        },
        {
            "name": "Shadow Demon",
            "Chen": -3.561
        },
        {
            "name": "Shadow Fiend",
            "Chen": 0.639
        },
        {
            "name": "Shadow Shaman",
            "Chen": 0.533
        },
        {
            "name": "Silencer",
            "Chen": -2.096
        },
        {
            "name": "Skywrath Mage",
            "Chen": -0.078
        },
        {
            "name": "Slardar",
            "Chen": 1.425
        },
        {
            "name": "Slark",
            "Chen": 2.381
        },
        {
            "name": "Snapfire",
            "Chen": 1.767
        },
        {
            "name": "Sniper",
            "Chen": -1.54
        },
        {
            "name": "Spectre",
            "Chen": -2.29
        },
        {
            "name": "Spirit Breaker",
            "Chen": 2.559
        },
        {
            "name": "Storm Spirit",
            "Chen": -0.403
        },
        {
            "name": "Sven",
            "Chen": 0.267
        },
        {
            "name": "Techies",
            "Chen": -0.525
        },
        {
            "name": "Templar Assassin",
            "Chen": 1.347
        },
        {
            "name": "Terrorblade",
            "Chen": -1.004
        },
        {
            "name": "Tidehunter",
            "Chen": 1.167
        },
        {
            "name": "Timbersaw",
            "Chen": 1.951
        },
        {
            "name": "Tinker",
            "Chen": 2.788
        },
        {
            "name": "Tiny",
            "Chen": 0.106
        },
        {
            "name": "Treant Protector",
            "Chen": 0.554
        },
        {
            "name": "Troll Warlord",
            "Chen": 1.011
        },
        {
            "name": "Tusk",
            "Chen": 2.448
        },
        {
            "name": "Underlord",
            "Chen": -2.619
        },
        {
            "name": "Undying",
            "Chen": 0.071
        },
        {
            "name": "Ursa",
            "Chen": 1.316
        },
        {
            "name": "Vengeful Spirit",
            "Chen": -0.326
        },
        {
            "name": "Venomancer",
            "Chen": -0.117
        },
        {
            "name": "Viper",
            "Chen": 0.431
        },
        {
            "name": "Visage",
            "Chen": -3.05
        },
        {
            "name": "Void Spirit",
            "Chen": -1.299
        },
        {
            "name": "Warlock",
            "Chen": 0.045
        },
        {
            "name": "Weaver",
            "Chen": 1.359
        },
        {
            "name": "Windranger",
            "Chen": 0.173
        },
        {
            "name": "Winter Wyvern",
            "Chen": 1.643
        },
        {
            "name": "Witch Doctor",
            "Chen": 0.914
        },
        {
            "name": "Wraith King",
            "Chen": -3.331
        },
        {
            "name": "Zeus",
            "Chen": -3.322
        }
    ],
    "Clockwerk": [
        {
            "name": "Abaddon",
            "Clockwerk": -0.394
        },
        {
            "name": "Alchemist",
            "Clockwerk": -0.162
        },
        {
            "name": "Ancient Apparition",
            "Clockwerk": 0.825
        },
        {
            "name": "Anti-Mage",
            "Clockwerk": 2.358
        },
        {
            "name": "Arc Warden",
            "Clockwerk": 0.047
        },
        {
            "name": "Axe",
            "Clockwerk": -0.637
        },
        {
            "name": "Bane",
            "Clockwerk": 0.059
        },
        {
            "name": "Batrider",
            "Clockwerk": 0.299
        },
        {
            "name": "Beastmaster",
            "Clockwerk": 2.426
        },
        {
            "name": "Bloodseeker",
            "Clockwerk": -0.599
        },
        {
            "name": "Bounty Hunter",
            "Clockwerk": -0.146
        },
        {
            "name": "Brewmaster",
            "Clockwerk": 2.305
        },
        {
            "name": "Bristleback",
            "Clockwerk": -0.32
        },
        {
            "name": "Broodmother",
            "Clockwerk": 2.117
        },
        {
            "name": "Centaur Warrunner",
            "Clockwerk": -0.304
        },
        {
            "name": "Chaos Knight",
            "Clockwerk": -0.413
        },
        {
            "name": "Chen",
            "Clockwerk": 0.793
        },
        {
            "name": "Clinkz",
            "Clockwerk": -0.359
        },
        {
            "name": "Crystal Maiden",
            "Clockwerk": -0.603
        },
        {
            "name": "Dark Seer",
            "Clockwerk": 1.512
        },
        {
            "name": "Dark Willow",
            "Clockwerk": -0.156
        },
        {
            "name": "Dawnbreaker",
            "Clockwerk": -0.16
        },
        {
            "name": "Dazzle",
            "Clockwerk": 1.463
        },
        {
            "name": "Death Prophet",
            "Clockwerk": -0.284
        },
        {
            "name": "Disruptor",
            "Clockwerk": 0.364
        },
        {
            "name": "Doom",
            "Clockwerk": 0.496
        },
        {
            "name": "Dragon Knight",
            "Clockwerk": -0.09
        },
        {
            "name": "Drow Ranger",
            "Clockwerk": -1.916
        },
        {
            "name": "Earth Spirit",
            "Clockwerk": 1.144
        },
        {
            "name": "Earthshaker",
            "Clockwerk": -1.688
        },
        {
            "name": "Elder Titan",
            "Clockwerk": 0.388
        },
        {
            "name": "Ember Spirit",
            "Clockwerk": 2.072
        },
        {
            "name": "Enchantress",
            "Clockwerk": -0.081
        },
        {
            "name": "Enigma",
            "Clockwerk": 1.132
        },
        {
            "name": "Faceless Void",
            "Clockwerk": 1.901
        },
        {
            "name": "Grimstroke",
            "Clockwerk": 0.614
        },
        {
            "name": "Gyrocopter",
            "Clockwerk": 0.259
        },
        {
            "name": "Hoodwink",
            "Clockwerk": -0.71
        },
        {
            "name": "Huskar",
            "Clockwerk": 4.334
        },
        {
            "name": "Invoker",
            "Clockwerk": 0.02
        },
        {
            "name": "Io",
            "Clockwerk": 1.064
        },
        {
            "name": "Jakiro",
            "Clockwerk": 1.086
        },
        {
            "name": "Juggernaut",
            "Clockwerk": 1.648
        },
        {
            "name": "Keeper of the Light",
            "Clockwerk": 1.331
        },
        {
            "name": "Kez",
            "Clockwerk": 0.364
        },
        {
            "name": "Kunkka",
            "Clockwerk": 0.922
        },
        {
            "name": "Legion Commander",
            "Clockwerk": -0.438
        },
        {
            "name": "Leshrac",
            "Clockwerk": -0.851
        },
        {
            "name": "Lich",
            "Clockwerk": 0.764
        },
        {
            "name": "Lifestealer",
            "Clockwerk": 1.805
        },
        {
            "name": "Lina",
            "Clockwerk": 0.432
        },
        {
            "name": "Lion",
            "Clockwerk": 0.114
        },
        {
            "name": "Lone Druid",
            "Clockwerk": 2.149
        },
        {
            "name": "Luna",
            "Clockwerk": -0.392
        },
        {
            "name": "Lycan",
            "Clockwerk": 1.037
        },
        {
            "name": "Magnus",
            "Clockwerk": -0.101
        },
        {
            "name": "Marci",
            "Clockwerk": 0.054
        },
        {
            "name": "Mars",
            "Clockwerk": -0.02
        },
        {
            "name": "Medusa",
            "Clockwerk": -1.249
        },
        {
            "name": "Meepo",
            "Clockwerk": 3.592
        },
        {
            "name": "Mirana",
            "Clockwerk": 0.161
        },
        {
            "name": "Monkey King",
            "Clockwerk": 0.21
        },
        {
            "name": "Morphling",
            "Clockwerk": 2.136
        },
        {
            "name": "Muerta",
            "Clockwerk": 0.162
        },
        {
            "name": "Naga Siren",
            "Clockwerk": 0.15
        },
        {
            "name": "Nature's Prophet",
            "Clockwerk": -0.361
        },
        {
            "name": "Necrophos",
            "Clockwerk": 0.155
        },
        {
            "name": "Night Stalker",
            "Clockwerk": -0.225
        },
        {
            "name": "Nyx Assassin",
            "Clockwerk": -0.481
        },
        {
            "name": "Ogre Magi",
            "Clockwerk": 0.418
        },
        {
            "name": "Omniknight",
            "Clockwerk": 1.166
        },
        {
            "name": "Oracle",
            "Clockwerk": 0.354
        },
        {
            "name": "Outworld Destroyer",
            "Clockwerk": 0.317
        },
        {
            "name": "Pangolier",
            "Clockwerk": -0.899
        },
        {
            "name": "Phantom Assassin",
            "Clockwerk": -0.315
        },
        {
            "name": "Phantom Lancer",
            "Clockwerk": 1.932
        },
        {
            "name": "Phoenix",
            "Clockwerk": 0.73
        },
        {
            "name": "Primal Beast",
            "Clockwerk": -2.101
        },
        {
            "name": "Puck",
            "Clockwerk": 0.971
        },
        {
            "name": "Pudge",
            "Clockwerk": -1.394
        },
        {
            "name": "Pugna",
            "Clockwerk": -0.144
        },
        {
            "name": "Queen of Pain",
            "Clockwerk": 1.803
        },
        {
            "name": "Razor",
            "Clockwerk": -1.659
        },
        {
            "name": "Riki",
            "Clockwerk": -0.785
        },
        {
            "name": "Ringmaster",
            "Clockwerk": 1.506
        },
        {
            "name": "Rubick",
            "Clockwerk": -0.608
        },
        {
            "name": "Sand King",
            "Clockwerk": 0.442
        },
        {
            "name": "Shadow Demon",
            "Clockwerk": 1.523
        },
        {
            "name": "Shadow Fiend",
            "Clockwerk": -0.624
        },
        {
            "name": "Shadow Shaman",
            "Clockwerk": -0.498
        },
        {
            "name": "Silencer",
            "Clockwerk": -0.033
        },
        {
            "name": "Skywrath Mage",
            "Clockwerk": 0.554
        },
        {
            "name": "Slardar",
            "Clockwerk": -0.162
        },
        {
            "name": "Slark",
            "Clockwerk": -2.978
        },
        {
            "name": "Snapfire",
            "Clockwerk": 1.071
        },
        {
            "name": "Sniper",
            "Clockwerk": -1.578
        },
        {
            "name": "Spectre",
            "Clockwerk": -0.96
        },
        {
            "name": "Spirit Breaker",
            "Clockwerk": -2.667
        },
        {
            "name": "Storm Spirit",
            "Clockwerk": 2.238
        },
        {
            "name": "Sven",
            "Clockwerk": -0.445
        },
        {
            "name": "Techies",
            "Clockwerk": -0.41
        },
        {
            "name": "Templar Assassin",
            "Clockwerk": -0.089
        },
        {
            "name": "Terrorblade",
            "Clockwerk": 1.615
        },
        {
            "name": "Tidehunter",
            "Clockwerk": -1.081
        },
        {
            "name": "Timbersaw",
            "Clockwerk": 0.595
        },
        {
            "name": "Tinker",
            "Clockwerk": -0.31
        },
        {
            "name": "Tiny",
            "Clockwerk": -0.624
        },
        {
            "name": "Treant Protector",
            "Clockwerk": -0.583
        },
        {
            "name": "Troll Warlord",
            "Clockwerk": -0.15
        },
        {
            "name": "Tusk",
            "Clockwerk": -0.447
        },
        {
            "name": "Underlord",
            "Clockwerk": 1.757
        },
        {
            "name": "Undying",
            "Clockwerk": 1.772
        },
        {
            "name": "Ursa",
            "Clockwerk": 0.28
        },
        {
            "name": "Vengeful Spirit",
            "Clockwerk": -0.748
        },
        {
            "name": "Venomancer",
            "Clockwerk": 1.68
        },
        {
            "name": "Viper",
            "Clockwerk": 1.09
        },
        {
            "name": "Visage",
            "Clockwerk": 0.94
        },
        {
            "name": "Void Spirit",
            "Clockwerk": 1.344
        },
        {
            "name": "Warlock",
            "Clockwerk": 1.502
        },
        {
            "name": "Weaver",
            "Clockwerk": -0.86
        },
        {
            "name": "Windranger",
            "Clockwerk": -1.795
        },
        {
            "name": "Winter Wyvern",
            "Clockwerk": 0.112
        },
        {
            "name": "Witch Doctor",
            "Clockwerk": 0.793
        },
        {
            "name": "Wraith King",
            "Clockwerk": -0.281
        },
        {
            "name": "Zeus",
            "Clockwerk": -0.044
        }
    ],
    "Dark Seer": [
        {
            "name": "Abaddon",
            "Dark Seer": -3.211
        },
        {
            "name": "Alchemist",
            "Dark Seer": -0.302
        },
        {
            "name": "Ancient Apparition",
            "Dark Seer": 1.033
        },
        {
            "name": "Anti-Mage",
            "Dark Seer": 0.194
        },
        {
            "name": "Arc Warden",
            "Dark Seer": -1.105
        },
        {
            "name": "Axe",
            "Dark Seer": 0.555
        },
        {
            "name": "Bane",
            "Dark Seer": 0.719
        },
        {
            "name": "Batrider",
            "Dark Seer": 2.41
        },
        {
            "name": "Beastmaster",
            "Dark Seer": -0.736
        },
        {
            "name": "Bloodseeker",
            "Dark Seer": 1.527
        },
        {
            "name": "Bounty Hunter",
            "Dark Seer": 0.591
        },
        {
            "name": "Brewmaster",
            "Dark Seer": 1.753
        },
        {
            "name": "Bristleback",
            "Dark Seer": -0.156
        },
        {
            "name": "Broodmother",
            "Dark Seer": -2.972
        },
        {
            "name": "Centaur Warrunner",
            "Dark Seer": -0.646
        },
        {
            "name": "Chaos Knight",
            "Dark Seer": -4.027
        },
        {
            "name": "Chen",
            "Dark Seer": 1.69
        },
        {
            "name": "Clinkz",
            "Dark Seer": 0.704
        },
        {
            "name": "Clockwerk",
            "Dark Seer": -1.603
        },
        {
            "name": "Crystal Maiden",
            "Dark Seer": 0.152
        },
        {
            "name": "Dark Willow",
            "Dark Seer": -0.093
        },
        {
            "name": "Dawnbreaker",
            "Dark Seer": 0.422
        },
        {
            "name": "Dazzle",
            "Dark Seer": 0.384
        },
        {
            "name": "Death Prophet",
            "Dark Seer": -0.848
        },
        {
            "name": "Disruptor",
            "Dark Seer": 0.677
        },
        {
            "name": "Doom",
            "Dark Seer": 1.836
        },
        {
            "name": "Dragon Knight",
            "Dark Seer": -0.724
        },
        {
            "name": "Drow Ranger",
            "Dark Seer": -2.227
        },
        {
            "name": "Earth Spirit",
            "Dark Seer": -1.252
        },
        {
            "name": "Earthshaker",
            "Dark Seer": 2.442
        },
        {
            "name": "Elder Titan",
            "Dark Seer": -2.411
        },
        {
            "name": "Ember Spirit",
            "Dark Seer": 2.108
        },
        {
            "name": "Enchantress",
            "Dark Seer": 1.183
        },
        {
            "name": "Enigma",
            "Dark Seer": 1.615
        },
        {
            "name": "Faceless Void",
            "Dark Seer": 2.437
        },
        {
            "name": "Grimstroke",
            "Dark Seer": -0.085
        },
        {
            "name": "Gyrocopter",
            "Dark Seer": 2.164
        },
        {
            "name": "Hoodwink",
            "Dark Seer": 0.037
        },
        {
            "name": "Huskar",
            "Dark Seer": 0.043
        },
        {
            "name": "Invoker",
            "Dark Seer": 1.604
        },
        {
            "name": "Io",
            "Dark Seer": -0.295
        },
        {
            "name": "Jakiro",
            "Dark Seer": 0.045
        },
        {
            "name": "Juggernaut",
            "Dark Seer": 0.736
        },
        {
            "name": "Keeper of the Light",
            "Dark Seer": -0.298
        },
        {
            "name": "Kez",
            "Dark Seer": 0.643
        },
        {
            "name": "Kunkka",
            "Dark Seer": -0.325
        },
        {
            "name": "Legion Commander",
            "Dark Seer": 1.219
        },
        {
            "name": "Leshrac",
            "Dark Seer": 1.792
        },
        {
            "name": "Lich",
            "Dark Seer": -0.692
        },
        {
            "name": "Lifestealer",
            "Dark Seer": -0.717
        },
        {
            "name": "Lina",
            "Dark Seer": 0.293
        },
        {
            "name": "Lion",
            "Dark Seer": -0.549
        },
        {
            "name": "Lone Druid",
            "Dark Seer": -1.143
        },
        {
            "name": "Luna",
            "Dark Seer": -2.22
        },
        {
            "name": "Lycan",
            "Dark Seer": -1.305
        },
        {
            "name": "Magnus",
            "Dark Seer": 1.037
        },
        {
            "name": "Marci",
            "Dark Seer": -0.216
        },
        {
            "name": "Mars",
            "Dark Seer": -0.173
        },
        {
            "name": "Medusa",
            "Dark Seer": -7.232
        },
        {
            "name": "Meepo",
            "Dark Seer": 1.874
        },
        {
            "name": "Mirana",
            "Dark Seer": -0.062
        },
        {
            "name": "Monkey King",
            "Dark Seer": 0.453
        },
        {
            "name": "Morphling",
            "Dark Seer": -0.659
        },
        {
            "name": "Muerta",
            "Dark Seer": -1.224
        },
        {
            "name": "Naga Siren",
            "Dark Seer": -2.739
        },
        {
            "name": "Nature's Prophet",
            "Dark Seer": 1.396
        },
        {
            "name": "Necrophos",
            "Dark Seer": -1.763
        },
        {
            "name": "Night Stalker",
            "Dark Seer": -0.549
        },
        {
            "name": "Nyx Assassin",
            "Dark Seer": 1.564
        },
        {
            "name": "Ogre Magi",
            "Dark Seer": -0.938
        },
        {
            "name": "Omniknight",
            "Dark Seer": 0.628
        },
        {
            "name": "Oracle",
            "Dark Seer": 2.599
        },
        {
            "name": "Outworld Destroyer",
            "Dark Seer": 0.427
        },
        {
            "name": "Pangolier",
            "Dark Seer": -0.344
        },
        {
            "name": "Phantom Assassin",
            "Dark Seer": -0.01
        },
        {
            "name": "Phantom Lancer",
            "Dark Seer": -6.248
        },
        {
            "name": "Phoenix",
            "Dark Seer": -0.403
        },
        {
            "name": "Primal Beast",
            "Dark Seer": -0.664
        },
        {
            "name": "Puck",
            "Dark Seer": 2.037
        },
        {
            "name": "Pudge",
            "Dark Seer": -0.12
        },
        {
            "name": "Pugna",
            "Dark Seer": -0.597
        },
        {
            "name": "Queen of Pain",
            "Dark Seer": 1.906
        },
        {
            "name": "Razor",
            "Dark Seer": -0.082
        },
        {
            "name": "Riki",
            "Dark Seer": -0.784
        },
        {
            "name": "Ringmaster",
            "Dark Seer": 0.825
        },
        {
            "name": "Rubick",
            "Dark Seer": 1.357
        },
        {
            "name": "Sand King",
            "Dark Seer": 0.277
        },
        {
            "name": "Shadow Demon",
            "Dark Seer": 0.345
        },
        {
            "name": "Shadow Fiend",
            "Dark Seer": -1.013
        },
        {
            "name": "Shadow Shaman",
            "Dark Seer": -1.907
        },
        {
            "name": "Silencer",
            "Dark Seer": -0.15
        },
        {
            "name": "Skywrath Mage",
            "Dark Seer": 0.152
        },
        {
            "name": "Slardar",
            "Dark Seer": 0.249
        },
        {
            "name": "Slark",
            "Dark Seer": 1.149
        },
        {
            "name": "Snapfire",
            "Dark Seer": 0.55
        },
        {
            "name": "Sniper",
            "Dark Seer": -0.197
        },
        {
            "name": "Spectre",
            "Dark Seer": -1.358
        },
        {
            "name": "Spirit Breaker",
            "Dark Seer": -0.945
        },
        {
            "name": "Storm Spirit",
            "Dark Seer": -0.138
        },
        {
            "name": "Sven",
            "Dark Seer": 2.869
        },
        {
            "name": "Techies",
            "Dark Seer": 0.398
        },
        {
            "name": "Templar Assassin",
            "Dark Seer": -2.19
        },
        {
            "name": "Terrorblade",
            "Dark Seer": -2.986
        },
        {
            "name": "Tidehunter",
            "Dark Seer": -0.268
        },
        {
            "name": "Timbersaw",
            "Dark Seer": 1.501
        },
        {
            "name": "Tinker",
            "Dark Seer": 1.058
        },
        {
            "name": "Tiny",
            "Dark Seer": 1.791
        },
        {
            "name": "Treant Protector",
            "Dark Seer": -0.159
        },
        {
            "name": "Troll Warlord",
            "Dark Seer": 0.69
        },
        {
            "name": "Tusk",
            "Dark Seer": -1.514
        },
        {
            "name": "Underlord",
            "Dark Seer": 0.024
        },
        {
            "name": "Undying",
            "Dark Seer": -0.493
        },
        {
            "name": "Ursa",
            "Dark Seer": 3.396
        },
        {
            "name": "Vengeful Spirit",
            "Dark Seer": -0.827
        },
        {
            "name": "Venomancer",
            "Dark Seer": -1.933
        },
        {
            "name": "Viper",
            "Dark Seer": -3.767
        },
        {
            "name": "Visage",
            "Dark Seer": -0.252
        },
        {
            "name": "Void Spirit",
            "Dark Seer": -0.019
        },
        {
            "name": "Warlock",
            "Dark Seer": 0.466
        },
        {
            "name": "Weaver",
            "Dark Seer": 1.68
        },
        {
            "name": "Windranger",
            "Dark Seer": 0.566
        },
        {
            "name": "Winter Wyvern",
            "Dark Seer": 1.237
        },
        {
            "name": "Witch Doctor",
            "Dark Seer": 1.099
        },
        {
            "name": "Wraith King",
            "Dark Seer": -3.421
        },
        {
            "name": "Zeus",
            "Dark Seer": -0.512
        }
    ],
    "Dark Willow": [
        {
            "name": "Abaddon",
            "Dark Willow": 0.195
        },
        {
            "name": "Alchemist",
            "Dark Willow": -0.007
        },
        {
            "name": "Ancient Apparition",
            "Dark Willow": 0.622
        },
        {
            "name": "Anti-Mage",
            "Dark Willow": -0.211
        },
        {
            "name": "Arc Warden",
            "Dark Willow": 0.773
        },
        {
            "name": "Axe",
            "Dark Willow": 1.918
        },
        {
            "name": "Bane",
            "Dark Willow": -0.158
        },
        {
            "name": "Batrider",
            "Dark Willow": -0.072
        },
        {
            "name": "Beastmaster",
            "Dark Willow": 1.316
        },
        {
            "name": "Bloodseeker",
            "Dark Willow": -0.261
        },
        {
            "name": "Bounty Hunter",
            "Dark Willow": -0.678
        },
        {
            "name": "Brewmaster",
            "Dark Willow": 1.902
        },
        {
            "name": "Bristleback",
            "Dark Willow": 0.321
        },
        {
            "name": "Broodmother",
            "Dark Willow": -0.136
        },
        {
            "name": "Centaur Warrunner",
            "Dark Willow": -0.084
        },
        {
            "name": "Chaos Knight",
            "Dark Willow": 0.453
        },
        {
            "name": "Chen",
            "Dark Willow": 1.179
        },
        {
            "name": "Clinkz",
            "Dark Willow": -0.315
        },
        {
            "name": "Clockwerk",
            "Dark Willow": 0.225
        },
        {
            "name": "Crystal Maiden",
            "Dark Willow": 0.283
        },
        {
            "name": "Dark Seer",
            "Dark Willow": -0.053
        },
        {
            "name": "Dawnbreaker",
            "Dark Willow": -2.24
        },
        {
            "name": "Dazzle",
            "Dark Willow": 0.201
        },
        {
            "name": "Death Prophet",
            "Dark Willow": -0.392
        },
        {
            "name": "Disruptor",
            "Dark Willow": -0.632
        },
        {
            "name": "Doom",
            "Dark Willow": -0.818
        },
        {
            "name": "Dragon Knight",
            "Dark Willow": 0.089
        },
        {
            "name": "Drow Ranger",
            "Dark Willow": -0.443
        },
        {
            "name": "Earth Spirit",
            "Dark Willow": 0.254
        },
        {
            "name": "Earthshaker",
            "Dark Willow": -0.275
        },
        {
            "name": "Elder Titan",
            "Dark Willow": -1.016
        },
        {
            "name": "Ember Spirit",
            "Dark Willow": -2.116
        },
        {
            "name": "Enchantress",
            "Dark Willow": -0.131
        },
        {
            "name": "Enigma",
            "Dark Willow": 1.088
        },
        {
            "name": "Faceless Void",
            "Dark Willow": -0.381
        },
        {
            "name": "Grimstroke",
            "Dark Willow": -0.382
        },
        {
            "name": "Gyrocopter",
            "Dark Willow": -0.534
        },
        {
            "name": "Hoodwink",
            "Dark Willow": 0.984
        },
        {
            "name": "Huskar",
            "Dark Willow": -0.08
        },
        {
            "name": "Invoker",
            "Dark Willow": 0.591
        },
        {
            "name": "Io",
            "Dark Willow": -1.28
        },
        {
            "name": "Jakiro",
            "Dark Willow": 0.358
        },
        {
            "name": "Juggernaut",
            "Dark Willow": 1.915
        },
        {
            "name": "Keeper of the Light",
            "Dark Willow": 1.14
        },
        {
            "name": "Kez",
            "Dark Willow": -1.494
        },
        {
            "name": "Kunkka",
            "Dark Willow": 0.766
        },
        {
            "name": "Legion Commander",
            "Dark Willow": 1.561
        },
        {
            "name": "Leshrac",
            "Dark Willow": 1.172
        },
        {
            "name": "Lich",
            "Dark Willow": -0.081
        },
        {
            "name": "Lifestealer",
            "Dark Willow": 0.782
        },
        {
            "name": "Lina",
            "Dark Willow": 0.236
        },
        {
            "name": "Lion",
            "Dark Willow": 0.017
        },
        {
            "name": "Lone Druid",
            "Dark Willow": -1.861
        },
        {
            "name": "Luna",
            "Dark Willow": -0.518
        },
        {
            "name": "Lycan",
            "Dark Willow": -0.013
        },
        {
            "name": "Magnus",
            "Dark Willow": -0.619
        },
        {
            "name": "Marci",
            "Dark Willow": -2.094
        },
        {
            "name": "Mars",
            "Dark Willow": 0.815
        },
        {
            "name": "Medusa",
            "Dark Willow": -0.223
        },
        {
            "name": "Meepo",
            "Dark Willow": -0.31
        },
        {
            "name": "Mirana",
            "Dark Willow": -1.052
        },
        {
            "name": "Monkey King",
            "Dark Willow": -0.784
        },
        {
            "name": "Morphling",
            "Dark Willow": 0.301
        },
        {
            "name": "Muerta",
            "Dark Willow": -0.004
        },
        {
            "name": "Naga Siren",
            "Dark Willow": 1.587
        },
        {
            "name": "Nature's Prophet",
            "Dark Willow": 0.703
        },
        {
            "name": "Necrophos",
            "Dark Willow": -0.123
        },
        {
            "name": "Night Stalker",
            "Dark Willow": -0.933
        },
        {
            "name": "Nyx Assassin",
            "Dark Willow": -0.014
        },
        {
            "name": "Ogre Magi",
            "Dark Willow": -0.447
        },
        {
            "name": "Omniknight",
            "Dark Willow": 1.56
        },
        {
            "name": "Oracle",
            "Dark Willow": -0.029
        },
        {
            "name": "Outworld Destroyer",
            "Dark Willow": -0.059
        },
        {
            "name": "Pangolier",
            "Dark Willow": -1.227
        },
        {
            "name": "Phantom Assassin",
            "Dark Willow": 0.234
        },
        {
            "name": "Phantom Lancer",
            "Dark Willow": 1.246
        },
        {
            "name": "Phoenix",
            "Dark Willow": 0.494
        },
        {
            "name": "Primal Beast",
            "Dark Willow": 0.111
        },
        {
            "name": "Puck",
            "Dark Willow": 1.338
        },
        {
            "name": "Pudge",
            "Dark Willow": -0.114
        },
        {
            "name": "Pugna",
            "Dark Willow": 0.501
        },
        {
            "name": "Queen of Pain",
            "Dark Willow": 0.599
        },
        {
            "name": "Razor",
            "Dark Willow": -1.547
        },
        {
            "name": "Riki",
            "Dark Willow": -0.706
        },
        {
            "name": "Ringmaster",
            "Dark Willow": 0.122
        },
        {
            "name": "Rubick",
            "Dark Willow": -0.231
        },
        {
            "name": "Sand King",
            "Dark Willow": 0.615
        },
        {
            "name": "Shadow Demon",
            "Dark Willow": 0.454
        },
        {
            "name": "Shadow Fiend",
            "Dark Willow": 0.591
        },
        {
            "name": "Shadow Shaman",
            "Dark Willow": -0.115
        },
        {
            "name": "Silencer",
            "Dark Willow": -0.534
        },
        {
            "name": "Skywrath Mage",
            "Dark Willow": -0.459
        },
        {
            "name": "Slardar",
            "Dark Willow": -0.993
        },
        {
            "name": "Slark",
            "Dark Willow": -0.451
        },
        {
            "name": "Snapfire",
            "Dark Willow": 0.205
        },
        {
            "name": "Sniper",
            "Dark Willow": -0.469
        },
        {
            "name": "Spectre",
            "Dark Willow": 0.392
        },
        {
            "name": "Spirit Breaker",
            "Dark Willow": -0.978
        },
        {
            "name": "Storm Spirit",
            "Dark Willow": 0.081
        },
        {
            "name": "Sven",
            "Dark Willow": -0.645
        },
        {
            "name": "Techies",
            "Dark Willow": 1.489
        },
        {
            "name": "Templar Assassin",
            "Dark Willow": -1.571
        },
        {
            "name": "Terrorblade",
            "Dark Willow": -0.292
        },
        {
            "name": "Tidehunter",
            "Dark Willow": -1.055
        },
        {
            "name": "Timbersaw",
            "Dark Willow": -0.007
        },
        {
            "name": "Tinker",
            "Dark Willow": 0.799
        },
        {
            "name": "Tiny",
            "Dark Willow": -0.261
        },
        {
            "name": "Treant Protector",
            "Dark Willow": -0.023
        },
        {
            "name": "Troll Warlord",
            "Dark Willow": -0.776
        },
        {
            "name": "Tusk",
            "Dark Willow": -1.331
        },
        {
            "name": "Underlord",
            "Dark Willow": -0.353
        },
        {
            "name": "Undying",
            "Dark Willow": -0.192
        },
        {
            "name": "Ursa",
            "Dark Willow": 0.063
        },
        {
            "name": "Vengeful Spirit",
            "Dark Willow": -1.416
        },
        {
            "name": "Venomancer",
            "Dark Willow": 0.161
        },
        {
            "name": "Viper",
            "Dark Willow": 0.522
        },
        {
            "name": "Visage",
            "Dark Willow": -1.794
        },
        {
            "name": "Void Spirit",
            "Dark Willow": 0.705
        },
        {
            "name": "Warlock",
            "Dark Willow": 0.602
        },
        {
            "name": "Weaver",
            "Dark Willow": -2.188
        },
        {
            "name": "Windranger",
            "Dark Willow": 0.599
        },
        {
            "name": "Winter Wyvern",
            "Dark Willow": -0.237
        },
        {
            "name": "Witch Doctor",
            "Dark Willow": -0.715
        },
        {
            "name": "Wraith King",
            "Dark Willow": 0.277
        },
        {
            "name": "Zeus",
            "Dark Willow": -0.089
        }
    ],
    "Dazzle": [
        {
            "name": "Abaddon",
            "Dazzle": 2.144
        },
        {
            "name": "Alchemist",
            "Dazzle": -0.195
        },
        {
            "name": "Ancient Apparition",
            "Dazzle": 1.814
        },
        {
            "name": "Anti-Mage",
            "Dazzle": 1.419
        },
        {
            "name": "Arc Warden",
            "Dazzle": 0.695
        },
        {
            "name": "Axe",
            "Dazzle": 1.189
        },
        {
            "name": "Bane",
            "Dazzle": -0.301
        },
        {
            "name": "Batrider",
            "Dazzle": 1.099
        },
        {
            "name": "Beastmaster",
            "Dazzle": -1.525
        },
        {
            "name": "Bloodseeker",
            "Dazzle": 0.422
        },
        {
            "name": "Bounty Hunter",
            "Dazzle": 1.124
        },
        {
            "name": "Brewmaster",
            "Dazzle": 1.164
        },
        {
            "name": "Bristleback",
            "Dazzle": -0.185
        },
        {
            "name": "Broodmother",
            "Dazzle": 1.262
        },
        {
            "name": "Centaur Warrunner",
            "Dazzle": -2.381
        },
        {
            "name": "Chaos Knight",
            "Dazzle": -0.289
        },
        {
            "name": "Chen",
            "Dazzle": 0.234
        },
        {
            "name": "Clinkz",
            "Dazzle": 0.402
        },
        {
            "name": "Clockwerk",
            "Dazzle": -1.437
        },
        {
            "name": "Crystal Maiden",
            "Dazzle": 0.559
        },
        {
            "name": "Dark Seer",
            "Dazzle": -0.406
        },
        {
            "name": "Dark Willow",
            "Dazzle": -0.08
        },
        {
            "name": "Dawnbreaker",
            "Dazzle": -1.157
        },
        {
            "name": "Death Prophet",
            "Dazzle": -0.153
        },
        {
            "name": "Disruptor",
            "Dazzle": -0.33
        },
        {
            "name": "Doom",
            "Dazzle": 0.969
        },
        {
            "name": "Dragon Knight",
            "Dazzle": -0.731
        },
        {
            "name": "Drow Ranger",
            "Dazzle": 0.591
        },
        {
            "name": "Earth Spirit",
            "Dazzle": 0.827
        },
        {
            "name": "Earthshaker",
            "Dazzle": -0.175
        },
        {
            "name": "Elder Titan",
            "Dazzle": -0.637
        },
        {
            "name": "Ember Spirit",
            "Dazzle": -1.395
        },
        {
            "name": "Enchantress",
            "Dazzle": -0.457
        },
        {
            "name": "Enigma",
            "Dazzle": -0.769
        },
        {
            "name": "Faceless Void",
            "Dazzle": -0.741
        },
        {
            "name": "Grimstroke",
            "Dazzle": 0.555
        },
        {
            "name": "Gyrocopter",
            "Dazzle": 0.498
        },
        {
            "name": "Hoodwink",
            "Dazzle": 1.078
        },
        {
            "name": "Huskar",
            "Dazzle": 0.543
        },
        {
            "name": "Invoker",
            "Dazzle": 0.177
        },
        {
            "name": "Io",
            "Dazzle": -0.506
        },
        {
            "name": "Jakiro",
            "Dazzle": -1.125
        },
        {
            "name": "Juggernaut",
            "Dazzle": 0.036
        },
        {
            "name": "Keeper of the Light",
            "Dazzle": 0.006
        },
        {
            "name": "Kez",
            "Dazzle": 0.633
        },
        {
            "name": "Kunkka",
            "Dazzle": -0.025
        },
        {
            "name": "Legion Commander",
            "Dazzle": -0.956
        },
        {
            "name": "Leshrac",
            "Dazzle": -1.737
        },
        {
            "name": "Lich",
            "Dazzle": 0.109
        },
        {
            "name": "Lifestealer",
            "Dazzle": -1.395
        },
        {
            "name": "Lina",
            "Dazzle": 0.672
        },
        {
            "name": "Lion",
            "Dazzle": -0.925
        },
        {
            "name": "Lone Druid",
            "Dazzle": -0.067
        },
        {
            "name": "Luna",
            "Dazzle": 0.537
        },
        {
            "name": "Lycan",
            "Dazzle": 0.804
        },
        {
            "name": "Magnus",
            "Dazzle": -0.798
        },
        {
            "name": "Marci",
            "Dazzle": -0.853
        },
        {
            "name": "Mars",
            "Dazzle": -0.825
        },
        {
            "name": "Medusa",
            "Dazzle": -2.136
        },
        {
            "name": "Meepo",
            "Dazzle": -0.754
        },
        {
            "name": "Mirana",
            "Dazzle": 0.202
        },
        {
            "name": "Monkey King",
            "Dazzle": 1.982
        },
        {
            "name": "Morphling",
            "Dazzle": 1.561
        },
        {
            "name": "Muerta",
            "Dazzle": -0.03
        },
        {
            "name": "Naga Siren",
            "Dazzle": -1.002
        },
        {
            "name": "Nature's Prophet",
            "Dazzle": 0.376
        },
        {
            "name": "Necrophos",
            "Dazzle": -2.01
        },
        {
            "name": "Night Stalker",
            "Dazzle": -0.512
        },
        {
            "name": "Nyx Assassin",
            "Dazzle": 0.853
        },
        {
            "name": "Ogre Magi",
            "Dazzle": 0.134
        },
        {
            "name": "Omniknight",
            "Dazzle": 1.078
        },
        {
            "name": "Oracle",
            "Dazzle": -0.816
        },
        {
            "name": "Outworld Destroyer",
            "Dazzle": 0.133
        },
        {
            "name": "Pangolier",
            "Dazzle": -0.268
        },
        {
            "name": "Phantom Assassin",
            "Dazzle": 0.462
        },
        {
            "name": "Phantom Lancer",
            "Dazzle": 2.707
        },
        {
            "name": "Phoenix",
            "Dazzle": 0.328
        },
        {
            "name": "Primal Beast",
            "Dazzle": 0.852
        },
        {
            "name": "Puck",
            "Dazzle": 0.71
        },
        {
            "name": "Pudge",
            "Dazzle": -0.938
        },
        {
            "name": "Pugna",
            "Dazzle": 0.732
        },
        {
            "name": "Queen of Pain",
            "Dazzle": -0.426
        },
        {
            "name": "Razor",
            "Dazzle": 1.328
        },
        {
            "name": "Riki",
            "Dazzle": 2.035
        },
        {
            "name": "Ringmaster",
            "Dazzle": -0.339
        },
        {
            "name": "Rubick",
            "Dazzle": -1.641
        },
        {
            "name": "Sand King",
            "Dazzle": 0.6
        },
        {
            "name": "Shadow Demon",
            "Dazzle": 0.26
        },
        {
            "name": "Shadow Fiend",
            "Dazzle": 1.326
        },
        {
            "name": "Shadow Shaman",
            "Dazzle": 0.189
        },
        {
            "name": "Silencer",
            "Dazzle": 0.35
        },
        {
            "name": "Skywrath Mage",
            "Dazzle": -0.223
        },
        {
            "name": "Slardar",
            "Dazzle": -0.037
        },
        {
            "name": "Slark",
            "Dazzle": 3.006
        },
        {
            "name": "Snapfire",
            "Dazzle": 1.106
        },
        {
            "name": "Sniper",
            "Dazzle": 1.269
        },
        {
            "name": "Spectre",
            "Dazzle": -0.607
        },
        {
            "name": "Spirit Breaker",
            "Dazzle": 0.117
        },
        {
            "name": "Storm Spirit",
            "Dazzle": -0.761
        },
        {
            "name": "Sven",
            "Dazzle": -0.385
        },
        {
            "name": "Techies",
            "Dazzle": 0.07
        },
        {
            "name": "Templar Assassin",
            "Dazzle": 0.741
        },
        {
            "name": "Terrorblade",
            "Dazzle": 0.872
        },
        {
            "name": "Tidehunter",
            "Dazzle": 1.419
        },
        {
            "name": "Timbersaw",
            "Dazzle": 1.068
        },
        {
            "name": "Tinker",
            "Dazzle": 1.229
        },
        {
            "name": "Tiny",
            "Dazzle": -1.981
        },
        {
            "name": "Treant Protector",
            "Dazzle": 0.426
        },
        {
            "name": "Troll Warlord",
            "Dazzle": 1.077
        },
        {
            "name": "Tusk",
            "Dazzle": 0.621
        },
        {
            "name": "Underlord",
            "Dazzle": -1.223
        },
        {
            "name": "Undying",
            "Dazzle": 0.227
        },
        {
            "name": "Ursa",
            "Dazzle": 0.444
        },
        {
            "name": "Vengeful Spirit",
            "Dazzle": 0.63
        },
        {
            "name": "Venomancer",
            "Dazzle": 0.565
        },
        {
            "name": "Viper",
            "Dazzle": 0.204
        },
        {
            "name": "Visage",
            "Dazzle": -0.342
        },
        {
            "name": "Void Spirit",
            "Dazzle": -0.993
        },
        {
            "name": "Warlock",
            "Dazzle": -0.616
        },
        {
            "name": "Weaver",
            "Dazzle": 2.439
        },
        {
            "name": "Windranger",
            "Dazzle": 1.318
        },
        {
            "name": "Winter Wyvern",
            "Dazzle": -1.446
        },
        {
            "name": "Witch Doctor",
            "Dazzle": -0.011
        },
        {
            "name": "Wraith King",
            "Dazzle": -1.862
        },
        {
            "name": "Zeus",
            "Dazzle": -0.474
        }
    ],
    "Enigma": [
        {
            "name": "Abaddon",
            "Enigma": 0.621
        },
        {
            "name": "Alchemist",
            "Enigma": 1.947
        },
        {
            "name": "Ancient Apparition",
            "Enigma": -0.758
        },
        {
            "name": "Anti-Mage",
            "Enigma": -1.315
        },
        {
            "name": "Arc Warden",
            "Enigma": -0.415
        },
        {
            "name": "Axe",
            "Enigma": 1.498
        },
        {
            "name": "Bane",
            "Enigma": -1.071
        },
        {
            "name": "Batrider",
            "Enigma": -1.984
        },
        {
            "name": "Beastmaster",
            "Enigma": 0.36
        },
        {
            "name": "Bloodseeker",
            "Enigma": 0.405
        },
        {
            "name": "Bounty Hunter",
            "Enigma": -1.027
        },
        {
            "name": "Brewmaster",
            "Enigma": 0.63
        },
        {
            "name": "Bristleback",
            "Enigma": 2.671
        },
        {
            "name": "Broodmother",
            "Enigma": -0.693
        },
        {
            "name": "Centaur Warrunner",
            "Enigma": -0.45
        },
        {
            "name": "Chaos Knight",
            "Enigma": -0.694
        },
        {
            "name": "Chen",
            "Enigma": -0.652
        },
        {
            "name": "Clinkz",
            "Enigma": 2.109
        },
        {
            "name": "Clockwerk",
            "Enigma": -1.068
        },
        {
            "name": "Crystal Maiden",
            "Enigma": -1.317
        },
        {
            "name": "Dark Seer",
            "Enigma": -1.628
        },
        {
            "name": "Dark Willow",
            "Enigma": -1.206
        },
        {
            "name": "Dawnbreaker",
            "Enigma": 1.803
        },
        {
            "name": "Dazzle",
            "Enigma": 0.899
        },
        {
            "name": "Death Prophet",
            "Enigma": 0.33
        },
        {
            "name": "Disruptor",
            "Enigma": -0.291
        },
        {
            "name": "Doom",
            "Enigma": -2.537
        },
        {
            "name": "Dragon Knight",
            "Enigma": -0.39
        },
        {
            "name": "Drow Ranger",
            "Enigma": 0.592
        },
        {
            "name": "Earth Spirit",
            "Enigma": -1.143
        },
        {
            "name": "Earthshaker",
            "Enigma": 0.525
        },
        {
            "name": "Elder Titan",
            "Enigma": 0.14
        },
        {
            "name": "Ember Spirit",
            "Enigma": -1.431
        },
        {
            "name": "Enchantress",
            "Enigma": 1.213
        },
        {
            "name": "Faceless Void",
            "Enigma": -0.423
        },
        {
            "name": "Grimstroke",
            "Enigma": 0.421
        },
        {
            "name": "Gyrocopter",
            "Enigma": 2.231
        },
        {
            "name": "Hoodwink",
            "Enigma": 0.891
        },
        {
            "name": "Huskar",
            "Enigma": -1.4
        },
        {
            "name": "Invoker",
            "Enigma": -0.166
        },
        {
            "name": "Io",
            "Enigma": -1.174
        },
        {
            "name": "Jakiro",
            "Enigma": 1.074
        },
        {
            "name": "Juggernaut",
            "Enigma": -0.547
        },
        {
            "name": "Keeper of the Light",
            "Enigma": -0.216
        },
        {
            "name": "Kez",
            "Enigma": 0.772
        },
        {
            "name": "Kunkka",
            "Enigma": 2.661
        },
        {
            "name": "Legion Commander",
            "Enigma": 0.928
        },
        {
            "name": "Leshrac",
            "Enigma": -1.068
        },
        {
            "name": "Lich",
            "Enigma": -0.786
        },
        {
            "name": "Lifestealer",
            "Enigma": -2.93
        },
        {
            "name": "Lina",
            "Enigma": -0.084
        },
        {
            "name": "Lion",
            "Enigma": -0.253
        },
        {
            "name": "Lone Druid",
            "Enigma": 2.364
        },
        {
            "name": "Luna",
            "Enigma": 0.77
        },
        {
            "name": "Lycan",
            "Enigma": 0.966
        },
        {
            "name": "Magnus",
            "Enigma": -1.951
        },
        {
            "name": "Marci",
            "Enigma": -2.117
        },
        {
            "name": "Mars",
            "Enigma": -1.041
        },
        {
            "name": "Medusa",
            "Enigma": 3.826
        },
        {
            "name": "Meepo",
            "Enigma": -2.96
        },
        {
            "name": "Mirana",
            "Enigma": -0.538
        },
        {
            "name": "Monkey King",
            "Enigma": -1.209
        },
        {
            "name": "Morphling",
            "Enigma": -0.834
        },
        {
            "name": "Muerta",
            "Enigma": 0.245
        },
        {
            "name": "Naga Siren",
            "Enigma": 0.257
        },
        {
            "name": "Nature's Prophet",
            "Enigma": 1.146
        },
        {
            "name": "Necrophos",
            "Enigma": 1.767
        },
        {
            "name": "Night Stalker",
            "Enigma": -1.527
        },
        {
            "name": "Nyx Assassin",
            "Enigma": -0.067
        },
        {
            "name": "Ogre Magi",
            "Enigma": -2.216
        },
        {
            "name": "Omniknight",
            "Enigma": -1.693
        },
        {
            "name": "Oracle",
            "Enigma": -1.946
        },
        {
            "name": "Outworld Destroyer",
            "Enigma": -1.475
        },
        {
            "name": "Pangolier",
            "Enigma": -1.163
        },
        {
            "name": "Phantom Assassin",
            "Enigma": 0.512
        },
        {
            "name": "Phantom Lancer",
            "Enigma": 0.259
        },
        {
            "name": "Phoenix",
            "Enigma": 1.001
        },
        {
            "name": "Primal Beast",
            "Enigma": -1.62
        },
        {
            "name": "Puck",
            "Enigma": -1.027
        },
        {
            "name": "Pudge",
            "Enigma": -0.699
        },
        {
            "name": "Pugna",
            "Enigma": -0.325
        },
        {
            "name": "Queen of Pain",
            "Enigma": -0.515
        },
        {
            "name": "Razor",
            "Enigma": -0.765
        },
        {
            "name": "Riki",
            "Enigma": 0.644
        },
        {
            "name": "Ringmaster",
            "Enigma": -0.492
        },
        {
            "name": "Rubick",
            "Enigma": 4.56
        },
        {
            "name": "Sand King",
            "Enigma": 2.803
        },
        {
            "name": "Shadow Demon",
            "Enigma": 0.807
        },
        {
            "name": "Shadow Fiend",
            "Enigma": 0.026
        },
        {
            "name": "Shadow Shaman",
            "Enigma": -0.668
        },
        {
            "name": "Silencer",
            "Enigma": 2.227
        },
        {
            "name": "Skywrath Mage",
            "Enigma": 1.086
        },
        {
            "name": "Slardar",
            "Enigma": -2.593
        },
        {
            "name": "Slark",
            "Enigma": -1.761
        },
        {
            "name": "Snapfire",
            "Enigma": 0.322
        },
        {
            "name": "Sniper",
            "Enigma": 1.944
        },
        {
            "name": "Spectre",
            "Enigma": 1.844
        },
        {
            "name": "Spirit Breaker",
            "Enigma": -1.668
        },
        {
            "name": "Storm Spirit",
            "Enigma": -1.206
        },
        {
            "name": "Sven",
            "Enigma": 0.159
        },
        {
            "name": "Techies",
            "Enigma": -0.207
        },
        {
            "name": "Templar Assassin",
            "Enigma": 2.146
        },
        {
            "name": "Terrorblade",
            "Enigma": 0.107
        },
        {
            "name": "Tidehunter",
            "Enigma": 0.297
        },
        {
            "name": "Timbersaw",
            "Enigma": 0.266
        },
        {
            "name": "Tinker",
            "Enigma": -2.52
        },
        {
            "name": "Tiny",
            "Enigma": 0.239
        },
        {
            "name": "Treant Protector",
            "Enigma": 0.421
        },
        {
            "name": "Troll Warlord",
            "Enigma": 0.024
        },
        {
            "name": "Tusk",
            "Enigma": -0.768
        },
        {
            "name": "Underlord",
            "Enigma": 0.066
        },
        {
            "name": "Undying",
            "Enigma": 0.733
        },
        {
            "name": "Ursa",
            "Enigma": -1.066
        },
        {
            "name": "Vengeful Spirit",
            "Enigma": 0.331
        },
        {
            "name": "Venomancer",
            "Enigma": -1.617
        },
        {
            "name": "Viper",
            "Enigma": -0.216
        },
        {
            "name": "Visage",
            "Enigma": 0.718
        },
        {
            "name": "Void Spirit",
            "Enigma": -0.871
        },
        {
            "name": "Warlock",
            "Enigma": 3.782
        },
        {
            "name": "Weaver",
            "Enigma": -0.562
        },
        {
            "name": "Windranger",
            "Enigma": 0.175
        },
        {
            "name": "Winter Wyvern",
            "Enigma": 1.933
        },
        {
            "name": "Witch Doctor",
            "Enigma": -0.386
        },
        {
            "name": "Wraith King",
            "Enigma": 2.58
        },
        {
            "name": "Zeus",
            "Enigma": -0.075
        }
    ],
    "Invoker": [
        {
            "name": "Abaddon",
            "Invoker": -0.975
        },
        {
            "name": "Alchemist",
            "Invoker": 0.275
        },
        {
            "name": "Ancient Apparition",
            "Invoker": -1.736
        },
        {
            "name": "Anti-Mage",
            "Invoker": 0.572
        },
        {
            "name": "Arc Warden",
            "Invoker": 0.161
        },
        {
            "name": "Axe",
            "Invoker": -0.16
        },
        {
            "name": "Bane",
            "Invoker": -0.244
        },
        {
            "name": "Batrider",
            "Invoker": 1.565
        },
        {
            "name": "Beastmaster",
            "Invoker": 0.643
        },
        {
            "name": "Bloodseeker",
            "Invoker": -0.151
        },
        {
            "name": "Bounty Hunter",
            "Invoker": 1.227
        },
        {
            "name": "Brewmaster",
            "Invoker": 0.83
        },
        {
            "name": "Bristleback",
            "Invoker": 1.335
        },
        {
            "name": "Broodmother",
            "Invoker": 2.565
        },
        {
            "name": "Centaur Warrunner",
            "Invoker": -0.48
        },
        {
            "name": "Chaos Knight",
            "Invoker": -0.443
        },
        {
            "name": "Chen",
            "Invoker": 1.228
        },
        {
            "name": "Clinkz",
            "Invoker": 0.484
        },
        {
            "name": "Clockwerk",
            "Invoker": -0.329
        },
        {
            "name": "Crystal Maiden",
            "Invoker": -0.032
        },
        {
            "name": "Dark Seer",
            "Invoker": -1.854
        },
        {
            "name": "Dark Willow",
            "Invoker": -0.339
        },
        {
            "name": "Dawnbreaker",
            "Invoker": -0.532
        },
        {
            "name": "Dazzle",
            "Invoker": -0.346
        },
        {
            "name": "Death Prophet",
            "Invoker": -0.275
        },
        {
            "name": "Disruptor",
            "Invoker": -0.85
        },
        {
            "name": "Doom",
            "Invoker": -0.363
        },
        {
            "name": "Dragon Knight",
            "Invoker": -0.957
        },
        {
            "name": "Drow Ranger",
            "Invoker": -0.825
        },
        {
            "name": "Earth Spirit",
            "Invoker": 1.469
        },
        {
            "name": "Earthshaker",
            "Invoker": 0.353
        },
        {
            "name": "Elder Titan",
            "Invoker": -1.067
        },
        {
            "name": "Ember Spirit",
            "Invoker": 0.7
        },
        {
            "name": "Enchantress",
            "Invoker": 1.234
        },
        {
            "name": "Enigma",
            "Invoker": 0.074
        },
        {
            "name": "Faceless Void",
            "Invoker": 1.098
        },
        {
            "name": "Grimstroke",
            "Invoker": -0.637
        },
        {
            "name": "Gyrocopter",
            "Invoker": 0.932
        },
        {
            "name": "Hoodwink",
            "Invoker": 1.659
        },
        {
            "name": "Huskar",
            "Invoker": 0.522
        },
        {
            "name": "Io",
            "Invoker": 0.829
        },
        {
            "name": "Jakiro",
            "Invoker": -0.653
        },
        {
            "name": "Juggernaut",
            "Invoker": 0.35
        },
        {
            "name": "Keeper of the Light",
            "Invoker": 0.805
        },
        {
            "name": "Kez",
            "Invoker": 1.476
        },
        {
            "name": "Kunkka",
            "Invoker": 0.107
        },
        {
            "name": "Legion Commander",
            "Invoker": 0.068
        },
        {
            "name": "Leshrac",
            "Invoker": -1.227
        },
        {
            "name": "Lich",
            "Invoker": -1.198
        },
        {
            "name": "Lifestealer",
            "Invoker": 1.342
        },
        {
            "name": "Lina",
            "Invoker": 0.155
        },
        {
            "name": "Lion",
            "Invoker": -0.556
        },
        {
            "name": "Lone Druid",
            "Invoker": -0.265
        },
        {
            "name": "Luna",
            "Invoker": -0.441
        },
        {
            "name": "Lycan",
            "Invoker": 2.297
        },
        {
            "name": "Magnus",
            "Invoker": -0.405
        },
        {
            "name": "Marci",
            "Invoker": 0.802
        },
        {
            "name": "Mars",
            "Invoker": 0.441
        },
        {
            "name": "Medusa",
            "Invoker": -2.734
        },
        {
            "name": "Meepo",
            "Invoker": 1.113
        },
        {
            "name": "Mirana",
            "Invoker": 0.454
        },
        {
            "name": "Monkey King",
            "Invoker": -0.83
        },
        {
            "name": "Morphling",
            "Invoker": 0.076
        },
        {
            "name": "Muerta",
            "Invoker": -1.684
        },
        {
            "name": "Naga Siren",
            "Invoker": 1.229
        },
        {
            "name": "Nature's Prophet",
            "Invoker": 0.673
        },
        {
            "name": "Necrophos",
            "Invoker": 0.212
        },
        {
            "name": "Night Stalker",
            "Invoker": -0.138
        },
        {
            "name": "Nyx Assassin",
            "Invoker": 0.952
        },
        {
            "name": "Ogre Magi",
            "Invoker": -0.833
        },
        {
            "name": "Omniknight",
            "Invoker": 0.894
        },
        {
            "name": "Oracle",
            "Invoker": -0.749
        },
        {
            "name": "Outworld Destroyer",
            "Invoker": 0.389
        },
        {
            "name": "Pangolier",
            "Invoker": 1.637
        },
        {
            "name": "Phantom Assassin",
            "Invoker": 1.252
        },
        {
            "name": "Phantom Lancer",
            "Invoker": 1.9
        },
        {
            "name": "Phoenix",
            "Invoker": -0.046
        },
        {
            "name": "Primal Beast",
            "Invoker": 0.211
        },
        {
            "name": "Puck",
            "Invoker": 3.498
        },
        {
            "name": "Pudge",
            "Invoker": -0.697
        },
        {
            "name": "Pugna",
            "Invoker": -0.122
        },
        {
            "name": "Queen of Pain",
            "Invoker": 0.62
        },
        {
            "name": "Razor",
            "Invoker": 0.048
        },
        {
            "name": "Riki",
            "Invoker": 1.573
        },
        {
            "name": "Ringmaster",
            "Invoker": 0.474
        },
        {
            "name": "Rubick",
            "Invoker": -0.103
        },
        {
            "name": "Sand King",
            "Invoker": 1.203
        },
        {
            "name": "Shadow Demon",
            "Invoker": 1.125
        },
        {
            "name": "Shadow Fiend",
            "Invoker": -0.369
        },
        {
            "name": "Shadow Shaman",
            "Invoker": -0.457
        },
        {
            "name": "Silencer",
            "Invoker": -0.652
        },
        {
            "name": "Skywrath Mage",
            "Invoker": 0.309
        },
        {
            "name": "Slardar",
            "Invoker": 1.255
        },
        {
            "name": "Slark",
            "Invoker": -2.348
        },
        {
            "name": "Snapfire",
            "Invoker": 1.122
        },
        {
            "name": "Sniper",
            "Invoker": -0.344
        },
        {
            "name": "Spectre",
            "Invoker": -0.505
        },
        {
            "name": "Spirit Breaker",
            "Invoker": -0.515
        },
        {
            "name": "Storm Spirit",
            "Invoker": 0.83
        },
        {
            "name": "Sven",
            "Invoker": 0.056
        },
        {
            "name": "Techies",
            "Invoker": -0.025
        },
        {
            "name": "Templar Assassin",
            "Invoker": 2.388
        },
        {
            "name": "Terrorblade",
            "Invoker": -0.283
        },
        {
            "name": "Tidehunter",
            "Invoker": -1.035
        },
        {
            "name": "Timbersaw",
            "Invoker": 0.203
        },
        {
            "name": "Tinker",
            "Invoker": -1.71
        },
        {
            "name": "Tiny",
            "Invoker": 0.257
        },
        {
            "name": "Treant Protector",
            "Invoker": -0.205
        },
        {
            "name": "Troll Warlord",
            "Invoker": -1.874
        },
        {
            "name": "Tusk",
            "Invoker": 1.69
        },
        {
            "name": "Underlord",
            "Invoker": -0.807
        },
        {
            "name": "Undying",
            "Invoker": 0.054
        },
        {
            "name": "Ursa",
            "Invoker": 0.364
        },
        {
            "name": "Vengeful Spirit",
            "Invoker": -0.115
        },
        {
            "name": "Venomancer",
            "Invoker": 0.001
        },
        {
            "name": "Viper",
            "Invoker": -1.238
        },
        {
            "name": "Visage",
            "Invoker": 0.374
        },
        {
            "name": "Void Spirit",
            "Invoker": 2.031
        },
        {
            "name": "Warlock",
            "Invoker": -0.767
        },
        {
            "name": "Weaver",
            "Invoker": 0.629
        },
        {
            "name": "Windranger",
            "Invoker": 1.188
        },
        {
            "name": "Winter Wyvern",
            "Invoker": -0.456
        },
        {
            "name": "Witch Doctor",
            "Invoker": -0.811
        },
        {
            "name": "Wraith King",
            "Invoker": -0.93
        },
        {
            "name": "Zeus",
            "Invoker": 0.017
        }
    ],
    "Io": [
        {
            "name": "Abaddon",
            "Io": -0.078
        },
        {
            "name": "Alchemist",
            "Io": -0.596
        },
        {
            "name": "Ancient Apparition",
            "Io": 2.832
        },
        {
            "name": "Anti-Mage",
            "Io": -2.58
        },
        {
            "name": "Arc Warden",
            "Io": -3.145
        },
        {
            "name": "Axe",
            "Io": 0.0
        },
        {
            "name": "Bane",
            "Io": 0.557
        },
        {
            "name": "Batrider",
            "Io": 2.406
        },
        {
            "name": "Beastmaster",
            "Io": 0.841
        },
        {
            "name": "Bloodseeker",
            "Io": -2.15
        },
        {
            "name": "Bounty Hunter",
            "Io": -0.341
        },
        {
            "name": "Brewmaster",
            "Io": -1.357
        },
        {
            "name": "Bristleback",
            "Io": 1.099
        },
        {
            "name": "Broodmother",
            "Io": 1.07
        },
        {
            "name": "Centaur Warrunner",
            "Io": -1.405
        },
        {
            "name": "Chaos Knight",
            "Io": 0.849
        },
        {
            "name": "Chen",
            "Io": -2.97
        },
        {
            "name": "Clinkz",
            "Io": -0.347
        },
        {
            "name": "Clockwerk",
            "Io": -1.042
        },
        {
            "name": "Crystal Maiden",
            "Io": 1.902
        },
        {
            "name": "Dark Seer",
            "Io": 0.257
        },
        {
            "name": "Dark Willow",
            "Io": 1.277
        },
        {
            "name": "Dawnbreaker",
            "Io": -0.796
        },
        {
            "name": "Dazzle",
            "Io": 0.579
        },
        {
            "name": "Death Prophet",
            "Io": -0.024
        },
        {
            "name": "Disruptor",
            "Io": 0.576
        },
        {
            "name": "Doom",
            "Io": 2.389
        },
        {
            "name": "Dragon Knight",
            "Io": 1.672
        },
        {
            "name": "Drow Ranger",
            "Io": 0.585
        },
        {
            "name": "Earth Spirit",
            "Io": -1.271
        },
        {
            "name": "Earthshaker",
            "Io": 1.47
        },
        {
            "name": "Elder Titan",
            "Io": 1.701
        },
        {
            "name": "Ember Spirit",
            "Io": 0.017
        },
        {
            "name": "Enchantress",
            "Io": -1.963
        },
        {
            "name": "Enigma",
            "Io": 1.126
        },
        {
            "name": "Faceless Void",
            "Io": 0.868
        },
        {
            "name": "Grimstroke",
            "Io": -0.264
        },
        {
            "name": "Gyrocopter",
            "Io": 0.508
        },
        {
            "name": "Hoodwink",
            "Io": 1.166
        },
        {
            "name": "Huskar",
            "Io": -0.281
        },
        {
            "name": "Invoker",
            "Io": -1.201
        },
        {
            "name": "Jakiro",
            "Io": 0.492
        },
        {
            "name": "Juggernaut",
            "Io": -1.255
        },
        {
            "name": "Keeper of the Light",
            "Io": 0.181
        },
        {
            "name": "Kez",
            "Io": 0.521
        },
        {
            "name": "Kunkka",
            "Io": 2.397
        },
        {
            "name": "Legion Commander",
            "Io": -0.382
        },
        {
            "name": "Leshrac",
            "Io": 0.353
        },
        {
            "name": "Lich",
            "Io": 1.051
        },
        {
            "name": "Lifestealer",
            "Io": -1.475
        },
        {
            "name": "Lina",
            "Io": 0.538
        },
        {
            "name": "Lion",
            "Io": -0.457
        },
        {
            "name": "Lone Druid",
            "Io": 1.049
        },
        {
            "name": "Luna",
            "Io": 0.6
        },
        {
            "name": "Lycan",
            "Io": -0.465
        },
        {
            "name": "Magnus",
            "Io": 1.72
        },
        {
            "name": "Marci",
            "Io": 1.761
        },
        {
            "name": "Mars",
            "Io": 1.318
        },
        {
            "name": "Medusa",
            "Io": 0.441
        },
        {
            "name": "Meepo",
            "Io": -1.028
        },
        {
            "name": "Mirana",
            "Io": 0.011
        },
        {
            "name": "Monkey King",
            "Io": 2.824
        },
        {
            "name": "Morphling",
            "Io": -0.665
        },
        {
            "name": "Muerta",
            "Io": 1.371
        },
        {
            "name": "Naga Siren",
            "Io": -2.319
        },
        {
            "name": "Nature's Prophet",
            "Io": -1.565
        },
        {
            "name": "Necrophos",
            "Io": -0.183
        },
        {
            "name": "Night Stalker",
            "Io": -2.396
        },
        {
            "name": "Nyx Assassin",
            "Io": -0.944
        },
        {
            "name": "Ogre Magi",
            "Io": 0.726
        },
        {
            "name": "Omniknight",
            "Io": 0.84
        },
        {
            "name": "Oracle",
            "Io": -2.191
        },
        {
            "name": "Outworld Destroyer",
            "Io": 0.315
        },
        {
            "name": "Pangolier",
            "Io": 0.574
        },
        {
            "name": "Phantom Assassin",
            "Io": 0.296
        },
        {
            "name": "Phantom Lancer",
            "Io": 0.249
        },
        {
            "name": "Phoenix",
            "Io": -1.434
        },
        {
            "name": "Primal Beast",
            "Io": 0.978
        },
        {
            "name": "Puck",
            "Io": -1.438
        },
        {
            "name": "Pudge",
            "Io": -0.398
        },
        {
            "name": "Pugna",
            "Io": -2.426
        },
        {
            "name": "Queen of Pain",
            "Io": -1.053
        },
        {
            "name": "Razor",
            "Io": 1.666
        },
        {
            "name": "Riki",
            "Io": 0.127
        },
        {
            "name": "Ringmaster",
            "Io": -0.036
        },
        {
            "name": "Rubick",
            "Io": -0.581
        },
        {
            "name": "Sand King",
            "Io": 1.659
        },
        {
            "name": "Shadow Demon",
            "Io": -1.721
        },
        {
            "name": "Shadow Fiend",
            "Io": 1.487
        },
        {
            "name": "Shadow Shaman",
            "Io": 0.275
        },
        {
            "name": "Silencer",
            "Io": -0.96
        },
        {
            "name": "Skywrath Mage",
            "Io": -0.941
        },
        {
            "name": "Slardar",
            "Io": 2.125
        },
        {
            "name": "Slark",
            "Io": 0.447
        },
        {
            "name": "Snapfire",
            "Io": -0.375
        },
        {
            "name": "Sniper",
            "Io": 0.226
        },
        {
            "name": "Spectre",
            "Io": -2.7
        },
        {
            "name": "Spirit Breaker",
            "Io": -0.592
        },
        {
            "name": "Storm Spirit",
            "Io": -1.713
        },
        {
            "name": "Sven",
            "Io": 1.176
        },
        {
            "name": "Techies",
            "Io": 1.366
        },
        {
            "name": "Templar Assassin",
            "Io": 0.338
        },
        {
            "name": "Terrorblade",
            "Io": 0.872
        },
        {
            "name": "Tidehunter",
            "Io": -1.101
        },
        {
            "name": "Timbersaw",
            "Io": 0.197
        },
        {
            "name": "Tinker",
            "Io": -0.951
        },
        {
            "name": "Tiny",
            "Io": -0.055
        },
        {
            "name": "Treant Protector",
            "Io": 0.397
        },
        {
            "name": "Troll Warlord",
            "Io": 3.388
        },
        {
            "name": "Tusk",
            "Io": 0.022
        },
        {
            "name": "Underlord",
            "Io": 2.29
        },
        {
            "name": "Undying",
            "Io": 0.524
        },
        {
            "name": "Ursa",
            "Io": 0.371
        },
        {
            "name": "Vengeful Spirit",
            "Io": 0.374
        },
        {
            "name": "Venomancer",
            "Io": 0.726
        },
        {
            "name": "Viper",
            "Io": 1.007
        },
        {
            "name": "Visage",
            "Io": -0.549
        },
        {
            "name": "Void Spirit",
            "Io": -3.136
        },
        {
            "name": "Warlock",
            "Io": -0.175
        },
        {
            "name": "Weaver",
            "Io": 0.65
        },
        {
            "name": "Windranger",
            "Io": 1.311
        },
        {
            "name": "Winter Wyvern",
            "Io": 1.607
        },
        {
            "name": "Witch Doctor",
            "Io": 0.613
        },
        {
            "name": "Wraith King",
            "Io": -1.611
        },
        {
            "name": "Zeus",
            "Io": -1.727
        }
    ],
    "Lone Druid": [
        {
            "name": "Abaddon",
            "Lone Druid": -1.841
        },
        {
            "name": "Alchemist",
            "Lone Druid": 0.341
        },
        {
            "name": "Ancient Apparition",
            "Lone Druid": -0.497
        },
        {
            "name": "Anti-Mage",
            "Lone Druid": -4.659
        },
        {
            "name": "Arc Warden",
            "Lone Druid": -2.024
        },
        {
            "name": "Axe",
            "Lone Druid": 0.242
        },
        {
            "name": "Bane",
            "Lone Druid": -2.65
        },
        {
            "name": "Batrider",
            "Lone Druid": 0.773
        },
        {
            "name": "Beastmaster",
            "Lone Druid": 1.508
        },
        {
            "name": "Bloodseeker",
            "Lone Druid": 3.074
        },
        {
            "name": "Bounty Hunter",
            "Lone Druid": 0.043
        },
        {
            "name": "Brewmaster",
            "Lone Druid": 4.149
        },
        {
            "name": "Bristleback",
            "Lone Druid": 2.854
        },
        {
            "name": "Broodmother",
            "Lone Druid": 0.736
        },
        {
            "name": "Centaur Warrunner",
            "Lone Druid": -1.287
        },
        {
            "name": "Chaos Knight",
            "Lone Druid": 1.24
        },
        {
            "name": "Chen",
            "Lone Druid": -0.089
        },
        {
            "name": "Clinkz",
            "Lone Druid": -1.368
        },
        {
            "name": "Clockwerk",
            "Lone Druid": -2.148
        },
        {
            "name": "Crystal Maiden",
            "Lone Druid": 2.348
        },
        {
            "name": "Dark Seer",
            "Lone Druid": 1.321
        },
        {
            "name": "Dark Willow",
            "Lone Druid": 1.936
        },
        {
            "name": "Dawnbreaker",
            "Lone Druid": -0.052
        },
        {
            "name": "Dazzle",
            "Lone Druid": 0.242
        },
        {
            "name": "Death Prophet",
            "Lone Druid": -1.664
        },
        {
            "name": "Disruptor",
            "Lone Druid": -0.982
        },
        {
            "name": "Doom",
            "Lone Druid": -2.212
        },
        {
            "name": "Dragon Knight",
            "Lone Druid": 0.206
        },
        {
            "name": "Drow Ranger",
            "Lone Druid": -0.67
        },
        {
            "name": "Earth Spirit",
            "Lone Druid": -3.348
        },
        {
            "name": "Earthshaker",
            "Lone Druid": 2.791
        },
        {
            "name": "Elder Titan",
            "Lone Druid": 1.207
        },
        {
            "name": "Ember Spirit",
            "Lone Druid": -4.11
        },
        {
            "name": "Enchantress",
            "Lone Druid": 1.85
        },
        {
            "name": "Enigma",
            "Lone Druid": -2.43
        },
        {
            "name": "Faceless Void",
            "Lone Druid": -2.007
        },
        {
            "name": "Grimstroke",
            "Lone Druid": -1.785
        },
        {
            "name": "Gyrocopter",
            "Lone Druid": 1.49
        },
        {
            "name": "Hoodwink",
            "Lone Druid": 1.017
        },
        {
            "name": "Huskar",
            "Lone Druid": 1.467
        },
        {
            "name": "Invoker",
            "Lone Druid": 0.21
        },
        {
            "name": "Io",
            "Lone Druid": -0.981
        },
        {
            "name": "Jakiro",
            "Lone Druid": 3.24
        },
        {
            "name": "Juggernaut",
            "Lone Druid": -1.969
        },
        {
            "name": "Keeper of the Light",
            "Lone Druid": -1.285
        },
        {
            "name": "Kez",
            "Lone Druid": 0.771
        },
        {
            "name": "Kunkka",
            "Lone Druid": 3.714
        },
        {
            "name": "Legion Commander",
            "Lone Druid": -1.335
        },
        {
            "name": "Leshrac",
            "Lone Druid": -0.011
        },
        {
            "name": "Lich",
            "Lone Druid": 1.667
        },
        {
            "name": "Lifestealer",
            "Lone Druid": -1.762
        },
        {
            "name": "Lina",
            "Lone Druid": 1.026
        },
        {
            "name": "Lion",
            "Lone Druid": -1.144
        },
        {
            "name": "Luna",
            "Lone Druid": 2.002
        },
        {
            "name": "Lycan",
            "Lone Druid": 2.622
        },
        {
            "name": "Magnus",
            "Lone Druid": 0.692
        },
        {
            "name": "Marci",
            "Lone Druid": 1.696
        },
        {
            "name": "Mars",
            "Lone Druid": -2.431
        },
        {
            "name": "Medusa",
            "Lone Druid": -1.186
        },
        {
            "name": "Meepo",
            "Lone Druid": 0.973
        },
        {
            "name": "Mirana",
            "Lone Druid": -0.39
        },
        {
            "name": "Monkey King",
            "Lone Druid": 4.123
        },
        {
            "name": "Morphling",
            "Lone Druid": -2.542
        },
        {
            "name": "Muerta",
            "Lone Druid": 0.016
        },
        {
            "name": "Naga Siren",
            "Lone Druid": 7.634
        },
        {
            "name": "Nature's Prophet",
            "Lone Druid": -0.089
        },
        {
            "name": "Necrophos",
            "Lone Druid": 0.893
        },
        {
            "name": "Night Stalker",
            "Lone Druid": -4.552
        },
        {
            "name": "Nyx Assassin",
            "Lone Druid": -1.792
        },
        {
            "name": "Ogre Magi",
            "Lone Druid": -0.464
        },
        {
            "name": "Omniknight",
            "Lone Druid": 1.323
        },
        {
            "name": "Oracle",
            "Lone Druid": -2.674
        },
        {
            "name": "Outworld Destroyer",
            "Lone Druid": -0.592
        },
        {
            "name": "Pangolier",
            "Lone Druid": 3.364
        },
        {
            "name": "Phantom Assassin",
            "Lone Druid": 3.373
        },
        {
            "name": "Phantom Lancer",
            "Lone Druid": 3.743
        },
        {
            "name": "Phoenix",
            "Lone Druid": 2.487
        },
        {
            "name": "Primal Beast",
            "Lone Druid": -0.291
        },
        {
            "name": "Puck",
            "Lone Druid": -2.652
        },
        {
            "name": "Pudge",
            "Lone Druid": -1.209
        },
        {
            "name": "Pugna",
            "Lone Druid": -1.21
        },
        {
            "name": "Queen of Pain",
            "Lone Druid": -1.239
        },
        {
            "name": "Razor",
            "Lone Druid": 2.261
        },
        {
            "name": "Riki",
            "Lone Druid": 1.228
        },
        {
            "name": "Ringmaster",
            "Lone Druid": -0.209
        },
        {
            "name": "Rubick",
            "Lone Druid": -0.224
        },
        {
            "name": "Sand King",
            "Lone Druid": 3.848
        },
        {
            "name": "Shadow Demon",
            "Lone Druid": -3.117
        },
        {
            "name": "Shadow Fiend",
            "Lone Druid": 0.216
        },
        {
            "name": "Shadow Shaman",
            "Lone Druid": -1.953
        },
        {
            "name": "Silencer",
            "Lone Druid": -3.541
        },
        {
            "name": "Skywrath Mage",
            "Lone Druid": -2.13
        },
        {
            "name": "Slardar",
            "Lone Druid": 0.393
        },
        {
            "name": "Slark",
            "Lone Druid": -0.417
        },
        {
            "name": "Snapfire",
            "Lone Druid": 0.778
        },
        {
            "name": "Sniper",
            "Lone Druid": 0.45
        },
        {
            "name": "Spectre",
            "Lone Druid": -2.873
        },
        {
            "name": "Spirit Breaker",
            "Lone Druid": 0.084
        },
        {
            "name": "Storm Spirit",
            "Lone Druid": -3.207
        },
        {
            "name": "Sven",
            "Lone Druid": -0.221
        },
        {
            "name": "Techies",
            "Lone Druid": 2.786
        },
        {
            "name": "Templar Assassin",
            "Lone Druid": 0.001
        },
        {
            "name": "Terrorblade",
            "Lone Druid": 0.8
        },
        {
            "name": "Tidehunter",
            "Lone Druid": 1.706
        },
        {
            "name": "Timbersaw",
            "Lone Druid": 1.74
        },
        {
            "name": "Tinker",
            "Lone Druid": 0.883
        },
        {
            "name": "Tiny",
            "Lone Druid": 0.094
        },
        {
            "name": "Treant Protector",
            "Lone Druid": 3.795
        },
        {
            "name": "Troll Warlord",
            "Lone Druid": 1.675
        },
        {
            "name": "Tusk",
            "Lone Druid": 3.752
        },
        {
            "name": "Underlord",
            "Lone Druid": 5.64
        },
        {
            "name": "Undying",
            "Lone Druid": 0.476
        },
        {
            "name": "Ursa",
            "Lone Druid": -0.794
        },
        {
            "name": "Vengeful Spirit",
            "Lone Druid": -1.307
        },
        {
            "name": "Venomancer",
            "Lone Druid": 2.613
        },
        {
            "name": "Viper",
            "Lone Druid": 1.604
        },
        {
            "name": "Visage",
            "Lone Druid": -0.635
        },
        {
            "name": "Void Spirit",
            "Lone Druid": -3.31
        },
        {
            "name": "Warlock",
            "Lone Druid": 2.541
        },
        {
            "name": "Weaver",
            "Lone Druid": 1.214
        },
        {
            "name": "Windranger",
            "Lone Druid": 2.327
        },
        {
            "name": "Winter Wyvern",
            "Lone Druid": 3.07
        },
        {
            "name": "Witch Doctor",
            "Lone Druid": 0.983
        },
        {
            "name": "Wraith King",
            "Lone Druid": -3.8
        },
        {
            "name": "Zeus",
            "Lone Druid": -0.968
        }
    ],
    "Lycan": [
        {
            "name": "Abaddon",
            "Lycan": -1.148
        },
        {
            "name": "Alchemist",
            "Lycan": 2.172
        },
        {
            "name": "Ancient Apparition",
            "Lycan": -3.515
        },
        {
            "name": "Anti-Mage",
            "Lycan": -0.02
        },
        {
            "name": "Arc Warden",
            "Lycan": -2.129
        },
        {
            "name": "Axe",
            "Lycan": 1.312
        },
        {
            "name": "Bane",
            "Lycan": 0.389
        },
        {
            "name": "Batrider",
            "Lycan": 1.182
        },
        {
            "name": "Beastmaster",
            "Lycan": -1.077
        },
        {
            "name": "Bloodseeker",
            "Lycan": 1.549
        },
        {
            "name": "Bounty Hunter",
            "Lycan": 2.197
        },
        {
            "name": "Brewmaster",
            "Lycan": 0.165
        },
        {
            "name": "Bristleback",
            "Lycan": 2.322
        },
        {
            "name": "Broodmother",
            "Lycan": 2.572
        },
        {
            "name": "Centaur Warrunner",
            "Lycan": -0.27
        },
        {
            "name": "Chaos Knight",
            "Lycan": 0.152
        },
        {
            "name": "Chen",
            "Lycan": 2.737
        },
        {
            "name": "Clinkz",
            "Lycan": -0.459
        },
        {
            "name": "Clockwerk",
            "Lycan": -0.82
        },
        {
            "name": "Crystal Maiden",
            "Lycan": 2.027
        },
        {
            "name": "Dark Seer",
            "Lycan": 1.35
        },
        {
            "name": "Dark Willow",
            "Lycan": 0.072
        },
        {
            "name": "Dawnbreaker",
            "Lycan": 0.505
        },
        {
            "name": "Dazzle",
            "Lycan": -0.545
        },
        {
            "name": "Death Prophet",
            "Lycan": -1.901
        },
        {
            "name": "Disruptor",
            "Lycan": -1.435
        },
        {
            "name": "Doom",
            "Lycan": -0.708
        },
        {
            "name": "Dragon Knight",
            "Lycan": 1.468
        },
        {
            "name": "Drow Ranger",
            "Lycan": -2.025
        },
        {
            "name": "Earth Spirit",
            "Lycan": -0.087
        },
        {
            "name": "Earthshaker",
            "Lycan": 2.322
        },
        {
            "name": "Elder Titan",
            "Lycan": -1.993
        },
        {
            "name": "Ember Spirit",
            "Lycan": 0.254
        },
        {
            "name": "Enchantress",
            "Lycan": 2.319
        },
        {
            "name": "Enigma",
            "Lycan": -0.956
        },
        {
            "name": "Faceless Void",
            "Lycan": -1.609
        },
        {
            "name": "Grimstroke",
            "Lycan": -2.962
        },
        {
            "name": "Gyrocopter",
            "Lycan": -0.502
        },
        {
            "name": "Hoodwink",
            "Lycan": 1.089
        },
        {
            "name": "Huskar",
            "Lycan": 1.657
        },
        {
            "name": "Invoker",
            "Lycan": -2.148
        },
        {
            "name": "Io",
            "Lycan": 0.495
        },
        {
            "name": "Jakiro",
            "Lycan": 2.127
        },
        {
            "name": "Juggernaut",
            "Lycan": -1.109
        },
        {
            "name": "Keeper of the Light",
            "Lycan": -1.144
        },
        {
            "name": "Kez",
            "Lycan": 3.101
        },
        {
            "name": "Kunkka",
            "Lycan": 2.058
        },
        {
            "name": "Legion Commander",
            "Lycan": 0.414
        },
        {
            "name": "Leshrac",
            "Lycan": 1.199
        },
        {
            "name": "Lich",
            "Lycan": -0.82
        },
        {
            "name": "Lifestealer",
            "Lycan": -0.142
        },
        {
            "name": "Lina",
            "Lycan": 0.777
        },
        {
            "name": "Lion",
            "Lycan": -0.577
        },
        {
            "name": "Lone Druid",
            "Lycan": -2.679
        },
        {
            "name": "Luna",
            "Lycan": 0.64
        },
        {
            "name": "Magnus",
            "Lycan": 0.249
        },
        {
            "name": "Marci",
            "Lycan": 2.087
        },
        {
            "name": "Mars",
            "Lycan": -1.254
        },
        {
            "name": "Medusa",
            "Lycan": -1.53
        },
        {
            "name": "Meepo",
            "Lycan": 0.895
        },
        {
            "name": "Mirana",
            "Lycan": 0.567
        },
        {
            "name": "Monkey King",
            "Lycan": 1.018
        },
        {
            "name": "Morphling",
            "Lycan": 0.937
        },
        {
            "name": "Muerta",
            "Lycan": -0.623
        },
        {
            "name": "Naga Siren",
            "Lycan": 6.897
        },
        {
            "name": "Nature's Prophet",
            "Lycan": -0.182
        },
        {
            "name": "Necrophos",
            "Lycan": 2.907
        },
        {
            "name": "Night Stalker",
            "Lycan": -2.41
        },
        {
            "name": "Nyx Assassin",
            "Lycan": -0.287
        },
        {
            "name": "Ogre Magi",
            "Lycan": -0.084
        },
        {
            "name": "Omniknight",
            "Lycan": -0.156
        },
        {
            "name": "Oracle",
            "Lycan": -2.715
        },
        {
            "name": "Outworld Destroyer",
            "Lycan": -1.599
        },
        {
            "name": "Pangolier",
            "Lycan": 2.929
        },
        {
            "name": "Phantom Assassin",
            "Lycan": 2.59
        },
        {
            "name": "Phantom Lancer",
            "Lycan": 3.282
        },
        {
            "name": "Phoenix",
            "Lycan": 1.342
        },
        {
            "name": "Primal Beast",
            "Lycan": 0.386
        },
        {
            "name": "Puck",
            "Lycan": -0.806
        },
        {
            "name": "Pudge",
            "Lycan": -1.713
        },
        {
            "name": "Pugna",
            "Lycan": -1.614
        },
        {
            "name": "Queen of Pain",
            "Lycan": 0.448
        },
        {
            "name": "Razor",
            "Lycan": -1.279
        },
        {
            "name": "Riki",
            "Lycan": -0.715
        },
        {
            "name": "Ringmaster",
            "Lycan": -1.762
        },
        {
            "name": "Rubick",
            "Lycan": -1.194
        },
        {
            "name": "Sand King",
            "Lycan": 0.963
        },
        {
            "name": "Shadow Demon",
            "Lycan": -0.779
        },
        {
            "name": "Shadow Fiend",
            "Lycan": -0.364
        },
        {
            "name": "Shadow Shaman",
            "Lycan": 0.704
        },
        {
            "name": "Silencer",
            "Lycan": -3.41
        },
        {
            "name": "Skywrath Mage",
            "Lycan": -4.314
        },
        {
            "name": "Slardar",
            "Lycan": 2.168
        },
        {
            "name": "Slark",
            "Lycan": 0.499
        },
        {
            "name": "Snapfire",
            "Lycan": -1.952
        },
        {
            "name": "Sniper",
            "Lycan": -2.798
        },
        {
            "name": "Spectre",
            "Lycan": -2.14
        },
        {
            "name": "Spirit Breaker",
            "Lycan": 1.256
        },
        {
            "name": "Storm Spirit",
            "Lycan": 0.69
        },
        {
            "name": "Sven",
            "Lycan": 3.058
        },
        {
            "name": "Techies",
            "Lycan": -0.132
        },
        {
            "name": "Templar Assassin",
            "Lycan": 0.328
        },
        {
            "name": "Terrorblade",
            "Lycan": 2.011
        },
        {
            "name": "Tidehunter",
            "Lycan": 3.462
        },
        {
            "name": "Timbersaw",
            "Lycan": 0.597
        },
        {
            "name": "Tinker",
            "Lycan": 0.165
        },
        {
            "name": "Tiny",
            "Lycan": 0.343
        },
        {
            "name": "Treant Protector",
            "Lycan": 2.331
        },
        {
            "name": "Troll Warlord",
            "Lycan": 4.468
        },
        {
            "name": "Tusk",
            "Lycan": 1.973
        },
        {
            "name": "Underlord",
            "Lycan": 1.283
        },
        {
            "name": "Undying",
            "Lycan": -0.948
        },
        {
            "name": "Ursa",
            "Lycan": -0.346
        },
        {
            "name": "Vengeful Spirit",
            "Lycan": -0.017
        },
        {
            "name": "Venomancer",
            "Lycan": -1.135
        },
        {
            "name": "Viper",
            "Lycan": -2.443
        },
        {
            "name": "Visage",
            "Lycan": 0.226
        },
        {
            "name": "Void Spirit",
            "Lycan": -1.372
        },
        {
            "name": "Warlock",
            "Lycan": -0.716
        },
        {
            "name": "Weaver",
            "Lycan": 0.279
        },
        {
            "name": "Windranger",
            "Lycan": 0.039
        },
        {
            "name": "Winter Wyvern",
            "Lycan": 1.615
        },
        {
            "name": "Witch Doctor",
            "Lycan": -0.618
        },
        {
            "name": "Wraith King",
            "Lycan": -1.376
        },
        {
            "name": "Zeus",
            "Lycan": -1.948
        }
    ],
    "Magnus": [
        {
            "name": "Abaddon",
            "Magnus": -1.841
        },
        {
            "name": "Alchemist",
            "Magnus": 0.54
        },
        {
            "name": "Ancient Apparition",
            "Magnus": -0.543
        },
        {
            "name": "Anti-Mage",
            "Magnus": -1.603
        },
        {
            "name": "Arc Warden",
            "Magnus": -0.925
        },
        {
            "name": "Axe",
            "Magnus": -0.209
        },
        {
            "name": "Bane",
            "Magnus": 0.5
        },
        {
            "name": "Batrider",
            "Magnus": 1.095
        },
        {
            "name": "Beastmaster",
            "Magnus": 0.6
        },
        {
            "name": "Bloodseeker",
            "Magnus": 0.265
        },
        {
            "name": "Bounty Hunter",
            "Magnus": -1.11
        },
        {
            "name": "Brewmaster",
            "Magnus": 0.326
        },
        {
            "name": "Bristleback",
            "Magnus": -0.462
        },
        {
            "name": "Broodmother",
            "Magnus": -1.375
        },
        {
            "name": "Centaur Warrunner",
            "Magnus": -0.311
        },
        {
            "name": "Chaos Knight",
            "Magnus": -1.045
        },
        {
            "name": "Chen",
            "Magnus": -0.577
        },
        {
            "name": "Clinkz",
            "Magnus": 0.192
        },
        {
            "name": "Clockwerk",
            "Magnus": -0.133
        },
        {
            "name": "Crystal Maiden",
            "Magnus": -0.808
        },
        {
            "name": "Dark Seer",
            "Magnus": -1.313
        },
        {
            "name": "Dark Willow",
            "Magnus": 0.677
        },
        {
            "name": "Dawnbreaker",
            "Magnus": 0.004
        },
        {
            "name": "Dazzle",
            "Magnus": 0.654
        },
        {
            "name": "Death Prophet",
            "Magnus": 0.687
        },
        {
            "name": "Disruptor",
            "Magnus": 0.344
        },
        {
            "name": "Doom",
            "Magnus": 0.308
        },
        {
            "name": "Dragon Knight",
            "Magnus": -0.058
        },
        {
            "name": "Drow Ranger",
            "Magnus": -0.761
        },
        {
            "name": "Earth Spirit",
            "Magnus": -0.002
        },
        {
            "name": "Earthshaker",
            "Magnus": 1.21
        },
        {
            "name": "Elder Titan",
            "Magnus": 1.177
        },
        {
            "name": "Ember Spirit",
            "Magnus": 1.656
        },
        {
            "name": "Enchantress",
            "Magnus": -0.274
        },
        {
            "name": "Enigma",
            "Magnus": 1.712
        },
        {
            "name": "Faceless Void",
            "Magnus": 1.441
        },
        {
            "name": "Grimstroke",
            "Magnus": 0.691
        },
        {
            "name": "Gyrocopter",
            "Magnus": 0.994
        },
        {
            "name": "Hoodwink",
            "Magnus": 0.742
        },
        {
            "name": "Huskar",
            "Magnus": 1.474
        },
        {
            "name": "Invoker",
            "Magnus": 0.427
        },
        {
            "name": "Io",
            "Magnus": -1.621
        },
        {
            "name": "Jakiro",
            "Magnus": -0.306
        },
        {
            "name": "Juggernaut",
            "Magnus": 1.136
        },
        {
            "name": "Keeper of the Light",
            "Magnus": -0.385
        },
        {
            "name": "Kez",
            "Magnus": 0.985
        },
        {
            "name": "Kunkka",
            "Magnus": 1.049
        },
        {
            "name": "Legion Commander",
            "Magnus": -0.646
        },
        {
            "name": "Leshrac",
            "Magnus": 0.131
        },
        {
            "name": "Lich",
            "Magnus": 0.027
        },
        {
            "name": "Lifestealer",
            "Magnus": -0.552
        },
        {
            "name": "Lina",
            "Magnus": -0.001
        },
        {
            "name": "Lion",
            "Magnus": 0.294
        },
        {
            "name": "Lone Druid",
            "Magnus": -0.592
        },
        {
            "name": "Luna",
            "Magnus": 0.443
        },
        {
            "name": "Lycan",
            "Magnus": 0.191
        },
        {
            "name": "Marci",
            "Magnus": -2.185
        },
        {
            "name": "Mars",
            "Magnus": 0.787
        },
        {
            "name": "Medusa",
            "Magnus": 0.104
        },
        {
            "name": "Meepo",
            "Magnus": -0.631
        },
        {
            "name": "Mirana",
            "Magnus": 0.106
        },
        {
            "name": "Monkey King",
            "Magnus": 0.067
        },
        {
            "name": "Morphling",
            "Magnus": 0.509
        },
        {
            "name": "Muerta",
            "Magnus": 0.015
        },
        {
            "name": "Naga Siren",
            "Magnus": -1.792
        },
        {
            "name": "Nature's Prophet",
            "Magnus": 0.591
        },
        {
            "name": "Necrophos",
            "Magnus": 0.152
        },
        {
            "name": "Night Stalker",
            "Magnus": -0.04
        },
        {
            "name": "Nyx Assassin",
            "Magnus": -0.154
        },
        {
            "name": "Ogre Magi",
            "Magnus": -1.059
        },
        {
            "name": "Omniknight",
            "Magnus": -0.014
        },
        {
            "name": "Oracle",
            "Magnus": -1.067
        },
        {
            "name": "Outworld Destroyer",
            "Magnus": -0.357
        },
        {
            "name": "Pangolier",
            "Magnus": 0.081
        },
        {
            "name": "Phantom Assassin",
            "Magnus": -1.116
        },
        {
            "name": "Phantom Lancer",
            "Magnus": -2.076
        },
        {
            "name": "Phoenix",
            "Magnus": 1.094
        },
        {
            "name": "Primal Beast",
            "Magnus": 0.642
        },
        {
            "name": "Puck",
            "Magnus": 1.601
        },
        {
            "name": "Pudge",
            "Magnus": -1.851
        },
        {
            "name": "Pugna",
            "Magnus": -0.866
        },
        {
            "name": "Queen of Pain",
            "Magnus": 0.493
        },
        {
            "name": "Razor",
            "Magnus": -0.8
        },
        {
            "name": "Riki",
            "Magnus": -0.033
        },
        {
            "name": "Ringmaster",
            "Magnus": 0.004
        },
        {
            "name": "Rubick",
            "Magnus": 1.545
        },
        {
            "name": "Sand King",
            "Magnus": -0.986
        },
        {
            "name": "Shadow Demon",
            "Magnus": -0.865
        },
        {
            "name": "Shadow Fiend",
            "Magnus": 0.535
        },
        {
            "name": "Shadow Shaman",
            "Magnus": 0.28
        },
        {
            "name": "Silencer",
            "Magnus": 0.328
        },
        {
            "name": "Skywrath Mage",
            "Magnus": -0.29
        },
        {
            "name": "Slardar",
            "Magnus": -0.018
        },
        {
            "name": "Slark",
            "Magnus": -1.172
        },
        {
            "name": "Snapfire",
            "Magnus": -0.239
        },
        {
            "name": "Sniper",
            "Magnus": 0.159
        },
        {
            "name": "Spectre",
            "Magnus": -0.891
        },
        {
            "name": "Spirit Breaker",
            "Magnus": -0.085
        },
        {
            "name": "Storm Spirit",
            "Magnus": -0.185
        },
        {
            "name": "Sven",
            "Magnus": 0.687
        },
        {
            "name": "Techies",
            "Magnus": -0.357
        },
        {
            "name": "Templar Assassin",
            "Magnus": 0.854
        },
        {
            "name": "Terrorblade",
            "Magnus": -0.475
        },
        {
            "name": "Tidehunter",
            "Magnus": 0.578
        },
        {
            "name": "Timbersaw",
            "Magnus": -0.453
        },
        {
            "name": "Tinker",
            "Magnus": -0.621
        },
        {
            "name": "Tiny",
            "Magnus": 0.482
        },
        {
            "name": "Treant Protector",
            "Magnus": -0.275
        },
        {
            "name": "Troll Warlord",
            "Magnus": 1.026
        },
        {
            "name": "Tusk",
            "Magnus": -0.05
        },
        {
            "name": "Underlord",
            "Magnus": -0.45
        },
        {
            "name": "Undying",
            "Magnus": -0.857
        },
        {
            "name": "Ursa",
            "Magnus": 1.595
        },
        {
            "name": "Vengeful Spirit",
            "Magnus": -0.265
        },
        {
            "name": "Venomancer",
            "Magnus": -0.954
        },
        {
            "name": "Viper",
            "Magnus": -0.169
        },
        {
            "name": "Visage",
            "Magnus": -0.613
        },
        {
            "name": "Void Spirit",
            "Magnus": -0.253
        },
        {
            "name": "Warlock",
            "Magnus": -0.568
        },
        {
            "name": "Weaver",
            "Magnus": 0.836
        },
        {
            "name": "Windranger",
            "Magnus": 0.662
        },
        {
            "name": "Winter Wyvern",
            "Magnus": 0.956
        },
        {
            "name": "Witch Doctor",
            "Magnus": -0.09
        },
        {
            "name": "Wraith King",
            "Magnus": 0.462
        },
        {
            "name": "Zeus",
            "Magnus": -0.047
        }
    ],
    "Marci": [
        {
            "name": "Abaddon",
            "Marci": -1.809
        },
        {
            "name": "Alchemist",
            "Marci": -1.162
        },
        {
            "name": "Ancient Apparition",
            "Marci": 0.58
        },
        {
            "name": "Anti-Mage",
            "Marci": -3.159
        },
        {
            "name": "Arc Warden",
            "Marci": -2.355
        },
        {
            "name": "Axe",
            "Marci": 1.438
        },
        {
            "name": "Bane",
            "Marci": -1.146
        },
        {
            "name": "Batrider",
            "Marci": 0.506
        },
        {
            "name": "Beastmaster",
            "Marci": 0.187
        },
        {
            "name": "Bloodseeker",
            "Marci": -1.833
        },
        {
            "name": "Bounty Hunter",
            "Marci": -1.132
        },
        {
            "name": "Brewmaster",
            "Marci": -0.621
        },
        {
            "name": "Bristleback",
            "Marci": -0.956
        },
        {
            "name": "Broodmother",
            "Marci": -2.286
        },
        {
            "name": "Centaur Warrunner",
            "Marci": 0.748
        },
        {
            "name": "Chaos Knight",
            "Marci": -0.078
        },
        {
            "name": "Chen",
            "Marci": -1.142
        },
        {
            "name": "Clinkz",
            "Marci": -0.984
        },
        {
            "name": "Clockwerk",
            "Marci": -0.124
        },
        {
            "name": "Crystal Maiden",
            "Marci": -0.445
        },
        {
            "name": "Dark Seer",
            "Marci": -0.138
        },
        {
            "name": "Dark Willow",
            "Marci": 1.974
        },
        {
            "name": "Dawnbreaker",
            "Marci": 0.985
        },
        {
            "name": "Dazzle",
            "Marci": 0.961
        },
        {
            "name": "Death Prophet",
            "Marci": -0.26
        },
        {
            "name": "Disruptor",
            "Marci": 0.579
        },
        {
            "name": "Doom",
            "Marci": -1.292
        },
        {
            "name": "Dragon Knight",
            "Marci": 1.133
        },
        {
            "name": "Drow Ranger",
            "Marci": -0.873
        },
        {
            "name": "Earth Spirit",
            "Marci": -1.875
        },
        {
            "name": "Earthshaker",
            "Marci": 1.178
        },
        {
            "name": "Elder Titan",
            "Marci": 0.273
        },
        {
            "name": "Ember Spirit",
            "Marci": 1.002
        },
        {
            "name": "Enchantress",
            "Marci": -4.308
        },
        {
            "name": "Enigma",
            "Marci": 1.877
        },
        {
            "name": "Faceless Void",
            "Marci": -0.006
        },
        {
            "name": "Grimstroke",
            "Marci": -1.181
        },
        {
            "name": "Gyrocopter",
            "Marci": 0.676
        },
        {
            "name": "Hoodwink",
            "Marci": -0.375
        },
        {
            "name": "Huskar",
            "Marci": -1.263
        },
        {
            "name": "Invoker",
            "Marci": -0.415
        },
        {
            "name": "Io",
            "Marci": -1.896
        },
        {
            "name": "Jakiro",
            "Marci": 1.583
        },
        {
            "name": "Juggernaut",
            "Marci": 0.957
        },
        {
            "name": "Keeper of the Light",
            "Marci": -0.918
        },
        {
            "name": "Kez",
            "Marci": -1.485
        },
        {
            "name": "Kunkka",
            "Marci": 1.902
        },
        {
            "name": "Legion Commander",
            "Marci": -1.133
        },
        {
            "name": "Leshrac",
            "Marci": 0.331
        },
        {
            "name": "Lich",
            "Marci": 0.029
        },
        {
            "name": "Lifestealer",
            "Marci": -1.305
        },
        {
            "name": "Lina",
            "Marci": -1.714
        },
        {
            "name": "Lion",
            "Marci": 0.084
        },
        {
            "name": "Lone Druid",
            "Marci": -1.82
        },
        {
            "name": "Luna",
            "Marci": 1.161
        },
        {
            "name": "Lycan",
            "Marci": -1.961
        },
        {
            "name": "Magnus",
            "Marci": 2.739
        },
        {
            "name": "Mars",
            "Marci": 0.814
        },
        {
            "name": "Medusa",
            "Marci": 0.826
        },
        {
            "name": "Meepo",
            "Marci": -1.052
        },
        {
            "name": "Mirana",
            "Marci": 0.465
        },
        {
            "name": "Monkey King",
            "Marci": 1.862
        },
        {
            "name": "Morphling",
            "Marci": -1.664
        },
        {
            "name": "Muerta",
            "Marci": 1.69
        },
        {
            "name": "Naga Siren",
            "Marci": -0.448
        },
        {
            "name": "Nature's Prophet",
            "Marci": -0.136
        },
        {
            "name": "Necrophos",
            "Marci": -0.879
        },
        {
            "name": "Night Stalker",
            "Marci": -2.807
        },
        {
            "name": "Nyx Assassin",
            "Marci": -1.632
        },
        {
            "name": "Ogre Magi",
            "Marci": -0.693
        },
        {
            "name": "Omniknight",
            "Marci": 0.89
        },
        {
            "name": "Oracle",
            "Marci": -3.334
        },
        {
            "name": "Outworld Destroyer",
            "Marci": -1.257
        },
        {
            "name": "Pangolier",
            "Marci": 1.526
        },
        {
            "name": "Phantom Assassin",
            "Marci": -0.199
        },
        {
            "name": "Phantom Lancer",
            "Marci": -1.054
        },
        {
            "name": "Phoenix",
            "Marci": -2.65
        },
        {
            "name": "Primal Beast",
            "Marci": 1.58
        },
        {
            "name": "Puck",
            "Marci": 0.547
        },
        {
            "name": "Pudge",
            "Marci": -2.15
        },
        {
            "name": "Pugna",
            "Marci": -1.0
        },
        {
            "name": "Queen of Pain",
            "Marci": 0.487
        },
        {
            "name": "Razor",
            "Marci": -0.064
        },
        {
            "name": "Riki",
            "Marci": -0.93
        },
        {
            "name": "Ringmaster",
            "Marci": 0.322
        },
        {
            "name": "Rubick",
            "Marci": -0.235
        },
        {
            "name": "Sand King",
            "Marci": -0.83
        },
        {
            "name": "Shadow Demon",
            "Marci": -1.966
        },
        {
            "name": "Shadow Fiend",
            "Marci": 0.491
        },
        {
            "name": "Shadow Shaman",
            "Marci": -0.31
        },
        {
            "name": "Silencer",
            "Marci": -0.862
        },
        {
            "name": "Skywrath Mage",
            "Marci": -1.581
        },
        {
            "name": "Slardar",
            "Marci": 0.611
        },
        {
            "name": "Slark",
            "Marci": -0.607
        },
        {
            "name": "Snapfire",
            "Marci": 0.372
        },
        {
            "name": "Sniper",
            "Marci": 0.126
        },
        {
            "name": "Spectre",
            "Marci": -2.639
        },
        {
            "name": "Spirit Breaker",
            "Marci": 0.398
        },
        {
            "name": "Storm Spirit",
            "Marci": -0.239
        },
        {
            "name": "Sven",
            "Marci": 0.9
        },
        {
            "name": "Techies",
            "Marci": 1.625
        },
        {
            "name": "Templar Assassin",
            "Marci": 1.401
        },
        {
            "name": "Terrorblade",
            "Marci": 0.222
        },
        {
            "name": "Tidehunter",
            "Marci": 0.735
        },
        {
            "name": "Timbersaw",
            "Marci": -1.032
        },
        {
            "name": "Tinker",
            "Marci": -0.9
        },
        {
            "name": "Tiny",
            "Marci": 0.852
        },
        {
            "name": "Treant Protector",
            "Marci": 0.943
        },
        {
            "name": "Troll Warlord",
            "Marci": 3.505
        },
        {
            "name": "Tusk",
            "Marci": -0.034
        },
        {
            "name": "Underlord",
            "Marci": 0.433
        },
        {
            "name": "Undying",
            "Marci": -1.784
        },
        {
            "name": "Ursa",
            "Marci": 0.9
        },
        {
            "name": "Vengeful Spirit",
            "Marci": -0.568
        },
        {
            "name": "Venomancer",
            "Marci": -0.24
        },
        {
            "name": "Viper",
            "Marci": -1.858
        },
        {
            "name": "Visage",
            "Marci": -2.376
        },
        {
            "name": "Void Spirit",
            "Marci": -1.677
        },
        {
            "name": "Warlock",
            "Marci": -0.625
        },
        {
            "name": "Weaver",
            "Marci": 0.402
        },
        {
            "name": "Windranger",
            "Marci": 1.205
        },
        {
            "name": "Winter Wyvern",
            "Marci": 1.759
        },
        {
            "name": "Witch Doctor",
            "Marci": 0.478
        },
        {
            "name": "Wraith King",
            "Marci": -0.03
        },
        {
            "name": "Zeus",
            "Marci": -0.733
        }
    ],
    "Mirana": [
        {
            "name": "Abaddon",
            "Mirana": 0.279
        },
        {
            "name": "Alchemist",
            "Mirana": -0.032
        },
        {
            "name": "Ancient Apparition",
            "Mirana": -1.582
        },
        {
            "name": "Anti-Mage",
            "Mirana": -1.045
        },
        {
            "name": "Arc Warden",
            "Mirana": 0.392
        },
        {
            "name": "Axe",
            "Mirana": 1.203
        },
        {
            "name": "Bane",
            "Mirana": 0.585
        },
        {
            "name": "Batrider",
            "Mirana": 0.35
        },
        {
            "name": "Beastmaster",
            "Mirana": 0.47
        },
        {
            "name": "Bloodseeker",
            "Mirana": 0.796
        },
        {
            "name": "Bounty Hunter",
            "Mirana": 1.129
        },
        {
            "name": "Brewmaster",
            "Mirana": -2.22
        },
        {
            "name": "Bristleback",
            "Mirana": -0.135
        },
        {
            "name": "Broodmother",
            "Mirana": -0.031
        },
        {
            "name": "Centaur Warrunner",
            "Mirana": 0.562
        },
        {
            "name": "Chaos Knight",
            "Mirana": 0.671
        },
        {
            "name": "Chen",
            "Mirana": 0.287
        },
        {
            "name": "Clinkz",
            "Mirana": -0.323
        },
        {
            "name": "Clockwerk",
            "Mirana": -0.116
        },
        {
            "name": "Crystal Maiden",
            "Mirana": 1.16
        },
        {
            "name": "Dark Seer",
            "Mirana": 0.027
        },
        {
            "name": "Dark Willow",
            "Mirana": 1.06
        },
        {
            "name": "Dawnbreaker",
            "Mirana": -0.208
        },
        {
            "name": "Dazzle",
            "Mirana": -0.177
        },
        {
            "name": "Death Prophet",
            "Mirana": -1.104
        },
        {
            "name": "Disruptor",
            "Mirana": 0.638
        },
        {
            "name": "Doom",
            "Mirana": 0.166
        },
        {
            "name": "Dragon Knight",
            "Mirana": -0.156
        },
        {
            "name": "Drow Ranger",
            "Mirana": 0.058
        },
        {
            "name": "Earth Spirit",
            "Mirana": 1.287
        },
        {
            "name": "Earthshaker",
            "Mirana": 0.218
        },
        {
            "name": "Elder Titan",
            "Mirana": -1.124
        },
        {
            "name": "Ember Spirit",
            "Mirana": 0.251
        },
        {
            "name": "Enchantress",
            "Mirana": 0.718
        },
        {
            "name": "Enigma",
            "Mirana": 0.538
        },
        {
            "name": "Faceless Void",
            "Mirana": 0.638
        },
        {
            "name": "Grimstroke",
            "Mirana": -0.8
        },
        {
            "name": "Gyrocopter",
            "Mirana": 0.452
        },
        {
            "name": "Hoodwink",
            "Mirana": -0.251
        },
        {
            "name": "Huskar",
            "Mirana": 0.233
        },
        {
            "name": "Invoker",
            "Mirana": -0.655
        },
        {
            "name": "Io",
            "Mirana": -0.041
        },
        {
            "name": "Jakiro",
            "Mirana": -0.732
        },
        {
            "name": "Juggernaut",
            "Mirana": 0.316
        },
        {
            "name": "Keeper of the Light",
            "Mirana": 0.227
        },
        {
            "name": "Kez",
            "Mirana": -0.607
        },
        {
            "name": "Kunkka",
            "Mirana": 0.758
        },
        {
            "name": "Legion Commander",
            "Mirana": 0.679
        },
        {
            "name": "Leshrac",
            "Mirana": -0.175
        },
        {
            "name": "Lich",
            "Mirana": -1.025
        },
        {
            "name": "Lifestealer",
            "Mirana": 0.065
        },
        {
            "name": "Lina",
            "Mirana": -0.624
        },
        {
            "name": "Lion",
            "Mirana": 0.029
        },
        {
            "name": "Lone Druid",
            "Mirana": 0.408
        },
        {
            "name": "Luna",
            "Mirana": 0.812
        },
        {
            "name": "Lycan",
            "Mirana": -0.453
        },
        {
            "name": "Magnus",
            "Mirana": -0.158
        },
        {
            "name": "Marci",
            "Mirana": -0.549
        },
        {
            "name": "Mars",
            "Mirana": 1.334
        },
        {
            "name": "Medusa",
            "Mirana": -0.706
        },
        {
            "name": "Meepo",
            "Mirana": 2.169
        },
        {
            "name": "Monkey King",
            "Mirana": -0.791
        },
        {
            "name": "Morphling",
            "Mirana": 0.714
        },
        {
            "name": "Muerta",
            "Mirana": -0.345
        },
        {
            "name": "Naga Siren",
            "Mirana": 1.166
        },
        {
            "name": "Nature's Prophet",
            "Mirana": -0.376
        },
        {
            "name": "Necrophos",
            "Mirana": 0.909
        },
        {
            "name": "Night Stalker",
            "Mirana": -0.119
        },
        {
            "name": "Nyx Assassin",
            "Mirana": 1.757
        },
        {
            "name": "Ogre Magi",
            "Mirana": 0.192
        },
        {
            "name": "Omniknight",
            "Mirana": 1.065
        },
        {
            "name": "Oracle",
            "Mirana": 0.24
        },
        {
            "name": "Outworld Destroyer",
            "Mirana": 0.8
        },
        {
            "name": "Pangolier",
            "Mirana": -1.624
        },
        {
            "name": "Phantom Assassin",
            "Mirana": -1.164
        },
        {
            "name": "Phantom Lancer",
            "Mirana": 0.867
        },
        {
            "name": "Phoenix",
            "Mirana": -1.442
        },
        {
            "name": "Primal Beast",
            "Mirana": -0.127
        },
        {
            "name": "Puck",
            "Mirana": 1.678
        },
        {
            "name": "Pudge",
            "Mirana": -0.188
        },
        {
            "name": "Pugna",
            "Mirana": 0.413
        },
        {
            "name": "Queen of Pain",
            "Mirana": 0.872
        },
        {
            "name": "Razor",
            "Mirana": -1.589
        },
        {
            "name": "Riki",
            "Mirana": 0.059
        },
        {
            "name": "Ringmaster",
            "Mirana": 0.218
        },
        {
            "name": "Rubick",
            "Mirana": 0.912
        },
        {
            "name": "Sand King",
            "Mirana": -0.32
        },
        {
            "name": "Shadow Demon",
            "Mirana": 1.566
        },
        {
            "name": "Shadow Fiend",
            "Mirana": -0.219
        },
        {
            "name": "Shadow Shaman",
            "Mirana": -0.354
        },
        {
            "name": "Silencer",
            "Mirana": -0.955
        },
        {
            "name": "Skywrath Mage",
            "Mirana": -0.001
        },
        {
            "name": "Slardar",
            "Mirana": -0.121
        },
        {
            "name": "Slark",
            "Mirana": -0.352
        },
        {
            "name": "Snapfire",
            "Mirana": 0.329
        },
        {
            "name": "Sniper",
            "Mirana": -1.113
        },
        {
            "name": "Spectre",
            "Mirana": 0.675
        },
        {
            "name": "Spirit Breaker",
            "Mirana": 0.402
        },
        {
            "name": "Storm Spirit",
            "Mirana": 0.163
        },
        {
            "name": "Sven",
            "Mirana": -0.448
        },
        {
            "name": "Techies",
            "Mirana": 0.703
        },
        {
            "name": "Templar Assassin",
            "Mirana": 0.16
        },
        {
            "name": "Terrorblade",
            "Mirana": 1.486
        },
        {
            "name": "Tidehunter",
            "Mirana": 0.785
        },
        {
            "name": "Timbersaw",
            "Mirana": -0.645
        },
        {
            "name": "Tinker",
            "Mirana": -0.309
        },
        {
            "name": "Tiny",
            "Mirana": 0.66
        },
        {
            "name": "Treant Protector",
            "Mirana": 1.222
        },
        {
            "name": "Troll Warlord",
            "Mirana": -1.809
        },
        {
            "name": "Tusk",
            "Mirana": 0.937
        },
        {
            "name": "Underlord",
            "Mirana": 1.141
        },
        {
            "name": "Undying",
            "Mirana": 0.104
        },
        {
            "name": "Ursa",
            "Mirana": -0.72
        },
        {
            "name": "Vengeful Spirit",
            "Mirana": -0.122
        },
        {
            "name": "Venomancer",
            "Mirana": -0.572
        },
        {
            "name": "Viper",
            "Mirana": -0.17
        },
        {
            "name": "Visage",
            "Mirana": -0.301
        },
        {
            "name": "Void Spirit",
            "Mirana": 0.704
        },
        {
            "name": "Warlock",
            "Mirana": -0.431
        },
        {
            "name": "Weaver",
            "Mirana": -1.269
        },
        {
            "name": "Windranger",
            "Mirana": -0.464
        },
        {
            "name": "Winter Wyvern",
            "Mirana": -0.877
        },
        {
            "name": "Witch Doctor",
            "Mirana": -0.243
        },
        {
            "name": "Wraith King",
            "Mirana": 0.69
        },
        {
            "name": "Zeus",
            "Mirana": 0.714
        }
    ],
    "Nyx Assassin": [
        {
            "name": "Abaddon",
            "Nyx Assassin": 0.424
        },
        {
            "name": "Alchemist",
            "Nyx Assassin": -1.37
        },
        {
            "name": "Ancient Apparition",
            "Nyx Assassin": -0.826
        },
        {
            "name": "Anti-Mage",
            "Nyx Assassin": -1.902
        },
        {
            "name": "Arc Warden",
            "Nyx Assassin": 0.521
        },
        {
            "name": "Axe",
            "Nyx Assassin": 0.768
        },
        {
            "name": "Bane",
            "Nyx Assassin": 0.605
        },
        {
            "name": "Batrider",
            "Nyx Assassin": 0.395
        },
        {
            "name": "Beastmaster",
            "Nyx Assassin": 0.824
        },
        {
            "name": "Bloodseeker",
            "Nyx Assassin": 0.325
        },
        {
            "name": "Bounty Hunter",
            "Nyx Assassin": -0.381
        },
        {
            "name": "Brewmaster",
            "Nyx Assassin": -0.09
        },
        {
            "name": "Bristleback",
            "Nyx Assassin": 0.258
        },
        {
            "name": "Broodmother",
            "Nyx Assassin": 0.376
        },
        {
            "name": "Centaur Warrunner",
            "Nyx Assassin": 0.609
        },
        {
            "name": "Chaos Knight",
            "Nyx Assassin": 1.271
        },
        {
            "name": "Chen",
            "Nyx Assassin": 1.031
        },
        {
            "name": "Clinkz",
            "Nyx Assassin": -0.632
        },
        {
            "name": "Clockwerk",
            "Nyx Assassin": 0.477
        },
        {
            "name": "Crystal Maiden",
            "Nyx Assassin": -0.461
        },
        {
            "name": "Dark Seer",
            "Nyx Assassin": -1.616
        },
        {
            "name": "Dark Willow",
            "Nyx Assassin": 0.006
        },
        {
            "name": "Dawnbreaker",
            "Nyx Assassin": -1.941
        },
        {
            "name": "Dazzle",
            "Nyx Assassin": -0.841
        },
        {
            "name": "Death Prophet",
            "Nyx Assassin": 0.457
        },
        {
            "name": "Disruptor",
            "Nyx Assassin": 0.578
        },
        {
            "name": "Doom",
            "Nyx Assassin": 1.434
        },
        {
            "name": "Dragon Knight",
            "Nyx Assassin": 1.599
        },
        {
            "name": "Drow Ranger",
            "Nyx Assassin": -0.309
        },
        {
            "name": "Earth Spirit",
            "Nyx Assassin": 0.861
        },
        {
            "name": "Earthshaker",
            "Nyx Assassin": 0.501
        },
        {
            "name": "Elder Titan",
            "Nyx Assassin": 0.464
        },
        {
            "name": "Ember Spirit",
            "Nyx Assassin": 1.688
        },
        {
            "name": "Enchantress",
            "Nyx Assassin": -0.286
        },
        {
            "name": "Enigma",
            "Nyx Assassin": 0.088
        },
        {
            "name": "Faceless Void",
            "Nyx Assassin": -0.297
        },
        {
            "name": "Grimstroke",
            "Nyx Assassin": 1.113
        },
        {
            "name": "Gyrocopter",
            "Nyx Assassin": -0.382
        },
        {
            "name": "Hoodwink",
            "Nyx Assassin": -0.635
        },
        {
            "name": "Huskar",
            "Nyx Assassin": 1.738
        },
        {
            "name": "Invoker",
            "Nyx Assassin": -1.112
        },
        {
            "name": "Io",
            "Nyx Assassin": 0.868
        },
        {
            "name": "Jakiro",
            "Nyx Assassin": 0.438
        },
        {
            "name": "Juggernaut",
            "Nyx Assassin": 0.53
        },
        {
            "name": "Keeper of the Light",
            "Nyx Assassin": -1.458
        },
        {
            "name": "Kez",
            "Nyx Assassin": 1.746
        },
        {
            "name": "Kunkka",
            "Nyx Assassin": 0.675
        },
        {
            "name": "Legion Commander",
            "Nyx Assassin": 0.54
        },
        {
            "name": "Leshrac",
            "Nyx Assassin": -0.858
        },
        {
            "name": "Lich",
            "Nyx Assassin": 0.832
        },
        {
            "name": "Lifestealer",
            "Nyx Assassin": 1.869
        },
        {
            "name": "Lina",
            "Nyx Assassin": 0.378
        },
        {
            "name": "Lion",
            "Nyx Assassin": 1.268
        },
        {
            "name": "Lone Druid",
            "Nyx Assassin": 1.683
        },
        {
            "name": "Luna",
            "Nyx Assassin": -1.519
        },
        {
            "name": "Lycan",
            "Nyx Assassin": 0.455
        },
        {
            "name": "Magnus",
            "Nyx Assassin": 0.176
        },
        {
            "name": "Marci",
            "Nyx Assassin": 1.629
        },
        {
            "name": "Mars",
            "Nyx Assassin": -0.045
        },
        {
            "name": "Medusa",
            "Nyx Assassin": -2.907
        },
        {
            "name": "Meepo",
            "Nyx Assassin": -0.144
        },
        {
            "name": "Mirana",
            "Nyx Assassin": -1.747
        },
        {
            "name": "Monkey King",
            "Nyx Assassin": -0.979
        },
        {
            "name": "Morphling",
            "Nyx Assassin": -2.365
        },
        {
            "name": "Muerta",
            "Nyx Assassin": -1.446
        },
        {
            "name": "Naga Siren",
            "Nyx Assassin": 1.576
        },
        {
            "name": "Nature's Prophet",
            "Nyx Assassin": -1.664
        },
        {
            "name": "Necrophos",
            "Nyx Assassin": 0.138
        },
        {
            "name": "Night Stalker",
            "Nyx Assassin": 1.186
        },
        {
            "name": "Ogre Magi",
            "Nyx Assassin": 1.056
        },
        {
            "name": "Omniknight",
            "Nyx Assassin": 0.096
        },
        {
            "name": "Oracle",
            "Nyx Assassin": 0.052
        },
        {
            "name": "Outworld Destroyer",
            "Nyx Assassin": -3.139
        },
        {
            "name": "Pangolier",
            "Nyx Assassin": 0.832
        },
        {
            "name": "Phantom Assassin",
            "Nyx Assassin": -1.364
        },
        {
            "name": "Phantom Lancer",
            "Nyx Assassin": -0.827
        },
        {
            "name": "Phoenix",
            "Nyx Assassin": -0.621
        },
        {
            "name": "Primal Beast",
            "Nyx Assassin": -0.54
        },
        {
            "name": "Puck",
            "Nyx Assassin": -2.212
        },
        {
            "name": "Pudge",
            "Nyx Assassin": 0.727
        },
        {
            "name": "Pugna",
            "Nyx Assassin": -1.056
        },
        {
            "name": "Queen of Pain",
            "Nyx Assassin": -0.358
        },
        {
            "name": "Razor",
            "Nyx Assassin": -0.225
        },
        {
            "name": "Riki",
            "Nyx Assassin": -0.563
        },
        {
            "name": "Ringmaster",
            "Nyx Assassin": -1.213
        },
        {
            "name": "Rubick",
            "Nyx Assassin": 0.045
        },
        {
            "name": "Sand King",
            "Nyx Assassin": -1.227
        },
        {
            "name": "Shadow Demon",
            "Nyx Assassin": -0.891
        },
        {
            "name": "Shadow Fiend",
            "Nyx Assassin": -1.51
        },
        {
            "name": "Shadow Shaman",
            "Nyx Assassin": 1.119
        },
        {
            "name": "Silencer",
            "Nyx Assassin": 0.843
        },
        {
            "name": "Skywrath Mage",
            "Nyx Assassin": 1.043
        },
        {
            "name": "Slardar",
            "Nyx Assassin": 1.721
        },
        {
            "name": "Slark",
            "Nyx Assassin": -0.253
        },
        {
            "name": "Snapfire",
            "Nyx Assassin": -0.618
        },
        {
            "name": "Sniper",
            "Nyx Assassin": -2.517
        },
        {
            "name": "Spectre",
            "Nyx Assassin": 0.61
        },
        {
            "name": "Spirit Breaker",
            "Nyx Assassin": 0.269
        },
        {
            "name": "Storm Spirit",
            "Nyx Assassin": -1.472
        },
        {
            "name": "Sven",
            "Nyx Assassin": 1.38
        },
        {
            "name": "Techies",
            "Nyx Assassin": 0.06
        },
        {
            "name": "Templar Assassin",
            "Nyx Assassin": 0.636
        },
        {
            "name": "Terrorblade",
            "Nyx Assassin": 0.336
        },
        {
            "name": "Tidehunter",
            "Nyx Assassin": 0.431
        },
        {
            "name": "Timbersaw",
            "Nyx Assassin": -0.411
        },
        {
            "name": "Tinker",
            "Nyx Assassin": -2.434
        },
        {
            "name": "Tiny",
            "Nyx Assassin": 1.215
        },
        {
            "name": "Treant Protector",
            "Nyx Assassin": -1.521
        },
        {
            "name": "Troll Warlord",
            "Nyx Assassin": 0.482
        },
        {
            "name": "Tusk",
            "Nyx Assassin": 0.318
        },
        {
            "name": "Underlord",
            "Nyx Assassin": 0.087
        },
        {
            "name": "Undying",
            "Nyx Assassin": 0.15
        },
        {
            "name": "Ursa",
            "Nyx Assassin": 1.353
        },
        {
            "name": "Vengeful Spirit",
            "Nyx Assassin": -0.043
        },
        {
            "name": "Venomancer",
            "Nyx Assassin": 0.102
        },
        {
            "name": "Viper",
            "Nyx Assassin": 0.773
        },
        {
            "name": "Visage",
            "Nyx Assassin": 1.883
        },
        {
            "name": "Void Spirit",
            "Nyx Assassin": 0.31
        },
        {
            "name": "Warlock",
            "Nyx Assassin": -0.482
        },
        {
            "name": "Weaver",
            "Nyx Assassin": -2.781
        },
        {
            "name": "Windranger",
            "Nyx Assassin": -0.893
        },
        {
            "name": "Winter Wyvern",
            "Nyx Assassin": -0.424
        },
        {
            "name": "Witch Doctor",
            "Nyx Assassin": -0.078
        },
        {
            "name": "Wraith King",
            "Nyx Assassin": 0.779
        },
        {
            "name": "Zeus",
            "Nyx Assassin": 0.722
        }
    ],
    "Pangolier": [
        {
            "name": "Abaddon",
            "Pangolier": -1.686
        },
        {
            "name": "Alchemist",
            "Pangolier": -0.439
        },
        {
            "name": "Ancient Apparition",
            "Pangolier": -1.11
        },
        {
            "name": "Anti-Mage",
            "Pangolier": -0.363
        },
        {
            "name": "Arc Warden",
            "Pangolier": -2.644
        },
        {
            "name": "Axe",
            "Pangolier": 1.007
        },
        {
            "name": "Bane",
            "Pangolier": 1.369
        },
        {
            "name": "Batrider",
            "Pangolier": -1.192
        },
        {
            "name": "Beastmaster",
            "Pangolier": -0.893
        },
        {
            "name": "Bloodseeker",
            "Pangolier": 4.372
        },
        {
            "name": "Bounty Hunter",
            "Pangolier": 0.792
        },
        {
            "name": "Brewmaster",
            "Pangolier": -1.86
        },
        {
            "name": "Bristleback",
            "Pangolier": 0.419
        },
        {
            "name": "Broodmother",
            "Pangolier": 0.385
        },
        {
            "name": "Centaur Warrunner",
            "Pangolier": -1.057
        },
        {
            "name": "Chaos Knight",
            "Pangolier": -2.594
        },
        {
            "name": "Chen",
            "Pangolier": 1.887
        },
        {
            "name": "Clinkz",
            "Pangolier": 0.691
        },
        {
            "name": "Clockwerk",
            "Pangolier": 1.244
        },
        {
            "name": "Crystal Maiden",
            "Pangolier": -0.321
        },
        {
            "name": "Dark Seer",
            "Pangolier": 0.45
        },
        {
            "name": "Dark Willow",
            "Pangolier": 1.273
        },
        {
            "name": "Dawnbreaker",
            "Pangolier": -0.33
        },
        {
            "name": "Dazzle",
            "Pangolier": 0.636
        },
        {
            "name": "Death Prophet",
            "Pangolier": 0.156
        },
        {
            "name": "Disruptor",
            "Pangolier": -1.487
        },
        {
            "name": "Doom",
            "Pangolier": 0.845
        },
        {
            "name": "Dragon Knight",
            "Pangolier": 1.139
        },
        {
            "name": "Drow Ranger",
            "Pangolier": 2.523
        },
        {
            "name": "Earth Spirit",
            "Pangolier": -0.333
        },
        {
            "name": "Earthshaker",
            "Pangolier": 0.549
        },
        {
            "name": "Elder Titan",
            "Pangolier": 3.464
        },
        {
            "name": "Ember Spirit",
            "Pangolier": 0.496
        },
        {
            "name": "Enchantress",
            "Pangolier": -0.426
        },
        {
            "name": "Enigma",
            "Pangolier": 1.273
        },
        {
            "name": "Faceless Void",
            "Pangolier": 0.47
        },
        {
            "name": "Grimstroke",
            "Pangolier": 2.521
        },
        {
            "name": "Gyrocopter",
            "Pangolier": 1.49
        },
        {
            "name": "Hoodwink",
            "Pangolier": 0.448
        },
        {
            "name": "Huskar",
            "Pangolier": 5.259
        },
        {
            "name": "Invoker",
            "Pangolier": -1.148
        },
        {
            "name": "Io",
            "Pangolier": -0.487
        },
        {
            "name": "Jakiro",
            "Pangolier": -1.688
        },
        {
            "name": "Juggernaut",
            "Pangolier": 1.206
        },
        {
            "name": "Keeper of the Light",
            "Pangolier": -0.281
        },
        {
            "name": "Kez",
            "Pangolier": 1.266
        },
        {
            "name": "Kunkka",
            "Pangolier": -0.354
        },
        {
            "name": "Legion Commander",
            "Pangolier": -0.738
        },
        {
            "name": "Leshrac",
            "Pangolier": 0.518
        },
        {
            "name": "Lich",
            "Pangolier": -0.774
        },
        {
            "name": "Lifestealer",
            "Pangolier": -1.722
        },
        {
            "name": "Lina",
            "Pangolier": -0.05
        },
        {
            "name": "Lion",
            "Pangolier": 0.172
        },
        {
            "name": "Lone Druid",
            "Pangolier": -3.303
        },
        {
            "name": "Luna",
            "Pangolier": 2.242
        },
        {
            "name": "Lycan",
            "Pangolier": -2.902
        },
        {
            "name": "Magnus",
            "Pangolier": 0.582
        },
        {
            "name": "Marci",
            "Pangolier": -1.572
        },
        {
            "name": "Mars",
            "Pangolier": -0.917
        },
        {
            "name": "Medusa",
            "Pangolier": -3.053
        },
        {
            "name": "Meepo",
            "Pangolier": -0.762
        },
        {
            "name": "Mirana",
            "Pangolier": 1.826
        },
        {
            "name": "Monkey King",
            "Pangolier": 3.981
        },
        {
            "name": "Morphling",
            "Pangolier": 1.054
        },
        {
            "name": "Muerta",
            "Pangolier": 1.398
        },
        {
            "name": "Naga Siren",
            "Pangolier": -0.923
        },
        {
            "name": "Nature's Prophet",
            "Pangolier": -0.331
        },
        {
            "name": "Necrophos",
            "Pangolier": -0.38
        },
        {
            "name": "Night Stalker",
            "Pangolier": 1.455
        },
        {
            "name": "Nyx Assassin",
            "Pangolier": -0.669
        },
        {
            "name": "Ogre Magi",
            "Pangolier": 0.934
        },
        {
            "name": "Omniknight",
            "Pangolier": 1.441
        },
        {
            "name": "Oracle",
            "Pangolier": -2.256
        },
        {
            "name": "Outworld Destroyer",
            "Pangolier": -1.989
        },
        {
            "name": "Phantom Assassin",
            "Pangolier": -1.313
        },
        {
            "name": "Phantom Lancer",
            "Pangolier": -0.389
        },
        {
            "name": "Phoenix",
            "Pangolier": 1.142
        },
        {
            "name": "Primal Beast",
            "Pangolier": 3.542
        },
        {
            "name": "Puck",
            "Pangolier": 1.652
        },
        {
            "name": "Pudge",
            "Pangolier": -2.158
        },
        {
            "name": "Pugna",
            "Pangolier": -0.936
        },
        {
            "name": "Queen of Pain",
            "Pangolier": -0.198
        },
        {
            "name": "Razor",
            "Pangolier": 1.401
        },
        {
            "name": "Riki",
            "Pangolier": 0.639
        },
        {
            "name": "Ringmaster",
            "Pangolier": -1.666
        },
        {
            "name": "Rubick",
            "Pangolier": -0.712
        },
        {
            "name": "Sand King",
            "Pangolier": -0.203
        },
        {
            "name": "Shadow Demon",
            "Pangolier": -0.24
        },
        {
            "name": "Shadow Fiend",
            "Pangolier": 2.145
        },
        {
            "name": "Shadow Shaman",
            "Pangolier": 0.09
        },
        {
            "name": "Silencer",
            "Pangolier": -0.061
        },
        {
            "name": "Skywrath Mage",
            "Pangolier": -0.701
        },
        {
            "name": "Slardar",
            "Pangolier": 1.013
        },
        {
            "name": "Slark",
            "Pangolier": -0.384
        },
        {
            "name": "Snapfire",
            "Pangolier": -0.377
        },
        {
            "name": "Sniper",
            "Pangolier": -0.502
        },
        {
            "name": "Spectre",
            "Pangolier": -0.776
        },
        {
            "name": "Spirit Breaker",
            "Pangolier": 1.256
        },
        {
            "name": "Storm Spirit",
            "Pangolier": 0.988
        },
        {
            "name": "Sven",
            "Pangolier": 0.059
        },
        {
            "name": "Techies",
            "Pangolier": 0.879
        },
        {
            "name": "Templar Assassin",
            "Pangolier": 1.586
        },
        {
            "name": "Terrorblade",
            "Pangolier": 1.198
        },
        {
            "name": "Tidehunter",
            "Pangolier": -2.425
        },
        {
            "name": "Timbersaw",
            "Pangolier": 0.905
        },
        {
            "name": "Tinker",
            "Pangolier": -0.371
        },
        {
            "name": "Tiny",
            "Pangolier": 0.293
        },
        {
            "name": "Treant Protector",
            "Pangolier": 2.255
        },
        {
            "name": "Troll Warlord",
            "Pangolier": 1.379
        },
        {
            "name": "Tusk",
            "Pangolier": -0.876
        },
        {
            "name": "Underlord",
            "Pangolier": -1.182
        },
        {
            "name": "Undying",
            "Pangolier": -0.854
        },
        {
            "name": "Ursa",
            "Pangolier": 0.546
        },
        {
            "name": "Vengeful Spirit",
            "Pangolier": -0.146
        },
        {
            "name": "Venomancer",
            "Pangolier": 0.076
        },
        {
            "name": "Viper",
            "Pangolier": -0.645
        },
        {
            "name": "Visage",
            "Pangolier": -2.009
        },
        {
            "name": "Void Spirit",
            "Pangolier": -1.74
        },
        {
            "name": "Warlock",
            "Pangolier": -1.798
        },
        {
            "name": "Weaver",
            "Pangolier": 1.712
        },
        {
            "name": "Windranger",
            "Pangolier": -0.936
        },
        {
            "name": "Winter Wyvern",
            "Pangolier": 0.596
        },
        {
            "name": "Witch Doctor",
            "Pangolier": -1.206
        },
        {
            "name": "Wraith King",
            "Pangolier": -1.555
        },
        {
            "name": "Zeus",
            "Pangolier": 0.017
        }
    ],
    "Phoenix": [
        {
            "name": "Abaddon",
            "Phoenix": 1.084
        },
        {
            "name": "Alchemist",
            "Phoenix": 0.837
        },
        {
            "name": "Ancient Apparition",
            "Phoenix": 0.156
        },
        {
            "name": "Anti-Mage",
            "Phoenix": -1.576
        },
        {
            "name": "Arc Warden",
            "Phoenix": 1.755
        },
        {
            "name": "Axe",
            "Phoenix": -1.403
        },
        {
            "name": "Bane",
            "Phoenix": -0.573
        },
        {
            "name": "Batrider",
            "Phoenix": 0.651
        },
        {
            "name": "Beastmaster",
            "Phoenix": -1.116
        },
        {
            "name": "Bloodseeker",
            "Phoenix": 2.14
        },
        {
            "name": "Bounty Hunter",
            "Phoenix": -0.199
        },
        {
            "name": "Brewmaster",
            "Phoenix": -0.681
        },
        {
            "name": "Bristleback",
            "Phoenix": 0.818
        },
        {
            "name": "Broodmother",
            "Phoenix": -1.617
        },
        {
            "name": "Centaur Warrunner",
            "Phoenix": 0.284
        },
        {
            "name": "Chaos Knight",
            "Phoenix": -2.88
        },
        {
            "name": "Chen",
            "Phoenix": -0.195
        },
        {
            "name": "Clinkz",
            "Phoenix": 1.509
        },
        {
            "name": "Clockwerk",
            "Phoenix": -0.713
        },
        {
            "name": "Crystal Maiden",
            "Phoenix": -0.832
        },
        {
            "name": "Dark Seer",
            "Phoenix": 0.356
        },
        {
            "name": "Dark Willow",
            "Phoenix": -0.473
        },
        {
            "name": "Dawnbreaker",
            "Phoenix": -1.25
        },
        {
            "name": "Dazzle",
            "Phoenix": -0.299
        },
        {
            "name": "Death Prophet",
            "Phoenix": 1.676
        },
        {
            "name": "Disruptor",
            "Phoenix": 0.722
        },
        {
            "name": "Doom",
            "Phoenix": 1.369
        },
        {
            "name": "Dragon Knight",
            "Phoenix": -1.997
        },
        {
            "name": "Drow Ranger",
            "Phoenix": 1.306
        },
        {
            "name": "Earth Spirit",
            "Phoenix": 0.958
        },
        {
            "name": "Earthshaker",
            "Phoenix": -0.504
        },
        {
            "name": "Elder Titan",
            "Phoenix": -0.299
        },
        {
            "name": "Ember Spirit",
            "Phoenix": -0.139
        },
        {
            "name": "Enchantress",
            "Phoenix": -0.099
        },
        {
            "name": "Enigma",
            "Phoenix": -0.973
        },
        {
            "name": "Faceless Void",
            "Phoenix": -0.871
        },
        {
            "name": "Grimstroke",
            "Phoenix": 0.319
        },
        {
            "name": "Gyrocopter",
            "Phoenix": -0.439
        },
        {
            "name": "Hoodwink",
            "Phoenix": 0.148
        },
        {
            "name": "Huskar",
            "Phoenix": 3.686
        },
        {
            "name": "Invoker",
            "Phoenix": -0.048
        },
        {
            "name": "Io",
            "Phoenix": 1.382
        },
        {
            "name": "Jakiro",
            "Phoenix": 1.404
        },
        {
            "name": "Juggernaut",
            "Phoenix": 3.531
        },
        {
            "name": "Keeper of the Light",
            "Phoenix": 1.132
        },
        {
            "name": "Kez",
            "Phoenix": 1.217
        },
        {
            "name": "Kunkka",
            "Phoenix": -0.93
        },
        {
            "name": "Legion Commander",
            "Phoenix": 1.77
        },
        {
            "name": "Leshrac",
            "Phoenix": -0.638
        },
        {
            "name": "Lich",
            "Phoenix": -0.225
        },
        {
            "name": "Lifestealer",
            "Phoenix": 2.281
        },
        {
            "name": "Lina",
            "Phoenix": 3.855
        },
        {
            "name": "Lion",
            "Phoenix": 0.744
        },
        {
            "name": "Lone Druid",
            "Phoenix": -2.41
        },
        {
            "name": "Luna",
            "Phoenix": -0.708
        },
        {
            "name": "Lycan",
            "Phoenix": -1.185
        },
        {
            "name": "Magnus",
            "Phoenix": -1.274
        },
        {
            "name": "Marci",
            "Phoenix": 2.602
        },
        {
            "name": "Mars",
            "Phoenix": -0.851
        },
        {
            "name": "Medusa",
            "Phoenix": -0.166
        },
        {
            "name": "Meepo",
            "Phoenix": 0.151
        },
        {
            "name": "Mirana",
            "Phoenix": 1.504
        },
        {
            "name": "Monkey King",
            "Phoenix": -2.321
        },
        {
            "name": "Morphling",
            "Phoenix": 2.235
        },
        {
            "name": "Muerta",
            "Phoenix": 2.595
        },
        {
            "name": "Naga Siren",
            "Phoenix": -3.68
        },
        {
            "name": "Nature's Prophet",
            "Phoenix": 0.972
        },
        {
            "name": "Necrophos",
            "Phoenix": -0.009
        },
        {
            "name": "Night Stalker",
            "Phoenix": -2.221
        },
        {
            "name": "Nyx Assassin",
            "Phoenix": 0.602
        },
        {
            "name": "Ogre Magi",
            "Phoenix": 0.324
        },
        {
            "name": "Omniknight",
            "Phoenix": -0.179
        },
        {
            "name": "Oracle",
            "Phoenix": 1.466
        },
        {
            "name": "Outworld Destroyer",
            "Phoenix": -0.715
        },
        {
            "name": "Pangolier",
            "Phoenix": -0.957
        },
        {
            "name": "Phantom Assassin",
            "Phoenix": -2.003
        },
        {
            "name": "Phantom Lancer",
            "Phoenix": -1.905
        },
        {
            "name": "Primal Beast",
            "Phoenix": 0.155
        },
        {
            "name": "Puck",
            "Phoenix": 1.46
        },
        {
            "name": "Pudge",
            "Phoenix": -0.971
        },
        {
            "name": "Pugna",
            "Phoenix": 0.574
        },
        {
            "name": "Queen of Pain",
            "Phoenix": 0.876
        },
        {
            "name": "Razor",
            "Phoenix": -1.621
        },
        {
            "name": "Riki",
            "Phoenix": 0.394
        },
        {
            "name": "Ringmaster",
            "Phoenix": -0.755
        },
        {
            "name": "Rubick",
            "Phoenix": -1.505
        },
        {
            "name": "Sand King",
            "Phoenix": -1.9
        },
        {
            "name": "Shadow Demon",
            "Phoenix": -1.3
        },
        {
            "name": "Shadow Fiend",
            "Phoenix": -0.777
        },
        {
            "name": "Shadow Shaman",
            "Phoenix": 0.207
        },
        {
            "name": "Silencer",
            "Phoenix": 0.928
        },
        {
            "name": "Skywrath Mage",
            "Phoenix": 2.207
        },
        {
            "name": "Slardar",
            "Phoenix": -1.757
        },
        {
            "name": "Slark",
            "Phoenix": 1.02
        },
        {
            "name": "Snapfire",
            "Phoenix": 0.374
        },
        {
            "name": "Sniper",
            "Phoenix": 0.337
        },
        {
            "name": "Spectre",
            "Phoenix": -0.493
        },
        {
            "name": "Spirit Breaker",
            "Phoenix": -0.353
        },
        {
            "name": "Storm Spirit",
            "Phoenix": 0.631
        },
        {
            "name": "Sven",
            "Phoenix": -2.324
        },
        {
            "name": "Techies",
            "Phoenix": 0.468
        },
        {
            "name": "Templar Assassin",
            "Phoenix": -2.061
        },
        {
            "name": "Terrorblade",
            "Phoenix": -1.153
        },
        {
            "name": "Tidehunter",
            "Phoenix": 1.073
        },
        {
            "name": "Timbersaw",
            "Phoenix": 0.604
        },
        {
            "name": "Tinker",
            "Phoenix": 1.457
        },
        {
            "name": "Tiny",
            "Phoenix": -1.602
        },
        {
            "name": "Treant Protector",
            "Phoenix": -3.175
        },
        {
            "name": "Troll Warlord",
            "Phoenix": -1.205
        },
        {
            "name": "Tusk",
            "Phoenix": -1.687
        },
        {
            "name": "Underlord",
            "Phoenix": -0.015
        },
        {
            "name": "Undying",
            "Phoenix": -0.913
        },
        {
            "name": "Ursa",
            "Phoenix": 0.059
        },
        {
            "name": "Vengeful Spirit",
            "Phoenix": -0.465
        },
        {
            "name": "Venomancer",
            "Phoenix": -1.048
        },
        {
            "name": "Viper",
            "Phoenix": 0.204
        },
        {
            "name": "Visage",
            "Phoenix": 0.062
        },
        {
            "name": "Void Spirit",
            "Phoenix": 1.486
        },
        {
            "name": "Warlock",
            "Phoenix": -1.696
        },
        {
            "name": "Weaver",
            "Phoenix": 0.713
        },
        {
            "name": "Windranger",
            "Phoenix": 0.052
        },
        {
            "name": "Winter Wyvern",
            "Phoenix": -1.801
        },
        {
            "name": "Witch Doctor",
            "Phoenix": -0.639
        },
        {
            "name": "Wraith King",
            "Phoenix": -3.103
        },
        {
            "name": "Zeus",
            "Phoenix": 0.073
        }
    ],
    "Sand King": [
        {
            "name": "Abaddon",
            "Sand King": 1.562
        },
        {
            "name": "Alchemist",
            "Sand King": 0.215
        },
        {
            "name": "Ancient Apparition",
            "Sand King": 1.599
        },
        {
            "name": "Anti-Mage",
            "Sand King": 1.175
        },
        {
            "name": "Arc Warden",
            "Sand King": -3.016
        },
        {
            "name": "Axe",
            "Sand King": -0.933
        },
        {
            "name": "Bane",
            "Sand King": -2.303
        },
        {
            "name": "Batrider",
            "Sand King": -1.732
        },
        {
            "name": "Beastmaster",
            "Sand King": -2.8
        },
        {
            "name": "Bloodseeker",
            "Sand King": 1.009
        },
        {
            "name": "Bounty Hunter",
            "Sand King": 1.09
        },
        {
            "name": "Brewmaster",
            "Sand King": -2.707
        },
        {
            "name": "Bristleback",
            "Sand King": 1.215
        },
        {
            "name": "Broodmother",
            "Sand King": -3.153
        },
        {
            "name": "Centaur Warrunner",
            "Sand King": 1.997
        },
        {
            "name": "Chaos Knight",
            "Sand King": -4.92
        },
        {
            "name": "Chen",
            "Sand King": 2.04
        },
        {
            "name": "Clinkz",
            "Sand King": -0.511
        },
        {
            "name": "Clockwerk",
            "Sand King": -0.396
        },
        {
            "name": "Crystal Maiden",
            "Sand King": -0.004
        },
        {
            "name": "Dark Seer",
            "Sand King": -0.371
        },
        {
            "name": "Dark Willow",
            "Sand King": -0.633
        },
        {
            "name": "Dawnbreaker",
            "Sand King": 0.586
        },
        {
            "name": "Dazzle",
            "Sand King": -0.497
        },
        {
            "name": "Death Prophet",
            "Sand King": -0.029
        },
        {
            "name": "Disruptor",
            "Sand King": 0.926
        },
        {
            "name": "Doom",
            "Sand King": 0.347
        },
        {
            "name": "Dragon Knight",
            "Sand King": -0.727
        },
        {
            "name": "Drow Ranger",
            "Sand King": -1.77
        },
        {
            "name": "Earth Spirit",
            "Sand King": 1.755
        },
        {
            "name": "Earthshaker",
            "Sand King": -0.724
        },
        {
            "name": "Elder Titan",
            "Sand King": 0.035
        },
        {
            "name": "Ember Spirit",
            "Sand King": 2.199
        },
        {
            "name": "Enchantress",
            "Sand King": -1.316
        },
        {
            "name": "Enigma",
            "Sand King": -2.87
        },
        {
            "name": "Faceless Void",
            "Sand King": 2.028
        },
        {
            "name": "Grimstroke",
            "Sand King": -1.046
        },
        {
            "name": "Gyrocopter",
            "Sand King": -0.192
        },
        {
            "name": "Hoodwink",
            "Sand King": -1.008
        },
        {
            "name": "Huskar",
            "Sand King": 0.785
        },
        {
            "name": "Invoker",
            "Sand King": -1.234
        },
        {
            "name": "Io",
            "Sand King": -1.671
        },
        {
            "name": "Jakiro",
            "Sand King": 1.206
        },
        {
            "name": "Juggernaut",
            "Sand King": 1.4
        },
        {
            "name": "Keeper of the Light",
            "Sand King": 0.578
        },
        {
            "name": "Kez",
            "Sand King": 1.085
        },
        {
            "name": "Kunkka",
            "Sand King": -0.35
        },
        {
            "name": "Legion Commander",
            "Sand King": -0.279
        },
        {
            "name": "Leshrac",
            "Sand King": 2.517
        },
        {
            "name": "Lich",
            "Sand King": -0.394
        },
        {
            "name": "Lifestealer",
            "Sand King": 3.307
        },
        {
            "name": "Lina",
            "Sand King": -0.319
        },
        {
            "name": "Lion",
            "Sand King": -0.24
        },
        {
            "name": "Lone Druid",
            "Sand King": -3.775
        },
        {
            "name": "Luna",
            "Sand King": -1.535
        },
        {
            "name": "Lycan",
            "Sand King": -0.947
        },
        {
            "name": "Magnus",
            "Sand King": 1.264
        },
        {
            "name": "Marci",
            "Sand King": 0.765
        },
        {
            "name": "Mars",
            "Sand King": -0.049
        },
        {
            "name": "Medusa",
            "Sand King": -2.38
        },
        {
            "name": "Meepo",
            "Sand King": -1.614
        },
        {
            "name": "Mirana",
            "Sand King": 0.354
        },
        {
            "name": "Monkey King",
            "Sand King": -0.584
        },
        {
            "name": "Morphling",
            "Sand King": 0.363
        },
        {
            "name": "Muerta",
            "Sand King": 1.166
        },
        {
            "name": "Naga Siren",
            "Sand King": -4.61
        },
        {
            "name": "Nature's Prophet",
            "Sand King": -0.369
        },
        {
            "name": "Necrophos",
            "Sand King": -0.658
        },
        {
            "name": "Night Stalker",
            "Sand King": 1.084
        },
        {
            "name": "Nyx Assassin",
            "Sand King": 1.283
        },
        {
            "name": "Ogre Magi",
            "Sand King": -0.463
        },
        {
            "name": "Omniknight",
            "Sand King": -0.716
        },
        {
            "name": "Oracle",
            "Sand King": 1.194
        },
        {
            "name": "Outworld Destroyer",
            "Sand King": -0.925
        },
        {
            "name": "Pangolier",
            "Sand King": 0.21
        },
        {
            "name": "Phantom Assassin",
            "Sand King": -0.988
        },
        {
            "name": "Phantom Lancer",
            "Sand King": -6.054
        },
        {
            "name": "Phoenix",
            "Sand King": 1.898
        },
        {
            "name": "Primal Beast",
            "Sand King": -0.463
        },
        {
            "name": "Puck",
            "Sand King": 0.945
        },
        {
            "name": "Pudge",
            "Sand King": 1.6
        },
        {
            "name": "Pugna",
            "Sand King": 0.462
        },
        {
            "name": "Queen of Pain",
            "Sand King": 2.053
        },
        {
            "name": "Razor",
            "Sand King": -1.173
        },
        {
            "name": "Riki",
            "Sand King": -1.386
        },
        {
            "name": "Ringmaster",
            "Sand King": 1.146
        },
        {
            "name": "Rubick",
            "Sand King": -0.01
        },
        {
            "name": "Shadow Demon",
            "Sand King": -2.566
        },
        {
            "name": "Shadow Fiend",
            "Sand King": -0.193
        },
        {
            "name": "Shadow Shaman",
            "Sand King": -1.473
        },
        {
            "name": "Silencer",
            "Sand King": 1.619
        },
        {
            "name": "Skywrath Mage",
            "Sand King": 0.104
        },
        {
            "name": "Slardar",
            "Sand King": 0.945
        },
        {
            "name": "Slark",
            "Sand King": -1.06
        },
        {
            "name": "Snapfire",
            "Sand King": 1.463
        },
        {
            "name": "Sniper",
            "Sand King": 0.129
        },
        {
            "name": "Spectre",
            "Sand King": 0.408
        },
        {
            "name": "Spirit Breaker",
            "Sand King": -1.14
        },
        {
            "name": "Storm Spirit",
            "Sand King": 2.15
        },
        {
            "name": "Sven",
            "Sand King": 0.124
        },
        {
            "name": "Techies",
            "Sand King": 0.241
        },
        {
            "name": "Templar Assassin",
            "Sand King": -2.14
        },
        {
            "name": "Terrorblade",
            "Sand King": -3.14
        },
        {
            "name": "Tidehunter",
            "Sand King": -0.164
        },
        {
            "name": "Timbersaw",
            "Sand King": 0.772
        },
        {
            "name": "Tinker",
            "Sand King": -0.583
        },
        {
            "name": "Tiny",
            "Sand King": -0.09
        },
        {
            "name": "Treant Protector",
            "Sand King": -1.148
        },
        {
            "name": "Troll Warlord",
            "Sand King": 0.265
        },
        {
            "name": "Tusk",
            "Sand King": -1.708
        },
        {
            "name": "Underlord",
            "Sand King": 1.168
        },
        {
            "name": "Undying",
            "Sand King": -0.518
        },
        {
            "name": "Ursa",
            "Sand King": -0.124
        },
        {
            "name": "Vengeful Spirit",
            "Sand King": -1.273
        },
        {
            "name": "Venomancer",
            "Sand King": -0.205
        },
        {
            "name": "Viper",
            "Sand King": -1.059
        },
        {
            "name": "Visage",
            "Sand King": -2.163
        },
        {
            "name": "Void Spirit",
            "Sand King": 1.484
        },
        {
            "name": "Warlock",
            "Sand King": -1.977
        },
        {
            "name": "Weaver",
            "Sand King": 0.408
        },
        {
            "name": "Windranger",
            "Sand King": -0.812
        },
        {
            "name": "Winter Wyvern",
            "Sand King": -2.166
        },
        {
            "name": "Witch Doctor",
            "Sand King": -0.723
        },
        {
            "name": "Wraith King",
            "Sand King": -1.032
        },
        {
            "name": "Zeus",
            "Sand King": 3.627
        }
    ],
    "Snapfire": [
        {
            "name": "Abaddon",
            "Snapfire": -0.047
        },
        {
            "name": "Alchemist",
            "Snapfire": -0.725
        },
        {
            "name": "Ancient Apparition",
            "Snapfire": 0.163
        },
        {
            "name": "Anti-Mage",
            "Snapfire": -0.031
        },
        {
            "name": "Arc Warden",
            "Snapfire": -1.815
        },
        {
            "name": "Axe",
            "Snapfire": 0.147
        },
        {
            "name": "Bane",
            "Snapfire": -1.828
        },
        {
            "name": "Batrider",
            "Snapfire": 0.624
        },
        {
            "name": "Beastmaster",
            "Snapfire": -1.74
        },
        {
            "name": "Bloodseeker",
            "Snapfire": 1.572
        },
        {
            "name": "Bounty Hunter",
            "Snapfire": 0.365
        },
        {
            "name": "Brewmaster",
            "Snapfire": -0.092
        },
        {
            "name": "Bristleback",
            "Snapfire": 0.901
        },
        {
            "name": "Broodmother",
            "Snapfire": 0.553
        },
        {
            "name": "Centaur Warrunner",
            "Snapfire": 1.034
        },
        {
            "name": "Chaos Knight",
            "Snapfire": -0.874
        },
        {
            "name": "Chen",
            "Snapfire": -1.661
        },
        {
            "name": "Clinkz",
            "Snapfire": 0.501
        },
        {
            "name": "Clockwerk",
            "Snapfire": -0.952
        },
        {
            "name": "Crystal Maiden",
            "Snapfire": -0.152
        },
        {
            "name": "Dark Seer",
            "Snapfire": -0.543
        },
        {
            "name": "Dark Willow",
            "Snapfire": -0.144
        },
        {
            "name": "Dawnbreaker",
            "Snapfire": -0.472
        },
        {
            "name": "Dazzle",
            "Snapfire": -0.905
        },
        {
            "name": "Death Prophet",
            "Snapfire": 0.693
        },
        {
            "name": "Disruptor",
            "Snapfire": 0.43
        },
        {
            "name": "Doom",
            "Snapfire": 0.846
        },
        {
            "name": "Dragon Knight",
            "Snapfire": 0.025
        },
        {
            "name": "Drow Ranger",
            "Snapfire": -1.384
        },
        {
            "name": "Earth Spirit",
            "Snapfire": 1.527
        },
        {
            "name": "Earthshaker",
            "Snapfire": -0.062
        },
        {
            "name": "Elder Titan",
            "Snapfire": -0.661
        },
        {
            "name": "Ember Spirit",
            "Snapfire": 0.024
        },
        {
            "name": "Enchantress",
            "Snapfire": -0.057
        },
        {
            "name": "Enigma",
            "Snapfire": -0.374
        },
        {
            "name": "Faceless Void",
            "Snapfire": 1.073
        },
        {
            "name": "Grimstroke",
            "Snapfire": -0.938
        },
        {
            "name": "Gyrocopter",
            "Snapfire": 1.136
        },
        {
            "name": "Hoodwink",
            "Snapfire": 0.438
        },
        {
            "name": "Huskar",
            "Snapfire": 0.525
        },
        {
            "name": "Invoker",
            "Snapfire": -0.841
        },
        {
            "name": "Io",
            "Snapfire": 0.439
        },
        {
            "name": "Jakiro",
            "Snapfire": -0.497
        },
        {
            "name": "Juggernaut",
            "Snapfire": 0.342
        },
        {
            "name": "Keeper of the Light",
            "Snapfire": 0.204
        },
        {
            "name": "Kez",
            "Snapfire": 1.37
        },
        {
            "name": "Kunkka",
            "Snapfire": 0.481
        },
        {
            "name": "Legion Commander",
            "Snapfire": 0.168
        },
        {
            "name": "Leshrac",
            "Snapfire": -1.268
        },
        {
            "name": "Lich",
            "Snapfire": 0.154
        },
        {
            "name": "Lifestealer",
            "Snapfire": 1.215
        },
        {
            "name": "Lina",
            "Snapfire": 0.64
        },
        {
            "name": "Lion",
            "Snapfire": 0.144
        },
        {
            "name": "Lone Druid",
            "Snapfire": -0.845
        },
        {
            "name": "Luna",
            "Snapfire": 0.384
        },
        {
            "name": "Lycan",
            "Snapfire": 1.948
        },
        {
            "name": "Magnus",
            "Snapfire": 0.635
        },
        {
            "name": "Marci",
            "Snapfire": -0.436
        },
        {
            "name": "Mars",
            "Snapfire": 1.393
        },
        {
            "name": "Medusa",
            "Snapfire": -1.94
        },
        {
            "name": "Meepo",
            "Snapfire": -2.345
        },
        {
            "name": "Mirana",
            "Snapfire": -0.251
        },
        {
            "name": "Monkey King",
            "Snapfire": -1.346
        },
        {
            "name": "Morphling",
            "Snapfire": 0.689
        },
        {
            "name": "Muerta",
            "Snapfire": -1.286
        },
        {
            "name": "Naga Siren",
            "Snapfire": -1.54
        },
        {
            "name": "Nature's Prophet",
            "Snapfire": -0.595
        },
        {
            "name": "Necrophos",
            "Snapfire": -0.505
        },
        {
            "name": "Night Stalker",
            "Snapfire": 0.219
        },
        {
            "name": "Nyx Assassin",
            "Snapfire": 0.699
        },
        {
            "name": "Ogre Magi",
            "Snapfire": 0.317
        },
        {
            "name": "Omniknight",
            "Snapfire": -0.769
        },
        {
            "name": "Oracle",
            "Snapfire": -0.264
        },
        {
            "name": "Outworld Destroyer",
            "Snapfire": 0.867
        },
        {
            "name": "Pangolier",
            "Snapfire": 0.467
        },
        {
            "name": "Phantom Assassin",
            "Snapfire": 0.961
        },
        {
            "name": "Phantom Lancer",
            "Snapfire": -0.818
        },
        {
            "name": "Phoenix",
            "Snapfire": -0.235
        },
        {
            "name": "Primal Beast",
            "Snapfire": 0.387
        },
        {
            "name": "Puck",
            "Snapfire": 0.826
        },
        {
            "name": "Pudge",
            "Snapfire": 0.075
        },
        {
            "name": "Pugna",
            "Snapfire": -0.645
        },
        {
            "name": "Queen of Pain",
            "Snapfire": 1.048
        },
        {
            "name": "Razor",
            "Snapfire": 0.259
        },
        {
            "name": "Riki",
            "Snapfire": -0.918
        },
        {
            "name": "Ringmaster",
            "Snapfire": 0.063
        },
        {
            "name": "Rubick",
            "Snapfire": -0.254
        },
        {
            "name": "Sand King",
            "Snapfire": -1.439
        },
        {
            "name": "Shadow Demon",
            "Snapfire": -0.58
        },
        {
            "name": "Shadow Fiend",
            "Snapfire": -0.14
        },
        {
            "name": "Shadow Shaman",
            "Snapfire": -0.575
        },
        {
            "name": "Silencer",
            "Snapfire": 0.583
        },
        {
            "name": "Skywrath Mage",
            "Snapfire": 0.437
        },
        {
            "name": "Slardar",
            "Snapfire": 0.13
        },
        {
            "name": "Slark",
            "Snapfire": -0.713
        },
        {
            "name": "Sniper",
            "Snapfire": -0.846
        },
        {
            "name": "Spectre",
            "Snapfire": -0.046
        },
        {
            "name": "Spirit Breaker",
            "Snapfire": 0.774
        },
        {
            "name": "Storm Spirit",
            "Snapfire": 0.616
        },
        {
            "name": "Sven",
            "Snapfire": 0.003
        },
        {
            "name": "Techies",
            "Snapfire": -0.403
        },
        {
            "name": "Templar Assassin",
            "Snapfire": 0.792
        },
        {
            "name": "Terrorblade",
            "Snapfire": -1.689
        },
        {
            "name": "Tidehunter",
            "Snapfire": -2.199
        },
        {
            "name": "Timbersaw",
            "Snapfire": 0.569
        },
        {
            "name": "Tinker",
            "Snapfire": -0.681
        },
        {
            "name": "Tiny",
            "Snapfire": -0.491
        },
        {
            "name": "Treant Protector",
            "Snapfire": -0.43
        },
        {
            "name": "Troll Warlord",
            "Snapfire": 0.078
        },
        {
            "name": "Tusk",
            "Snapfire": -0.034
        },
        {
            "name": "Underlord",
            "Snapfire": -0.521
        },
        {
            "name": "Undying",
            "Snapfire": -0.056
        },
        {
            "name": "Ursa",
            "Snapfire": 0.167
        },
        {
            "name": "Vengeful Spirit",
            "Snapfire": 0.12
        },
        {
            "name": "Venomancer",
            "Snapfire": -0.493
        },
        {
            "name": "Viper",
            "Snapfire": -0.639
        },
        {
            "name": "Visage",
            "Snapfire": 0.58
        },
        {
            "name": "Void Spirit",
            "Snapfire": 1.578
        },
        {
            "name": "Warlock",
            "Snapfire": -0.784
        },
        {
            "name": "Weaver",
            "Snapfire": 1.031
        },
        {
            "name": "Windranger",
            "Snapfire": 0.549
        },
        {
            "name": "Winter Wyvern",
            "Snapfire": -1.269
        },
        {
            "name": "Witch Doctor",
            "Snapfire": -0.149
        },
        {
            "name": "Wraith King",
            "Snapfire": -0.067
        },
        {
            "name": "Zeus",
            "Snapfire": -0.009
        }
    ],
    "Techies": [
        {
            "name": "Abaddon",
            "Techies": -1.174
        },
        {
            "name": "Alchemist",
            "Techies": 0.325
        },
        {
            "name": "Ancient Apparition",
            "Techies": -0.451
        },
        {
            "name": "Anti-Mage",
            "Techies": -0.638
        },
        {
            "name": "Arc Warden",
            "Techies": -0.652
        },
        {
            "name": "Axe",
            "Techies": -0.771
        },
        {
            "name": "Bane",
            "Techies": 0.755
        },
        {
            "name": "Batrider",
            "Techies": -0.218
        },
        {
            "name": "Beastmaster",
            "Techies": -0.073
        },
        {
            "name": "Bloodseeker",
            "Techies": -0.733
        },
        {
            "name": "Bounty Hunter",
            "Techies": -0.799
        },
        {
            "name": "Brewmaster",
            "Techies": 0.483
        },
        {
            "name": "Bristleback",
            "Techies": 1.433
        },
        {
            "name": "Broodmother",
            "Techies": -2.453
        },
        {
            "name": "Centaur Warrunner",
            "Techies": 1.196
        },
        {
            "name": "Chaos Knight",
            "Techies": -0.362
        },
        {
            "name": "Chen",
            "Techies": 0.816
        },
        {
            "name": "Clinkz",
            "Techies": -1.454
        },
        {
            "name": "Clockwerk",
            "Techies": 0.37
        },
        {
            "name": "Crystal Maiden",
            "Techies": -0.363
        },
        {
            "name": "Dark Seer",
            "Techies": -0.592
        },
        {
            "name": "Dark Willow",
            "Techies": -1.513
        },
        {
            "name": "Dawnbreaker",
            "Techies": 0.433
        },
        {
            "name": "Dazzle",
            "Techies": 0.027
        },
        {
            "name": "Death Prophet",
            "Techies": -0.359
        },
        {
            "name": "Disruptor",
            "Techies": -0.133
        },
        {
            "name": "Doom",
            "Techies": -0.232
        },
        {
            "name": "Dragon Knight",
            "Techies": 0.151
        },
        {
            "name": "Drow Ranger",
            "Techies": -0.377
        },
        {
            "name": "Earth Spirit",
            "Techies": -0.357
        },
        {
            "name": "Earthshaker",
            "Techies": -0.596
        },
        {
            "name": "Elder Titan",
            "Techies": -2.198
        },
        {
            "name": "Ember Spirit",
            "Techies": -0.473
        },
        {
            "name": "Enchantress",
            "Techies": -0.514
        },
        {
            "name": "Enigma",
            "Techies": 0.025
        },
        {
            "name": "Faceless Void",
            "Techies": 0.398
        },
        {
            "name": "Grimstroke",
            "Techies": 0.469
        },
        {
            "name": "Gyrocopter",
            "Techies": -0.51
        },
        {
            "name": "Hoodwink",
            "Techies": 0.147
        },
        {
            "name": "Huskar",
            "Techies": -0.187
        },
        {
            "name": "Invoker",
            "Techies": 0.435
        },
        {
            "name": "Io",
            "Techies": -1.465
        },
        {
            "name": "Jakiro",
            "Techies": 0.526
        },
        {
            "name": "Juggernaut",
            "Techies": 1.444
        },
        {
            "name": "Keeper of the Light",
            "Techies": 0.611
        },
        {
            "name": "Kez",
            "Techies": -0.429
        },
        {
            "name": "Kunkka",
            "Techies": -0.551
        },
        {
            "name": "Legion Commander",
            "Techies": -1.746
        },
        {
            "name": "Leshrac",
            "Techies": 0.997
        },
        {
            "name": "Lich",
            "Techies": 0.33
        },
        {
            "name": "Lifestealer",
            "Techies": 2.863
        },
        {
            "name": "Lina",
            "Techies": 0.042
        },
        {
            "name": "Lion",
            "Techies": 0.622
        },
        {
            "name": "Lone Druid",
            "Techies": -2.81
        },
        {
            "name": "Luna",
            "Techies": -0.765
        },
        {
            "name": "Lycan",
            "Techies": 0.161
        },
        {
            "name": "Magnus",
            "Techies": 0.639
        },
        {
            "name": "Marci",
            "Techies": -1.631
        },
        {
            "name": "Mars",
            "Techies": 1.119
        },
        {
            "name": "Medusa",
            "Techies": 1.286
        },
        {
            "name": "Meepo",
            "Techies": -2.87
        },
        {
            "name": "Mirana",
            "Techies": -0.71
        },
        {
            "name": "Monkey King",
            "Techies": -2.85
        },
        {
            "name": "Morphling",
            "Techies": -1.555
        },
        {
            "name": "Muerta",
            "Techies": -0.43
        },
        {
            "name": "Naga Siren",
            "Techies": -1.991
        },
        {
            "name": "Nature's Prophet",
            "Techies": -0.298
        },
        {
            "name": "Necrophos",
            "Techies": 0.654
        },
        {
            "name": "Night Stalker",
            "Techies": -0.928
        },
        {
            "name": "Nyx Assassin",
            "Techies": -0.074
        },
        {
            "name": "Ogre Magi",
            "Techies": -0.368
        },
        {
            "name": "Omniknight",
            "Techies": -0.49
        },
        {
            "name": "Oracle",
            "Techies": 0.794
        },
        {
            "name": "Outworld Destroyer",
            "Techies": 0.336
        },
        {
            "name": "Pangolier",
            "Techies": -0.84
        },
        {
            "name": "Phantom Assassin",
            "Techies": -0.923
        },
        {
            "name": "Phantom Lancer",
            "Techies": -2.123
        },
        {
            "name": "Phoenix",
            "Techies": -0.531
        },
        {
            "name": "Primal Beast",
            "Techies": 0.477
        },
        {
            "name": "Puck",
            "Techies": 0.259
        },
        {
            "name": "Pudge",
            "Techies": 0.718
        },
        {
            "name": "Pugna",
            "Techies": 0.053
        },
        {
            "name": "Queen of Pain",
            "Techies": 0.475
        },
        {
            "name": "Razor",
            "Techies": -2.286
        },
        {
            "name": "Riki",
            "Techies": -1.363
        },
        {
            "name": "Ringmaster",
            "Techies": -0.645
        },
        {
            "name": "Rubick",
            "Techies": 0.45
        },
        {
            "name": "Sand King",
            "Techies": -0.329
        },
        {
            "name": "Shadow Demon",
            "Techies": 0.446
        },
        {
            "name": "Shadow Fiend",
            "Techies": -0.717
        },
        {
            "name": "Shadow Shaman",
            "Techies": -0.173
        },
        {
            "name": "Silencer",
            "Techies": -0.371
        },
        {
            "name": "Skywrath Mage",
            "Techies": 0.107
        },
        {
            "name": "Slardar",
            "Techies": -1.256
        },
        {
            "name": "Slark",
            "Techies": -3.05
        },
        {
            "name": "Snapfire",
            "Techies": 0.327
        },
        {
            "name": "Sniper",
            "Techies": -0.391
        },
        {
            "name": "Spectre",
            "Techies": 1.503
        },
        {
            "name": "Spirit Breaker",
            "Techies": -0.697
        },
        {
            "name": "Storm Spirit",
            "Techies": 0.506
        },
        {
            "name": "Sven",
            "Techies": -0.755
        },
        {
            "name": "Templar Assassin",
            "Techies": 1.839
        },
        {
            "name": "Terrorblade",
            "Techies": -1.819
        },
        {
            "name": "Tidehunter",
            "Techies": 1.089
        },
        {
            "name": "Timbersaw",
            "Techies": 0.357
        },
        {
            "name": "Tinker",
            "Techies": 1.138
        },
        {
            "name": "Tiny",
            "Techies": 0.527
        },
        {
            "name": "Treant Protector",
            "Techies": -0.028
        },
        {
            "name": "Troll Warlord",
            "Techies": -1.74
        },
        {
            "name": "Tusk",
            "Techies": -0.446
        },
        {
            "name": "Underlord",
            "Techies": 0.659
        },
        {
            "name": "Undying",
            "Techies": 0.165
        },
        {
            "name": "Ursa",
            "Techies": -0.664
        },
        {
            "name": "Vengeful Spirit",
            "Techies": 0.732
        },
        {
            "name": "Venomancer",
            "Techies": -1.015
        },
        {
            "name": "Viper",
            "Techies": -0.545
        },
        {
            "name": "Visage",
            "Techies": 0.196
        },
        {
            "name": "Void Spirit",
            "Techies": 0.828
        },
        {
            "name": "Warlock",
            "Techies": 0.559
        },
        {
            "name": "Weaver",
            "Techies": -1.648
        },
        {
            "name": "Windranger",
            "Techies": -0.998
        },
        {
            "name": "Winter Wyvern",
            "Techies": -0.581
        },
        {
            "name": "Witch Doctor",
            "Techies": 0.384
        },
        {
            "name": "Wraith King",
            "Techies": 1.433
        },
        {
            "name": "Zeus",
            "Techies": 0.451
        }
    ],
    "Timbersaw": [
        {
            "name": "Abaddon",
            "Timbersaw": 0.131
        },
        {
            "name": "Alchemist",
            "Timbersaw": -1.04
        },
        {
            "name": "Ancient Apparition",
            "Timbersaw": 1.111
        },
        {
            "name": "Anti-Mage",
            "Timbersaw": -0.797
        },
        {
            "name": "Arc Warden",
            "Timbersaw": 0.094
        },
        {
            "name": "Axe",
            "Timbersaw": -2.818
        },
        {
            "name": "Bane",
            "Timbersaw": 0.999
        },
        {
            "name": "Batrider",
            "Timbersaw": 1.519
        },
        {
            "name": "Beastmaster",
            "Timbersaw": 0.307
        },
        {
            "name": "Bloodseeker",
            "Timbersaw": 1.925
        },
        {
            "name": "Bounty Hunter",
            "Timbersaw": 0.156
        },
        {
            "name": "Brewmaster",
            "Timbersaw": -0.313
        },
        {
            "name": "Bristleback",
            "Timbersaw": 0.632
        },
        {
            "name": "Broodmother",
            "Timbersaw": -0.786
        },
        {
            "name": "Centaur Warrunner",
            "Timbersaw": -0.887
        },
        {
            "name": "Chaos Knight",
            "Timbersaw": -2.807
        },
        {
            "name": "Chen",
            "Timbersaw": -1.702
        },
        {
            "name": "Clinkz",
            "Timbersaw": 0.602
        },
        {
            "name": "Clockwerk",
            "Timbersaw": -0.489
        },
        {
            "name": "Crystal Maiden",
            "Timbersaw": 0.627
        },
        {
            "name": "Dark Seer",
            "Timbersaw": -1.621
        },
        {
            "name": "Dark Willow",
            "Timbersaw": 0.029
        },
        {
            "name": "Dawnbreaker",
            "Timbersaw": -2.019
        },
        {
            "name": "Dazzle",
            "Timbersaw": -0.936
        },
        {
            "name": "Death Prophet",
            "Timbersaw": 2.527
        },
        {
            "name": "Disruptor",
            "Timbersaw": 0.365
        },
        {
            "name": "Doom",
            "Timbersaw": 2.1
        },
        {
            "name": "Dragon Knight",
            "Timbersaw": -0.358
        },
        {
            "name": "Drow Ranger",
            "Timbersaw": -0.43
        },
        {
            "name": "Earth Spirit",
            "Timbersaw": 0.586
        },
        {
            "name": "Earthshaker",
            "Timbersaw": 0.026
        },
        {
            "name": "Elder Titan",
            "Timbersaw": -0.014
        },
        {
            "name": "Ember Spirit",
            "Timbersaw": 0.859
        },
        {
            "name": "Enchantress",
            "Timbersaw": 0.743
        },
        {
            "name": "Enigma",
            "Timbersaw": -0.348
        },
        {
            "name": "Faceless Void",
            "Timbersaw": 2.486
        },
        {
            "name": "Grimstroke",
            "Timbersaw": 1.881
        },
        {
            "name": "Gyrocopter",
            "Timbersaw": 1.156
        },
        {
            "name": "Hoodwink",
            "Timbersaw": -2.085
        },
        {
            "name": "Huskar",
            "Timbersaw": -2.151
        },
        {
            "name": "Invoker",
            "Timbersaw": 0.243
        },
        {
            "name": "Io",
            "Timbersaw": -0.248
        },
        {
            "name": "Jakiro",
            "Timbersaw": 0.723
        },
        {
            "name": "Juggernaut",
            "Timbersaw": 0.09
        },
        {
            "name": "Keeper of the Light",
            "Timbersaw": -0.149
        },
        {
            "name": "Kez",
            "Timbersaw": 0.64
        },
        {
            "name": "Kunkka",
            "Timbersaw": -1.714
        },
        {
            "name": "Legion Commander",
            "Timbersaw": -0.611
        },
        {
            "name": "Leshrac",
            "Timbersaw": 1.493
        },
        {
            "name": "Lich",
            "Timbersaw": -1.217
        },
        {
            "name": "Lifestealer",
            "Timbersaw": 2.202
        },
        {
            "name": "Lina",
            "Timbersaw": 0.539
        },
        {
            "name": "Lion",
            "Timbersaw": 0.784
        },
        {
            "name": "Lone Druid",
            "Timbersaw": -1.824
        },
        {
            "name": "Luna",
            "Timbersaw": -0.216
        },
        {
            "name": "Lycan",
            "Timbersaw": -0.61
        },
        {
            "name": "Magnus",
            "Timbersaw": 0.888
        },
        {
            "name": "Marci",
            "Timbersaw": 1.044
        },
        {
            "name": "Mars",
            "Timbersaw": 0.918
        },
        {
            "name": "Medusa",
            "Timbersaw": 2.699
        },
        {
            "name": "Meepo",
            "Timbersaw": -5.057
        },
        {
            "name": "Mirana",
            "Timbersaw": 0.734
        },
        {
            "name": "Monkey King",
            "Timbersaw": -1.593
        },
        {
            "name": "Morphling",
            "Timbersaw": -2.501
        },
        {
            "name": "Muerta",
            "Timbersaw": 1.157
        },
        {
            "name": "Naga Siren",
            "Timbersaw": -3.831
        },
        {
            "name": "Nature's Prophet",
            "Timbersaw": -0.417
        },
        {
            "name": "Necrophos",
            "Timbersaw": 0.786
        },
        {
            "name": "Night Stalker",
            "Timbersaw": 1.601
        },
        {
            "name": "Nyx Assassin",
            "Timbersaw": 0.443
        },
        {
            "name": "Ogre Magi",
            "Timbersaw": 0.785
        },
        {
            "name": "Omniknight",
            "Timbersaw": 0.104
        },
        {
            "name": "Oracle",
            "Timbersaw": -0.358
        },
        {
            "name": "Outworld Destroyer",
            "Timbersaw": 3.3
        },
        {
            "name": "Pangolier",
            "Timbersaw": -0.86
        },
        {
            "name": "Phantom Assassin",
            "Timbersaw": -2.208
        },
        {
            "name": "Phantom Lancer",
            "Timbersaw": -3.422
        },
        {
            "name": "Phoenix",
            "Timbersaw": -0.611
        },
        {
            "name": "Primal Beast",
            "Timbersaw": 0.765
        },
        {
            "name": "Puck",
            "Timbersaw": 1.711
        },
        {
            "name": "Pudge",
            "Timbersaw": -1.528
        },
        {
            "name": "Pugna",
            "Timbersaw": 3.908
        },
        {
            "name": "Queen of Pain",
            "Timbersaw": 1.925
        },
        {
            "name": "Razor",
            "Timbersaw": -0.892
        },
        {
            "name": "Riki",
            "Timbersaw": 0.174
        },
        {
            "name": "Ringmaster",
            "Timbersaw": -1.076
        },
        {
            "name": "Rubick",
            "Timbersaw": -0.39
        },
        {
            "name": "Sand King",
            "Timbersaw": -0.828
        },
        {
            "name": "Shadow Demon",
            "Timbersaw": -2.753
        },
        {
            "name": "Shadow Fiend",
            "Timbersaw": 0.279
        },
        {
            "name": "Shadow Shaman",
            "Timbersaw": 0.162
        },
        {
            "name": "Silencer",
            "Timbersaw": 4.003
        },
        {
            "name": "Skywrath Mage",
            "Timbersaw": 2.275
        },
        {
            "name": "Slardar",
            "Timbersaw": 1.002
        },
        {
            "name": "Slark",
            "Timbersaw": -2.136
        },
        {
            "name": "Snapfire",
            "Timbersaw": -0.617
        },
        {
            "name": "Sniper",
            "Timbersaw": -1.307
        },
        {
            "name": "Spectre",
            "Timbersaw": 1.036
        },
        {
            "name": "Spirit Breaker",
            "Timbersaw": -0.921
        },
        {
            "name": "Storm Spirit",
            "Timbersaw": 1.71
        },
        {
            "name": "Sven",
            "Timbersaw": 0.764
        },
        {
            "name": "Techies",
            "Timbersaw": -0.355
        },
        {
            "name": "Templar Assassin",
            "Timbersaw": 1.641
        },
        {
            "name": "Terrorblade",
            "Timbersaw": -1.753
        },
        {
            "name": "Tidehunter",
            "Timbersaw": -1.627
        },
        {
            "name": "Tinker",
            "Timbersaw": 1.011
        },
        {
            "name": "Tiny",
            "Timbersaw": -0.701
        },
        {
            "name": "Treant Protector",
            "Timbersaw": -3.253
        },
        {
            "name": "Troll Warlord",
            "Timbersaw": 1.599
        },
        {
            "name": "Tusk",
            "Timbersaw": -2.376
        },
        {
            "name": "Underlord",
            "Timbersaw": -0.838
        },
        {
            "name": "Undying",
            "Timbersaw": -1.149
        },
        {
            "name": "Ursa",
            "Timbersaw": 1.59
        },
        {
            "name": "Vengeful Spirit",
            "Timbersaw": 0.083
        },
        {
            "name": "Venomancer",
            "Timbersaw": -0.464
        },
        {
            "name": "Viper",
            "Timbersaw": -0.265
        },
        {
            "name": "Visage",
            "Timbersaw": 0.104
        },
        {
            "name": "Void Spirit",
            "Timbersaw": -0.258
        },
        {
            "name": "Warlock",
            "Timbersaw": 0.228
        },
        {
            "name": "Weaver",
            "Timbersaw": 1.635
        },
        {
            "name": "Windranger",
            "Timbersaw": 0.121
        },
        {
            "name": "Winter Wyvern",
            "Timbersaw": -3.173
        },
        {
            "name": "Witch Doctor",
            "Timbersaw": 1.564
        },
        {
            "name": "Wraith King",
            "Timbersaw": -1.876
        },
        {
            "name": "Zeus",
            "Timbersaw": 0.468
        }
    ],
    "Vengeful Spirit": [
        {
            "name": "Abaddon",
            "Vengeful Spirit": 0.459
        },
        {
            "name": "Alchemist",
            "Vengeful Spirit": 0.308
        },
        {
            "name": "Ancient Apparition",
            "Vengeful Spirit": -0.838
        },
        {
            "name": "Anti-Mage",
            "Vengeful Spirit": 1.533
        },
        {
            "name": "Arc Warden",
            "Vengeful Spirit": 0.781
        },
        {
            "name": "Axe",
            "Vengeful Spirit": 0.308
        },
        {
            "name": "Bane",
            "Vengeful Spirit": -0.548
        },
        {
            "name": "Batrider",
            "Vengeful Spirit": -0.18
        },
        {
            "name": "Beastmaster",
            "Vengeful Spirit": 1.394
        },
        {
            "name": "Bloodseeker",
            "Vengeful Spirit": 0.485
        },
        {
            "name": "Bounty Hunter",
            "Vengeful Spirit": 0.079
        },
        {
            "name": "Brewmaster",
            "Vengeful Spirit": 1.735
        },
        {
            "name": "Bristleback",
            "Vengeful Spirit": 0.178
        },
        {
            "name": "Broodmother",
            "Vengeful Spirit": -0.505
        },
        {
            "name": "Centaur Warrunner",
            "Vengeful Spirit": 0.608
        },
        {
            "name": "Chaos Knight",
            "Vengeful Spirit": 2.335
        },
        {
            "name": "Chen",
            "Vengeful Spirit": 0.884
        },
        {
            "name": "Clinkz",
            "Vengeful Spirit": -1.013
        },
        {
            "name": "Clockwerk",
            "Vengeful Spirit": 0.757
        },
        {
            "name": "Crystal Maiden",
            "Vengeful Spirit": 0.841
        },
        {
            "name": "Dark Seer",
            "Vengeful Spirit": 0.78
        },
        {
            "name": "Dark Willow",
            "Vengeful Spirit": 1.416
        },
        {
            "name": "Dawnbreaker",
            "Vengeful Spirit": 0.495
        },
        {
            "name": "Dazzle",
            "Vengeful Spirit": -0.677
        },
        {
            "name": "Death Prophet",
            "Vengeful Spirit": -0.551
        },
        {
            "name": "Disruptor",
            "Vengeful Spirit": -0.554
        },
        {
            "name": "Doom",
            "Vengeful Spirit": -0.303
        },
        {
            "name": "Dragon Knight",
            "Vengeful Spirit": -0.301
        },
        {
            "name": "Drow Ranger",
            "Vengeful Spirit": 0.151
        },
        {
            "name": "Earth Spirit",
            "Vengeful Spirit": 0.358
        },
        {
            "name": "Earthshaker",
            "Vengeful Spirit": -0.096
        },
        {
            "name": "Elder Titan",
            "Vengeful Spirit": -0.089
        },
        {
            "name": "Ember Spirit",
            "Vengeful Spirit": 0.617
        },
        {
            "name": "Enchantress",
            "Vengeful Spirit": 0.798
        },
        {
            "name": "Enigma",
            "Vengeful Spirit": -0.243
        },
        {
            "name": "Faceless Void",
            "Vengeful Spirit": -0.435
        },
        {
            "name": "Grimstroke",
            "Vengeful Spirit": -0.697
        },
        {
            "name": "Gyrocopter",
            "Vengeful Spirit": 0.876
        },
        {
            "name": "Hoodwink",
            "Vengeful Spirit": 0.045
        },
        {
            "name": "Huskar",
            "Vengeful Spirit": -0.752
        },
        {
            "name": "Invoker",
            "Vengeful Spirit": -0.268
        },
        {
            "name": "Io",
            "Vengeful Spirit": -0.342
        },
        {
            "name": "Jakiro",
            "Vengeful Spirit": 0.543
        },
        {
            "name": "Juggernaut",
            "Vengeful Spirit": 0.197
        },
        {
            "name": "Keeper of the Light",
            "Vengeful Spirit": -0.047
        },
        {
            "name": "Kez",
            "Vengeful Spirit": -0.404
        },
        {
            "name": "Kunkka",
            "Vengeful Spirit": 0.588
        },
        {
            "name": "Legion Commander",
            "Vengeful Spirit": -1.979
        },
        {
            "name": "Leshrac",
            "Vengeful Spirit": 0.378
        },
        {
            "name": "Lich",
            "Vengeful Spirit": 1.371
        },
        {
            "name": "Lifestealer",
            "Vengeful Spirit": -0.042
        },
        {
            "name": "Lina",
            "Vengeful Spirit": 0.28
        },
        {
            "name": "Lion",
            "Vengeful Spirit": -0.962
        },
        {
            "name": "Lone Druid",
            "Vengeful Spirit": 1.296
        },
        {
            "name": "Luna",
            "Vengeful Spirit": 0.983
        },
        {
            "name": "Lycan",
            "Vengeful Spirit": 0.261
        },
        {
            "name": "Magnus",
            "Vengeful Spirit": 0.055
        },
        {
            "name": "Marci",
            "Vengeful Spirit": 0.47
        },
        {
            "name": "Mars",
            "Vengeful Spirit": 0.189
        },
        {
            "name": "Medusa",
            "Vengeful Spirit": 0.433
        },
        {
            "name": "Meepo",
            "Vengeful Spirit": 0.65
        },
        {
            "name": "Mirana",
            "Vengeful Spirit": 0.125
        },
        {
            "name": "Monkey King",
            "Vengeful Spirit": 0.324
        },
        {
            "name": "Morphling",
            "Vengeful Spirit": 0.349
        },
        {
            "name": "Muerta",
            "Vengeful Spirit": 1.547
        },
        {
            "name": "Naga Siren",
            "Vengeful Spirit": 1.097
        },
        {
            "name": "Nature's Prophet",
            "Vengeful Spirit": -1.107
        },
        {
            "name": "Necrophos",
            "Vengeful Spirit": 0.997
        },
        {
            "name": "Night Stalker",
            "Vengeful Spirit": -1.085
        },
        {
            "name": "Nyx Assassin",
            "Vengeful Spirit": 0.048
        },
        {
            "name": "Ogre Magi",
            "Vengeful Spirit": -0.096
        },
        {
            "name": "Omniknight",
            "Vengeful Spirit": 0.779
        },
        {
            "name": "Oracle",
            "Vengeful Spirit": -0.924
        },
        {
            "name": "Outworld Destroyer",
            "Vengeful Spirit": 0.882
        },
        {
            "name": "Pangolier",
            "Vengeful Spirit": 0.364
        },
        {
            "name": "Phantom Assassin",
            "Vengeful Spirit": -0.474
        },
        {
            "name": "Phantom Lancer",
            "Vengeful Spirit": 4.038
        },
        {
            "name": "Phoenix",
            "Vengeful Spirit": 0.455
        },
        {
            "name": "Primal Beast",
            "Vengeful Spirit": 0.604
        },
        {
            "name": "Puck",
            "Vengeful Spirit": 0.902
        },
        {
            "name": "Pudge",
            "Vengeful Spirit": -0.423
        },
        {
            "name": "Pugna",
            "Vengeful Spirit": -0.302
        },
        {
            "name": "Queen of Pain",
            "Vengeful Spirit": 0.082
        },
        {
            "name": "Razor",
            "Vengeful Spirit": -0.373
        },
        {
            "name": "Riki",
            "Vengeful Spirit": -0.472
        },
        {
            "name": "Ringmaster",
            "Vengeful Spirit": 0.408
        },
        {
            "name": "Rubick",
            "Vengeful Spirit": -0.046
        },
        {
            "name": "Sand King",
            "Vengeful Spirit": 1.341
        },
        {
            "name": "Shadow Demon",
            "Vengeful Spirit": -0.45
        },
        {
            "name": "Shadow Fiend",
            "Vengeful Spirit": -0.69
        },
        {
            "name": "Shadow Shaman",
            "Vengeful Spirit": -0.552
        },
        {
            "name": "Silencer",
            "Vengeful Spirit": 0.285
        },
        {
            "name": "Skywrath Mage",
            "Vengeful Spirit": -0.808
        },
        {
            "name": "Slardar",
            "Vengeful Spirit": -1.199
        },
        {
            "name": "Slark",
            "Vengeful Spirit": 0.122
        },
        {
            "name": "Snapfire",
            "Vengeful Spirit": 0.049
        },
        {
            "name": "Sniper",
            "Vengeful Spirit": -0.213
        },
        {
            "name": "Spectre",
            "Vengeful Spirit": 0.774
        },
        {
            "name": "Spirit Breaker",
            "Vengeful Spirit": -0.206
        },
        {
            "name": "Storm Spirit",
            "Vengeful Spirit": 0.621
        },
        {
            "name": "Sven",
            "Vengeful Spirit": -0.277
        },
        {
            "name": "Techies",
            "Vengeful Spirit": -0.794
        },
        {
            "name": "Templar Assassin",
            "Vengeful Spirit": -0.089
        },
        {
            "name": "Terrorblade",
            "Vengeful Spirit": 2.614
        },
        {
            "name": "Tidehunter",
            "Vengeful Spirit": 1.263
        },
        {
            "name": "Timbersaw",
            "Vengeful Spirit": -0.029
        },
        {
            "name": "Tinker",
            "Vengeful Spirit": -0.745
        },
        {
            "name": "Tiny",
            "Vengeful Spirit": -0.432
        },
        {
            "name": "Treant Protector",
            "Vengeful Spirit": 0.588
        },
        {
            "name": "Troll Warlord",
            "Vengeful Spirit": -0.053
        },
        {
            "name": "Tusk",
            "Vengeful Spirit": 0.296
        },
        {
            "name": "Underlord",
            "Vengeful Spirit": 1.158
        },
        {
            "name": "Undying",
            "Vengeful Spirit": 0.452
        },
        {
            "name": "Ursa",
            "Vengeful Spirit": -0.339
        },
        {
            "name": "Venomancer",
            "Vengeful Spirit": 0.271
        },
        {
            "name": "Viper",
            "Vengeful Spirit": 0.138
        },
        {
            "name": "Visage",
            "Vengeful Spirit": 0.042
        },
        {
            "name": "Void Spirit",
            "Vengeful Spirit": 0.324
        },
        {
            "name": "Warlock",
            "Vengeful Spirit": 1.351
        },
        {
            "name": "Weaver",
            "Vengeful Spirit": -0.331
        },
        {
            "name": "Windranger",
            "Vengeful Spirit": -0.519
        },
        {
            "name": "Winter Wyvern",
            "Vengeful Spirit": 1.229
        },
        {
            "name": "Witch Doctor",
            "Vengeful Spirit": 0.348
        },
        {
            "name": "Wraith King",
            "Vengeful Spirit": 0.188
        },
        {
            "name": "Zeus",
            "Vengeful Spirit": -0.49
        }
    ],
    "Venomancer": [
        {
            "name": "Abaddon",
            "Venomancer": 0.242
        },
        {
            "name": "Alchemist",
            "Venomancer": -0.587
        },
        {
            "name": "Ancient Apparition",
            "Venomancer": 0.253
        },
        {
            "name": "Anti-Mage",
            "Venomancer": 0.821
        },
        {
            "name": "Arc Warden",
            "Venomancer": 1.979
        },
        {
            "name": "Axe",
            "Venomancer": -2.361
        },
        {
            "name": "Bane",
            "Venomancer": 1.069
        },
        {
            "name": "Batrider",
            "Venomancer": 0.346
        },
        {
            "name": "Beastmaster",
            "Venomancer": -0.647
        },
        {
            "name": "Bloodseeker",
            "Venomancer": -1.064
        },
        {
            "name": "Bounty Hunter",
            "Venomancer": -0.645
        },
        {
            "name": "Brewmaster",
            "Venomancer": 1.019
        },
        {
            "name": "Bristleback",
            "Venomancer": -1.436
        },
        {
            "name": "Broodmother",
            "Venomancer": 0.7
        },
        {
            "name": "Centaur Warrunner",
            "Venomancer": -0.374
        },
        {
            "name": "Chaos Knight",
            "Venomancer": -1.374
        },
        {
            "name": "Chen",
            "Venomancer": 0.657
        },
        {
            "name": "Clinkz",
            "Venomancer": 0.819
        },
        {
            "name": "Clockwerk",
            "Venomancer": -1.714
        },
        {
            "name": "Crystal Maiden",
            "Venomancer": 0.647
        },
        {
            "name": "Dark Seer",
            "Venomancer": 1.626
        },
        {
            "name": "Dark Willow",
            "Venomancer": -0.155
        },
        {
            "name": "Dawnbreaker",
            "Venomancer": -0.579
        },
        {
            "name": "Dazzle",
            "Venomancer": -0.593
        },
        {
            "name": "Death Prophet",
            "Venomancer": -0.795
        },
        {
            "name": "Disruptor",
            "Venomancer": -0.882
        },
        {
            "name": "Doom",
            "Venomancer": -0.821
        },
        {
            "name": "Dragon Knight",
            "Venomancer": -2.001
        },
        {
            "name": "Drow Ranger",
            "Venomancer": -0.408
        },
        {
            "name": "Earth Spirit",
            "Venomancer": 0.982
        },
        {
            "name": "Earthshaker",
            "Venomancer": -0.949
        },
        {
            "name": "Elder Titan",
            "Venomancer": 0.206
        },
        {
            "name": "Ember Spirit",
            "Venomancer": -0.662
        },
        {
            "name": "Enchantress",
            "Venomancer": 0.763
        },
        {
            "name": "Enigma",
            "Venomancer": 1.466
        },
        {
            "name": "Faceless Void",
            "Venomancer": 0.405
        },
        {
            "name": "Grimstroke",
            "Venomancer": 0.134
        },
        {
            "name": "Gyrocopter",
            "Venomancer": -0.981
        },
        {
            "name": "Hoodwink",
            "Venomancer": -0.029
        },
        {
            "name": "Huskar",
            "Venomancer": 0.383
        },
        {
            "name": "Invoker",
            "Venomancer": -0.009
        },
        {
            "name": "Io",
            "Venomancer": -0.751
        },
        {
            "name": "Jakiro",
            "Venomancer": -0.698
        },
        {
            "name": "Juggernaut",
            "Venomancer": 1.142
        },
        {
            "name": "Keeper of the Light",
            "Venomancer": 0.736
        },
        {
            "name": "Kez",
            "Venomancer": 0.973
        },
        {
            "name": "Kunkka",
            "Venomancer": -1.406
        },
        {
            "name": "Legion Commander",
            "Venomancer": 1.528
        },
        {
            "name": "Leshrac",
            "Venomancer": -1.355
        },
        {
            "name": "Lich",
            "Venomancer": -0.379
        },
        {
            "name": "Lifestealer",
            "Venomancer": -0.444
        },
        {
            "name": "Lina",
            "Venomancer": 0.386
        },
        {
            "name": "Lion",
            "Venomancer": -0.639
        },
        {
            "name": "Lone Druid",
            "Venomancer": -2.541
        },
        {
            "name": "Luna",
            "Venomancer": -0.303
        },
        {
            "name": "Lycan",
            "Venomancer": 1.223
        },
        {
            "name": "Magnus",
            "Venomancer": 1.038
        },
        {
            "name": "Marci",
            "Venomancer": 0.287
        },
        {
            "name": "Mars",
            "Venomancer": -0.983
        },
        {
            "name": "Medusa",
            "Venomancer": 0.833
        },
        {
            "name": "Meepo",
            "Venomancer": -0.35
        },
        {
            "name": "Mirana",
            "Venomancer": 0.532
        },
        {
            "name": "Monkey King",
            "Venomancer": -1.32
        },
        {
            "name": "Morphling",
            "Venomancer": 1.708
        },
        {
            "name": "Muerta",
            "Venomancer": 1.31
        },
        {
            "name": "Naga Siren",
            "Venomancer": 0.886
        },
        {
            "name": "Nature's Prophet",
            "Venomancer": 1.642
        },
        {
            "name": "Necrophos",
            "Venomancer": -0.552
        },
        {
            "name": "Night Stalker",
            "Venomancer": -2.006
        },
        {
            "name": "Nyx Assassin",
            "Venomancer": -0.139
        },
        {
            "name": "Ogre Magi",
            "Venomancer": -0.762
        },
        {
            "name": "Omniknight",
            "Venomancer": -0.114
        },
        {
            "name": "Oracle",
            "Venomancer": 0.311
        },
        {
            "name": "Outworld Destroyer",
            "Venomancer": -0.26
        },
        {
            "name": "Pangolier",
            "Venomancer": 0.187
        },
        {
            "name": "Phantom Assassin",
            "Venomancer": 1.028
        },
        {
            "name": "Phantom Lancer",
            "Venomancer": 1.838
        },
        {
            "name": "Phoenix",
            "Venomancer": 0.982
        },
        {
            "name": "Primal Beast",
            "Venomancer": -0.004
        },
        {
            "name": "Puck",
            "Venomancer": 0.195
        },
        {
            "name": "Pudge",
            "Venomancer": 0.801
        },
        {
            "name": "Pugna",
            "Venomancer": 0.663
        },
        {
            "name": "Queen of Pain",
            "Venomancer": 0.954
        },
        {
            "name": "Razor",
            "Venomancer": -0.019
        },
        {
            "name": "Riki",
            "Venomancer": -0.587
        },
        {
            "name": "Ringmaster",
            "Venomancer": -0.697
        },
        {
            "name": "Rubick",
            "Venomancer": 0.1
        },
        {
            "name": "Sand King",
            "Venomancer": 0.245
        },
        {
            "name": "Shadow Demon",
            "Venomancer": 0.74
        },
        {
            "name": "Shadow Fiend",
            "Venomancer": -0.384
        },
        {
            "name": "Shadow Shaman",
            "Venomancer": 0.024
        },
        {
            "name": "Silencer",
            "Venomancer": -0.525
        },
        {
            "name": "Skywrath Mage",
            "Venomancer": 0.371
        },
        {
            "name": "Slardar",
            "Venomancer": -1.339
        },
        {
            "name": "Slark",
            "Venomancer": 1.715
        },
        {
            "name": "Snapfire",
            "Venomancer": 0.585
        },
        {
            "name": "Sniper",
            "Venomancer": 1.602
        },
        {
            "name": "Spectre",
            "Venomancer": -0.957
        },
        {
            "name": "Spirit Breaker",
            "Venomancer": -1.277
        },
        {
            "name": "Storm Spirit",
            "Venomancer": 2.407
        },
        {
            "name": "Sven",
            "Venomancer": -0.628
        },
        {
            "name": "Techies",
            "Venomancer": 1.03
        },
        {
            "name": "Templar Assassin",
            "Venomancer": -0.882
        },
        {
            "name": "Terrorblade",
            "Venomancer": 1.671
        },
        {
            "name": "Tidehunter",
            "Venomancer": -1.795
        },
        {
            "name": "Timbersaw",
            "Venomancer": 0.531
        },
        {
            "name": "Tinker",
            "Venomancer": 2.038
        },
        {
            "name": "Tiny",
            "Venomancer": -2.12
        },
        {
            "name": "Treant Protector",
            "Venomancer": -0.41
        },
        {
            "name": "Troll Warlord",
            "Venomancer": 0.396
        },
        {
            "name": "Tusk",
            "Venomancer": -0.302
        },
        {
            "name": "Underlord",
            "Venomancer": -0.229
        },
        {
            "name": "Undying",
            "Venomancer": -1.733
        },
        {
            "name": "Ursa",
            "Venomancer": -1.557
        },
        {
            "name": "Vengeful Spirit",
            "Venomancer": -0.372
        },
        {
            "name": "Viper",
            "Venomancer": 1.068
        },
        {
            "name": "Visage",
            "Venomancer": -0.724
        },
        {
            "name": "Void Spirit",
            "Venomancer": 0.382
        },
        {
            "name": "Warlock",
            "Venomancer": -0.143
        },
        {
            "name": "Weaver",
            "Venomancer": 1.068
        },
        {
            "name": "Windranger",
            "Venomancer": 1.533
        },
        {
            "name": "Winter Wyvern",
            "Venomancer": -1.712
        },
        {
            "name": "Witch Doctor",
            "Venomancer": 0.299
        },
        {
            "name": "Wraith King",
            "Venomancer": -1.705
        },
        {
            "name": "Zeus",
            "Venomancer": -0.056
        }
    ],
    "Visage": [
        {
            "name": "Abaddon",
            "Visage": -0.389
        },
        {
            "name": "Alchemist",
            "Visage": -0.041
        },
        {
            "name": "Ancient Apparition",
            "Visage": -1.14
        },
        {
            "name": "Anti-Mage",
            "Visage": 0.298
        },
        {
            "name": "Arc Warden",
            "Visage": -0.928
        },
        {
            "name": "Axe",
            "Visage": 2.979
        },
        {
            "name": "Bane",
            "Visage": -0.65
        },
        {
            "name": "Batrider",
            "Visage": 0.624
        },
        {
            "name": "Beastmaster",
            "Visage": 1.365
        },
        {
            "name": "Bloodseeker",
            "Visage": -1.08
        },
        {
            "name": "Bounty Hunter",
            "Visage": 0.209
        },
        {
            "name": "Brewmaster",
            "Visage": 0.224
        },
        {
            "name": "Bristleback",
            "Visage": 3.477
        },
        {
            "name": "Broodmother",
            "Visage": -1.973
        },
        {
            "name": "Centaur Warrunner",
            "Visage": 0.43
        },
        {
            "name": "Chaos Knight",
            "Visage": 1.458
        },
        {
            "name": "Chen",
            "Visage": 2.994
        },
        {
            "name": "Clinkz",
            "Visage": -0.352
        },
        {
            "name": "Clockwerk",
            "Visage": -1.068
        },
        {
            "name": "Crystal Maiden",
            "Visage": 1.135
        },
        {
            "name": "Dark Seer",
            "Visage": 0.354
        },
        {
            "name": "Dark Willow",
            "Visage": 1.741
        },
        {
            "name": "Dawnbreaker",
            "Visage": -0.449
        },
        {
            "name": "Dazzle",
            "Visage": 0.228
        },
        {
            "name": "Death Prophet",
            "Visage": -1.427
        },
        {
            "name": "Disruptor",
            "Visage": 0.937
        },
        {
            "name": "Doom",
            "Visage": -1.156
        },
        {
            "name": "Dragon Knight",
            "Visage": -0.406
        },
        {
            "name": "Drow Ranger",
            "Visage": 0.317
        },
        {
            "name": "Earth Spirit",
            "Visage": 1.066
        },
        {
            "name": "Earthshaker",
            "Visage": 2.882
        },
        {
            "name": "Elder Titan",
            "Visage": -2.321
        },
        {
            "name": "Ember Spirit",
            "Visage": 1.115
        },
        {
            "name": "Enchantress",
            "Visage": 1.962
        },
        {
            "name": "Enigma",
            "Visage": -0.762
        },
        {
            "name": "Faceless Void",
            "Visage": 0.115
        },
        {
            "name": "Grimstroke",
            "Visage": -1.566
        },
        {
            "name": "Gyrocopter",
            "Visage": 0.455
        },
        {
            "name": "Hoodwink",
            "Visage": 0.341
        },
        {
            "name": "Huskar",
            "Visage": -1.025
        },
        {
            "name": "Invoker",
            "Visage": -1.2
        },
        {
            "name": "Io",
            "Visage": 0.558
        },
        {
            "name": "Jakiro",
            "Visage": 1.23
        },
        {
            "name": "Juggernaut",
            "Visage": -2.91
        },
        {
            "name": "Keeper of the Light",
            "Visage": -0.088
        },
        {
            "name": "Kez",
            "Visage": 0.106
        },
        {
            "name": "Kunkka",
            "Visage": -0.001
        },
        {
            "name": "Legion Commander",
            "Visage": -0.222
        },
        {
            "name": "Leshrac",
            "Visage": -0.292
        },
        {
            "name": "Lich",
            "Visage": -0.65
        },
        {
            "name": "Lifestealer",
            "Visage": -1.759
        },
        {
            "name": "Lina",
            "Visage": 0.164
        },
        {
            "name": "Lion",
            "Visage": -1.578
        },
        {
            "name": "Lone Druid",
            "Visage": 0.675
        },
        {
            "name": "Luna",
            "Visage": 1.605
        },
        {
            "name": "Lycan",
            "Visage": -0.291
        },
        {
            "name": "Magnus",
            "Visage": 0.267
        },
        {
            "name": "Marci",
            "Visage": 2.134
        },
        {
            "name": "Mars",
            "Visage": 0.826
        },
        {
            "name": "Medusa",
            "Visage": 2.44
        },
        {
            "name": "Meepo",
            "Visage": -1.575
        },
        {
            "name": "Mirana",
            "Visage": 0.239
        },
        {
            "name": "Monkey King",
            "Visage": -0.675
        },
        {
            "name": "Morphling",
            "Visage": -1.4
        },
        {
            "name": "Muerta",
            "Visage": -0.04
        },
        {
            "name": "Naga Siren",
            "Visage": 5.871
        },
        {
            "name": "Nature's Prophet",
            "Visage": -0.572
        },
        {
            "name": "Necrophos",
            "Visage": -2.293
        },
        {
            "name": "Night Stalker",
            "Visage": -2.242
        },
        {
            "name": "Nyx Assassin",
            "Visage": -2.04
        },
        {
            "name": "Ogre Magi",
            "Visage": -0.025
        },
        {
            "name": "Omniknight",
            "Visage": 2.318
        },
        {
            "name": "Oracle",
            "Visage": -1.01
        },
        {
            "name": "Outworld Destroyer",
            "Visage": -3.109
        },
        {
            "name": "Pangolier",
            "Visage": 2.125
        },
        {
            "name": "Phantom Assassin",
            "Visage": 1.909
        },
        {
            "name": "Phantom Lancer",
            "Visage": 3.223
        },
        {
            "name": "Phoenix",
            "Visage": -0.19
        },
        {
            "name": "Primal Beast",
            "Visage": 0.571
        },
        {
            "name": "Puck",
            "Visage": 0.558
        },
        {
            "name": "Pudge",
            "Visage": -0.524
        },
        {
            "name": "Pugna",
            "Visage": -1.399
        },
        {
            "name": "Queen of Pain",
            "Visage": 0.419
        },
        {
            "name": "Razor",
            "Visage": 2.955
        },
        {
            "name": "Riki",
            "Visage": 2.962
        },
        {
            "name": "Ringmaster",
            "Visage": -0.326
        },
        {
            "name": "Rubick",
            "Visage": -0.975
        },
        {
            "name": "Sand King",
            "Visage": 2.155
        },
        {
            "name": "Shadow Demon",
            "Visage": -0.308
        },
        {
            "name": "Shadow Fiend",
            "Visage": -1.54
        },
        {
            "name": "Shadow Shaman",
            "Visage": -1.099
        },
        {
            "name": "Silencer",
            "Visage": 0.102
        },
        {
            "name": "Skywrath Mage",
            "Visage": -0.423
        },
        {
            "name": "Slardar",
            "Visage": 0.652
        },
        {
            "name": "Slark",
            "Visage": -0.344
        },
        {
            "name": "Snapfire",
            "Visage": -0.683
        },
        {
            "name": "Sniper",
            "Visage": 0.559
        },
        {
            "name": "Spectre",
            "Visage": -1.195
        },
        {
            "name": "Spirit Breaker",
            "Visage": -0.653
        },
        {
            "name": "Storm Spirit",
            "Visage": -0.074
        },
        {
            "name": "Sven",
            "Visage": 1.352
        },
        {
            "name": "Techies",
            "Visage": -0.536
        },
        {
            "name": "Templar Assassin",
            "Visage": 0.409
        },
        {
            "name": "Terrorblade",
            "Visage": 0.746
        },
        {
            "name": "Tidehunter",
            "Visage": 1.758
        },
        {
            "name": "Timbersaw",
            "Visage": -0.318
        },
        {
            "name": "Tinker",
            "Visage": -2.884
        },
        {
            "name": "Tiny",
            "Visage": 0.867
        },
        {
            "name": "Treant Protector",
            "Visage": 1.513
        },
        {
            "name": "Troll Warlord",
            "Visage": 0.543
        },
        {
            "name": "Tusk",
            "Visage": -0.008
        },
        {
            "name": "Underlord",
            "Visage": 2.61
        },
        {
            "name": "Undying",
            "Visage": 1.792
        },
        {
            "name": "Ursa",
            "Visage": -0.148
        },
        {
            "name": "Vengeful Spirit",
            "Visage": -0.172
        },
        {
            "name": "Venomancer",
            "Visage": 0.534
        },
        {
            "name": "Viper",
            "Visage": -0.128
        },
        {
            "name": "Void Spirit",
            "Visage": -0.544
        },
        {
            "name": "Warlock",
            "Visage": 1.253
        },
        {
            "name": "Weaver",
            "Visage": 1.976
        },
        {
            "name": "Windranger",
            "Visage": 0.192
        },
        {
            "name": "Winter Wyvern",
            "Visage": 4.278
        },
        {
            "name": "Witch Doctor",
            "Visage": -0.414
        },
        {
            "name": "Wraith King",
            "Visage": -0.432
        },
        {
            "name": "Zeus",
            "Visage": -1.602
        }
    ],
    "Void Spirit": [
        {
            "name": "Abaddon",
            "Void Spirit": 1.219
        },
        {
            "name": "Alchemist",
            "Void Spirit": 0.912
        },
        {
            "name": "Ancient Apparition",
            "Void Spirit": -1.94
        },
        {
            "name": "Anti-Mage",
            "Void Spirit": 1.798
        },
        {
            "name": "Arc Warden",
            "Void Spirit": -2.324
        },
        {
            "name": "Axe",
            "Void Spirit": 1.284
        },
        {
            "name": "Bane",
            "Void Spirit": -1.077
        },
        {
            "name": "Batrider",
            "Void Spirit": 0.679
        },
        {
            "name": "Beastmaster",
            "Void Spirit": 1.051
        },
        {
            "name": "Bloodseeker",
            "Void Spirit": 1.336
        },
        {
            "name": "Bounty Hunter",
            "Void Spirit": 0.914
        },
        {
            "name": "Brewmaster",
            "Void Spirit": 0.581
        },
        {
            "name": "Bristleback",
            "Void Spirit": -1.174
        },
        {
            "name": "Broodmother",
            "Void Spirit": 2.691
        },
        {
            "name": "Centaur Warrunner",
            "Void Spirit": -0.347
        },
        {
            "name": "Chaos Knight",
            "Void Spirit": 0.378
        },
        {
            "name": "Chen",
            "Void Spirit": 1.477
        },
        {
            "name": "Clinkz",
            "Void Spirit": -0.862
        },
        {
            "name": "Clockwerk",
            "Void Spirit": -1.313
        },
        {
            "name": "Crystal Maiden",
            "Void Spirit": 0.165
        },
        {
            "name": "Dark Seer",
            "Void Spirit": 0.067
        },
        {
            "name": "Dark Willow",
            "Void Spirit": -0.733
        },
        {
            "name": "Dawnbreaker",
            "Void Spirit": 0.19
        },
        {
            "name": "Dazzle",
            "Void Spirit": 1.142
        },
        {
            "name": "Death Prophet",
            "Void Spirit": -0.844
        },
        {
            "name": "Disruptor",
            "Void Spirit": 1.762
        },
        {
            "name": "Doom",
            "Void Spirit": 1.025
        },
        {
            "name": "Dragon Knight",
            "Void Spirit": 2.357
        },
        {
            "name": "Drow Ranger",
            "Void Spirit": -1.252
        },
        {
            "name": "Earth Spirit",
            "Void Spirit": -0.116
        },
        {
            "name": "Earthshaker",
            "Void Spirit": 0.572
        },
        {
            "name": "Elder Titan",
            "Void Spirit": 0.391
        },
        {
            "name": "Ember Spirit",
            "Void Spirit": 2.41
        },
        {
            "name": "Enchantress",
            "Void Spirit": 0.218
        },
        {
            "name": "Enigma",
            "Void Spirit": 0.907
        },
        {
            "name": "Faceless Void",
            "Void Spirit": -0.137
        },
        {
            "name": "Grimstroke",
            "Void Spirit": 0.653
        },
        {
            "name": "Gyrocopter",
            "Void Spirit": 0.261
        },
        {
            "name": "Hoodwink",
            "Void Spirit": -1.191
        },
        {
            "name": "Huskar",
            "Void Spirit": 3.65
        },
        {
            "name": "Invoker",
            "Void Spirit": -2.631
        },
        {
            "name": "Io",
            "Void Spirit": 3.149
        },
        {
            "name": "Jakiro",
            "Void Spirit": 0.45
        },
        {
            "name": "Juggernaut",
            "Void Spirit": -2.45
        },
        {
            "name": "Keeper of the Light",
            "Void Spirit": 0.216
        },
        {
            "name": "Kez",
            "Void Spirit": 0.674
        },
        {
            "name": "Kunkka",
            "Void Spirit": -1.472
        },
        {
            "name": "Legion Commander",
            "Void Spirit": 1.085
        },
        {
            "name": "Leshrac",
            "Void Spirit": -0.367
        },
        {
            "name": "Lich",
            "Void Spirit": -0.673
        },
        {
            "name": "Lifestealer",
            "Void Spirit": -1.047
        },
        {
            "name": "Lina",
            "Void Spirit": -1.596
        },
        {
            "name": "Lion",
            "Void Spirit": 0.191
        },
        {
            "name": "Lone Druid",
            "Void Spirit": 3.167
        },
        {
            "name": "Luna",
            "Void Spirit": 0.719
        },
        {
            "name": "Lycan",
            "Void Spirit": 1.4
        },
        {
            "name": "Magnus",
            "Void Spirit": 0.218
        },
        {
            "name": "Marci",
            "Void Spirit": 1.584
        },
        {
            "name": "Mars",
            "Void Spirit": -1.829
        },
        {
            "name": "Medusa",
            "Void Spirit": 0.778
        },
        {
            "name": "Meepo",
            "Void Spirit": 5.74
        },
        {
            "name": "Mirana",
            "Void Spirit": -0.701
        },
        {
            "name": "Monkey King",
            "Void Spirit": 2.224
        },
        {
            "name": "Morphling",
            "Void Spirit": -0.333
        },
        {
            "name": "Muerta",
            "Void Spirit": 1.241
        },
        {
            "name": "Naga Siren",
            "Void Spirit": 2.828
        },
        {
            "name": "Nature's Prophet",
            "Void Spirit": -1.116
        },
        {
            "name": "Necrophos",
            "Void Spirit": 0.769
        },
        {
            "name": "Night Stalker",
            "Void Spirit": 2.62
        },
        {
            "name": "Nyx Assassin",
            "Void Spirit": -0.338
        },
        {
            "name": "Ogre Magi",
            "Void Spirit": 0.453
        },
        {
            "name": "Omniknight",
            "Void Spirit": 1.312
        },
        {
            "name": "Oracle",
            "Void Spirit": 1.307
        },
        {
            "name": "Outworld Destroyer",
            "Void Spirit": -1.047
        },
        {
            "name": "Pangolier",
            "Void Spirit": 1.806
        },
        {
            "name": "Phantom Assassin",
            "Void Spirit": -1.012
        },
        {
            "name": "Phantom Lancer",
            "Void Spirit": -0.158
        },
        {
            "name": "Phoenix",
            "Void Spirit": -1.489
        },
        {
            "name": "Primal Beast",
            "Void Spirit": 1.168
        },
        {
            "name": "Puck",
            "Void Spirit": -1.449
        },
        {
            "name": "Pudge",
            "Void Spirit": -1.255
        },
        {
            "name": "Pugna",
            "Void Spirit": 0.332
        },
        {
            "name": "Queen of Pain",
            "Void Spirit": -1.589
        },
        {
            "name": "Razor",
            "Void Spirit": -0.668
        },
        {
            "name": "Riki",
            "Void Spirit": 1.738
        },
        {
            "name": "Ringmaster",
            "Void Spirit": 0.448
        },
        {
            "name": "Rubick",
            "Void Spirit": -0.093
        },
        {
            "name": "Sand King",
            "Void Spirit": -1.554
        },
        {
            "name": "Shadow Demon",
            "Void Spirit": -1.532
        },
        {
            "name": "Shadow Fiend",
            "Void Spirit": 1.326
        },
        {
            "name": "Shadow Shaman",
            "Void Spirit": 0.931
        },
        {
            "name": "Silencer",
            "Void Spirit": 0.481
        },
        {
            "name": "Skywrath Mage",
            "Void Spirit": -0.467
        },
        {
            "name": "Slardar",
            "Void Spirit": 0.56
        },
        {
            "name": "Slark",
            "Void Spirit": 1.049
        },
        {
            "name": "Snapfire",
            "Void Spirit": -1.555
        },
        {
            "name": "Sniper",
            "Void Spirit": -2.17
        },
        {
            "name": "Spectre",
            "Void Spirit": -0.566
        },
        {
            "name": "Spirit Breaker",
            "Void Spirit": 0.022
        },
        {
            "name": "Storm Spirit",
            "Void Spirit": -0.586
        },
        {
            "name": "Sven",
            "Void Spirit": 2.119
        },
        {
            "name": "Techies",
            "Void Spirit": -1.016
        },
        {
            "name": "Templar Assassin",
            "Void Spirit": 1.656
        },
        {
            "name": "Terrorblade",
            "Void Spirit": 2.192
        },
        {
            "name": "Tidehunter",
            "Void Spirit": 2.447
        },
        {
            "name": "Timbersaw",
            "Void Spirit": 0.187
        },
        {
            "name": "Tinker",
            "Void Spirit": 0.262
        },
        {
            "name": "Tiny",
            "Void Spirit": 1.351
        },
        {
            "name": "Treant Protector",
            "Void Spirit": -1.409
        },
        {
            "name": "Troll Warlord",
            "Void Spirit": 2.065
        },
        {
            "name": "Tusk",
            "Void Spirit": 0.177
        },
        {
            "name": "Underlord",
            "Void Spirit": 1.767
        },
        {
            "name": "Undying",
            "Void Spirit": -0.548
        },
        {
            "name": "Ursa",
            "Void Spirit": 0.319
        },
        {
            "name": "Vengeful Spirit",
            "Void Spirit": -0.29
        },
        {
            "name": "Venomancer",
            "Void Spirit": -0.504
        },
        {
            "name": "Viper",
            "Void Spirit": -1.201
        },
        {
            "name": "Visage",
            "Void Spirit": 0.51
        },
        {
            "name": "Warlock",
            "Void Spirit": 1.068
        },
        {
            "name": "Weaver",
            "Void Spirit": -1.366
        },
        {
            "name": "Windranger",
            "Void Spirit": -0.437
        },
        {
            "name": "Winter Wyvern",
            "Void Spirit": 1.166
        },
        {
            "name": "Witch Doctor",
            "Void Spirit": -0.891
        },
        {
            "name": "Wraith King",
            "Void Spirit": 0.928
        },
        {
            "name": "Zeus",
            "Void Spirit": -1.766
        }
    ],
    "Windranger": [
        {
            "name": "Abaddon",
            "Windranger": -0.766
        },
        {
            "name": "Alchemist",
            "Windranger": -0.392
        },
        {
            "name": "Ancient Apparition",
            "Windranger": -1.566
        },
        {
            "name": "Anti-Mage",
            "Windranger": 0.02
        },
        {
            "name": "Arc Warden",
            "Windranger": -0.056
        },
        {
            "name": "Axe",
            "Windranger": 2.329
        },
        {
            "name": "Bane",
            "Windranger": 1.148
        },
        {
            "name": "Batrider",
            "Windranger": -0.501
        },
        {
            "name": "Beastmaster",
            "Windranger": 0.011
        },
        {
            "name": "Bloodseeker",
            "Windranger": 0.308
        },
        {
            "name": "Bounty Hunter",
            "Windranger": -0.037
        },
        {
            "name": "Brewmaster",
            "Windranger": -0.855
        },
        {
            "name": "Bristleback",
            "Windranger": 0.532
        },
        {
            "name": "Broodmother",
            "Windranger": -0.213
        },
        {
            "name": "Centaur Warrunner",
            "Windranger": 2.405
        },
        {
            "name": "Chaos Knight",
            "Windranger": 0.176
        },
        {
            "name": "Chen",
            "Windranger": 0.056
        },
        {
            "name": "Clinkz",
            "Windranger": -1.383
        },
        {
            "name": "Clockwerk",
            "Windranger": 1.724
        },
        {
            "name": "Crystal Maiden",
            "Windranger": 0.167
        },
        {
            "name": "Dark Seer",
            "Windranger": -0.906
        },
        {
            "name": "Dark Willow",
            "Windranger": -0.674
        },
        {
            "name": "Dawnbreaker",
            "Windranger": -0.035
        },
        {
            "name": "Dazzle",
            "Windranger": -1.241
        },
        {
            "name": "Death Prophet",
            "Windranger": -1.174
        },
        {
            "name": "Disruptor",
            "Windranger": -0.53
        },
        {
            "name": "Doom",
            "Windranger": -0.031
        },
        {
            "name": "Dragon Knight",
            "Windranger": 0.676
        },
        {
            "name": "Drow Ranger",
            "Windranger": 0.106
        },
        {
            "name": "Earth Spirit",
            "Windranger": 1.114
        },
        {
            "name": "Earthshaker",
            "Windranger": -0.153
        },
        {
            "name": "Elder Titan",
            "Windranger": -1.405
        },
        {
            "name": "Ember Spirit",
            "Windranger": 0.633
        },
        {
            "name": "Enchantress",
            "Windranger": -0.407
        },
        {
            "name": "Enigma",
            "Windranger": -0.369
        },
        {
            "name": "Faceless Void",
            "Windranger": 0.979
        },
        {
            "name": "Grimstroke",
            "Windranger": -0.916
        },
        {
            "name": "Gyrocopter",
            "Windranger": -0.29
        },
        {
            "name": "Hoodwink",
            "Windranger": -0.458
        },
        {
            "name": "Huskar",
            "Windranger": -4.088
        },
        {
            "name": "Invoker",
            "Windranger": -0.722
        },
        {
            "name": "Io",
            "Windranger": -1.5
        },
        {
            "name": "Jakiro",
            "Windranger": -0.916
        },
        {
            "name": "Juggernaut",
            "Windranger": -1.266
        },
        {
            "name": "Keeper of the Light",
            "Windranger": -1.39
        },
        {
            "name": "Kez",
            "Windranger": -1.946
        },
        {
            "name": "Kunkka",
            "Windranger": 0.267
        },
        {
            "name": "Legion Commander",
            "Windranger": 0.277
        },
        {
            "name": "Leshrac",
            "Windranger": 0.146
        },
        {
            "name": "Lich",
            "Windranger": -0.404
        },
        {
            "name": "Lifestealer",
            "Windranger": -1.025
        },
        {
            "name": "Lina",
            "Windranger": -1.297
        },
        {
            "name": "Lion",
            "Windranger": 0.169
        },
        {
            "name": "Lone Druid",
            "Windranger": -2.527
        },
        {
            "name": "Luna",
            "Windranger": 0.617
        },
        {
            "name": "Lycan",
            "Windranger": -0.137
        },
        {
            "name": "Magnus",
            "Windranger": -0.298
        },
        {
            "name": "Marci",
            "Windranger": -1.22
        },
        {
            "name": "Mars",
            "Windranger": 1.551
        },
        {
            "name": "Medusa",
            "Windranger": -1.443
        },
        {
            "name": "Meepo",
            "Windranger": -0.779
        },
        {
            "name": "Mirana",
            "Windranger": 0.356
        },
        {
            "name": "Monkey King",
            "Windranger": -1.661
        },
        {
            "name": "Morphling",
            "Windranger": -0.711
        },
        {
            "name": "Muerta",
            "Windranger": -0.452
        },
        {
            "name": "Naga Siren",
            "Windranger": 0.897
        },
        {
            "name": "Nature's Prophet",
            "Windranger": 0.33
        },
        {
            "name": "Necrophos",
            "Windranger": 0.487
        },
        {
            "name": "Night Stalker",
            "Windranger": -0.594
        },
        {
            "name": "Nyx Assassin",
            "Windranger": 0.847
        },
        {
            "name": "Ogre Magi",
            "Windranger": 1.16
        },
        {
            "name": "Omniknight",
            "Windranger": -0.709
        },
        {
            "name": "Oracle",
            "Windranger": 0.068
        },
        {
            "name": "Outworld Destroyer",
            "Windranger": 0.495
        },
        {
            "name": "Pangolier",
            "Windranger": 0.845
        },
        {
            "name": "Phantom Assassin",
            "Windranger": -0.485
        },
        {
            "name": "Phantom Lancer",
            "Windranger": 1.067
        },
        {
            "name": "Phoenix",
            "Windranger": -0.208
        },
        {
            "name": "Primal Beast",
            "Windranger": 0.752
        },
        {
            "name": "Puck",
            "Windranger": 1.247
        },
        {
            "name": "Pudge",
            "Windranger": -0.36
        },
        {
            "name": "Pugna",
            "Windranger": 0.056
        },
        {
            "name": "Queen of Pain",
            "Windranger": 1.281
        },
        {
            "name": "Razor",
            "Windranger": 1.216
        },
        {
            "name": "Riki",
            "Windranger": -1.171
        },
        {
            "name": "Ringmaster",
            "Windranger": -0.146
        },
        {
            "name": "Rubick",
            "Windranger": 0.151
        },
        {
            "name": "Sand King",
            "Windranger": 0.629
        },
        {
            "name": "Shadow Demon",
            "Windranger": -0.141
        },
        {
            "name": "Shadow Fiend",
            "Windranger": -1.685
        },
        {
            "name": "Shadow Shaman",
            "Windranger": -0.464
        },
        {
            "name": "Silencer",
            "Windranger": -0.95
        },
        {
            "name": "Skywrath Mage",
            "Windranger": -0.345
        },
        {
            "name": "Slardar",
            "Windranger": -1.091
        },
        {
            "name": "Slark",
            "Windranger": -0.93
        },
        {
            "name": "Snapfire",
            "Windranger": -0.59
        },
        {
            "name": "Sniper",
            "Windranger": -0.88
        },
        {
            "name": "Spectre",
            "Windranger": 1.169
        },
        {
            "name": "Spirit Breaker",
            "Windranger": 0.693
        },
        {
            "name": "Storm Spirit",
            "Windranger": -0.498
        },
        {
            "name": "Sven",
            "Windranger": -0.525
        },
        {
            "name": "Techies",
            "Windranger": 0.973
        },
        {
            "name": "Templar Assassin",
            "Windranger": -0.575
        },
        {
            "name": "Terrorblade",
            "Windranger": -0.775
        },
        {
            "name": "Tidehunter",
            "Windranger": 0.591
        },
        {
            "name": "Timbersaw",
            "Windranger": -0.178
        },
        {
            "name": "Tinker",
            "Windranger": -0.332
        },
        {
            "name": "Tiny",
            "Windranger": 1.267
        },
        {
            "name": "Treant Protector",
            "Windranger": 0.514
        },
        {
            "name": "Troll Warlord",
            "Windranger": -2.345
        },
        {
            "name": "Tusk",
            "Windranger": 0.648
        },
        {
            "name": "Underlord",
            "Windranger": -0.34
        },
        {
            "name": "Undying",
            "Windranger": -0.529
        },
        {
            "name": "Ursa",
            "Windranger": -2.649
        },
        {
            "name": "Vengeful Spirit",
            "Windranger": 0.352
        },
        {
            "name": "Venomancer",
            "Windranger": -1.496
        },
        {
            "name": "Viper",
            "Windranger": -2.377
        },
        {
            "name": "Visage",
            "Windranger": -0.587
        },
        {
            "name": "Void Spirit",
            "Windranger": 0.146
        },
        {
            "name": "Warlock",
            "Windranger": -0.158
        },
        {
            "name": "Weaver",
            "Windranger": -0.049
        },
        {
            "name": "Winter Wyvern",
            "Windranger": -0.484
        },
        {
            "name": "Witch Doctor",
            "Windranger": -0.971
        },
        {
            "name": "Wraith King",
            "Windranger": 0.202
        },
        {
            "name": "Zeus",
            "Windranger": 0.449
        }
    ],
    "Winter Wyvern": [
        {
            "name": "Abaddon",
            "Winter Wyvern": 0.202
        },
        {
            "name": "Alchemist",
            "Winter Wyvern": 0.157
        },
        {
            "name": "Ancient Apparition",
            "Winter Wyvern": 2.478
        },
        {
            "name": "Anti-Mage",
            "Winter Wyvern": 2.804
        },
        {
            "name": "Arc Warden",
            "Winter Wyvern": -1.224
        },
        {
            "name": "Axe",
            "Winter Wyvern": 0.952
        },
        {
            "name": "Bane",
            "Winter Wyvern": -1.064
        },
        {
            "name": "Batrider",
            "Winter Wyvern": -1.129
        },
        {
            "name": "Beastmaster",
            "Winter Wyvern": 0.163
        },
        {
            "name": "Bloodseeker",
            "Winter Wyvern": -1.16
        },
        {
            "name": "Bounty Hunter",
            "Winter Wyvern": 0.778
        },
        {
            "name": "Brewmaster",
            "Winter Wyvern": 0.436
        },
        {
            "name": "Bristleback",
            "Winter Wyvern": -1.133
        },
        {
            "name": "Broodmother",
            "Winter Wyvern": -2.681
        },
        {
            "name": "Centaur Warrunner",
            "Winter Wyvern": 0.701
        },
        {
            "name": "Chaos Knight",
            "Winter Wyvern": -2.817
        },
        {
            "name": "Chen",
            "Winter Wyvern": -0.695
        },
        {
            "name": "Clinkz",
            "Winter Wyvern": -0.333
        },
        {
            "name": "Clockwerk",
            "Winter Wyvern": -0.111
        },
        {
            "name": "Crystal Maiden",
            "Winter Wyvern": -0.522
        },
        {
            "name": "Dark Seer",
            "Winter Wyvern": -1.179
        },
        {
            "name": "Dark Willow",
            "Winter Wyvern": 0.369
        },
        {
            "name": "Dawnbreaker",
            "Winter Wyvern": -1.534
        },
        {
            "name": "Dazzle",
            "Winter Wyvern": 1.422
        },
        {
            "name": "Death Prophet",
            "Winter Wyvern": 1.413
        },
        {
            "name": "Disruptor",
            "Winter Wyvern": -0.599
        },
        {
            "name": "Doom",
            "Winter Wyvern": 0.873
        },
        {
            "name": "Dragon Knight",
            "Winter Wyvern": -0.533
        },
        {
            "name": "Drow Ranger",
            "Winter Wyvern": -0.671
        },
        {
            "name": "Earth Spirit",
            "Winter Wyvern": 1.539
        },
        {
            "name": "Earthshaker",
            "Winter Wyvern": -0.165
        },
        {
            "name": "Elder Titan",
            "Winter Wyvern": -0.79
        },
        {
            "name": "Ember Spirit",
            "Winter Wyvern": 0.498
        },
        {
            "name": "Enchantress",
            "Winter Wyvern": -0.161
        },
        {
            "name": "Enigma",
            "Winter Wyvern": -1.726
        },
        {
            "name": "Faceless Void",
            "Winter Wyvern": 0.502
        },
        {
            "name": "Grimstroke",
            "Winter Wyvern": 0.502
        },
        {
            "name": "Gyrocopter",
            "Winter Wyvern": 1.777
        },
        {
            "name": "Hoodwink",
            "Winter Wyvern": 0.18
        },
        {
            "name": "Huskar",
            "Winter Wyvern": 1.709
        },
        {
            "name": "Invoker",
            "Winter Wyvern": 0.183
        },
        {
            "name": "Io",
            "Winter Wyvern": -1.421
        },
        {
            "name": "Jakiro",
            "Winter Wyvern": -0.272
        },
        {
            "name": "Juggernaut",
            "Winter Wyvern": 0.313
        },
        {
            "name": "Keeper of the Light",
            "Winter Wyvern": 1.262
        },
        {
            "name": "Kez",
            "Winter Wyvern": -2.113
        },
        {
            "name": "Kunkka",
            "Winter Wyvern": -0.549
        },
        {
            "name": "Legion Commander",
            "Winter Wyvern": -3.524
        },
        {
            "name": "Leshrac",
            "Winter Wyvern": 0.996
        },
        {
            "name": "Lich",
            "Winter Wyvern": -1.188
        },
        {
            "name": "Lifestealer",
            "Winter Wyvern": -0.227
        },
        {
            "name": "Lina",
            "Winter Wyvern": -2.728
        },
        {
            "name": "Lion",
            "Winter Wyvern": -0.649
        },
        {
            "name": "Lone Druid",
            "Winter Wyvern": -2.698
        },
        {
            "name": "Luna",
            "Winter Wyvern": 0.238
        },
        {
            "name": "Lycan",
            "Winter Wyvern": -1.251
        },
        {
            "name": "Magnus",
            "Winter Wyvern": -1.24
        },
        {
            "name": "Marci",
            "Winter Wyvern": -1.664
        },
        {
            "name": "Mars",
            "Winter Wyvern": 0.221
        },
        {
            "name": "Medusa",
            "Winter Wyvern": -2.043
        },
        {
            "name": "Meepo",
            "Winter Wyvern": -7.608
        },
        {
            "name": "Mirana",
            "Winter Wyvern": 0.911
        },
        {
            "name": "Monkey King",
            "Winter Wyvern": 1.275
        },
        {
            "name": "Morphling",
            "Winter Wyvern": -0.345
        },
        {
            "name": "Muerta",
            "Winter Wyvern": 0.512
        },
        {
            "name": "Naga Siren",
            "Winter Wyvern": -1.711
        },
        {
            "name": "Nature's Prophet",
            "Winter Wyvern": 0.282
        },
        {
            "name": "Necrophos",
            "Winter Wyvern": 1.957
        },
        {
            "name": "Night Stalker",
            "Winter Wyvern": -1.051
        },
        {
            "name": "Nyx Assassin",
            "Winter Wyvern": 0.457
        },
        {
            "name": "Ogre Magi",
            "Winter Wyvern": -0.644
        },
        {
            "name": "Omniknight",
            "Winter Wyvern": -0.376
        },
        {
            "name": "Oracle",
            "Winter Wyvern": -0.648
        },
        {
            "name": "Outworld Destroyer",
            "Winter Wyvern": 1.793
        },
        {
            "name": "Pangolier",
            "Winter Wyvern": -0.204
        },
        {
            "name": "Phantom Assassin",
            "Winter Wyvern": 0.3
        },
        {
            "name": "Phantom Lancer",
            "Winter Wyvern": -1.186
        },
        {
            "name": "Phoenix",
            "Winter Wyvern": 1.814
        },
        {
            "name": "Primal Beast",
            "Winter Wyvern": 0.775
        },
        {
            "name": "Puck",
            "Winter Wyvern": 0.714
        },
        {
            "name": "Pudge",
            "Winter Wyvern": 0.277
        },
        {
            "name": "Pugna",
            "Winter Wyvern": 3.019
        },
        {
            "name": "Queen of Pain",
            "Winter Wyvern": 1.542
        },
        {
            "name": "Razor",
            "Winter Wyvern": 0.494
        },
        {
            "name": "Riki",
            "Winter Wyvern": -0.174
        },
        {
            "name": "Ringmaster",
            "Winter Wyvern": 0.538
        },
        {
            "name": "Rubick",
            "Winter Wyvern": 0.037
        },
        {
            "name": "Sand King",
            "Winter Wyvern": 2.246
        },
        {
            "name": "Shadow Demon",
            "Winter Wyvern": -0.471
        },
        {
            "name": "Shadow Fiend",
            "Winter Wyvern": 0.555
        },
        {
            "name": "Shadow Shaman",
            "Winter Wyvern": -0.452
        },
        {
            "name": "Silencer",
            "Winter Wyvern": -0.283
        },
        {
            "name": "Skywrath Mage",
            "Winter Wyvern": 0.145
        },
        {
            "name": "Slardar",
            "Winter Wyvern": -0.14
        },
        {
            "name": "Slark",
            "Winter Wyvern": 1.357
        },
        {
            "name": "Snapfire",
            "Winter Wyvern": 1.492
        },
        {
            "name": "Sniper",
            "Winter Wyvern": 1.325
        },
        {
            "name": "Spectre",
            "Winter Wyvern": 0.415
        },
        {
            "name": "Spirit Breaker",
            "Winter Wyvern": -1.204
        },
        {
            "name": "Storm Spirit",
            "Winter Wyvern": -0.078
        },
        {
            "name": "Sven",
            "Winter Wyvern": -1.449
        },
        {
            "name": "Techies",
            "Winter Wyvern": 0.641
        },
        {
            "name": "Templar Assassin",
            "Winter Wyvern": -1.26
        },
        {
            "name": "Terrorblade",
            "Winter Wyvern": -3.539
        },
        {
            "name": "Tidehunter",
            "Winter Wyvern": 0.718
        },
        {
            "name": "Timbersaw",
            "Winter Wyvern": 3.214
        },
        {
            "name": "Tinker",
            "Winter Wyvern": 2.048
        },
        {
            "name": "Tiny",
            "Winter Wyvern": -0.098
        },
        {
            "name": "Treant Protector",
            "Winter Wyvern": 0.565
        },
        {
            "name": "Troll Warlord",
            "Winter Wyvern": -2.542
        },
        {
            "name": "Tusk",
            "Winter Wyvern": -1.618
        },
        {
            "name": "Underlord",
            "Winter Wyvern": 0.733
        },
        {
            "name": "Undying",
            "Winter Wyvern": 2.028
        },
        {
            "name": "Ursa",
            "Winter Wyvern": -0.705
        },
        {
            "name": "Vengeful Spirit",
            "Winter Wyvern": -1.226
        },
        {
            "name": "Venomancer",
            "Winter Wyvern": 1.689
        },
        {
            "name": "Viper",
            "Winter Wyvern": 1.247
        },
        {
            "name": "Visage",
            "Winter Wyvern": -3.974
        },
        {
            "name": "Void Spirit",
            "Winter Wyvern": -1.017
        },
        {
            "name": "Warlock",
            "Winter Wyvern": 0.306
        },
        {
            "name": "Weaver",
            "Winter Wyvern": 1.887
        },
        {
            "name": "Windranger",
            "Winter Wyvern": 0.57
        },
        {
            "name": "Witch Doctor",
            "Winter Wyvern": 0.286
        },
        {
            "name": "Wraith King",
            "Winter Wyvern": -1.014
        },
        {
            "name": "Zeus",
            "Winter Wyvern": 0.629
        }
    ]
}


function updateTableData(selectedItems, ownComfortHeroes, oppositeComfortHeroes, isEnemyTable, setColumns, setData) {
    const nameColumn = {
        name: 'Counter',
        selector: row => row.name,
        sortable: true,
    };

    const comfortColumn = {
        name: 'Comfort',
        selector: row => row.comfort || '', // Add comfort emoji if available
        sortable: true,
    };

    const grandTotalColumn = {
        name: 'Grand Total',
        selector: row => row.grandTotal?.toFixed(3) || '0.000', // Round to 3 decimal places, fallback to 0
        sortable: true,
        sortFunction: (a, b) => b.grandTotal - a.grandTotal // Custom sorting function
    };
    
    // Ensure that these base columns are always present
    let selectedColumns = [nameColumn, comfortColumn, grandTotalColumn];

    if (selectedItems.length > 0) {
        // Filter out any undefined columns before adding them to selectedColumns
        const heroColumns = selectedItems
            .map(item => allColumns[item.value])
            .filter(column => column !== undefined);

        selectedColumns = [nameColumn, comfortColumn, ...heroColumns, grandTotalColumn];
    }

    setColumns(selectedColumns);

    if (selectedItems.length > 0) {
        const heroMap = {};
        selectedItems.forEach(item => {
            if (allData[item.value]) {
                allData[item.value].forEach(row => {
                    if (!heroMap[row.name]) {
                        heroMap[row.name] = { name: row.name, grandTotal: 0, comfort: '' };
                    }
                });
            }
        });

        selectedItems.forEach(item => {
            if (allData[item.value]) {
                allData[item.value].forEach((row) => {
                    if (heroMap[row.name]) {
                        const value = row.name === item.value ? 0 : row[item.value];
                        heroMap[row.name][item.value] = value;
                        heroMap[row.name].grandTotal = (heroMap[row.name].grandTotal || 0) + value;
                    }
                });
            }
        });

        Object.keys(heroMap).forEach(key => {
            let comfortEmojis = '';
            ownComfortHeroes.forEach(hero => {
                if (key === hero.value) {
                    comfortEmojis += isEnemyTable ? '😈' : '😊'; // Own comfort emoji
                }
            });
            oppositeComfortHeroes.forEach(hero => {
                if (key === hero.value) {
                    comfortEmojis += isEnemyTable ? '😊' : '😈'; // Opposite comfort emoji
                }
            });
            heroMap[key].comfort = comfortEmojis; // Assign the concatenated emojis to the comfort column
        });

        const selectedData = Object.values(heroMap);
        selectedData.sort((a, b) => b.grandTotal - a.grandTotal);

        setData(selectedData);
    } else {
        setData([]);
    }
}

function SelectTable({ onComfortSelect, ownComfortHeroes, oppositeComfortHeroes, isEnemyTable, urlParam }) {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    
    useEffect(() => {
        // Initialize selected options from URL parameters
        const initialComfort = searchParams.get(`${urlParam}Comfort`)?.split(',') || [];
        const initialPicks = searchParams.get(`${urlParam}Picks`)?.split(',') || [];

        // Map the values to the format expected by the Select component
        const initialComfortOptions = initialComfort.map(value => ({ value, label: value }));
        const initialPickOptions = initialPicks.map(value => ({ value, label: value }));

        // Set the initial values for the selects
        onComfortSelect(initialComfortOptions);
        setSelectedOptions(initialPickOptions);
    }, [searchParams, onComfortSelect, urlParam]);

    useEffect(() => {
        updateTableData(selectedOptions, ownComfortHeroes, oppositeComfortHeroes, isEnemyTable, setColumns, setData);
    }, [ownComfortHeroes, oppositeComfortHeroes, selectedOptions, isEnemyTable]);

    function onSelect(selectedItems) {
        setSelectedOptions(selectedItems);
        const pickValues = selectedItems.map(item => item.value);
        searchParams.set(`${urlParam}Picks`, pickValues.join(','));
        setSearchParams(searchParams);
    }


    return (
        <div className="">
            <div>
                <Select
                    defaultValue={searchParams.get(`${urlParam}Comfort`)?.split(',').map(value => ({ value, label: value })) || []}
                    isMulti
                    onChange={selectedItems => {
                        onComfortSelect(selectedItems);
                        const comfortValues = selectedItems.map(item => item.value);
                        searchParams.set(`${urlParam}Comfort`, comfortValues.join(','));
                        setSearchParams(searchParams);
                    }}
                    name="comfortSelect"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Comfort Heroes..."
                    filterOption={(candidate, input) => {
                        if (!input) return true;
                        const searchInput = input.toLowerCase();
                        const label = candidate.label.toLowerCase();
                        const words = label.split(' ');
                        const initials = words.length > 1 ? words.map(word => word[0]).join('').toLowerCase() : ''; 
                        return label.includes(searchInput) || (initials && initials === searchInput);
                    }}
                />
            </div>
            <div>
                <Select
                    defaultValue={searchParams.get(`${urlParam}Picks`)?.split(',').map(value => ({ value, label: value })) || []}
                    isMulti
                    onChange = {onSelect}
                    name="pickSelect"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select" 
                    placeholder="Select Picked Heroes..."
                    filterOption={(candidate, input) => {
                        if (!input) return true;
                        const searchInput = input.toLowerCase();
                        const label = candidate.label.toLowerCase();
                        const words = label.split(' ');
                        const initials = words.length > 1 ? words.map(word => word[0]).join('').toLowerCase() : '';
                        return label.includes(searchInput) || (initials && initials === searchInput);
                    }}
                />
            </div>
            <div>
                <DataTable
                columns={columns}
                data={data}
                defaultSortField="Grand Total" // Sort by Grand Total
                defaultSortAsc={false} // Descending order
                />
            </div>
        </div>
    );
}


function DualSelectTable() {
    const [enemyComfortHeroes, setEnemyComfortHeroes] = useState([]);
    const [allyComfortHeroes, setAllyComfortHeroes] = useState([]);
    const [enemyNotes, setEnemyNotes] = useState('');  // State for enemy notes
    const [allyNotes, setAllyNotes] = useState('');    // State for ally notes

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: 1, marginRight: '10px' }}>
                <h2>Enemy Team Picks</h2>
                <textarea 
                    value={enemyNotes} 
                    onChange={(e) => setEnemyNotes(e.target.value)} 
                    placeholder="Write notes for Enemy Team..." 
                    style={{ width: '100%', marginBottom: '10px' }} 
                />
                <SelectTable 
                    onComfortSelect={setEnemyComfortHeroes} // Affects Enemy table
                    ownComfortHeroes={enemyComfortHeroes} // Enemy comfort affects Enemy table
                    oppositeComfortHeroes={allyComfortHeroes} // Ally comfort affects Enemy table
                    isEnemyTable={true} // Flag to indicate it's the enemy table
                    urlParam="enemy"
                />
            </div>
            <div style={{ flex: 1, marginLeft: '10px' }}>
                <h2>Ally Team Picks</h2>
                <textarea 
                    value={allyNotes} 
                    onChange={(e) => setAllyNotes(e.target.value)} 
                    placeholder="Write notes for Ally Team..." 
                    style={{ width: '100%', marginBottom: '10px' }} 
                />
                <SelectTable 
                    onComfortSelect={setAllyComfortHeroes} // Affects Ally table
                    ownComfortHeroes={allyComfortHeroes} // Ally comfort affects Ally table
                    oppositeComfortHeroes={enemyComfortHeroes} // Enemy comfort affects Ally table
                    isEnemyTable={false} // Flag to indicate it's the ally table
                    urlParam="ally"
                />
            </div>
        </div>
    );
}

export default DualSelectTable;